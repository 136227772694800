const Ext = window['Ext'];

export default Ext.define('ComunicazioniIFModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'idCom', type: 'int' },
            { name: 'testo', type: 'string' },
            { name: 'dataIniVal', type: 'date', format: 'd/m/Y' },
            { name: 'dataFinVal', type: 'date', format: 'd/m/Y' },
            { name: 'datIns', type: 'date', format: 'd/m/Y' },
            { name: 'flagPubbl', type: 'string' },
            { name: 'coloreRiga', type: 'string' },
            {
                name: 'stato',
                type: 'string',
                convert: function (value, record) {
                    let stato = "";
                    if (record.get('flagPubbl') === '1')
                        stato = "Pubblicata";
                    else if (record.get('flagPubbl') === '0')
                        // Esempio di elaborazione: concatenazione di testo e flagPubbl
                        stato = "Bozza";
                    else
                        stato = "Scaduta";
                    return stato;
                }
            }
        ]
});
