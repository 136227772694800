const Ext = window['Ext'];

export default Ext.define('ClientiCdSModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideCef', type: 'int' },
            { name: 'numCef', type: 'auto' },
            { name: 'desCef', type: 'string' },
            { name: 'datIniVal', type: 'date', format: 'd/m/Y' },
            { name: 'datFinVal', type: 'date', format: 'd/m/Y' },
            { name: 'flgSerCmm', type: 'boolean', defaultValue: false },
            { name: 'ideSer', type: 'int', defaultValue: 1},
            { name: 'desSer', type: 'string' },
            { name: 'ideLic', type: 'int' },
            { name: 'flgVal', type: 'boolean' },
            { name: 'sigLmzMerPcl', type: 'string', defaultValue: "No"},
            { name: 'desLmzMerPcl', type: 'string' }           
        ]
});
