/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import React from 'react';
import { Container, Panel, Dialog, Menu, MenuItem, Grid, Button, Spacer, SegmentedButton, Column, Toolbar } from '@sencha/ext-react-modern';
import { Loadmask } from "@sencha/ext-react-modern";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SchedaRto from './Rto';
import PopUpOperazioni from './RtoPopupOperazioni';
import PopUpCreaRtoDaPo from './RtoPopupCreaRtoDaPo';
import PopUpModifiche from './RtoPopupModifiche';
//import RtoPage from '../pages/RtoPage';
import rtoListModel from '../models/RtoListModel.js';
//import { AstroLoadMask } from '../components/AstroLoadMask';
import { useUser } from '../components/UserContext';
import DangerIcon from './../images/abiCli_Rosso.svg';


const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*'
]);

const storeRtoList = Ext.create('Ext.data.Store', {
    //model: rtoListModel,
    proxy: {
        actionMethods: {
            read: "POST"
        },
        type: 'ajax',
        url: 'Rto/LeggiElencoRto',
        reader: {
            type: 'json'
        },
        timeout: 900000
    },
    autoload: false
});
//---------PERIODO -----------------------------------
const storePeriodiFerroviariAttivi = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviariAttivi',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeListaPO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Po/LeggiListTreniRTO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//----------------------------------------------------------------------

function RtoList(params) {
    let ui = 'action';
    let uiPo = 'decline';

    const { user, switchUser } = useUser();

    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    var isRFICOMP = user.ruolo == 'RFI-PO-COMP';

    const [popupAzioni, setPopupAzioni] = useState({ showPopup: false, titolo: "", azione: "", data: "" });
    const [popupRtoDaPo, setPopupRtoDaPo] = useState({ showPopup: false, data: "" });
    const [popupModifiche, setPopupModifiche] = useState({ showPopup: false, data: "", htmlTesto: "" });
    const [showDialogDetailRTO, setShowDialogDetailRTO] = useState(false);
    const [paramsDetail, setParamsDetail] = useState({ ideRTO: 0, ideGrpRTO: 0, ideCli: 0, flgUpdVes: null, flgModTrc: null, flgUpdTrc: null, tipoAzione: "", readOnly: false, openInEdit: false, openInEditPO: false, versione: "", ideStoRTO: null, modificaServiziCommerciali: false, ideTrcOra: 0, flgModRTODaPO: false, modificaRTODaPOConferma: false })
    const [rowGridSelectedCount, setRowGridSelectedCount] = useState(0);
    let numRecordText = "Totale RTO: "
    let numRecordTextSelected = `Selezionate: ${rowGridSelectedCount}`
    const caricaRtoList = (filtro) => {
        params.handleLoadMask(false);
        storeRtoList.load({
            params: filtro,
            callback: function (records, options, success) {
                params.handleLoadMask(true);
                if (success) {
                    if (records.length < 1) {
                        setBtnTotRecord(0);
                        Ext.toast({ message: 'Nessuna RTO soddisfa il criterio di ricerca', timeout: 4000 });
                    }
                    else {
                        setBtnTotRecord(records.length);

                        // verifica se esistono i paramsDetail che vengono caricati quando si apre un dettaglio
                        // in caso positivo esegue la funzione che 1)trova l'indice della riga, seleziona la riga e fa lo scroll fino a visualizzarla
                        //(memorizza l'ideRTO e ripulisce lo stato dei paramsDetail)

                        // Dopo aver eseguito la funzione o in caso i paramsDetal vuoto esegue  il passo successivo

                        onGridSelect(); // rivaluta le azioni consentite
                    }
                }
                else {
                    Ext.Msg.alert('Elenco RTO', 'Errore caricamento RTO');
                }
            }
        });
    }

    const setBtnTotRecord = (numRecord) => {
        Ext.ComponentQuery.query('#btnTotRecord')[0]?.setText("Totale RTO: " + numRecord);
    }

    //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        storeRtoList.removeAll();
        storePeriodiFerroviariAttivi.load();
        if (params.reload) {
            caricaRtoList(params.filtro);
        }
    }, [params.filterChanged, params.strRicercaUniversale]);

    useEffect(() => {
        if (!params.reload) {
            storeRtoList.removeAll();
            storePeriodiFerroviariAttivi.load();
        }
    }, [params]);

    useEffect(() => {
        if (params.nuova !== undefined)
            onNuovoClick();
    }, [params.nuova]);

    //-------------------------------------------------------------------------------------------------------------------------
    const azioniRFI = {
        tools: {
            apriRto: {
                handler: onApri.bind(this),
                bind: {
                    iconCls: "x-fa fa-folder-open buttonGridTool",
                    tooltip: 'Apri RTO'
                }
            },
            modificaRto: {
                handler: onModifica.bind(this),
                bind: {
                    hidden: '{!record.flgUpdVes || record.flgAczVes}',
                    iconCls: "x-fa fa-edit buttonGridTool",
                    tooltip: 'Modifica RTO'
                }
            },
            modificaPo: {
                handler: onModificaPO.bind(this),
                bind: {
                    hidden: '{!record.modificaTraccia}',
                    iconCls: "x-fa fa-edit buttonGridToolPo",
                    tooltip: 'Modifica PO'
                }
            },
            dettaglioModifiche: {
                handler: onDettaglioModifiche.bind(this),
                bind: {
                    hidden: '{record.desVes != "Modificata"}',
                    iconCls: "x-far fa-clock buttonGridTool",
                    tooltip: 'Dettaglio Modifiche'
                }
            }
        }
    }
    const azioniIF = {
        tools: {
            apriRto: {
                handler: onApri.bind(this),
                bind: {
                    iconCls: "x-fa fa-folder-open buttonGridTool",
                    tooltip: 'Apri RTO'
                }
            },
            modificaRto: {
                handler: onModifica.bind(this),
                bind: {
                    hidden: '{!(record.flgUpdVes || record.ideStoRTO == 0 || record.flgAczVes)}',
                    iconCls: "x-fa fa-edit buttonGridTool",
                    tooltip: 'Modifica RTO'
                }
            },
            copiaRto: {
                handler: onCopia.bind(this),
                bind: {
                    iconCls: "x-fa fa-clone buttonGridTool",
                    tooltip: 'Copia RTO',
                    hidden: '{(record.ideNazOri != "0")}'
                }

            },
            modificaRtoDaPO: {
                handler: onModificaRtoDaPO.bind(this),
                bind: {
                    iconCls: "x-fa fa-wand-magic-sparkles buttonGridToolPo",
                    tooltip: 'Modifica RTO Da PO',
                    hidden: '{!(record.flgRtoDaPO && (record.flgUpdVes || record.ideStoRTO == 0))}'
                }

            },
            traformazioneStudioInTraccia: {
                handler: onTrasformazioneStudioInTraccia.bind(this),
                bind: {
                    iconCls: "fa-solid fa-angles-right buttonGridTool",
                    tooltip: 'Trasforma Studio in Traccia',
                    hidden: '{!(record.flgStuRto)}'
                }

            },
            dettaglioModifiche: {
                handler: onDettaglioModifiche.bind(this),
                bind: {
                    hidden: '{record.desVes != "Modificata"}',
                    iconCls: "x-far fa-clock buttonGridTool",
                    tooltip: 'Dettaglio Modifiche'
                }
            },
            apriRtoServComm: {
                handler: onApriServComm.bind(this),
                bind: {
                    hidden: '{record.aggServiziComm === false}',
                    iconCls: "x-fa fa-luggage-cart buttonGridTool",
                    tooltip: 'Modifica Servizi Commerciali'
                }
            },
            eliminaRto: {
                handler: onElimina.bind(this),
                bind: {
                    iconCls: "x-fa fa-trash buttonGridTool",
                    tooltip: 'Elimina RTO',
                    hidden: '{(record.ideStoRTO != "0")}'
                }

            }
        }
    }


    const formatTesto = (testo) => {
        let html = "<div class='dialogTxt'>";
        testo.split("\n\t").map((i, key) => {
            html = html + "<div key=" + key + ">" + i + "</div>";
        })
        html = html + "</div>";

        return html;
    }

    //const renderCellWithIcons = (record) => {
    //    const iconClass = record ? "x-fa fa-share icongridForza" : "";
    //    return (<span className={iconClass} />);
    //};
    //const flagForzaturaPUB = { xtype: 'reactcell', renderer: (_, record) => renderCellWithIcons(record.get('flagForzaturaPUB')) }
    const toolTipFlagForzaturaPUB = {
        tools: {
            flgForzataRto: {
                bind: {
                    cls: '{ record.flagForzaturaPUB ? "x-fa fa-share icongridForza" : ""  } tableIconTooltipRTO',
                    tooltip: '{ record.flagForzaturaPUB ? "Forzatatura PUB" : ""  }',
                    passive: true
                }
            }
        }
    }

    const toolTipSistema = {
        tools: {
            sistema: {
                bind: {
                    cls: '{ record.ideNazOri == "1" ? "flag_europe" : ((record.ideNazOri == "0") ? "flag_italy" : "") } tableIconTooltipRTO24',
                    tooltip: '{ record.desNazOri  }',
                    passive: true
                }
            }
        }
    }

    const toolTipIncongr = {
        tools: {
            apriRto: {
                bind: {
                    cls: '{ record.icoInc } tableIconTooltipRTO24',
                    tooltip: '{record.nomTipoInc}',
                    passive: true
                }
            }
        }
    }

    const toolTipFlagII = {
        tools: {
            apriRto: {
                bind: {
                    cls: '{ record.icoII } tableIconTooltipRTO',
                    tooltip: '{record.tooltipII}',
                    passive: true
                }
            }
        }
    }

    const toolTipFlagCds = {
        tools: {
            apriRto: {
                bind: {
                    cls: '{ "cer_Id" + record.icoCer } tableIconTooltipRTO',
                    tooltip: '{record.tooltipCer}',
                    passive: true
                }
            }
        }
    }

    const toolTipFlagCir = {
        tools: {
            apriRto: {
                bind: {
                    cls: '{ "cir_Id" + record.icoCir } tableIconTooltipRTO',
                    tooltip: '{record.tooltipCir}',
                    passive: true
                }
            }
        }
    }

    //------------------------------------------------------ Events -----------------------------------------------------------
    function renderColorRow(value, record, field, metaData) {
        //var style = metaData.row.baseCls;

        if (record.data.desVes === 'Modificata') {
            metaData.row.setCls("yellowBackground");
        }
        else {
            if (record.data.ideTipVco === 3) { //&& !isIF
                metaData.row.setCls("greenBackground");  // #adfd8c
            }
            else {
                metaData.row.setCls("whiteBackground");
            }
        }

        return value;
    }

    function renderNull(value, record, field, metaData) {

        return "";
    }

    //const renderIconCell = (value, record, field) => {
    //    let imgico = (record.data.ideNazOri == "0") ? "flag_europe" : ((record.data.ideNazOri == "1") ? "flag_italy" : "");
    //    return (
    //        <Image
    //            src={imgico}
    //        / >
    //    );
    //}


    function onApri(grid, info) {
        paramsDetail.ideRTO = info.record.data.ideRTO;
        paramsDetail.ideGrpRTO = info.record.data.ideGrpRTO;
        paramsDetail.ideCli = info.record.data.ideCli;
        paramsDetail.flgUpdVes = info.record.data.flgUpdVes;
        paramsDetail.flgModTrc = info.record.data.modificaTraccia;
        paramsDetail.flgUpdTrc = info.record.data.aggiornaTraccia;
        paramsDetail.tipoAzione = "A";
        paramsDetail.openInEdit = false;
        paramsDetail.openInEditPO = false;
        paramsDetail.modificaServiziCommerciali = false;
        paramsDetail.readOnly = (storePeriodiFerroviariAttivi.findRecord("ideAnnFro", params.filtro.ideAnnFro, 0, false, false, true) != null) ? false : true;
        paramsDetail.flgModRTODaPO = info.record.data.flgRtoDaPO;
        setParamsDetail({ ...paramsDetail });
        setShowDialogDetailRTO({ showDialogDetailRTO: true });
    }
    function onApriServComm(grid, info) {
        paramsDetail.ideRTO = info.record.data.ideRTO;
        paramsDetail.ideGrpRTO = info.record.data.ideGrpRTO;
        paramsDetail.ideCli = info.record.data.ideCli;
        paramsDetail.flgUpdVes = info.record.data.flgUpdVes;
        paramsDetail.flgModTrc = info.record.data.modificaTraccia;
        paramsDetail.flgUpdTrc = info.record.data.aggiornaTraccia;
        paramsDetail.tipoAzione = "A";
        paramsDetail.openInEdit = false;
        paramsDetail.openInEditPO = false;
        paramsDetail.readOnly = true;
        paramsDetail.modificaServiziCommerciali = true;
        setParamsDetail({ ...paramsDetail });
        setShowDialogDetailRTO({ showDialogDetailRTO: true });
    }

    function onDettaglioModifiche(grid, info) {
        let testo = "";
        params.handleLoadMask(false);
        Ext.Ajax.request({
            url: 'Rto/LeggiMessaggioPosta',
            method: 'POST',
            params: {
                ideUte: user.ideUte,
                ipAddress: "localhost",
                ideRTO: info.record.data.ideRTO
            },
            callback: function (records, success, operation) {
                params.handleLoadMask(true);
                if (success) {
                    let returnObj = Ext.decode(operation.responseText);

                    if (returnObj.resp == "OK") {
                        testo = formatTesto(returnObj.testo)
                        popupModifiche.data = info.record.data;
                        popupModifiche.htmlTesto = testo;
                        popupModifiche.showPopup = true;
                        setPopupModifiche({ ...popupModifiche });
                    }
                    else {
                        if (returnObj.resp == "KO")
                            Ext.Msg.alert('Dettaglio modifiche RTO', returnObj.msgErr);
                    }
                }
                else {
                    Ext.Msg.alert('Dettaglio modifiche RTO', "Errore in lettura delle modifiche.");
                }
            }
        });
    }

    function onModifica(grid, info) {
        // i parametri openInEdit, versione e ideStoRTO devono essere impostati per il tipoAzione="A" e openInEdit = true;
        paramsDetail.ideRTO = info.record.data.ideRTO;
        paramsDetail.ideGrpRTO = info.record.data.ideGrpRTO;
        paramsDetail.ideCli = info.record.data.ideCli;
        paramsDetail.flgUpdVes = info.record.data.flgUpdVes;
        paramsDetail.flgModTrc = false;
        paramsDetail.flgUpdTrc = info.record.data.aggiornaTraccia;
        paramsDetail.tipoAzione = "A";
        paramsDetail.openInEdit = true;
        paramsDetail.openInEditPO = false;
        paramsDetail.versione = info.record.data.desVes;
        paramsDetail.ideStoRTO = info.record.data.ideStoRTO;
        paramsDetail.readOnly = (storePeriodiFerroviariAttivi.findRecord("ideAnnFro", params.filtro.ideAnnFro, 0, false, false, true) != null) ? false : true;
        paramsDetail.modificaServiziCommerciali = false;
        setParamsDetail({ ...paramsDetail });
        setShowDialogDetailRTO({ showDialogDetailRTO: true });
    }

    function onModificaPO(grid, info) {
        // i parametri openInEdit, versione e ideStoRTO devono essere impostati per il tipoAzione="A" e openInEdit = true;
        paramsDetail.ideRTO = info.record.data.ideRTO;
        paramsDetail.ideGrpRTO = info.record.data.ideGrpRTO;
        paramsDetail.ideCli = info.record.data.ideCli;
        paramsDetail.flgUpdVes = info.record.data.flgUpdVes;
        paramsDetail.flgModTrc = info.record.data.modificaTraccia;
        paramsDetail.flgUpdTrc = info.record.data.aggiornaTraccia;
        paramsDetail.tipoAzione = "A";
        paramsDetail.openInEdit = false;
        paramsDetail.openInEditPO = true;
        paramsDetail.versione = info.record.data.desVes;
        paramsDetail.ideStoRTO = info.record.data.ideStoRTO;
        paramsDetail.readOnly = (storePeriodiFerroviariAttivi.findRecord("ideAnnFro", params.filtro.ideAnnFro, 0, false, false, true) != null) ? false : true;
        paramsDetail.modificaServiziCommerciali = false;
        setParamsDetail({ ...paramsDetail });
        setShowDialogDetailRTO({ showDialogDetailRTO: true });
    }

    function onModificaRtoDaPoRefresh(ideRTO, ideGrpRTO, ideCli, tipologia, ideStoRTO) {
        // i parametri openInEdit, versione e ideStoRTO devono essere impostati per il tipoAzione="A" e openInEdit = true;
        paramsDetail.ideRTO = ideRTO;
        paramsDetail.ideGrpRTO = ideGrpRTO;
        paramsDetail.ideCli = ideCli;
        paramsDetail.flgUpdVes = false;
        paramsDetail.flgModTrc = false;
        paramsDetail.flgUpdTrc = false;
        paramsDetail.tipoAzione = "A";
        paramsDetail.openInEdit = true;
        paramsDetail.versione = tipologia;
        paramsDetail.ideStoRTO = ideStoRTO;
        paramsDetail.readOnly = false;
        paramsDetail.modificaServiziCommerciali = false;
        paramsDetail.modificaRTODaPOConferma = true;
        setParamsDetail({ ...paramsDetail });
        popupRtoDaPo.data = "";
        popupRtoDaPo.showPopup = false;
        setPopupRtoDaPo({ ...popupRtoDaPo });
        setShowDialogDetailRTO({ showDialogDetailRTO: true });
    }

    function onCopia(grid, info) {
        paramsDetail.ideRTO = info.record.data.ideRTO;
        paramsDetail.ideGrpRTO = user.idTipoGruppo;
        paramsDetail.ideCli = info.record.data.ideCli;
        paramsDetail.flgUpdVes = null;
        paramsDetail.flgModTrc = null;
        paramsDetail.flgUpdTrc = null;
        paramsDetail.tipoAzione = "C";
        paramsDetail.openInEdit = false;
        paramsDetail.openInEditPO = false;
        paramsDetail.versione = "";
        paramsDetail.ideStoRTO = null;
        paramsDetail.readOnly = false;
        paramsDetail.modificaServiziCommerciali = false;
        setParamsDetail({ ...paramsDetail });
        setShowDialogDetailRTO({ showDialogDetailRTO: true });
    }
    function onTrasformazioneStudioInTraccia(grid, info) {
        Ext.Msg.confirm('Conferma', "Trasformare lo studio - Protocollo If : " + info.record.data.ptlIF + " Numero treno if : " + info.record.data.nomTreIpr + " Origine : " + info.record.data.nomLocPrv + " (" + info.record.data.oraPar + ")" + " Destino : " + info.record.data.nomLocDsn + " (" + info.record.data.oraArr + ")" + " - in RTO. La nuova RTO sarà creata in stato 2.", function (button) {
            if (button == 'yes') {
                paramsDetail.ideRTO = info.record.data.ideRTO;
                paramsDetail.ideGrpRTO = info.record.data.ideGrpRTO;
                paramsDetail.ideCli = info.record.data.ideCli;
                paramsDetail.flgUpdVes = info.record.data.flgUpdVes;
                paramsDetail.flgModTrc = false;
                paramsDetail.flgUpdTrc = info.record.data.aggiornaTraccia;
                paramsDetail.tipoAzione = "T";
                paramsDetail.openInEdit = true;
                paramsDetail.openInEditPO = false;
                paramsDetail.versione = info.record.data.desVes;
                paramsDetail.ideStoRTO = info.record.data.ideStoRTO;
                paramsDetail.readOnly = (storePeriodiFerroviariAttivi.findRecord("ideAnnFro", params.filtro.ideAnnFro, 0, false, false, true) != null) ? false : true;
                paramsDetail.modificaServiziCommerciali = false;
                setParamsDetail({ ...paramsDetail });
                setShowDialogDetailRTO({ showDialogDetailRTO: true });
            }
        })
    }

    function onModificaRtoDaPO(grid, info) {
        storeListaPO.clearData();
        storeListaPO.removeAll();
        storeListaPO.load({
            params: {
                ideRto: info.record.data.ideRTO
            },
            callback: function (records, options, success) {
                if (success) {
                    if (records.length < 1) {
                        Ext.Msg.alert('Elenco PO', 'Nessun Treno associato alla RTO');
                    }
                    else {
                        popupRtoDaPo.data = info;
                        popupRtoDaPo.showPopup = true;
                        setPopupRtoDaPo({ ...popupRtoDaPo });
                    }
                }
                else {
                    Ext.Msg.alert('Elenco PO', 'Errore caricamento lista Treni');
                }
            }
        });
    }

    function onElimina(grid, info) {
        if (isIF && info.record.data.ideStoRTO == 0) {
            Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione della richiesta?", function (button) {
                let msgKo = 'Elimina RTO fallita';
                if (button == 'yes') {
                    Ext.Ajax.request({
                        url: 'Rto/EliminaRto',
                        method: 'POST',
                        params: {
                            ideUte: user.ideUte,
                            ipAddress: "",
                            ideRto: info.record.data.ideRTO
                        },
                        callback: function (records, success, operation) {
                            if (success) {
                                var returnObj = Ext.decode(operation.responseText);
                                //modifica gestione errore
                                if (returnObj.resp == "KO") {
                                    Ext.Msg.alert(msgKo, returnObj.msgErr)
                                }
                                else {
                                    //updateValuesParams(4, 1, Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value)
                                    Ext.toast({ message: 'RTO eliminata!', timeout: 4000 });
                                    RefreshRtoList();
                                }
                            }
                            else {
                                Ext.Msg.alert('Operazione fallita', msgKo);
                            }
                        }
                    });

                }
            })
        }
        else
            Ext.Msg.alert('Operazione non consentita', "Operazione consentita solo all'IF e con RTO in stato 0");
    }

    function hideDialogDetailRTO() {
        setShowDialogDetailRTO({ showDialogDetailRTO: false });
        //caricaRtoList(params.filtro);
    }

    function onGridSelect() {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        if (grid != undefined) {
            var gridSelection = grid.getSelections();
            let lng = gridSelection.length;
            setRowGridSelectedCount(lng)
            // bottoni a selezione singola
            if (lng === 1) {
                //abilita i bottoni singola gestione
                Ext.ComponentQuery.query('#btnApri')[0].setDisabled(false);
                if (isIF && gridSelection[0].data.ideNazOri === 0)
                    Ext.ComponentQuery.query('#btnCopia')[0].setDisabled(false);
                if (isIF && gridSelection[0].data.ideStoRTO === 0)
                    Ext.ComponentQuery.query('#btnElimina')[0].setDisabled(false);
            }
            else {
                //disabilita i bottoni singola gestione
                Ext.ComponentQuery.query('#btnApri')[0].setDisabled(true);
                Ext.ComponentQuery.query('#btnCopia')[0].setDisabled(true);
                Ext.ComponentQuery.query('#btnElimina')[0].setDisabled(true);
            }
            // Bottoni e menu a selezione multipla
            if (lng >= 1) {
                //abilita bottoni e voci menu

                //-- Esporta
                Ext.ComponentQuery.query('#meEsportaPdf')[0].setDisabled(false);
                Ext.ComponentQuery.query('#meEsportaExcel')[0].setDisabled(false);
                Ext.ComponentQuery.query('#meEsportaSintesi')[0].setDisabled(false);

                // Altri
                AbilitaAzioni(gridSelection);

            }
            else {
                //disabilita bottoni e voci menu
                Ext.ComponentQuery.query('#btnAccetta')[0].setDisabled(true);
                Ext.ComponentQuery.query('#btnRifiuta')[0].setDisabled(true);
                //-- Esporta
                Ext.ComponentQuery.query('#meEsportaPdf')[0].setDisabled(true);
                Ext.ComponentQuery.query('#meEsportaExcel')[0].setDisabled(true);
                Ext.ComponentQuery.query('#meEsportaSintesi')[0].setDisabled(true);
                //-- Stato
                Ext.ComponentQuery.query('#msTrasmetti')[0].setDisabled(true);
                Ext.ComponentQuery.query('#msPrendiCarico')[0].setDisabled(true);
                Ext.ComponentQuery.query('#msCompletaPO')[0].setDisabled(true);
                Ext.ComponentQuery.query('#msPubblicaPO')[0].setDisabled(true);
                Ext.ComponentQuery.query('#msPubblicaForza')[0].setDisabled(true);
                Ext.ComponentQuery.query('#msAccettaPO')[0].setDisabled(true);
                Ext.ComponentQuery.query('#msRifiutaPO')[0].setDisabled(true);
                Ext.ComponentQuery.query('#msAnnullaRTO')[0].setDisabled(true);
                Ext.ComponentQuery.query('#msRichiediContr')[0].setDisabled(true);
                Ext.ComponentQuery.query('#msContrattualizza')[0].setDisabled(true);
                //-- PO
                Ext.ComponentQuery.query('#mpAggiornaPO')[0].setDisabled(true);
                Ext.ComponentQuery.query('#mpInviaRDI')[0].setDisabled(true);
                Ext.ComponentQuery.query('#mpForzaRDI')[0].setDisabled(true);
                Ext.ComponentQuery.query('#mpVerificaCdSCirc')[0].setDisabled(true);
                Ext.ComponentQuery.query('#mpAssociazioneOneClick')[0].setDisabled(true);
            }
        }
    }

    function onApriClick() {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        var gridSelection = grid.getSelections();
        if (gridSelection.length === 1) {
            paramsDetail.ideRTO = gridSelection[0].data.ideRTO;
            paramsDetail.ideGrpRTO = gridSelection[0].data.ideGrpRTO;
            paramsDetail.ideCli = gridSelection[0].data.ideCli;
            paramsDetail.flgUpdVes = gridSelection[0].data.flgUpdVes;
            paramsDetail.flgModTrc = gridSelection[0].data.modificaTraccia;
            paramsDetail.flgUpdTrc = gridSelection[0].data.aggiornaTraccia;
            paramsDetail.tipoAzione = "A";
            paramsDetail.openInEdit = false;
            paramsDetail.versione = gridSelection[0].data.desVes;
            paramsDetail.readOnly = (storePeriodiFerroviariAttivi.findRecord("ideAnnFro", params.filtro.ideAnnFro, 0, false, false, true) != null) ? false : true;
            paramsDetail.modificaServiziCommerciali = false;
            paramsDetail.flgModRTODaPO = gridSelection[0].data.flgRtoDaPO;
            setParamsDetail({ ...paramsDetail });
            setShowDialogDetailRTO({ showDialogDetailRTO: true });
            /*caricaRto(gridSelection[0].data.ideRTO, gridSelection[0].data.ideGrpRTO, gridSelection[0].data.ideRTO, gridSelection[0].data.ideCli);*/
        }
        else {
            Ext.Msg.alert('Nessuna selezione', 'Selezionare una RTO dalla lista');
        }
    }

    function onCopiaClick() {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        var gridSelection = grid.getSelections();
        if (gridSelection.length === 1) {
            paramsDetail.ideRTO = gridSelection[0].data.ideRTO;
            paramsDetail.ideGrpRTO = user.idTipoGruppo;
            paramsDetail.ideCli = gridSelection[0].data.ideCli;
            paramsDetail.flgUpdVes = null;
            paramsDetail.flgModTrc = null;
            paramsDetail.flgUpdTrc = null;
            paramsDetail.tipoAzione = "C";
            paramsDetail.openInEdit = false;
            paramsDetail.openInEditPO = false;
            paramsDetail.versione = "";
            paramsDetail.ideStoRTO = null;
            paramsDetail.readOnly = false;
            paramsDetail.modificaServiziCommerciali = false;
            setParamsDetail({ ...paramsDetail });
            setShowDialogDetailRTO({ showDialogDetailRTO: true });
        }
        else {
            Ext.Msg.alert('Nessuna selezione', 'Selezionare una RTO dalla lista');
        }
    }

    function onNuovoClick() {
        paramsDetail.ideRTO = 0;
        paramsDetail.ideGrpRTO = 0;
        paramsDetail.ideCli = 0;
        paramsDetail.flgUpdVes = null;
        paramsDetail.flgModTrc = false;
        paramsDetail.flgUpdTrc = false;
        paramsDetail.tipoAzione = "N";
        paramsDetail.modificaServiziCommerciali = false;
        paramsDetail.openInEdit = false;
        paramsDetail.openInEditPO = false;
        paramsDetail.versione = "";
        paramsDetail.ideStoRTO = null;
        paramsDetail.readOnly = false;
        setParamsDetail({ ...paramsDetail });
        setShowDialogDetailRTO({ showDialogDetailRTO: true });
    }


    function onVisualizzaClick() {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        var gridSelection = grid.getSelections();
        if (gridSelection.length > 0) {
            let par = { ideRto: gridSelection[0].data.ideRTO, ideGrp: gridSelection[0].data.ideGrpRTO, ideCli: gridSelection[0].data.ideCli };
            navigateExternal("/SchedaRto", { state: { par } }); //non funziona
        }
        else {
            Ext.Msg.alert('Nessuna selezione', 'Selezionare una RTO dalla lista');
        }

    }

    function onAccettaClick(item) {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        var gridSelection = grid.getSelections();
        if (gridSelection.length > 0) {
            var elenco = [];
            for (let i = 0; i < gridSelection.length; i++) {
                let rowData = gridSelection[i].data;
                if (rowData.flgAczVes === 1) {
                    elenco.push(rowData.ideRTO);
                }
            }
            // Aprire popup per il l'accettazione - "btnAccetta"
            popupAzioni.azione = item._itemId;
            popupAzioni.data = elenco;
            popupAzioni.showPopup = true;
            setPopupAzioni({ ...popupAzioni });
        }
        else {
            Ext.Msg.alert('Nessuna selezione', 'Selezionare una RTO dalla lista');
        }
    }
    function accettaModificaRto(rowData) {
        chiudiPopupModifiche();
        var elenco = [];
        if (rowData.flgAczVes === 1) {
            elenco.push(rowData.ideRTO);

            // Aprire popup per il l'accettazione - "btnAccetta"
            popupAzioni.azione = "btnAccetta";
            popupAzioni.data = elenco;
            popupAzioni.showPopup = true;
            setPopupAzioni({ ...popupAzioni });
        }
    }

    function onRifiutaClick(item) {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        var gridSelection = grid.getSelections();
        if (gridSelection.length > 0) {
            var elenco = [];
            for (let i = 0; i < gridSelection.length; i++) {
                let rowData = gridSelection[i].data;
                if (rowData.flgAczVes === 1) {
                    elenco.push(rowData.ideRTO);
                }
            }
            // Aprire popup per il l'accettazione - "btnRifiuta"
            popupAzioni.azione = item._itemId;
            popupAzioni.data = elenco;
            popupAzioni.showPopup = true;
            setPopupAzioni({ ...popupAzioni });
        }
        else {
            Ext.Msg.alert('Nessuna selezione', 'Selezionare una RTO dalla lista');
        }
    }
    function rifiutaModificaRto(rowData) {
        chiudiPopupModifiche();
        var elenco = [];
        if (rowData.flgAczVes === 1) {
            elenco.push(rowData.ideRTO);

            // Aprire popup per il l'accettazione - "btnRifiuta"
            popupAzioni.azione = "btnRifiuta";
            popupAzioni.data = elenco;
            popupAzioni.showPopup = true;
            setPopupAzioni({ ...popupAzioni });
        }
    }

    const onEsportaExcelClick = () => {
        Ext.Msg.confirm('Confirm', "Esporta Elenco RTO?", function (button) {

            if (button === 'yes') {
                //var jsonObject = {"Filtro": params.filtro};
                Ext.Ajax.request({
                    url: 'Rto/EsportazioneRFI',
                    method: 'POST',
                    type: 'ajax',
                    params: params.filtro,
                    //data: JSON.stringify({ filtro:  params.filtro  }),
                    //dataType: "json",
                    success: function (response) {
                        let filename = response.data;
                        Ext.Msg.alert("Success", 'yea');
                    },
                    failure: function (response) {
                        Ext.Msg.alert("Error", response.statusText);
                    }
                });

            }
        })
    }

    const RefreshRtoList = () => {
        storeRtoList.removeAll();
        storePeriodiFerroviariAttivi.load();
        caricaRtoList(params.filtro);
    }

    //function onDownloadGridClick() {
    //    var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
    //    grid.saveDocumentAs({
    //        type: 'xlsx',
    //        title: 'My export',
    //        fileName: 'myExport.xlsx'
    //    });
    //}
    // ----------------------------------------------------- Definizione menu --------------------------------------------------//  

    let widthActionMenu = 110;

    function ValidaAzioneStato(azione, rowData) {
        // Valida se l'rto selezionata può passare allo stato definito nell'azione
        let valida = false;

        switch (azione) {
            case "msTrasmetti":
                valida = (isIF && rowData.ideStoRTO === 0 && rowData.desVes.toLowerCase() == "attiva" && (rowData.flgAbiCmbSto || rowData.flgCmbSto)) ? true : false;
                break;
            case "msPrendiCarico":
                valida = (!isIF && rowData.ideStoRTO === 1 && rowData.desVes.toLowerCase() == "attiva" && (rowData.flgAbiCmbSto || rowData.flgCmbSto)) ? true : false;
                break;
            case "msCompletaPO":
                valida = (!isIF && rowData.ideStoRTO === 2 && rowData.desVes.toLowerCase() == "attiva" && (rowData.flgAbiCmbSto || rowData.flgCmbSto)) ? true : false;
                break;
            case "msPubblicaPO":
                valida = (!isIF && (rowData.ideStoRTO === 4 || (rowData.flgAbiPubTrc && rowData.ideStoRTO === 8)) && rowData.desVes.toLowerCase() == "attiva" && (rowData.flgAbiCmbSto || rowData.flgCmbSto)) ? true : false;
                break;
            case "msPubblicaForza":
                valida = (!isIF && (rowData.ideStoRTO === 4 || (rowData.flgAbiPubTrc && rowData.ideStoRTO === 8)) && rowData.desVes.toLowerCase() == "attiva" && (rowData.flgCmbStoFrz || rowData.flgPubFrzRto) && (rowData.flgAbiCmbSto || rowData.flgCmbSto)) ? true : false;
                break;
            case "msAccettaPO":
                valida = (isIF && (rowData.ideStoRTO === 5 || rowData.ideStoRTO === 8) && rowData.desVes.toLowerCase() == "attiva" && (rowData.flgAbiCmbSto || rowData.flgCmbSto) && rowData.numTrenoRFI !== "") ? true : false;
                break;
            case "msRifiutaPO":
                valida = (isIF && (rowData.ideStoRTO === 5 || rowData.ideStoRTO === 6 || rowData.ideStoRTO === 8) && rowData.desVes.toLowerCase() == "attiva" && (rowData.flgAbiCmbSto || rowData.flgCmbSto) && rowData.numTrenoRFI !== "") ? true : false;
                break;
            case "msAnnullaRTO":
                valida = ((rowData.ideStoRTO === 1 || rowData.ideStoRTO === 2 || rowData.ideStoRTO === 4 || (!isIF && rowData.ideStoRTO === 5)) && rowData.desVes.toLowerCase() == "attiva" && (rowData.flgAbiCmbSto || rowData.flgCmbSto)) ? true : false;
                break;
            case "msRichiediContr":
                valida = (isIF && rowData.ideStoRTO === 6 && rowData.desVes.toLowerCase() == "attiva" && (rowData.flgAbiCmbSto || rowData.flgCmbSto)) ? true : false;
                break;
            case "msContrattualizza":
                valida = (!isIF && rowData.ideStoRTO === 6 && rowData.desVes.toLowerCase() == "attiva" && (rowData.flgAbiCmbSto || rowData.flgCmbSto)) ? true : false;
                break;
            default:
                valida = false;
        }
        return valida
    }

    function ValidaAzionePO(azione, rowData) {
        // Valida se per l'rto selezionata si può eseguire l'azione
        let valida = false;

        switch (azione) {
            case "mpAggiornaPO":
                valida = (!isIF && rowData.flgUpdTrc === 1) ? true : false;
                break;
            case "mpInviaRDI":
                valida = (!isIF && rowData.flagInviaPUB) ? true : false;
                break;
            case "mpForzaRDI":
                valida = (!isIF && rowData.flgFrzRto) ? true : false;
                break;
            case "mpVerificaCdSCirc":
                valida = (!isIF && rowData.flgTrnAsc) ? true : false;
                break;
            case "mpAssociazioneOneClick":
                valida = (!isIF && rowData.flgAscTrc) ? true : false;
                break;
            default:
                valida = false;
        }
        return valida
    }

    function AbilitaAzioni(gridSelection) {
        let btnAccetta = false;
        let btnRifiuta = false;
        let statoTrasmetti = false, statoPrendiCarico = false, statoCompleta = false, statoPubblica = false, statoPubblicaForza = false, statoAccetta = false;
        let statoRifiuta = false, statoAnnulla = false, statoRichiediContr = false, statoContrattualizza = false;
        let poAggiornaPO = false, poInviaRDI = false, poForzaRDI = false, poPubbliaForza = false, poVerificaCdSCirc = false, poOneClick = false;

        if (storePeriodiFerroviariAttivi.findRecord("ideAnnFro", params.filtro.ideAnnFro, 0, false, false, true) != null) {
            for (var i = 0; i < gridSelection.length; i++) {
                let row = gridSelection[i];

                // bottoni
                if (btnAccetta === false) {
                    // condizioni per abilitare il bottone Accetta RTO Modificate
                    btnAccetta = (row.data.flgAczVes === 1) ? true : false;
                }
                if (btnRifiuta === false) {
                    // condizioni per abilitare il bottone Rifiuta RTO Modificate
                    btnRifiuta = (row.data.flgAczVes === 1) ? true : false;
                }

                // Stato
                // condizioni per abilitare la voce Trasmetti
                if (statoTrasmetti === false) {
                    statoTrasmetti = ValidaAzioneStato("msTrasmetti", row.data);
                }
                // condizioni per abilitare la voce Prendi in Carico
                if (statoPrendiCarico === false) {
                    statoPrendiCarico = ValidaAzioneStato("msPrendiCarico", row.data);
                }
                // condizioni per abilitare la voce Completa
                if (statoCompleta === false) {
                    statoCompleta = ValidaAzioneStato("msCompletaPO", row.data);
                }
                // condizioni per abilitare la voce Pubblica
                if (statoPubblica === false) {
                    statoPubblica = ValidaAzioneStato("msPubblicaPO", row.data);
                }
                // condizioni per abilitare la voce Pubblica e Forza
                if (statoPubblicaForza === false) {
                    statoPubblicaForza = ValidaAzioneStato("msPubblicaForza", row.data);
                }
                // condizioni per abilitare la voce Accetta
                if (statoAccetta === false) {
                    statoAccetta = ValidaAzioneStato("msAccettaPO", row.data);
                }
                // condizioni per abilitare la voce Rifiuta
                if (statoRifiuta === false) {
                    statoRifiuta = ValidaAzioneStato("msRifiutaPO", row.data);
                }
                // condizioni per abilitare la voce Annulla
                if (statoAnnulla === false) {
                    statoAnnulla = ValidaAzioneStato("msAnnullaRTO", row.data);
                }
                // condizioni per abilitare la voce Richiedi Contrattualizzazione
                if (statoRichiediContr === false) {
                    statoRichiediContr = ValidaAzioneStato("msRichiediContr", row.data);
                }
                // condizioni per abilitare la voce Contrattualizza
                if (statoContrattualizza === false) {
                    statoContrattualizza = ValidaAzioneStato("msContrattualizza", row.data);
                }

                // PO
                // condizioni per abilitare la voce Aggiorna PO
                if (poAggiornaPO === false) {
                    poAggiornaPO = ValidaAzionePO("mpAggiornaPO", row.data);
                }
                // condizioni per abilitare la voce Invia a RDI
                if (poInviaRDI === false) {
                    poInviaRDI = ValidaAzionePO("mpInviaRDI", row.data);
                }
                // condizioni per abilitare la voce Forza
                if (poForzaRDI === false) {
                    poForzaRDI = ValidaAzionePO("mpForzaRDI", row.data);
                }
                // condizioni per abilitare la voce Verifica CdS e Circolabilità
                if (poVerificaCdSCirc === false) {
                    poVerificaCdSCirc = ValidaAzionePO("mpVerificaCdSCirc", row.data);
                }
                // condizioni per abilitare la voce one click
                if (poOneClick === false) {
                    poOneClick = ValidaAzionePO("mpAssociazioneOneClick", row.data);
                }
            }
        }

        // Bottoni
        Ext.ComponentQuery.query('#btnAccetta')[0].setDisabled(!btnAccetta);
        Ext.ComponentQuery.query('#btnRifiuta')[0].setDisabled(!btnRifiuta);

        // Sato
        Ext.ComponentQuery.query('#msTrasmetti')[0].setDisabled(!statoTrasmetti);
        //Ext.ComponentQuery.query('#msTrasmetti')[0].setHidden(!statoTrasmetti);

        Ext.ComponentQuery.query('#msPrendiCarico')[0].setDisabled(!statoPrendiCarico);
        //Ext.ComponentQuery.query('#msPrendiCarico')[0].setHidden(!statoPrendiCarico);

        Ext.ComponentQuery.query('#msCompletaPO')[0].setDisabled(!statoCompleta);
        //Ext.ComponentQuery.query('#msCompletaPO')[0].setHidden(!statoCompleta);

        Ext.ComponentQuery.query('#msPubblicaPO')[0].setDisabled(!statoPubblica);
        //Ext.ComponentQuery.query('#msPubblicaPO')[0].setHidden(!statoPubblica);

        Ext.ComponentQuery.query('#msPubblicaForza')[0].setDisabled(!statoPubblicaForza);
        //Ext.ComponentQuery.query('#msPubblicaForza')[0].setHidden(!statoPubblicaForza);

        Ext.ComponentQuery.query('#msAccettaPO')[0].setDisabled(!statoAccetta);
        //Ext.ComponentQuery.query('#msAccettaPO')[0].setHidden(!statoAccetta);

        Ext.ComponentQuery.query('#msRifiutaPO')[0].setDisabled(!statoRifiuta);
        //Ext.ComponentQuery.query('#msRifiutaPO')[0].setHidden(!statoRifiuta);

        Ext.ComponentQuery.query('#msAnnullaRTO')[0].setDisabled(!statoAnnulla);
        //Ext.ComponentQuery.query('#msAnnullaRTO')[0].setHidden(!statoAnnulla);

        Ext.ComponentQuery.query('#msRichiediContr')[0].setDisabled(!statoRichiediContr);
        //Ext.ComponentQuery.query('#msRichiediContr')[0].setHidden(!statoRichiediContr);

        Ext.ComponentQuery.query('#msContrattualizza')[0].setDisabled(!statoContrattualizza);
        //Ext.ComponentQuery.query('#msContrattualizza')[0].setHidden(!statoContrattualizza);

        // PO
        Ext.ComponentQuery.query('#mpAggiornaPO')[0].setDisabled(!poAggiornaPO);
        //Ext.ComponentQuery.query('#mpAggiornaPO')[0].setHidden(!poAggiornaPO);

        Ext.ComponentQuery.query('#mpInviaRDI')[0].setDisabled(!poInviaRDI);
        //Ext.ComponentQuery.query('#mpInviaRDI')[0].setHidden(!poInviaRDI);

        Ext.ComponentQuery.query('#mpForzaRDI')[0].setDisabled(!poForzaRDI);
        //Ext.ComponentQuery.query('#mpForzaRDI')[0].setHidden(!poForzaRDI);

        Ext.ComponentQuery.query('#mpVerificaCdSCirc')[0].setDisabled(!poVerificaCdSCirc);
        //Ext.ComponentQuery.query('#mpVerificaCdSCirc')[0].setHidden(!poVerificaCdSCirc);

        Ext.ComponentQuery.query('#mpAssociazioneOneClick')[0].setDisabled(!poOneClick);
    }

    function onMenuStatoClick(item) {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        var gridSelection = grid.getSelections();
        if (gridSelection.length > 0) {
            var elenco = [];
            for (let i = 0; i < gridSelection.length; i++) {
                let rowData = gridSelection[i].data;
                if (ValidaAzioneStato(item._itemId, rowData)) {
                    let item = {
                        IdeRTO: rowData.ideRTO,
                        PtlRFI: rowData.ptlRFI,
                        NomTreIpr: rowData.nomTreIpr,
                        IdeStoRTO: rowData.ideStoRTO,
                        DatAtvPvv: rowData.datAtvPvv
                    }
                    elenco.push(item);
                }
            }
            // Aprire popup per il cambio stato 
            popupAzioni.azione = item._itemId;
            popupAzioni.data = elenco;
            popupAzioni.showPopup = true;
            setPopupAzioni({ ...popupAzioni });
        }
        else {
            Ext.Msg.alert('Nessuna selezione', 'Selezionare una RTO dalla lista');
        }
    }

    function chiudiPopupAzioni() {
        popupAzioni.azione = "";
        popupAzioni.data = "";
        popupAzioni.showPopup = false;
        setPopupAzioni({ ...popupAzioni });
        caricaRtoList(params.filtro);
    }

    function chiudiPopupCreaRtoDaPo() {
        popupRtoDaPo.data = "";
        popupRtoDaPo.showPopup = false;
        setPopupRtoDaPo({ ...popupRtoDaPo });
    }

    function chiudiPopupModifiche() {
        popupModifiche.data = "";
        popupModifiche.showPopup = false;
        setPopupModifiche({ ...popupModifiche });
    }

    function onMenuPoClick(item) {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        var gridSelection = grid.getSelections();
        if (gridSelection.length > 0) {
            var elenco = [];
            for (let i = 0; i < gridSelection.length; i++) {
                let rowData = gridSelection[i].data;
                if (ValidaAzionePO(item._itemId, rowData)) {
                    let item = {
                        IdeRTO: rowData.ideRTO,
                        PtlRFI: rowData.ptlRFI,
                        NomTreIpr: rowData.nomTreIpr,
                        IdeStoRTO: rowData.ideStoRTO,
                        DatAtvPvv: rowData.datAtvPvv
                    }
                    elenco.push(item);
                }
            }
            // Aprire popup per il cambio stato 
            popupAzioni.azione = item._itemId;
            popupAzioni.data = elenco;
            popupAzioni.showPopup = true;
            setPopupAzioni({ ...popupAzioni });
        }
        else {
            Ext.Msg.alert('Nessuna selezione', 'Selezionare una RTO dalla lista');
        }
    }

    function onMenuEsportaClick(item) {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        var gridSelection = grid.getSelections();

        if (gridSelection.length > 0) {
            var elenco = "";

            for (let i = 0; i < gridSelection.length; i++) {
                let rowData = gridSelection[i].data;
                elenco = (i === gridSelection.length - 1) ? elenco + rowData.ideRTO : elenco + rowData.ideRTO + ",";
            }

            let msgKo = 'Esportazione fallita';

            // Costruisci l'URL con i parametri in query string
            let url = 'Rto/ExportSintesiExcel';//?ideUte=${user.ideUte}&ideAnnFro=${params.filtro.ideAnnFro}&listaRto=${elenco}&flgImtTrc=1`;

            // Effettua la chiamata POST per il download            
            //console.log('Request Rto/ExportSintesiExcel (sec)= ', Math.floor(Date.now() / 1000));

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ideUte: user.ideUte,
                    ideAnnFro: params.filtro.ideAnnFro,
                    listaRto: elenco,
                    flgImtTrc: 1
                })
            })
                .then(response => {
                    //console.log('Response Rto/ExportSintesiExcel (sec)= ', Math.floor(Date.now() / 1000));
                    if (response.ok) {
                        // Estre il nome del file dall'intestazione Content-Disposition
                        let archiveNameRTO = 'archivioRTO.xls';
                        const contentDisposition = response.headers.get('Content-Disposition');
                        const regex = /filename\*=UTF-8''(.+?)(?=';|$)/;
                        const match = regex.exec(contentDisposition);

                        if (match) {
                            archiveNameRTO = match[1];
                        }

                        return { response, archiveNameRTO };
                    }
                })
                .then(({ response, archiveNameRTO }) => response.blob()
                    .then(blob => {
                        //console.log('Inizio elaborazione blob (sec)= ', Math.floor(Date.now() / 1000));
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = archiveNameRTO; // Utilizza il nome del file recuperato
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                        //console.log('Fine elaborazione blob (sec)= ', Math.floor(Date.now() / 1000));
                        toastEsporta.hide();
                    })
                )
                .catch(error => {
                    toastEsporta.hide();
                    Ext.Msg.alert('Operazione fallita', msgKo);

                });
        } else {
            toastEsporta.hide();
            Ext.Msg.alert('Nessuna selezione', 'Selezionare una RTO dalla lista');

        }

        //Ext.toast({ message: 'Download File in Corso', timeout: 3600000, itemId:'toastEsportaSintesi'});
        const toastEsporta = Ext.toast({ message: 'Download Esporta Sintesi in Corso', timeout: 18000000 });
    }

    function onMenuEsportaExcelIfClick(item) {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        var gridSelection = grid.getSelections();

        if (gridSelection.length > 0) {
            var elenco = "";

            for (let i = 0; i < gridSelection.length; i++) {
                let rowData = gridSelection[i].data;
                elenco = (i === gridSelection.length - 1) ? elenco + rowData.ideRTO : elenco + rowData.ideRTO + ",";
            }

            let msgKo = 'Esportazione fallita';

            // Costruisci l'URL con i parametri in query string
            //let url = `Rto/ExportRTOIfExcel?ideUte=${user.ideUte}&ideAnnFro=${params.filtro.ideAnnFro}&listaRto=${elenco}`;  // flgImtTrc deve essere Valutato da Popup
            let url = `Rto/ExportRTOIfExcel`;

            // Effettua la chiamata GET per il download
            //fetch(url)
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ideUte: user.ideUte,
                    ideAnnFro: params.filtro.ideAnnFro,
                    listaRto: elenco
                })
            }).then(response => {
                // Estre il nome del file dall'intestazione Content-Disposition
                let archiveNameRTO = 'archivioRTO.zip';
                const contentDisposition = response.headers.get('Content-Disposition');
                const regex = /filename\*=UTF-8''(.+?)(?=';|$)/;
                const match = regex.exec(contentDisposition);

                if (match) {
                    archiveNameRTO = match[1];
                }

                return { response, archiveNameRTO };
            })
                .then(({ response, archiveNameRTO }) => response.blob()
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = archiveNameRTO; // Utilizza il nome del file recuperato
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                        toastEsporta.hide();
                    })
                )
                .catch(error => {
                    toastEsporta.hide();
                    Ext.Msg.alert('Operazione fallita', msgKo);
                });
        } else {
            toastEsporta.hide();
            Ext.Msg.alert('Nessuna selezione', 'Selezionare una RTO dalla lista');
        }
        const toastEsporta = Ext.toast({ message: 'Download Esporta RTO in Corso', timeout: 18000000 });
    }

    function onMenuEsportaSelezioneClick(item) {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        var gridSelection = grid.getSelections();

        var elenco = "";

        for (let i = 0; i < gridSelection.length; i++) {
            let rowData = gridSelection[i].data;
            elenco = (i === gridSelection.length - 1) ? elenco + rowData.ideRTO : elenco + rowData.ideRTO + ",";
        }

        let msgKo = 'Esportazione fallita';

        // Costruisci l'URL con i parametri in query string
        //let url = `Rto/ExportSelezionePdf?ideUte=${user.ideUte}&listaRto=${elenco}&ideTipGrp=${user.ideGrp}`;
        let url = `Rto/ExportSelezionePdf`;

        // Effettua la chiamata POST per il download
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ideUte: user.ideUte,
                listaRto: elenco,
                ideTipGrp: user.ideGrp
            })
        })
            .then(response => {
                // Estre il nome del file dall'intestazione Content-Disposition
                let archiveNameRTO = 'EsportaRTO.zip';
                const contentDisposition = response.headers.get('Content-Disposition');
                const regex = /filename\*=UTF-8''(.+?)(?=';|$)/;
                const match = regex.exec(contentDisposition);

                if (match) {
                    archiveNameRTO = match[1];
                }

                return { response, archiveNameRTO };
            })
            .then(({ response, archiveNameRTO }) => response.blob()
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = archiveNameRTO;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                    toastEsporta.hide();
                })
            )
            .catch(error => {
                toastEsporta.hide();
                Ext.Msg.alert('Operazione fallita', msgKo);
            });

        const toastEsporta = Ext.toast({ message: 'Download Esporta Dettaglio RTO in Corso', timeout: 18000000 });
    }
    //-------------------------------------------------------------------------------------------------------------------------//

    const navigateExternal = (target, options) => {
        if (options.state) {
            localStorage.setItem("state", JSON.stringify(options.state));
        }
        window.open(target, "_blank", "noreferrer");
    };

    // -------------------------------------------------- Definizione colonne e state delle colonne ----------------------------------------------------------------------------- columnWrap
    const colonneGridRtoList = () => {
        //----------------------------------------------- Definizione di default delle colonne ---------------------------------------------------------------------------------------------    
        const clnAzioni = { text: "Azioni", ignore: "true", ignoreExport: "true", cell: (isIF ? azioniIF : azioniRFI), width: (isIF ? 155 : 100) };
        const clnScadenza = { text: "Scadenza", dataIndex: "datScd", type: "datecolumn", width: 83, formatter: 'date("d/m/Y")' };
        const clnProtocolloIF = { text: "Protocollo IF", dataIndex: "ptlIF", filterType: 'string', width: 190, cell: { cls: 'selectable' } };
        const clnDataProtocolloIF = { text: "Data Prot. IF", hidden: true, dataIndex: "datPtlIF", type: "datecolumn", width: 83, formatter: 'date("d/m/Y")' };
        const clnProtocolloRFI = { text: "Protocollo RFI", dataIndex: "ptlRFI", filterType: 'string', width: 123, cell: { cls: 'selectable' } };
        const clnDataProtocolloRFI = { text: "Data Prot. RFI", hidden: true, dataIndex: "datPtlRFI", type: "datecolumn", width: 83, formatter: 'date("d/m/Y")' };
        const clnOrigine = { text: "Origine", dataIndex: "nomLocPrv", filterType: 'list', width: 155, userSelectable: true };
        const clnOraPar = { text: "Ora Par", dataIndex: "oraPar", width: 51, filterType: 'string' };
        const clnDestinazione = { text: "Destinazione", dataIndex: "nomLocDsn", filterType: 'list', width: 155, userSelectable: true };
        const clnOraArr = { text: "Ora Arr", dataIndex: "oraArr", width: 51, filterType: 'string' };
        const clnNumTrenoIF = { text: "N.Treno IF", dataIndex: "nomTreIpr", filterType: 'string', width: 70, cell: { cls: 'selectable' } };
        const clnLotto = { text: "Lotto", hidden: true, dataIndex: "nomLtx", filterType: 'string', width: 100, cell: { cls: 'selectable' } };
        const clnIF = { text: "Impresa Ferroviaria", dataIndex: "nomCli", filterType: 'string', minWidth: 190 };  //  tdCls: 'wrap-text' 
        const clnPeriodo = { text: "Periodo", hidden: true, dataIndex: "desTipPer", filterType: 'list' };
        const clnTipoRto = { text: "Tipo RTO", dataIndex: "desTipRTO", width: 70, filterType: 'list' };
        const clnTipoVco = { text: "Tipo VCO", dataIndex: "desTipVco", width: 112, filterType: 'list' };
        const clnDataAtt = { text: "Data Att. Provvedimento", dataIndex: "datAtvPvv", type: "datecolumn", width: 83, formatter: 'date("d/m/Y")' };
        const clnDataVersione = { text: "Data Versione", dataIndex: "datVesRTO", type: "datecolumn", width: 83, formatter: 'date("d/m/Y")' };
        const clnStato = { text: "Stato", dataIndex: "ideStoRTO", filterType: 'number', align: "center", width: 65, renderer: renderColorRow };
        const clnDataPO = { text: "Data PO", dataIndex: "datPublicazionePO", type: "datecolumn", width: 83, formatter: 'date("d/m/Y")' };
        const clnNumTrenoRFI = { text: "Num.Treno RFI", dataIndex: "numTrenoRFI", filterType: 'string', width: 87, cell: { cls: 'selectable' } };
        const clnCdS = { text: "CdS", align: "center", width: 60, groupable: false, dataIndex: "tooltipCer", filterType: 'list', cell: toolTipFlagCds, renderer: () => { return ''; } };
        const clnCirc = { text: "Circ", align: "center", width: 60, groupable: false, dataIndex: "tooltipCir", filterType: 'list', cell: toolTipFlagCir, renderer: () => { return ''; } };
        const clnII = { text: "I.I.", width: 60, align: "center", dataIndex: "tooltipII", filterType: 'list', cell: toolTipFlagII, renderer: () => { return ''; } };
        const clnIncongrPO = { text: "Incongr. PO", align: "center", width: 70, dataIndex: "nomTipoInc", filterType: 'list', cell: toolTipIncongr, renderer: () => { return ''; } };
        const clnStatoInvio = { text: "Stato Invio I.I.", dataIndex: "desStatoInvio", filterType: 'list', width: 120 };
        const clnForzatura = { text: "Forzatura", align: "center", width: 60, groupable: false, dataIndex: "flagForzaturaPUB", filterType: 'boolean', cell: toolTipFlagForzaturaPUB, renderer: () => { return ''; } };
        const clnVersione = { text: "Versione", dataIndex: "desVes", filterType: 'list', width: 90 };
        const clnTipoModifica = { text: "Tipo Modifica", dataIndex: "desTipMod", filterType: 'list', width: 90 };
        //const clnTipoModifica = { text: "Tipo Modifica", dataIndex: "desTipMod", cell: azioniCronologia, filter: 'string', width: 110 };
        const clnSistema = { text: "Sistema", align: "center", width: 70, groupable: false, dataIndex: "desNazOri", filterType: 'list', cell: toolTipSistema, renderer: () => { return ''; } };
        const clnPoPubblicato = {
            text: "PO Pubblicato", dataIndex: "poPubblicato", filterType: 'string', width: 70
        }
        const clnRichiestaContract = { text: "Contratt. anticipata", dataIndex: "richiestaContract", filterType: 'list', width: 80 };
        // colonna File di Upload ...

        const gridColumsRFI = [clnAzioni, clnIF, clnLotto, clnPeriodo, clnProtocolloIF, clnDataProtocolloIF, clnProtocolloRFI, clnDataProtocolloRFI, clnStato, clnPoPubblicato, clnVersione, clnII, clnStatoInvio, clnForzatura,
            clnOrigine, clnOraPar, clnDestinazione, clnOraArr, clnNumTrenoIF, clnNumTrenoRFI, clnIncongrPO, clnSistema, clnTipoRto, clnTipoVco, clnDataAtt, clnDataVersione, clnScadenza, clnDataPO, clnCdS, clnCirc, clnTipoModifica, clnRichiestaContract];

        const gridColumsIF = [clnAzioni, clnLotto, clnPeriodo, clnProtocolloIF, clnDataProtocolloIF, clnProtocolloRFI, clnDataProtocolloRFI, clnStato, clnPoPubblicato, clnVersione, clnII, clnOrigine, clnOraPar,
            clnDestinazione, clnOraArr, clnNumTrenoIF, clnNumTrenoRFI, clnIncongrPO, clnSistema, clnTipoRto, clnTipoVco, clnDataAtt, clnDataVersione, clnScadenza, clnDataPO, clnCdS, clnCirc,
            clnTipoModifica, clnRichiestaContract];

        return isIF ? gridColumsIF : gridColumsRFI;
    }
    const [rtoListColums, setrtoListColums] = useState(colonneGridRtoList());
    //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    const getColonne = () => {
        let grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        return grid.getColumns();
    }

    const onViewOptions = () => {
        let grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        let gridViewOptionsPlugin = grid.findPlugin('gridviewoptions'); // Ottieni il riferimento al plugin GridViewOptions
        gridViewOptionsPlugin.onHeaderLongPress();
    }

    function onDownloadClick() {
        var grid = Ext.ComponentQuery.query('#gridRtoList')[0];
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var now = new Date();
        var year = now.getFullYear();
        var month = String(now.getMonth() + 1).padStart(2, '0');
        var day = String(now.getDate()).padStart(2, '0');
        var hours = String(now.getHours()).padStart(2, '0');
        var minutes = String(now.getMinutes()).padStart(2, '0');

        // Crea la stringa del titolo
        var title = 'Esporta Griglia Gestione RTO (' + day + '-' + month + '-' + year + '  ' + hours + ':' + minutes + ')';

        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }



    //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    return (
        <Container cls="container-spinner" itemId="rtoListContainer" layout="fit" FitToParent={true} >
            <Grid
                store={storeRtoList}
                itemId="gridRtoList"
                flex={1}
                scrollable={true}
                selectable={{ checkbox: true, mode: 'multi', rows: true }}
                onSelect={onGridSelect}
                onDeselect={onGridSelect}
                //plugins={['gridfilters', 'gridexporter', 'gridviewoptions']}
                plugins={['gridfilterbar', 'gridexporter', 'gridviewoptions']}
                loadingText=''
                cls="custom-grid custom-gridRTO"
                itemConfig={{ viewModel: true }}
                columns={rtoListColums}
                variableHeights={true}
            //columnLines={true}
            >
                <Toolbar docked="top" cls="actionButtonPanel">
                    {/* <Button cls="marginButton appButton" text="Esporta" ui={ui} handler={onDownloadGridClick} iconCls="x-fa fa-download" />*/}
                    <Button cls="ricercaAvanzataButton" itemId="btnTotRecord" docked="left" text={numRecordText}></Button>
                    {rowGridSelectedCount > 0 && (<Button cls="ricercaAvanzataButton" itemId="btnSelRecord" docked="left" text={numRecordTextSelected}></Button>)}
                    <Spacer />

                    <Button margin={5} cls="button-height appButton" docked="right" align="right" tooltip="Opzioni Griglia" iconCls="x-fa fa-gear" handler={onViewOptions} />
                    <Button margin={5} cls="button-height exportButton" docked="right" align="right" tooltip="Esporta Griglia" itemId="btnDownload" iconCls="x-fa fa-file-excel" handler={onDownloadClick} />

                    <Panel >
                        <Button align="right" cls="button-height appButton" text="Nuova" itemId="btnNuovo" hidden={!isIF} handler={onNuovoClick} ui={ui} margin={5} iconCls="x-fa fa-plus" />
                        <Button align="right" cls="button-height appButton" text="Apri" itemId="btnApri" ui={ui} margin={5} disabled={true} handler={onApriClick} iconCls="x-fa fa-folder-open" />
                        <Button align="right" cls="button-height appButton" text="Copia" itemId="btnCopia" hidden={!isIF} handler={onCopiaClick} ui={ui} margin={5} disabled={true} iconCls="x-fa fa-clone" />
                        {/*  <Button align="right" text="Visualizza" itemId="btnVisualizza" ui={ui} margin={5} disabled={false} handler={onVisualizzaClick} iconCls="x-fa fa-edit" /> */}
                        <Button align="right" cls="button-height appButton" text="Elimina" itemId="btnElimina" ui={ui} hidden={!isIF} margin={5} disabled={true} iconCls="x-fa fa-trash" />
                        <Button align="right" cls="button-height appButton" text="Accetta RTO Modificate" itemId="btnAccetta" ui={ui} margin={5} hidden={isRFICOMP} disabled={true} handler={onAccettaClick} iconCls="x-fa fa-check-double" />
                        <Button align="right" cls="button-height appButton" text="Rifiuta RTO Modificate" itemId="btnRifiuta" ui={ui} margin={5} hidden={isRFICOMP} disabled={true} handler={onRifiutaClick} iconCls="x-fa fa-xmark" />
                        {/* <Button align="right" cls="button-height exportButton" text="Scarica su Excel" itemId="btnDownload" ui={ui} margin={5} handler={onDownloadClick} iconCls="x-fa fa-file-excel" />*/}
                    </Panel>

                    <Button docked="right" align="right" cls="button-height appButtonDanger" text="PO" ui={uiPo} margin={5} hidden={isIF}>
                        <Menu minWidth={widthActionMenu} >
                            <MenuItem text="Aggiorna PO" hidden={isIF} itemId="mpAggiornaPO" disabled={true} handler={onMenuPoClick} iconCls="x-fa fa-arrow-rotate-right" />
                            <MenuItem text="Invia a RDI" hidden={isIF} itemId="mpInviaRDI" disabled={true} handler={onMenuPoClick} iconCls="x-fa fa-train-tram" />
                            <MenuItem text="Forza Parere RDI" hidden={isIF} itemId="mpForzaRDI" disabled={true} handler={onMenuPoClick} iconCls="x-fa fa-share" />
                            <MenuItem text="Verifica CdS e Circolabilita'" hidden={isIF} itemId="mpVerificaCdSCirc" disabled={true} handler={onMenuPoClick} iconCls="x-fa fa-certificate" />
                            <MenuItem text="Associazione OneClick" hidden={isIF} itemId="mpAssociazioneOneClick" disabled={true} handler={onMenuPoClick} iconCls="x-fa fa-wand-magic-sparkles" />
                        </Menu>
                    </Button>

                    <Button cls="button-height appButton" docked="right" align="right" text="Cambio Stato" ui={ui} margin={5} >
                        <Menu minWidth={widthActionMenu} >
                            <MenuItem text="Trasmetti RTO" hidden={!isIF} itemId="msTrasmetti" handler={onMenuStatoClick} disabled={true} iconCls="x-fa fa-arrow-right-from-bracket" />
                            <MenuItem text="Prendi in Carico" hidden={isIF} itemId="msPrendiCarico" handler={onMenuStatoClick} disabled={true} iconCls="x-fa fa-arrow-right-to-bracket" />
                            <MenuItem text="Completa PO (stato 4)" hidden={isIF || isRFICOMP} itemId="msCompletaPO" handler={onMenuStatoClick} disabled={true} iconCls="x-fa fa-square-check" />
                            <MenuItem text="Pubblica PO" hidden={isIF || isRFICOMP} itemId="msPubblicaPO" handler={onMenuStatoClick} disabled={true} iconCls="x-fa fa-arrow-right-from-bracket" />
                            <MenuItem text="Pubblica PO e Forza RDI" hidden={isIF || isRFICOMP} itemId="msPubblicaForza" handler={onMenuStatoClick} disabled={true} iconCls="x-fa fa-share-from-square" />
                            <MenuItem text="Accetta PO" hidden={!isIF} itemId="msAccettaPO" handler={onMenuStatoClick} disabled={true} iconCls="x-fa fa-square-check" />
                            <MenuItem text="Rifiuta PO" hidden={!isIF} itemId="msRifiutaPO" handler={onMenuStatoClick} disabled={true} iconCls="x-fa fa-solid fa-square-xmark" />
                            <MenuItem text="Annulla RTO" hidden={isRFICOMP} itemId="msAnnullaRTO" handler={onMenuStatoClick} disabled={true} iconCls="x-fa fa-square-minus" />
                            <MenuItem text="Richiedi Contrattualizzazione" hidden={!isIF} itemId="msRichiediContr" handler={onMenuStatoClick} disabled={true} iconCls="x-fa fa-file-signature" />
                            <MenuItem text="Contrattualizza" hidden={isIF || isRFICOMP} itemId="msContrattualizza" handler={onMenuStatoClick} disabled={true} iconCls="x-fa fa-file-signature" />
                        </Menu>
                    </Button>

                    <Button cls="button-height appButton" docked="right" align="right" text="Esporta" ui={ui} margin={5} >
                        <Menu minWidth={widthActionMenu} >
                            <MenuItem text="Dettaglio RTO" itemId="meEsportaPdf" handler={onMenuEsportaSelezioneClick} disabled={true} iconCls="x-fa fa-file-pdf" />
                            <MenuItem text="Excel" itemId="meEsportaExcel" handler={onMenuEsportaExcelIfClick} disabled={true} iconCls="x-fa fa-file-excel" />
                            <MenuItem text="Sintesi" hidden={isIF} itemId="meEsportaSintesi" handler={onMenuEsportaClick} disabled={true} iconCls="x-fa fa-file-excel" />
                        </Menu>
                    </Button >

                </Toolbar>


            </Grid>

            {popupModifiche.showPopup && <Dialog
                draggable={true}
                displayed={true}
                layout='fit'
                minWidth='40%'
                minHeight='35%'
                maxWidth='70%'
                maxHeight='60%'
                centered={true}
                scrollable={true}
                itemId='dialogModifiche'
            >
                <PopUpModifiche data={popupModifiche.data} htmlTesto={popupModifiche.htmlTesto} accettaModificaRto={accettaModificaRto} rifiutaModificaRto={rifiutaModificaRto} chiudiPopup={chiudiPopupModifiche} />
            </Dialog>}

            {popupRtoDaPo.showPopup && <Dialog
                displayed={true}
                layout='fit'
                minWidth='40%'
                minHeight='35%'
                itemId='dialogCreaRtoDaPO'
            >
                <PopUpCreaRtoDaPo data={popupRtoDaPo.data} storeListaPO={storeListaPO} chiudiPopup={chiudiPopupCreaRtoDaPo} onModificaRtoDaPoRefresh={onModificaRtoDaPoRefresh} />
            </Dialog>}

            {popupAzioni.showPopup && <Dialog
                draggable={true}
                displayed={true}
                layout='fit'
                minWidth='40%'
                minHeight='35%'
                maxWidth='70%'
                maxHeight='60%'
                centered={true}
                scrollable={true}
                itemId='dialogOperazioni'
            >
                <PopUpOperazioni azione={popupAzioni.azione} data={popupAzioni.data} chiudiPopup={chiudiPopupAzioni} isIF={isIF} />
            </Dialog>}

            {showDialogDetailRTO.showDialogDetailRTO && <Dialog
                displayed={true}
                //title="Dettaglio RTO"
                //closable="true"
                //closeAction="hide"
                maximized={true}
                itemId='dialogDetailRTO'
            //maximizable={true}
            //onHide={() => setShowDialogDetailRTO({ showDialogDetailRTO: false })}
            >
                <SchedaRto parRto={paramsDetail} hideDialogDetailRTO={hideDialogDetailRTO} RefreshRtoList={RefreshRtoList} setParamsDetail={setParamsDetail} />
            </Dialog>
            }

        </Container>

    );
}

export default RtoList;
