import './Rto.css';

//#region Stati
export const svg_0 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1242.317 222.895">
<rect id="Rectangle_2258" data-name="Rectangle 2258" width="97.317" height="97.32" transform="translate(383.949 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2262" data-name="Rectangle 2262" width="97.317" height="97.32" transform="translate(763.5 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2264" data-name="Rectangle 2264" width="97.317" height="97.32" transform="translate(955 124.196)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2259" data-name="Rectangle 2259" width="97.317" height="97.32" transform="translate(192.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(241.08 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2257" data-name="Rectangle 2257" width="97.317" height="97.32" transform="translate(2.5 2.5)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<text id="_0" data-name="0" transform="translate(52 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2260" data-name="Rectangle 2260" width="97.317" height="97.32" transform="translate(383.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(432.51 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(432.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(812.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1003.5 200.895)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2263" data-name="Rectangle 2263" width="97.317" height="97.32" transform="translate(763.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(811.934 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2265" data-name="Rectangle 2265" width="97.317" height="97.32" transform="translate(954.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1003.49 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2266" data-name="Rectangle 2266" width="97.317" height="97.32" transform="translate(1144.5 2.5)" fill="#dbeeff" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1193.149 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2261" data-name="Rectangle 2261" width="97.317" height="97.32" transform="translate(573.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(620.55 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_913" data-name="Line 913" x2="77.69" transform="translate(107 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_914" data-name="Line 914" x2="77.69" transform="translate(488 50.379)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_917" data-name="Line 917" x2="77.69" transform="translate(678 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_918" data-name="Line 918" x2="77.69" transform="translate(869 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_922" data-name="Line 922" x2="77.69" transform="translate(1059 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_919" data-name="Line 919" x2="77.69" transform="translate(869 172.856)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_915" data-name="Line 915" y2="10.957" transform="translate(432.874 106.129)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_920" data-name="Line 920" x1="0.103" y2="10.849" transform="translate(812.897 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_921" data-name="Line 921" y2="11" transform="translate(1003 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2127" data-name="Path 2127" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9999.321 -1625.266)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2128" data-name="Path 2128" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10129.272 -1624.762)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2129" data-name="Path 2129" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10129.402 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2130" data-name="Path 2130" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10325.057 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_916" data-name="Line 916" x2="77.69" transform="translate(298 51.16)" fill="none" stroke="#919191" stroke-width="1"/>
</svg>

)
export const svg_1 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 222.895">
<rect id="Rectangle_2250" data-name="Rectangle 2250" width="97.317" height="97.32" transform="translate(381.949 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2251" data-name="Rectangle 2251" width="97.317" height="97.32" transform="translate(761.5 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2252" data-name="Rectangle 2252" width="97.317" height="97.32" transform="translate(953 124.196)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2255" data-name="Rectangle 2255" width="97.317" height="97.32" transform="translate(190.5 2.5)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<text id="_1" data-name="1" transform="translate(239.08 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2256" data-name="Rectangle 2256" width="97.317" height="97.32" transform="translate(0.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2253" data-name="Rectangle 2253" width="97.317" height="97.32" transform="translate(381.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.895)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2249" data-name="Rectangle 2249" width="97.317" height="97.32" transform="translate(761.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2247" data-name="Rectangle 2247" width="97.317" height="97.32" transform="translate(952.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2248" data-name="Rectangle 2248" width="97.317" height="97.32" transform="translate(1142.5 2.5)" fill="#dbeeff" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2254" data-name="Rectangle 2254" width="97.317" height="97.32" transform="translate(571.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_912" data-name="Line 912" x2="77.69" transform="translate(105 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_907" data-name="Line 907" x2="77.69" transform="translate(486 50.379)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_908" data-name="Line 908" x2="77.69" transform="translate(676 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_903" data-name="Line 903" x2="77.69" transform="translate(867 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_904" data-name="Line 904" x2="77.69" transform="translate(1057 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_909" data-name="Line 909" x2="77.69" transform="translate(867 172.856)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_910" data-name="Line 910" y2="10.957" transform="translate(430.874 106.129)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_905" data-name="Line 905" x1="0.103" y2="10.849" transform="translate(810.897 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_906" data-name="Line 906" y2="11" transform="translate(1001 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2126" data-name="Path 2126" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.266)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2124" data-name="Path 2124" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1624.762)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2125" data-name="Path 2125" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2123" data-name="Path 2123" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_911" data-name="Line 911" x2="77.69" transform="translate(296 51.16)" fill="none" stroke="#919191" stroke-width="1"/>
</svg>

)
export const svg_2 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 222.895">
<rect id="Rectangle_2240" data-name="Rectangle 2240" width="97.317" height="97.32" transform="translate(381.949 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2242" data-name="Rectangle 2242" width="97.317" height="97.32" transform="translate(761.5 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2243" data-name="Rectangle 2243" width="97.317" height="97.32" transform="translate(953 124.196)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2238" data-name="Rectangle 2238" width="97.317" height="97.32" transform="translate(190.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.08 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2237" data-name="Rectangle 2237" width="97.317" height="97.32" transform="translate(0.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2239" data-name="Rectangle 2239" width="97.317" height="97.32" transform="translate(381.5 2.5)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<text id="_2" data-name="2" transform="translate(430.51 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.895)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2244" data-name="Rectangle 2244" width="97.317" height="97.32" transform="translate(761.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2245" data-name="Rectangle 2245" width="97.317" height="97.32" transform="translate(952.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2246" data-name="Rectangle 2246" width="97.317" height="97.32" transform="translate(1142.5 2.5)" fill="#dbeeff" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2241" data-name="Rectangle 2241" width="97.317" height="97.32" transform="translate(571.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_893" data-name="Line 893" x2="77.69" transform="translate(105 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_894" data-name="Line 894" x2="77.69" transform="translate(486 50.379)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_897" data-name="Line 897" x2="77.69" transform="translate(676 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_898" data-name="Line 898" x2="77.69" transform="translate(867 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_899" data-name="Line 899" x2="77.69" transform="translate(1057 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_900" data-name="Line 900" x2="77.69" transform="translate(867 172.856)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_895" data-name="Line 895" y2="10.957" transform="translate(430.874 106.129)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_901" data-name="Line 901" x1="0.103" y2="10.849" transform="translate(810.897 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_902" data-name="Line 902" y2="11" transform="translate(1001 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2119" data-name="Path 2119" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.266)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2120" data-name="Path 2120" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1624.762)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2121" data-name="Path 2121" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2122" data-name="Path 2122" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_896" data-name="Line 896" x2="77.69" transform="translate(296 51.16)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_3_013 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 223.517">
<rect id="Rectangle_2229" data-name="Rectangle 2229" width="97.317" height="97.32" transform="translate(381.949 123.696)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<rect id="Rectangle_2232" data-name="Rectangle 2232" width="97.317" height="97.32" transform="translate(761.5 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2234" data-name="Rectangle 2234" width="97.317" height="97.32" transform="translate(953 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2227" data-name="Rectangle 2227" width="97.317" height="97.32" transform="translate(190.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.08 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2228" data-name="Rectangle 2228" width="97.317" height="97.32" transform="translate(0.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2230" data-name="Rectangle 2230" width="97.317" height="97.32" transform="translate(381.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.395)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.395)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.395)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2233" data-name="Rectangle 2233" width="97.317" height="97.32" transform="translate(761.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2235" data-name="Rectangle 2235" width="97.317" height="97.32" transform="translate(952.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2236" data-name="Rectangle 2236" width="97.317" height="97.32" transform="translate(1142.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2231" data-name="Rectangle 2231" width="97.317" height="97.32" transform="translate(571.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_882" data-name="Line 882" x2="77.69" transform="translate(105 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_886" data-name="Line 886" x2="77.69" transform="translate(486 49.879)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_887" data-name="Line 887" x2="77.69" transform="translate(676 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_889" data-name="Line 889" x2="77.69" transform="translate(867 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_892" data-name="Line 892" x2="77.69" transform="translate(1057 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_890" data-name="Line 890" x2="77.69" transform="translate(867 172.356)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_884" data-name="Line 884" y2="10.957" transform="translate(430.874 105.629)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_888" data-name="Line 888" x1="0.103" y2="10.849" transform="translate(810.897 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_891" data-name="Line 891" y2="11" transform="translate(1001 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2115" data-name="Path 2115" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.766)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<path id="Path_2116" data-name="Path 2116" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1625.262)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2117" data-name="Path 2117" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2118" data-name="Path 2118" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_885" data-name="Line 885" x2="77.69" transform="translate(296 50.66)" fill="none" stroke="#919191" stroke-width="1"/>
</svg>

)
export const svg_3_0123 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 223.517">
<rect id="Rectangle_2219" data-name="Rectangle 2219" width="97.317" height="97.32" transform="translate(381.949 123.696)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<rect id="Rectangle_2222" data-name="Rectangle 2222" width="97.317" height="97.32" transform="translate(761.5 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2224" data-name="Rectangle 2224" width="97.317" height="97.32" transform="translate(953 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2218" data-name="Rectangle 2218" width="97.317" height="97.32" transform="translate(190.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.08 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2217" data-name="Rectangle 2217" width="97.317" height="97.32" transform="translate(0.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2220" data-name="Rectangle 2220" width="97.317" height="97.32" transform="translate(381.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.395)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.395)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.395)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2223" data-name="Rectangle 2223" width="97.317" height="97.32" transform="translate(761.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2225" data-name="Rectangle 2225" width="97.317" height="97.32" transform="translate(952.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2226" data-name="Rectangle 2226" width="97.317" height="97.32" transform="translate(1142.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2221" data-name="Rectangle 2221" width="97.317" height="97.32" transform="translate(571.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_871" data-name="Line 871" x2="77.69" transform="translate(105 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_872" data-name="Line 872" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_874" data-name="Line 874" x2="77.69" transform="translate(486 49.879)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_875" data-name="Line 875" x2="77.69" transform="translate(676 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_877" data-name="Line 877" x2="77.69" transform="translate(867 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_881" data-name="Line 881" x2="77.69" transform="translate(1057 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_878" data-name="Line 878" x2="77.69" transform="translate(867 172.356)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_876" data-name="Line 876" y2="10.957" transform="translate(430.874 105.629)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_879" data-name="Line 879" x1="0.103" y2="10.849" transform="translate(810.897 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_880" data-name="Line 880" y2="11" transform="translate(1001 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2111" data-name="Path 2111" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.766)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2112" data-name="Path 2112" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1625.262)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2113" data-name="Path 2113" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2114" data-name="Path 2114" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_873" data-name="Line 873" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_3_01243 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 223.517">
<rect id="Rectangle_2208" data-name="Rectangle 2208" width="97.317" height="97.32" transform="translate(381.949 123.696)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<rect id="Rectangle_2212" data-name="Rectangle 2212" width="97.317" height="97.32" transform="translate(761.5 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2214" data-name="Rectangle 2214" width="97.317" height="97.32" transform="translate(953 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2209" data-name="Rectangle 2209" width="97.317" height="97.32" transform="translate(190.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.08 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2207" data-name="Rectangle 2207" width="97.317" height="97.32" transform="translate(0.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2210" data-name="Rectangle 2210" width="97.317" height="97.32" transform="translate(381.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.395)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.395)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.395)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2213" data-name="Rectangle 2213" width="97.317" height="97.32" transform="translate(761.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2215" data-name="Rectangle 2215" width="97.317" height="97.32" transform="translate(952.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2216" data-name="Rectangle 2216" width="97.317" height="97.32" transform="translate(1142.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2211" data-name="Rectangle 2211" width="97.317" height="97.32" transform="translate(571.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_860" data-name="Line 860" x2="77.69" transform="translate(105 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_861" data-name="Line 861" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_863" data-name="Line 863" x2="77.69" transform="translate(486 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_864" data-name="Line 864" x2="77.69" transform="translate(676 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_867" data-name="Line 867" x2="77.69" transform="translate(867 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_870" data-name="Line 870" x2="77.69" transform="translate(1057 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_868" data-name="Line 868" x2="77.69" transform="translate(867 172.356)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_865" data-name="Line 865" y2="10.957" transform="translate(430.874 105.629)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_866" data-name="Line 866" x1="0.103" y2="10.849" transform="translate(810.897 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_869" data-name="Line 869" y2="11" transform="translate(1001 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2107" data-name="Path 2107" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.766)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2108" data-name="Path 2108" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1625.262)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<path id="Path_2109" data-name="Path 2109" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2110" data-name="Path 2110" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_862" data-name="Line 862" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>
)
export const svg_3_012453 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 223.517">
<rect id="Rectangle_2199" data-name="Rectangle 2199" width="97.317" height="97.32" transform="translate(381.949 123.696)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<rect id="Rectangle_2202" data-name="Rectangle 2202" width="97.317" height="97.32" transform="translate(761.5 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2203" data-name="Rectangle 2203" width="97.317" height="97.32" transform="translate(953 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2197" data-name="Rectangle 2197" width="97.317" height="97.32" transform="translate(190.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.08 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2198" data-name="Rectangle 2198" width="97.317" height="97.32" transform="translate(0.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2200" data-name="Rectangle 2200" width="97.317" height="97.32" transform="translate(381.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.395)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.395)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.395)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2204" data-name="Rectangle 2204" width="97.317" height="97.32" transform="translate(761.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2205" data-name="Rectangle 2205" width="97.317" height="97.32" transform="translate(952.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2206" data-name="Rectangle 2206" width="97.317" height="97.32" transform="translate(1142.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2201" data-name="Rectangle 2201" width="97.317" height="97.32" transform="translate(571.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_849" data-name="Line 849" x2="77.69" transform="translate(105 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_850" data-name="Line 850" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_851" data-name="Line 851" x2="77.69" transform="translate(486 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_852" data-name="Line 852" x2="77.69" transform="translate(676 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_855" data-name="Line 855" x2="77.69" transform="translate(867 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_859" data-name="Line 859" x2="77.69" transform="translate(1057 49.879)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_856" data-name="Line 856" x2="77.69" transform="translate(867 172.356)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_853" data-name="Line 853" y2="10.957" transform="translate(430.874 105.629)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_857" data-name="Line 857" x1="0.103" y2="10.849" transform="translate(810.897 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_858" data-name="Line 858" y2="11" transform="translate(1001 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2103" data-name="Path 2103" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.766)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2104" data-name="Path 2104" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1625.262)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2105" data-name="Path 2105" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.918)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<path id="Path_2106" data-name="Path 2106" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_854" data-name="Line 854" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_4 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 222.895">
<rect id="Rectangle_2188" data-name="Rectangle 2188" width="97.317" height="97.32" transform="translate(381.949 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2191" data-name="Rectangle 2191" width="97.317" height="97.32" transform="translate(761.5 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2192" data-name="Rectangle 2192" width="97.317" height="97.32" transform="translate(953 124.196)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2189" data-name="Rectangle 2189" width="97.317" height="97.32" transform="translate(190.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.08 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2187" data-name="Rectangle 2187" width="97.317" height="97.32" transform="translate(0.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2190" data-name="Rectangle 2190" width="97.317" height="97.32" transform="translate(381.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.895)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2193" data-name="Rectangle 2193" width="97.317" height="97.32" transform="translate(761.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2194" data-name="Rectangle 2194" width="97.317" height="97.32" transform="translate(952.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2196" data-name="Rectangle 2196" width="97.317" height="97.32" transform="translate(1142.5 2.5)" fill="#dbeeff" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2195" data-name="Rectangle 2195" width="97.317" height="97.32" transform="translate(571.5 2.5)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<text id="_4" data-name="4" transform="translate(618.55 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_838" data-name="Line 838" x2="77.69" transform="translate(105 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_839" data-name="Line 839" x2="77.69" transform="translate(296 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_841" data-name="Line 841" x2="77.69" transform="translate(486 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_842" data-name="Line 842" x2="77.69" transform="translate(676 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_843" data-name="Line 843" x2="77.69" transform="translate(867 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_844" data-name="Line 844" x2="77.69" transform="translate(1057 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_845" data-name="Line 845" x2="77.69" transform="translate(867 172.856)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_846" data-name="Line 846" y2="10.957" transform="translate(430.874 106.129)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_847" data-name="Line 847" x1="0.103" y2="10.849" transform="translate(810.897 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_848" data-name="Line 848" y2="11" transform="translate(1001 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2099" data-name="Path 2099" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.266)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2100" data-name="Path 2100" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1624.762)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2101" data-name="Path 2101" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2102" data-name="Path 2102" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_840" data-name="Line 840" x2="77.69" transform="translate(296 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>
)
export const svg_5 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 222.895">
<rect id="Rectangle_2183" data-name="Rectangle 2183" width="97.317" height="97.32" transform="translate(381.949 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2179" data-name="Rectangle 2179" width="97.317" height="97.32" transform="translate(761.5 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2180" data-name="Rectangle 2180" width="97.317" height="97.32" transform="translate(953 124.196)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2184" data-name="Rectangle 2184" width="97.317" height="97.32" transform="translate(190.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.08 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2186" data-name="Rectangle 2186" width="97.317" height="97.32" transform="translate(0.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2185" data-name="Rectangle 2185" width="97.317" height="97.32" transform="translate(381.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.895)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2181" data-name="Rectangle 2181" width="97.317" height="97.32" transform="translate(761.5 2.5)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<text id="_5" data-name="5" transform="translate(809.934 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2177" data-name="Rectangle 2177" width="97.317" height="97.32" transform="translate(952.5 2.5)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2178" data-name="Rectangle 2178" width="97.317" height="97.32" transform="translate(1142.5 2.5)" fill="#dbeeff" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2182" data-name="Rectangle 2182" width="97.317" height="97.32" transform="translate(571.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_837" data-name="Line 837" x2="77.69" transform="translate(105 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_834" data-name="Line 834" x2="77.69" transform="translate(296 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_833" data-name="Line 833" x2="77.69" transform="translate(486 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_827" data-name="Line 827" x2="77.69" transform="translate(676 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_828" data-name="Line 828" x2="77.69" transform="translate(867 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_829" data-name="Line 829" x2="77.69" transform="translate(1057 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_830" data-name="Line 830" x2="77.69" transform="translate(867 172.856)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_835" data-name="Line 835" y2="10.957" transform="translate(430.874 106.129)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_831" data-name="Line 831" x1="0.103" y2="10.849" transform="translate(810.897 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_832" data-name="Line 832" y2="11" transform="translate(1001 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2098" data-name="Path 2098" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.266)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2097" data-name="Path 2097" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1624.762)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2095" data-name="Path 2095" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2096" data-name="Path 2096" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_836" data-name="Line 836" x2="77.69" transform="translate(296 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_6_012456 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 222.895">
<rect id="Rectangle_2169" data-name="Rectangle 2169" width="97.317" height="97.32" transform="translate(381.949 124.196)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2172" data-name="Rectangle 2172" width="97.317" height="97.32" transform="translate(761.5 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2174" data-name="Rectangle 2174" width="97.317" height="97.32" transform="translate(953 124.196)" fill="none" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2168" data-name="Rectangle 2168" width="97.317" height="97.32" transform="translate(190.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.08 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2167" data-name="Rectangle 2167" width="97.317" height="97.32" transform="translate(0.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2170" data-name="Rectangle 2170" width="97.317" height="97.32" transform="translate(381.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.895)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2173" data-name="Rectangle 2173" width="97.317" height="97.32" transform="translate(761.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2175" data-name="Rectangle 2175" width="97.317" height="97.32" transform="translate(952.5 2.5)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<text id="_6" data-name="6" transform="translate(1001.49 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2176" data-name="Rectangle 2176" width="97.317" height="97.32" transform="translate(1142.5 2.5)" fill="#dbeeff" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2171" data-name="Rectangle 2171" width="97.317" height="97.32" transform="translate(571.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_816" data-name="Line 816" x2="77.69" transform="translate(105 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_817" data-name="Line 817" x2="77.69" transform="translate(296 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_818" data-name="Line 818" x2="77.69" transform="translate(486 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_821" data-name="Line 821" x2="77.69" transform="translate(676 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_823" data-name="Line 823" x2="77.69" transform="translate(867 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_826" data-name="Line 826" x2="77.69" transform="translate(1057 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_824" data-name="Line 824" x2="77.69" transform="translate(867 172.856)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_819" data-name="Line 819" y2="10.957" transform="translate(430.874 106.129)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_822" data-name="Line 822" x1="0.103" y2="10.849" transform="translate(810.897 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_825" data-name="Line 825" y2="11" transform="translate(1001 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2091" data-name="Path 2091" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.266)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2092" data-name="Path 2092" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1624.762)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2093" data-name="Path 2093" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2094" data-name="Path 2094" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_820" data-name="Line 820" x2="77.69" transform="translate(296 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_6_01245686 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 222.895">
<rect id="Rectangle_2157" data-name="Rectangle 2157" width="97.317" height="97.32" transform="translate(381.949 124.196)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2158" data-name="Rectangle 2158" width="97.317" height="97.32" transform="translate(761.5 124.196)" fill="#ffdbdb" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2159" data-name="Rectangle 2159" width="97.317" height="97.32" transform="translate(953 124.196)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2160" data-name="Rectangle 2160" width="97.317" height="97.32" transform="translate(190.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.08 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2161" data-name="Rectangle 2161" width="97.317" height="97.32" transform="translate(0.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2162" data-name="Rectangle 2162" width="97.317" height="97.32" transform="translate(381.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.895)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.895)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2163" data-name="Rectangle 2163" width="97.317" height="97.32" transform="translate(761.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2164" data-name="Rectangle 2164" width="97.317" height="97.32" transform="translate(952.5 2.5)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<text id="_6" data-name="6" transform="translate(1001.49 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2165" data-name="Rectangle 2165" width="97.317" height="97.32" transform="translate(1142.5 2.5)" fill="#dbeeff" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77.5)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2166" data-name="Rectangle 2166" width="97.317" height="97.32" transform="translate(571.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_805" data-name="Line 805" x2="77.69" transform="translate(105 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_806" data-name="Line 806" x2="77.69" transform="translate(296 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_807" data-name="Line 807" x2="77.69" transform="translate(486 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_808" data-name="Line 808" x2="77.69" transform="translate(676 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_809" data-name="Line 809" x2="77.69" transform="translate(867 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_810" data-name="Line 810" x2="77.69" transform="translate(1057 50.379)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_811" data-name="Line 811" x2="77.69" transform="translate(867 172.856)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_812" data-name="Line 812" y2="10.957" transform="translate(430.874 106.129)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_813" data-name="Line 813" x1="0.103" y2="10.849" transform="translate(810.897 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_814" data-name="Line 814" y2="11" transform="translate(1001 106.237)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<path id="Path_2087" data-name="Path 2087" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.266)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2088" data-name="Path 2088" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1624.762)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2089" data-name="Path 2089" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2090" data-name="Path 2090" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_815" data-name="Line 815" x2="77.69" transform="translate(296 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_7_012457 = (<svg xmlns="http://www.w3.org/2000/svg"class="status-svg" width="400" viewBox="0 0 1240.317 223.517">
<rect id="Rectangle_2148" data-name="Rectangle 2148" width="97.317" height="97.32" transform="translate(381.949 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2152" data-name="Rectangle 2152" width="97.317" height="97.32" transform="translate(761.5 123.696)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<rect id="Rectangle_2153" data-name="Rectangle 2153" width="97.317" height="97.32" transform="translate(953 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2149" data-name="Rectangle 2149" width="97.317" height="97.32" transform="translate(190.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.081 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2147" data-name="Rectangle 2147" width="97.317" height="97.32" transform="translate(0.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2150" data-name="Rectangle 2150" width="97.317" height="97.32" transform="translate(381.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.394)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.394)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.394)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2154" data-name="Rectangle 2154" width="97.317" height="97.32" transform="translate(761.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2155" data-name="Rectangle 2155" width="97.317" height="97.32" transform="translate(952.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2156" data-name="Rectangle 2156" width="97.317" height="97.32" transform="translate(1142.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2151" data-name="Rectangle 2151" width="97.317" height="97.32" transform="translate(571.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_794" data-name="Line 794" x2="77.69" transform="translate(105 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_795" data-name="Line 795" x2="77.69" transform="translate(296 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_796" data-name="Line 796" x2="77.69" transform="translate(486 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_799" data-name="Line 799" x2="77.69" transform="translate(676 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_800" data-name="Line 800" x2="77.69" transform="translate(867 49.878)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_804" data-name="Line 804" x2="77.69" transform="translate(1057 49.878)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_801" data-name="Line 801" x2="77.69" transform="translate(867 172.356)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_797" data-name="Line 797" y2="10.957" transform="translate(430.874 105.629)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_802" data-name="Line 802" x1="0.103" y2="10.849" transform="translate(810.897 105.737)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_803" data-name="Line 803" y2="11" transform="translate(1001 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2083" data-name="Path 2083" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.766)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2084" data-name="Path 2084" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1625.262)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2085" data-name="Path 2085" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2086" data-name="Path 2086" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_798" data-name="Line 798" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_7_0124567 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 223.517">
<rect id="Rectangle_2143" data-name="Rectangle 2143" width="97.317" height="97.32" transform="translate(381.949 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2139" data-name="Rectangle 2139" width="97.317" height="97.32" transform="translate(761.5 123.696)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<rect id="Rectangle_2140" data-name="Rectangle 2140" width="97.317" height="97.32" transform="translate(953 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2145" data-name="Rectangle 2145" width="97.317" height="97.32" transform="translate(190.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.081 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2146" data-name="Rectangle 2146" width="97.317" height="97.32" transform="translate(0.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2144" data-name="Rectangle 2144" width="97.317" height="97.32" transform="translate(381.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.394)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.394)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.394)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2141" data-name="Rectangle 2141" width="97.317" height="97.32" transform="translate(761.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2137" data-name="Rectangle 2137" width="97.317" height="97.32" transform="translate(952.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2138" data-name="Rectangle 2138" width="97.317" height="97.32" transform="translate(1142.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2142" data-name="Rectangle 2142" width="97.317" height="97.32" transform="translate(571.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_793" data-name="Line 793" x2="77.69" transform="translate(105 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_791" data-name="Line 791" x2="77.69" transform="translate(296 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_789" data-name="Line 789" x2="77.69" transform="translate(486 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_783" data-name="Line 783" x2="77.69" transform="translate(676 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_784" data-name="Line 784" x2="77.69" transform="translate(867 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_785" data-name="Line 785" x2="77.69" transform="translate(1057 49.878)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_788" data-name="Line 788" x2="77.69" transform="translate(867 172.356)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_790" data-name="Line 790" y2="10.957" transform="translate(430.874 105.629)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_786" data-name="Line 786" x1="0.103" y2="10.849" transform="translate(810.897 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_787" data-name="Line 787" y2="11" transform="translate(1001 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2082" data-name="Path 2082" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.766)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2081" data-name="Path 2081" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1625.262)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2079" data-name="Path 2079" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2080" data-name="Path 2080" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.918)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_792" data-name="Line 792" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_7_01245687 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 223.517">
<rect id="Rectangle_2118" data-name="Rectangle 2118" width="97.317" height="97.32" transform="translate(381.949 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2121" data-name="Rectangle 2121" width="97.317" height="97.32" transform="translate(761.5 123.696)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<rect id="Rectangle_2122" data-name="Rectangle 2122" width="97.317" height="97.32" transform="translate(953 123.696)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2119" data-name="Rectangle 2119" width="97.317" height="97.32" transform="translate(190.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.081 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2117" data-name="Rectangle 2117" width="97.317" height="97.32" transform="translate(0.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2120" data-name="Rectangle 2120" width="97.317" height="97.32" transform="translate(381.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.394)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.394)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.394)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2123" data-name="Rectangle 2123" width="97.317" height="97.32" transform="translate(761.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2124" data-name="Rectangle 2124" width="97.317" height="97.32" transform="translate(952.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2126" data-name="Rectangle 2126" width="97.317" height="97.32" transform="translate(1142.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2125" data-name="Rectangle 2125" width="97.317" height="97.32" transform="translate(571.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_761" data-name="Line 761" x2="77.69" transform="translate(105 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_762" data-name="Line 762" x2="77.69" transform="translate(296 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_763" data-name="Line 763" x2="77.69" transform="translate(486 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_766" data-name="Line 766" x2="77.69" transform="translate(676 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_767" data-name="Line 767" x2="77.69" transform="translate(867 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_771" data-name="Line 771" x2="77.69" transform="translate(1057 49.878)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_768" data-name="Line 768" x2="77.69" transform="translate(867 172.356)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_764" data-name="Line 764" y2="10.957" transform="translate(430.874 105.629)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_769" data-name="Line 769" x1="0.103" y2="10.849" transform="translate(810.897 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_770" data-name="Line 770" y2="11" transform="translate(1001 105.737)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<path id="Path_2071" data-name="Path 2071" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.32 -1625.766)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2072" data-name="Path 2072" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1625.262)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2073" data-name="Path 2073" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.401 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2074" data-name="Path 2074" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_765" data-name="Line 765" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_7_012456867 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 223.517">
<rect id="Rectangle_2130" data-name="Rectangle 2130" width="97.317" height="97.32" transform="translate(381.949 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2131" data-name="Rectangle 2131" width="97.317" height="97.32" transform="translate(761.5 123.696)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<rect id="Rectangle_2132" data-name="Rectangle 2132" width="97.317" height="97.32" transform="translate(953 123.696)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2135" data-name="Rectangle 2135" width="97.317" height="97.32" transform="translate(190.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.081 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2136" data-name="Rectangle 2136" width="97.317" height="97.32" transform="translate(0.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2133" data-name="Rectangle 2133" width="97.317" height="97.32" transform="translate(381.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.394)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.394)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.394)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2127" data-name="Rectangle 2127" width="97.317" height="97.32" transform="translate(761.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2128" data-name="Rectangle 2128" width="97.317" height="97.32" transform="translate(952.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2129" data-name="Rectangle 2129" width="97.317" height="97.32" transform="translate(1142.5 2)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2134" data-name="Rectangle 2134" width="97.317" height="97.32" transform="translate(571.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_782" data-name="Line 782" x2="77.69" transform="translate(105 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_779" data-name="Line 779" x2="77.69" transform="translate(296 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_772" data-name="Line 772" x2="77.69" transform="translate(486 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_773" data-name="Line 773" x2="77.69" transform="translate(676 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_774" data-name="Line 774" x2="77.69" transform="translate(867 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_775" data-name="Line 775" x2="77.69" transform="translate(1057 49.878)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_776" data-name="Line 776" x2="77.69" transform="translate(867 172.356)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_780" data-name="Line 780" y2="10.957" transform="translate(430.874 105.629)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_777" data-name="Line 777" x1="0.103" y2="10.849" transform="translate(810.897 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_778" data-name="Line 778" y2="11" transform="translate(1001 105.737)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<path id="Path_2078" data-name="Path 2078" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.321 -1625.766)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2075" data-name="Path 2075" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1625.262)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2076" data-name="Path 2076" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.402 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2077" data-name="Path 2077" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.918)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_781" data-name="Line 781" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_8 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1240.317 223.517">
<rect id="Rectangle_2109" data-name="Rectangle 2109" width="97.317" height="97.32" transform="translate(381.949 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2108" data-name="Rectangle 2108" width="97.317" height="97.32" transform="translate(761.5 123.696)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2107" data-name="Rectangle 2107" width="97.317" height="97.32" transform="translate(953 123.696)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<rect id="Rectangle_2115" data-name="Rectangle 2115" width="97.317" height="97.32" transform="translate(190.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.081 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2116" data-name="Rectangle 2116" width="97.317" height="97.32" transform="translate(0.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2110" data-name="Rectangle 2110" width="97.317" height="97.32" transform="translate(381.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.394)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.394)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.394)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2111" data-name="Rectangle 2111" width="97.317" height="97.32" transform="translate(761.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2112" data-name="Rectangle 2112" width="97.317" height="97.32" transform="translate(952.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2113" data-name="Rectangle 2113" width="97.317" height="97.32" transform="translate(1142.5 2)" fill="#dbeeff" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_9" data-name="9" transform="translate(1191.149 77)" fill="#5fa2dd" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2114" data-name="Rectangle 2114" width="97.317" height="97.32" transform="translate(571.5 2)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_760" data-name="Line 760" x2="77.69" transform="translate(105 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_754" data-name="Line 754" x2="77.69" transform="translate(296 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_755" data-name="Line 755" x2="77.69" transform="translate(486 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_756" data-name="Line 756" x2="77.69" transform="translate(676 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_757" data-name="Line 757" x2="77.69" transform="translate(867 49.878)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_758" data-name="Line 758" x2="77.69" transform="translate(1057 49.878)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_750" data-name="Line 750" x2="77.69" transform="translate(867 172.356)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_751" data-name="Line 751" y2="10.957" transform="translate(430.874 105.629)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_752" data-name="Line 752" x1="0.103" y2="10.849" transform="translate(810.897 105.737)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_753" data-name="Line 753" y2="11" transform="translate(1001 105.737)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<path id="Path_2070" data-name="Path 2070" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.32 -1625.766)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2068" data-name="Path 2068" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1625.262)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2067" data-name="Path 2067" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.401 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2069" data-name="Path 2069" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.918)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_759" data-name="Line 759" x2="77.69" transform="translate(296 49.879)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_9_0124569 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400"  viewBox="0 0 1242.317 222.894">
<rect id="Rectangle_2099" data-name="Rectangle 2099" width="97.317" height="97.32" transform="translate(381.949 124.196)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2100" data-name="Rectangle 2100" width="97.317" height="97.32" transform="translate(761.5 124.196)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2101" data-name="Rectangle 2101" width="97.317" height="97.32" transform="translate(953 124.196)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2105" data-name="Rectangle 2105" width="97.317" height="97.32" transform="translate(190.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.081 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2106" data-name="Rectangle 2106" width="97.317" height="97.32" transform="translate(0.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2102" data-name="Rectangle 2102" width="97.317" height="97.32" transform="translate(381.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.894)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.894)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.894)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2103" data-name="Rectangle 2103" width="97.317" height="97.32" transform="translate(761.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2097" data-name="Rectangle 2097" width="97.317" height="97.32" transform="translate(952.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2098" data-name="Rectangle 2098" width="97.317" height="97.32" transform="translate(1142.5 2.5)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<text id="_9" data-name="9" transform="translate(1191.149 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2104" data-name="Rectangle 2104" width="97.317" height="97.32" transform="translate(571.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_749" data-name="Line 749" x2="77.69" transform="translate(105 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_739" data-name="Line 739" x2="77.69" transform="translate(296 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_740" data-name="Line 740" x2="77.69" transform="translate(486 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_741" data-name="Line 741" x2="77.69" transform="translate(676 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_742" data-name="Line 742" x2="77.69" transform="translate(867 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_743" data-name="Line 743" x2="77.69" transform="translate(1057 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_744" data-name="Line 744" x2="77.69" transform="translate(867 172.856)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_745" data-name="Line 745" y2="10.957" transform="translate(430.874 106.129)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_746" data-name="Line 746" x1="0.103" y2="10.849" transform="translate(810.897 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_747" data-name="Line 747" y2="11" transform="translate(1001 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2063" data-name="Path 2063" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.32 -1625.266)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_2064" data-name="Path 2064" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1624.762)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2065" data-name="Path 2065" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.401 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_2066" data-name="Path 2066" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_748" data-name="Line 748" x2="77.69" transform="translate(296 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
export const svg_9_01245689 = (<svg xmlns="http://www.w3.org/2000/svg" class="status-svg" width="400" viewBox="0 0 1242.317 222.894">
<rect id="Rectangle_2046" data-name="Rectangle 2046" width="97.317" height="97.32" transform="translate(381.949 124.196)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2047" data-name="Rectangle 2047" width="97.317" height="97.32" transform="translate(761.5 124.196)" fill="#ececec" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2048" data-name="Rectangle 2048" width="97.317" height="97.32" transform="translate(953 124.196)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<rect id="Rectangle_2049" data-name="Rectangle 2049" width="97.317" height="97.32" transform="translate(190.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_1" data-name="1" transform="translate(239.081 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">1</tspan></text>
<rect id="Rectangle_2050" data-name="Rectangle 2050" width="97.317" height="97.32" transform="translate(0.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_0" data-name="0" transform="translate(50 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">0</tspan></text>
<rect id="Rectangle_2051" data-name="Rectangle 2051" width="97.317" height="97.32" transform="translate(381.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_2" data-name="2" transform="translate(430.51 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">2</tspan></text>
<text id="_3" data-name="3" transform="translate(430.96 200.894)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">3</tspan></text>
<text id="_7" data-name="7" transform="translate(810.96 200.894)" fill="#919191" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">7</tspan></text>
<text id="_8" data-name="8" transform="translate(1001.5 200.894)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">8</tspan></text>
<rect id="Rectangle_2052" data-name="Rectangle 2052" width="97.317" height="97.32" transform="translate(761.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_5" data-name="5" transform="translate(809.934 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">5</tspan></text>
<rect id="Rectangle_2053" data-name="Rectangle 2053" width="97.317" height="97.32" transform="translate(952.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_6" data-name="6" transform="translate(1001.49 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">6</tspan></text>
<rect id="Rectangle_2054" data-name="Rectangle 2054" width="97.317" height="97.32" transform="translate(1142.5 2.5)" fill="#5fa2dd" stroke="#ffe200" stroke-miterlimit="10" stroke-width="5"/>
<text id="_9" data-name="9" transform="translate(1191.149 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">9</tspan></text>
<rect id="Rectangle_2055" data-name="Rectangle 2055" width="97.317" height="97.32" transform="translate(571.5 2.5)" fill="#5fa2dd" stroke="#5897ce" stroke-miterlimit="10" stroke-width="1"/>
<text id="_4" data-name="4" transform="translate(618.55 77.5)" fill="#fff" font-size="70" font-family="Arial-Black, Arial Black" font-weight="800"><tspan x="-23.345" y="0">4</tspan></text>
<line id="Line_711" data-name="Line 711" x2="77.69" transform="translate(105 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_712" data-name="Line 712" x2="77.69" transform="translate(296 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_713" data-name="Line 713" x2="77.69" transform="translate(486 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_714" data-name="Line 714" x2="77.69" transform="translate(676 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_715" data-name="Line 715" x2="77.69" transform="translate(867 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_716" data-name="Line 716" x2="77.69" transform="translate(1057 50.378)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<line id="Line_707" data-name="Line 707" x2="77.69" transform="translate(867 172.856)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_708" data-name="Line 708" y2="10.957" transform="translate(430.874 106.129)" fill="none" stroke="#919191" stroke-width="1"/>
<line id="Line_709" data-name="Line 709" x1="0.103" y2="10.849" transform="translate(810.897 106.237)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_710" data-name="Line 710" y2="11" transform="translate(1001 106.237)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
<path id="Path_1816" data-name="Path 1816" d="M-9758.161,1731.667v66.477h134.53" transform="translate(9997.32 -1625.266)" fill="none" stroke="#919191" stroke-width="1"/>
<path id="Path_1817" data-name="Path 1817" d="M-9640.839,1797.609h133.727v-66.446" transform="translate(10127.272 -1624.762)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_1818" data-name="Path 1818" d="M-9640.969,1852.547h233.346V1718.181h33.911" transform="translate(10127.401 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<path id="Path_1819" data-name="Path 1819" d="M-9378.367,1718.181h-38.447v82.588h-39.241" transform="translate(10323.057 -1657.418)" fill="none" stroke="#707070" stroke-width="1"/>
<line id="Line_717" data-name="Line 717" x2="77.69" transform="translate(296 50.379)" fill="none" stroke="#5fa2dd" stroke-width="4"/>
</svg>

)
//#endregion

//#region Semafori
export const semaforo_gen_yellow = (<svg xmlns="http://www.w3.org/2000/svg" class="semaforo-svg" width="35.889" height="35.889" viewBox="0 0 35.889 35.889">
    <path id="square-solid" d="M0,4.841A4.846,4.846,0,0,1,4.841,0H29.047a4.846,4.846,0,0,1,4.841,4.841V29.047a4.846,4.846,0,0,1-4.841,4.841H4.841A4.846,4.846,0,0,1,0,29.047Z" transform="translate(1 34.889) rotate(-90)" fill="#fff000" stroke="#a2a2a2" stroke-width="2" />
</svg>
)
export const semaforo_gen_green = (<svg xmlns="http://www.w3.org/2000/svg" class="semaforo-svg" width="35.889" height="35.889" viewBox="0 0 35.889 35.889">
    <path id="square-solid" d="M0,4.841A4.846,4.846,0,0,1,4.841,0H29.047a4.846,4.846,0,0,1,4.841,4.841V29.047a4.846,4.846,0,0,1-4.841,4.841H4.841A4.846,4.846,0,0,1,0,29.047Z" transform="translate(1 34.889) rotate(-90)" fill="#3f0" stroke="#a2a2a2" stroke-width="2" />
</svg>
)
export const semaforo_gen_red = (<svg xmlns="http://www.w3.org/2000/svg" class="semaforo-svg" width="35.889" height="35.889" viewBox="0 0 35.889 35.889">
    <path id="square-solid" d="M0,4.841A4.846,4.846,0,0,1,4.841,0H29.047a4.846,4.846,0,0,1,4.841,4.841V29.047a4.846,4.846,0,0,1-4.841,4.841H4.841A4.846,4.846,0,0,1,0,29.047Z" transform="translate(1 34.889) rotate(-90)" fill="red" stroke="#a2a2a2" stroke-width="2" />
</svg>
)
export const semaforo_gen_white = (<svg xmlns="http://www.w3.org/2000/svg" class="semaforo-svg" width="35.889" height="35.889" viewBox="0 0 35.889 35.889">
    <path id="square-solid" d="M0,4.841A4.846,4.846,0,0,1,4.841,0H29.047a4.846,4.846,0,0,1,4.841,4.841V29.047a4.846,4.846,0,0,1-4.841,4.841H4.841A4.846,4.846,0,0,1,0,29.047Z" transform="translate(1 34.889) rotate(-90)" fill="#fff" stroke="#a2a2a2" stroke-width="2" />
</svg>
)
//#endregion