import React from 'react';
import { Panel, Container, Spacer, Loadmask } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import RtoControllo from './RtoControllo';
import RtoProcesso from './RtoProcesso';
import PoDetail from './PoDetail';
import RtoDetail from './RtoDetail';
import { useUser } from '../components/UserContext';

//---------------------------------------------------------------------------- Store
const Ext = window['Ext'];
const storeOperazioniTecniche = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiaOperazioniTecniche',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeTipologieFermata = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologieFermata',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//const storeLocalitaEstere = Ext.create('Ext.data.Store', {
//    proxy: {
//        method: 'GET',
//        type: 'ajax',
//        url: 'Anagrafiche/LeggiAnagraficaLocalitaEstere',
//        reader: {
//            type: 'json'
//        },
//        timeout: 600000
//    },
//    autoload: false
//});
const storeLocalita = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiLocalita',
        reader: {
            type: 'json'
        },
        timeout: 120000
    },
    autoload: false
});
const storeInfoCatalogo = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiCatalogoTRC',
        reader: {
            type: 'json'
        },
        timeout: 120000
    },
    autoload: false
});
const storeAnagraficaImpostazione = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiCodiceImpostazioneRifSTI',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeAnagraficaLocomotori = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiLocomotoriRifSTI',
        reader: {
            type: 'json'
        },
        timeout: 120000
    },
    autoload: false
});
const storeAnagraficaTipiMateriale = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologieMaterialeRimorchiato',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeAnagraficaPesiAss = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPesiAssiali',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeCodificheSagome = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiCodificheSagome',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeAnagraficaTipiFreno = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologieFreno',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//const storeAnagraficaImpreseEstere = Ext.create('Ext.data.Store', {
//    proxy: {
//        method: 'GET',
//        type: 'ajax',
//        url: 'Anagrafiche/LeggiAnagraficaImpresePartnerEstere',
//        reader: {
//            type: 'json'
//        }
//    },
//    autoload: false
//});
const storePeriodoRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiePeriodicita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeTipoVCO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/GetElencoVersioniVCO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeCatTreniIF = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiCategorieTreno',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeCommittenti = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiAnagraficaCommittente',
        reader: {
            type: 'json'
        },
        timeout: 120000
    },
    autoload: false
});
const storeTipoTraccia = Ext.create('Ext.data.Store', {
    //model: TipologieTracciaModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologieTraccia',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeTipologieRTO = Ext.create('Ext.data.Store', {
    //model: tipologieRTOModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologieRTO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeTipoTraffico = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologieTraffico',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeAccordiQuadro = Ext.create('Ext.data.Store', {
    //model: tipologieRTOModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiGestioneAQ',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeLotti = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Lotti/LeggiLotti',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

let periodicitaCalendario = "";
let storeLoaded = 0;
let storeToLoad = 0;

function RtoVersione(props) {
    const schedeRto = props.storeRtoSchede;
    const listaPo = props.listaPO;
    const { user, switchUser } = useUser();
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: false, message: "Attendere caricamento Anagrafiche..." })
    const [statePanelDetail, setStatePanelDetail] = useState({ rtoCollapsed: (props.inEdit === false && (props.inEditPO === false || props.inEditPO === undefined)) ? false : !props.inEdit, poCollapsed: (props.inEdit === false && (props.inEditPO === false || props.inEditPO === undefined)) ? true : !props.inEditPO })
    const [stateAggiornaControllo, setStateAggiornaControllo] = useState({ modifiche: 0 })
    const aggiornaStateControllo = () => {
        stateAggiornaControllo.modifiche = stateAggiornaControllo.modifiche <= 100 ? stateAggiornaControllo.modifiche + 1 : 1;
        setStateAggiornaControllo({ ...stateAggiornaControllo });
    }
    const aggiornaPanelCollapse = (panelName, collapsedPanel) => {

        if (panelName === "RTO")
            statePanelDetail.rtoCollapsed = collapsedPanel;

        if (panelName === "PO")
            statePanelDetail.poCollapsed = collapsedPanel;

        setStatePanelDetail({ ...statePanelDetail });

        if (statePanelDetail.rtoCollapsed && statePanelDetail.poCollapsed)   
            setStatePanelDetail({ rtoCollapsed: false, poCollapsed: true });        
    }
    
    //const [anagrafiche, setAnagrafiche] = useState({ loaded: false, localita: storeLocalita, operazioniTecniche: storeOperazioniTecniche, tipologieFermata: storeTipologieFermata, localitaEstere: storeLocalitaEstere, catalogoTRC: storeInfoCatalogo, codiciImpostazione: storeAnagraficaImpostazione, locomotori: storeAnagraficaLocomotori, tipiMateriale: storeAnagraficaTipiMateriale, pesiAssiali: storeAnagraficaPesiAss, codificheSagome: storeCodificheSagome, tipiFreno: storeAnagraficaTipiFreno, impresePartnerEstere: storeAnagraficaImpreseEstere, periodicitaRtoCalen: periodicitaCalendario, periodoRTO: storePeriodoRTO, periodoFerroviario: storePeriodiFerroviari, tipoVco: storeTipoVCO, categorieTreniIF: storeCatTreniIF, committenti: storeCommittenti, tipoTraccia: storeTipoTraccia, tipologieRTO: storeTipologieRTO, tipoTraffico: storeTipoTraffico, accordiQuadro: storeAccordiQuadro, lotti: storeLotti });
    const [anagrafiche, setAnagrafiche] = useState({ loaded: false, localita: storeLocalita, operazioniTecniche: storeOperazioniTecniche, tipologieFermata: storeTipologieFermata, catalogoTRC: storeInfoCatalogo, codiciImpostazione: storeAnagraficaImpostazione, locomotori: storeAnagraficaLocomotori, tipiMateriale: storeAnagraficaTipiMateriale, pesiAssiali: storeAnagraficaPesiAss, codificheSagome: storeCodificheSagome, tipiFreno: storeAnagraficaTipiFreno, periodicitaRtoCalen: periodicitaCalendario, periodoRTO: storePeriodoRTO, periodoFerroviario: storePeriodiFerroviari, tipoVco: storeTipoVCO, categorieTreniIF: storeCatTreniIF, committenti: storeCommittenti, tipoTraccia: storeTipoTraccia, tipologieRTO: storeTipologieRTO, tipoTraffico: storeTipoTraffico, accordiQuadro: storeAccordiQuadro, lotti: storeLotti });
    const updateAnagrafiche = () => {
        anagrafiche.localita = storeLocalita;
        anagrafiche.operazioniTecniche = storeOperazioniTecniche;
        anagrafiche.tipologieFermata = storeTipologieFermata;
        //anagrafiche.localitaEstere = storeLocalitaEstere;
        anagrafiche.catalogoTRC = storeInfoCatalogo;
        anagrafiche.codiciImpostazione = storeAnagraficaImpostazione;
        anagrafiche.locomotori = storeAnagraficaLocomotori;
        anagrafiche.tipiMateriale = storeAnagraficaTipiMateriale;
        anagrafiche.pesiAssiali = storeAnagraficaPesiAss;
        anagrafiche.codificheSagome = storeCodificheSagome;
        anagrafiche.tipiFreno = storeAnagraficaTipiFreno;
        //anagrafiche.impresePartnerEstere = storeAnagraficaImpreseEstere;
        anagrafiche.periodicitaRtoCalen = periodicitaCalendario;
        anagrafiche.periodoRTO = storePeriodoRTO;
        anagrafiche.periodoFerroviario = storePeriodiFerroviari;
        anagrafiche.tipoVco = storeTipoVCO;
        anagrafiche.categorieTreniIF = storeCatTreniIF;
        anagrafiche.committenti = storeCommittenti;
        anagrafiche.tipoTraccia = storeTipoTraccia;
        anagrafiche.tipologieRTO = storeTipologieRTO;
        anagrafiche.tipoTraffico = storeTipoTraffico;
        anagrafiche.accordiQuadro = storeAccordiQuadro;
        anagrafiche.lotti = storeLotti;
        anagrafiche.loaded = true;
        setAnagrafiche({ ...anagrafiche });
        setStateLoadMask({ hidden: true, message: "Attendere caricamento Anagrafiche..." });
    }

    const loadStoreAccordiQuadro = () => {
        storeAccordiQuadro.clearData();
        storeAccordiQuadro.removeAll();
        storeAccordiQuadro.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStorePeriodiFerroviari = () => {
        storePeriodiFerroviari.clearData();
        storePeriodiFerroviari.removeAll();
        storePeriodiFerroviari.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreAnagraficaImpostazione = (ideCli) => {
        var datRif = schedeRto.data.items[0].data.rto.datAtvPvv;
        storeAnagraficaImpostazione.clearData();
        storeAnagraficaImpostazione.removeAll();
        storeAnagraficaImpostazione.load({
            params: {
                datRif: datRif,
                ideCli: ideCli
            },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreAnagraficaLocomotori = (ideCli) => {
        var datRif = schedeRto.data.items[0].data.rto.datAtvPvv;
        storeAnagraficaLocomotori.clearData();
        storeAnagraficaLocomotori.removeAll();
        storeAnagraficaLocomotori.load({
            params: {
                datRif: datRif,
                ideCli: ideCli
            }, 
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreAnagraficaTipiMateriale = () => {
        storeAnagraficaTipiMateriale.clearData();
        storeAnagraficaTipiMateriale.removeAll();
        storeAnagraficaTipiMateriale.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    //const loadStoreLocalitaEstere = (ideRto) => {
    //    storeLocalitaEstere.clearData();
    //    storeLocalitaEstere.removeAll();
    //    storeLocalitaEstere.load({
    //        params: { ideRTO: ideRto },
    //        callback: function (records, operation, success) {
    //            storeLoaded++;
    //            if (storeLoaded >= storeToLoad)
    //                updateAnagrafiche();
    //        }
    //    });
    //};
    const loadStoreAnagraficaPesiAss = () => {
        storeAnagraficaPesiAss.clearData();
        storeAnagraficaPesiAss.removeAll();
        storeAnagraficaPesiAss.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreCodificheSagome = () => {
        storeCodificheSagome.clearData();
        storeCodificheSagome.removeAll();
        storeCodificheSagome.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreAnagraficaTipiFreno = () => {
        storeAnagraficaTipiFreno.clearData();
        storeAnagraficaTipiFreno.removeAll();
        storeAnagraficaTipiFreno.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStorePeriodoRTO = () => {
        storePeriodoRTO.clearData();
        storePeriodoRTO.removeAll();
        storePeriodoRTO.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreTipoVCO = () => {
        storeTipoVCO.clearData();
        storeTipoVCO.removeAll();
        storeTipoVCO.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreCommittenti = () => {
        storeCommittenti.clearData();
        storeCommittenti.removeAll();
        storeCommittenti.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreTipoTraccia = () => {
        storeTipoTraccia.clearData();
        storeTipoTraccia.removeAll();
        storeTipoTraccia.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreTipologieRTO = () => {
        storeTipologieRTO.clearData();
        storeTipologieRTO.removeAll();
        storeTipologieRTO.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreLocalita = (ideUte) => {
        storeLocalita.clearData();
        storeLocalita.removeAll();
        storeLocalita.load({
            params: { ideUte: ideUte },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreOperazioniTecniche = (ideRto) => {
        storeOperazioniTecniche.clearData();
        storeOperazioniTecniche.removeAll();
        storeOperazioniTecniche.load({
            params: { ideRto: ideRto },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreTipologieFermata = (ideRto) => {
        storeTipologieFermata.clearData();
        storeTipologieFermata.removeAll();
        storeTipologieFermata.load({
            params: { ideRto: ideRto },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreInfoCatalogo = (ideAnnFro) => {
        storeInfoCatalogo.clearData();
        storeInfoCatalogo.removeAll();
        storeInfoCatalogo.load({
            params: { ideAnno: ideAnnFro },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    //const loadStoreAnagraficaImpreseEstere = (ideCli) => {
    //    storeAnagraficaImpreseEstere.clearData();
    //    storeAnagraficaImpreseEstere.removeAll();
    //    storeAnagraficaImpreseEstere.load({
    //        params: { ideCli: ideCli },
    //        callback: function () {
    //            storeLoaded++;
    //            if (storeLoaded >= storeToLoad)
    //                updateAnagrafiche();
    //        }
    //    });
    //};
    const loadStoreCatTreniIF = (ideCli) => {
        storeCatTreniIF.clearData();
        storeCatTreniIF.removeAll();
        storeCatTreniIF.load({
            params: { ideCli: ideCli },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreTipoTraffico = (ideCli) => {
        storeTipoTraffico.clearData();
        storeTipoTraffico.removeAll();
        storeTipoTraffico.load({
            params: { ideCli: ideCli },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreLotti = (ideUte, ideCli, ideAnnFro) => {
        storeLotti.clearData();
        storeLotti.removeAll();
        storeLotti.load({
            params: {
                ideUte: ideUte,
                ideCli: ideCli,
                ideAnnFro: ideAnnFro
            },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const caricaPeriodicitaCalendario = (ideRto) => {
        Ext.Ajax.request({
            url: 'Rto/LeggiCalendarioPeriodicita',
            method: 'GET',
            params: { ideRTO: ideRto },
            callback: function (resp, success, operation) {
                periodicitaCalendario = Ext.decode(operation.responseText);
            }
        });
    };

    function caricaAnagrafiche(ideRto, ideUte, ideAnnFro, ideCli) {
        caricaPeriodicitaCalendario(ideRto);
        storeToLoad = 20;
        storeLoaded = 0;

        loadStorePeriodiFerroviari();
        loadStoreAnagraficaImpostazione(ideCli);
        loadStoreAnagraficaLocomotori(ideCli);
        loadStoreAnagraficaTipiMateriale();
        //loadStoreLocalitaEstere(ideRto);
        loadStoreAnagraficaPesiAss();
        loadStoreCodificheSagome();
        loadStoreAnagraficaTipiFreno();
        loadStorePeriodoRTO();
        loadStoreTipoVCO();
        loadStoreCommittenti();
        loadStoreTipoTraccia();
        loadStoreTipologieRTO();
        loadStoreAccordiQuadro();
        loadStoreOperazioniTecniche(ideRto);
        loadStoreTipologieFermata(ideRto);
        loadStoreLocalita(ideUte);
        loadStoreInfoCatalogo(ideAnnFro);
        //loadStoreAnagraficaImpreseEstere(ideCli);
        loadStoreCatTreniIF(ideCli);
        loadStoreTipoTraffico(ideCli);
        loadStoreLotti(ideUte, ideCli, ideAnnFro);

    };

    useEffect(() => {
        setStateLoadMask({ hidden: false, message: "Attendere caricamento Anagrafiche..." })
        let rto = schedeRto.data.items[0].data.rto;
        anagrafiche.loaded = false;

        caricaAnagrafiche(rto.ideRTO, user.ideUte, rto.ideAnnFro, rto.ideCli);

    }, [])
    return (
        <Container height="100%" width="100%" layout="fit" >

            <VersioneRtoContext.Provider value={
                {
                    storeRtoSchede: schedeRto,
                    listaPO: listaPo,
                    storeLocalita: anagrafiche.localita,
                    storeOperazioniTecniche: anagrafiche.operazioniTecniche,
                    storeTipologieFermata: anagrafiche.tipologieFermata,
                    //storeLocalitaEstere: anagrafiche.localitaEstere,
                    storeInfoCatalogo: anagrafiche.catalogoTRC,
                    storeAnagraficaImpostazione: anagrafiche.codiciImpostazione,
                    storeAnagraficaLocomotori: anagrafiche.locomotori,
                    storeAnagraficaTipiMateriale: anagrafiche.tipiMateriale,
                    storeAnagraficaPesiAss: anagrafiche.pesiAssiali,
                    storeCodificheSagome: anagrafiche.codificheSagome,
                    storeAnagraficaTipiFreno: anagrafiche.tipiFreno,
                    //storeAnagraficaImpreseEstere: anagrafiche.impresePartnerEstere,
                    periodicitaCalendario: anagrafiche.periodicitaRtoCalen,
                    storePeriodoRTO: anagrafiche.periodoRTO,
                    storePeriodiFerroviari: anagrafiche.periodoFerroviario,
                    storeTipoVCO: anagrafiche.tipoVco,
                    storeCatTreniIF: anagrafiche.categorieTreniIF,
                    storeCommittenti: anagrafiche.committenti,
                    storeTipoTraccia: anagrafiche.tipoTraccia,
                    storeTipologieRTO: anagrafiche.tipologieRTO,
                    storeTipoTraffico: anagrafiche.tipoTraffico,
                    storeAccordiQuadro: anagrafiche.accordiQuadro,
                    storeLotti: anagrafiche.lotti
                }
            }>

                <Panel cls="container-spinner" height="100%" width="100%" layout="vbox">
                    <Panel>{schedeRto.getCount() > 0 && <RtoProcesso />}</Panel>
                    <Panel layout="vbox" maxHeight="60%" scrollable>
                        <Panel>
                            <RtoControllo aggiornaStateControllo={aggiornaStateControllo} modificheControllo={stateAggiornaControllo.modifiche} modificaServiziCommerciali={props.modificaServiziCommerciali} />
                        </Panel>
                        {/* <Panel>
                            {schedeRto.getCount() > 0 && <RtoProcesso />}
                        </Panel> */}
                    </Panel>

                    <Spacer maxHeight={5} maxWidth={5} />
                    
                    <Panel layout="hbox" flex={1} bodyPadding={2}>
                        <Loadmask
                            cls="custom-grid"
                            itemId="loadMaskRtoDet"
                            hidden={stateLoadMask.hidden}
                            indicator={true}
                            message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                            modal={true}
                        />
                        {anagrafiche.loaded && <RtoDetail aggiornaStateControllo={aggiornaStateControllo} collapsed={statePanelDetail.rtoCollapsed} inEdit={props.inEdit} inEditPO={props.inEditPO} RefreshRto={props.RefreshRto} aggiornaStateModifica={props.aggiornaStateModifica} aggiornaStatePeriodII={props.aggiornaStatePeriodII} aggiornaStateServII={props.aggiornaStateServII} aggiornaPanelCollapse={aggiornaPanelCollapse} modificaServiziCommerciali={props.modificaServiziCommerciali} aggiornaStateModificaManovra={props.aggiornaStateModificaManovra} />}

                        {anagrafiche.loaded && <PoDetail hiddenEditPO={props.hiddenEditPO} toolHandler={props.toolHandler} RefreshRtoDaAggiornaPO={props.RefreshRtoDaAggiornaPO} aggiornaStateControllo={aggiornaStateControllo} collapsed={statePanelDetail.poCollapsed} inEdit={props.inEdit} inEditPO={props.inEditPO} RefreshListaPO={props.RefreshListaPO} readOnly={props.readOnly} aggiornaStateModifica={props.aggiornaStateModifica} aggiornaPanelCollapse={aggiornaPanelCollapse} flgUpdTrc={props.storeRtoSchede.data.items[0].data.rto.flgUpdTrc}/>}

                    </Panel>
                    
                </Panel>

            </VersioneRtoContext.Provider>

        </Container>
    );
}

export default RtoVersione;
