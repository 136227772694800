import React from 'react';
import { createContext, useState, useContext } from 'react';
import { Container, Panel, TitleBar, Image, Button } from '@sencha/ext-react-modern';

const UserContext = createContext(undefined); //inizia con undefined perch� all'inizio non si sa chi � loggato

export const UserProvider = ({ children }) => {
    let [user, setUser] = useState(null);
     
    return (
        <UserContext.Provider
            value={{
                user,
                switchUser: (newUser) => setUser(newUser),
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

//This external function is created just for convenience,so there's no need to export the user context to external components.
export const useUser = () => useContext(UserContext);


/*
 * The UserContext.Provider component is what allows consuming components to subscribe to context changes.
 * This component accepts a value prop, which is what will be passed to consuming components that are descendants
 * of this provider. The value prop should be the authenticated user.
 * */



//////export const useUser = () => useContext(UserContext); 
////export const useUser = () => {
////    const { user, setUser } = useContext(UserContext);   
////    const addUser = (user) => {
////        setUser(user);
////    };
////    const removeUser = () => {
////        setUser(null);
////    };
////    return { user, addUser, removeUser };
////};

