import React from 'react';
import { Panel, Container, Grid, Column } from '@sencha/ext-react-modern';

import Calendar from './PoPeriodicitaCalendar.js';

//import { useState, useEffect } from "react";

//import Calendario from "../components/Calendario/Calendario/Calendario.js";
//import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
//import Stazioni from "../components/Calendario/Stazioni/Stazioni.js";

const Ext = window['Ext'];

function PoPeriodicita(props) {

    const storePeriodicita = Ext.create('Ext.data.Store', {
        data: props.schedaPo.schede["periodicità"]
    });

    return (
        <Container>
            <Panel
                itemId={"poPeriodicita" + props.ind}
                title={props.schedaPo.testataPO.desTrcOra}
                titleCollapse={true}
                cls='PnlSectionPOTR'
                iconCls="x-fa fa-train"
                collapsible={{ collapsed: false, direction: 'top' }}
            >
                <Container
                    layout="vbox" flex={1} cls="column-in-panel" >
                    <Grid
                        minHeight='200'
                        height='200'
                        flex={1}
                        scrollable
                        selectable={{ checkbox: false, mode: 'single' }}
                        store={storePeriodicita}
                        plugins={['gridfilters']}
                        itemConfig={{
                            viewModel: true
                        }}
                    >

                        <Column flex={1} text="Da" dataIndex="desLocPrv" filter='string' />
                        <Column flex={1} text="A" dataIndex="desLocDsn" filter='string' />
                        <Column text="Operativit&aacute" dataIndex="desOpv" flex={1} filter='string' />
                        <Column text="Giorni" dataIndex="desPerTrc" filter='string' flex={1} />
                    </Grid>
                    {/*{renderListOfCalendar(storePeriodicita.getData().items)}*/}
                    {storePeriodicita.getData().items.map((item, i) => (<Calendar key={`poper${props.ind}cal${i}`} calendar={item} indkey={`poper${props.ind}-${i}`} ind={i} />))}
                </Container>
            </Panel>
        </Container>
    );

}

export default PoPeriodicita;

