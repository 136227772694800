export const weekDays = ['L', 'M', 'M', 'G', 'V', 'S', 'D'];
export const monthNames = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
export const colorMapping = {
    '0': '#C2C2C2',
    '1': '#90BEE6',
    '2': '#5FA2DD'
};
export const squareStyle = {
    width: "16px",
    height: "16px",
    borderRadius: "2px",
    border: "2px solid",
    display: "inline-block",
    textAlign: "center",
    verticalAlign: "middle",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
};

export const squareColors = {
    circola: "#5FA2DD",
    nonCircola: "#C2C2C2",
    nonEffettuabile: "#DD5F5F",
    attivazioneProvvedimento: "trasparent"
};

export const circolaText = "Circola";
export const nonCircolaText = "Non Circola";
export const nonEffettuabileText = "Non Effettuabile";
export const siEffettuaText = "Si effettua";
export const nonSiEffettuaText = "Non si effettua";
export const attivazioneProvvedimentoText = "Attivazione provvedimento";