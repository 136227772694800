import React from 'react';
import { Panel, FormPanel, Container, Button, ComboBoxField, CheckBoxField, DatePickerField, FileField, Spacer, Grid, Column, FieldSet, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { useState, useContext, useEffect } from "react";
import { VersioneRtoContext } from '../components/VersioneRtoContext';

import RtoRiepilogo from './RtoRiepilogo';
import RtoPercorso from './RtoPercorso';
import RtoPeriodicita from './RtoPeriodicita';
import RtoImpostazione from './RtoImpostazione';
import RtoImpegnoImpianto from './RtoII';
import RtoDossierPcs from './RtoDossierPcs';
import RtoServiziCommerciali from './RtoServiziCommerciali';

const Ext = window['Ext'];

let giorniCircolazione = 0;

function RtoDetail(props) {

    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede;
    const inEdit = props.inEdit; 

    // attenzione ai servizi commerciali, se il valore di default per collapseRtoServiziCommerciali � true bisona inserire ==> props.modificaServiziCommerciali ? false : true;
    const caricaPercorso = () => {
        Ext.Ajax.request({
            url: 'Rto/LeggiPercorsoRTO',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                giorniCircolazione = returnObj[0].giO_PER;
                aggiornaStateGiorniCircolazione(giorniCircolazione);
            }
        });
    }

    const [stateAggiornaTabPeriodicita, setStateAggiornaTabPeriodicita] = useState({ modifiche: false })
    const aggiornaStateTabPeriodicita = (modifiche) => {
        stateAggiornaTabPeriodicita.modifiche = modifiche;
        setStateAggiornaTabPeriodicita({ ...stateAggiornaTabPeriodicita });
    }

    const [stateAggiornaTabServiziComm, setStateAggiornaTabServiziComm] = useState({ modifiche: false })
    const aggiornaStateTabServiziComm = (modifiche) => {
        stateAggiornaTabServiziComm.modifiche = modifiche;
        setStateAggiornaTabServiziComm({ ...stateAggiornaTabServiziComm });
    }

    const [stateAggiornaTabPercorso, setStateAggiornaTabPercorso] = useState({ modifiche: false })
    const aggiornaStateTabPercorso = (modifiche) => {
        stateAggiornaTabPercorso.modifiche = modifiche;
        setStateAggiornaTabPercorso({ ...stateAggiornaTabPercorso });
    }

    const [stateAggiornaGiorniCircolazione, setStateAggiornaGiorniCircolazione] = useState({ giorni: giorniCircolazione })
    const aggiornaStateGiorniCircolazione = (giorni) => {
        stateAggiornaGiorniCircolazione.giorni = giorni;
        setStateAggiornaGiorniCircolazione({ ...stateAggiornaGiorniCircolazione });
    }

    let pnl = Ext.ComponentQuery.query('#rtoDetailPanel')[0];
    if (pnl != undefined) {
        let pnlcoll = pnl.getCollapsed();
        if (pnlcoll != props.collapsed) {
            pnl.setCollapsed(props.collapsed);
        }
    }

    // Gestione del collapse del pannello principale
    const onPanelCollapse = () => {
        props.aggiornaPanelCollapse("RTO", true);        
    }
    const onPanelExpand = () => {
        props.aggiornaPanelCollapse("RTO", false);
    }
    
    useEffect(() => {
        caricaPercorso();
    }, [rtoSchede.data.items[0].data.rto.ideRTO]); 
   
  return (

    <Panel
          title="Dettaglio Richiesta IF"
          itemId="rtoDetailPanel"
          layout="vbox"
          cls="iconPanelsRTO"
          collapsible={{ direction: 'left'}}
          collapsed={props.collapsed}
         scrollable
          flex={1}
          titleCollapse={true}
          onCollapse={onPanelCollapse}
          onExpand={onPanelExpand}
          //tools={[
          //    { iconCls: 'x-fa fa-angles-up buttonPanelTool', tooltip: 'Collassa le sezioni', type: 'collassa', handler: toolHandler},
          //    { iconCls: 'x-fa fa-angles-down buttonPanelTool', tooltip: 'Espandi le sezioni', type: 'espandi', handler: toolHandler}          
          //]}
      >
        <Container>
              <RtoRiepilogo modificaGiorniCircolazione={stateAggiornaGiorniCircolazione.giorni} aggiornaStateTabPercorso={aggiornaStateTabPercorso} aggiornaStateTabServiziComm={aggiornaStateTabServiziComm} aggiornaStateTabPeriodicita={aggiornaStateTabPeriodicita} inEdit={props.inEdit} RefreshRto={props.RefreshRto} aggiornaStateModifica={props.aggiornaStateModifica} />
        </Container>
        <Container>
              <RtoPercorso modificheTabPercorso={stateAggiornaTabPercorso.modifiche} aggiornaStateTabPercorso={aggiornaStateTabPercorso} aggiornaStateTabServiziComm={aggiornaStateTabServiziComm} aggiornaStateTabPeriodicita={aggiornaStateTabPeriodicita} inEdit={props.inEdit} aggiornaStateModifica={props.aggiornaStateModifica} RefreshRto={props.RefreshRto} />
        </Container>
        <Container>
              <RtoPeriodicita aggiornaStateGiorniCircolazione={aggiornaStateGiorniCircolazione} aggiornaStateTabPeriodicita={aggiornaStateTabPeriodicita} modificheTabPeriodicita={stateAggiornaTabPeriodicita.modifiche} inEdit={props.inEdit} aggiornaStateControllo={props.aggiornaStateControllo} aggiornaStateModifica={props.aggiornaStateModifica} aggiornaStatePeriodII={props.aggiornaStatePeriodII} />
        </Container>
        <Container>
              <RtoImpostazione inEdit={props.inEdit} aggiornaStateModifica={props.aggiornaStateModifica} aggiornaStateControllo={props.aggiornaStateControllo}  />
        </Container>
        <Container>
              <RtoImpegnoImpianto inEdit={props.inEdit} aggiornaStatePeriodII={props.aggiornaStatePeriodII} aggiornaStateServII={props.aggiornaStateServII} aggiornaStateControllo={props.aggiornaStateControllo} aggiornaStateModifica={props.aggiornaStateModifica} aggiornaStateModificaManovra={props.aggiornaStateModificaManovra} />
        </Container>
          {contextVersioneRto.storeRtoSchede.data.items[0].data.rto.ideTipTra !== 3 && <Container>
              <RtoServiziCommerciali aggiornaStateTabServiziComm={aggiornaStateTabServiziComm} modificheTabServiziComm={stateAggiornaTabServiziComm.modifiche} inEdit={props.inEdit} aggiornaStateModifica={props.aggiornaStateModifica} modificaServiziCommerciali={props.modificaServiziCommerciali} />
          </Container>
          }
          {contextVersioneRto.storeRtoSchede.data.items[0].data.rto.ideOri === 4 && <Container>
              <RtoDossierPcs inEdit={props.inEdit} aggiornaStateModifica={props.aggiornaStateModifica} />
          </Container>}
    </Panel>

  );
}

export default RtoDetail;
