import React from 'react';
import { Panel, Container, Loadmask, Checkcolumn, Grid, Column } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';
import clientiReferntiModel from '../models/ClientiReferentiModel.js';

const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

const storeClientiReferenti = Ext.create('Ext.data.Store', {
    model: clientiReferntiModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});


function ClienteReferenti(props) {

    const { user, switchUser } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    var readonly = (user.ruolo == "RFI-PO-COMP" || user.ruolo == "RFI-PO-RFICC") ? true : false;

    const [stateLoadMask, setStateLoadMask] = useState({ hidden: false, message: "Attendere caricamento referenti contrattuali in corso...", ricarica: true });
    const cliente = props;

    const caricaClientiReferentiiIF = (ideCli) => {
        Ext.Ajax.request({
            url: 'Clienti/LeggiListeReferentiTipologie',
            method: 'GET',
            params: {
                ideCli: ideCli
            },
            callback: function (records, success, operation) {
                setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                var returnObj = Ext.decode(operation.responseText);
                storeClientiReferenti.loadData(returnObj);
                Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].setStore(storeClientiReferenti);
            }
        });
    }

    if (stateLoadMask.ricarica === true) {
        setStateLoadMask({ hidden: false, message: "Caricamento in corso...", ricarica: false });
        caricaClientiReferentiiIF(props.cliente.ideCli);
    }

    function onNuovoReferenteClick() {
        var grid = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0];
        var store = grid.getStore();
        var record = Ext.create(clientiReferntiModel, {});
        store.insert(0, record);
        grid.getPlugin('rowedit').startEdit(record, 1);
    }

    function onEliminaReferenteClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del referente contrattuale?", function (button) {

            if (button === 'yes') {
                setStateLoadMask({ hidden: false, message: "Attendere eliminazione in corso...", ricarica: false });
                var referentiContrattualiByDB = null;
                Ext.Ajax.request({
                    url: 'Clienti/LeggiListeReferentiTipologie',
                    method: 'GET',
                    params: {
                        ideCli: props.cliente.ideCli
                    },
                    callback: function (resp, success, operation) {
                        referentiContrattualiByDB = Ext.decode(operation.responseText);
                        if (referentiContrattualiByDB.find(rifComm => rifComm.ideEfeCnt === info.record.data.ideEfeCnt) != null) {
                            Ext.Ajax.request({
                                url: 'Clienti/EliminaReferente',
                                method: 'POST',
                                params: {
                                    ideUte: user.ideUte,
                                    ipAddress: "",
                                    ideReferente: info.record.data.ideEfeCnt
                                },
                                callback: function (records, success, operation) {
                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                                    if (success) {
                                        var returnObj = Ext.decode(operation.responseText);
                                        //modifica gestione errore
                                        if (returnObj.resp == "KO") {
                                            Ext.Msg.alert('Elimina referente contrattuale fallito', returnObj.msgErr)
                                        }
                                        else {
                                            Ext.toast({ message: 'Referente contrattuale eliminato', timeout: 4000 });
                                        }
                                    }
                                    else {
                                        Ext.Msg.alert('Elimina referente contrattuale fallito', 'Elimina referente contrattuale fallito');
                                    }
                                }
                            });
                        }
                        else {
                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: false });
                            Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getStore().remove(info.record);
                            Ext.toast({ message: 'Referente contrattuale eliminato', timeout: 4000 });
                        }
                    }
                })

            }
        })

    }

    function onAggiungiReferenteClick(grid, info) {

        var store = grid.getStore();
        var record = Ext.create(clientiReferntiModel, {});
        store.insert(info.cell.row._recordIndex + 1, record);
        grid.getPlugin('rowedit').startEdit(record, 2);
    }

    function onModificaReferenteClick(grid, info) {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 2);
        }
    }

    const legendaItems = [
        { sigla: 'CGEN', descrizione: 'Comunicazioni di carattere generale' },
        { sigla: 'INFO', descrizione: 'Informatico' },
        { sigla: 'RIPO', descrizione: 'Richieste in P. O.' },
        { sigla: 'FATT', descrizione: 'Fatturazione' },
        { sigla: 'SAOP', descrizione: 'Sala operativa' },
        { sigla: 'PEDA', descrizione: 'Rendicontazione – Pedaggio' },
        { sigla: 'PERF', descrizione: 'Rendicontazione - Performance Regime' },
        { sigla: 'SERV', descrizione: 'Rendicontazione- Servizi Accessori' },
        { sigla: 'GSMR', descrizione: 'Rendicontazione – GSMR' },
        { sigla: 'ESER', descrizione: 'Incidenti di Esercizio' }
    ];

    const half = Math.ceil(legendaItems.length / 2);
    const firstHalfItems = legendaItems.slice(0, half);
    const secondHalfItems = legendaItems.slice(half);

    function handleCollapse(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoReferente')[0].setHidden(true);
    };

    function handleExpand(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoReferente')[0].setHidden(readonly);
    };

    return (

        <Panel
            title="Referenti contrattuali"
            scrollable
            itemId="panelReferenti"
            bodyPadding={5}
            titleCollapse={true}
            iconCls="x-fa fa-address-book"
            collapsible={{ collapsed: true, direction: 'top' }}
            cls='PnlSectionL1 iconPanelsRTOSpecial '
            onCollapse={handleCollapse}
            onExpand={handleExpand}
            tools={[
                {
                    iconCls: 'x-fa x-fa fa-plus', itemId: "nuovoReferente", hidden: "true", tooltip: 'Nuovo', handler: onNuovoReferenteClick.bind(this)
                }
            ]}
        >
            <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
                <Loadmask
                    cls="custom-grid"
                    itemId="loadMaskClientiReferenti"
                    hidden={stateLoadMask.hidden}
                    indicator={true}
                    message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                    modal={true}
                />

                <Grid
                    itemId="gridReferentiContrattuali"
                    flex={1}
                    markDirty={true}
                    store={storeClientiReferenti}
                    minHeight='200'
                    height='200'
                    scrollable
                    cls="custom-grid"
                    selectable={{ checkbox: false, mode: 'single' }}
                    plugins={{ gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" } }}
                    itemConfig={{
                        viewModel: true
                    }}
                    listeners={{
                        canceledit: function (editor, context) {
                            caricaClientiReferentiiIF(props.cliente.ideCli);
                        },
                        edit: function (grid, location) {
                            if (location.record.data.cgenFlg === false && location.record.data.infoFlg === false && location.record.data.ripoFlg === false &&
                                location.record.data.fattFlg === false && location.record.data.saopFlg === false && location.record.data.saopFlg === false &&
                                location.record.data.eserFlg === false && location.record.data.pedaFlg === false && location.record.data.perfFlg === false &&
                                location.record.data.servFlg === false && location.record.data.gsmrFlg === false) {
                                Ext.toast({ message: 'Selezionare almeno una tipologia di referente contrattuale', timeout: 4000 });
                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                                return false;
                            }
                            else {
                                setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso...", ricarica: false });
                                var riferimentiContrattualiByDB = null;
                                Ext.Ajax.request({
                                    url: 'Clienti/LeggiListeReferentiTipologie',
                                    method: 'GET',
                                    params: {
                                        ideCli: props.cliente.ideCli
                                    },
                                    callback: function (resp, success, operation) {
                                        const ideReferenteApp = location.record.data.ideEfeCnt;
                                        riferimentiContrattualiByDB = Ext.decode(operation.responseText);

                                        if (riferimentiContrattualiByDB.find(rifComm => rifComm.ideEfeCnt === ideReferenteApp) == null) {
                                            var ideUteN = user.ideUte;
                                            var ideCliN = props.cliente.ideCli;
                                            var cgenflagN = location.record.data.cgenFlg;
                                            var infoflagN = location.record.data.infoFlg;
                                            var ripoflagN = location.record.data.ripoFlg;
                                            var fattflagN = location.record.data.fattFlg;
                                            var soapflagN = location.record.data.saopFlg;
                                            var eserflagN = location.record.data.eserFlg;
                                            var pedaflagN = location.record.data.pedaFlg;
                                            var perfflagN = location.record.data.perfFlg;
                                            var servflagN = location.record.data.servFlg;
                                            var gsmrflagN = location.record.data.gsmrFlg;
                                            var nomeN = location.record.data.nom;
                                            var cognomeN = location.record.data.cog;
                                            var telefonoN = location.record.data.tel;
                                            var cellulareN = location.record.data.cel;
                                            var faxN = location.record.data.fax;
                                            var emailN = location.record.data.eml;
                                            Ext.Ajax.request({
                                                url: 'Clienti/InserisciReferente',
                                                method: 'POST',
                                                params: {
                                                    ideUte: ideUteN,
                                                    ideCli: ideCliN,
                                                    cgenflag: cgenflagN,
                                                    infoflag: infoflagN,
                                                    ripoflag: ripoflagN,
                                                    fattflag: fattflagN,
                                                    soapflag: soapflagN,
                                                    eserflag: eserflagN,
                                                    pedaflag: pedaflagN,
                                                    perfflag: perfflagN,
                                                    servflag: servflagN,
                                                    gsmrflag: gsmrflagN,
                                                    nome: nomeN,
                                                    cognome: cognomeN,
                                                    telefono: telefonoN,
                                                    cellulare: cellulareN,
                                                    fax: faxN,
                                                    email: emailN
                                                },
                                                callback: function (records, success, operation) {
                                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp === "KO") {
                                                            Ext.Msg.alert('Inserimento referente contrattuale fallito', returnObj.msgErr)
                                                        }
                                                        else {
                                                            Ext.toast({ message: 'Referente contrattuale inserito', timeout: 4000 });
                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Inserimento referente contrattuale fallito', 'Inserimento referente contrattuale fallito');
                                                    }
                                                }
                                            })
                                        }
                                        else {
                                            var ideUteA = user.ideUte;
                                            var ideCliA = props.cliente.ideCli;
                                            var ideReferenteA = location.record.data.ideEfeCnt;
                                            var cgenflagA = location.record.data.cgenFlg;
                                            var infoflagA = location.record.data.infoFlg;
                                            var ripoflagA = location.record.data.ripoFlg;
                                            var fattflagA = location.record.data.fattFlg;
                                            var soapflagA = location.record.data.saopFlg;
                                            var eserflagA = location.record.data.eserFlg;
                                            var pedaflagA = location.record.data.pedaFlg;
                                            var perfflagA = location.record.data.perfFlg;
                                            var servflagA = location.record.data.servFlg;
                                            var gsmrflagA = location.record.data.gsmrFlg;
                                            var nomeA = location.record.data.nom;
                                            var cognomeA = location.record.data.cog;
                                            var telefonoA = location.record.data.tel;
                                            var cellulareA = location.record.data.cel;
                                            var faxA = location.record.data.fax;
                                            var emailA = location.record.data.eml;
                                            Ext.Ajax.request({
                                                url: 'Clienti/ModificaReferente',
                                                method: 'POST',
                                                params: {
                                                    ideUte: ideUteA,
                                                    ideCli: ideCliA,
                                                    ideReferente: ideReferenteA,
                                                    cgenflag: cgenflagA,
                                                    infoflag: infoflagA,
                                                    ripoflag: ripoflagA,
                                                    fattflag: fattflagA,
                                                    soapflag: soapflagA,
                                                    eserflag: eserflagA,
                                                    pedaflag: pedaflagA,
                                                    perfflag: perfflagA,
                                                    servflag: servflagA,
                                                    gsmrflag: gsmrflagA,
                                                    nome: nomeA,
                                                    cognome: cognomeA,
                                                    telefono: telefonoA,
                                                    cellulare: cellulareA,
                                                    fax: faxA,
                                                    email: emailA
                                                },
                                                callback: function (records, success, operation) {
                                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp === "KO") {
                                                            Ext.Msg.alert('Modifica referente contrattuale fallito', returnObj.msgErr)
                                                        }
                                                        else {
                                                            Ext.toast({ message: 'Referente contrattuale modificato', timeout: 4000 });
                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Modifica referente contrattuale fallita', 'Modifica referente contrattuale fallita');
                                                    }
                                                }
                                            })
                                        }
                                    }
                                })
                            }

                        },
                    }}
                >
                    <Checkcolumn
                        text="CGEN"
                        flex="1"
                        dataIndex='cgenFlg'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Checkcolumn
                        text='INFO'
                        flex="1"
                        dataIndex='infoFlg'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Checkcolumn
                        text='RIPO'
                        flex="1"
                        dataIndex='ripoFlg'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Checkcolumn
                        text='FATT'
                        flex="1"
                        dataIndex='fattFlg'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Checkcolumn
                        text='SAOP'
                        flex="1"
                        dataIndex='saopFlg'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Checkcolumn
                        text='ESER'
                        flex="1"
                        dataIndex='eserFlg'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Checkcolumn
                        text='PEDA'
                        flex="1"
                        dataIndex='pedaFlg'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Checkcolumn
                        text='PERF'
                        flex="1"
                        dataIndex='perfFlg'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Checkcolumn
                        text='SERV'
                        flex="1"
                        dataIndex='servFlg'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Checkcolumn
                        text='GSMR'
                        flex="1"
                        dataIndex='gsmrFlg'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridReferentiContrattuali')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Column text="Nome" flex="1" dataIndex="nom" filter='string' editor={{
                        xtype: 'textfield', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true
                    }} />
                    <Column text="Cognome" flex="1" dataIndex="cog" filter='string' editor={{
                        xtype: 'textfield', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true
                    }} />
                    <Column text="Telefono" flex="1" dataIndex="tel" filter='string' editor={{
                        xtype: 'textfield'
                    }} />
                    <Column text="Cellulare" flex="1" dataIndex="cel" filter='string' editor={{
                        xtype: 'textfield'
                    }} />
                    <Column text="Fax" flex="1" dataIndex="fax" filter='string' editor={{
                        xtype: 'textfield'
                    }} />
                    <Column text="Email" flex="1" dataIndex="eml" filter='string' editor={{
                        xtype: 'textfield'
                    }} />
                    <Column flex="1" itemId='actionRefrentiContrattuali' editable={false} text="Azioni" hidden={readonly}
                        cell={{

                            tools: {

                                aggiungiReferente: {

                                    handler: onAggiungiReferenteClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-plus",

                                        tooltip: 'Aggiungi'
                                    }

                                },

                                modificaReferente: {

                                    handler: onModificaReferenteClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-edit",

                                        tooltip: 'Modifica'
                                    }

                                },

                                eliminaReferente: {

                                    handler: onEliminaReferenteClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-trash",

                                        tooltip: 'Elimina'
                                    }

                                }

                            }

                        }}
                    />
                </Grid>

            </Container>

            <Container layout={{ type: 'hbox', align: 'middle', pack: 'center' }}>
                <Container layout="vbox" margin="0 80 0 0">
                    {firstHalfItems.map((item, index) => (
                        <div className="legenda-item"><b>{item.sigla}:</b> {item.descrizione}</div>
                    ))}
                </Container>
                <Container layout="vbox">
                    {secondHalfItems.map((item, index) => (
                        <div className="legenda-item"><b>{item.sigla}:</b> {item.descrizione}</div>
                    ))}
                </Container>
            </Container>

        </Panel>

    );
}

export default ClienteReferenti;