import React, { useEffect, useState } from "react";
import { Container, Panel, Titlebar, Grid, Column, Button, Toolbar, SegmentedButton, Combobox } from "@sencha/ext-react-modern";
import ColumnGrouped from "../home/charts/column_grouped";
import { useUser } from "../components/UserContext";
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';
import Stacked from "../home/charts/stacked";
const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
]);
//  -----------PERIODI FERROVIARI MENU ----------------------------------------------
const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//-----------IMPRESE FERROVIARIE----------------------------------
const storeImpresa = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiListImpresaFerroviaria',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//-------------PERIODI RTO----------------------------
const storePeriodoRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiePeriodicita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//---------------------------------------------
const storeStatisticheFinalizzazione = Ext.create('Ext.data.Store', {
    proxy: {
        url: 'AnalisiRto/ReportStatisticheFinalizzazioneRTO',
        method: 'GET',
        type: 'ajax',
        reader: {
            type: 'json'
        },
    },
    autoload: false,

});

function StatisticheFinalizzazione() {

    let title = "Statistiche Finalizzazione RTO";
    let ui = 'action';
    const { user } = useUser();
    const { ambiente, updateAmbiente, inizializzaAmbiente } = useAmbiente();
    const { periodi } = usePeriodi();

    storePeriodiFerroviari.setData(periodi.periodiFerroviari);
    storePeriodoRTO.setData(periodi.periodoRTO);

    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });

    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    const [chartDataSF, setChartDataSF] = useState(null);

    //const caricaPeriodiFerroviari = () => {
    //    storePeriodiFerroviari.load({
    //    })
    //}
    //const caricaPeriodoRTO = () => {
    //    storePeriodoRTO.load({
    //    })
    //}
    const caricaImpresaFerroviaria = () => {
        storeImpresa.load({
            params: {
                IdeOpaRfi: user.idOperatoreRFI
            }
        })
    }

    const caricaStatisticheFinalizzazione = (ideUte, ideAnnFro, ideTipPer, ideCli,) => {

        storeStatisticheFinalizzazione.load({
            params: {
                ideUte: ideUte,
                ideAnnFro: ideAnnFro,
                ideTipPer: ideTipPer,
                ideCli: ideCli
            },
            callback: function () {

            }
        });
    };

    const loadGraph = (pIdeAnnFro, pIdeTipPer) => {

        let updatedChartSF = null;
        let impresa = isIF ? user.ideCli : parseInt(Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0]._value);

        Ext.Ajax.request({
            url: 'AnalisiRto/ReportStatisticheFinalizzazioneRTO',
            method: 'GET',
            params: {
                ideUte: user.ideUte,
                ideAnnFro: pIdeAnnFro,
                ideTipPer: pIdeTipPer,
                ideCli: impresa != -1 ? impresa : null
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);

                if (returnObj.length > 0) {
                    let responseData = returnObj;

                    let labels = [...new Set(responseData.map(item => item.desTipPer))];
                    let dataTotAperte = new Array(labels.length).fill(0);
                    let dataTotAnnullate = new Array(labels.length).fill(0);
                    let dataTotRifiutate = new Array(labels.length).fill(0);
                    let dataTotAccettate = new Array(labels.length).fill(0);
                    let dataTotContrattualizzate = new Array(labels.length).fill(0);

                    responseData.forEach(item => {
                        let index = labels.indexOf(item.desTipPer);
                        if (index !== -1) {
                            dataTotAperte[index] += item.totAperte;
                            dataTotAnnullate[index] += item.totAnnullate;
                            dataTotRifiutate[index] += item.totRifiutate;
                            dataTotAccettate[index] += item.totAccettate;
                            dataTotContrattualizzate[index] += item.totContrattualizzate;
                        }
                    });

                    updatedChartSF = {
                        labels: labels,
                        datasets: [
                            {
                                label: 'RTO Aperte',
                                data: dataTotAperte,
                                backgroundColor: 'rgba(246,161,8,1)'

                            },
                            {
                                label: 'RTO Annullate',
                                data: dataTotAnnullate,
                                backgroundColor: 'rgba(128,128,128,1)'

                            },
                            {
                                label: 'RTO Rifiutate',
                                data: dataTotRifiutate,
                                backgroundColor: 'rgba(255,8,8,1)'

                            },
                            {
                                label: 'RTO Accettate',
                                data: dataTotAccettate,
                                backgroundColor: 'rgba(100,149,237,1)'

                            },
                            {
                                label: 'RTO Contrattualizzate',
                                data: dataTotContrattualizzate,
                                backgroundColor: 'rgba(52,204,52,1)'

                            }
                        ]
                    };
                    setChartDataSF(updatedChartSF);
                }
                else {
                    updatedChartSF = {
                        labels: ['No dati'],
                        datasets: [
                            {
                                label: 'No dati',
                                data: [0]
                            },
                        ]
                    };
                    setChartDataSF(updatedChartSF);
                }


            }
        });

    }

    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ambiente.ideAnnFro);

            if (ambiente.ideTipPer >= 0) {
                Ext.ComponentQuery.query('#comboPeriodoRto')[0].setValue(ambiente.ideTipPer);
            }
            avviaRicerca();

            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });

        }
    };
    const aggiornaStateAmbiente = () => {

        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        stateAmbiente.ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : -1;
        setStateAmbiente({ ...stateAmbiente });

        avviaRicerca();
    }

    useEffect(() => {
        //caricaPeriodiFerroviari();
        //caricaPeriodoRTO();
        caricaImpresaFerroviaria();
    }, []);

    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);

    //------PERIODO FERROVIARIO----
    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente StatisticheFinalizzazione SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente StatisticheFinalizzazione SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
        //----------------------------------------------------------------
    }
    //---------------FILTRO PERIODO RTO------------------------
    function onSelectPeriodoRto(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo rto
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoRto')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoRto')[0] != null) {
                let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                if (valTipPer != stateAmbiente.ideTipPer) {
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente StatisticheFinalizzazione SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente StatisticheFinalizzazione SPF ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
        //----------------------------------------------------------------
    }
    function onClearPeriodoRto() {
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: null }).then(
            function (result) {
                aggiornaStateAmbiente();
                //console.log('sono in updateAmbiente StatisticheFinalizzazione SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                //console.log('sono in updateAmbiente StatisticheFinalizzazione SPF ideTipPer = ', -1);
            }
        );
    }

    function onSelectImpresa(e) {
        avviaRicerca();
    }
    //--------------GRAFICO TOTALI------------------------------
    const dataTotali = {
        labels: ["RTO Aperte", "RTO Annullate", "RTO Rifiutate", "RTO Accettate", "RTO Contrattualizzate"],
        datasets: [{
            label: 'Anno Orario',
            data: [4000, 3000, 5000, 6000, 3500, 7000]
        }, {
            label: 'VCO',
            data: [2000, 1000, 3000, 4000, 1500, 5000]
        }, {
            label: 'Adeguamento',
            data: [2000, 1000, 3000, 4000, 1500, 5000]
        }
        ]
    };



    //--------------
    function onClearPeriodoRto() {
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: stateAmbiente.ideAnnFro, ideTipPer: -1 }).then(
            function (result) {
                //console.log('sono in updateAmbiente e ideTipPer = ', result);

                //console.log('sono in updateAmbiente e ambiente.ideTipPer = ', stateAmbiente.ideTipPer);
            }
        );
    }

    //--------------CONFERMA/AVVIA RICERCA --------------------------
    const avviaRicerca = () => {
        let ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        let ideCli = parseInt(Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].getValue()) > 0 ? Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].getValue() : null;
        let ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
        caricaStatisticheFinalizzazione(user.ideUte, ideAnnFro, ideTipPer, ideCli);
        loadGraph(ideAnnFro, ideTipPer);
    }

    //--------------PULISCI FILTRI------------------------------------
    const handlePulisciFiltri = (btn) => {
        Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].setValue(null);
    };

    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridReportDett')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }
    const getTotale = (record) => {
        let totale = record.data.totAccettate + record.data.totAnnullate + record.data.totAperte + record.data.totContrattualizzate + record.data.totRifiutate;
        return totale
    }


    return (


        <Container cls="container-spinner" height="100%" width="100%" layout="fit" margin='3 0 0 0'>

            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-list-check" />
                    <Combobox itemId="comboPeriodoFerroviario" align="right" width={170} margin='0 10 0 0' editable="false" valueField="ideAnnFro" displayField="desAnnFro"
                        queryMode="local"
                        store={storePeriodiFerroviari}
                        onSelect={onSelectPeriodoFerroviario}
                    />
                    <Combobox itemId="comboPeriodoRto" width={150} margin='0 4 0 0' docked="right" align="right" editable="false" valueField="ideTipPer" displayField="desTipPer"
                        store={storePeriodoRTO}
                        clearable
                        placeHolder="Periodo RTO"
                        class="placeholder"
                        queryMode="local"
                        onSelect={onSelectPeriodoRto}
                        onChange={onSelectPeriodoRto}
                        onClearicontap={onClearPeriodoRto}
                    />
                </Titlebar>

                <Toolbar docked="top" margin="10 0 10 10">
                    <Combobox itemId="comboImpresaFerroviaria" docked="left" align="left" width={520} height={25} margin='0 4 0 0' editable="false" valueField="ideCli" displayField="nomCli"
                        label="Impresa Ferroviaria"
                        labelAlign="left"
                        labelWidth={120}
                        queryMode="local"
                        clearable
                        forceSelection="true"
                        store={storeImpresa}
                        onSelect={onSelectImpresa}
                        onChange={onSelectImpresa}
                    />
                    <SegmentedButton docked="right">
                        <Button cls="marginButton ricercaAvanzataButton" align="right" text="Pulisci filtri" iconCls={'fa-solid fa-filter-circle-xmark'} ui="action" handler={handlePulisciFiltri} />
                        <Button cls="marginButton appButton" align="right" text="Avvia ricerca" iconCls={'x-fa fa-search'} ui="action" handler={avviaRicerca} />
                        <Button cls="marginButton exportButton" docked="right" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                    </SegmentedButton>
                </Toolbar>

                <Container layout="vbox" scrollable >

                    <Container cls="chart-container" >
                        <Panel title="Totali Periodo Ferroviario" padding="10" margin="10" >
                            <Container >
                                <Stacked datalabelsVisibility={false} chartData={chartDataSF} aspectRatio={2} indexAxis={'y'} height={200}></Stacked>
                            </Container>
                        </Panel>
                    </Container>

                    <Grid flex={1} scrollable cls="custom-grid" itemId="gridReportDett" itemConfig={{ viewModel: true }}
                        minHeight={420}
                        selectable={{ checkbox: false, mode: 'single' }}
                        plugins={['gridfilters', 'gridexporter']}
                        store={storeStatisticheFinalizzazione}
                        loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                    >

                        <Column height="40" width={170} text="Periodo Ferroviario" dataIndex="desAnnFro" filter='string' exportStyle={{ width: '170' }} />
                        <Column height="40" width={150} text="Periodo RTO" dataIndex="desTipPer" filter='string' exportStyle={{ width: '120' }} />
                        <Column height="40" width={380} text="IF" flex={1} dataIndex="nomCli" filter='string' exportStyle={{ width: '300' }} />
                        <Column height="40" width={170} text="RTO Contrattualizzate" dataIndex="totContrattualizzate" filter='number' exportStyle={{ width: '430' }} />
                        <Column height="40" width={140} text="RTO Accettate" dataIndex="totAccettate" filter='number' exportStyle={{ width: '430' }} />
                        <Column height="40" width={140} text="RTO Rifiutate" dataIndex="totRifiutate" filter='string' exportStyle={{ width: '170' }} />
                        <Column height="40" width={140} text="RTO Annullate" dataIndex="totAnnullate" filter='string' exportStyle={{ width: '170' }} />
                        <Column height="40" width={140} text="RTO Aperte" dataIndex="totAperte" filter='string' exportStyle={{ width: '170' }} />
                        <Column height="40" width={200} text="Totale" renderer={(value, record) => getTotale(record)} exportRenderer={(value, record) => getTotale(record)} filter='string' exportStyle={{ width: '170' }} />


                    </Grid>

                </Container>



            </Panel>
        </Container>


    );
}

export default StatisticheFinalizzazione;