import React from "react";
import { useState, useEffect } from "react";
import {
  Container,
  Panel,
  TitleBar,
  Image,
  Button,
  ToolTip,
  Label,
} from "@sencha/ext-react-modern";
import { Outlet } from "react-router-dom";
//import { Link } from 'react-router';
import { useAmbiente } from "../components/AmbienteContext";
import AppTitle from "../home/AppTitle";
import FooterBar from "../home/FooterBar";
import NavTree from "../components/NavTree";
import Breadcrumb from "../components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useUser } from "../components/UserContext"; // *** da inserire per richiamare l'utente loggato
import { NotificationButton } from "./NotificationButton";

const Ext = window["Ext"];

function Home() {
  const { ambiente } = useAmbiente();
  const { user, switchUser } = useUser();
  let navigate = useNavigate();
  var panel = Ext.ComponentQuery.query("#menuPanel")[0];
  let panelMenuCollapse = panel != null ? panel.getCollapsed() : true;
  const [params, setParams] = useState({ collapse: panelMenuCollapse });
  const [notifiche, setNotifiche] = useState([]);
  const [isLoadingNotifiche, setIsLoadingNotifiche] = useState(false);
  const [versioneApp, setVersioneApp] = useState("");
  const redCss = "custom-button-notification  custom-red-button-notification";
  const blueCss = "custom-button-notification  custom-blue-button-notification";
  const greenCss =
    "custom-button-notification  custom-green-button-notification";
  const yellowCss =
    "custom-button-notification  custom-yellow-button-notification";

    async function fetchData() {
        try {
            setIsLoadingNotifiche(true)
            if (ambiente?.ideUte && ambiente?.ideAnnFro) {
                await Ext.Ajax.request({
                    url: "HomePage/Notifiche",
                    method: "GET",
                    params: {
                        ideUte: ambiente?.ideUte,
                        ideAnnFro: ambiente?.ideAnnFro,
                        ideTipPer: ambiente?.ideTipPer,
                    },
                    timeout: 300000,
                    callback: function (records, success, operation) {
                        if (success && records != null) {
                            setNotifiche(Ext.decode(operation.responseText));
                            //console.log("RISULTATI", (Ext.decode(operation.responseText)))
                            setIsLoadingNotifiche(false)
                        } else {
                            console.error("Nessun dato presente:", success, records);
                            setIsLoadingNotifiche(false)
                        }
                    },
                });
            } else {
                //console.log("Parametri di ambiente null", ambiente);
                setIsLoadingNotifiche(false)
            }
        } catch (error) {
            console.error("Errore durante il recupero delle notifiche:", error);
            setIsLoadingNotifiche(false)
        }
    }

    useEffect(() => {
        Ext.Ajax.request({
            url: 'Anagrafiche/LeggiVersioneApp',
            method: 'GET',
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);
                    setVersioneApp(returnObj);
                }
            }
        });        
    }, []);

  useEffect(() => {
      fetchData();

      const interval = setInterval(() => {
          fetchData(); //aggiorna ogni 90 sec
      }, 90000);
      return () => clearInterval(interval);

  }, [ambiente]);
  //console.log(ambiente);

  // const collapseMenu = params.collapse;
  function toggleMenu() {
    updateCollapseMenu(!params.collapse);
  }
  function updateCollapseMenu(value) {
    params.collapse = value;
    setParams({ ...params });
  }

  function leggiCookieLoggedInH(cookieName) {
    const cookieString = RegExp(cookieName + "=[^;]+").exec(document.cookie);
    //const cookies = cookieString.split(';');
    return decodeURIComponent(
      !!cookieString ? cookieString.toString().replace(/^[^=]+./, "") : ""
    );
  }

  const headerStyle = {
    backgroundColor: "#003780",
  };

  const handleLogOut = () => {
    Ext.Msg.confirm(
      "Conferma disconnessione",
      "L'utente verrà disconnesso. Si vuole procedere?",
      function (button) {
        if (button === "yes") {
          const contenutoCookie = leggiCookieLoggedInH("AstroIFCookie");
          if (contenutoCookie) {
            Ext.Ajax.request({
              url: "InfoUser/DelCookie",
              method: "POST",
              params: {
                nomeCookie: "AstroIFCookie",
              },
              callback: function (records, success, operation) {
                //console.log("success DelCookie:", success);
                //console.log("records DelCookie:", records);
                if (success) {
                  console.log("Cookie cancellato:");
                  switchUser(null);
                  navigate("/");
                }
              },
            });
          } else {
            switchUser(null);
            navigate("/");
          }
        }
      }
    );
  };

  const gotoPage = (text) => {
    const params = {
      ideTipPer: ambiente?.ideTipPer,
      ideAnnoFer: ambiente?.ideAnnFro,
      pagina: text,
    };
      //navigate(`/MainRto`, { stateFromNotifiche: params });
      navigate(`/MainRto`, { state: params });
  };
  const gotoCliente = () => {    
    navigate(`/Cliente`);
  };
  const gotoHome = () => {    
    navigate(`/`);
  };
  //gridNotifiche, da cancellare

  const isNumberGreaterThanZero = (numeroNotificheString) => {
    if (numeroNotificheString === null || numeroNotificheString === undefined) {
      return false;
    } else {
      let parsed;
      if (isNaN(numeroNotificheString)) {
        parsed = parseFloat(numeroNotificheString.replace(/k/i, "") || "0");
      } else {
        parsed = parseFloat(numeroNotificheString);
      }
      return !isNaN(parsed) && parsed > 0;
    }
  };

  const getbuttonScadenzaCdSClass = (numberString) => {
    if (
      numberString == null ||
      numberString === undefined ||
      numberString === ""
    ) {
      return null;
    }
    let parsed;
    let value;
    if (isNaN(numberString)) {
      parsed = parseFloat(numberString.replace(/k/i, "") || "0");
      const isK = /k$/i.test(numberString.trim());
      value = isK ? parsed * 1000 : parsed;
    } else {
      parsed = parseFloat(numberString);
      value = parsed;
    }

    if (value >= 0) {
      return redCss;
    } else if (value >= -60 && parsed < 0) {
      return blueCss;
    } else {
      return null;
    }
  };

    // -----------------------------------------------------------------------
    const CNT_CDS = "Scadenza CdS";
    const CNT_TSM_NTF = "RTO trasmesse da IF";
    const CNT_MOD_NTF = "RTO modificate (in attesa di accettazione)";
    const CNT_SCD_NTF = "RTO in scadenza";
    const CNT_UPD_NTF = "RTO aggiornate";
    const CNT_RDI_ACZ = "RTO accettate da RDI";
    const CNT_RDI_RIF = "RTO rifiutate da RDI";
    const CNT_RDI_INU = "RTO incongruenti con PO";
    const CNT_TSM_ACZ = "RTO trasmesse (con valutazione I.I. OK)";
    const CNT_TSM_ATT = "RTO trasmesse (in attesa di valutazione I.I.)";
    const CNT_TSM_RIF = "RTO trasmesse (con valutazione I.I. KO)";
    const CNT_TSM_INU = "RTO trasmesse (con PO incongruente)";
    const CNT_TSM_BLU = "RTO trasmesse (valutazione I.I. non necessaria)";
    // -----------------------------------------------------------------------

  const notificheDiv =
    ambiente?.ideUte && ambiente?.ideAnnFro ? (
      isLoadingNotifiche ? (
        <Button cls={redCss} iconCls={'fa-solid fa-spinner fa-pulse'}></Button>
      ) : user?.idTipoDominio === 1 ? (
        <>
          <NotificationButton
            count={notifiche?.cnT_SCD_NTF}
            cls={redCss}
            iconCls="x-far fa-calendar-check"
            page={CNT_SCD_NTF}
            name={CNT_SCD_NTF}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_TSM_NTF}
            cls={blueCss}
            iconCls="x-fa fa-arrow-right-to-bracket"
            page={CNT_TSM_NTF}
            name={CNT_TSM_NTF}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_MOD_NTF}
            cls={blueCss}
            iconCls="x-fa fa-pen-to-square"
            page={CNT_MOD_NTF}
            name={CNT_MOD_NTF}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_RDI_ACZ}
            cls={greenCss}
            iconCls="x-fa fa-train-tram"
            page={CNT_RDI_ACZ}
            name={CNT_RDI_ACZ}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_RDI_RIF}
            cls={redCss}
            iconCls="x-fa fa-train-tram"
            page={CNT_RDI_RIF}
            name={CNT_RDI_RIF}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_RDI_INU}
            cls={redCss}
            iconCls="x-fas fa-triangle-exclamation"
            page={CNT_RDI_INU}
            name={CNT_RDI_INU}
            funzione={gotoPage}
          />
        </>
      ) : user?.idTipoDominio === 2 ? (
        <>
          <NotificationButton
            count={notifiche?.cnT_SCD_NTF}
            cls={redCss}
            iconCls="x-far fa-calendar-check"
            page={CNT_SCD_NTF}
            name={CNT_SCD_NTF}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_TSM_ACZ}
            cls={greenCss}
            iconCls="x-fa fa-envelope"
            page={CNT_TSM_ACZ}
            name={CNT_TSM_ACZ}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_TSM_ATT}
            cls={yellowCss}
            iconCls="x-fa fa-envelope"
            page={CNT_TSM_ATT}
            name={CNT_TSM_ATT}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_TSM_RIF}
            cls={redCss}
            iconCls="x-fa fa-envelope"
            page={CNT_TSM_RIF}
            name={CNT_TSM_RIF}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_TSM_BLU}
            cls={redCss}
            iconCls="x-fas fa-envelope"
            page={CNT_TSM_BLU}
            name={CNT_TSM_BLU}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_TSM_INU}
            cls={redCss}
            iconCls="x-fas fa-triangle-exclamation"
            page={CNT_TSM_INU}
            name={CNT_TSM_INU}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_MOD_NTF}
            cls={blueCss}
            iconCls="x-fa fa-pen-to-square"
            page={CNT_MOD_NTF}
            name={CNT_MOD_NTF}
            funzione={gotoPage}
          />
          <NotificationButton
            count={notifiche?.cnT_UPD_NTF}
            cls={blueCss}
            iconCls="x-fas fa-arrows-rotate"
            page={CNT_UPD_NTF}
            name={CNT_UPD_NTF}
            funzione={gotoPage}
          />
          {getbuttonScadenzaCdSClass(notifiche?.cnT_CDS) != null && (
            <Button
                text={notifiche?.cnT_CDS}
                cls={getbuttonScadenzaCdSClass(notifiche?.cnT_CDS)}
                iconCls="x-fa fa-certificate"
                handler={() => gotoCliente()}
                margin="0"
                align="right"
                tooltip={CNT_CDS}
              >
            </Button>
          )}
        </>
      ) : (
        <></>
      )
    ) : (
      <></>
    );

  const accessoNegato = 
  <div className="denied-div"> 
    <p className="text-denied">Accesso disabilitato: Contattare RFI</p> 
  </div>
           
        

  return (
    <Container layout="fit" viewport="true" fullscreen>
        <Panel layout="vbox" flex={1}>

              <AppTitle versioneApp={versioneApp} />

            <Container
                cls="userButtonsContainer"
                layout={{ type: "hbox", align: "center" }}
                style={headerStyle}
            >
                <Panel padding="0" layout="fit" height="25px">
                <Button
                    align="left"
                    iconCls="x-fa fa-bars"
                    arrow={false}
                    ui="action"
                    handler={toggleMenu}
                    cls="hamburger-menu-button"
                />
                <Button
                    align="left"
                    iconCls="x-fa fa-house"
                    arrow={false}
                    ui="action"
                    handler={gotoHome}
                    cls="hamburger-menu-button"
                />
                {/*iconCls="x-fa fa-bars buttonMenu"*/}
                {/* <Breadcrumb key={uniqid()} selectedItem={this.state.selectedItem} onFunctionID={this.onFunctionID} />*/}
                <Breadcrumb layout="fit" />
                </Panel>
                {/* <Button cls="logout-button" margin="0 10 0 0" align="right" iconCls="x-fa fa-right-from-bracket" text="Esci" /> */}
                <Container cls="userInfo" layout={{ type: "hbox" }}>
                  {user.ruolo === "IFDISAB" ? accessoNegato : notificheDiv}
                  {/*<Button*/}
                  {/*    cls="logout-button"*/}
                  {/*    margin="0 9 2 0"*/}
                  {/*    align="right"*/}
                  {/*    iconCls="x-fa fa-door-open"*/}
                  {/*    handler={handleLogOut}*/}
                  {/*    tooltip="Esci"*/}
                  {/*/>*/}
                </Container>
    
            </Container>

            <Panel layout="fit" flex={1}>
                <Panel layout="hbox" padding="1 1 4 1">
                <NavTree
                    collapseMenu={params.collapse}
                    updateCollapseMenu={updateCollapseMenu}
                />
                      <Panel shadow flex={1}>
                    <Outlet />
                </Panel>
                </Panel>
            </Panel>

            {/* <FooterBar versioneApp={versioneApp} /> */}

        </Panel>
    </Container>
  );
}

export default Home;
