import React from "react";
import { Container, Panel } from '@sencha/ext-react-modern';
import { useLocation } from "react-router-dom";

function NotValidUser() {
    let location = useLocation();
    return (
        <Container height="100%" width="100%" layout={{type: 'vbox', pack: 'top'}}  
        style={{backgroundImage: "url(/sfondo2.png)",  backgroundRepeat: 'no-repeat', backgroundSize:'100% 100%'}} 
        >
              <Panel  layout={{
                    type: 'hbox',
                    pack: 'center'                   
                }}
                margin="80 30 20 30"
                background= '#003780'
                // bodyStyle={{background:'transparent'}}  
                
                >   
                    <div>
                    <h1>Utente disconnesso.</h1>
                    <p><a href="https://picweb.rfi.it/">
                        Clicca qui per tornare alla home page di PIC Web
                    </a></p>
                    </div>
                </Panel>
        </Container>
    );
}

export default NotValidUser;
