import React, { useEffect, useState, useRef } from "react";
import { Container, FormPanel, Panel, Titlebar, Grid, Column, Button, ExtButton, Dialog, SegmentedButton, DatePickerField, ComboBoxField, CheckBoxField } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";
import { useNavigate } from "react-router-dom";



const Ext = window['Ext'];

const TIPI_MATERIALE = [
    { text: 'Ordinario', value: 'Ordinario' },
    { text: 'Leggero', value: 'Leggero' }
];
const storeAnagraficaImpostazione = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiCodiceImpostazioneAnaSTI',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeAnagraficaLocomotori = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiLocomotoriAnaSTI',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeIF = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiListImpresaFerroviaria',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

function MaterialiSTIDetail(props) {

    const navigate = useNavigate();
    const { user } = useUser();
    const formPanelRef = useRef(null);
    const [records, setRecords] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [pubblicare, setPubblicare] = useState(null);
    const [tipoMateriale, setTipoMateriale] = useState(TIPI_MATERIALE[0].value);

    let numRecordSelText = "Righe selezionate : 0";
    const [numRecord, setNumRecord] = useState(0);


    let title = "Caratteristica STI del materiale";
    let ui = 'action';
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;

    var isRFICOMP = user.ruolo === 'RFI-PO-COMP';
    var isRFICC = user.ruolo === 'RFI-PO-RFICC';


    const onCloseDialog = () => {
        setDialogVisible(false);
        setPubblicare(null);
    };

    const handleCheckBoxChange = () => {
        setPubblicare(prev => (prev === "Si" ? "No" : "Si"));
    };

    const handleTipoMaterialeChange = (field, newValue) => {
        setTipoMateriale(field.newValue);
    };

    const caricaAnagraficaImpostazione = () => {
        storeAnagraficaImpostazione.load({
            callback: (records, operation, success) => {
                if (success) {
                    if (props.azione == "M" || props.azione == "C") {
                        Ext.ComponentQuery.query('#codiceImpostazioneField')[0].setValue(props.data.codImpostazione);
                    }
                } else {
                    console.error("Errore durante il caricamento dei dati");
                }
            }
        })
    }
    const caricaAnagraficaLocomotori = () => {
        storeAnagraficaLocomotori.load({
            callback: (records, operation, success) => {
                if (success) {
                    if (props.azione == "M" || props.azione == "C") {
                        Ext.ComponentQuery.query('#locomotoreField')[0].setValue(props.data.idLocomotore);
                    }
                } else {
                    console.error("Errore durante il caricamento dei dati");
                }
            }
        })
    }
    const caricaAnagraficaImprese = (ideOpaRfi) => {
        storeIF.load({
            params: {
                IdeOpaRfi: ideOpaRfi
            },
            callback: (records, operation, success) => {
                if (success) {
                    if (props.azione == "M" || props.azione == "C") {
                        Ext.ComponentQuery.query('#codiceImpreseField')[0].setValue(props.data.ideCli);
                    }

                } else {
                    console.error("Errore durante il caricamento dei dati");
                }
            }
        })
    }

    const validateDates = (datIniVal, datFinVal, flgPbl) => {
        // regole --------------------------------------------------------------------------------------------------------------------------
        // - Se non pubblicato verifica che il valore inserito come inizio validità sia di almeno 5 giorni successiva alla data attuale 
        //   (se pubblicato il valore non è modificabile e non effettua il controllo)
        // - Verifica sempre che il valore inserito come fine validità sia di almeno 5 giorni successiva alla data attuale 
        //----------------------------------------------------------------------------------------------------------------------------------

        if (!datIniVal) {
            Ext.Msg.alert('Errore', 'La data di inizio validità è obbligatoria.');
            return false;
        }

        const startDate = new Date(datIniVal);

        let fiveDaysLater = new Date();
        // Somma 5 giorni alla data odierna
        fiveDaysLater.setDate(fiveDaysLater.getDate() + 5);

        if (flgPbl = 0 && startDate < fiveDaysLater) {
            Ext.Msg.alert('Errore', 'La data di inizio validità deve essere di almeno 5 giorni successiva alla data attuale.');
            return false;
        }

        if (!datFinVal) {
        }
        else {
            const endDate = new Date(datFinVal);
            if (endDate < startDate) {
                Ext.Msg.alert('Errore', 'La data di fine validità deve essere maggiore alla data di inizio validità.');
                return false;
            }
            if (endDate < fiveDaysLater) {
                Ext.Msg.alert('Errore', 'La data di fine validità deve essere di almeno 5 giorni successiva alla data attuale.');
                return false;
            }
        }

        return true;
    };

    const handleSaveClick = () => {
        if (formPanelRef.current) {
            const formValues = formPanelRef.current.cmp.getValues();

            if (!validateDates(formValues.datIniVal, formValues.datFinVal, (!formValues.flgPbl ? 0 : formValues.flgPbl))) {
                return;
            }

            const url = props.azione === "M" ? 'STI/ModificaMaterialeConformeSTI' : 'STI/InserisciMaterialeConformeSTI';
            const successMessage = props.azione === "M" ? 'Materiale modificato con successo' : 'Materiale inserito con successo';
            const errorMessage = props.azione === "M" ? 'Errore durante la modifica del materiale' : 'Errore durante l\'inserimento del materiale';

            let ideSTITmp = props.data ? props.data.ideSTI : null;

            let paramI = {
                ideUte: user.ideUte,
                ipAddress: null,
                tipoMateriale: formValues.tipoMateriale,
                ideCliList: formValues.ideCli,
                ideLcm: formValues.ideLcm,
                codImp: formValues.codImp,
                datIniVal: formValues.datIniVal,
                datFinVal: formValues.datFinVal,
                flgPbl: !formValues.flgPbl ? 0 : formValues.flgPbl
            };

            let paramM = {
                ideUte: user.ideUte,
                ipAddress: null,
                tipoMateriale: formValues.tipoMateriale,
                ideSTI: ideSTITmp,
                ideCli: formValues.ideCli,
                ideLcm: formValues.ideLcm,
                codImp: formValues.codImp,
                datIniVal: formValues.datIniVal,
                datFinVal: formValues.datFinVal,
                flgPbl: !formValues.flgPbl ? 0 : formValues.flgPbl
            };

            Ext.Ajax.request({
                url: url,
                method: 'POST',
                params: props.azione === "M" ? paramM : paramI,
                success: (response) => {
                    const result = Ext.decode(response.responseText);
                    if (result.resp === "OK") {
                        Ext.toast({ message: successMessage, timeout: 4000 });
                        props.caricaMaterialiSTI();
                        props.chiudi();
                    } else {
                        Ext.Msg.alert(errorMessage, result.msgErr);
                    }
                },
                failure: () => {
                    Ext.Msg.alert('Errore', errorMessage);
                }
            });
        }

        setDialogVisible(false);
        setPubblicare(null);
    };




    useEffect(() => {
        caricaAnagraficaImpostazione();
        caricaAnagraficaLocomotori();
        if (user.idOperatoreRFI != null && user.idOperatoreRFI > 0) {
            caricaAnagraficaImprese(user.idOperatoreRFI);
        }
        if (props.azione === "M" || props.azione === "C") {
            const tipoMaterialeField = Ext.ComponentQuery.query('#tipoMaterialeField')[0];
            const datIniValField = Ext.ComponentQuery.query('#datIniValField')[0];
            const datFinValField = Ext.ComponentQuery.query('#datFinValField')[0];


            if (tipoMaterialeField && datIniValField && datFinValField) {
                tipoMaterialeField.setValue(props.data.tipoMateriale);
                datIniValField.setValue(new Date(props.data.datIniVal));
                datFinValField.setValue(new Date(props.data.datFinVal));
                setPubblicare(props.data.pubblicato);



            }
        }
    }, [props.azione, props.data, user.idOperatoreRFI]);


    useEffect(() => {
        const locomotoreField = Ext.ComponentQuery.query('#locomotoreField')[0];
        const codiceImpostazioneField = Ext.ComponentQuery.query('#codiceImpostazioneField')[0];

        if (locomotoreField && codiceImpostazioneField) {
            if (tipoMateriale === 'Ordinario') {
                locomotoreField.setDisabled(true);
                codiceImpostazioneField.setHidden(false);
            } else {
                locomotoreField.setDisabled(false);
                codiceImpostazioneField.setHidden(true);
            }
        }
    }, [tipoMateriale]);





    //const optionsImprese = Array.from(new Set(records.map(record => record.data.ideCli)))
    //    .map(ideCli => {
    //        const record = records.find(record => record.data.ideCli === ideCli);
    //        return {
    //            text: record.data.desCli,
    //            value: record.data.ideCli
    //        };
    //    });

    //const optionsLocomotori = Array.from(new Set(records.map(record => record.data.idLocomotore)))
    //    .map(idLocomotore => {
    //        const record = records.find(record => record.data.idLocomotore === idLocomotore);
    //        return {
    //            text: record.data.desLocomotore,
    //            value: record.data.idLocomotore
    //        };
    //    });

    //const optionsImpostazione = Array.from(new Set(records.map(record => record.data.codImpostazione)))
    //    .map(codImpostazione => {
    //        const record = records.find(record => record.data.codImpostazione === codImpostazione);
    //        return {
    //            text: record.data.desImpostazione,
    //            value: record.data.codImpostazione
    //        };
    //    });


    return (
        <Container cls="container-spinner" layout="fit" >
            <Panel
                layout="vbox"
                scrollable={true}
            >

                <FormPanel ref={formPanelRef} layout="vbox" padding={10}>
                    <Container layout="hbox" margin="0 0">
                        <ComboBoxField
                            name="tipoMateriale"
                            label="Tipo Materiale"
                            labelAlign="top"
                            itemId="tipoMaterialeField"
                            options={TIPI_MATERIALE}
                            displayField="text"
                            valueField="value"
                            queryMode="local"
                            flex={2}
                            margin="0 10 0 0"
                            value={tipoMateriale}
                            onChange={handleTipoMaterialeChange}
                            disabled={props.data?.pubblicato === "Si" || props.azione === "M" || props.azione === "C"}
                        />
                        <ComboBoxField
                            name="ideCli"
                            label="Imprese"
                            labelAlign="top"
                            multiselect={ props.azione === "N"? true : false }
                            itemId="codiceImpreseField"
                            store={storeIF}
                            labelWidth={60}
                            //options={optionsImprese}
                            displayField="nomCli"
                            valueField="ideCli"
                            queryMode="local"
                            flex={3}
                            disabled={props.data?.pubblicato === "Si" && props.azione === "M"}
                        />
                    </Container>
                    <Container layout="hbox" margin="0 0">
                        <ComboBoxField
                            name="codImp"
                            label="Cod. Impostazione"
                            labelAlign="top"
                            itemId="codiceImpostazioneField"
                            store={storeAnagraficaImpostazione}
                            //options={optionsImpostazione}
                            displayField="codImp"
                            valueField="codImp"
                            queryMode="local"
                            flex={2}
                            margin="0 10 0 0"
                            hidden={tipoMateriale !== 'Ordinario'}
                            disabled={props.data?.pubblicato === "Si" && props.azione === "M"}

                        />
                        <ComboBoxField
                            name="ideLcm"
                            label="Locomotore"
                            labelAlign="top"
                            itemId="locomotoreField"
                            //options={optionsLocomotori}
                            store={storeAnagraficaLocomotori}
                            displayField="desLcm"
                            valueField="ideLcm"
                            queryMode="local"
                            flex={3}
                            labelWidth={60}
                            hidden={tipoMateriale === 'Ordinario'}
                            disabled={props.data?.pubblicato === "Si" && props.azione === "M"}
                        />
                    </Container>
                    <Container layout="hbox" margin="0 0">
                        <DatePickerField
                            name="datIniVal"
                            itemId="datIniValField"
                            label="Inizio validità"
                            labelAlign="top"
                            flex={1}
                            margin="0 10 0 0"
                            disabled={props.data?.pubblicato === "Si" && props.azione === "M"}
                        />
                        <DatePickerField
                            name="datFinVal"
                            itemId="datFinValField"
                            label="Fine validità"
                            labelAlign="top"
                            flex={1}

                        />

                        <Container layout="vbox" margin="6 0 0 10">
                            <div className="x-form-item-label x-form-item-label-left">
                                <label>Pubblicare</label>
                            </div>
                            <Container layout="hbox" align="stretch">
                                <CheckBoxField
                                    boxLabel="Si"
                                    name="flgPbl"
                                    value={1}
                                    displayField="text"
                                    valueField="value"
                                    checked={pubblicare === "Si"}
                                    itemId="pubblicareField"
                                    disabled={props.data?.pubblicato === "Si" && props.azione === "M"}
                                />
                            </Container>

                        </Container>
                    </Container>


                    <Container layout="fit" margin="10 0 0 0" align="stretch">
                        {props.azione !== "M" && (
                            <Button text="Inserisci" handler={handleSaveClick} ui="action" margin="0 10 0 0" />
                        )}
                        {props.azione === "M" && (
                            <Button text="Modifica" handler={handleSaveClick} ui="action" margin="0 10 0 0" />
                        )}
                    </Container>
                </FormPanel>

            </Panel >
        </Container >

    );
}

export default MaterialiSTIDetail;