import React from 'react';
import { Panel, Container, Grid, Column } from '@sencha/ext-react-modern';

import PoServizi from './PoIIServizi.js';
import PoLegami from './PoIILegami.js';
import PoManovre from './PoIIManovre.js';

const Ext = window['Ext'];

// scheda Impegno Impianto per traccia
function PoII(props) {
 
    let schedaPo = props.schedaPo;
    let semaforiII = props.schedaPo.schede.impegnoImpiantoPO;

    let panelCollapsed = false; // props.index === 0 ? false : true;
    let flagII = (schedaPo.IIPO.impegnoImpianto.length > 0 || schedaPo.IIPO.serviziInfrastrutturaLocalita.length > 0 || schedaPo.IIPO.manovreImpegnoImpianto.length > 0 || schedaPo.IIPO.legamiLocalita.length > 0) ? true : false;

    function getIcoStato(value, record, field, metaData) {
        let icon = value.replace("_", "");
        return (<div className={"po-icon po" + icon} />);
    }
    function getIcoInc(value, record, field, metaData) {
        let icon;
        switch (value) {
            case 1:
                icon = 'warning';
                break;
            case 2:
                icon = 'warning-red';
                break;
            default:
                icon = '';
                break;
        }
        return (<div className={"po-icon po" + icon} />);
    }

    return (
        <Container>
            <Panel
                itemId={"poII" + props.ind}
                title={schedaPo.testataPO.desTrcOra}
                titleCollapse={true}
                cls='PnlSectionPOTR'
                iconCls="x-fa fa-train"
                collapsible={{ collapsed: panelCollapsed, direction: 'top' }}
            >
                {semaforiII.length > 0 && <Container margin="0 10 0 10" layout="vbox" cls="column-in-panel" flex={1} >
                    <Grid
                        border={true}
                        itemId="semaforiII"
                        flex={1}
                        minHeight={60}
                        maxHeight={250}
                        scrollable
                        store={semaforiII}
                        itemConfig={{ viewModel: true }}
                    >
                        <Column text="Localit&aacute" dataIndex="noM_LOC" flex={1}
                        />
                        <Column text="Note" dataIndex="note" flex={3}
                        />
                        <Column text="Inc." dataIndex="flG_INU" width={70} align="center" renderer={getIcoInc}
                        />
                        <Column text="Stato" dataIndex="imI_IMT" width={75} align="center" renderer={getIcoStato}
                        />
                    </Grid>
                </Container>}
                <Container margin="0 0 0 10">
                    <PoServizi storePoSchede={props.schedaPo} impegnoImpiantoPO={props.schedaPo.IIPO} />

                    <PoLegami storePoSchede={props.schedaPo} impegnoImpiantoPO={props.schedaPo.IIPO} />

                    <PoManovre storePoSchede={props.schedaPo} impegnoImpiantoPO={props.schedaPo.IIPO} />
                </Container>
            </Panel>
        </Container>

    );
}

export default PoII;