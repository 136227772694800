import React, { useEffect, useState } from "react";
import { Container, Panel, Titlebar, Grid, Column, Button, Toolbar, SegmentedButton, Combobox } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';
import ColumnGrouped from "../home/charts/column_grouped";
import Stacked from "../home/charts/stacked";

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.plugin.Exporter'

]);
//  -----------PERIODO FERROVIARIO  ----------------------------------------------
const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//-----------IMPRESE FERROVIARIE----------------------------------
const storeImpresa = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiListImpresaFerroviaria',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//-------------PERIODI RTO----------------------------
const storePeriodoRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiePeriodicita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});


const storeStatisticheFattibilita = Ext.create('Ext.data.Store', {
    proxy: {
        url: 'AnalisiRto/ReportStatisticheStudiFattibilita',
        method: 'GET',
        type: 'ajax',
        reader: {
            type: 'json'
        },
    },
    autoload: false,

});


function StatisticheFattibilita() {

    let title = "Statistiche Studi Fattibilita'";
    let ui = 'action';
    const { user } = useUser();
    const { ambiente, updateAmbiente } = useAmbiente();
    const { periodi } = usePeriodi();

    storePeriodiFerroviari.setData(periodi.periodiFerroviari);
    storePeriodoRTO.setData(periodi.periodoRTO);

    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });

    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    const [chartDataSF, setChartDataSF] = useState(null);


    //const caricaPeriodiFerroviari = () => {
    //    storePeriodiFerroviari.load({
    //    })
    //}
    //const caricaPeriodoRTO = () => {
    //    storePeriodoRTO.load({
    //    })
    //}
    const caricaImpresaFerroviaria = () => {
        storeImpresa.load({
            params: {
                IdeOpaRfi: user.idOperatoreRFI
            }
        })
    }

    const caricaStatisticheFattibilita = (ideUte, ideAnnFro, ideCli, ideTipPer) => {

        storeStatisticheFattibilita.load({
            params: {
                ideUte: ideUte,
                ideAnnFro: ideAnnFro,
                ideCli: ideCli,
                ideTipPer: ideTipPer
            },
            callback: function () {

            }
        });
    };

    const loadGraph = (pIdeAnnFro, pIdeTipPer) => {

        let updatedChartSF = null;
        let impresa = isIF ? user.ideCli : parseInt(Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0]._value);

        Ext.Ajax.request({
            url: 'AnalisiRto/ReportStatisticheStudiFattibilita',
            method: 'GET',
            params: {
                ideUte: user.ideUte,
                ideAnnFro: pIdeAnnFro,
                ideTipPer: pIdeTipPer,
                ideCli: impresa != -1 ? impresa : null
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);

                if (returnObj.length > 0) {
                    let responseData = returnObj;

                    let labels = [...new Set(responseData.map(item => item.desTipPer))];
                    let dataTotStudiNonConvertiti = new Array(labels.length).fill(0);
                    let dataTotSTudiConvertiti = new Array(labels.length).fill(0);

                    responseData.forEach(item => {
                        let index = labels.indexOf(item.desTipPer);
                        if (index !== -1) {
                            dataTotStudiNonConvertiti[index] += item.totStudiNonConvertiti;
                            dataTotSTudiConvertiti[index] += item.totSTudiConvertiti;

                        }
                    });

                    updatedChartSF = {
                        labels: labels,
                        datasets: [
                            {
                                label: 'Non convertiti in RTO',
                                data: dataTotStudiNonConvertiti,
                                backgroundColor: 'rgba(246,161,8,1)'

                            },

                            {
                                label: 'Convertiti in RTO',
                                data: dataTotSTudiConvertiti,
                                backgroundColor: 'rgba(100,149,237,1)'

                            },

                        ]
                    };
                    setChartDataSF(updatedChartSF);
                }
                else {
                    updatedChartSF = {
                        labels: ['No dati'],
                        datasets: [
                            {
                                label: 'No dati',
                                data: [0]
                            },
                        ]
                    };
                    setChartDataSF(updatedChartSF);
                }


            }
        });

    }

    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ambiente.ideAnnFro);

            if (ambiente.ideTipPer >= 0) {
                Ext.ComponentQuery.query('#comboPeriodoRto')[0].setValue(ambiente.ideTipPer);
            }
            avviaRicerca();

            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });

        }
    };
    const aggiornaStateAmbiente = () => {

        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        stateAmbiente.ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : -1;
        setStateAmbiente({ ...stateAmbiente });

        avviaRicerca();

    }

    useEffect(() => {
        //caricaPeriodiFerroviari();
        //caricaPeriodoRTO();
        caricaImpresaFerroviaria();
    }, []);

    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);


    //--------------CONFERMA/AVVIA RICERCA --------------------------
    const avviaRicerca = () => {
        let ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        let ideCli = parseInt(Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].getValue()) > 0 ? Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].getValue() : null;
        let ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
        caricaStatisticheFattibilita(user.ideUte, ideAnnFro, ideCli, ideTipPer);
        loadGraph(ideAnnFro, ideTipPer);
    }

    //------PERIODO FERROVIARIO----
    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente StatisticheFattibilita SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente StatisticheFattibilita SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
    }

    //---------------FILTRO PERIODO RTO------------------------
    function onSelectPeriodoRto(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo rto
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoRto')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoRto')[0] != null) {
                let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                if (valTipPer != stateAmbiente.ideTipPer) {
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente StatisticheFattibilita SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente StatisticheFattibilita SPF ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
    }
    function onClearPeriodoRto() {
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: null }).then(
            function (result) {
                aggiornaStateAmbiente();
                //console.log('sono in updateAmbiente StatisticheFattibilita SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                //console.log('sono in updateAmbiente StatisticheFattibilita SPF ideTipPer = ', -1);
            }
        );
    }

    function onSelectImpresa(e) {
        avviaRicerca();
    }

    //--------------PULISCI FILTRI------------------------------------
    const handlePulisciFiltri = (btn) => {
        Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].setValue(null);
    };
    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridStatFatt')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }




    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="fa-lg fa fa-arrow-up-right-dots" />

                    <Combobox itemId="comboPeriodoFerroviario" align="right" width={170} margin='0 10 0 0' editable="false" valueField="ideAnnFro" displayField="desAnnFro"
                        queryMode="local"
                        store={storePeriodiFerroviari}
                        onSelect={onSelectPeriodoFerroviario}
                    />
                    <Combobox itemId="comboPeriodoRto" width={150} margin='0 4 0 0' docked="right" align="right" editable="false" valueField="ideTipPer" displayField="desTipPer"
                        store={storePeriodoRTO}
                        clearable
                        queryMode="local"
                        onSelect={onSelectPeriodoRto}
                        onChange={onSelectPeriodoRto}
                        onClearicontap={onClearPeriodoRto}
                    />

                </Titlebar>

                <Toolbar docked="top" margin="10 0 10 10">

                    <Combobox itemId="comboImpresaFerroviaria" docked="left" align="left" width={320} height={25} margin='0 4 0 0' editable="false" valueField="ideCli" displayField="nomCli"
                        label="Impresa Ferroviaria"
                        labelAlign="left"
                        labelWidth={120}
                        queryMode="local"
                        clearable
                        forceSelection="true"
                        store={storeImpresa}
                        onSelect={onSelectImpresa}
                        onChange={onSelectImpresa}
                    />
                    <SegmentedButton docked="right">
                        <Button cls="marginButton ricercaAvanzataButton" align="right" text="Pulisci filtri" iconCls={'fa-solid fa-filter-circle-xmark'} ui="action" handler={handlePulisciFiltri} />
                        <Button cls="marginButton appButton" align="right" text="Avvia ricerca" iconCls={'x-fa fa-search'} ui="action" handler={avviaRicerca} />
                        <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                    </SegmentedButton>
                </Toolbar>

                <Container layout="vbox" scrollable >

                    <Container cls="chart-container" >

                        <Panel flex="1" title="Totali Periodo Ferroviario" padding="10" margin="10" >
                            <Container>
                                <Stacked datalabelsVisibility={false} chartData={chartDataSF} aspectRatio={2} indexAxis={'y'} height={200}></Stacked>
                            </Container>
                        </Panel>

                    </Container>
                    <Container layout={{ type: 'hbox', align: 'stretch' }} defaults={{ flex: 1 }} scrollable="vertical" minHeight={320} >

                        <Grid flex={1} scrollable cls="custom-grid" itemId="gridStatFatt" itemConfig={{ viewModel: true }}
                            selectable={{ checkbox: false, mode: 'single' }}
                            plugins={['gridfilters', 'gridexporter']}
                            store={storeStatisticheFattibilita}
                            loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                        >


                            <Column height="40" width={200} text="Periodo Ferroviario" dataIndex="desAnnFro" filter='string' exportStyle={{ width: '200' }} />
                            <Column height="40" width={250} text="Periodo RTO" dataIndex="desTipPer" filter='string' exportStyle={{ width: '150' }} />
                            <Column height="40" width={250} text="IF" dataIndex="nomCli" filter='string' flex={1} exportStyle={{ width: '500' }} />
                            <Column height="40" width={200} text="Convertiti in RTO" dataIndex="totSTudiConvertiti" filter='string' exportStyle={{ width: '430' }} />
                            <Column height="40" width={200} text="Non Convertiti in RTO" dataIndex="totStudiNonConvertiti" filter='string' exportStyle={{ width: '330' }} />
                            <Column height="40" width={200} text="Totale" dataIndex="totRTO" filter='string' exportStyle={{ width: '150' }} />


                        </Grid>
                    </Container>


                </Container>


            </Panel>
        </Container>

    );
}

export default StatisticheFattibilita;