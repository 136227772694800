import React from 'react';
import "./Style.css";
import { fixPeriodicita, getClassAndStyle } from './Funzioni';
import { weekdays, CalendarIcon } from './Costanti';

const CalendarioSintetico = ({ giorniTreno, periodicitaSintetico }) => {
  const periodicita = fixPeriodicita(periodicitaSintetico, '0', '6');
  return (
    <div className="weekdays-table">
      {weekdays.map((day, index) => {
        const { className, style } = getClassAndStyle(day, index, periodicita);
        return (
          <div className={className} key={index} style={style}>
            {
              day === 'Svg' ? (<CalendarIcon />) : 
              day === 'Giorni' ? (<p>{giorniTreno}</p>) : 
              (day)
            }
          </div>
        );
      })}
      
    </div>
  );
};

export default CalendarioSintetico;
