import React, { Component, useEffect } from 'react';
import { Container, Panel, SearchField, Toolbar, TreeList } from '@sencha/ext-react-modern';
import { useNavigate } from "react-router-dom";
import { useUser } from '../components/UserContext';
import { useState } from "react";
import  menuIF  from '../data/menuIF.json';
import  menuRFI  from '../data/menuRFI.json';

const Ext = window['Ext'];


function NavTree({ collapseMenu, updateCollapseMenu }) {
    const navigate = useNavigate();
   
    const { user, switchUser } = useUser();
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;

    const collapse = collapseMenu;
    let store;
    //useEffect(() => {
        store = Ext.create('Ext.data.TreeStore', {
            rootVisible: true,
            root: {
                "rootVisible": true,
                "expanded": true,
                "text": "ApplicationMenu",
                "children": (isIF)? menuIF : menuRFI
            },
            filters: [
                function (node) {  //funzione che restituisce true per i nodi da visualizzare nel tree e false per i nodi da non visualizzare nel tree
                    return checkNotRoleInNode(node, user.ruolo); //va ad escludere dal tree tutti quelli che non hanno user.ruolo nella descrizione ruolo del nodo nel json
                }
            ]
        });
        //return () => {
        //    store.destroy();
        //};
    //}, []); 

    function checkNotRoleInNode(node, roleName) {
        let flgAbi = false;

        if (node.get('text') === 'ApplicationMenu') {
            return true;
        }
        if (!(node.get('ruolo') && node.get('ruolo').indexOf(roleName) !== -1)) {
           // console.log(`La condizione '${roleName}' non è soddisfatta nel nodo: ${node.get('text')}`);
            flgAbi=false;
        }
        else
            flgAbi = true;

        if (node.childNodes && node.childNodes.length > 0) { //se il nodo con user.ruolo non lo trova, continua sui figli
            for (var i = 0; i < node.childNodes.length; i++) {
                checkNotRoleInNode(node.childNodes[i], roleName);
            }
        }
        return flgAbi;
    }
    
    function onSelectionchange({ treelist, record })
    {
        if (record != undefined & record != null)
        {
            if (record.data?.leaf && record.data?.url != "")
            {
                var panel = Ext.ComponentQuery.query('#menuPanel')[0];
                panel.setCollapsed(true);
                updateCollapseMenu(true);
                navigate(record.data.url);
            } else {
                treelist.fireEvent('selectionchange', treelist, record);
            }
        }
    }

    return (
        
        <Panel
            id="menuPanel"
            scrollable="y"
            shadow
            layout="fit"
            header={false}
            collapsible={{direction: 'left' }}
            collapsed={collapse}
            >


            <TreeList
                cls="left-menu"
                id="treeListId"
                ui="nav"
                scrollable="true"
                store={store}
                expanderFirst={false}
                expanderOnly={false}
                onSelectionchange={onSelectionchange}
                //  selection={selection}
                />

            </Panel>

    );
}

export default NavTree;
