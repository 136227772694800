import React, { useEffect, useState } from "react";
import { Container, Panel, Titlebar, Grid, Column, Button, Toolbar, SegmentedButton, Combobox } from "@sencha/ext-react-modern";
import ColumnGrouped from "../home/charts/column_grouped";
import { useUser } from "../components/UserContext";
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';
import Stacked from "../home/charts/stacked";

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
]);
//  -----------PERIODI FERROVIARI ----------------------------------------------
const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//----------ANALISI MODIFICHE IN CORSO-------------
const storeAnalisiModInCorso = Ext.create('Ext.data.Store', {
    proxy: {
        url: 'AnalisiRto/LeggiAnalisiModificheRTOinCorso',
        method: 'GET',
        type: 'ajax',
        reader: {
            type: 'json'
        },
    },
    autoload: false,

});
//-------------PERIODI RTO----------------------------
const storePeriodoRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiePeriodicita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//-----------IMPRESE FERROVIARIE----------------------------------
const storeImpresa = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiListImpresaFerroviaria',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

function AnalisiModificheRtoInCorso() {

    let title = "Analisi Modifiche RTO In Corso";
    let ui = 'action';
    const { user } = useUser();

    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;

    const { ambiente, updateAmbiente } = useAmbiente();
    const { periodi } = usePeriodi();

    storePeriodiFerroviari.setData(periodi.periodiFerroviari);
    storePeriodoRTO.setData(periodi.periodoRTO);

    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });
    const [chartDataCorso, setChartDataCorso] = useState(null);

    //const caricaPeriodiFerroviari = () => {
    //    storePeriodiFerroviari.load({
    //    })
    //}
    //const caricaPeriodoRTO = () => {
    //    storePeriodoRTO.load({
    //    })
    //}
    const caricaImpresaFerroviaria = () => {
        storeImpresa.load({
            params: {
                IdeOpaRfi: user.idOperatoreRFI
            }
        })
    }


    const caricaAnalisiModInCorso = (ideUte, ideAnnFro, ideTipPer, ideCli) => {

        storeAnalisiModInCorso.load({
            params: {
                ideUte: ideUte,
                ideAnnFro: ideAnnFro,
                ideTipPer: ideTipPer,
                ideCli: ideCli,
            },
            callback: function () {

            }
        });
    };

    //-----------CARICAMENTO GRAFICO--------------------------------------------------------
    const loadGraph = (pIdeAnnFro, pIdeTipPer) => {

        let updatedChartCorso = null;
        let impresa = isIF ? user.ideCli : parseInt(Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0]._value);

        Ext.Ajax.request({
            url: 'AnalisiRto/LeggiAnalisiModificheRTOinCorso',
            method: 'GET',
            params: {
                ideUte: user.ideUte,
                ideAnnFro: pIdeAnnFro,
                ideTipPer: pIdeTipPer,
                ideCli: impresa !== -1 ? impresa : null
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);

                if (returnObj.length > 0) {
                    let responseData = returnObj;

                    let labels = [...new Set(responseData.map(item => item.desTipPer))];
                    let dataAtv = new Array(labels.length).fill(0);
                    let dataUpdGsr = new Array(labels.length).fill(0);
                    let dataUpdIpr = new Array(labels.length).fill(0);

                    responseData.forEach(item => {
                        let index = labels.indexOf(item.desTipPer);
                        if (index !== -1) {
                            dataAtv[index] += item.atv;
                            dataUpdGsr[index] += item.updGsr;
                            dataUpdIpr[index] += item.updIpr;
                        }
                    });

                    updatedChartCorso = {
                        labels: labels,
                        datasets: [
                            {
                                label: 'RTO Modificate (in attesa di IF)',
                                data: dataUpdIpr,
                                backgroundColor: 'rgba(246,161,8,1)'

                            },
                            {
                                label: 'RTO Modificate (in attesa di RFI)',
                                data: dataUpdGsr,
                                backgroundColor: 'rgba(255,8,8,1)'

                            },
                            {
                                label: 'RTO Attive',
                                data: dataAtv,
                                backgroundColor: 'rgba(52,204,52,1)'

                            }
                        ]
                    };
                    setChartDataCorso(updatedChartCorso);
                }
                else {
                    updatedChartCorso = {
                        labels: ['No dati'],
                        datasets: [
                            {
                                label: 'No dati',
                                data: [0]
                            },
                        ]
                    };
                    setChartDataCorso(updatedChartCorso);
                }


            }
        });

    }
    //------------------------------------------------------------------------------------------

    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF && ambiente.ideAnnFro != "") {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value = ambiente.ideAnnFro;

            if (ambiente.ideTipPer >= 0) {
                Ext.ComponentQuery.query('#comboPeriodoRto')[0]._value = ambiente.ideTipPer;
            }
            avviaRicerca();

            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });

        }
    };

    const aggiornaStateAmbiente = () => {

        avviaRicerca();
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        stateAmbiente.ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : -1;
        setStateAmbiente({ ...stateAmbiente });

    }

    useEffect(() => {
        //caricaPeriodiFerroviari();
        //caricaPeriodoRTO();
        caricaImpresaFerroviaria();
    }, []);


    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);

    //------PERIODO FERROVIARIO----
    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] !== undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] !== null) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() !== stateAmbiente.ideAnnFro) {
                    let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente AnalisiModificheInCorso SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente AnalisiModificheInCorso SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
        
        //----------------------------------------------------------------
    }

    //---------------FILTRO PERIODO RTO------------------------
    function onSelectPeriodoRto(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo rto
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoRto')[0] !== undefined && Ext.ComponentQuery.query('#comboPeriodoRto')[0] !== null) {
                let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                if (valTipPer != stateAmbiente.ideTipPer) {
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente AnalisiModificheInCorso SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente AnalisiModificheInCorso SPF ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
        //----------------------------------------------------------------
    }
    function onClearPeriodoRto() {
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue(), ideTipPer: null }).then(
            function (result) {
                aggiornaStateAmbiente();
                //console.log('sono in updateAmbiente AnalisiModificheInCorso SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                //console.log('sono in updateAmbiente AnalisiModificheInCorso SPF ideTipPer = ', -1);
            }
        );
        //Ext.toast({ message: ambiente.ideTipPer, timeout: 4000 });

    }

    function onChangePeriodoRto(e) {
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() })
    } function onSelectImpresa() {
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].getValue() })
    }

    //--------------CONFERMA/AVVIA RICERCA --------------------------
    const avviaRicerca = () => {
        let ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value;
        let ideCli = parseInt(Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].getValue() : null;
        let ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;

        caricaAnalisiModInCorso(user.ideUte, ideAnnFro, ideTipPer, ideCli);
        loadGraph(ideAnnFro, ideTipPer);
    }

   //--------------PULISCI FILTRI------------------------------------
    const handlePulisciFiltri = (btn) => {
        Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].setValue(null);
        Ext.ComponentQuery.query('#comboPeriodoRto')[0].setValue(null);
        onClearPeriodoRto();
        aggiornaStateAmbiente();

    };

    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridAnModInCor')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }




    return (

        <Container cls="container-spinner" height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-list-check" />
                    <Combobox itemId="comboPeriodoFerroviario" align="right" width={170} margin='0 10 0 0' editable="false" valueField="ideAnnFro" displayField="desAnnFro"
                        queryMode="local"
                        store={storePeriodiFerroviari}
                        onSelect={onSelectPeriodoFerroviario}
                    />
                    <Combobox itemId="comboPeriodoRto" width={150} margin='0 4 0 0' docked="right" align="right" editable="false" valueField="ideTipPer" displayField="desTipPer"
                        store={storePeriodoRTO}
                        clearable
                        placeHolder="Periodo RTO"
                        class="placeholder"
                        queryMode="local"
                        onSelect={onSelectPeriodoRto}
                        onChange={onChangePeriodoRto}
                        onClearicontap={onClearPeriodoRto}
                    />
                </Titlebar>

                <Toolbar docked="top" margin="10 0 10 10">
                    <Combobox itemId="comboImpresaFerroviaria" docked="left" align="left" width={520} height={25} margin='0 4 0 0' editable="false" valueField="ideCli" displayField="nomCli"
                        label="Impresa Ferroviaria"
                        labelAlign="left"
                        labelWidth={120}
                        queryMode="local"
                        clearable
                        forceSelection="true"
                        store={storeImpresa}
                        onSelect={onSelectImpresa}
                      // onChange={onSelectImpresa}
                    />
                    <SegmentedButton docked="right">
                        <Button cls="marginButton ricercaAvanzataButton" align="right" text="Pulisci filtri" iconCls={'fa-solid fa-filter-circle-xmark'} ui="action" handler={handlePulisciFiltri} />
                        <Button cls="marginButton appButton" align="right" text="Avvia ricerca" iconCls={'x-fa fa-search'} ui="action" handler={avviaRicerca} />
                        <Button cls="marginButton exportButton" docked="right" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                    </SegmentedButton>
                </Toolbar>

                <Container layout="vbox" scrollable >

                    <Container cls="chart-container" >

                        <Panel flex="1" title="Totali Periodo Ferroviario" padding="10" margin="10" >
                            <Container>
                                <Stacked datalabelsVisibility={false} chartData={chartDataCorso} aspectRatio={2} indexAxis={'y'} height={200}></Stacked>
                            </Container>
                        </Panel>

                    </Container>
                    <Container layout={{ type: 'hbox', align: 'stretch' }} defaults={{ flex: 1 }} scrollable="vertical" minHeight={320} >

                        <Grid flex={1} scrollable cls="custom-grid" itemId="gridAnModInCor" itemConfig={{ viewModel: true }}
                            selectable={{ checkbox: false, mode: 'single' }}
                            plugins={['gridfilters', 'gridexporter']}
                            store={storeAnalisiModInCorso}

                            loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                        >

                            <Column height="40" width={150} text="Periodo RTO" dataIndex="desTipPer" type="datecolumn" filter='string' exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                            <Column height="40" width={200} text="Periodo Ferroviario" dataIndex="desAnnFro" filter='string' exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                            <Column height="40" width={250} text="IF" dataIndex="nomCli" filter='string' flex={1} exportStyle={{ width: '500', alignment: { horizontal: 'Left' } }} />
                            <Column height="40" width={150} text="Rto Attive" dataIndex="atv" filter='number' exportStyle={{ width: '200', alignment: { horizontal: 'Left' } }} />
                            <Column height="40" width={250} text="RTO Modificate (in attesa di RFI)" dataIndex="updGsr" filter='number' flex={1} exportStyle={{ width: '330', alignment: { horizontal: 'Left' } }} />
                            <Column height="40" width={250} text="RTO Modificate (in attesa di IF)" dataIndex="updIpr" filter='number' flex={1} exportStyle={{ width: '330', alignment: { horizontal: 'Left' } }} />
                        </Grid>
                    </Container>

                </Container>




            </Panel>
        </Container>


    );
}

export default AnalisiModificheRtoInCorso;