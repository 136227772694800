const Ext = window['Ext'];

export default Ext.define('RtoServizioCommercialeModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideSerCmmRTO', type: 'int' },
            { name: 'datIni', type: 'date', format: 'd/m/Y' },
            { name: 'datFin', type: 'date', format: 'd/m/Y' },  
            { name: 'ideLocPrv', type: 'int' },
            { name: 'ideLocDsn', type: 'int' },
            { name: 'prgPrcPrv', type: 'auto' },
            { name: 'prgPrcDsn', type: 'auto' },
        ]
});
