import react, { useState, useContext } from "react";
import { Panel, FormPanel, Container, Button, ComboBoxField, CheckBoxField, DatePickerField, FileField, Spacer, Grid, Column, FieldSet, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import Calendario from "../components/Calendario/Calendario/Calendario.js";
import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
import Stazioni from "../components/Calendario/Stazioni/Stazioni.js";
import serviziInfrLocModel from '../models/ServiziInfrLocModel.js';
import { useUser } from '../components/UserContext';
import periodicitaModel from '../models/PeriodicitaIIServiziModel.js';

const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

const storePeriodicitaIIServizi = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

function PoIIServizi(props) {
    
    const [datiCalendario, setDatiCalendario] = useState({ isCalendarioVisible: false, titolo: "Periodicita servizio ..", dataInizio: null, dataFine: null, periodicita: "", periodicitaSintetico: "", giorniTreno: 0 });

    const contextVersioneRto = useContext(VersioneRtoContext);
    const poSchede = props.storePoSchede;
    const listaPO = contextVersioneRto.listaPO;
    const rtoSchede = contextVersioneRto.storeRtoSchede; 
    const { user, switchUser } = useUser();
  

    const storeServiziImpianto = Ext.create('Ext.data.Store', {
        proxy: {
            type: 'localstorage',
        },
        autoload: false
    });

    function onPeriodicitaClick(grid, info) {
        //caricaPeriodicitaServizoImpianto(info.record.data.idE_SER_IFS_LOC);
        datiCalendario.titolo = "Periodicit&aacute servizio " + info.record.data.noM_SER_IFS + " - " + info.record.data.noM_LOC;   
        
        datiCalendario.periodicitaSintetico = info.record.data.snG_GIO_SET;
        datiCalendario.giorniTreno = info.record.data.toT_GIO;
        datiCalendario.periodicita = info.record.data.snG_PER_ERR;
        datiCalendario.dataInizio = info.record.data.daT_INI_VAL;
        datiCalendario.dataFine = info.record.data.daT_FIN_VAL;
        datiCalendario.isCalendarioVisible = true;

        setDatiCalendario({ ...datiCalendario });
    }

    const caricaServiziImpianto = () => {
        storeServiziImpianto.loadData(props.impegnoImpiantoPO.serviziInfrastrutturaLocalita);
    }

    caricaServiziImpianto();


    return (
    <Container>
        <Panel
            title="Servizi Impianto"
            titleCollapse={true}
            iconCls="x-fa fa-charging-station"
            collapsible={{ collapsed: false, direction: 'top' }}
            cls='PnlSectionPOL2 iconPanelsRTO'
        >
            <Container
                layout="vbox" itemId="containerServPo" fitToParent={true} cls="column-in-panel"
            >
                <Grid
                    itemId="gridServiziImpiantoPo"
                    minHeight='100'
                    height='100'
                    disabled={datiCalendario.isCalendarioVisible}
                    markDirty={true}
                    flex={1}
                    scrollable
                    selectable={{ checkbox: false, mode: 'single' }}
                    store={storeServiziImpianto}
                    itemConfig={{
                        viewModel: true
                    }}
                >
                    <Column flex={1} text="Localita" dataIndex="noM_LOC" filter='string' />
                    <Column text="Servizio" dataIndex="noM_SER_IFS" flex={1}   />
                    <Column text="Area" dataIndex="noM_AREA" flex={1}  />
                    <Column text="Minuti" dataIndex="durata" filter='string' flex={1}  />
                    <Column text="Periodicit&aacute" dataIndex="periodicita" filter='string' flex={1} editable={false}
                        cell={{

                            tools: {

                                visualizzaPeriodicita: {

                                    handler: onPeriodicitaClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-calendar buttonMerciTool",

                                        tooltip: 'Periodicita'
                                    }
                                }

                            }

                        }}
                    />                    
                </Grid>
                {datiCalendario.isCalendarioVisible && (
                    <Panel title={datiCalendario.titolo} itemId="panelCalendarioPerServIIPo" closable={true} listeners=
                        {
                            {
                                close: function () {
                                        setDatiCalendario({ isCalendarioVisible: false, titolo: "Periodicita servizio ..", dataInizio: null, dataFine: null, periodicita: "", periodicitaSintetico: "", giorniTreno: 0 });  
                                }
                            }} 
                    >
                        <Panel collapsible={false} cls="calendar-container">
                            <br />
                            <div className="periodicitaHeader">
                                <CalendarioSintetico
                                        giorniTreno={datiCalendario.giorniTreno}
                                        periodicitaSintetico={datiCalendario.periodicitaSintetico}
                                />
                            </div>
                        </Panel>
                        <Panel collapsible={false}>
                            <Calendario
                                    dataInizio={datiCalendario.dataInizio}
                                    dataFine={datiCalendario.dataFine}
                                    periodicita={datiCalendario.periodicita}
                                dataAttProvvedimento={null}
                                    periodicitaSintetico={datiCalendario.periodicitaSintetico}
                                IsNonEffettuabile = {true}
                                isAttivazioneProvvedimento = {false}
                            />
                        </Panel>
                    </Panel>)}
            </Container>
        </Panel>
    </Container>
    );
}

export default PoIIServizi;