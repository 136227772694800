import React from 'react';
import { Panel, FormPanel, Container, Button, ComboBoxField, Loadmask, DatePickerField, TimeField, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { useState, useContext, useEffect } from "react";
import periodoFerroviarioModel from '../models/PeriodoFerroviarioModel.js';
import { useUser } from '../components/UserContext';
import { CopiaRtoContext } from '../components/CopiaRtoContext';

const Ext = window['Ext'];
var isAnnoOrAdeVCONUovaTraccia = false;
var isVcoAdeVariazione = false;
var ideRTOOriginaria = -1;
var flagRTO = 0;
var dataOriginaria = "";
var numTrenoRFIOrig = "";
var rangeDateStartProvvediamento = null;
var rangeDateEndProvvediamento = null;

const storeLotti = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Lotti/LeggiLotti',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    model: periodoFerroviarioModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});


var calcolaCaso = function (idePeriodo, ideTipoVco) {
    if (isNaN(ideTipoVco) && idePeriodo === 0) {
        isAnnoOrAdeVCONUovaTraccia = true;
        isVcoAdeVariazione = false;
    }
    else if (idePeriodo === 0 || ((idePeriodo === 1 || idePeriodo === 2) && ideTipoVco === 2)) {
        isAnnoOrAdeVCONUovaTraccia = true;
        isVcoAdeVariazione = false;
    }
    else if (((idePeriodo === 1 || idePeriodo === 2) && (!isNaN(ideTipoVco) && ideTipoVco !== 2))) {
        isAnnoOrAdeVCONUovaTraccia = false;
        isVcoAdeVariazione = true;
    }
    else {
        isAnnoOrAdeVCONUovaTraccia = false;
        isVcoAdeVariazione = false;
    }

}

const rtoOriginariaStore = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});
var filterConditionPerFerrVCOAde = function (record) {
    var dataIni = record.get('datIniVal');
    var dataFin = record.get('datFinVal');
    var compreso = new Date(dataIni) <= new Date() && new Date(dataFin) >= new Date();
    // Replace this with your actual filtering logic
    return new Date(dataIni) >= new Date() || compreso;
};
var filterConditionPerFerrAnn = function (record) {
    // Replace this with your actual filtering logic
    return new Date(record.get('datIniVal')) >= new Date();
};

function renderColorRow(value, record, field, metaData) {
    if (record.data.bcK_CLR === 'White') {
        let row = metaData.row;
        row.setStyle("background:#FFFFFF;");  // #adfd8c
    }
    else if (record.data.bcK_CLR === 'Red') {
        let row = metaData.row;
        row.setStyle("background:#FFDAB9;");  // pesca
    }
    else if (record.data.bcK_CLR === 'Blue') {
        let row = metaData.row;
        row.setStyle("background:#ADD8E6;");  // ligthblue
    }
    return value;
}
function RtoRiepilogoCopiaRto(props) {
    const contextVersioneRto = useContext(CopiaRtoContext);
    const { user, switchUser } = useUser();
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere salvataggio in corso..." });
    let disabilitaAccordoQuadro = false;
    let disabilitaCategoriaIF = false;
    var rangeDateStart = new Date(contextVersioneRto.storePeriodiFerroviari.data.items.find(pf => new Date(pf.data.datIniVal) <= (new Date()) && new Date(pf.data.datFinVal) >= (new Date())).data.datIniVal);
    var rangeDateEnd = new Date(contextVersioneRto.storePeriodiFerroviari.data.items.find(pf => new Date(pf.data.datIniVal) <= (new Date()) && new Date(pf.data.datFinVal) >= (new Date())).data.datFinVal);

    const caricaPeriodiFerroviari = () => {
        storePeriodiFerroviari.load({
            callback: (records, operation, success) => {
                if (success) {
                    if (contextVersioneRto.storeSchedaRto.data.items[0].data.ideTipPer === 0)
                        storePeriodiFerroviari.filterBy(filterConditionPerFerrAnn);
                    else
                        storePeriodiFerroviari.filterBy(filterConditionPerFerrVCOAde)
                    Ext.ComponentQuery.query('#dataProtocolloIFIdCopia')[0].enable();
                    Ext.ComponentQuery.query('#trenoIFIdCopia')[0].enable();
                    Ext.ComponentQuery.query('#periodoRtoIdCopia')[0].setValue(contextVersioneRto.storeSchedaRto.data.items[0].data.ideTipPer);
                }
            }
        });

    }
    function validatoreCampiRiepilogo(campo) {
        if (campo._required === true) {
            if (campo.getValue() !== null) {
                if (typeof campo.getValue() === 'string') {
                    if (campo.getValue().trim().length > 0)
                        return true;
                    else
                        return false;
                }
                else
                    return true;
            }
            else
                return false;
        }
        else
            return true;

    }

    function onConfermaCopiaIFClick() {
        if (Ext.ComponentQuery.query('#protocolloIFIdCopia')[0]._value !== contextVersioneRto.storeSchedaRto.data.items[0].data.ptlIF) {
            if (validatoreCampiRiepilogo(Ext.ComponentQuery.query('#periodoRtoIdCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#periodoFerroviarioIdCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#protocolloIFIdCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#trenoIFIdCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#categoriaIFIdCopia')[0]) &&
                (Ext.ComponentQuery.query('#periodoRtoIdCopia')[0]._value !== 2 || (Ext.ComponentQuery.query('#periodoRtoIdCopia')[0]._value === 2 && validatoreCampiRiepilogo(Ext.ComponentQuery.query('#dataProvvedimentoIdCopia')[0]))) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#origineIdCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#destinazioneIdCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#tipoRTOIdCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#tipoTrafficoIdCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#lottoIdCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#timeOraDestCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#dataProtocolloIFIdCopia')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#timeOraPartCopia')[0])) {
                var timeArrMod = "";
                var timeParMod = "";
                if (typeof Ext.ComponentQuery.query('#timeOraDestCopia')[0]._value !== 'string') {
                    timeArrMod = ('0' + Ext.ComponentQuery.query('#timeOraDestCopia')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#timeOraDestCopia')[0]._value.getMinutes()).slice(-2);
                }
                if (typeof Ext.ComponentQuery.query('#timeOraPartCopia')[0]._value !== 'string') {
                    timeParMod = ('0' + Ext.ComponentQuery.query('#timeOraPartCopia')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#timeOraPartCopia')[0]._value.getMinutes()).slice(-2);
                }

                setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                Ext.Ajax.request({
                    url: 'Rto/CopiaRTO',
                    method: 'POST',
                    params: {
                        ideRTO: contextVersioneRto.storeSchedaRto.data.items[0].data.ideRTO,
                        ideUte: user.ideUte,
                        protIF: Ext.ComponentQuery.query('#protocolloIFIdCopia')[0]._value,
                        ideLotto: Ext.ComponentQuery.query('#lottoIdCopia')[0]._value,
                        dataProtocolloIF: Ext.ComponentQuery.query('#dataProtocolloIFIdCopia')[0]._value,
                        dataProvvedimento: Ext.ComponentQuery.query('#dataProvvedimentoIdCopia')[0]._value,
                        ideAccordoQuadro: Ext.ComponentQuery.query('#accordoQuadroIdCopia')[0]._value,
                        ideTipPeriodo: Ext.ComponentQuery.query('#periodoRtoIdCopia')[0]._value,
                        numTrenoIF: Ext.ComponentQuery.query('#trenoIFIdCopia')[0]._value,
                        note: Ext.ComponentQuery.query('#noteIdCopia')[0]._value,
                        committente: Ext.ComponentQuery.query('#committenteIdCopia')[0]._value
                    },
                    callback: function (records, success, operation) {
                        setStateLoadMask({ hidden: true, message: "Attendere copia in corso..." });
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            //modifica gestione errore
                            if (returnObj.resp === "KO") {
                                Ext.Msg.alert('Copia fallita', returnObj.msgErr);
                            }
                            else {
                                Ext.toast({ message: 'Operazione terminata con successo!', timeout: 4000 });
                                props.RefreshRtoDaNuova(returnObj.rtoApp, user.idTipoGruppo, user.ideCli);
                            }
                        }
                        else {
                            Ext.Msg.alert('Copia fallita', 'Copia fallita');
                        }

                    }
                })
            }
            else
                Ext.toast({ message: 'Verificare i campi contrassegnati come obbligatori!', timeout: 4000 })
        }
        else {
            Ext.toast({ message: 'Inserire un protocollo IF diverso!', timeout: 4000 })
        }
    }

    useEffect(() => {
        if (contextVersioneRto.storeSchedaRto.data.items[0].data.ideTipPer === 2)
            Ext.ComponentQuery.query('#dataProvvedimentoIdCopia')[0].markInvalid('Questo campo � obbligatorio');
        if (contextVersioneRto.storeSchedaRto.data.items[0].data.ideLtx === null || contextVersioneRto.storeSchedaRto.data.items[0].data.ideLtx === "")
            Ext.ComponentQuery.query('#lottoIdCopia')[0].markInvalid('Questo campo � obbligatorio');
        caricaPeriodiFerroviari();
        calcolaCaso(contextVersioneRto.storeSchedaRto.data.items[0].data.ideTipPer, parseInt(Ext.ComponentQuery.query('#tipoVCOIdCopia')[0]._value));
        if (isAnnoOrAdeVCONUovaTraccia) {
            disabilitaAccordoQuadro = false;
            disabilitaCategoriaIF = false;

        }
        else if (isVcoAdeVariazione) {
            disabilitaAccordoQuadro = true;
            if ((contextVersioneRto.storeSchedaRto.data.items[0].data.ideTipPer !== 0 ? 2 : null) === 3)  //solo servizi
            {
                disabilitaCategoriaIF = true;
            }
            else if ((contextVersioneRto.storeSchedaRto.data.items[0].data.ideTipPer !== 0 ? 2 : null) === 1) //traccia e servizi
            {
                disabilitaCategoriaIF = false;
            }
        }
    }, [])


    return (
        <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoCop"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
            <Panel
                title="Riepilogo"
                scrollable
                itemId="panelRiepilogoCopia"
                bodyPadding={5}
                titleCollapse={true}
                iconCls="x-fa fa-th-list"
                cls='PnlSectionL1'
                tools={[
                    { iconCls: 'x-fa fa-check', itemId: "confermaRiepilogoCopia", handler: onConfermaCopiaIFClick.bind(this), tooltip: 'Conferma' }
                    /* { type: 'menu', handler: toolHandler }*/
                ]}
            >
                <FormPanel layout="hbox" >

                    <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                        <ComboBoxField
                            label="Periodo RTO"
                            labelAlign="top"
                            store={contextVersioneRto.storePeriodoRTO}
                            displayField="desTipPer"
                            valueField="ideTipPer"
                            queryMode="local"
                            itemId="periodoRtoIdCopia"
                            typeAhead
                            required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            forceSelection={true}
                            listeners={{
                                change: function (combo, value, oldValue) {
                                    value === 2 ? Ext.ComponentQuery.query('#dataProvvedimentoIdCopia')[0].enable() : Ext.ComponentQuery.query('#dataProvvedimentoIdCopia')[0].disable();
                                    //anno orario
                                    if (parseInt(value) === 0) {
                                        Ext.ComponentQuery.query('#periodoFerroviarioIdCopia')[0].getStore().clearFilter();
                                        Ext.ComponentQuery.query('#periodoFerroviarioIdCopia')[0].getStore().filterBy(filterConditionPerFerrAnn);
                                        Ext.ComponentQuery.query('#periodoFerroviarioIdCopia')[0].setValue(storePeriodiFerroviari.data.items[0].data.ideAnnFro);
                                        Ext.ComponentQuery.query('#tipoVCOIdCopia')[0].setValue(null);
                                    }
                                    //adeguamento o vco
                                    else {
                                        Ext.ComponentQuery.query('#periodoFerroviarioIdCopia')[0].getStore().clearFilter();
                                        Ext.ComponentQuery.query('#periodoFerroviarioIdCopia')[0].getStore().filterBy(filterConditionPerFerrVCOAde);
                                        Ext.ComponentQuery.query('#periodoFerroviarioIdCopia')[0].setValue(storePeriodiFerroviari.data.items[1].data.ideAnnFro);
                                        Ext.ComponentQuery.query('#tipoVCOIdCopia')[0].setValue(2);
                                    }
                                    calcolaCaso(parseInt(value), parseInt(Ext.ComponentQuery.query('#tipoVCOIdCopia')[0]._value));
                                    if (isAnnoOrAdeVCONUovaTraccia) {
                                        Ext.ComponentQuery.query('#accordoQuadroIdCopia')[0].enable();
                                        Ext.ComponentQuery.query('#categoriaIFIdCopia')[0].enable();
                                        Ext.ComponentQuery.query('#trenoIFIdCopia')[0].enable();

                                    }
                                    else if (isVcoAdeVariazione) {
                                        Ext.ComponentQuery.query('#accordoQuadroIdCopia')[0].disable();
                                        Ext.ComponentQuery.query('#accordoQuadroIdCopia')[0].setValue(null);
                                        Ext.ComponentQuery.query('#trenoIFIdCopia')[0].disable();
                                        Ext.ComponentQuery.query('#trenoIFIdCopia')[0].setValue(null);
                                        if (parseInt(Ext.ComponentQuery.query('#tipoVCOIdCopia')[0]._value) === 3)  //solo servizi
                                        {
                                            Ext.ComponentQuery.query('#categoriaIFIdCopia')[0].disable();
                                            Ext.ComponentQuery.query('#categoriaIFIdCopia')[0].setValue(null);
                                        }
                                        else if (parseInt(Ext.ComponentQuery.query('#tipoVCOIdCopia')[0]._value) === 1) //traccia e servizi
                                        {
                                            Ext.ComponentQuery.query('#categoriaIFIdCopia')[0].enable();
                                        }
                                    }


                                },
                                select: function (combo, value, oldValue) {
                                    if (parseInt(value.data.ideTipPer) === 0) {
                                        storePeriodiFerroviari.clearFilter();
                                        storePeriodiFerroviari.filterBy(filterConditionPerFerrAnn);
                                    }
                                    //adeguamento o vco
                                    else {
                                        storePeriodiFerroviari.clearFilter();
                                        storePeriodiFerroviari.filterBy(filterConditionPerFerrVCOAde);
                                    }

                                }


                            }}
                        />
                        <TextField clearable={false} label="Protocollo IF" itemId="protocolloIFIdCopia" maxLength={50} labelAlign="top" head="20" minwidth="200" required={true} requiredMessage="Questo campo &egrave; obbligatorio" value={contextVersioneRto.storeSchedaRto.data.items[0].data.ptlIF} />
                        <TextField clearable={false} label="N. Treno IF" itemId="trenoIFIdCopia" maxLength={100} labelAlign="top" labelCls='LabelRiepilogoBlu' head="20" required={true} requiredMessage="Questo campo &egrave; obbligatorio" />
                        <Panel layout="hbox" margin="4 0 4 0">
                            <ComboBoxField
                                label="Origine"
                                labelAlign="top"
                                itemId="origineIdCopia"
                                typeAhead
                                store={contextVersioneRto.storeLocalita}
                                displayField="nomLoc"
                                valueField="ideLoc"
                                labelCls='LabelRiepilogoBlu'
                                value={contextVersioneRto.storeSchedaRto.data.items[0].data.ideLocPrv}
                                required={true}
                                disabled={true}
                                requiredMessage="Questo campo &egrave; obbligatorio"
                                forceSelection={true}
                                queryMode="local"
                            />
                            <TimeField
                                label="Ora"
                                labelAlign="top"
                                width="100"
                                disabled={true}
                                itemId='timeOraPartCopia'
                                maxLength={5}
                                required={true}
                                requiredMessage="Questo campo &egrave; obbligatorio"
                                value={contextVersioneRto.storeSchedaRto.data.items[0].data.oraPar}
                                format='H:i'
                                picker={{
                                    meridiem: false,
                                    alignPMInside: true
                                }}
                            />
                        </Panel>
                        <ComboBoxField
                            label="Tipo RTO"
                            labelAlign="top"
                            store={contextVersioneRto.storeTipologieRTO}
                            displayField="desTipRTO"
                            itemId="tipoRTOIdCopia"
                            valueField="ideTipRTO"
                            queryMode="local"
                            required={true}
                            value={contextVersioneRto.storeSchedaRto.data.items[0].data.ideTipRTO}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            forceSelection={true}
                            typeAhead
                            disabled={true}
                        // onSelect={onSelectPeriodoRTO}
                        />
                    </Panel>

                    <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                        <ComboBoxField
                            label="Periodo Ferroviario"
                            labelAlign="top"
                            store={storePeriodiFerroviari}
                            displayField="desAnnFro"
                            itemId="periodoFerroviarioIdCopia"
                            valueField="ideAnnFro"
                            queryMode="local"
                            value={contextVersioneRto.storeSchedaRto.data.items[0].data.ideAnnFro}
                            listeners={{
                                change: function (combo, value, oldValue) {
                                    if (combo._selection !== null) {
                                        rangeDateStartProvvediamento = new Date(combo._selection.data.datIniVal);
                                        rangeDateEndProvvediamento = new Date(combo._selection.data.datFinVal);
                                        storeLotti.clearData();
                                        storeLotti.removeAll();
                                        storeLotti.load({
                                            params: {
                                                ideUte: user.ideUte,
                                                ideCli: user.ideCli,
                                                ideAnnFro: value
                                            }
                                        });
                                    }
                                },
                                select: function (combo, value, oldValue) {
                                    rangeDateStartProvvediamento = new Date(combo._selection.data.datIniVal);
                                    rangeDateEndProvvediamento = new Date(combo._selection.data.datFinVal);
                                    storeLotti.clearData();
                                    storeLotti.removeAll();
                                    storeLotti.load({
                                        params: {
                                            ideUte: user.ideUte,
                                            ideCli: user.ideCli,
                                            ideAnnFro: value.data.ideAnnFro
                                        }
                                    });
                                }
                            }}
                        // onSelect={onSelectPeriodoRTO}
                        />
                        <DatePickerField
                            clearable={false}
                            label="Data Prot.IF"
                            itemId="dataProtocolloIFIdCopia"
                            labelAlign="top"
                            required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            destroyPickerOnHide
                            picker={{
                                yearFrom: 1990
                            }}
                            listeners={{
                                change: function (filed, newValue) {
                                    if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                        Ext.ComponentQuery.query('#dataProtocolloIFIdCopia')[0].setValue(null);
                                    }
                                }
                            }}
                        />

                        <DatePickerField
                            clearable={false}
                            label="Data Provvedimento"
                            itemId="dataProvvedimentoIdCopia"
                            labelAlign="top"
                            labelCls='LabelRiepilogoBlu'
                            required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            destroyPickerOnHide
                            disabled={contextVersioneRto.storeSchedaRto.data.items[0].data.ideTipPer === 2 ? false : true}
                            picker={{
                                yearFrom: 1990
                            }}
                            listeners={{
                                change: function (filed, newValue) {
                                    if (newValue > rangeDateEndProvvediamento || newValue < rangeDateStartProvvediamento) {
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdCopia')[0].setValue(null);
                                    }
                                }
                            }}
                        />
                        <Panel layout="hbox" margin="4 0 4 0">
                            <ComboBoxField
                                label="Destinazione"
                                labelAlign="top"
                                itemId="destinazioneIdCopia"
                                typeAhead
                                labelCls='LabelRiepilogoBlu'
                                store={contextVersioneRto.storeLocalita}
                                displayField="nomLoc"
                                valueField="ideLoc"
                                required={true}
                                disabled={true}
                                value={contextVersioneRto.storeSchedaRto.data.items[0].data.ideLocDsn}
                                requiredMessage="Questo campo &egrave; obbligatorio"
                                forceSelection={true}
                                queryMode="local"
                            />
                            <TimeField
                                label="Ora"
                                labelAlign="top"
                                itemId='timeOraDestCopia'
                                requiredMessage="Questo campo &egrave; obbligatorio"
                                format='H:i'
                                required={true}
                                maxLength={5}
                                value={contextVersioneRto.storeSchedaRto.data.items[0].data.oraArr}
                                disabled={true}
                                width="100"
                                picker={{
                                    meridiem: false,
                                    alignPMInside: true
                                }}
                            />
                        </Panel>
                        <ComboBoxField
                            label="Tipo Traffico"
                            labelAlign="top"
                            store={contextVersioneRto.storeTipoTraffico}
                            displayField="desTipTra"
                            valueField="ideTipTra"
                            itemId="tipoTrafficoIdCopia"
                            queryMode="local"
                            disabled={true}
                            value={contextVersioneRto.storeSchedaRto.data.items[0].data.ideTipTra}
                            forceSelection={true}
                            typeAhead
                        />
                    </Panel>

                    <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                        <ComboBoxField
                            label="Tipo VCO"
                            labelAlign="top"
                            store={contextVersioneRto.storeTipoVCO}
                            displayField="etichetta"
                            itemId="tipoVCOIdCopia"
                            valueField="valore"
                            required={true}
                            queryMode="local"
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            forceSelection={true}
                            disabled={true}
                            typeAhead
                            value={contextVersioneRto.storeSchedaRto.data.items[0].data.ideTipPer !== 0 ? 2 : null}
                            listeners={{
                                change: function (combo, value, oldValue) {
                                    if (parseInt(Ext.ComponentQuery.query('#periodoRtoIdCopia')[0]._value) != null) {
                                        calcolaCaso(parseInt(Ext.ComponentQuery.query('#periodoRtoIdCopia')[0]._value), parseInt(value));
                                        if (isAnnoOrAdeVCONUovaTraccia) {
                                            Ext.ComponentQuery.query('#accordoQuadroIdCopia')[0].enable();
                                            Ext.ComponentQuery.query('#categoriaIFIdCopia')[0].enable();
                                            Ext.ComponentQuery.query('#trenoIFIdCopia')[0].enable();
                                            ////anno orario
                                            //if (parseInt(Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]._value) === 0) {
                                            //    Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].setValue(null);
                                            //    Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].disable();
                                            //}
                                            ////adeguamento
                                            //else if (parseInt(Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]._value) === 1) {
                                            //    Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].enable();
                                            //}
                                            ////vco
                                            if (parseInt(Ext.ComponentQuery.query('#periodoRtoIdCopia')[0]._value) === 2) {
                                                Ext.ComponentQuery.query('#dataProvvedimentoIdCopia')[0].enable();
                                            }
                                            else {
                                                Ext.ComponentQuery.query('#dataProvvedimentoIdCopia')[0].disable();
                                            }
                                        }
                                        else if (isVcoAdeVariazione) {
                                            Ext.ComponentQuery.query('#accordoQuadroIdCopia')[0].disable();
                                            Ext.ComponentQuery.query('#accordoQuadroIdCopia')[0].setValue(null);
                                            Ext.ComponentQuery.query('#trenoIFIdCopia')[0].disable();
                                            Ext.ComponentQuery.query('#trenoIFIdCopia')[0].setValue(null);
                                            if (parseInt(value) === 3) //solo servizi
                                            {
                                                Ext.ComponentQuery.query('#categoriaIFIdCopia')[0].disable();
                                                Ext.ComponentQuery.query('#categoriaIFIdCopia')[0].setValue(null);
                                            }
                                            else if (parseInt(value) === 1) //traccia e servizi
                                            {
                                                Ext.ComponentQuery.query('#categoriaIFIdCopia')[0].enable();
                                            }
                                        }
                                    }
                                }

                            }}
                        // onSelect={onSelectPeriodoRTO}
                        />
                        <TextField clearable={false} disabled={true} label="Protocollo RFI" itemId="prtotocolloRFIIdCopia" labelAlign="top" head="20" minwidth="200" />
                        <ComboBoxField
                            label="Categoria IF"
                            labelAlign="top"
                            store={contextVersioneRto.storeCatTreniIF}
                            displayField="desCatTre"
                            valueField="ideCatTre"
                            queryMode="local"
                            required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            labelCls='LabelRiepilogoBlu'
                            itemId="categoriaIFIdCopia"
                            forceSelection={true}
                            disabled={disabilitaCategoriaIF}
                            value={contextVersioneRto.storeSchedaRto.data.items[0].data.ideCatTreIF}
                            typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        />
                        <TextField clearable={false} label="Giorni RTO" itemId="giorniRTOIdCopia" labelCls='LabelRiepilogoBlu' labelAlign="top" disabled={true} head="20" value={contextVersioneRto.storeSchedaRto.data.items[0].data.giO_RTO} />
                        <ComboBoxField
                            label="Accordo Quadro"
                            labelAlign="top"
                            store={contextVersioneRto.storeAccordiQuadro}
                            displayField="desAcoQrd"
                            valueField="ideAcoQrd"
                            itemId="accordoQuadroIdCopia"
                            queryMode="local"
                            disabled={disabilitaAccordoQuadro}
                            forceSelection={true}
                            value={contextVersioneRto.storeSchedaRto.data.items[0].data.ideAcoQrd}
                            typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        />
                    </Panel>

                    <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                        <Button text="RTO Originaria" labelAlign="top" margin="23 0 5 0" itemId="rtoOriginariaIdCopia" head="20" disabled={true} />
                        <TextField clearable={false} label="Data Prot.RFI" itemId="dataProtocolloRFIIdCopia" labelAlign="top" head="20" disabled={true} />
                        <ComboBoxField
                            label="Committente"
                            labelAlign="top"
                            itemId="committenteIdCopia"
                            labelCls='LabelRiepilogoBlu'
                            store={contextVersioneRto.storeCommittenti}
                            displayField="nomeCommittente"
                            valueField="ideCommittente"
                            queryMode="local"
                            forceSelection={true}
                            typeAhead
                            value={0}
                        // onSelect={onSelectPeriodoRTO}
                        />
                        <ComboBoxField
                            label="Tipo Traccia"
                            labelAlign="top"
                            itemId="tipoTracciaIdCopia"
                            store={contextVersioneRto.storeTipoTraccia}
                            displayField="desTipTraccia"
                            valueField="ideTipTraccia"
                            disabled={true}
                            value={0}
                            queryMode="local"
                            typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        />
                        <ComboBoxField
                            label="Lotto"
                            labelAlign="top"
                            store={storeLotti}
                            displayField="nomLtx"
                            itemId="lottoIdCopia"
                            valueField="ideLtx"
                            required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            queryMode="local"
                            value={contextVersioneRto.storeSchedaRto.data.items[0].data.ideLtx}
                            forceSelection={true}
                            typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        />
                    </Panel>

                </FormPanel>


                <Panel
                    title="Note"
                    iconCls="x-fa fa-sticky-note"
                    collapsible={{ collapsed: false, direction: 'top' }}
                    cls='PnlSectionL1'
                    padding={3}
                >
                    <FormPanel >
                        <TextAreaField
                            flex={1}
                            maxRows={10}
                            maxLength={1000}
                            itemId="noteIdCopia"
                            clearable={false}
                        />
                    </FormPanel>
                </Panel>

            </Panel>
        </Container>
    );
}

export default RtoRiepilogoCopiaRto;