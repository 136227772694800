const Ext = window['Ext'];

export default Ext.define('ClientiReferentiModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideEfeCnt', type: 'auto' },
            { name: 'nom', type: 'auto' },
            { name: 'cog', type: 'auto' },
            { name: 'tel', type: 'auto' },
            { name: 'eml', type: 'auto' },
            { name: 'cel', type: 'auto' },
            { name: 'fax', type: 'auto' },
            { name: 'cgenFlg', type: 'auto' },
            { name: 'infoFlg', type: 'auto' },
            { name: 'ripoFlg', type: 'auto' },
            { name: 'fattFlg', type: 'auto' },
            { name: 'saopFlg', type: 'auto' },
            { name: 'eserFlg', type: 'auto' },
            { name: 'pedaFlg', type: 'auto' },
            { name: 'perfFlg', type: 'auto' },
            { name: 'servFlg', type: 'auto' },
            { name: 'gsmrFlg', type: 'auto' },
        ]
});
