import React from "react";
import { useNavigate } from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Button, Label } from '@sencha/ext-react-modern';
import '../App.css';

function Breadcrumb() {
    const breadcrumbs = useBreadcrumbs();
    const navigate = useNavigate();

    const breadcrumbPageToName = {
        'MainRto': 'Gestione RTO',
        'SchedaRto': 'Scheda RTO',
        'UploadPO': 'Associazione Massiva PO',
        'UploadRTO': 'Upload RTO',
        'Clienti': 'Gestione Cliente',
        'AcqDossierPCS': 'Acquisizione Dossier PCS',
        'PubDossierPCS': 'Pubblicazione PO PCS',
        'AnalisiRTOInternazionali': 'Analisi RTO Internazionali',
        'MonitoraggioCdS': 'Monitoraggio CdS IF Master',
        'GestioneUtenti': 'Gestione Utenti',
        'CatalogoMerci': 'Catalogo Merci',
        'GuidaUpload': 'Guida Upload',
        'Cliente': 'Scheda Cliente',
        'IFNews': 'Gestione Comunicazioni IF',
        'AuthPCS': 'Login RNE PCS',
        'ReportDettaglio': 'Report di Dettaglio',
        'ReportGenerale': 'Report Generale',
        'ReportStatoLavorazione': 'Report Stato Lavorazione RTO',
        'DownloadReport': 'Download Report',
        'AnalisiModificheInCorso': 'Analisi Modifiche RTO In Corso',
        'DettaglioImpegnoImpianto': 'Dettaglio Impegno Impianto',
        'StatisticheFinalizzazione': 'Statistiche Finalizzazione RTO',
        'StatisticheStudiFattibilita': 'Statistiche Studi Fattibilita',
        'StatisticheModificheRto': 'Statistiche Modifiche RTO',
        'StatisticheOperazioni': 'Statistiche Operazioni',
        'StatisticheCambiStato': 'Statistiche Cambi Stato',
        'Lotto': 'Gestione Lotto',
        'MaterialiSTI': 'MaterialiSTI'
    };

    const translateBreadcrumb = (breadcrumb) => {
        const currentPage = breadcrumbPageToName[breadcrumb.props.children];
        let intermediatePage = '';
        switch (currentPage) {
            case 'Gestione RTO':
            case 'Gestione Lotto':
            case 'Associazione Massiva PO':
            case 'Upload PO':
                intermediatePage = 'RTO';
                break;
            case 'Gestione Cliente':
            case 'Monitoraggio CdS IF Master':
            case 'Gestione Utenti':
            case 'Gestione Comunicazioni IF':
                intermediatePage = 'Clienti';
                break;
            case 'MaterialiSTI':
                intermediatePage = 'Gestione Anagrafiche';
                break;
            case 'Acquisizione Dossier PCS':
            case 'Pubblicazione PO PCS':
            case 'Analisi RTO Internazionali':
                intermediatePage = 'RTO Internazionali';
                break;
            case 'Report Generale':
            case 'Report di Dettaglio':
            case 'Report Stato Lavorazione RTO':
            case 'Statistiche Operazioni':
            case 'Statistiche Cambi Stato':
            case 'Statistiche Studi Fattibilita':
            case 'Statistiche Finalizzazione RTO':
            case 'Analisi Modifiche RTO In Corso':
            case 'Statistiche Modifiche RTO':
            case 'Dettaglio Impegno Impianto':
            case 'Download Report':
                intermediatePage = 'Analisi RTO';
                break;
            default:
                break;
        }
        return [intermediatePage, currentPage];
    };

    return (
        <nav>
            {breadcrumbs
                .filter(({ breadcrumb }) => breadcrumb.props.children !== 'Home')
                .map(({ breadcrumb, match }, index) => (
                    <div className="breadcrumb" key={match.url}>
                        {translateBreadcrumb(breadcrumb).map((item, index, array) => (
                            <React.Fragment key={index}>
                                {index > 0 && (index < array.length - 1 || item !== 'Home') && <span>></span>}
                                {item && (
                                    <span style={{ textDecoration: "none", color: "white", paddingLeft: "7px", paddingRight: "7px" }}>
                                        {item}
                                    </span>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                ))}
        </nav>
    );
}

export default Breadcrumb;
