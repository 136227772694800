import { useState, useEffect, React } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Panel, Titlebar, Grid, Button, SegmentedButton, Column, Toolbar, Combobox } from '@sencha/ext-react-modern';
import lottoModel from '../models/LottoModel.js';
import periodoFerroviarioModel from '../models/PeriodoFerroviarioModel.js';
import impresaFerroviariaModel from '../models/ImpresaFerroviariaModel.js';

import { useUser } from '../components/UserContext';
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';

const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.Exporter'
]);

const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    model: periodoFerroviarioModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeImprese = Ext.create('Ext.data.Store', {
    model: impresaFerroviariaModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiListImpresaFerroviaria',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeLotti = Ext.create('Ext.data.Store', {
    model: lottoModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Lotti/LeggiLotti',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

function Lotto() {

    let title = "Gestione Lotto";
    // let ui = 'action raised';
    let ui = 'action';
    const { user, switchUser } = useUser();
    const { ambiente, updateAmbiente } = useAmbiente();
    const { periodi } = usePeriodi();

    storePeriodiFerroviari.setData(periodi.periodiFerroviari);

    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });
    const [params, setParams] = useState({ ideUte: null, ideCli: null, ideAnnFro: null })

    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    let navigate = useNavigate();

    //const caricaPeriodiFerroviari = () => {
    //    storePeriodiFerroviari.load({
    //    })
    //}
    const caricaImpreseFerroviarie = () => {
        storeImprese.load({
            params: {
                IdeOpaRfi: user.idOperatoreRFI
            }
        })
    }
    const caricaLotti = () => {
        let pIdeCli = isIF === true ? user.ideCli : Ext.ComponentQuery.query('#comboImpreseFerroviarie')[0].getValue();

        if (pIdeCli >= 1) {
            let params = {
                ideUte: user.ideUte,
                ideCli: pIdeCli,
                ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue()
            };
            storeLotti.clearData();
            storeLotti.removeAll();
            storeLotti.load({
                params: params
            });
        }

    }
    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ambiente.ideAnnFro);            
            if (isIF) {
                // se è if non serve la selezione dell'impresa'
                caricaLotti();
            }
            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });
        }
    };
    const aggiornaStateAmbiente = () => {
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();        
        setStateAmbiente({ ...stateAmbiente });   

        caricaLotti();
    }

    useEffect(() => {
        //caricaPeriodiFerroviari();
        caricaImpreseFerroviarie();
        //storeLotti.removeAll();
    }, []);

    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);
 
    // --------------------------------------  

    // Azioni Toolbar Grid ---------------------
    function onNuovoClick() {
        debugger;
        var grid = Ext.ComponentQuery.query('#gridLotti')[0];
        var store = grid.getStore();
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing == false) {
            var record = Ext.create(lottoModel, {});
            record.data.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value;
            record.data.numRto = 0;
            store.insert(0, record);
            rowediting.startEdit(0, 0);
            rowediting.clearListeners();
        }
    }

    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        debugger;
        var grid = Ext.ComponentQuery.query('#gridLotti')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }

    //----------DELETE--------------
    function onDeleteClick(grid, info) {
        debugger;
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del lotto?", function (button) {

            if (button === 'yes') {
                Ext.Ajax.request({
                    url: 'Lotti/EliminaLotto',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideLtx: info.record.data.ideLtx
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            //modifica gestione errore
                            if (returnObj.resp == "KO") {
                                Ext.Msg.alert('Elimina lotto fallito', returnObj.msgErr)
                            }
                            else {                       
                                caricaLotti();
                                Ext.toast({ message: 'Lotto eliminato!', timeout: 4000 });
                            }
                        }
                        else {
                            Ext.Msg.alert('Elimina lotto fallito', 'Elimina lotto fallito');
                        }
                    }
                });

            }
        })

    }


    function onCopiaClick(grid, info) {
        var lottoObject = { desLtx: "Copia di " + info.record.data.desLtx, ideLtx: info.record.data.ideLtx, nomLtx: "Copia di " + info.record.data.nomLtx, ideAnnFro: info.record.data.ideAnnFro, desAnnFro: info.record.data.desAnnFro, numRto: info.record.data.numRto, nomCli: info.record.data.nomCli };
        var lottoJson = Ext.encode(lottoObject);
        Ext.Ajax.request({
            url: 'Lotti/CopiaLotto',
            method: 'POST',
            params: {
                ideUte: user.ideUte,
                ipAddress: "",
                lotto: lottoJson,
                ideAnnFro: info.record.data.ideAnnFro
            },
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);
                    //modifica gestione errore
                    if (returnObj.resp == "KO") {
                        Ext.Msg.alert('Copia lotto fallita', returnObj.msgErr)
                    }
                    else {         
                        caricaLotti();
                        Ext.toast({ message: 'Lotto copiato!', timeout: 4000 });
                    }
                }
                else {
                    Ext.Msg.alert('Copia lotto fallita', 'Copia lotto fallita');
                }
            }
        });
    }

    function onModificaClick(grid, info) {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 2);
        }
    }

    function navigateToDetails(lottop, anno, nomIF) {
        const params = {
            ideLotto: lottop,
            ideAnnoFer: anno,
            idIF: (isIF === true) ? user.ideCli : storeImprese?.findRecord("nomCli", nomIF, 0, false, false, true).data.ideCli,
            pagina: "linkFromLotto"
        };

        // Navigate to the target route with parameters
        navigate(`/MainRto`, { state: params });
    };

    const handleSelect = (e) => {       
        if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
            if (!statePF.firstOnSelectComboPF) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = stateAmbiente.ideTipPer;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente Lotto SPF ideAnnFro = ', e.newValue.data.ideAnnFro);
                        }
                    );
                }
            }
        }
        
    };
    const handleSelectImpresa = (e) => {
        caricaLotti();
    }
    function renderUnderline(value, record, field, meta) {
        if (value !== null) {
            meta.setCls("line");
            return value;
        }
    }

    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1}>
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-box" />
                    <Combobox
                        itemId="comboImpreseFerroviarie"
                        width={350}
                        docked="right"
                        align="right"
                        margin="0 5 0 5"
                        // label="Impresa"
                        hidden={isIF === true ? true : false}
                        store={storeImprese}
                        displayField="nomCli"
                        autoSelect="true"
                        queryMode="local"
                        valueField="ideCli"
                        onSelect={handleSelectImpresa}
                    />
                    <Combobox
                        itemId="comboPeriodoFerroviario"
                        width={170}
                        docked="right"
                        align="right"
                        // label="Periodo Ferroviario"
                        store={storePeriodiFerroviari}
                        displayField="desAnnFro"
                        margin='0 5 0 0'
                        queryMode="local"
                        valueField="ideAnnFro"
                        onSelect={handleSelect}
                    />
                </Titlebar>
                <Grid
                    flex={1}
                    scrollable
                    itemId="gridLotti"
                    selectable={{ checkbox: false, mode: 'single' }}
                    store={storeLotti}
                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                    cls="custom-grid"
                    listeners={{
                        //validateedit: function (editor, context, eOpts) {
                        //    var a = 0;
                        //},
                        childsingletap: function (editor, context, eOpts) {
                            let ideLotto = context.record.data.ideLtx;
                            let ideAnnFro = context.record.data.ideAnnFro;
                            let nomIF = context.record.data.nomCli; //poi setValueField
                            // if (context.column._text === "Dettaglio Merci" && impostazione.impostazione.rto.ideTipTra === 3) {
                            if (context.column._text === "RTO") {
                                navigateToDetails(ideLotto, ideAnnFro, nomIF);
                            }
                        },
                        beforeedit: function (editor, context, eOpts) {
                            if (!isIF)
                                return false;
                        },
                        edit: function (grid, location) {
                            if (location.record.data.nomLtx !== "" && location.record.data.desLtx !== "") {
                                if (location.record.phantom) {
                                    Ext.Ajax.request({
                                        url: 'Lotti/InserisciLotto',
                                        method: 'POST',
                                        params: {
                                            ideUte: user.ideUte,
                                            ideCli: user.ideCli,
                                            ipAddress: "",
                                            nomLotto: location.record.data.nomLtx,
                                            desLotto: location.record.data.desLtx,
                                            ideAnnFro: location.record.data.ideAnnFro
                                        },
                                        callback: function (records, success, operation) {
                                            if (success) {
                                                var returnObj = Ext.decode(operation.responseText);
                                                //modifica gestione errore
                                                if (returnObj.resp == "KO") {
                                                    Ext.Msg.alert('Inserimento lotto fallito', returnObj.msgErr)
                                                }
                                                else {                    
                                                    caricaLotti();
                                                    Ext.toast({ message: 'Lotto creato!', timeout: 4000 });
                                                }
                                            }
                                            else {
                                                Ext.Msg.alert('Inserimento lotto fallito', 'Inserimento lotto fallito');
                                            }
                                        }
                                    })
                                }
                                else {
                                    var lottoObject = { desLtx: location.record.data.desLtx, ideLtx: location.record.data.ideLtx, nomLtx: location.record.data.nomLtx, ideAnnFro: location.record.data.ideAnnFro, desAnnFro: location.record.data.desAnnFro, numRto: location.record.data.numRto, nomCli: location.record.data.nomCli };
                                    var lottoJson = Ext.encode(lottoObject);
                                    Ext.Ajax.request({
                                        url: 'Lotti/ModificaLotto',
                                        method: 'POST',
                                        params: {
                                            ideUte: user.ideUte,
                                            ideCli: user.ideCli,
                                            ipAddress: "",
                                            lotto: lottoJson,
                                            ideAnnFro: location.record.data.ideAnnFro,
                                            nomLtx: location.record.data.nomLtx,
                                            ideLtx: location.record.data.ideLtx
                                        },
                                        callback: function (records, success, operation) {
                                            if (success) {
                                                var returnObj = Ext.decode(operation.responseText);
                                                //modifica gestione errore
                                                if (returnObj.resp === "KO") {
                                                    Ext.Msg.alert('Modifica lotto fallita', returnObj.msgErr);
                                                    location.record.reject();
                                                }
                                                else {
                                                    caricaLotti();
                                                    Ext.toast({ message: 'Lotto modificato', timeout: 4000 });
                                                }
                                            }
                                            else {
                                                Ext.Msg.alert('Modifica lotto fallita', 'Modifica lotto fallita');
                                                location.record.reject();
                                            }

                                        }
                                    });
                                }
                            }
                            else {
                                Ext.toast({ message: 'Inserire i campi obbligatori!', timeout: 4000 });
                                return false;
                            }

                        },
                        canceledit: function (editor, context) {
                            if (context.record.phantom) {
                                context.editor.grid.store.remove(context.record);
                            }

                        }
                    }}

                    plugins={{ rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" }, gridfilters: {}, gridexporter: {} }}
                    itemConfig={{
                        viewModel: true
                    }}
                >

                    <Toolbar docked="top" margin="0 0 10 0" cls="lottoToolbar" layout={{ type: 'hbox', pack: 'end' }}>
                        <SegmentedButton>
                            <Button cls="marginButton appButton" text="Nuovo" ui={ui} hidden={isIF ? false : true} handler={onNuovoClick} iconCls="x-fa fa-plus" />
                            <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                        </SegmentedButton>
                        {/* {isIF && <div style={{ color: '#666', fontSize: '13px' }}>Doppio click su un lotto per modificarlo</div>} */}
                    </Toolbar>
                    <Column itemId='elimina' text="Azioni" hidden={!isIF ? true : false} autoSizeColumn="true"
                        cell={{

                            tools: {

                                eliminaLotto: {
                                    handler: onDeleteClick.bind(this),
                                    bind: {
                                        iconCls: "x-fa fa-trash buttonGridTool",
                                        hidden: '{!((record.numRto) == "0")}',
                                        tooltip: 'Elimina'
                                    }

                                },
                                copiaLotto: {
                                    handler: onCopiaClick.bind(this),
                                    bind: {
                                        iconCls: "x-fa fa-clone buttonGridTool",
                                        tooltip: 'Copia'
                                    }
                                },
                                modificaLotto: {
                                    handler: onModificaClick.bind(this),
                                    bind: {
                                        iconCls: "x-fa fa-edit buttonGridTool",
                                        tooltip: 'Modifica'
                                    }
                                }

                            }

                        }}

                    />
                    <Column height="40" text="Impresa Ferroviaria" dataIndex="nomCli" hidden={isIF} filter='string' flex={1} />
                    <Column text="Nome Lotto" editor={{ xtype: 'textfield', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true }} editable="true" dataIndex="nomLtx" filter='string' flex={1} />
                    <Column text="Descrizione" editor={{ xtype: 'textfield', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true }} editable="true" dataIndex="desLtx" filter='string' flex={1} />
                    <Column text="RTO" renderer={renderUnderline} dataIndex="numRto" autoSizeColumn="true" cell={{ tools: { Totale: { bind: { cls: 'lineText' } } } }} />


                </Grid>

            </Panel>
        </Container>
    );


}

export default Lotto;

