import React, { useState, useEffect } from "react";
import {
    Panel,
    Container,
    Button,    
    Label
} from "@sencha/ext-react-modern";
import PassaggiStatoCondizioni from "./PassaggiStatoCondizioni";

function PassaggiStato(props) {
    const [passaggi, setPassaggi] = useState(null);
    const [modificheControllo, setModificheControllo] = useState(-1);

    useEffect(() => {
        setPassaggi(props.passaggiStato);
        setModificheControllo(props.modificheControllo);
    }, [props.modificheControllo]);

    //console.log(passaggi);

    return (
        <Container cls="cdzPassaggiStato" layout="center" style={{ textAlign: "center" }}>
            {passaggi?.map((passaggio, index) => (
                <>
                    {passaggio.condizioni.some((item) => !item.check) && (
                        <>
                            <Label
                                style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    fontStyle: "italic",
                                }}
                                cls="cStatoTitle"
                                html={passaggio.titolo}
                            />
                            <PassaggiStatoCondizioni condizioni={passaggio.condizioni} modificheControllo={modificheControllo} key={index} />
                        </>
                    )}
                </>
            ))}
        </Container>

    );

}

export default PassaggiStato;