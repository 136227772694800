const Ext = window['Ext'];

export default Ext.define('AllegatoClienteModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideAlg', type: 'int' },
            { name: 'nomFie', type: 'string' },
            { name: 'datImo', type: 'date', format: 'd/m/Y' },
            { name: 'datCmc', type: 'date', format: 'd/m/Y' },
            { name: 'byt', type: 'int' },
            { name: 'rge', type: 'string' },
            { name: 'ideTipAlg', type: 'int' },
            { name: 'desTipAlg', type: 'string' },
            { name: 'ideCli', type: 'int' },
            { name: 'nomCli', type: 'string' },
            { name: 'ideCliMse', type: 'int' }
        ]
});
