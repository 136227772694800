import React, { useState, useContext, useEffect } from "react";
import {
  Panel,
  FormPanel,
  Container,
  Button,
  TextField,
  Label,
  Column,
  Grid,
  Checkbox,
  Loadmask,
} from "@sencha/ext-react-modern";
import { VersioneRtoContext } from "../components/VersioneRtoContext";
import { useUser } from "../components/UserContext";
import RtoImage from "../components/RTO/Rto";
import PassaggiStato from "../components/RTO/PassaggiStato";
//import { useUser } from '../components/UserContext';   // *** da inserire per richiamare l'utente loggato

//const LoggedInUser = () => {
//    const { user } = useUser();
//    return (
//        <Button align="right" iconCls="x-fa fa-user" text={user.name} />
//    );
//}

const toolHandler = (panel, tool) => {
  const Ext = window["Ext"];

  Ext.toast(`You clicked ${tool.config.tooltip || "a custom tool"}.`);
  // panel.setHeight(....)
  // esempio
  //if (tool.config.type == "maximize") {
  //    panel.setHeight(window.innerHeight).setWidth(window.innerWidth)
  //} else {
  //    panel.setHeight(panel.initialConfig.height).setWidth(panel.initialConfig.width)
  //}
};
const Ext = window["Ext"];
const stVersioniRTO = Ext.create("Ext.data.Store", {
  proxy: {
    method: "GET",
    type: "ajax",
    url: "Rto/LeggiVersioniRTO",
    reader: {
      type: "json",
    },
  },
  autoload: false,
});

function RtoControllo(props) {
  const [isVersioniVisible, setisVersioniVisible] = useState(false);
  const [storeVersioniRTO, setStoreVersioniRTO] = useState(stVersioniRTO);
  const [storePassaggiStato, setStorePassaggiStato] = useState([]);
  const [modificheControllo, setModificheControllo] = useState(-1);
  const [flexRTOImg, setFlexRTOImg] = useState(40);
  const [flexVersioni, setFlexVersioni] = useState(1);
  const [stateLoadMask, setStateLoadMask] = useState({
    hidden: true,
    message: "Attendere...",
  });
  const contextVersioneRto = useContext(VersioneRtoContext);
  const rto = contextVersioneRto.storeRtoSchede.data.items[0].data.rto;
  const { user } = useUser();
  const ideGrpRTO = rto?.ideGrpRTO;
  const ideRTO = rto?.ideRTO;
  const ideTipGpr = user?.idTipoGruppo;
  const ideUte = user?.ideUte;
  function aggiornaControllo(stato) {
    props.aggiornaStateControllo();
  }
  function onApriRTOClick(grid, info) {
    window.open(
      "/SchedaRtoLettura?ideRto=" +
        info.record.data.ideRTO +
        "&ideCli=" +
        user.ideCli,
      "_blank"
    );
  }
  useEffect(() => {
    async function fetchData() {
      setStateLoadMask({ hidden: false, message: "Attendere..." });
      await storeVersioniRTO.load({
        params: {
          ideGrpRTO: ideGrpRTO,
          ideTipGpr: ideTipGpr,
        },
      });
      await Ext.Ajax.request({
        url: "Rto/GetCondizioniCambioStato",
        method: "GET",
        params: { ideRTO: ideRTO, ideUte: ideUte },
        callback: function (records, success, operation) {
          // setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
          if (success) {
              setStorePassaggiStato(Ext.decode(operation.responseText));
              setModificheControllo(props.modificheControllo);
          } else {
          }
        },
      });
      setStateLoadMask({ hidden: true, message: "Attendere..." });
    }

    fetchData();
  }, [
    ideGrpRTO,
    ideTipGpr,
    ideRTO,
    ideUte,
    storeVersioniRTO,
    props.modificheControllo,
  ]);
  

  // caricaVersioniRTO(ideGrpRTO, ideTipGpr);
  //#region Dati di Test
  //DALLA PROCEDURA MI ARRIVA QUESTO

  //          |CHIAVE|              |VALORE|                      |CAMPO COMPONENTE|
  //          IMI_STO_RTO           2.svg                         Immagine dello stato
  //          SEM_PUB_TRC           gen-white.svg                 PO Pubblicato
  //          SEM_STO_IVO_PUB       gen-white.svg                 Stato II
  //          SEM_IPG_IMT_CNT       gen-white.svg                 II Completo
  //          SEM_TIP_INU           null (.svg)                   Congruenza RTO/PO
  //          DES_IVO_PUB           Non inviabile                 Stato di Invio II
  //          DAT_ATV_PVV           12/11/2022 00:00:00           Data Invio a RDI          ?
  //          DAT_SCD               12/11/2022 00:00:00           Data Risposta RDI         ?
  //          SEM_CIR               null (.svg)                   Circolabilità             ?
  //          SEM_CER               null (.svg)                   CdS                       ?

  //const stato = "0.svg"
  //const poPubblicato = "gen-green.svg"
  //const statoII = "gen-green.svg"
  //const iICompleto = "gen-white.svg"
  //const congruenzaRTOPO = null
  //const statoInvioII = "Non Inviabile"
  //const dataInvioRDI = "12/11/2022 00:00:00"
  //const dataRispostaRDI = "12/11/2022 00:00:00"
  //const circolabilità = "gen-red.svg"
  //const cdS = "gen-green.svg"
  function renderColorRow(value, record, field, metaData) {
    // Trova il valore numerico più alto nella colonna "Prg"
    const maxPrg = Math.max(
      ...storeVersioniRTO.data.items.map((item) => item.data.prgVesRTO)
    );
    let row = metaData.row;
    // Verifica se il valore attuale è il massimo
    if (value === maxPrg) {
        //row.setStyle("background:#f3e8c0;");
        row.setCls("beigeBackground");
      }
    else {
        row.setCls("whiteBackground");
    }
    return value;
  }
  //console.log(rto);

    function onDettaglioModifiche(grid, info) {
        let htmlTesto = "";

        Ext.Ajax.request({
            url: 'Rto/LeggiMessaggioPosta',
            method: 'POST',
            params: {
                ideUte: user.ideUte,
                ipAddress: "localhost",
                ideRTO: info.record.data.ideRTO
            },
            callback: function (records, success, operation) {

                if (success) {
                    let returnObj = Ext.decode(operation.responseText);

                    if (returnObj.resp == "OK") {
                        htmlTesto = formatTesto(returnObj.testo)

                        // -----------------------
                        var dialog = Ext.create({
                            itemId: 'dialogDettaglioModifiche',
                            xtype: 'dialog',
                            title: 'Dettaglio Modifiche',
                            draggable: "true",
                            minWidth: '40%',
                            minHeight: '35%',
                            maxWidth: '70%',
                            maxHeight: '60%',
                            centered: 'true',
                            scrollable: 'true',
                            layout: 'fit',
                            closable: "true",
                            items: [
                                {
                                    xtype: 'panel',
                                    layout: "vbox",
                                    margin: "5 5 5 5",
                                    scrollable: 'true',
                                    items: [
                                        {
                                            xtype: 'panel',
                                            layout: "vbox",
                                            scrollable: 'true',
                                            style: { whiteSpace: 'pre-wrap', padding: '10px', border: '1px solid #ccc' },
                                            html: htmlTesto
                                        }
                                    ]
                                }
                            ]
                        });

                        dialog.show();
                        // -----------------------
                    }
                    else {
                        if (returnObj.resp == "KO")
                            Ext.Msg.alert('Dettaglio modifiche RTO', returnObj.msgErr);
                    }
                }
                else {
                    Ext.Msg.alert('Dettaglio modifiche RTO', "Errore in lettura delle modifiche.");
                }
            }
        });
    }

    const formatTesto = (testo) => {
        let html = "<div class='dialogTxt'>";
        testo.split("\n\t").map((i, key) => {
            html = html + "<div key=" + key + ">" + i + "</div>";
        })
        html = html + "</div>";

        return html;
    }

  return (
    <Panel
      title="Controllo RTO"
      layout="vbox"
      shadow
      iconCls="x-fa fa-share-alt"
      collapsible={{
        collapsed: props.modificaServiziCommerciali,
        direction: "top",
      }}
      cls="PnlSectionL1 iconPanelsRTO"
      tools={[
        {
          iconCls: "x-fa fa-file-pdf",
          tooltip: "Esporta in Pdf",
          hidden: true,
          handler: toolHandler,
        },
        {
          iconCls: "x-fa fa-sign-out-alt",
          tooltip: "Esporta",
          hidden: true,
          handler: toolHandler,
        },
        /*{ iconCls: 'x-fa fa-edit', tooltip: 'Modifica RTO', handler: toolHandler },*/
        /* { type: 'menu', handler: toolHandler }*/
      ]}
    >
      <Container scrollable layout="hbox">
        <Container
          flex={flexRTOImg}
          cls="rtoImg"
          layout="vbox"
          style={{ textAlign: "center" }}
          hidden={isVersioniVisible}
        >
              <RtoImage
                stato={rto.imI_STO_RTO}
                poPubblicato={rto.seM_PUB_TRC}
                statoII={rto.seM_STO_IVO_PUB}
                iICompleto={rto.seM_IPG_IMT_CNT}
                congruenzaRTOPO={rto.seM_TIP_INU}
                statoInvioII={rto.deS_IVO_PUB}
                dataInvioRDI={rto.datIvoPub}
                dataRispostaRDI={rto.datRPaPub}
                circolabilità={rto.seM_CIR}
                cdS={rto.seM_CER}
                modificheControllo={modificheControllo}
               />

               <PassaggiStato passaggiStato={storePassaggiStato} modificheControllo={modificheControllo} />
          
        </Container>

        <Container cls="vesioniPanel" flex={flexVersioni}>
          <Panel
            title="Versioni"
            layout="fit"
            iconCls="x-far fa-clock"
            cls="iconPanelsRTO"
            margin="0 0 0 3"
            height="100%"
            collapsible={{ collapsed: true, direction: "right" }}
            onExpand={() => {
              setisVersioniVisible(true);
              setFlexRTOImg(0);
              setFlexVersioni(3);
            }}
            onCollapse={() => {
              setisVersioniVisible(false);
              setFlexRTOImg(40);
              setFlexVersioni(1);
            }}
          >
            <Container layout="vbox" align="stretch">
              <Grid
                itemId="gridVersioni"
                cls="column-in-panel"
                scrollable
                collapsible
                selectable={{ checkbox: false, mode: "single" }}
                store={storeVersioniRTO}
                minHeight="130"
                itemConfig={{
                  viewModel: true,
                }}
              >
                <Column
                  itemId="apriRtoVersioni"
                  flex={1}
                  align="center"
                  cell={{
                    tools: {
                      apriRTO: {
                        handler: onApriRTOClick.bind(this),
                        bind: {
                          iconCls: "x-fa fa-folder-open buttonGridTool",
                          tooltip: "Apri RTO",
                          hidden:
                            '{record.ideRTO === "" || record.ideRTO === null || record.ideRTO === 0}',
                        },
                      },
                    },
                  }}
                />
                <Column
                  text="Prg."
                  dataIndex="prgVesRTO"
                  flex={1}
                  renderer={renderColorRow}
                />
                <Column text="Data Versione" dataIndex="datVesRTO" flex={1} />
                <Column text="Origine" dataIndex="nomLocPrv" flex={1} />
                <Column text="Ora part." dataIndex="oraPar" flex={1} />
                <Column text="Destinazione" dataIndex="nomLocDsn" flex={1} />
                <Column text="Ora arrivo" dataIndex="oraArr" flex={1} />
                <Column text="Versione" dataIndex="deS_VES_RTO" flex={1} />
                <Column text="Autore" dataIndex="autoreVersione" flex={1} />
                <Column width={108} text="Modifiche" align="center" sortable="false"
                   cell={{
                      tools: {
                         dettaglioModifiche: {
                            handler: onDettaglioModifiche.bind(this),
                            bind: {

                                iconCls: "x-far fa-clock buttonGridTool",
                                tooltip: 'Dettaglio Modifiche',
                                hidden: "{(record.prgVesRTO==0)}"
                            },                            
                         }
                      }
                   }}
                />
                <Column
                  cls="blueIconGrid"
                  text='<span class="x-fa fa-list"/>'
                  dataIndex="flG_UPD_RTO"
                  width={60}
                  renderer={function (value, metaData, records, view) {
                    return "";
                  }}
                  cell={{
                    tools: {
                      prova: {
                        bind: {
                          iconCls: "x-fa fa-check",
                          hidden: "{(record.flG_UPD_RTO==0)}",
                        },
                      },
                    },
                  }}
                />
                <Column
                  cls="blueIconGrid"
                  text='<span class="x-fa fa-road"/>'
                  dataIndex="flG_UPD_PRC_RTO"
                  width={60}
                  renderer={function (value, metaData, records, view) {
                    return "";
                  }}
                  cell={{
                    tools: {
                      prova: {
                        bind: {
                          iconCls: "x-fa fa-check",
                          hidden: "{(record.flG_UPD_PRC_RTO==0)}",
                        },
                      },
                    },
                  }}
                />
                <Column
                  cls="blueIconGrid"
                  text='<span class="x-far fa-calendar-days"/>'
                  dataIndex="flG_UPD_PER_RTO"
                  width={60}
                  renderer={function (value, metaData, records, view) {
                    return "";
                  }}
                  cell={{
                    tools: {
                      prova: {
                        bind: {
                          iconCls: "x-fa fa-check",
                          hidden: "{(record.flG_UPD_PER_RTO==0)}",
                        },
                      },
                    },
                  }}
                />
                <Column
                  cls="blueIconGrid"
                  text='<span class="x-fa fa-gauge-simple"/>'
                  dataIndex="flG_UPD_IMP_RTO"
                  width={60}
                  renderer={function (value, metaData, records, view) {
                    return "";
                  }}
                  cell={{
                    tools: {
                      prova: {
                        bind: {
                          iconCls: "x-fa fa-check",
                          hidden: "{(record.flG_UPD_IMP_RTO==0)}",
                        },
                      },
                    },
                  }}
                />
                <Column
                  cls="blueIconGrid"
                  text='<span class="x-fa fa-charging-station"/>'
                  dataIndex="flG_UPD_IMT_SER"
                  width={60}
                  renderer={function (value, metaData, records, view) {
                    return "";
                  }}
                  cell={{
                    tools: {
                      prova: {
                        bind: {
                          iconCls: "x-fa fa-check",
                          hidden: "{(record.flG_UPD_IMT_SER==0)}",
                        },
                      },
                    },
                  }}
                />
                <Column
                  cls="blueIconGrid"
                  text='<span class="x-fa fa-link"/>'
                  dataIndex="flG_UPD_IMT_LEG"
                  width={60}
                  renderer={function (value, metaData, records, view) {
                    return "";
                  }}
                  cell={{
                    tools: {
                      prova: {
                        bind: {
                          iconCls: "x-fa fa-check",
                          hidden: "{(record.flG_UPD_IMT_LEG==0)}",
                        },
                      },
                    },
                  }}
                />
                <Column
                  cls="blueIconGrid"
                  text='<span class="x-fa fa-shuffle"/>'
                  dataIndex="flG_UPD_IMT_MVN"
                  width={60}
                  renderer={function (value, metaData, records, view) {
                    return "";
                  }}
                  cell={{
                    tools: {
                      prova: {
                        bind: {
                          iconCls: "x-fa fa-check",
                          hidden: "{(record.flG_UPD_IMT_MVN==0)}",
                        },
                      },
                    },
                  }}
                />
                <Column
                  cls="blueIconGrid"
                  text='<span class="x-fa fa-cart-flatbed-suitcase"/>'
                  dataIndex="flG_UPD_SER_CMM_RTO"
                  width={60}
                  renderer={function (value, metaData, records, view) {
                    return "";
                  }}
                  cell={{
                    tools: {
                      prova: {
                        bind: {
                          iconCls: "x-fa fa-check",
                          hidden: "{(record.flG_UPD_SER_CMM_RTO==0)}",
                        },
                      },
                    },
                  }}
                />
                <Column
                  cls="redIconGrid"
                  text='<span class="x-fa fa-list"/>'
                  dataIndex="flG_UPD_TRC"
                  width={60}
                  renderer={function (value, metaData, records, view) {
                    return "";
                  }}
                  cell={{
                    tools: {
                      prova: {
                        bind: {
                          iconCls: "x-fa fa-check",
                          hidden: "{(record.flG_UPD_TRC==0)}",
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <table class="legendTable">
                <thead>
                  <tr>
                    <th class="blueIconGrid">
                      <span class="x-fa fa-list" />
                    </th>
                    <th class="blueIconGrid">
                      <span class="x-fa fa-road" />
                    </th>
                    <th class="blueIconGrid">
                      <span class="x-far fa-calendar-days" />
                    </th>
                    <th class="blueIconGrid">
                      <span class="x-fa fa-gauge-simple" />
                    </th>
                    <th class="blueIconGrid">
                      <span class="x-fa fa-charging-station" />
                    </th>
                    <th class="blueIconGrid">
                      <span class="x-fa fa-link" />
                    </th>
                    <th class="blueIconGrid">
                      <span class="x-fa fa-shuffle" />
                    </th>
                    <th class="blueIconGrid">
                      <span class="x-fa fa-cart-flatbed-suitcase" />
                    </th>
                    <th class="redIconGrid">
                      <span class="x-fa fa-list" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="legendText">Riepilogo</td>
                    <td class="legendText">Percorso</td>
                    <td class="legendText">Periodicità</td>
                    <td class="legendText">Impostazione</td>
                    <td class="legendText">Servizi</td>
                    <td class="legendText">Legami</td>
                    <td class="legendText">Manovre</td>
                    <td class="legendText">Servizi Commerciali</td>
                    <td class="legendText">PO Modificato</td>
                  </tr>
                </tbody>
              </table>
            </Container>
          </Panel>
        </Container>
      </Container>

      <Container
        cls="container-spinner"
        height="100%"
        width="100%"
        layout="fit"
      >
        <Loadmask
          cls="custom-grid"
          itemId="loadMaskRtoDet"
          hidden={stateLoadMask.hidden}
          indicator={true}
          message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
          modal={true}
        />
      </Container>
    </Panel>
  );
}

export default RtoControllo;
