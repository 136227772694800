import React from 'react';
import { Panel, Container, Loadmask, Label,TextAreaField, FormPanel } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import newsIFModel from '../models/ComunicazioniIFModel.js';
import { useUser } from '../components/UserContext';
import ComunicazioniIFModel from '../models/ComunicazioniIFModel.js';

const Ext = window['Ext'];

let comunicazioniString = "";

function HomeComunicazioniIF(props) {
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: false, message: "Attendere caricamento comunicazioni in corso...", ricarica: true });
    const { user, switchUser } = useUser();

    const caricaComunicazioniIF = () => {
        Ext.Ajax.request({
            url: 'Clienti/LeggiComunicazioniIFHome',
            method: 'GET',
            callback: function (records, success, operation) {
                comunicazioniString = "";
                var returnObj = Ext.decode(operation.responseText);
                comunicazioniString = returnObj;
                if (comunicazioniString === "")   
                    props.aggiornaComunicazioni(false);
                else {
                    props.aggiornaComunicazioni(true);
                }
                setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
            }
        });
    }

    if (stateLoadMask.ricarica === true)
        caricaComunicazioniIF();

    return (

        <Container cls="container-spinner" height="100%" width="100%" layout="fit" padding="10" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskComunicazioni"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />

            {comunicazioniString !== "" &&
                <Label
                        cls='altezzaComIF'
                            flex={1}
                            disabled={true}
                            clearable={false}
                html={comunicazioniString}
                        />
            }
            </Container>

    );
}

export default HomeComunicazioniIF;