import React, { useEffect, useState } from "react";
import { Container, Panel, Titlebar, Grid, Column, Button, Dialog, SegmentedButton } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";
import { useNavigate } from "react-router-dom";
import SuccessIcon from './../images/abiCli_Verde.svg';
import WarnIcon from './../images/abiCli_Giallo.svg';
import DangerIcon from './../images/abiCli_Rosso.svg';
import NuovoClienteForm from "./NuovoClienteForm";


const Ext = window['Ext'];


const storeClienti = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Clienti/LeggiClienti',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeModificaAbilitazioni = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'ajax',
        url: 'Clienti/ModificaAbilitazioniImpresa',
        actionMethods: {
            create: 'POST', read: 'POST', update: 'POST', destroy: 'POST'
        },
        reader: {
            type: 'json'
        },
        writer: {
            type: 'json'
        }
    },
    autoload: false
});



function MainClienti() {

    const navigate = useNavigate();
    const { user } = useUser();
    const [dialogVisible, setDialogVisible] = useState(false);

    let title = "Gestione cliente";
    let ui = 'action';
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;

    var isRFICOMP = user.ruolo === 'RFI-PO-COMP';
    var isRFICC = user.ruolo === 'RFI-PO-RFICC';

    const caricaClienti = () => {
        storeClienti.load({
            params: {
                ideUte: user.ideUte
            },
            callback: (records, operation, success) => {
                if (success) {
                    //console.log(records);
                } else {
                    console.error("Errore durante il caricamento dei dati");
                }
            }
        })
    }



    const onApri = (grid, info, action) => {
        const ideCli = info.record.data.ideCli;
        navigate("/Cliente", { state: { ideCli, action } });
    };

    const handleStatoIFClick = (ideCli, value) => {
        let stato;
        if (user.ruolo === 'RFI') {
            if (value === 'Abilitata') {
                stato = 2;
            } else if (value === 'Disabilitata') {
                stato = 1;
            } else {
                return;
            }
            storeModificaAbilitazioni.load({
                params: {
                    IdeCli: ideCli,
                    IdeAbiCli: stato
                },
                callback: (records, operation, success) => {
                    if (success) {
                        console.log('Modifica effettuata con successo');
                        caricaClienti();
                    } else {
                        console.error("Errore durante la modifica delle abilitazioni");
                    }
                }
            });

        }
    };

    const onNewClick = () => {
        setDialogVisible(true);
    };

    const onCloseDialog = () => {
        setDialogVisible(false);
    };


    useEffect(() => {
        caricaClienti();
    }, []);


    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridGestioneCli')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }

    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">

                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1}>
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-user" />
                </Titlebar>

                <Container layout="vbox" flex={1} padding="10">

                    <Container docked="top" margin="10 0 0 0">
                        <SegmentedButton docked="right">
                            <Button cls="marginButton exportButton" docked="right" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                            <Button text="Nuovo Cliente" handler={onNewClick} ui="action" cls="marginButton appButton" iconCls="x-fa fa-plus" hidden={user.ruolo != 'RFI'} />
                        </SegmentedButton>
                    </Container>

                    {!isIF && (
                        <Grid
                            flex={1}
                            cls="custom-grid"
                            store={storeClienti}
                            itemId="gridGestioneCli"
                            loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                            plugins={['gridfilters', 'gridexporter']}
                            itemConfig={{ viewModel: true }}
                        >
                            <Column height="40" width={70} text="Azioni" filter='string' cell={{
                                tools: {
                                    apri: {
                                        handler: (grid, info) => onApri(grid, info, 'view'), // Usa una funzione freccia per passare i parametri
                                        iconCls: "x-fa fa-folder-open buttonGridTool",
                                        tooltip: 'Apri scheda Cliente'
                                    }
                                }
                            }} />
                            <Column text="Stato IF" dataIndex="des_Abi_Cli" flex={1}
                                cell={{
                                    xtype: 'reactcell',
                                    renderer: (value, record) => {
                                        let icon;
                                        let tooltipText;
                                        switch (value) {
                                            case 'Disabilitata':
                                                icon = WarnIcon;
                                                tooltipText = 'Disabilitata. Clicca per abilitare';
                                                break;
                                            case 'Abilitata':
                                                icon = SuccessIcon;
                                                tooltipText = isRFICOMP || isRFICC ? 'Abilitata' : 'Abilitata. Clicca per disabilitare';
                                                break;
                                            default:
                                                icon = DangerIcon;
                                                tooltipText = 'Stato non riconosciuto';
                                                break;
                                        }
                                        return (
                                            <span
                                                onClick={() => handleStatoIFClick(record.data.ideCli, value)}
                                                className="mc-icons-wrapper"
                                            >
                                                <img className="mc-icons" src={icon} alt={value} data-qtip={tooltipText} />
                                                {value}
                                            </span>

                                        );
                                    }
                                }}
                            />
                            <Column text="Sigla" dataIndex="sig_Cli" width={150} filter='string' />
                            <Column text="Nome Cliente" dataIndex="nomCli" flex={1} hidden={true} />
                            <Column text="Tipo Contratto" dataIndex="des_Tip_Ipr" width={120} filter='string' />
                            <Column text="Tipo Traffico" dataIndex="sig_Tip_Tra_Cli" width={110} filter='string' />
                            <Column text="Licenza" dataIndex="num_Lic" flex={1} filter='string' />
                            <Column text="Titolo autorizzatorio" dataIndex="num_Tlo_Atn" flex={1} filter='string' />
                            <Column text="CdS" dataIndex="num_Cef" flex={1} filter='string' />
                            <Column text="Diritti d'Accesso (eccetto RFICO)" dataIndex="eln_Opa_Rfi" flex={1} filter='string' />
                            <Column text="ID Cliente" dataIndex="ideCli" flex={1} hidden={true} />

                        </Grid>
                    )}
                    <Dialog
                        displayed={dialogVisible}
                        closable
                        title="Nuovo Cliente"
                        modal
                        closeAction="hide"
                        maskTapHandler={onCloseDialog}
                        onHide={onCloseDialog}
                        width="400"
                    >
                        <NuovoClienteForm onClose={onCloseDialog} />

                    </Dialog>
                </Container>
            </Panel>
        </Container>

    );
}

export default MainClienti;