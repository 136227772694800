import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Container } from '@sencha/ext-react-modern';

Chart.register(ChartDataLabels);

function PieChart({ chartData, aspectRatio, indexAxis, height = 420 }) {
    const chartRef = useRef(null);


    useEffect(() => {

        const ctx = chartRef.current.getContext('2d');
        const chart = new Chart(ctx, {
            type: 'pie', // Cambiato da 'doughnut' a 'pie'
            data: chartData,
            options: {
                responsive: true,
                aspectRatio: aspectRatio,
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        formatter: (value, context) => {
                            return value;
                        },
                        color: 'rgba(0, 55, 128, 1)',
                        labels: {
                            title: {
                                font: {
                                    weight: 'bold'
                                }
                            }
                        },
                        anchor: 'center',
                        align: 'center',
                        offset: 0,
                    },
                    legend: {
                        position: 'bottom',
                    }
                }
            }
        });

        return () => {
            chart.destroy();
        };
    }, [chartData]);

    return (
        <Container layout="fit" height={height}>

            <canvas style={{ height: '100%', width: '100%', margin: '0' }} ref={chartRef} />

        </Container>
    );
}

export default PieChart;
