const Ext = window['Ext'];

export default Ext.define('ClientiCdSFileModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'idPdfCert', type: 'auto' },
            { name: 'nomFileCert', type: 'auto' },
            { name: 'dataUpload', type: 'date', format: 'd/m/Y' },
            { name: 'codTipPdf', type: 'auto' }
        ]
});