const Ext = window['Ext'];

export default Ext.define('ManovreLocalitaModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'iDE_MAN_IPG_IMP', type: 'auto' },
            { name: 'aUT_PRD', type: 'auto' },
            { name: 'iDE_PRC_RTO', type: 'auto' },
            { name: 'iDE_LOC', type: 'auto' },
            { name: 'nOM_LOC', type: 'auto' },
            { name: 'iDE_AREA_ORI', type: 'auto' },
            { name: 'nOM_ARE_ORI', type: 'auto' },
            { name: 'iDE_AREA_DES', type: 'auto' },
            { name: 'nOM_AREA_DES', type: 'auto' },
            { name: 'oRA_INI', type: 'auto' },
            { name: 'oRA_FIN', type: 'auto' },
            { name: 'pERIODICITA', type: 'auto' },
            { name: 'iMPOSTAZIONE', type: 'auto' },
            { name: 'oPERAZIONI', type: 'auto' },
            { name: 'oFF_SET_INI', type: 'auto' },
            { name: 'oFF_SET_FIN', type: 'auto' },
            { name: 'iDE_FASCIO_ORI', type: 'auto' },
            { name: 'iDE_FASCIO_DES', type: 'auto' },
            { name: 'nOM_FASCIO_ORI', type: 'auto' },
            { name: 'nOM_FASCIO_DES', type: 'auto' },
            { name: 'iDE_BINARIO_ORI', type: 'auto' },
            { name: 'nOM_BINARIO_ORI', type: 'auto' },
            { name: 'iDE_BINARIO_DES', type: 'auto' },
            { name: 'nOM_BINARIO_DES', type: 'auto' },
            { name: 'iDE_GESTORE', type: 'auto' },
            { name: 'dSC_GESTORE', type: 'auto' },
            { name: 'mAD', type: 'auto' },
            { name: 'cRM', type: 'auto' },
            { name: 'tOP', type: 'auto' },
            { name: 'tMP', type: 'auto' },
            { name: 'aSL', type: 'auto' },
            { name: 'aRF', type: 'auto' },
            { name: 'mAD_ORA', type: 'auto' },
            { name: 'cRM_ORA', type: 'auto' },
            { name: 'tOP_ORA', type: 'auto' },
            { name: 'tMP_ORA', type: 'auto' },
            { name: 'aSL_ORA', type: 'auto' },
            { name: 'aRF_ORA', type: 'auto' },
            { name: 'mAD_OFF_SET', type: 'auto' },
            { name: 'cRM_OFF_SET', type: 'auto' },
            { name: 'tOP_OFF_SET', type: 'auto' },
            { name: 'tMP_OFF_SET', type: 'auto' },
            { name: 'aSL_OFF_SET', type: 'auto' },
            { name: 'aRF_OFF_SET', type: 'auto' },
            { name: 'nOTE', type: 'auto' },
            { name: 'mASSA', type: 'auto' },
            { name: 'lUNGHEZZA', type: 'auto' },
            { name: 'mp', type: 'auto' },
            { name: 'te', type: 'auto' },
            { name: 'tES', type: 'auto' },
            { name: 'tEP', type: 'auto' },
            { name: 'tc', type: 'auto' },
            { name: 'mP_NOTE', type: 'auto' },
            { name: 'tE_NOTE', type: 'auto' },
            { name: 'tC_NOTE', type: 'auto' }, 
            { name: 'iDE_OGT_MVN', type: 'auto' },
            { name: 'dES_OGT_MVN', type: 'auto' },
            { name: 'dETTAGLIO_PERIODICITA', type: 'auto' },
            { name: 'operazioniManovraImpianto', type: 'auto' },
            { name: 'labelOperazioniManovraImpianto', type: 'auto' }
        ]
});
      