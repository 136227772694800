import React, { useEffect, useState, useRef } from "react";
import { Container, FormPanel, Panel, Titlebar, Grid, Column, Button, ExtButton, Dialog, SegmentedButton, DatePickerField, ComboBoxField, CheckBoxField } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";
import { useNavigate } from "react-router-dom";
import Detail from './MaterialiSTIDetail'; 


const Ext = window['Ext'];
let selezioneMultipla = false;


const storeMaterialiConformiSTI = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'STI/LeggiAnagraficaMaterialiConformiSTI',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeModificaAbilitazioni = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'ajax',
        url: 'Clienti/ModificaAbilitazioniImpresa',
        actionMethods: {
            create: 'POST', read: 'POST', update: 'POST', destroy: 'POST'
        },
        reader: {
            type: 'json'
        },
        writer: {
            type: 'json'
        }
    },
    autoload: false
});


const TIPI_MATERIALE = [
    { text: 'Ordinario', value: 'Ordinario' },
    { text: 'Leggero', value: 'Leggero' }
];


function MaterialiSTI() {

    const navigate = useNavigate();
    const { user } = useUser();
    const formPanelRef = useRef(null);  
    const [dialogVisible, setDialogVisible] = useState(false);
    const [paramsDetail, setParamsDetail] = useState({ tipoAzione: "", data: null })

    let numRecordSelText = "Righe selezionate : 0";
    const [numRecord, setNumRecord] = useState(0);


    let title = "Caratteristica STI del materiale";
    let ui = 'action';
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;

    var isRFICO = user.ruolo === 'RFI';
    
    const caricaMaterialiSTI = () => {
        storeMaterialiConformiSTI.load({
            params: {
                ideUte: user.ideUte,
                isIF : isIF
            },
            callback: (records, operation, success) => {
                if (success) {
                    //console.log(records);                    
                } else {
                    console.error("Errore durante il caricamento dei dati");
                }
            }
        })
    }

    const setBtnSelRecord = (numRecord) => {
        Ext.ComponentQuery.query('#btnSelRecordSTI')[0].setText("Righe selezionate: " + numRecord);
    }

    const onApri = (grid, info) => {             
        paramsDetail.tipoAzione = "M";
        paramsDetail.data = info.record.data;        
        setParamsDetail({ ...paramsDetail });

        setDialogVisible(true);       
    };

    const onElimina = (el, info) => {
        // parte da utilizzare per le azioni massive
        //const grid = Ext.ComponentQuery.query('#gridMaterialiSTI')[0];
        //const selectedRecords = grid.getSelections();

        //if (selectedRecords.length === 0) {
        //    Ext.Msg.alert('Nessuna selezione', 'Selezionare almeno un elemento dalla lista');
        //    return;
        //}
        if (info.record.data.pubblicato != "Si") {
            Ext.Ajax.request({
                url: 'STI/EliminaMaterialeConformeSTI',
                method: 'POST',
                params: {
                    ideUte: user.ideUte,
                    ipAddress: null,
                    tipoMateriale: info.record.data.tipoMateriale,
                    ideSTI: info.record.data.ideSTI
                },
                success: (response) => {
                    const result = Ext.decode(response.responseText);
                    if (result.resp == "OK") {
                        Ext.toast({ message: 'Eliminazione avvenuta con successo', timeout: 4000 });
                        caricaMaterialiSTI();
                    } else {
                        Ext.Msg.alert('Errore durante l\'eliminazione', result.msgErr);
                    }
                },
                failure: () => {
                    Ext.Msg.alert('Errore', 'Errore durante l\'eliminazione');
                }
            });
        }
        else {
            Ext.Msg.alert('Operazione non consentita', 'Conformità materiale STI pubblicata');
            return;
        }
    };

    const handleSelectionChange = (grid) => {
        const selectedRecords = grid.getSelections();
        setNumRecord(selectedRecords.length);
        setBtnSelRecord(selectedRecords.length);
    };

    const onNewClick = () => {
        //setTipoMateriale(TIPI_MATERIALE[0].value); // Imposta "Ordinario"

        paramsDetail.tipoAzione = "N";
        paramsDetail.data = null;
        setParamsDetail({ ...paramsDetail });

        setDialogVisible(true);       
    };

    const onCopia = (grid, info) => {
        paramsDetail.tipoAzione = "C";
        paramsDetail.data = info.record.data;
        setParamsDetail({ ...paramsDetail });

        setDialogVisible(true);
    };

    const onPubblica = (grid, info) => {
        let rec = info.record.data;
        if (rec.pubblicato != "Si") {
            Ext.Ajax.request({
                url: 'STI/PubblicaMaterialeConformeSTI',
                method: 'POST',
                params: {
                    ideUte: user.ideUte,
                    ipAddress: null,
                    tipoMateriale: rec.tipoMateriale,
                    ideSTI: rec.ideSTI
                },
                success: (response) => {
                    const result = Ext.decode(response.responseText);
                    if (result.resp == "OK") {
                        Ext.toast({ message: 'Pubblicazione avvenuta con successo', timeout: 4000 });
                        caricaMaterialiSTI();
                    } else {
                        Ext.Msg.alert('Errore durante la pubblicazione', result.msgErr);
                    }
                },
                failure: () => {
                    Ext.Msg.alert('Errore', 'Errore durante la pubblicazione');
                }
            });
        }
        else {
            Ext.Msg.alert('Operazione non consentita', 'Conformità materiale STI già pubblicata');
            return;
        }
    }

    const onPubblicaClick = () => {
        let grid = Ext.ComponentQuery.query('#gridMaterialiSTI')[0];        
        var gridSelection = grid.getSelections();

        if (gridSelection.length === 0) {
            Ext.Msg.alert('Nessuna selezione', 'Selezionare almeno un elemento dalla lista');
            return;
        }
        else {
            var elenco = [];
            for (let i = 0; i < gridSelection.length; i++) {
                let rowData = gridSelection[i].data;
                if (rowData.pubblicato != "Si") {
                    elenco.push(rowData);
                }
            }
            Ext.Ajax.request({
                url: 'STI/PubblicaMaterialeConformeSTIList',
                method: 'POST',
                params: {
                    ideUte: user.ideUte,
                    ipAddress: null,
                    listSti: Ext.encode(elenco)
                },
                success: (response) => {
                    const result = Ext.decode(response.responseText);
                    if (result.resp == "OK") {
                        Ext.toast({ message: 'Pubblicazione avvenuta con successo', timeout: 4000 });
                        caricaMaterialiSTI();
                    } else {
                        Ext.Msg.alert('Errore durante la pubblicazione', result.msgErr);
                    }
                },
                failure: () => {
                    Ext.Msg.alert('Errore', 'Errore durante la pubblicazione');
                }
            });

        }
    }
     
    const onCloseDialog = () => {
        setDialogVisible(false);
    };

    useEffect(() => {
        caricaMaterialiSTI();
    }, []);


    function renderImpostazione(value, record, field, metaData) {
        let retVal = value >=0? value: null; 
        return retVal;
    }


    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridMaterialiSTI')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }

    
    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">

                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1}>
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-train" />
                </Titlebar>

                <Container layout="vbox" flex={1} padding="10">

                    <Container docked="top" margin="10 0 0 0">
                        <SegmentedButton docked="right">
                            <Button text="Nuovo" handler={onNewClick} hidden={!isRFICO} ui="action" cls="marginButton appButton" iconCls="x-fa fa-plus" />                            
                            <Button text="Pubblica" handler={onPubblicaClick} hidden={!isRFICO} ui="action" cls="marginButton appButton" iconCls="x-fa fa-cloud-upload-alt" />
                            <Button cls="marginButton exportButton" docked="right" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                            {/*<ExtButton cls="ricercaAvanzataButton" itemId="btnSelRecordSTI" align="left" margin="2 2 2 2" text={numRecordSelText}></ExtButton>*/}
                        </SegmentedButton>
                    </Container>


                    <Grid
                        flex={1}
                        scrollable
                        variableHeights={true}
                        itemId="gridMaterialiSTI"
                        store={storeMaterialiConformiSTI}
                        selectable={{ checkbox: true, mode: 'multi' }}
                        plugins={{ gridfilters: {}, gridexporter: {} }}
                        // onSelect= {onItemclick}
                        itemConfig={{
                            viewModel: true
                        }}
                        listeners={{
                            select: (grid, record, index, eOpts) => handleSelectionChange(grid),
                            deselect: (grid, record, index, eOpts) => handleSelectionChange(grid),
                        }}
                        cls="custom-grid"
                        loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                    >
                        <Column height="40" width={130} text="Azioni" filter='string' hidden={!isRFICO} ignoreExport= "true" cell={{
                            tools: {
                                apri: {
                                    handler: onApri.bind(this),
                                    iconCls: "x-fa x-fa fa-edit buttonGridTool",
                                    tooltip: 'Modifica scheda Materiale'
                                },
                                copia: {
                                    handler: onCopia.bind(this),                                  
                                    iconCls: "x-fa fa-clone buttonGridTool",
                                    tooltip: 'Copia scheda Materiale'                 
                                },
                                elimina: {
                                    handler: (grid, info) => onElimina(grid, info),
                                    iconCls: "x-fa fa-trash buttonGridTool",
                                    tooltip: 'Elimina scheda Materiale',
                                    bind: {
                                        hidden: '{(record.pubblicato == "Si")}'
                                    }
                                },
                                pubblica: {
                                    handler: (grid, info) => onPubblica(grid, info),
                                    iconCls: "x-fa fa-cloud-upload-alt buttonGridTool",
                                    tooltip: 'Pubblica scheda Materiale',
                                    bind: {                                        
                                        hidden: '{(record.pubblicato == "Si")}'
                                    }
                                }
                            }
                        }} />
                        <Column text="ideCli" dataIndex="ideCli" flex={1} filter='string' hidden/>
                        <Column text="ideSTI" dataIndex="ideSTI" flex={1} filter='string' hidden/>
                        <Column text="Impresa" dataIndex="desCli" flex={1} filter='string' />
                        <Column text="Tipo Materiale" dataIndex="tipoMateriale" width={90} filter='string' />
                        <Column text="Locomotore" dataIndex="desLocomotore" flex={1} filter='string' />
                        <Column text="Codice Impostazione" dataIndex="codImpostazione" width={150} filter='string' renderer={renderImpostazione} />
                        <Column text="Inizio Validità" dataIndex="datIniVal" width={90} filter='date' formatter= 'date("d/m/Y")' />
                        <Column text="Fine Validità" dataIndex="datFinVal" width={90} filter='date' formatter='date("d/m/Y")' />
                        <Column text="Pubblicato" dataIndex="pubblicato" width={85} align="center" filter='string' />
                        <Column text="Aggiornamento" dataIndex="dataAggiornamento" width={120} filter='date' formatter='date("d/m/Y")' align="center" />
                       
                    </Grid>

                    {dialogVisible &&
                        <Dialog
                            displayed={true}
                            draggable={true}
                            closable
                            title="Materiale Conforme STI"
                            modal
                            closeAction="hide"
                            maskTapHandler={onCloseDialog}
                            onHide={onCloseDialog}
                            width={700}
                        >

                            <Detail data={paramsDetail.data} azione={paramsDetail.tipoAzione} chiudi={onCloseDialog} caricaMaterialiSTI={caricaMaterialiSTI} />

                        </Dialog>
                    }


                </Container>
            </Panel >
        </Container >

    );
}

export default MaterialiSTI;