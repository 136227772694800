import { useState, React, useEffect } from 'react';
import { Container, Panel, Titlebar, Grid, Column, Button, SegmentedButton, Toolbar } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
]);
const storeMonitoraggio = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Clienti/LeggiMonitoraggioCdSIFMaster',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

function MonitoraggioCds() {

    let title = "Monitoraggio CdS IF Master";
    let ui = 'action';
    const { user } = useUser();
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    const [state, setState] = useState({ MonitoraggioMaster: storeMonitoraggio })



    const aggiornaState = () => {
        state.MonitoraggioMaster = storeMonitoraggio;
        setState({ ...state });
    }

    const loadStoreMonitoraggio = () => {

        storeMonitoraggio.load({
            params: {
                ideUte: user.ideUte,
                ideGrp: user.idGruppo,
                ipAddress: "",

            },
            callback: function () {
                aggiornaState();
            }
        });
    };

    //aggiornamento intervalli------------------
    useEffect(() => {
        loadStoreMonitoraggio();
        const interval = setInterval(() => {
            loadStoreMonitoraggio(); //aggiorna ogni 90 sec
        }, 90000);

        return () => clearInterval(interval);
    }, []);



    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridMonitoraggio')[0];
        grid.saveDocumentAs({
            type: 'xlsx',

            matrix: true,
            title: title,
            fileName: title + '.xlsx'
        });
    }

    //--------------------COLORE RIGHE SCADENZA CDS-----------------------------
    const renderSign = (format, value) => (
        <span style={{ color: value >= 0 ? 'red' : value <= -1 && value >= -60 ? 'blue' : 'black' }}>
            {Ext.util.Format.number(value, format)}
        </span>
    )

    //value >= 0 ? '#ff0000' : value <= -1 && value >= -60 ? '0000ff' : '#000000'

    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1}>
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-chart-line" />
                </Titlebar>

                <Grid flex={1} scrollable itemId="gridMonitoraggio" selectable={{ checkbox: false, mode: 'single' }} variableHeights={true}
                    store={state.MonitoraggioMaster}
                    plugins={['gridfilters', 'gridexporter']}
                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                    cls="custom-grid"
                >

                    <Toolbar docked="top" margin="10 0 10 10">
                        <SegmentedButton docked="right">
                            <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                        </SegmentedButton>
                    </Toolbar>

                    <Column height="40" width={200} text="Cliente Master" dataIndex="cliMast" filter='string' flex={1} exportStyle={{ width: '320' }} />
                    <Column height="40" width={340} text="Cliente Contratto" dataIndex="cliContr" filter='string' flex={1} exportStyle={{ width: '410' }} renderer={(value, metaData, record) => (
                        <div dangerouslySetInnerHTML={{ __html: value }} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} />
                    )} />
                    <Column height="40" width={95} text="Tipo Traffico" dataIndex="tipTraff" filter='string' exportStyle={{ width: '130' }} renderer={(value, metaData, record) => (
                        <div dangerouslySetInnerHTML={{ __html: value }} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} />
                    )} />
                    <Column height="40" width={70} text="N.Lic." dataIndex="numLic" filter='string' exportStyle={{ width: '100' }} />
                    <Column height="40" width={105} text="Stato Licenza" dataIndex="statoLic" filter='string' value='Attiva' exportStyle={{ width: '135' }} />
                    <Column height="40" width={85} text="Data Lic." dataIndex="datLic" filter='string' exportStyle={{ width: '110' }} />
                    <Column height="40" width={140} text="Scadenza Cds (GG)" dataIndex="scadCdS" filter='string' renderer={renderSign.bind(this, '0')} exportStyle={{ width: '200' }}/>
                    <Column height="40" width={100} text="Num. Cds" dataIndex="numCdS" filter='string' exportStyle={{ width: '230' }} />
                    <Column height="40" width={140} text="Des. Cds" dataIndex="desCdS" filter='string' exportStyle={{ width: '220' }} />
                    <Column height="40" width={110} text="Tipo Cds" dataIndex="tipCdS" filter='string' exportStyle={{ width: '150' }} />
                    <Column height="40" width={110} text="Cds Valido Dal" dataIndex='datIniValCdS' filter='string' exportStyle={{ width: '160' }} />
                    <Column height="40" width={100} text="Cds Valido Al" dataIndex='datFinValCdS' filter='string' exportStyle={{ width: '140' }} />
                    <Column height="40" width={120} text="MP" dataIndex='mp' filter='string' exportStyle={{ width: '160' }} />
                    <Column height="40" width={260} text="Note MP" dataIndex='noteMp' flex={1} exportStyle={{ width: '520' }}
                        filter='string' flex={1} renderer={(value, metaData, record) => (
                            <div dangerouslySetInnerHTML={{ __html: value }} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} />
                        )} />
                </Grid>

            </Panel>
        </Container>

    );
}

export default MonitoraggioCds;