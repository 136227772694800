import { useState, React, useEffect } from 'react';
import { Container, Panel, Titlebar, Grid, Button, FileField, SegmentedButton, Column, Toolbar } from '@sencha/ext-react-modern';
import { Loadmask } from "@sencha/ext-react-modern";
import { useUser } from '../components/UserContext';
//import * as XLSX from 'xlsx'; //serve per leggere e trasformare file Excel in array

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.plugin.Exporter'
]);

const storeUploadXlsInfo = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        timeout: 300000,
        url: 'Po/LeggiUploadXlsInfo',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});


//  let x = '';
function UploadPO() {
    let uploadTimerPause = false;
    const { user, switchUser } = useUser();
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento in corso ..." });
    const [state, setState] = useState({ uploadXlsInfo: storeUploadXlsInfo })
    const aggiornaState = () => {
        state.uploadXlsInfo = storeUploadXlsInfo;

        //  x = state.uploadXlsInfo.data.pctCmp
        setState({ ...state });
    }

    const loadStoreUploadXlsInfo = () => {
        if (uploadTimerPause === false) {
            storeUploadXlsInfo.load({
                params: { ideUte: user.ideUte },
                timeout: 300000,
                callback: function () {
                    aggiornaState();
                }
            });
        }
    };

    //aggiornamento intervalli------------------
    useEffect(() => {
        loadStoreUploadXlsInfo();
 
        const interval = setInterval(() => {
            loadStoreUploadXlsInfo(); //aggiorna ogni 90 sec
        }, 90000);
        return () => clearInterval(interval);
        
    }, []);

    //----------------------------

    let title = "Upload PO";
    let ui = 'action';

    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;

    //----------------------------------------------------------------
    function renderDataElab(value) {
        if (value === null || value === 0 || value == '0001-01-01T00:00:00') {
            return null; 
        } else {
            return Ext.Date.format(new Date(value), "d/m/Y H:i:s");

        }
    }

    //------------------READ EXCEL FILE CONVERT TO ARRAY --------------------------
    let excelFile = "";
    let byteArray = null;


    const excelToByteArray = (args) => {
        excelFile = args.newValue;
        if (excelFile !== "") {
            const file = args.sender.el.down('input[type=file]').dom.files[0];

            reader(file, (err, res) => {
                if (err != null) {
                    Ext.toast({ message: 'Errore: ' + err, timeout: 4000 });
                }
                else {
                    let base64result = res.split(',')[1];
                    byteArray = base64result;
                    Ext.toast({ message: 'File Letto!', timeout: 4000 });
                }
            });      

        }
    }
    function reader(file, callback) {
        const fr = new FileReader();
        fr.onload = () => callback(null, fr.result);
        fr.onerror = (err) => callback(err);
        fr.readAsDataURL(file);
    }
    //-----------------------------------------------------------------------------

    const tplLog = data => (
        <div className='rowexpander'>
            <div id='multicolumn'>
                {data.logEla?.split("\n\t").map((i, key) => {
                    return <div key={key}>{i}</div>;
                })}
            </div>
        </div>
    )

    //SUBMIT EVENT --------------------------
    const fileSubmit = () => {
        uploadTimerPause = true;
        setStateLoadMask({ hidden: false, message: "Attendere caricamento in corso ..." });
        let excelFile = Ext.ComponentQuery.query('#ffUpload')[0]._value;
        //--------------- POST---------------------------------------------------- int ideUte, string IP, string nomeFile, byte[] file
        Ext.Ajax.request({
            url: 'Po/UploadFileExcelPO',
            method: 'POST',
            params: {
                ideUte: user.ideUte,
                IP: "",
                nomeFile: excelFile,
                file: byteArray
            },     
            timeout: 800000,
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);
                    if (returnObj.resp == "KO") {
                        if (returnObj.errori === "" || returnObj.errori[0] === "")
                            Ext.Msg.alert('Errore in Upload PO', returnObj.msgErr);
                        else {
                            // gestione visualizzazione array errori (returnObj.errori)
                            let strErr = "";
                            for (var i = 0; i < returnObj.errori.length; i++) {
                                strErr += "\n" + returnObj.errori[i];
                            }
                            Ext.Msg.alert('Errore in Upload PO', strErr);                            
                        }
                        setStateLoadMask({ hidden: true });
                        uploadTimerPause = false;
                    }
                    else {
                        setStateLoadMask({ hidden: true });
                        uploadTimerPause = false;
                        loadStoreUploadXlsInfo(); 
                    }
                }
                else {
                    Ext.Msg.alert('Upload PO', "Errore nel caricamento del file.");
                    setStateLoadMask({ hidden: true });
                    uploadTimerPause = false;
                }                
            }
        });
    }

    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridUploadPo')[0];
        grid.saveDocumentAs({
        type: 'xlsx',
        title: title,
        fileName: title + '.xlsx'
        });
    }
    //-----------------------SCARICA FILE---------------------------------------------
    const downloadBlob = (ideUplTrc, nomeFile) => {
        let url = `Po/LeggiBlob?ideUplTrc=${ideUplTrc}`;
        let msgKo = 'Esportazione fallita';

        fetch(url)
            .then(response => {
                // Estre il nome del file dall'intestazione Content-Disposition
                let archiveNameRTO = nomeFile + '.xls';
                const contentDisposition = response.headers.get('Content-Disposition');
                const regex = /filename\*=UTF-8''(.+?)(?=';|$)/;
                const match = regex.exec(contentDisposition);

                if (match) {
                    archiveNameRTO = match[1];
                }

                return { response, archiveNameRTO };
            })
            .then(({ response, archiveNameRTO }) => response.blob()
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = archiveNameRTO; // Utilizza il nome del file recuperato
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                })
            )
            .catch(error => {
                Ext.Msg.alert('Operazione fallita', msgKo);
            });
    }
    function onDownloadFileClick(grid, info) {
        if (info !== null) {
            downloadBlob(info.record.data.ideUplTrc, info.record.data.nomFie);
        }
    }
    const DOCUMENT_URL = "/Documento";
    const getDocumento = (idPag) => {
        window.open(DOCUMENT_URL + "?idPag=" + idPag, '_blank');
      };
    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Loadmask cls="custom-grid" itemId="loadMaskRtoDet" hidden={stateLoadMask.hidden}
                indicator={false}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true} />

            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1}>
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-upload" />
                    <Button align="right" cls="title-icon" iconCls="x-fa fa-circle-question" handler={() =>getDocumento(4)}/>
                </Titlebar>


                <Grid
                    flex={1} scrollable itemId="gridUploadPo"
                    selectable={{ checkbox: false, mode: 'single' }}
                    store={state.uploadXlsInfo}
                    plugins={{ rowexpander: true, gridfilters:true, gridexporter:true}}
                    cls="custom-grid"
                    itemConfig={{
                        body: {
                            tpl: tplLog
                        },
                        viewModel: true
                    }}

                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                    cls="custom-grid"
                >

                    <Toolbar docked="top" margin="10 0 15 10" >
                        {/*
                        
                        ui='action' handler={{hanfleFileSubmit}fileCaricato == false ? Ext.toast('file non in formato Excel') : Ext.toast('fffff')}
                            */ }

                        <SegmentedButton docked="right">
                            <Button cls="marginButton appButton" text="Carica" ui={ui} handler={fileSubmit} />
                            <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />

                        </SegmentedButton>
                        <Panel docked="right" margin="-25 0 0 0">
                            <FileField itemId="ffUpload" buttontext="Sfoglia" title='title' margin="0 10 0 0" cls="appButton marginButton" width="400"                           
                                label="File..."
                                onChange={excelToByteArray}
                                onAction={excelToByteArray}
                            />
                        </Panel >
                    </Toolbar>

                    <Column height="35" width={65} text="Scarica" ignoreExport="true" cell={{
                        tools: {
                            download: {                               
                                handler: onDownloadFileClick.bind(this),
                                bind: {
                                    hidden: '{record.desTipEla!="File"}',
                                    iconCls: "x-fa fa-download buttonGridTool",
                                    tooltip: 'Scarica'
                                }
                            }
                        }

                    }}
                    />
                    <Column height="40" width={200} text="Nome File" dataIndex="nomFie" filter='string' flex={1} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                    <Column height="40" width={150} text="Tipo Elaborazione" dataIndex="desTipEla" filter='string' autoSizeColumn="true" exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                    <Column height="40" width={130} text="Utente" dataIndex="usr" filter='string' exportStyle={{ width: '170', alignment: { horizontal: 'Left' } }} />
                    <Column height="40" width={140} text="Data Upload" dataIndex="datUpl" type="datecolumn" formatter='date("d/m/Y H:i:s")' filter='date' exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} />
                    <Column height="40" width={140} text="Data Fine Elaborazione" dataIndex="datFinEla" type="datecolumn" renderer={renderDataElab} filter='date' exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                    <Column height="40" width={130} text="Tot. Associazioni" dataIndex="numTrcTot" filter='int' exportStyle={{ width: '180', alignment: { horizontal: 'Left' } }} />
                    <Column height="40" width={100} text="PO Associati" dataIndex="numTrcAsc" filter='string' exportStyle={{ width: '180', alignment: { horizontal: 'Left' } }} />
                    <Column height="40" width={95} text="PO Invariati" dataIndex="numTrcInv" filter='string' exportStyle={{ width: '160', alignment: { horizontal: 'Left' } }} />
                    <Column height="40" width={130} text="PO Non Associati" dataIndex="numTrcErr" filter='int' exportStyle={{ width: '200', alignment: { horizontal: 'Left' } }} />
                    <Column height="40" width={130} text="Stato" dataIndex="pctCmp" filter='string' exportStyle={{ width: '90', alignment: { horizontal: 'Left' } }} cell={{
                        xtype: 'widgetcell',
                        widget: {
                            xtype: 'progressbarwidget',
                            bind: '{record.pctCmp / 100}',
                            //textTpl: '{percent:number("0")/100}%'
                            textTpl: '{value:percent}'
                        }
                    }} />
                    <Column height="40" minWidth={100} text="Log Elaborazione" dataIndex='logEla' filter='string' flex={1} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />


                </Grid>

            </Panel>
        </Container>
    );


}

export default UploadPO;

