import React, { useEffect, useState } from "react";
import {
  Panel,
  Dialog,
  Loadmask,
  Label,
  Container,
} from "@sencha/ext-react-modern";
import { useLocation } from "react-router-dom";
import { useUser } from "../components/UserContext";
const Ext = window["Ext"];
function Documento() {
  const loadMaskMessage = "Attendere, caricamento Documenti...";
  const [documenti, setDocumenti] = useState([]);
  const [hiddenLoadMask, setHiddenLoadMask] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idPaginaNum = parseInt(searchParams.get("idPag"), 10);
  const { user } = useUser();
  const ideUte = user?.ideUte;
  const [pagina, setPagina] = useState(null);
  const [isLoadingDownloadExcel, setIsLoadingDownloadExcel] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        if (!isNaN(idPaginaNum)) {
          await Ext.Ajax.request({
            url: "HelpOnLine/GetAllHelpOnLine",
            method: "GET",
            params: {
              ideUte: ideUte,
            },
            callback: function (records, success, operation) {
              if (success && records != null) {
                setPagina(
                  Ext.decode(operation.responseText)?.filter(
                    (item) => item.idPagina === idPaginaNum
                  )[0]
                );
                //console.log(
                //  Ext.decode(operation.responseText)?.filter(
                //    (item) => item.idPagina === idPaginaNum
                //  )[0]
                //);
              } else {
                console.error("Nessun dato presente:", success, records);
              }
            },
          });
          await Ext.Ajax.request({
            url: "HelpOnLine/GetSingleHelpOnLine",
            method: "GET",
            params: {
              ideUte: ideUte,
              idePag: idPaginaNum,
            },
            callback: function (records, success, operation) {
              if (success && records != null) {
                setDocumenti(Ext.decode(operation.responseText));
              } else {
                console.error("Nessun dato presente:", success, records);
              }
            },
          });
        } else {
          console.error("L'idPagina non è un numero valido.");
        }

        setHiddenLoadMask(true);
      } catch (error) {
        console.error("Errore durante il recupero delle guide:", error);
        setHiddenLoadMask(true);
      }
    }
    fetchData();
  }, [ideUte, idPaginaNum]);

  const base64ToUint8Array = (base64) => {
    const binaryString = window.atob(base64);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }
    return byteArray;
  };

  const onDownloadClick = (record) => {
    setIsLoadingDownloadExcel(true);
    let fileType;
    switch (record?.nomeFilePDF.split(".").pop()) {
      case "xls":
        fileType = "application/vnd.ms-excel";
        break;
      case "xlsx":
        fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "pdf":
        fileType = "application/pdf";
        break;
      default:
        fileType = "application/octet-stream";
    }
    const byteArray = base64ToUint8Array(record?.blobPDF);
    const excelBlob = new Blob([byteArray], { type: fileType });
    const fileName = record?.nomeFilePDF;
    const excelUrl = URL.createObjectURL(excelBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = excelUrl;
    downloadLink.download = fileName;
    downloadLink.click();

    URL.revokeObjectURL(excelUrl);
    setIsLoadingDownloadExcel(false);
  };
  const iconClass = isLoadingDownloadExcel ? "x-fa fa-spinner fa-spin buttonPanelTool exportExcelMiniGuid" : "x-fa fa-file-excel buttonPanelTool exportExcelMiniGuide"
  const content =
    documenti.length > 0 &&
    documenti.some((doc) => doc.blobPDF !== null && doc.blobPDF !== "") ? (
      documenti.map((documento) => {
        const parts = documento?.nomeFilePDF?.split(".");
        const extension = parts[parts?.length - 1];
        const isExcel = ["xls", "xlsx"].includes(extension?.toLowerCase());
        if (documento.blobPDF) {
          const byteCharacters = documento.blobPDF;
          const byteArray = base64ToUint8Array(byteCharacters);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          const urlPDF = URL.createObjectURL(blob);
          const content = !isExcel ? (
            <Panel
              key={documento.idOrdine}
              shadow
              title={documento.subtitle}
              collapsible={{
                direction: "top",
                dynamic: true,
              }}
              flex={1}
              cls="doc-panel"
            >
              <iframe
                title={documento.subtitle}
                src={urlPDF}
                className="iframeDoc"
              />
            </Panel>
          ) : (
            <Panel
              key={documento.idOrdine}
              shadow
              title={documento.subtitle}
              flex={1}
              cls="doc-panel excel-panel"
              tools={[
                {
                  iconCls: iconClass,
                  tooltip: `Scarica ${documento?.subtitle}`,
                  handler: () => onDownloadClick(documento),
                },
              ]}
            />
          );
          return content;
        } else {
          return null;
        }
      })
    ) : (
      <Container
        cls="noDocContainer"
        flex={1}
        layout={{ type: "hbox", align: "middle" }}
      >
        <Label
          style={{
            fontSize: "26px",
            fontWeight: "bold",
            width: "fit-content",
          }}
          html={"NESSUN DOCUMENTO DISPONIBILE"}
        />
      </Container>
    );

  return (
    <Dialog
      title={pagina?.nomePagina ? pagina?.nomePagina : "-"}
      displayed={true}
      maximized={true}
      cls="documentsBody"
    >
      {hiddenLoadMask ? (
        content
      ) : (
        <Loadmask
          cls="custom-grid doc-grid"
          itemId="loadMaskDOC"
          hidden={false}
          indicator={true}
          message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class="spinMessage">${loadMaskMessage}</span></div>`}
          modal={true}
        />
      )}
    </Dialog>
  );
}

export default Documento;
