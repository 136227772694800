import React from 'react';
import '../App.css';
import { Container, Image, Button, ToolTip, Label } from '@sencha/ext-react-modern';
import img_logo from '../resources/Rete_Ferroviaria_Italiana_logo.png';
import { useNavigate } from "react-router-dom";
import { useUser } from '../components/UserContext';

import LoggedInUser from "./LoggedInUser";

//const LoggedInUser = () => {
//    const { user } = useUser();
//    if (!user) {
//        return (
//        <ToolTip showOnTap align="tl-tr" anchorToTarget anchor>
//            <ul style={{ marginBottom: '15px' }}>
//                <li>Nessun utente loggato:</li>
//            </ul>
//        </ToolTip>);
//    }   
//    const userContent = `<div class="text-container"><div class="nomeUtente">${user.nome.toUpperCase()} ${user.cognome.toUpperCase()}</div><div class="ruoloUtente">${user.descrizioneGruppo}</div></div>`
//    return (<Button cls="userIcon" align="right" iconCls="x-fa fa-user" text={userContent} disabled={true} />); 
//}



function AppTitle(props) {
    //const [gruppi, setGruppi] = useState({ gruppi: [], loading: true });

    //const groupData = () => {

    //    const resp = await fetch('astroif');
    //    const data = await resp.json();

    //    var result = [];
    //    for (var i in data)
    //        result.push([i, data[i]]);

    //    setGruppi(result);

    //}

    //const  populateGruppiData =

    //    (async () => {
    //        try {
    //            let response = await fetch('astroif');
    //            let data = await response.json();
    //            this.setState({ gruppi: data, loading: false });
    //        }
    //        catch (err)
    //        {
    //            alert(err);
    //        }
    //    })();

    const { user, switchUser } = useUser();//to do: fare componente a parte per logout

    let navigate = useNavigate();
    function imageClick() { navigate('/'); }

    // let headerHeight = 62;
    // let logoPadding = 5;
    let logoPadding = 6;
    let headerHeight = 52;

    const headerStyle = {
        backgroundColor: "#fefefe"
    };
    const appStyle = {
        backgroundColor: "#003780",
        color: "#fefefe"
    };
    let waveWidth = 80;
    let waveHeight = 40;
    // let waveOffset = 40;
    let waveOffset = 0;



    return (

        // <Container layout="hbox" height={headerHeight}  padding="0 1 0 25">
        <Container layout="hbox" height={headerHeight} padding="0 1 0 0">
            <Container layout={{ type: 'hbox', align: 'center' }} padding={logoPadding} style={headerStyle}>
                <Image
                    src={img_logo}
                    // height={headerHeight - logoPadding * 2}
                    // onLoad={(p) => {
                    //     let factor = p.e.srcElement.width / p.e.srcElement.height;
                    //     p.sender.setWidth((headerHeight - logoPadding * 2) * factor);
                    //     p.sender.setStyle(headerStyle);
                    // }}
                    height={42}
                    onLoad={(p) => {
                        let factor = p.e.srcElement.width / p.e.srcElement.height;
                        p.sender.setWidth(42 * factor);
                        p.sender.setStyle(headerStyle);
                    }}
                />
            </Container>
            <Container width={waveOffset} style={headerStyle} />
            <Container cls="marginBanner" layout="vbox" flex={1}>
                <Container height={headerHeight - waveHeight} layout="hbox" style={headerStyle}></Container>
                <Container layout="hbox" height={waveHeight}>
                    <Container layout={{ type: 'hbox', align: 'left' }} style={appStyle}>
                        <svg width={waveWidth} height={waveHeight} viewBox="0 0 80 40">
                            <path fill={headerStyle.backgroundColor} d="M0,40 C40,40 40,0 80,0 L0,0 Z" />
                        </svg>
                    </Container>
                    <Container cls="appNameContainer" flex={1} layout={{ type: 'hbox', align: 'middle' }} style={appStyle} padding="0 1 0 20" >
                        <Button cls="trainIcon" iconCls="x-fa fa-train" ui="plain" handler={imageClick} />
                        <Label style={{ fontFamily: "Arial Black", fontSize: "33px", fontWeight: "bold" }} padding="0 8 0 20" html={"ASTRO IF"} />
                        <Label style={{ fontFamily: "Arial", fontStyle: "italic", fontSize: "12px", fontWeight: "bold" }} padding="5 8 0 8" html={`ASSEGNAZIONE TRACCIA ORARIO<br />IMPRESE FERROVIARIE <span style='font-size: 9px'> (Ver. ${props.versioneApp})</span>`} />
                        {/*<Label style={{ fontFamily: "Arial", fontStyle: "italic", fontSize: "9px" }} padding="16 0 0 0" html={"<br />(Ver." + props.versioneApp + ")"} />*/}
                    </Container>
                    <Container cls="userInfo" layout={{ type: 'hbox' }} style={appStyle}>
                        <LoggedInUser />
                    </Container>
                </Container>
            </Container>
        </Container>
    );
}

export default AppTitle;

