const Ext = window['Ext'];

export default Ext.define('ImpostazioneModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideLocPrv', type: 'auto' },
            { name: 'nomLocPrv', type: 'auto' },
            { name: 'ideLocDsn', type: 'auto' },
            { name: 'nomLocDsn', type: 'auto' },
            { name: 'ideLcm', type: 'auto' },
            { name: 'desLcm', type: 'auto' },
            { name: 'ideTipFre', type: 'auto' },
            { name: 'sigTipFre', type: 'auto' },
            { name: 'ideTipMatRmc', type: 'auto' },
            { name: 'sigTipMatRmc', type: 'auto' },
            { name: 'pesRmc', type: 'auto' },
            { name: 'pctPesFre', type: 'auto' },
            { name: 'velMaxTre', type: 'auto' },
            { name: 'lunTre', type: 'auto' },
            { name: 'notCmp', type: 'auto' },
            { name: 'idePesAsl', type: 'auto' },
            { name: 'sigPesAsl', type: 'auto' },
            { name: 'desPesAsl', type: 'auto' },
            { name: 'flagTE', type: 'auto' },
            { name: 'noteTE', type: 'auto' },
            { name: 'flagTES', type: 'auto' },
            { name: 'noteTES', type: 'auto' },
            { name: 'flagTEP', type: 'auto' },
            { name: 'noteTEP', type: 'auto' },
            { name: 'flagMerPcl', type: 'auto' },
            { name: 'notMerPcl', type: 'auto' },
            { name: 'ideSagoma1', type: 'auto' },
            { name: 'noteSagoma1', type: 'auto' },
            { name: 'ideSagoma2', type: 'auto' },
            { name: 'noteSagoma2', type: 'auto' },
            { name: 'flagSagoma', type: 'auto' },
            { name: 'codImp', type: 'auto' },
            { name: 'prgPrcPrv', type: 'auto' },
            { name: 'prgPrcDsn', type: 'auto' },
            { name: 'flagAltoRischioMerciPericolose', type: 'auto' },
            { name: 'conformeSTI', type: 'auto' }
        ],
    validators: {
        
        ideLocPrv: 'presence',
        ideLocDsn: 'presence',
        ideLcm: 'presence',
        ideTipFre: 'presence',
        pesRmc: 'presence',
        pctPesFre: 'presence',
        velMaxTre: 'presence',
        lunTre: 'presence'
    }
});
