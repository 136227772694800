import React, { useState, useEffect } from "react";
import {
    Panel,
    Container,
    Button,    
    Label
} from "@sencha/ext-react-modern";

function PassaggiStatoCondizioni(props) {
    const [condizioni, setCondizioni] = useState(props.condizioni);   

    useEffect(() => {
        setCondizioni(props.condizioni);        
    }, [props.modificheControllo]);

    //console.log(condizioni);

    return (
        
        <Container layout="hbox">
            {condizioni.map((item, index) => (
                <Container
                    cls="cStatoItem"
                    layout="hbox"
                    key={props.key+`cnd${index}`}
                    //key={item.id}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    {item.check ? (
                        <Label
                            html={
                                '<span class="x-fa fa-circle-check checkPassaggioStato"/>'
                            }
                        />
                    ) : (
                        <Label
                            html={
                                '<span class="x-fa fa-circle-xmark xPassaggioStato"/>'
                            }
                        />
                    )}
                    <Label
                        margin="0 10 0 5"
                        style={{ fontSize: "15px" }}
                        html={item.nome}
                    />
                </Container>
            ))}
        </Container>
                       

    );

}
export default PassaggiStatoCondizioni;