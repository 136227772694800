import React from 'react';
import { Panel, Grid, Column, Container } from '@sencha/ext-react-modern';

import localitaPercorsoModel from '../models/LocalitaPercorsoRTOModel.js';

const Ext = window['Ext'];

function PoPercorso(props) {

    const toolTipFlagCds = {
        tools: {
            apriRto: {
                bind: {
                    cls: '{ "cer_Id" + record.icoCer } tableIconTooltipRTO',
                    tooltip: '{record.tooltipCer}',
                    passive: true
                }
            }
        }
    }
    const toolTipFlagCdsMP = {
        tools: {
            apriRto: {
                bind: {
                    cls: '{ "cermp_Id" + record.icoMP } tableIconTooltipRTO',
                    tooltip: '{record.tooltipMP}',
                    passive: true
                }
            }
        }
    }
    const toolTipFlagCir = {
        tools: {
            apriRto: {
                bind: {
                    cls: '{ "cir_Id" + record.icoCir } tableIconTooltipRTO',
                    tooltip: '{record.tooltipCir}',
                    passive: true
                }
            }
        }
    }

    const storePercorso = Ext.create('Ext.data.Store', {
        model: localitaPercorsoModel,
        data: props.schedaPo.schede.percorso
    });

    return (
        <Container>
            <Panel
                itemId={"poPercorso" + props.ind}
                title={props.schedaPo.testataPO.desTrcOra}
                titleCollapse={true}
                cls='PnlSectionPOTR'
                iconCls="x-fa fa-train"
                collapsible={{ direction: 'top' }}
                collapsed={false}
            >
                <Panel layout="hbox" >
                    <Panel layout="vbox" flex={1} cls="column-in-panel">
                        <Grid
                            minHeight={300}
                            maxHeight={500}
                            flex={1}
                            scrollable
                            selectable={{ checkbox: false, mode: 'single' }}
                            store={storePercorso}
                            plugins={{
                                gridfilters: {}
                            }}
                            itemConfig={{
                                viewModel: true
                            }}
                            cls="custom-grid"
                        >
                            <Column width={20} cell={{
                                bind: {
                                    //cls: '{(record.sigPas == "O") ? "origine" : "")}'
                                    cls: '{(record.sigPas == "O") ? "originePO" : ((record.sigPas == "D") ? "destinoPO" : ((record.sigPas == "F") ? "fermataPO" : "transitoPO"))}'
                                }
                            }} />
                            <Column dataIndex="sigPas" width={20} filter='string' sortable={false} />
                            <Column text="Localit&aacute" width={250} dataIndex="nomLoc" sortable={false} filter='string' />
                            <Column text="Arriva (hh24:mi)" width={75} dataIndex="oraArr" sortable={false} filter='string' />
                            <Column text="Sosta (min.)" width={75} dataIndex="minPas" sortable={false} />
                            <Column text="Parte (hh24:mi)" width={75} dataIndex="oraPar" sortable={false} filter='string' />
                            <Column text="Comp." width={90} dataIndex="codComRfi" sortable={false} filter='string' />
                            <Column text="N.Treno alt." width={90} dataIndex="nomTreAlt" sortable={false} filter='string' />
                            <Column text="Op. Tecniche" width={250} dataIndex="opeTec" sortable={false} filter='string' />
                            <Column width={70} dataIndex="giorniCirc" text='<span class="x-far fa-calendar-check"/>' sortable={false} />                          
                            <Column text="Prog. Km" width={75} dataIndex="progrKm" sortable={false} filter='string' />
                            <Column text="Copertura CdS" width={98} align="center" sortable={false} cell={ toolTipFlagCds  } />
                            <Column text="Copertura CdS MP" width={95} align="center" sortable={false} cell={ toolTipFlagCdsMP }  />
                            <Column text="Circolabilit&aacute" width={117} align="center" sortable={false} cell={ toolTipFlagCir } />
                        </Grid>
                    </Panel>
                </Panel>
            </Panel>
        </Container>
    );

}

export default PoPercorso;