import React from "react";
import { Panel, Button } from "@sencha/ext-react-modern";

function Bottoni({ descTipoGuida, pagine, getDocumento }) {
  return (
    <Panel
      cls="tipoGuida"
      title={descTipoGuida.toUpperCase()}
      layout={{ type: "vbox", align: "center" }}
      flex={1}
    >
      {pagine
        .filter((pagina) => pagina.descTipoGuida === descTipoGuida)
        .map((pagina) => (
          <Button
            key={pagina.idPagina}
            text={pagina.nomePagina}
            cls="marginButton guidaButton"
            iconCls={`x-fa ${pagina.nomeIcona}`}
            handler={() => {
              getDocumento(pagina.idPagina);
            }}
          />
        ))}
    </Panel>
  );
}

export default Bottoni;
