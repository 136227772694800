import React from 'react';
import { Panel, Container, Button, Loadmask, Grid, Column } from '@sencha/ext-react-modern';
import { useState, useContext, useEffect } from "react";
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import periodicitaModel from '../models/RtoPeriodicitaModel.js';
import Calendario from "../components/Calendario/Calendario/Calendario.js";
import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
import Stazioni from "../components/Calendario/Stazioni/Stazioni.js";
import { useUser } from '../components/UserContext';
import { parseDate } from "../components/Calendario/Calendario/Funzioni.js";
import localitaModel from '../models/LocalitaModel.js';
import dayjs from 'dayjs';

const Ext = window['Ext'];
var rangeDateStart = null;
var rangeDateEnd = null;
var ideLocAppOri = null;
var ideLocAppDes = null;

Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

const storePeriodicita = Ext.create('Ext.data.Store', {
    model: periodicitaModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});




function RtoPeriodicita(props) {
    let arrayLocalita = [];
    const storeLocalita = Ext.create('Ext.data.Store', {
        model: localitaModel,
        proxy: {
            type: 'localstorage',
        },
        autoload: false
    });
    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede;

    const [statePanelCollapse, setStatePanelCollapse] = useState(props.collapse);

    useEffect(() => {
        setStatePanelCollapse(props.collapse);
    }, [props.collapse]);

    rangeDateStart = new Date(contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", rtoSchede.data.items[0].data.rto.ideAnnFro, 0, false, false, true).data.datIniVal);
    rangeDateEnd = new Date(contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", rtoSchede.data.items[0].data.rto.ideAnnFro, 0, false, false, true).data.datFinVal);
    ideLocAppOri = rtoSchede.data.items[0].data.percorsoRTO.find(x => x.idePas === 1).prgPrc;
    ideLocAppDes = rtoSchede.data.items[0].data.percorsoRTO.find(x => x.idePas === 2).prgPrc;
    function aggiornaControllo(stato) {
        props.aggiornaStateControllo();
    }
    function aggiornaStateCheck(ckPeriodi) {
        props.aggiornaStateModifica(true);
        props.aggiornaStatePeriodII(ckPeriodi);
    }
    const { user, switchUser } = useUser();
    const [params, setParams] = useState({ hidden: false, message: "Attendere salvataggio in corso...", calendars: contextVersioneRto.periodicitaCalendario, ricarica: true });

    function updateValuesParams(a, b, c, d) {
        params.hidden = a;
        params.message = b;
        params.calendars = c;
        params.ricarica = d;
        setParams({ ...params });
    }

    Ext.Ajax.request({
        url: 'Rto/LeggiPercorsoRTO',
        method: 'GET',
        params: {
            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
        },
        callback: function (records, success, operation) {
            var returnObj = Ext.decode(operation.responseText);
            storeLocalita.clearData();
            storeLocalita.removeAll();
            storeLocalita.loadData(returnObj);
            arrayLocalita = [];

            for (let i = 0; i < storeLocalita.data.items.length; i++) {
                let app = { text: storeLocalita.data.items[i].data.nomLoc, value: storeLocalita.data.items[i].data.prgPrc, ideLoc: storeLocalita.data.items[i].data.ideLoc };
                arrayLocalita.push(app);
            }
        }
    });


    /* colore utilizzato x pallocchi stazioni  */ 
    const colore ="#5fa2dd"





    const caricaPeriodicita = () => {
        storePeriodicita.clearData();
        storePeriodicita.removeAll();
        Ext.Ajax.request({
            url: 'Rto/LeggiCalendarioPeriodicita',
            method: 'GET',
            params: { ideRTO: rtoSchede.data.items[0].data.rto.ideRTO },
            callback: function (resp, success, operation) {
                updateValuesParams(true, "Attendere salvataggio in corso...", Ext.decode(operation.responseText), false);
                Ext.Ajax.request({
                    url: 'Rto/LeggiListaPeriodicitaRTO',
                    method: 'GET',
                    params: {
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                    },
                    callback: function (records, success, operation) {
                        props.aggiornaStateTabPeriodicita(false);
                        var returnObj = Ext.decode(operation.responseText);
                        storePeriodicita.loadData(returnObj);
                        Ext.ComponentQuery.query('#gridPeriodicita')[0].setStore(storePeriodicita);

                    }
                })
            }
        })

    }
    if (params.ricarica === true || props.modificheTabPeriodicita === true)
        caricaPeriodicita();

    //function refreshGridPeriodicita() {
    //    Ext.Ajax.request({
    //        url: 'Rto/LeggiCalendarioPeriodicita',
    //        method: 'GET',
    //        params: { ideRTO: rtoSchede.data.items[0].data.rto.ideRTO },
    //        callback: function (resp, success, operation) {
    //            setCalendars(Ext.decode(operation.responseText));
    //            Ext.Ajax.request({
    //                url: 'Rto/LeggiListaPeriodicitaRTO',
    //                method: 'GET',
    //                params: {
    //                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
    //                },
    //                callback: function (records, success, operation) {
    //                    var returnObj = Ext.decode(operation.responseText);
    //                    storePeriodicita.loadData(returnObj);
    //                    Ext.ComponentQuery.query('#gridPeriodicita')[0].setStore(storePeriodicita);

    //                }
    //            })
    //        }
    //    })
    //}

    function onNuovaPeriodicitaClick() {
        var grid = Ext.ComponentQuery.query('#gridPeriodicita')[0];
        var store = grid.getStore();
        var record = Ext.create(periodicitaModel, {});
        record.data.ideLocPrv = ideLocAppOri;
        record.data.ideLocDsn = ideLocAppDes;
        record.data.ideOpv = 0;
        record.data.datIniPer = rangeDateStart;
        record.data.datFinPer = rangeDateEnd;
        record.data.flgSmr = true
        store.insert(grid.getStore().data.length, record);
        grid.getPlugin('rowedit').startEdit(record, 1);
    }

    function onAggiungiPeriodicitaClick(grid, info) {

        var store = grid.getStore();
        var record = Ext.create(periodicitaModel, {});
        record.data.ideLocPrv = ideLocAppOri;
        record.data.ideLocDsn = ideLocAppDes;
        record.data.ideOpv = 0;
        record.data.datIniPer = rangeDateStart;
        record.data.datFinPer = rangeDateEnd;
        record.data.flgSmr = true
        store.insert(info.cell.row._recordIndex + 1, record);
        grid.getPlugin('rowedit').startEdit(record, 1);
    }
    function onModificaPeriodicitaClick(grid, info) {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 2);
            Ext.ComponentQuery.query('#cmbLocOriPer')[0].setValue(info.record.data.prgPrcPrv);          
            Ext.ComponentQuery.query('#cmbLocDsnPer')[0].setValue(info.record.data.prgPrcDsn);
        }
    }

    function onEliminaPeriodicitaClick(grid, info) {
        if (Ext.ComponentQuery.query('#gridPeriodicita')[0].getStore().getRange().length > 1) {
            Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione della periodicita?", function (button) {

                if (button === 'yes') {
                    updateValuesParams(false, "Attendere salvataggio in corso...", params.calendars, false);
                    var periodicitaByDB = null;
                    Ext.Ajax.request({
                        url: 'Rto/LeggiListaPeriodicitaRTO',
                        method: 'GET',
                        params: {
                            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                        },
                        callback: function (resp, success, operation) {
                            periodicitaByDB = Ext.decode(operation.responseText);
                            if (periodicitaByDB.find(per => per.idePerRto === info.record.data.idePerRto) != null) {
                                Ext.Ajax.request({
                                    url: 'Rto/EliminaPeriodicita',
                                    method: 'POST',
                                    params: {
                                        ideUte: user.ideUte,
                                        ipAddress: "",
                                        idePerRTO: info.record.data.idePerRto
                                    },
                                    callback: function (records, success, operation) {
                                        updateValuesParams(false, "Attendere salvataggio in corso...", params.calendars, true);
                                        if (success) {
                                            var returnObj = Ext.decode(operation.responseText);
                                            //modifica gestione errore
                                            if (returnObj.resp == "KO") {
                                                Ext.Msg.alert('Elimina periodicita fallito', returnObj.msgErr)
                                            }
                                            else {
                                                Ext.toast({ message: 'Periodicita eliminata', timeout: 4000 });
                                                aggiornaControllo(true);
                                                Ext.Ajax.request({
                                                    url: 'Rto/LeggiPercorsoRTO',
                                                    method: 'GET',
                                                    params: {
                                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                    },
                                                    callback: function (records, success, operation) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        props.aggiornaStateGiorniCircolazione(returnObj[0].giO_PER);
                                                    }
                                                });
                                                Ext.Ajax.request({
                                                    url: 'Rto/CheckPeriodicitaImpegnoImpianto',
                                                    method: 'POST',
                                                    params: {
                                                        ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                                                    },
                                                    callback: function (records, success, operation) {
                                                        var returnCheck = Ext.decode(operation.responseText);
                                                        if (returnCheck.length > 0) {
                                                            let stringa = "";
                                                            for (let i = 0; i < returnCheck.length; i++) {
                                                                stringa = stringa + returnCheck[i] + "<br>";
                                                            }
                                                            Ext.Msg.alert('Attenzione', stringa);
                                                            aggiornaStateCheck(false);
                                                        }
                                                        else {
                                                            aggiornaStateCheck(true);
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                        else {
                                            Ext.Msg.alert('Elimina periodicita fallito', 'Elimina periodicita fallito');
                                        }
                                    }
                                });
                            }
                            else {
                                Ext.ComponentQuery.query('#gridPeriodicita')[0].getStore().remove(info.record);
                                Ext.toast({ message: 'Periodicita eliminata', timeout: 4000 });
                                updateValuesParams(false, "Attendere salvataggio in corso...", params.calendars, true);
                            }
                        }
                    })

                }
            })
        }
        else {
            Ext.toast({ message: 'Impossibile eliminare', timeout: 4000 });
        }
   

    }


    const renderListOfCalendar = (calendars) => {
        const cal = calendars.map((name, i) => <><Panel collapsible={false} key={`sintesi${i}`} cls="calendar-container">
            <br />
            <div className="periodicitaHeader">
                <Stazioni stazPartenza={calendars[i].noM_LOC_PRV} stazArrivo={calendars[i].noM_LOC_DSN} colore={colore} />
                <CalendarioSintetico
                    giorniTreno={calendars[i].snG_PER? calendars[i].snG_PER.split('1').length - 1 : '0'}
                    periodicitaSintetico={calendars[i].snG_GIO_SET}
                />
            </div>
            <Button cls="plus-minus-button" itemId={`calendarioExpButmin${i}`} iconCls="x-fa fa-minus" handler={function () {
                Ext.ComponentQuery.query(`#calendarioExpButPlus${i}`)[0].show();
                this.hide();
                Ext.ComponentQuery.query(`#calendarioExp${i}`)[0].hide();
            }}></Button>
            <Button cls="plus-minus-button" itemId={`calendarioExpButPlus${i}`} hidden={true} iconCls="x-fa fa-plus" handler={function () {
                Ext.ComponentQuery.query(`#calendarioExpButmin${i}`)[0].show();
                this.hide();
                Ext.ComponentQuery.query(`#calendarioExp${i}`)[0].show();
            }}></Button>
        </Panel>
            <Panel itemId={`calendarioExp${i}`} hidden={false} collapsible={false}>
                <Calendario 
                    dataInizio={calendars[i].daT_INI_VAL}
                    dataFine={calendars[i].daT_FIN_VAL}
                    periodicita={calendars[i].snG_PER}
                    dataAttProvvedimento={calendars[i].daT_ATV_PVV}
                    periodicitaSintetico={calendars[i].snG_GIO_SET}
                    IsNonEffettuabile = {false}
                    isAttivazioneProvvedimento = {true}
                />
            </Panel>
</>)
        return cal;
    }

    function handleCollapse(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovaPeriodicita')[0].setHidden(true);
    };

    function handleExpand(panel, direction, eOpts) {
        if (props.inEdit === true && rtoSchede.data.items[0].data.rto.ideTipVCO !== '3')
            Ext.ComponentQuery.query('#nuovaPeriodicita')[0].setHidden(false);
    };

    function modificaGiorniCircolazione(grid, info) {
        Ext.Ajax.request({
            url: 'Rto/LeggiPercorsoRTO',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                storeLocalita.clearData();
                storeLocalita.removeAll();
                storeLocalita.loadData(returnObj);
                arrayLocalita = [];

                for (let i = 0; i < storeLocalita.data.items.length; i++) {
                    let app = { text: storeLocalita.data.items[i].data.nomLoc, value: storeLocalita.data.items[i].data.prgPrc, ideLoc: storeLocalita.data.items[i].data.ideLoc };
                    arrayLocalita.push(app);
                }

                Ext.ComponentQuery.query('#cmbLocOriPer')[0].setOptions(arrayLocalita);
                Ext.ComponentQuery.query('#cmbLocDsnPer')[0].setOptions(arrayLocalita);
            }
        });
        var dialog = Ext.create({
            xtype: 'dialog',
            title: 'Dettaglio Giorni',
            layout: 'fit',
            closable: "true",
            width: '500px',
            listeners: {
                close: function (dialog) {
                    dialog.destroy();
                    Ext.Ajax.request({
                        url: 'Rto/LeggiPercorsoRTO',
                        method: 'GET',
                        params: {
                            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                        },
                        callback: function (records, success, operation) {
                            var returnObj = Ext.decode(operation.responseText);
                            props.aggiornaStateGiorniCircolazione(returnObj[0].giO_PER);
                        }
                    });
                    aggiornaControllo(true);
                    Ext.Ajax.request({
                        url: 'Rto/CheckPeriodicitaImpegnoImpianto',
                        method: 'POST',
                        params: {
                            ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                        },
                        callback: function (records, success, operation) {
                            var returnCheck = Ext.decode(operation.responseText);
                            if (returnCheck.length > 0) {
                                let stringa = "";
                                for (let i = 0; i < returnCheck.length; i++) {
                                    stringa = stringa + returnCheck[i] + "<br>";
                                }
                                Ext.Msg.alert('Attenzione', stringa);
                                aggiornaStateCheck(false);
                            }
                            else {
                                aggiornaStateCheck(true);
                            }
                        }
                    });
                }
            },
            items: [
                {
                    xtype: 'formpanel',
                    cls: 'PnlSectionL1',
                    ref: form => form = (form),
                    itemId: 'formDettaglioGiorni',
                    items: [
                        {
                            xtype: 'fieldset',
                            defaults: { labelAlign: "left" },
                            items: [
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Sempre",
                                            flex: 1,
                                            checked: info.record.data.flgSmr,
                                            margin: "5 5 5 5",
                                            name: "checkSmr",
                                            itemId: "checkSmr",
                                            listeners: {
                                                change: function (checkbox, newValue, oldValue, eOpts) {
                                                    if (newValue) {
                                                        Ext.ComponentQuery.query('#checkLnd')[0].disable();
                                                        Ext.ComponentQuery.query('#checkMrt')[0].disable();
                                                        Ext.ComponentQuery.query('#checkMrc')[0].disable();
                                                        Ext.ComponentQuery.query('#checkGvd')[0].disable();
                                                        Ext.ComponentQuery.query('#checkVnr')[0].disable();
                                                        Ext.ComponentQuery.query('#checkSbt')[0].disable();
                                                        Ext.ComponentQuery.query('#checkDmn')[0].disable();
                                                        Ext.ComponentQuery.query('#checkLav')[0].disable();
                                                        Ext.ComponentQuery.query('#checkFes')[0].disable();
                                                        Ext.ComponentQuery.query('#checkPtf')[0].disable();
                                                        Ext.ComponentQuery.query('#checkPof')[0].disable();
                                                        Ext.ComponentQuery.query('#checkSegPof')[0].disable();
                                                        Ext.ComponentQuery.query('#checkLnd')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkMrt')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkMrc')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkGvd')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkVnr')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkSbt')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkDmn')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkLav')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkFes')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkPtf')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkPof')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkSegPof')[0].setChecked(false);
                                                    }
                                                    else {
                                                        Ext.ComponentQuery.query('#checkLnd')[0].enable();
                                                        Ext.ComponentQuery.query('#checkMrt')[0].enable();
                                                        Ext.ComponentQuery.query('#checkMrc')[0].enable();
                                                        Ext.ComponentQuery.query('#checkGvd')[0].enable();
                                                        Ext.ComponentQuery.query('#checkVnr')[0].enable();
                                                        Ext.ComponentQuery.query('#checkSbt')[0].enable();
                                                        Ext.ComponentQuery.query('#checkDmn')[0].enable();
                                                        Ext.ComponentQuery.query('#checkLav')[0].enable();
                                                        Ext.ComponentQuery.query('#checkFes')[0].enable();
                                                        Ext.ComponentQuery.query('#checkPtf')[0].enable();
                                                        Ext.ComponentQuery.query('#checkPof')[0].enable();
                                                        Ext.ComponentQuery.query('#checkSegPof')[0].enable();
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Lavorativi",
                                            flex: 1,
                                            checked: info.record.data.flgLav,
                                            margin: "5 5 5 5",
                                            name: "checkLav",
                                            itemId: "checkLav",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Lunedi",
                                            flex: 1,
                                            checked: info.record.data.flgLnd,
                                            margin: "5 5 5 5",
                                            name: "checkLnd",
                                            itemId: "checkLnd",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgFes,
                                            margin: "5 5 5 5",
                                            name: "checkFes",
                                            itemId: "checkFes",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Martedi",
                                            flex: 1,
                                            checked: info.record.data.flgMrt,
                                            margin: "5 5 5 5",
                                            name: "checkMrt",
                                            itemId: "checkMrt",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Prefestivi",
                                            flex: 1,
                                            checked: info.record.data.flgPtf,
                                            margin: "5 5 5 5",
                                            name: "checkPtf",
                                            itemId: "checkPtf",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Mercoledi",
                                            flex: 1,
                                            checked: info.record.data.flgMrc,
                                            margin: "5 5 5 5",
                                            name: "checkMrc",
                                            itemId: "checkMrc",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Post Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgPof,
                                            margin: "5 5 5 5",
                                            name: "checkPof",
                                            itemId: "checkPof",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Giovedi",
                                            flex: 1,
                                            checked: info.record.data.flgGvd,
                                            margin: "5 5 5 5",
                                            name: "checkGvd",
                                            itemId: "checkGvd",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Seguenti Post Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgSegPof,
                                            margin: "5 5 5 5",
                                            name: "checkSegPof",
                                            itemId: "checkSegPof",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Venerdi",
                                            flex: 1,
                                            checked: info.record.data.flgVnr,
                                            margin: "5 5 5 5",
                                            name: "checkVnr",
                                            itemId: "checkVnr",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Sabato",
                                            flex: 1,
                                            checked: info.record.data.flgSbt,
                                            margin: "5 5 5 5",
                                            name: "checkSbt",
                                            itemId: "checkSbt",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Domenica",
                                            flex: 1,
                                            checked: info.record.data.flgDmn,
                                            margin: "5 5 5 5",
                                            name: "checkDmn",
                                            itemId: "checkDmn",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            xtype: 'toolbar',
                            shadow: false,
                            cls: 'PnlSectionL1',
                            docked: 'bottom',
                            layout: { type: 'hbox', pack: 'right' },
                            items: [
                                {
                                    xtype: 'button',
                                    cls: 'appButton',
                                    text: 'RESET',
                                    handler: () =>
                                        Ext.ComponentQuery.query('#formDettaglioGiorni')[0].reset()
                                },
                                {
                                    xtype: 'button',
                                    cls: 'appButton',
                                    text: 'OK',
                                    handler: (btn) => {
                                        const values = btn.up('formpanel').getValues();
                                        if (!((values.checkSmr === false || values.checkSmr === null) && (values.checkLnd === false || values.checkLnd === null) && (values.checkMrt === false || values.checkMrt === null) && (values.checkMrc === false || values.checkMrc === null) && (values.checkGvd === false || values.checkGvd === null) && (values.checkVnr === false || values.checkVnr === null) && (values.checkSbt === false || values.checkSbt === null) && (values.checkDmn === false || values.checkDmn === null) && (values.checkLav === false || values.checkLav === null) && (values.checkFes === false || values.checkFes === null) && (values.checkPtf === false || values.checkPtf === null) && (values.checkPof === false || values.checkPof === null) && (values.checkSegPof === false || values.checkSegPof === null))) {
                                        updateValuesParams(false, "Attendere salvataggio in corso...", params.calendars, false);                                  
                                        info.record.data.flgSmr = values.checkSmr === true ? true : false;
                                        info.record.data.flgLnd = values.checkLnd === true ? true : false;
                                        info.record.data.flgMrt = values.checkMrt === true ? true : false;
                                        info.record.data.flgMrc = values.checkMrc === true ? true : false;
                                        info.record.data.flgGvd = values.checkGvd === true ? true : false;
                                        info.record.data.flgVnr = values.checkVnr === true ? true : false;
                                        info.record.data.flgSbt = values.checkSbt === true ? true : false;
                                        info.record.data.flgDmn = values.checkDmn === true ? true : false;
                                        info.record.data.flgLav = values.checkLav === true ? true : false;
                                        info.record.data.flgFes = values.checkFes === true ? true : false;
                                        info.record.data.flgPtf = values.checkPtf === true ? true : false;
                                        info.record.data.flgPof = values.checkPof === true ? true : false;
                                        info.record.data.flgSegPof = values.checkSegPof === true ? true : false;
                                        var periodicitaObject = info.record.data;
                                        periodicitaObject.datIniPer = new Date(periodicitaObject.datIniPer);
                                        periodicitaObject.datFinPer = new Date(periodicitaObject.datFinPer);
                                        periodicitaObject.datIniPer.setHours(periodicitaObject.datIniPer.getHours() + 4);
                                        periodicitaObject.datFinPer.setHours(periodicitaObject.datFinPer.getHours() + 4);
                                            periodicitaObject.prgPrcPrv = info.record.data.prgPrcPrv;
                                            periodicitaObject.prgPrcDsn = info.record.data.prgPrcDsn;
                                        var periodicitaJson = Ext.encode(periodicitaObject);
                                        
                                            Ext.Ajax.request({
                                                url: 'Rto/ModificaPeriodicita',
                                                method: 'POST',
                                                params: {
                                                    ideUte: user.ideUte,
                                                    ipAddress: "",
                                                    periodicitaDes: periodicitaJson
                                                },
                                                callback: function (records, success, operation) {
                                                    updateValuesParams(false, "Attendere salvataggio in corso...", params.calendars, true);
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp === "KO") {
                                                            Ext.Msg.alert('Modifica periodicita fallita', returnObj.msgErr);
                                                        }
                                                        else {
                                                            dialog.destroy();
                                                            Ext.Ajax.request({
                                                                url: 'Rto/LeggiPercorsoRTO',
                                                                method: 'GET',
                                                                params: {
                                                                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                                },
                                                                callback: function (records, success, operation) {
                                                                    var returnObj = Ext.decode(operation.responseText);
                                                                    props.aggiornaStateGiorniCircolazione(returnObj[0].giO_PER);
                                                                }
                                                            });
                                                            Ext.toast({ message: 'Periodicita modificata', timeout: 4000 });
                                                            aggiornaControllo(true);
                                                            Ext.Ajax.request({
                                                                url: 'Rto/CheckPeriodicitaImpegnoImpianto',
                                                                method: 'POST',
                                                                params: {
                                                                    ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                                                                },
                                                                callback: function (records, success, operation) {
                                                                    var returnCheck = Ext.decode(operation.responseText);
                                                                    if (returnCheck.length > 0) {
                                                                        let stringa = "";
                                                                        for (let i = 0; i < returnCheck.length; i++) {
                                                                            stringa = stringa + returnCheck[i] + "<br>";
                                                                        }
                                                                        Ext.Msg.alert('Attenzione', stringa);
                                                                        aggiornaStateCheck(false);
                                                                    }
                                                                    else {
                                                                        aggiornaStateCheck(true);
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Modifica periodicita fallita', 'Modifica periodicita fallita');
                                                    }

                                                }
                                            });
                                        }
                                        else {
                                            Ext.Msg.alert('Dettaglio Giorni', 'Scegliere almeno un giorno')
                                        }


                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        });

        dialog.show();
    }

    return (
        <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={params.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${params.message}</span></div>`}
                modal={true}
            />
        <Panel
            title="Periodicit&aacute"
            titleCollapse={true}
            iconCls="x-fa fa-calendar"
                collapsible={{ collapsed: statePanelCollapse, direction: 'top' }}
            onCollapse={handleCollapse}
            onExpand={handleExpand}
            cls='PnlSectionL1 iconPanelsRTO'
            tools={[
                {
                    iconCls: 'x-fa x-fa fa-plus', tooltip: 'Nuovo', hidden: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3', itemId:"nuovaPeriodicita", handler: onNuovaPeriodicitaClick.bind(this)
                }
                /* { type: 'menu', handler: toolHandler }*/
            ]}
        >
                <Panel layout="hbox" >
                    <Panel layout="vbox" flex={1} cls="column-in-panel">
                <Grid
                    itemId="gridPeriodicita"
                        minHeight={100}
                        maxHeight={200}
                        flex={1}
                    markDirty={true}
                    scrollable
                    selectable={{ checkbox: false, mode: 'single' }}
                    store={storePeriodicita}
                    plugins={{
                        gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" },
                    }}
                    itemConfig={{
                        viewModel: true
                    }}
                    listeners={{
                        beforeedit: function (editor, context, eOpts) {
                            if (!props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3')
                                return false;
                            Ext.Ajax.request({
                                url: 'Rto/LeggiPercorsoRTO',
                                method: 'GET',
                                params: {
                                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                },
                                callback: function (records, success, operation) {
                                    var returnObj = Ext.decode(operation.responseText);
                                    storeLocalita.clearData();
                                    storeLocalita.removeAll();
                                    storeLocalita.loadData(returnObj);
                                    arrayLocalita = [];

                                    for (let i = 0; i < storeLocalita.data.items.length; i++) {
                                        let app = { text: storeLocalita.data.items[i].data.nomLoc, value: storeLocalita.data.items[i].data.prgPrc, ideLoc: storeLocalita.data.items[i].data.ideLoc };
                                        arrayLocalita.push(app);
                                    } 
                                        Ext.ComponentQuery.query('#cmbLocOriPer')[0].setOptions(arrayLocalita);
                                        Ext.ComponentQuery.query('#cmbLocDsnPer')[0].setOptions(arrayLocalita);
                                }
                            });
                        },
                        edit: function (grid, location) {
                            var periodicitaByDB = null;
                            Ext.Ajax.request({
                                url: 'Rto/LeggiListaPeriodicitaRTO',
                                method: 'GET',
                                params: {
                                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                },
                                callback: function (resp, success, operation) {
                                    const idPeriodicita = location.record.data.idePerRto;
                                    var idPeriodicitaNew = null;
                                    periodicitaByDB = Ext.decode(operation.responseText);
                                    updateValuesParams(false, "Attendere salvataggio in corso...", params.calendars, false);
                                    if (periodicitaByDB.find(per => per.idePerRto === idPeriodicita) == null) {
                                        
                                        var periodicitaNewObject = location.record.data;
                                        periodicitaNewObject.datIniPer.setHours(periodicitaNewObject.datIniPer.getHours() + 4);
                                        periodicitaNewObject.datFinPer.setHours(periodicitaNewObject.datFinPer.getHours() + 4);
                                        periodicitaNewObject.prgPrcPrv = Ext.ComponentQuery.query('#cmbLocOriPer')[0]._selection.data.value;
                                        periodicitaNewObject.prgPrcDsn = Ext.ComponentQuery.query('#cmbLocDsnPer')[0]._selection.data.value;
                                        periodicitaNewObject.ideLocPrv = Ext.ComponentQuery.query('#cmbLocOriPer')[0]._selection.data.ideLoc;
                                        periodicitaNewObject.ideLocDsn = Ext.ComponentQuery.query('#cmbLocDsnPer')[0]._selection.data.ideLoc;
                                        var periodicitaNewJson = Ext.encode(periodicitaNewObject);
                                        Ext.Ajax.request({
                                            url: 'Rto/InserisciPeriodicita',
                                            method: 'POST',
                                            params: {
                                                ideUte: user.ideUte,
                                                ipAddress: "",
                                                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                                                periodicitaDes: periodicitaNewJson
                                            },
                                            callback: function (records, success, operation) {
                                                updateValuesParams(false, "Attendere salvataggio in corso...", params.calendars, true);
                                                if (success) {
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    //modifica gestione errore
                                                    if (returnObj.resp == "KO") {
                                                        Ext.Msg.alert('Inserimento periodicita fallito', returnObj.msgErr)
                                                    }
                                                    else {
                                                        const info = new Object();
                                                        info.record = new Object();
                                                        info.record.data = new Object();
                                                        info.record.data = returnObj.impostazione
                                                        modificaGiorniCircolazione(null, info);
                                                    }
                                                }
                                                else {
                                                    Ext.Msg.alert('Inserimento periodicita fallito', 'Inserimento periodicita fallito');
                                                }
                                            }
                                        })
                                    }
                                    else {

                                        var periodicitaObject = location.record.data;
                                        periodicitaObject.datIniPer.setHours(periodicitaObject.datIniPer.getHours() + 4);
                                        periodicitaObject.datFinPer.setHours(periodicitaObject.datFinPer.getHours() + 4);
                                        periodicitaObject.prgPrcPrv = Ext.ComponentQuery.query('#cmbLocOriPer')[0]._selection.data.value;
                                        periodicitaObject.prgPrcDsn = Ext.ComponentQuery.query('#cmbLocDsnPer')[0]._selection.data.value;
                                        periodicitaObject.ideLocPrv = Ext.ComponentQuery.query('#cmbLocOriPer')[0]._selection.data.ideLoc;
                                        periodicitaObject.ideLocDsn = Ext.ComponentQuery.query('#cmbLocDsnPer')[0]._selection.data.ideLoc;
                                        periodicitaObject.desPerRto = periodicitaObject.desPerRto.toString();
                                        var periodicitaJson = Ext.encode(periodicitaObject);
                                        Ext.Ajax.request({
                                            url: 'Rto/ModificaPeriodicita',
                                            method: 'POST',
                                            params: {
                                                ideUte: user.ideUte,
                                                ipAddress: "",
                                                periodicitaDes: periodicitaJson
                                            },
                                            callback: function (records, success, operation) {
                                                updateValuesParams(false, "Attendere salvataggio in corso...", params.calendars, true);
                                                if (success) {
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    //modifica gestione errore
                                                    if (returnObj.resp === "KO") {
                                                        Ext.Msg.alert('Modifica periodicita fallita', returnObj.msgErr);
                                                        location.record.reject();
                                                    }
                                                    else {
                                                        Ext.toast({ message: 'Periodicita modificata', timeout: 4000 });
                                                        aggiornaControllo(true);
                                                        Ext.Ajax.request({
                                                            url: 'Rto/LeggiPercorsoRTO',
                                                            method: 'GET',
                                                            params: {
                                                                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                            },
                                                            callback: function (records, success, operation) {
                                                                var returnObj = Ext.decode(operation.responseText);
                                                                props.aggiornaStateGiorniCircolazione(returnObj[0].giO_PER);
                                                            }
                                                        });
                                                        Ext.Ajax.request({
                                                            url: 'Rto/CheckPeriodicitaImpegnoImpianto',
                                                            method: 'POST',
                                                            params: {
                                                                ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                                                            },
                                                            callback: function (records, success, operation) {
                                                                var returnCheck = Ext.decode(operation.responseText);
                                                                if (returnCheck.length > 0) {
                                                                    let stringa = "";
                                                                    for (let i = 0; i < returnCheck.length; i++) {
                                                                        stringa = stringa + returnCheck[i] + "<br>";
                                                                    }
                                                                    Ext.Msg.alert('Attenzione', stringa);
                                                                    aggiornaStateCheck(false);
                                                                }
                                                                else {
                                                                    aggiornaStateCheck(true);
                                                                }
                                                            }
                                                        });
                                                    }
                                                }
                                                else {
                                                    Ext.Msg.alert('Modifica periodicita fallita', 'Modifica periodicita fallita');
                                                    location.record.reject();
                                                }

                                            }
                                        });
                                    }
                                }
                            })

                        },
                        canceledit: function (editor, context) {
                            caricaPeriodicita();

                        }

                    }}
                        >
                            <Column itemId='actionRTOPeriodicita' editable={false} text="Azioni" width={100} hidden={!props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3'}
                                cell={{

                                    tools: {

                                        aggiungiPeriodicita: {

                                            handler: onAggiungiPeriodicitaClick.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-plus",

                                                tooltip: 'Aggiungi'
                                            }

                                        },

                                        modificaPeriodicita: {

                                            handler: onModificaPeriodicitaClick.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-edit",

                                                tooltip: 'Modifica'
                                            }

                                        },

                                        eliminaPeriodicita: {

                                            handler: onEliminaPeriodicitaClick.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-trash",

                                                tooltip: 'Elimina'
                                            }

                                        }

                                    }

                                }}
                            />
                    <Column width={200} text="Da" dataIndex="ideLocPrv" renderer={function (value, metaData, records, view) {
                                //return arrayLocalita.find(x => x.value === value) != null ? arrayLocalita.find(x => x.value === value).text + "(" + metaData.data.prgPrcPrv + ")" : "";
                                return metaData.data.desLocPrv;
                    }} editor={{ xtype: 'combobox', triggerAction: 'all',itemId:"cmbLocOriPer", mode: 'local', options: arrayLocalita, allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true }} filter='string' />
                    <Column width={200} text="A" dataIndex="ideLocDsn" renderer={function (value, metaData, records, view) {
                                // return arrayLocalita.find(x => x.value === value) != null ? arrayLocalita.find(x => x.value === value).text + "(" + metaData.data.prgPrcDsn + ")" : "";
                                return metaData.data.desLocDsn;
                    }} editor={{ xtype: 'combobox', triggerAction: 'all', itemId: "cmbLocDsnPer", mode: 'local', options: arrayLocalita, allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true }} filter='string' />
                            <Column text="Operativit&aacute" dataIndex="ideOpv" width={150} filter='string' renderer={function (value, metaData, records, view) {
                        if (value === 0)
                            return "Circola"
                        else
                            return "Soppresso"
                    }} editor={{
                        xtype: 'combobox', editable: true, itemId: 'cmbOpe', triggerAction: 'all', mode: 'local', options:
                            [
                                { text: 'Circola', value: 0 },
                                { text: 'Soppresso', value: 1 }
                            ]
                        ,
                        allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true

                    }} />
                    <Column text="Dal" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datIniPer" editor={{
                        xtype: 'datefield', triggerAction: 'all', itemId:"datIniPeriodicita", allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true, listeners: {
                            change: function (filed, newValue) {
                                if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                    Ext.ComponentQuery.query('#datIniPeriodicita')[0].setValue(null);
                                }
                            }
                        }
                    }}
                    />
                    <Column text="Al" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datFinPer" editor={{
                        xtype: 'datefield', triggerAction: 'all', itemId: "datFinPeriodicita", allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true, listeners: {
                            change: function (filed, newValue) {
                                if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                    Ext.ComponentQuery.query('#datFinPeriodicita')[0].setValue(null);
                                }
                            }
                        }
                    }}
                    />
                            <Column text="Giorni" renderer={function (value, metaData, record, rowIndex, colIndex, store, view) {
                                return metaData.data.desPerRto;
                            }} editable={false} filter='string' flex={1} cell={{

                                tools: {

                                    modificaMerci: {

                                        handler: modificaGiorniCircolazione.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-edit buttonMerciTool",

                                            tooltip: 'Modifica Giorni Circolazione',

                                            hidden: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3'
                                        }
                                    }

                                }

                            }} />

                </Grid>
                    {renderListOfCalendar(params.calendars)}
                    </Panel>
                </Panel>
            </Panel>
        </Container>
    );
}

export default RtoPeriodicita;

