const Ext = window['Ext'];

export default Ext.define('ImpresaFerroviariaModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideCli', type: 'int' },
            { name: 'nomCli', type: 'string' }
        ]
});
