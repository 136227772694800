import React from 'react';
import { Panel, Grid, Column, Loadmask, Container } from '@sencha/ext-react-modern';
import { useState, useContext, useEffect } from "react";
import impostazioneModel from '../models/ImpostazioneModel.js';
import localitaModel from '../models/LocalitaModel.js';
import { useUser } from '../components/UserContext';
import { VersioneRtoContext } from '../components/VersioneRtoContext';

const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

var hiddenMerci = false;

var ideLocAppOri = null;
var ideLocAppDes = null;

let prgOri = 0;
let prgDes = 0;

const storeImpostazione = Ext.create('Ext.data.Store', {
    model: impostazioneModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});




function RtoImpostazione(props) {
    let arrayAnaLocalita = [];
    const storeLocalita = Ext.create('Ext.data.Store', {
        model: localitaModel,
        proxy: {
            type: 'localstorage',
        },
        autoload: false
    });
    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede; 
    function aggiornaControllo(stato) {
        props.aggiornaStateControllo(stato);
    }
    ideLocAppOri = rtoSchede.data.items[0].data.percorsoRTO.find(x => x.idePas === 1).prgPrc;
    ideLocAppDes = rtoSchede.data.items[0].data.percorsoRTO.find(x => x.idePas === 2).prgPrc;
    if (rtoSchede.data.items[0].data.rto.ideTipTra === 1 || rtoSchede.data.items[0].data.rto.ideTipTra === 2)
        hiddenMerci = true;
    else {
        hiddenMerci = false;
    }
    const { user, switchUser } = useUser();
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere salvataggio in corso..." });
    const [statePanelCollapse, setStatePanelCollapse] = useState(props.collapse);

    useEffect(() => {
        setStatePanelCollapse(props.collapse);
    }, [props.collapse]);


    const caricaImpostazione = () => {
        Ext.Ajax.request({
            url: 'Rto/LeggiImpostazioniRTO',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                caricaLocalita();
                var returnObj = Ext.decode(operation.responseText);
                storeImpostazione.loadData(returnObj);
                if (storeImpostazione.data.length === 0) {
                    var record = Ext.create(impostazioneModel, {});
                    record.data.codImp = "";
                    storeImpostazione.insert(0, record);
                }
            }
        });
    }


/*    useEffect(() => {*/
        caricaImpostazione();
/*    }, [rtoSchede.data.items[0].data.rto.ideRTO, props.modificheTabImpostazione]); */

    //carico le anagrafiche dei codici impostazioni


    let arrayAnaImpostazione = [];
    let noImp = { text: "Nessuna selezione", value: null }
    arrayAnaImpostazione.push(noImp);
    for (let i = 0; i < contextVersioneRto.storeAnagraficaImpostazione.data.items.length; i++) {
        let data = contextVersioneRto.storeAnagraficaImpostazione.data.items[i].data;
        let descrImp = data.flagSTI == 1 ? data.codImp.toString() + "   (STI)" : data.codImp.toString();
        let app = { text: descrImp, value: contextVersioneRto.storeAnagraficaImpostazione.data.items[i].data.codImp.toString() };
        arrayAnaImpostazione.push(app);
    }

    //carico le anagrafiche dei locomotori

    let arrayAnaLocomotori = [];
    for (let i = 0; i < contextVersioneRto.storeAnagraficaLocomotori.data.items.length; i++) {
        let app = { text: contextVersioneRto.storeAnagraficaLocomotori.data.items[i].data.desLcm, value: contextVersioneRto.storeAnagraficaLocomotori.data.items[i].data.ideLcm };
        arrayAnaLocomotori.push(app); 
    }

    //carico le anagrafiche dei tipi freno

    let arrayAnaTipiFreno = [];
    for (let i = 0; i < contextVersioneRto.storeAnagraficaTipiFreno.data.items.length; i++) {
        let app = { text: contextVersioneRto.storeAnagraficaTipiFreno.data.items[i].data.sigTipFre, value: contextVersioneRto.storeAnagraficaTipiFreno.data.items[i].data.ideTipFre };
        arrayAnaTipiFreno.push(app);
    }




    const caricaLocalita = () => {
        arrayAnaLocalita = [];
        Ext.Ajax.request({
            url: 'Rto/LeggiPercorsoRTO',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                storeLocalita.clearData();
                storeLocalita.removeAll();
                storeLocalita.loadData(returnObj);
                for (let i = 0; i < storeLocalita.data.items.length; i++) {
                    let app = { text: storeLocalita.data.items[i].data.nomLoc, value: storeLocalita.data.items[i].data.prgPrc, ideLoc: storeLocalita.data.items[i].data.ideLoc };
                    arrayAnaLocalita.push(app);
                }
            }
        })
    }




    function onAggiungiImpClick(grid, info) {
        var store = grid.getStore();
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing == false) {
            var record = Ext.create(impostazioneModel, {});   
            record.data.codImp = "";
            record.data.ideLocPrv = ideLocAppOri;
            record.data.ideLocDsn = ideLocAppDes;
            record.data.prgPrcPrv = prgOri;
            record.data.prgPrcDsn = prgDes;
            store.insert(info.cell.row._recordIndex + 1, record);
            rowediting.startEdit(record, 3);
        }
    }

    function dettaglioMerci(grid, info) {
        if (info.record.data.codImp !== "") {
            var dialog = Ext.create({
                width: '1000',
                itemId: 'dialogDettaglioMerci',
                xtype: 'dialog',
                title: 'Dettaglio Merci',
                layout: 'fit',
                closable: "true",
                items: [
                    {
                        xtype: 'formpanel',
                        cls: 'PnlSectionL1',
                        ref: form => form = (form),
                        itemId: 'formDettaglioMerci',
                        items: [
                            {
                                xtype: 'fieldset',
                                defaults: { labelAlign: "left" },
                                items: [
                                    {
                                        xtype: 'panel',
                                        layout: "hbox",
                                        margin: "5 5 5 5",
                                        defaults: { labelAlign: "left" },
                                        items: [
                                            {
                                                xtype: 'selectfield',
                                                label: "\u00A0Massa Assiale",
                                                labelWidth: "36.5%",
                                                labelCls: "x-fa fa-balance-scale-right",
                                                store: contextVersioneRto.storeAnagraficaPesiAss,
                                                displayField: 'desPesAsl',
                                                valueField: 'idePesAsl',
                                                value: info.record.data.idePesAsl,
                                                flex: 1,
                                                margin: "5 5 5 5",
                                                name: "comboPesoAssiale",
                                                readOnly: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3'
                                            }
                                        ]
                                    },
                                    {
                                        xtype: 'panel',
                                        layout: "hbox",
                                        margin: "5 5 5 5",
                                        defaults: { labelAlign: "left" },
                                        items: [
                                            {
                                                xtype: 'checkboxfield',
                                                labelWidth: "90%",
                                                label: "\u00A0\u00A0\u00A0\u00A0\u00A0Trasp. Eccezionale",
                                                flex: 1,
                                                checked: info.record.data.flagTE,
                                                margin: "5 5 5 5",
                                                disabled: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                name: "checkTE",
                                                listeners: {
                                                    change: function (checkbox, newValue, oldValue, eOpts) {
                                                        if (newValue) {
                                                            Ext.ComponentQuery.query('#noteTe')[0].setEditable(true);
                                                        } else {
                                                            Ext.ComponentQuery.query('#noteTe')[0].setEditable(false);
                                                            Ext.ComponentQuery.query('#noteTe')[0].setValue(null);
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                xtype: 'textfield',
                                                flex: 3,
                                                itemId: 'noteTe',
                                                readOnly: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                value: info.record.data.noteTE,
                                                margin: "5 5 5 5",
                                                label: "Note TE",
                                                name: "textNoteTE",
                                                editable: false
                                            }
                                        ]
                                    },
                                    {
                                        xtype: 'panel',
                                        layout: "hbox",
                                        margin: "5 5 5 5",
                                        defaults: { labelAlign: "left" },
                                        items: [
                                            {
                                                xtype: 'checkboxfield',
                                                label: "  Trasp. Eccedente Sagoma",
                                                flex: 1,
                                                disabled: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                labelCls: "x-fa fa-truck",
                                                labelWidth: "90%",
                                                checked: info.record.data.flagTES,
                                                margin: "5 5 5 5",
                                                name: "checkTES",
                                                listeners: {
                                                    change: function (checkbox, newValue, oldValue, eOpts) {
                                                        if (newValue) {
                                                            Ext.ComponentQuery.query('#noteTES')[0].setEditable(true);
                                                        } else {
                                                            Ext.ComponentQuery.query('#noteTES')[0].setEditable(false);
                                                            Ext.ComponentQuery.query('#noteTES')[0].setValue(null);
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                xtype: 'textfield',
                                                flex: 3,
                                                value: info.record.data.noteTES,
                                                margin: "5 5 5 5",
                                                itemId: 'noteTES',
                                                readOnly: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                label: "Note TES",
                                                name: "textNoteTES",
                                                editable: false
                                            }
                                        ]
                                    },
                                    {
                                        xtype: 'panel',
                                        layout: "hbox",
                                        margin: "5 5 5 5",
                                        defaults: { labelAlign: "left" },
                                        items: [
                                            {
                                                xtype: 'checkboxfield',
                                                labelWidth: "90%",
                                                label: "\u00A0\u00A0\u00A0\u00A0\u00A0Trasp. Eccedente Massa",
                                                checked: info.record.data.flagTEP,
                                                flex: 1,
                                                margin: "5 5 5 5",
                                                name: "checkTEP",
                                                disabled: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                listeners: {
                                                    change: function (checkbox, newValue, oldValue, eOpts) {
                                                        if (newValue) {
                                                            Ext.ComponentQuery.query('#noteTEP')[0].setEditable(true);
                                                        } else {
                                                            Ext.ComponentQuery.query('#noteTEP')[0].setEditable(false);
                                                            Ext.ComponentQuery.query('#noteTEP')[0].setValue(null);
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                xtype: 'textfield',
                                                flex: 3,
                                                value: info.record.data.noteTEP,
                                                margin: "5 5 5 5",
                                                readOnly: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                label: "Note TEM",
                                                itemId: "noteTEP",
                                                name: "textNoteTEP",
                                                editable: false
                                            }
                                        ]
                                    },
                                    {
                                        xtype: 'panel',
                                        layout: "hbox",
                                        margin: "5 5 5 5",
                                        defaults: { labelAlign: "left" },
                                        items: [
                                            {
                                                xtype: 'checkboxfield',
                                                labelWidth: "90%",
                                                label: "\u00A0\u00A0Merci Pericolose",
                                                disabled: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                checked: info.record.data.flagMerPcl,
                                                labelCls: "x-fa fa-exclamation-triangle",
                                                flex: 1,
                                                margin: "5 5 5 5",
                                                name: "checkMP",
                                                listeners: {
                                                    change: function (checkbox, newValue, oldValue, eOpts) {
                                                        if (newValue) {
                                                            Ext.ComponentQuery.query('#noteMP')[0].setEditable(true);
                                                        } else {
                                                            Ext.ComponentQuery.query('#noteMP')[0].setEditable(false);
                                                            Ext.ComponentQuery.query('#noteMP')[0].setValue(null);
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                xtype: 'textfield',
                                                flex: 3,
                                                margin: "5 5 5 5",
                                                readOnly: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                value: info.record.data.notMerPcl,
                                                label: "Note MP",
                                                name: "textNoteMP",
                                                itemId: "noteMP",
                                                editable: false
                                            }
                                        ]
                                    },
                                    {
                                        xtype: 'panel',
                                        layout: "hbox",
                                        margin: "5 5 5 5",
                                        defaults: { labelAlign: "left" },
                                        items: [
                                            {
                                                xtype: 'selectfield',
                                                label: "\u00A0\u00A0Cod. Sagoma",
                                                labelWidth: "40%",
                                                labelCls: "x-fa fa-draw-polygon",
                                                flex: 1,
                                                readOnly: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                margin: "5 5 5 5",
                                                value: info.record.data.ideSagoma1,
                                                store: contextVersioneRto.storeCodificheSagome,
                                                displayField: 'sigCodSag',
                                                valueField: 'ideCodSag',
                                                name: "comboSagoma1",
                                                listeners: {
                                                    change: function (combo, value) {
                                                        if (value != null)
                                                            Ext.ComponentQuery.query('#noteSAG1')[0].setEditable(true);
                                                        else {
                                                            Ext.ComponentQuery.query('#noteSAG1')[0].setEditable(false);
                                                            Ext.ComponentQuery.query('#noteSAG1')[0].setValue(null);
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                xtype: 'textfield',
                                                flex: 1,
                                                margin: "5 5 5 5",
                                                value: info.record.data.noteSagoma1,
                                                label: "Note Sagoma",
                                                readOnly: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                name: "textNoteSagoma1",
                                                itemId: "noteSAG1",
                                                editable: false
                                            }
                                        ]
                                    },
                                    {
                                        xtype: 'panel',
                                        layout: "hbox",
                                        margin: "5 5 5 5",
                                        defaults: { labelAlign: "left" },
                                        items: [
                                            {
                                                xtype: 'selectfield',
                                                label: "\u00A0\u00A0Cod. Sagoma",
                                                labelWidth: "40%",
                                                labelCls: "x-fa fa-draw-polygon",
                                                flex: 1,
                                                value: info.record.data.ideSagoma2,
                                                margin: "5 5 5 5",
                                                readOnly: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                store: contextVersioneRto.storeCodificheSagome,
                                                displayField: 'sigCodSag',
                                                valueField: 'ideCodSag',
                                                name: "comboSagoma2",
                                                listeners: {
                                                    change: function (combo, value) {
                                                        if (value != null)
                                                            Ext.ComponentQuery.query('#noteSAG2')[0].setEditable(true);
                                                        else {
                                                            Ext.ComponentQuery.query('#noteSAG2')[0].setEditable(false);
                                                            Ext.ComponentQuery.query('#noteSAG2')[0].setValue(null);
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                xtype: 'textfield',
                                                flex: 1,
                                                margin: "5 5 5 5",
                                                readOnly: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                                itemId: "noteSAG2",
                                                value: info.record.data.noteSagoma2,
                                                label: "Note Sagoma",
                                                name: "textNoteSagoma2",
                                                editable: false
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                xtype: 'toolbar',
                                shadow: false,
                                cls: 'PnlSectionL1',
                                docked: 'bottom',
                                layout: { type: 'hbox', pack: 'right' },
                                items: [
                                    {
                                        xtype: 'button',
                                        cls: 'appButton',
                                        text: 'RESET',
                                        hidden: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                        handler: () =>
                                            Ext.ComponentQuery.query('#formDettaglioMerci')[0].reset()
                                    },
                                    {
                                        xtype: 'button',
                                        cls: 'appButton',
                                        text: 'OK',
                                        hidden: !props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3',
                                        handler: (btn) => {
                                            const values = btn.up('formpanel').getValues();
                                            if (values.comboSagoma1 !== values.comboSagoma2 || (values.comboSagoma1 == null && values.comboSagoma2 == null)) {
                                                if (!((info.record.data.flagMerPcl === (values.checkMP === null ? false : values.checkMP) || (info.record.data.flagMerPcl === values.checkMP)) && info.record.data.flagTE === (values.checkTE === null ? false : values.checkTE) && info.record.data.flagTEP === (values.checkTEP === null ? false : values.checkTEP) &&
                                                    info.record.data.flagTES === (values.checkTES === null ? false : values.checkTES) && info.record.data.idePesAsl === values.comboPesoAssiale && info.record.data.ideSagoma1 === values.comboSagoma1 &&
                                                    info.record.data.ideSagoma2 === values.comboSagoma2 && info.record.data.notMerPcl === values.textNoteMP &&
                                                    info.record.data.noteSagoma1 === values.textNoteSagoma1 && info.record.data.noteSagoma2 === values.textNoteSagoma2 &&
                                                    info.record.data.noteTE === values.textNoteTE && info.record.data.noteTEP === values.textNoteTEP &&
                                                    info.record.data.noteTES === values.textNoteTES)) {
                                                    info.record.data.flagMerPcl = values.checkMP;
                                                    info.record.data.flagTE = values.checkTE;
                                                    info.record.data.flagTEP = values.checkTEP;
                                                    info.record.data.flagTES = values.checkTES;
                                                    info.record.data.idePesAsl = values.comboPesoAssiale;
                                                    info.record.data.ideSagoma1 = values.comboSagoma1;
                                                    info.record.data.ideSagoma2 = values.comboSagoma2;
                                                    info.record.data.notMerPcl = values.textNoteMP;
                                                    info.record.data.noteSagoma1 = values.textNoteSagoma1;
                                                    info.record.data.noteSagoma2 = values.textNoteSagoma2;
                                                    info.record.data.noteTE = values.textNoteTE;
                                                    info.record.data.noteTEP = values.textNoteTEP;
                                                    info.record.data.noteTES = values.textNoteTES;
                                                    if (values.comboSagoma1 != null || values.comboSagoma2 != null)
                                                        info.record.data.flagSagoma = true;
                                                    else
                                                        info.record.data.flagSagoma = false;
                                                    var impostazioneObject = info.record.data;
                                                    var impostazioneJson = Ext.encode(impostazioneObject);
                                                    setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                                                    Ext.Ajax.request({
                                                        url: 'Rto/ModificaImpostazioneRTO',
                                                        method: 'POST',
                                                        params: {
                                                            ideUte: user.ideUte,
                                                            ipAddress: "",
                                                            impostazioneDes: impostazioneJson
                                                        },
                                                        callback: function (records, success, operation) {
                                                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                                            if (success) {
                                                                var returnObj = Ext.decode(operation.responseText);
                                                                //modifica gestione errore
                                                                if (returnObj.resp === "KO") {
                                                                    Ext.Msg.alert('Modifica impostazione fallita', returnObj.msgErr);
                                                                }
                                                                else {
                                                                    dialog.destroy();
                                                                    Ext.toast({ message: 'Impostazione modificata', timeout: 4000 });
                                                                    aggiornaControllo(true);
                                                                    props.aggiornaStateModifica(true);
                                                                }
                                                            }
                                                            else {
                                                                Ext.Msg.alert('Modifica impostazione fallita', 'Modifica impostazione fallita');
                                                            }

                                                        }
                                                    });
                                                }
                                                else {
                                                    Ext.ComponentQuery.query('#dialogDettaglioMerci')[0].destroy();
                                                }

                                            }
                                            else {
                                                Ext.toast({ message: 'Impossibile inserire due codici sagoma uguali', timeout: 4000 });
                                            }
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            });

            dialog.show();
        }
        else {
            Ext.Msg.alert('Dettaglio Merci', 'Inserire prima una specifica di Impostazione')
        }
    }

    function onModificaImpClick(grid, info) {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 3);
            Ext.ComponentQuery.query('#cmbLocOriImp')[0].setValue(info.record.data.prgPrcPrv);
            Ext.ComponentQuery.query('#cmbLocDsnImp')[0].setValue(info.record.data.prgPrcDsn);
        }
    }

    function onEliminaImpClick(grid, info) {
        if (Ext.ComponentQuery.query('#gridImpostazione')[0].getStore().getRange().length > 1) {
            Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione dell'impostazione?", function (button) {

                if (button === 'yes') {
                    setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                    var impostazioniByDB = null;
                    Ext.Ajax.request({
                        url: 'Rto/LeggiImpostazioniRTO',
                        method: 'GET',
                        params: {
                            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                        },
                        callback: function (resp, success, operation) {
                            impostazioniByDB = Ext.decode(operation.responseText);
                            if (impostazioniByDB.find(imp => imp.ideImpRTO === info.record.data.ideImpRTO) != null) {
                                Ext.Ajax.request({
                                    url: 'Rto/EliminaImpostazioneRTO',
                                    method: 'POST',
                                    params: {
                                        ideUte: user.ideUte,
                                        ipAddress: "",
                                        ideImpRTO: info.record.data.ideImpRTO
                                    },
                                    callback: function (records, success, operation) {
                                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                        if (success) {
                                            var returnObj = Ext.decode(operation.responseText);
                                            //modifica gestione errore
                                            if (returnObj.resp == "KO") {
                                                Ext.Msg.alert('Elimina impostazione fallito', returnObj.msgErr)
                                            }
                                            else {
                                                Ext.toast({ message: 'Impostazione eliminata', timeout: 4000 });
                                                aggiornaControllo(true);
                                                props.aggiornaStateModifica(true);
                                            }
                                        }
                                        else {
                                            Ext.Msg.alert('Elimina impostazione fallito', 'Elimina impostazione fallito');
                                        }
                                    }
                                });
                            }
                            else {
                                Ext.ComponentQuery.query('#gridImpostazione')[0].getStore().remove(info.record);
                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                            }
                        }
                    })

                }
            })
        }
        else {
            Ext.toast({ message: 'Impossibile eliminare', timeout: 4000 });
        }
    }

    //function refreshGridImpostazione() {
    //    Ext.Ajax.request({
    //        url: 'Rto/LeggiImpostazioniRTO',
    //        method: 'GET',
    //        params: {
    //            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
    //        },
    //        callback: function (records, success, operation) {
    //            var returnObj = Ext.decode(operation.responseText);
    //            storeImpostazione.loadData(returnObj);
    //            if (storeImpostazione.data.length === 0) {
    //                var record = Ext.create(impostazioneModel, {});
    //                record.data.codImp = "";
    //                storeImpostazione.insert(0, record);
    //            }
    //        }
    //    });
    //}


    return (
        <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
        <Panel
            title="Impostazione"
            scrollable
            bodyPadding={5}
            titleCollapse={true}
            iconCls="x-fa fa-tachometer-alt"
            collapsible={{ collapsed: statePanelCollapse, direction: 'top' }}
            cls='PnlSectionL1 iconPanelsRTO'
        >
            <Panel layout="hbox" >
                <Panel layout="vbox" flex={1} cls="column-in-panel">
                    <Grid
                        itemId="gridImpostazione"
                        minHeight={100}
                        maxHeight={200}
                        markDirty={true}
                        flex={1}
                        scrollable
                        selectable={{ checkbox: false, mode: 'single' }}
                        store={storeImpostazione}
                        plugins={{
                            gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" },
                        }}
                        itemConfig={{
                            viewModel: true
                        }}
                            listeners={{
                            beforeedit: function (editor, context, eOpts) {
                                    if (!props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3' || context.source.type === 'doubletap')
                                    return false;
                                Ext.ComponentQuery.query('#gridImpostazione')[0].setMinHeight(Ext.ComponentQuery.query('#gridImpostazione')[0]._minHeight + 50);
                                Ext.Ajax.request({
                                    url: 'Rto/LeggiPercorsoRTO',
                                    method: 'GET',
                                    params: {
                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                    },
                                    callback: function (records, success, operation) {
                                        var returnObj = Ext.decode(operation.responseText);
                                        storeLocalita.clearData();
                                        storeLocalita.removeAll();
                                        storeLocalita.loadData(returnObj);
                                        arrayAnaLocalita = [];

                                        for (let i = 0; i < storeLocalita.data.items.length; i++) {
                                            let app = { text: storeLocalita.data.items[i].data.nomLoc, value: storeLocalita.data.items[i].data.prgPrc, ideLoc: storeLocalita.data.items[i].data.ideLoc };
                                            arrayAnaLocalita.push(app);
                                        }
                                        

                                            Ext.ComponentQuery.query('#cmbLocOriImp')[0].setOptions(arrayAnaLocalita);
                                            Ext.ComponentQuery.query('#cmbLocDsnImp')[0].setOptions(arrayAnaLocalita);
                                        

                                    }
                                });
                            },
                            edit: function (grid, location) {
                                Ext.ComponentQuery.query('#gridImpostazione')[0].setMinHeight(Ext.ComponentQuery.query('#gridImpostazione')[0]._minHeight - 50);
                                setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                                var impostazioniByDB = null;
                                Ext.Ajax.request({
                                    url: 'Rto/LeggiImpostazioniRTO',
                                    method: 'GET',
                                    params: {
                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                    },
                                    callback: function (resp, success, operation) {
                                        const idImpostazione = location.record.data.ideImpRTO;
                                        impostazioniByDB = Ext.decode(operation.responseText);

                                        if (impostazioniByDB.find(imp => imp.ideImpRTO === idImpostazione) == null) {
                                            location.record.data.ideTipMatRmc = contextVersioneRto.storeAnagraficaTipiMateriale.findRecord("sigTipMatRmc", location.record.data.sigTipMatRmc, 0, false, false, true).data.ideTipMatRmc;
                                            var impostazioneNewObject = location.record.data;
                                            impostazioneNewObject.prgPrcDsn = Ext.ComponentQuery.query('#cmbLocDsnImp')[0]._selection.data.value;
                                            impostazioneNewObject.prgPrcPrv = Ext.ComponentQuery.query('#cmbLocOriImp')[0]._selection.data.value;
                                            impostazioneNewObject.ideLocPrv = Ext.ComponentQuery.query('#cmbLocOriImp')[0]._selection.data.ideLoc;
                                            impostazioneNewObject.ideLocDsn = Ext.ComponentQuery.query('#cmbLocDsnImp')[0]._selection.data.ideLoc;
                                            var impostazioneNewJson = Ext.encode(impostazioneNewObject);
                                            Ext.Ajax.request({
                                                url: 'Rto/InserisciImpostazioneRTO',
                                                method: 'POST',
                                                params: {
                                                    ideUte: user.ideUte,
                                                    ipAddress: "",
                                                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                                                    impostazioneDes: impostazioneNewJson
                                                },
                                                callback: function (records, success, operation) {
                                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp == "KO") {
                                                            Ext.Msg.alert('Inserimento impostazione fallito', returnObj.msgErr)
                                                        }
                                                        else {
                                                            Ext.toast({ message: 'Impostazione creata', timeout: 4000 });
                                                            aggiornaControllo(true);
                                                            props.aggiornaStateModifica(true);
                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Inserimento impostazione fallito', 'Inserimento impostazione fallito');
                                                    }
                                                }
                                            })
                                        }
                                        else {
                                            location.record.data.ideTipMatRmc = contextVersioneRto.storeAnagraficaTipiMateriale.findRecord("sigTipMatRmc", location.record.data.sigTipMatRmc, 0, false, false, true).data.ideTipMatRmc;

                                            var impostazioneObject = location.record.data;
                                            impostazioneObject.prgPrcDsn = Ext.ComponentQuery.query('#cmbLocDsnImp')[0]._selection.data.value;
                                            impostazioneObject.prgPrcPrv = Ext.ComponentQuery.query('#cmbLocOriImp')[0]._selection.data.value;
                                            impostazioneObject.ideLocPrv = Ext.ComponentQuery.query('#cmbLocOriImp')[0]._selection.data.ideLoc;
                                            impostazioneObject.ideLocDsn = Ext.ComponentQuery.query('#cmbLocDsnImp')[0]._selection.data.ideLoc;
                                            var impostazioneJson = Ext.encode(impostazioneObject);
                                            Ext.Ajax.request({
                                                url: 'Rto/ModificaImpostazioneRTO',
                                                method: 'POST',
                                                params: {
                                                    ideUte: user.ideUte,
                                                    ipAddress: "",
                                                    impostazioneDes: impostazioneJson
                                                },
                                                callback: function (records, success, operation) {
                                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp === "KO") {
                                                            Ext.Msg.alert('Modifica impostazione fallita', returnObj.msgErr);
                                                            location.record.reject();
                                                        }
                                                        else {
                                                            Ext.toast({ message: 'Impostazione modificata', timeout: 4000 });
                                                            aggiornaControllo(true);
                                                            props.aggiornaStateModifica(true);
                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Modifica impostazione fallita', 'Modifica impostazione fallita');
                                                        location.record.reject();
                                                    }

                                                }
                                            });
                                        }
                                    }
                                })

                            },
                            canceledit: function (editor, context) {
                                caricaImpostazione();
                            }
                            
                        }}

                        >
                            <Column itemId='actionImpostazioni' editable={false} text="Azioni" autoSizeColumn="true" flex={1} hidden={!props.inEdit || rtoSchede.data.items[0].data.rto.ideTipVCO === '3'}
                                cell={{

                                    tools: {

                                        aggiungiIMpostazione: {

                                            handler: onAggiungiImpClick.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-plus",

                                                tooltip: 'Aggiungi'
                                            }

                                        },

                                        modificaIMpostazione: {

                                            handler: onModificaImpClick.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-edit",

                                                tooltip: 'Modifica'
                                            }

                                        },

                                        eliminaImpostazione: {

                                            handler: onEliminaImpClick.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-trash",

                                                tooltip: 'Elimina'
                                            }

                                        }



                                    }

                                }}

                            />
                        <Column text="Da" dataIndex="ideLocPrv" renderer={function (value, metaData, records, view) {
                                //return storeLocalita.findRecord("ideLoc", value, 0, false, false, true) != null ? storeLocalita.findRecord("ideLoc", value, 0, false, false, true).data.nomLoc + "(" + metaData.data.prgPrcPrv + ")" : "";
                                return metaData.data.nomLocPrv;
                        }} editor={{
                            xtype: 'combobox',
                            itemId: "cmbLocOriImp",
                            triggerAction: 'all',
                            mode: 'local',
                            options: arrayAnaLocalita,
                            allowBlank: false,
                            requiredMessage: "Questo campo non puo' essere vuoto",
                            required: true
                        }} filter='string' flex={1} />
                        <Column text="A" dataIndex="ideLocDsn" renderer={function (value, metaData, records, view) {
                                //return storeLocalita.findRecord("ideLoc", value, 0, false, false, true) != null ? storeLocalita.findRecord("ideLoc", value, 0, false, false, true).data.nomLoc + "(" + metaData.data.prgPrcDsn + ")" : "";
                                return metaData.data.nomLocDsn;
                        }} editor={{
                            xtype: 'combobox', triggerAction: 'all', itemId: "cmbLocDsnImp", mode: 'local', options: arrayAnaLocalita, allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true
                        }} filter='string' flex={1} />
                        <Column text="Cod.Imp." dataIndex="codImp" editor={{
                            xtype: 'combobox', options: arrayAnaImpostazione,
                            listeners:
                            {
                                change: function (gridView, value, columnIndex) {
                                    if (value === "") {
                                        Ext.ComponentQuery.query('#cmbLocomotore')[0].setValue("");
                                        Ext.ComponentQuery.query('#cmbLocomotore')[0].enable();         
                                        Ext.ComponentQuery.query('#cmbTipoFreno')[0].setValue("");
                                        Ext.ComponentQuery.query('#cmbTipoFreno')[0].enable();
                                        Ext.ComponentQuery.query('#txtTipoMateriale')[0].setValue("");
                                        Ext.ComponentQuery.query('#nmbPesoRimorchiato')[0].setValue("");
                                        Ext.ComponentQuery.query('#nmbPesoRimorchiato')[0].enable();
                                        Ext.ComponentQuery.query('#nmbPesoFrenato')[0].setValue("");
                                        Ext.ComponentQuery.query('#nmbPesoFrenato')[0].enable();
                                        Ext.ComponentQuery.query('#nmbVelocitaMax')[0].setValue("");
                                        Ext.ComponentQuery.query('#nmbVelocitaMax')[0].enable();
                                    }
                                    else {
                                        Ext.ComponentQuery.query('#cmbLocomotore')[0].disable();
                                        Ext.ComponentQuery.query('#cmbTipoFreno')[0].disable();
                                        Ext.ComponentQuery.query('#nmbPesoRimorchiato')[0].disable();
                                        Ext.ComponentQuery.query('#nmbPesoFrenato')[0].disable();
                                        Ext.ComponentQuery.query('#nmbVelocitaMax')[0].disable();
                                        let impostazioneApp = contextVersioneRto.storeAnagraficaImpostazione.findRecord('codImp', value, 0, false, false, true);
                                        Ext.ComponentQuery.query('#cmbLocomotore')[0].setValue(impostazioneApp.data.idLocomotore);
                                        Ext.ComponentQuery.query('#cmbTipoFreno')[0].setValue(impostazioneApp.data.idTipoFreno);
                                        Ext.ComponentQuery.query('#txtTipoMateriale')[0].setValue(impostazioneApp.data.siglaTipoMatRimorchiato);
                                        Ext.ComponentQuery.query('#nmbPesoRimorchiato')[0].setValue(impostazioneApp.data.pesoRimorchiato);
                                        Ext.ComponentQuery.query('#nmbPesoFrenato')[0].setValue(impostazioneApp.data.percentualePesoFrenata);
                                        Ext.ComponentQuery.query('#nmbVelocitaMax')[0].setValue(impostazioneApp.data.velocitaMaxTreno);
                                    }
                                }
                            }
                        }}
                        flex={1} />
                        <Column text="Locomotore" dataIndex="ideLcm" renderer={function (value, metaData, records, view) {
                            return contextVersioneRto.storeAnagraficaLocomotori.findRecord("ideLcm", value, 0, false, false, true) != null ? contextVersioneRto.storeAnagraficaLocomotori.findRecord("ideLcm", value, 0, false, false, true).data.desLcm : "";
                        }} editor={{
                            xtype: 'combobox', itemId: 'cmbLocomotore', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true, mode: 'local', options: arrayAnaLocomotori, listeners:
                            {
                                change: function (gridView, value, columnIndex) {
                                    Ext.ComponentQuery.query('#txtTipoMateriale')[0].setValue(contextVersioneRto.storeAnagraficaLocomotori.findRecord('ideLcm', value, 0, false, false, true) != null ? contextVersioneRto.storeAnagraficaLocomotori.findRecord('ideLcm', value, 0, false, false, true).data.sigTipMatRmc : null);
                                }
                            }
                        }} flex={1} />
                        <Column text="Tipo Freno" renderer={function (value, metaData, records, view) {
                                return contextVersioneRto.storeAnagraficaTipiFreno.findRecord("ideTipFre", value, 0, false, false, true) != null ? contextVersioneRto.storeAnagraficaTipiFreno.findRecord("ideTipFre", value, 0, false, false, true).data.sigTipFre : "";
                        }} editor={{
                            xtype: 'combobox', itemId: 'cmbTipoFreno', triggerAction: 'all', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true, mode: 'local', options: arrayAnaTipiFreno
                        }}
                            dataIndex="ideTipFre" filter='string' flex={1} />
                            <Column text="Tipo Materiale" editor={{ xtype: 'textfield', readOnly: "true", itemId: 'txtTipoMateriale' }} dataIndex="sigTipMatRmc" filter='string' flex={1} />
                            <Column text="Conforme STI" dataIndex="conformeSTI" filter='string' flex={1} />
                            <Column text="Massa Rimorchiata" dataIndex="pesRmc" editor={{ xtype: 'numberfield', itemId: 'nmbPesoRimorchiato', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true }} filter='string' flex={1} />
                        <Column text="Perc. Massa Frenata" editor={{ xtype: 'numberfield', itemId: 'nmbPesoFrenato', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true }} dataIndex="pctPesFre" filter='string' flex={1} />
                            <Column text="Velocità Massima" editor={{ xtype: 'numberfield', itemId: 'nmbVelocitaMax', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true, maxValue: 999 }} dataIndex="velMaxTre" filter='string' flex={1} />
                            <Column text="Lunghezza Treno" editor={{ xtype: 'numberfield', iconCls: "x-fa fa-truck", allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true, maxValue: 5000 }} dataIndex="lunTre" filter='string' flex={1} />
                            <Column text="Note comp." editor={{ xtype: 'textfield', itemId: 'txtNotaComposizione', maxLength: 250 }} dataIndex="notCmp" filter='string' flex={1} tdCls="wrap-text" />
                        <Column text="Dettaglio Merci" filter='string' flex={1} hidden={ hiddenMerci}
                                cell={{

                                    tools: {
                                        pesoAssiale: {

                                            bind: {

                                                iconCls: "x-fa fa-balance-scale-right",

                                                hidden: '{!((record.idePesAsl) != null)}',

                                                passive: true,

                                                tooltip: 'Massa Assiale'
                                            }

                                        },
                                        trasportoEccezionale: {

                                            bind: {

                                                iconCls: "x-fa fa-truck",

                                                hidden: '{!((record.flagTE) == true || (record.flagTES) == true || (record.flagTEP) == true)}',

                                                tooltip: 'Trasporto Eccezionale',

                                                passive: true
                                            }

                                        },
                                        merciPericolose: {

                                            bind: {

                                                iconCls: "x-fa fa-exclamation-triangle",

                                                hidden: '{!((record.flagMerPcl) == true)}',

                                                tooltip: 'Merci Pericolose',

                                                passive: true
                                            }

                                        },

                                        codiceSagoma: {

                                            bind: {

                                                iconCls: "x-fa fa-draw-polygon",

                                                hidden: '{!((record.flagSagoma) == true)}',

                                                tooltip: 'Codice Sagoma',

                                                passive: true
                                            }

                                        },

                                        modificaMerci: {

                                            handler: dettaglioMerci.bind(this),

                                            bind: {

                                                iconCls: (props.inEdit && rtoSchede.data.items[0].data.rto.ideTipVCO !== '3') ? "x-fa fa-edit buttonMerciTool" : "x-fa fa-arrow-up-right-from-square buttonMerciTool",

                                                tooltip: (props.inEdit && rtoSchede.data.items[0].data.rto.ideTipVCO !== '3') ? 'Modifica Dettaglio Merci' : 'Dettaglio Merci'
                                            }
                                        }

                                    }

                            }}
                        />

                    </Grid>
                </Panel>
            </Panel>
            </Panel>
        </Container>
    );
}

export default RtoImpostazione;
