import { createContext, useState, useContext, useEffect } from "react";

const Ext = window["Ext"];

const PeriodiContext = createContext(undefined); //inizia con undefined perchè all'inizio non si sa chi è loggato

export const PeriodiProvider = ({ children }) => {
    const [periodi, setPeriodi] = useState({ periodiFerroviari:[], periodiFerroviariAttivi:[], periodoRTO:[] });

    const fetchData = (url) => {
        return new Promise((resolve, reject) => {
            Ext.Ajax.request({
                url,
                method: 'get',
                callback: function (records, success, operation) {
                    if (success) {
                        resolve(Ext.decode(operation.responseText));
                    } else {
                        reject(new Error('Errore durante la richiesta dei dati'));
                    }
                }
            });
        });
    };

    const loadData = async () => {
        try {
            const requests = [
                fetchData('Anagrafiche/LeggiPeriodiFerroviari'),
                fetchData('Anagrafiche/LeggiPeriodiFerroviariAttivi'),
                fetchData('Anagrafiche/LeggiTipologiePeriodicita'),
            ];
            const responses = await Promise.all(requests);
            setPeriodi({ periodiFerroviari: responses[0], periodiFerroviariAttivi: responses[1], periodoRTO: responses[2] })
        } catch (error) {
            console.error('Errore durante la richiesta dei periodi (ferroviario e rto) ', error);
        }
    }  

    useEffect(() => {       
        loadData();        
    }, []);
   
    return (
    <PeriodiContext.Provider
            value={{
                periodi,
                loadPeriodi: () => loadData()
            }}
    >
        {children}
    </PeriodiContext.Provider>
    );
};

//This external function is created just for convenience,so there's no need to export the user context to external components.
export const usePeriodi = () => useContext(PeriodiContext);

