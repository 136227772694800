import React, { useEffect, useState } from "react";
import { Container, Panel, Titlebar, Grid, Column, Button, Toolbar, SegmentedButton, Togglefield } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
]);

//-------------LISTA UTENTI---------------------
const storeGestioneUtenti = Ext.create('Ext.data.Store', {
    proxy: {
        url: 'Utenti/LeggiListaUtenteCliente',
        method: 'GET',
        type: 'ajax',
        reader: {
            type: 'json'
        },
    },
    autoload: false,

});



function GestioneUtenti() {

    let title = " Gestione Utenti";
    let ui = 'action';
    const [state, setState] = useState({ GestioneUte: storeGestioneUtenti })
    const { user, switchUser } = useUser();



    const aggiornaState = () => {
        state.GestioneUte = storeGestioneUtenti;
        setState({ ...state });
    }

    const caricaGestioneUtenti = (ideUte) => {

        storeGestioneUtenti.load({
            callback: function () {
                aggiornaState();
            }
        });
    }
    //aggiornamento intervalli------------------
    useEffect(() => {
        caricaGestioneUtenti();
        const interval = setInterval(() => {
            caricaGestioneUtenti(); //aggiorna ogni 90 sec
        }, 90000);

        return () => clearInterval(interval);
    }, []);


    //------------ Aggiorna Abilitazione E-mail-----------------------
    function onGestisciMailClick(grid, info) {

        let mail = 0;
        let value = info.record.data.flgMail

        if (value === 1) {
            mail = 0
        } else if (value === 0) {
            mail = 1
        } else {
            mail = null
        }

        //--------------- POST
        Ext.Ajax.request({
            url: 'Utenti/AggiornaInvioMail',
            method: 'POST',
            params: {
                ideUte: info.record.data.ideUte,
                setMail: mail
            },
            callback: (record, success, operation) => {
                if (success) {

                    var returnObj = Ext.decode(operation.responseText);

                    if (returnObj.resp === "KO") {
                        Ext.toast({ message: 'Errore Aggiornamento Email', timeout: 4000 });
                    } else {
                        Ext.toast({ message: 'Email Aggiornata', timeout: 4000 });
                        caricaGestioneUtenti();
                    }

                } else {
                    Ext.toast({ message: 'Aggiornamento Fallito', timeout: 4000 });
                }
            }
        });

    }

    //----------Testo Toggle-------------
    const renderTextToggle = (record) => {

        let value = record.data.flgMail;

        switch (value) {
            case 0:
                return 'No';
            case 1:
                return 'Si';
            default:
                return value;
        }
    };


    const renderExportAttivo = (record) => {

        let value = record.data.flgAttivo;

        switch (value) {
            case 0:
                return 'No';
            case 1:
                return 'Si';
            default:
                return value;
        }
    };

    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridGestioneUte')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }

    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-users" />
                </Titlebar>


                <Grid flex={1} scrollable cls="custom-grid" itemId="gridGestioneUte" itemConfig={{ viewModel: true }}
                    selectable={{ checkbox: false, mode: 'single' }}
                    plugins={['gridfilters', 'gridexporter']}
                    store={storeGestioneUtenti}
                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                >

                    <Toolbar docked="top" margin="10 0 10 10">
                        <SegmentedButton docked="right">
                            <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                        </SegmentedButton>
                    </Toolbar>

                    <Column height="40" width={300} text="Gruppo RFI / Impresa" dataIndex="descrGruppo" filter='string' flex={2} exportStyle={{ width: '520' }} />
                    <Column height="40" width={90} text="Dominio" dataIndex="descrDominio" filter='string' autoSizeColumn="true" exportStyle={{ width: '120' }} />
                    <Column height="40" width={220} text="Nome" dataIndex="nome" filter='string' exportStyle={{ width: '230' }} />
                    <Column height="40" width={220} text="Cognome" dataIndex="cognome" filter='string' exportStyle={{ width: '230' }} />
                    <Column height="40" width={250} text="Username" dataIndex="username" filter='string' flex={1} exportStyle={{ width: '320' }} />
                    <Column height="40" width={200} text="E-Mail" dataIndex='email' filter='string' flex={1} exportStyle={{ width: '260' }} />
                    <Column height="40" width={135} text="Data Inserimento" dataIndex='dataInserimento' type="datecolumn" formatter='date("d/m/Y")' filter='date' exportStyle={{ width: '170' }} />
                    <Column height="40" width={60} text="Attivo" dataIndex='flgAttivo' filter={{ type: 'boolean', menu: { items: { yes: { text: 'Si' }, no:{text: 'No' } } } }}
                        exportStyle={{ width: '100' }} renderer={(value, record) => renderExportAttivo(record)} exportRenderer={(value, record) => renderExportAttivo(record)} />

                    <Column height="40" width={100} text="Invio E-Mail" dataIndex='flgMail' renderer={(value, record) => renderTextToggle(record)} exportStyle={{ width: '130' }} filter={{ type: 'boolean', menu: { items: { yes: { text: 'Si' }, no: { text: 'No' } } } }}
                        exportRenderer={(value, record) => renderTextToggle(record)} cell={{
                       
                        tools: {
                            email: {
                                handler: onGestisciMailClick.bind(this),
                                bind: {
                                    //    tpl: '{record.flgMail === 1 ? "Si" : ((record.flgMail === 0) ? "No" : "")}',
                                    cls: '{record.flgMail === 1 ? "toggleOn" : ((record.flgMail === 0) ? "toggleOff": "" )}',
                                    tooltip: 'E-Mail Abilitata'
                                }
                            }
                        }
                    }}
                    />

                </Grid>
            </Panel>
        </Container>

    );
}

export default GestioneUtenti;