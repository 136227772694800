import React from 'react';
import { Container, Panel, Button } from '@sencha/ext-react-modern';

import Calendario from "../components/Calendario/Calendario/Calendario.js";
import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
import Stazioni from "../components/Calendario/Stazioni/Stazioni.js";

const Ext = window['Ext'];

function PoPeriodicitaCalendar(props) {
    const colore = "#A00000"

    return (
        <>
            {props.calendar.data.snG_GIO_SET != "" && <Panel collapsible={false} cls="calendar-container">
                <br />
                <div className="periodicitaHeader">
                    <Stazioni stazPartenza={props.calendar.data.desLocPrv} stazArrivo={props.calendar.data.desLocDsn} colore={colore} />
                    <CalendarioSintetico
                        giorniTreno={props.calendar.data.toT_GIO}
                        periodicitaSintetico={props.calendar.data.snG_GIO_SET}
                    />
                </div>
                <Button cls="plus-minus-button" itemId={`calendarioExpButminPO${props.indkey}`} hidden={true} iconCls="x-fa fa-minus" handler={function () {
                    Ext.ComponentQuery.query(`#calendarioExpButPlusPO${props.indkey}`)[0].show();
                    this.hide();
                    Ext.ComponentQuery.query(`#calendarioExpPO${props.indkey}`)[0].hide();
                }}></Button>
                <Button cls="plus-minus-button" itemId={`calendarioExpButPlusPO${props.indkey}`} iconCls="x-fa fa-plus" handler={function () {
                    Ext.ComponentQuery.query(`#calendarioExpButminPO${props.indkey}`)[0].show();
                    this.hide();
                    Ext.ComponentQuery.query(`#calendarioExpPO${props.indkey}`)[0].show();
                }}></Button>
            </Panel>}
            {props.calendar.data.snG_PER != "" && <Panel itemId={`calendarioExpPO${props.indkey}`} hidden={true} collapsible={false}>
                <Calendario
                    dataInizio={props.calendar.data.daT_INI_VAL}
                    dataFine={props.calendar.data.daT_FIN_VAL}
                    periodicita={props.calendar.data.snG_PER}
                    dataAttProvvedimento={props.calendar.data.dAT_INI_VAL_TRE}
                    periodicitaSintetico={props.calendar.data.snG_GIO_SET}
                    IsNonEffettuabile={false}
                    isAttivazioneProvvedimento={false}
                />
            </Panel> }
        </>
    );

}

export default PoPeriodicitaCalendar;

