import React from 'react';
import { Panel, FormPanel, Container, Button, Dialog, ComboBoxField, CheckBoxField, DatePickerField, FileField, SpinnerField, Accordion, FieldSet, TextField, TextAreaField,Spacer } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';
import { useLocation } from 'react-router-dom';

import SchedaRto from '../rto/Rto'

/*// esempio https://codesandbox.io/s/sleepy-mendel-q94uqu?fontsize=14&hidenavigation=1&module=/src/App.js&theme=dark&file=/src/App.js:2266-2339*/

function RtoPage() {
    const { user, switchUser } = useUser();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [showDialogDetailRTO, setShowDialogDetailRTO] = useState(false);
    const paramsDetail = { ideRTO: 0, ideGrpRTO: 0, ideCli: 0, flgUpdVes: null, flgModTrc: null, flgUpdTrc: null, tipoAzione: "", openInEdit: false };
    paramsDetail.ideRTO = parseInt(searchParams.get("ideRto"));
    paramsDetail.ideGrpRTO = user.idTipoGruppo;
    paramsDetail.ideCli = parseInt(searchParams.get("ideCli"));
    paramsDetail.flgUpdVes = false;
    paramsDetail.flgModTrc = false;
    paramsDetail.flgUpdTrc = false;
    paramsDetail.tipoAzione = "A";
    paramsDetail.openInEdit = false;
    paramsDetail.readOnly = true;
    paramsDetail.modificaServiziCommerciali = false;
    function hideDialogDetailRTO() {
        setShowDialogDetailRTO({ showDialogDetailRTO: false });
        //caricaRtoList(params.filtro);
    }
    function RefreshRtoList() {
        // non deve fare nulla
    }

    return (
      <Dialog
                displayed={true}
                //title="Dettaglio RTO"
                //closable="true"
                //closeAction="hide"
                maximized={true}
                //maximizable={true}
                //onHide={() => setShowDialogDetailRTO({ showDialogDetailRTO: false })}
            >
            <SchedaRto parRto={paramsDetail} hideDialogDetailRTO={hideDialogDetailRTO} RefreshRtoList={RefreshRtoList} />
            </Dialog>
  );
}

export default RtoPage;
