import React from 'react';
import { Panel, FormPanel, Container, Dialog, Button, List, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';

const Ext = window['Ext'];


function PoRiepilogoTrc(props) {

    //let schedaPo = props.schedaPo;
    let rto = props.rto;
    function aggiornaControllo(stato) {
        props.aggiornaStateControllo(stato);
    }
    let origine = props.schedaPo.testataPO.sigLocPar + " (" + props.schedaPo.testataPO.oraPar + ")";
    let destinazione = props.schedaPo.testataPO.sigLocArr + " (" + props.schedaPo.testataPO.oraArr + ")";
    let note = props.schedaPo.testataPO.nota;

    const { user, switchUser } = useUser();

    const toolHandler = (panel, tool) => {

        if (tool.config.type === 'delete') {
            if (props.rto.ideStoRTO >= 4 && props.numPO < 2) {
                Ext.Msg.alert('Impossibile eliminare traccia associata dopo il completamento della progettazione di dettaglio. Associare prima una nuova traccia, poi eliminare questa');
            }
            else {
                Ext.Msg.confirm('Associazione PO', "Stai per eliminare la Traccia Orario" + props.schedaPo.testataPO.desTrcOra + ", procedere?", function (button) {
                    if (button === 'yes') {
                        Ext.Ajax.request({
                            url: 'Po/EliminaTracciaOrario',
                            method: 'POST',
                            params: {
                                ideUte: user.ideUte,
                                ipAddress: "localhost",
                                ideTrcOra: props.schedaPo.testataPO.ideTrcOra
                            },
                            callback: function (records, success, operation) {
                                if (success) {
                                    props.RefreshListaPO();
                                    aggiornaControllo(true);
                                }
                            }
                        });
                    }
                })
            }
        }
    }

    function onModificaNotaPO() {
        Ext.ComponentQuery.query('#buttonModificaRiepilogoPO' + props.ind)[0].setDisabled(true);
        Ext.ComponentQuery.query('#confermaRiepilogoPO' + props.ind)[0].setDisabled(false);
        Ext.ComponentQuery.query('#annullaRiepilogoPO' + props.ind)[0].setDisabled(false);
        Ext.ComponentQuery.query('#notePOId' + props.ind)[0].enable();
    }

    function onAnnullaModificaNotaPO() {
        Ext.ComponentQuery.query('#buttonModificaRiepilogoPO' + props.ind)[0].setDisabled(false);
        Ext.ComponentQuery.query('#confermaRiepilogoPO' + props.ind)[0].setDisabled(true);
        Ext.ComponentQuery.query('#annullaRiepilogoPO' + props.ind)[0].setDisabled(true);
        Ext.ComponentQuery.query('#notePOId' + props.ind)[0].setValue(note);
        Ext.ComponentQuery.query('#notePOId' + props.ind)[0].disable();
    }

    function onConfermaModificaNotaPO() {
        Ext.Ajax.request({
            url: 'Po/ModificaIndotteTracce',
            method: 'POST',
            params: {
                ideUte: user.ideUte,
                ipAddress: "localhost",
                ideTrcOra: props.schedaPo.testataPO.ideTrcOra,
                nota: Ext.ComponentQuery.query('#notePOId' + props.ind)[0]._value
            },
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);
                    //modifica gestione errore
                    if (returnObj.resp === "KO") {
                        Ext.Msg.alert('Modifica nota fallito', returnObj.msgErr);
                    }
                    else {
                        Ext.toast({ message: 'Nota Salvata', timeout: 4000 });
                        Ext.ComponentQuery.query('#buttonModificaRiepilogoPO' + props.ind)[0].setDisabled(false);
                        Ext.ComponentQuery.query('#confermaRiepilogoPO' + props.ind)[0].setDisabled(true);
                        Ext.ComponentQuery.query('#annullaRiepilogoPO' + props.ind)[0].setDisabled(true);
                        Ext.ComponentQuery.query('#notePOId' + props.ind)[0].disable();
                        note = Ext.ComponentQuery.query('#notePOId' + props.ind)[0]._value
                    }
                }
                else {
                    Ext.Msg.alert('Modifica nota fallita', 'Modifica nota fallita');
                }

            }
        });
    }

    return (
        <Container>
            <Panel
                itemId={"poRiepilogo" + props.ind}
                title={props.schedaPo.testataPO.desTrcOra}
                titleCollapse={true}
                cls='PnlSectionPOTR'
                iconCls="x-fa fa-train"
                collapsible={{ collapsed: false, direction: 'top' }}
                tools={[
                    { iconCls: 'x-fa fa-trash', tooltip: 'Elimina', type: 'delete', handler: toolHandler, hidden: props.hiddenDeletePO }
                ]}
            >
                <Panel layout="hbox" >

                    <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                        <TextField clearable={false} label="N. Treno RFI" labelAlign="top" labelCls='LabelRiepilogoRed' editable={false} head="20" value={props.schedaPo.testataPO.nomTreRFI} />
                        <TextField clearable={false} label="Origine" labelAlign="top" labelCls='LabelRiepilogoRed' editable={false} head="20" value={origine} />
                    </Panel>
                    <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                        <TextField clearable={false} label="Data inizio validita" labelAlign="top" labelCls='LabelRiepilogoRed' editable={false} head="20" value={props.schedaPo.testataPO.datIniValTre} />
                        <TextField clearable={false} label="Destinazione" labelAlign="top" labelCls='LabelRiepilogoRed' editable={false} head="20" value={destinazione} />
                    </Panel>
                    <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                        <TextField clearable={false} label="Categoria RFI" labelAlign="top" labelCls='LabelRiepilogoRed' editable={false} head="20" value={props.schedaPo.testataPO.sigCatTre} />
                        <TextField clearable={false} label="Giorni PO" labelAlign="top" labelCls='LabelRiepilogoRed' editable={false} head="20" value={props.schedaPo.testataPO.gioTrc} />
                    </Panel>
                    <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                        <TextField clearable={false} label="Committente PO" labelAlign="top" labelCls='LabelRiepilogoRed' editable={false} head="20" value={props.schedaPo.testataPO.nomCme} />
                        <TextField clearable={false} label="Km PO" labelAlign="top" editable={false} head="20" value={props.schedaPo.testataPO.kmtTrc} />
                    </Panel>
                </Panel>
                <Panel
                    title="Note"
                    iconCls="x-fa fa-sticky-note"
                    collapsible={{ collapsed: false, direction: 'top' }}
                    cls='PnlSectionPoL1'
                    padding={3}
                    tools={[
                        { iconCls: 'x-fa fa-edit', itemId: "buttonModificaRiepilogoPO" + props.ind, tooltip: 'Modifica', hidden: props.hiddenDeletePO, handler: onModificaNotaPO.bind(this) },
                        { iconCls: 'x-fa fa-check', itemId: "confermaRiepilogoPO" + props.ind, hidden: props.hiddenDeletePO, handler: onConfermaModificaNotaPO.bind(this), disabled: 'true', tooltip: 'Conferma' },
                        { iconCls: 'x-fa fa-undo', itemId: "annullaRiepilogoPO" + props.ind, handler: onAnnullaModificaNotaPO.bind(this), hidden: props.hiddenDeletePO, disabled: 'true', tooltip: 'Annulla' },
                        /* { type: 'menu', handler: toolHandler }*/
                    ]}
                >
                    <FormPanel >
                        <TextAreaField
                            flex={1}
                            maxRows={10}
                            labelCls='LabelRiepilogoRed'
                            itemId={"notePOId" + props.ind}
                            disabled={true}
                            value={note}
                        />
                    </FormPanel>
                </Panel>
            </Panel>
        </Container>
    );

}

export default PoRiepilogoTrc;