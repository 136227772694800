import "./Style.css";
import React from 'react';
import { monthNames, weekDays } from './Costanti';
import {
    parseDate,
    validateDates,
    getBackgroundColor,
    generateEmptyDays,
    calculateDateInfo,
    calculateIsHighlightedDay,
    createDayElement,
    createMonthElement,
    getClassAndStyle,
    fixPeriodicita,
} from './Funzioni';
import Legenda from "./Legenda";

const Calendario = ({ dataInizio, dataFine, periodicita, dataAttProvvedimento, periodicitaSintetico, IsNonEffettuabile, isAttivazioneProvvedimento}) => {
    validateDates(dataInizio, dataFine)
    const months = [];
    let periodicitaIndex = 0;
    let currentDate = parseDate(dataInizio).startOf('month');
    let remainingDays = parseDate(dataFine).diff(parseDate(dataInizio), 'day') + 1;
    periodicitaSintetico = fixPeriodicita(periodicitaSintetico, '0', '6');
    while (remainingDays > 0) {
        const days = [];
        const { currentYear, currentMonth, daysInMonth, firstDayOfWeek, isFirstMonth, daysDifference } = calculateDateInfo(currentDate, dataInizio);
        const emptyDays = generateEmptyDays(firstDayOfWeek, isFirstMonth, daysDifference);
        const daysToAdd = Math.min(remainingDays, isFirstMonth ? daysInMonth - (parseDate(dataInizio).date() - 1) : daysInMonth);
        days.push(emptyDays);

        const startDay = isFirstMonth ? parseDate(dataInizio).date() : 1;
        const remainingDaysInMonth = isFirstMonth ? parseDate(dataInizio).daysInMonth() : currentDate.date() + daysToAdd - 1;
        for (let day = startDay; day <= remainingDaysInMonth; day++) {
            const dayClassName = calculateIsHighlightedDay(day, currentMonth, currentYear, dataAttProvvedimento) ? 'day day-highlighted' : 'day';
            const color = getBackgroundColor(periodicitaIndex, periodicita);
            days.push(createDayElement(currentYear, currentMonth, day, dayClassName, color));
            periodicitaIndex++;
        }

        months.push(createMonthElement(currentYear, currentMonth, days, monthNames));
        remainingDays -= daysToAdd;
        currentDate = currentDate.add(1, 'month').startOf('month');
    }
    return (
        <>
            <div className="group">
                <div className="month" key={`Legend`}>
                    <h2 className='monthTitle' style={{ color: 'transparent' }}>_</h2>
                    <div className="days" >
                        {weekDays.map((day, index) => {
                            const { className, style } = getClassAndStyle(index, periodicitaSintetico);
                            return (
                                <>
                                    <div className={`${className} day`} key={index} style={style}>
                                        {(day)}
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
                <div className="calendar">{months}</div>
            </div>
            <Legenda IsNonEffettuabile={IsNonEffettuabile} isAttivazioneProvvedimento={isAttivazioneProvvedimento}/>
        </>


    );
};

export default Calendario;
