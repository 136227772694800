// COMPONENTE GESTIONE UTENTE
// ---------------------------
// Dati utente che arrivano dal token PIC, se non c'� il token lo UserContext sar� undefined e si aprir� la dialogModale per inserire le credenziali.
// Questa dialog avr� 2 button IF e RFIDC (valutare se mettere visibili solo in modalit� #debug) per l'inserimento veloce delle credenziali di test + un form di login/pwd
// Dal form se le credenziali sono valide, in base alle regole delle pagine masterpage (token)/login(debug) vecchie, si accede al sistema.


import React, { useContext, useState, Image, useMemo, Container, useRef, Loadmask, useEffect } from 'react';
import '../App.css';
import { useUser } from '../components/UserContext';
import { PasswordField, Button, ExtDialog, Panel, Formpanel, Spacer, TextField, Toolbar } from '@sencha/ext-react-modern';
import infoUtenteModel from '../models/InfoUtenteModel.js';
import { useNavigate } from "react-router-dom";
import rneLogo from '../images/rne-logo.svg';

const Ext = window['Ext'];


function AuthPCS() {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(true);
    const { user, switchUser } = useUser();
    const handleSubmit = (btn) => {
        if (Ext.ComponentQuery.query('#userPCS')[0]._value === "" || Ext.ComponentQuery.query('#userPCS')[0]._value === null || Ext.ComponentQuery.query('#passwordPCS')[0]._value === "" || Ext.ComponentQuery.query('#passwordPCS')[0]._value === null)
            Ext.toast({ message: 'Immettere user e password per proseguire', timeout: 3000 });
        else {
            Ext.Ajax.request({
                url: 'RtoInternazionali/SalvaCredenzialiPCS',
                method: 'POST',
                params: {
                    ideUte: user.ideUte,
                    pcs_username: Ext.ComponentQuery.query('#userPCS')[0]._value,
                    pcs_password: Ext.ComponentQuery.query('#passwordPCS')[0]._value
                },
                callback: function (records, success, operation) {
                    var returnObj = Ext.decode(operation.responseText);
                    if (returnObj.resp === "OK") {
                        Ext.Ajax.request({
                            url: 'RtoInternazionali/AutenticazionePCS',
                            method: 'GET',
                            params: {
                                ideUte: user.ideUte
                            },
                            callback: function (records, success, operation) {
                                var returnObj = Ext.decode(operation.responseText);
                                if (returnObj !== "" && returnObj !== null) {
                                    handleClose();
                                }
                                else {
                                    Ext.toast({ message: 'Autenticazione PCS fallita', timeout: 3000 });
                                    handleClose();
                                }
                            }
                        });

                    }
                    else {
                        Ext.toast({ message: 'Salvataggio credenziali PCS non riuscito', timeout: 3000 });
                        handleClose();
                    }

                }
            });
        }

    };

    const handleClose = (e) => {
        navigate(-1);
    }

    return (
        <ExtDialog
            modal={showModal}            
            displayed={showModal}
            title="Login RNE PCS"
            closable
            //onDestroy={handleClose}
            centered
        >
            <Panel>
                <Formpanel ref={form => form = (form)}
                    shadow
                    padding="20"
                    platformConfig={{
                        "!phone": {
                            maxHeight: 500,
                            width: 350
                        }
                    }}>
                    <Button margin="38px" width="224px" height="49px" cls="logoButton"></Button>                
                    <TextField itemId="userPCS" label="UserID" name="login" required placeholder="inserire utente" />
                    <PasswordField itemId="passwordPCS" label="Password" name="pwd" required revealable />
                    <Toolbar shadow={false} docked="bottom" layout={{ type: 'hbox', pack: 'right' }}>
                        <Spacer maxHeight={100} maxWidth={100} ></Spacer>
                        <Button type="submit" text={"Entra"} cls={'ButtonAstro'} handler={handleSubmit} />
                        {/*<Button text="Reset" handler={() => this.form.reset()} />*/}
                    </Toolbar>
                </Formpanel>
                </Panel>
        </ExtDialog>
    )
}

export default AuthPCS;




//di seguito utility per uso futuro, da cancellare poi



//  <Container {...buttonGroupProps}>

//const buttonGroupProps = {
//    padding: '50 30 50 30',
//    layout: { type: 'hbox', align: 'center' },
//    flex: 1,
//    width: '100%'
//}

//const layouFormPanelProps = {
//    padding: '50 30 50 30',
//    layout: { type: 'vbox', align: 'middle' },
//    flex: 1,
//    width: '100%'
//}



//const handleSubmit = async e => {
//    e.preventDefault();
//    const token = await loginUser({
//        username,
//        password
//    });
//    setToken(token);
//}




/*-- per dare il focus su un input text o su un altro elemento del form
const formInputRef = React.useRef(null);
const focusInput = () => {
    formInputRef.current.focus();
}
return (
    <h1>Usare useRef to access underlying DOM</h1>
    <input ref={formInputRef} type= "text"/>
    <button onClick= {focusInput}>Focus input qui</button>
    );
--*/



//let utente = {
//    username: "astroif-impresa", //ad es.: "astroif-impresa";
//    idTipoGruppo: 1,
//    ideCli: 1,
//    idTipoDominio: 1,
//    idOperatoreRFI: 1
//}