const Ext = window['Ext'];

export default Ext.define('infoUtenteModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideUte', type: 'int' },
            { name: 'usr', type: 'string' },
            { name: 'nom', type: 'string' },
            { name: 'cog', type: 'string' },
            { name: 'tel', type: 'string' },
            { name: 'eml', type: 'string' },
            { name: 'ideGrp', type: 'int' },
            { name: 'dscGrp', type: 'string' },
            { name: 'ideTipGrp', type: 'int' },
            { name: 'dscTipGrp', type: 'string' },
            { name: 'ideDmi', type: 'int' },
            { name: 'dscDmi', type: 'string' },
            { name: 'ideTipDmi', type: 'int' },
            { name: 'dscTipDmi', type: 'string' },
            { name: 'ideCli', type: 'int' },
            { name: 'nomCli', type: 'string' },
            { name: 'ideOpaRfi', type: 'int' },
            { name: 'sigOpaRfi', type: 'string' },
            { name: 'ideAbiCli', type: 'int' },
            { name: 'flgCatMer', type: 'int' }

        ]
});

//nota: i nullabili type auto?????