import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { UserProvider } from './components/UserContext';

const root = createRoot(document.getElementById('root'));

//root.render(<App />);

root.render(<UserProvider><App /></UserProvider>);   



// *** per lo switch del theme ***
//import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
//const themes =
//{
//    default: '/ext-modern-runtime/triton-default.css',
//    dark: '/ext-modern-runtime/triton-dark.css',
//    light: '/ext-modern-runtime/triton-light.css'
//}
//root.render(<ThemeSwitcherProvider defaultTheme={'default'} themeMap={themes}><App /></ThemeSwitcherProvider>);

//ovvero
//import { ThemeProvider } from './components/ThemeContext';
//root.render(<ThemeProvider><UserProvider><App /></UserProvider></ThemeProvider>);




