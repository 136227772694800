import React from 'react';
import { Panel, FormPanel, Container, Dialog, Toolbar, Button, ComboBoxField, Spacer, List, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { useState, useEffect, useContext } from "react";
import { useUser } from '../components/UserContext';
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import PnlRiepilogo from './PoRiepilogoTrc.js'; 

const Ext = window['Ext'];

function PoRiepilogo(props) {
    const { user, switchUser } = useUser();

    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    const numPO = props.schedePo.length;
    const contextVersioneRto = useContext(VersioneRtoContext);
    const rto = contextVersioneRto.storeRtoSchede.data.items[0].data.rto;
    const hiddenAddPO = ((props.statoRto === 2 || props.statoRto === 4 || props.statoRto === 5 || props.statoRto === 6 || props.statoRto === 8) && !isIF && (props.inEditPO !== undefined ? props.inEditPO : false) && !props.readOnly) ? false : true;
    const hiddenDeletePO = hiddenAddPO; // || (props.statoRto > 4 && props.schedePo.length === 1 );
    const [treniAssociabili, setTreniAssociabili] = useState({ showDialog: false });
    const calcolaData = (dataInput) => {

        let dateObj = new Date(dataInput);

        let day = dateObj.getDate();
        let month = dateObj.getMonth() + 1;
        let year = dateObj.getFullYear();
        let hours = dateObj.getHours();
        let minutes = dateObj.getMinutes();
        let seconds = dateObj.getSeconds();

        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        let formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes + ':' + seconds;
        return formattedDate;
    }
    const dataAggiornamento = props.schedePo.length > 0 ? calcolaData(props.schedePo[0].testataPO.datUpd).toString() : "";
    

    const TreniRTOAssociabili = (props) => {
        const { user, switchUser } = useUser();
        const [stateTreni, setStateTreni] = useState({ arrayTreniAssociabili: [] })

        function aggiornaControllo(stato) {
            props.aggiornaStateControllo();
        }

        const loadListaTreniAssociabili = (idecli, iderto, idelocori, idelocdsn) => {
            let arrayTreni = [];
            Ext.Ajax.request({
                url: 'Po/LeggiListTreniRTOAssociabili',
                method: 'GET',
                params: {
                    ideCli: idecli,
                    ideRto: iderto,
                    ideUte: user.ideUte,
                    ideLocOri: idelocori,
                    ideLocDsn: idelocdsn
                },
                callback: function (resp, success, operation) {
                    let arrayTreni = [];
                    if (success) {
                        let listaTreni = Ext.decode(operation.responseText);
                        if (listaTreni.length > 0) {
                            for (let i = 0; i < listaTreni.length; i++) {
                                let treno = listaTreni[i];
                                let descrTreno = treno.nomTreRfi + " - " + treno.sigCatTre + " - " + treno.datIniVal + " (" + treno.desLocOri + " " + treno.oraPar + " - " + treno.desLocDsn + " " + treno.oraArr + ")";
                                let app = { key: treno.nomTreRfi, value: descrTreno, treno: treno };
                                arrayTreni.push(app);
                            }
                        }
                    }
                    stateTreni.arrayTreniAssociabili = arrayTreni;
                    aggiornaStateTreni();
                }
            });
        };

        const aggiornaStateTreni = () => {
            setStateTreni( { ...stateTreni } );
        };

        useEffect(() => {
            loadListaTreniAssociabili(props.rto.ideCli, props.rto.ideRTO, props.rto.ideLocPrv, props.rto.ideLocDsn);
        }, []);

    const ricercaTrenoPO = () => {
            var testo = Ext.ComponentQuery.query('#testoRicercaTrenoPO')[0].getValue();
            if (testo != null && testo.trim() != "") {
                testo = testo.trim();
                Ext.Ajax.request({
                    url: 'Po/LeggiTreniRTOAssociabiliByNomTreRFI',
                    method: 'GET',
                    params: { ideCli: props.rto.ideCli, ideRto: props.rto.ideRTO, NomTreRfi: testo },
                    callback: function (resp, success, operation) {
                        if (success) {
                         
                            let arrayTreni = Ext.decode(operation.responseText);
                            if (arrayTreni.length > 0) {
                                for (let i = 0; i < arrayTreni.length; i++) {
                                    let treno = arrayTreni[i];
                                    let descrTreno = treno.nomTreRfi + " - " + treno.sigCatTre + " - " + treno.datIniVal + " (" + treno.desLocOri + " " + treno.oraPar + " - " + treno.desLocDsn + " " + treno.oraArr + ")";
                                    let app = { key: treno.nomTreRfi, value: descrTreno, treno: treno };
                                    //// ricerca nella lista treni associabili se esite già il treno (treno non è chiave unica), se già inserito viene eliminato dall'elenco prima di aggiungere il nuovo trovato              
                                    //let index = stateTreni.arrayTreniAssociabili.findIndex(p => p.key === treno.nomTreRfi);
                                    //if (index > -1) {
                                    //    // rimuove il treno dall'elenco
                                    //    stateTreni.arrayTreniAssociabili.splice(index, 1);
                                    //}
                                    //Aggiunge il treno in fondo all'elenco
                                    stateTreni.arrayTreniAssociabili.push(app);
                                    Ext.ComponentQuery.query('#polistTreniAssociabili')[0].refresh();
                                    // ripulusce il box di ricerca
                                    Ext.ComponentQuery.query('#testoRicercaTrenoPO')[0].setValue('');
                                    //--------------------------------------------------------------                            
                                    
                                }
                                aggiornaStateTreni();
                            }
                            else { Ext.Msg.alert('Ricerca treno', 'Nessun treno trovato con ' + testo); }
                        }
                    }

                });

            }
            else {
                Ext.Msg.alert('Nessuna selezione', 'Impostare il numero treno per la ricerca');
            }
        };

        const onListSelect = (list, record) => {
            Ext.ComponentQuery.query('#btnConfermaAssPO')[0].setDisabled(false);
        };
        const onListDeselect = () => {
            Ext.ComponentQuery.query('#btnConfermaAssPO')[0].setDisabled(true);
        };

        const onConfermaAzione = () => {
            Ext.ComponentQuery.query('#btnConfermaAssPO')[0].setDisabled(true);
            let list = Ext.ComponentQuery.query('#polistTreniAssociabili')[0];

            let trenoSelezionato = list.getSelection().data.treno;

            if (trenoSelezionato != null) {
                let nomTreRfi = trenoSelezionato.nomTreRfi;
                let datIniVal = trenoSelezionato.datIniVal;
                Ext.Ajax.request({
                    url: 'Po/InserisciTracciaOrario',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideRto: props.rto.ideRTO,
                        NomTreRfi: nomTreRfi,
                        DatIniVal: datIniVal
                    },
                    callback: function (resp, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            // gestione errore
                            if (returnObj.resp === "KO") {
                                Ext.Msg.alert('Associazione fallita', returnObj.msgErr)
                            }
                            else {
                                aggiornaControllo(true);
                                props.RefreshListaPO();
                            }
                        }
                    }

                });
            }
        };


        const tpl = data => (
            <div>
                <div>{data.value}</div>
            </div>
        );

        return (
            <Container>
                <Dialog
                    displayed={true}
                    closable={true}
                    resizable={true}
                    title="Associa PO"     
                    centered={false}
                    style={{ transform: 'translate3d(36vW, 35vH, 0)' }} 
                >
                    <Panel layout="vbox"
                        height={250}
                        maxHeight='60vh'
                        minWidth={500}>
                        <List
                            flex={1}
                            scrollable
                            itemId="polistTreniAssociabili"
                            data={stateTreni.arrayTreniAssociabili}
                            itemTpl={tpl}
                            onSelect={onListSelect}
                            onDeselect={onListDeselect}                          
                        />

                    </Panel>
                    <Toolbar docked="bottom" border={true} height={40}>
                        <Panel layout="hbox" docked="left">
                            <TextField itemId="testoRicercaTrenoPO" margin="5 10 5 10" />
                            <Button cls="appButton" ui="action" margin="5 10 5 0" align="right" itemId="btnRicercaTrenoPO" iconCls="x-fa fa-search" handler={ricercaTrenoPO} />
                        </Panel>
                        <Panel docked="right">
                            <Button cls="appButton" ui="action" margin="5 10 5 10" align="right" itemId="btnConfermaAssPO" iconCls="x-fa fa-square-check" text="Associa" disabled={true} handler={onConfermaAzione} />
                            <Button cls="appButton" ui="action" margin="5 10 5 10" align="right" itemId="btnChiudiAssPO" iconCls="x-fa fa-square-check" text="Chiudi" handler={props.chiudiTreniAssociabili} />
                        </Panel>
                    </Toolbar>

                </Dialog>
            </Container>

        )
    }

    const toolHandler = (panel, tool) => {
       
        if (tool.config.type === 'add') {
            treniAssociabili.showDialog = true;
            setTreniAssociabili({ ...treniAssociabili });
        }
        if (tool.config.type === 'agg') {
            if (props.statoRto === 2 || props.statoRto === 4) {
                Ext.Ajax.request({
                    url: 'Po/AggiornaDatiTracce',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideRTO: rto.ideRTO
                    },
                    callback: function (resp, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            // gestione errore
                            if (returnObj.resp === "KO") {
                                Ext.Msg.alert('Aggiornamento fallito', returnObj.msgErr)
                            }
                            else {
                                /* props.aggiornaStateControllo();*/
                                props.RefreshListaPO();
                            }
                        }
                    }

                });
            }
            else {
                Ext.Ajax.request({
                    url: 'Po/AggiornaPO',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideRTO: rto.ideRTO,
                        statoRTO: rto.ideStoRTO,
                        ideTipGrp: user.idTipoGruppo
                    },
                    callback: function (resp, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            // gestione errore
                            if (returnObj.resp === "KO") {
                                Ext.Msg.alert('Aggiornamento fallito', returnObj.msgErr)
                            }
                            else {
                                if (returnObj.ideRto != null)
                                    props.RefreshRtoDaAggiornaPO(returnObj.ideRto, returnObj.ideCli);
                                Ext.Msg.alert('Esito Aggiornamento', returnObj.mess)
                            }
                        }
                    }

                });
            }
        }
    }

    function chiudiTreniAssociabili() {
        treniAssociabili.showDialog = false;
        setTreniAssociabili({ ...treniAssociabili });
        // aggiungere refresh lista treni associati
    }
    const title = !isIF && props.schedePo.length > 0 ? (`<span style="display:flex; width:100%; justify-content: space-between"> <span >Riepilogo</span><span style="font-weight:normal; font-style:italic; font-size:12px">PO acquisito il ${dataAggiornamento}</span></span>`): (`<span>Riepilogo</span>`)

    return (
        <Panel
            title={title}
            scrollable
            bodyPadding={5}
            titleCollapse={false}
            iconCls="x-fa fa-th-list"
            collapsible={{ collapsed: false, direction: 'top' }}
            cls='PnlSectionPoL1 iconPanelsRTO corsivoNoGrassetto'
            tools={[
                { iconCls: 'x-fa fa-plus', tooltip: 'Aggiungi', type: 'add', handler: toolHandler, hidden: hiddenAddPO }
            ]}
        >
            {props.schedePo.length > 0 && props.schedePo.map((scheda, index) => (<PnlRiepilogo key={scheda.testataPO.ideTrcOra} ind={index} editPO={props.editPO} aggiornaStateControllo={props.aggiornaStateControllo} rto={rto} schedaPo={scheda} numPO={numPO} datUpd={scheda.testataPO.datUpd} hiddenDeletePO={hiddenDeletePO} RefreshListaPO={props.RefreshListaPO} />))}

            {treniAssociabili.showDialog && <TreniRTOAssociabili aggiornaStateControllo={props.aggiornaStateControllo} chiudiTreniAssociabili={chiudiTreniAssociabili} rto={rto} RefreshListaPO={props.RefreshListaPO} />
            
            }
        </Panel>
    );
}

export default PoRiepilogo;