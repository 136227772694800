import React from "react";
import {
  Panel,
  FormPanel,
  Container,
  Button,
  TextField,
  Label,
} from "@sencha/ext-react-modern";
import { useState, useContext } from "react";
import { VersioneRtoContext } from "../components/VersioneRtoContext";
import { toPadding } from "chart.js/helpers";
const Ext = window["Ext"];

const formatDate = (val) => {
  var retVal = null;
  if (val != null) {
    let date = new Date(val);
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let mm =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let yyyy = date.getFullYear();
    retVal = dd + "/" + mm + "/" + yyyy;
  }
  return retVal;
};

function RtoProcesso() {
  const contextVersioneRto = useContext(VersioneRtoContext);
  const rtoSchede = contextVersioneRto.storeRtoSchede;

  const rto = rtoSchede.data.items[0].data.rto;
  const icoSistema =
    rto.ideNazOri == "1"
      ? "flag_europe"
      : rto.ideNazOri == "0"
      ? "flag_italy"
      : "";
  const styleTitle = {
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "bold",
    padding: 10
  };
  const styleText = {
    fontFamily: "Arial",
    fontSize: "14px",
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10

  };
  return (
    // <Panel
    //     title="Informazioni di processo"
    //     layout="fit"
    //     iconCls="x-fa fa-th-list"
    //     collapsible={{ collapsed: false, direction: 'top' }}
    //     cls='PnlSectionL1 iconPanelsRTO'
    // >
    // <Panel layout="hbox" margin="0 0 0 5" flex={1}>
    //     <TextField label="Sorgente" labelAlign="top" head="20" width={110} readonly value={rto.desOri} />
    //     <Label head="20" width={30} className={icoSistema} margin="25 5 1 5" />
    //     <TextField label="Autore" labelAlign="top" head="20" flex={1} readonly value={rto.autoreVersione} />
    //     <TextField label="Versione RTO" labelAlign="top" head="20" flex={1} readonly value={"v" + rto.prgVesRTO + " - " + rto.nomVesRTO} />
    //     <TextField label="Data Versione" labelAlign="top" head="20" flex={1} readonly value={formatDate(rto.datVesRTO)} />
    //     <TextField label="Data Scadenza" labelAlign="top" head="20" flex={1} readonly value={formatDate(rto.datScd)} />
    //     <TextField label="Ultimo cambio stato" labelAlign="top" head="20" flex={2} readonly value={rto.autoreUltimaVersione} />
    // </Panel>
    <Panel cls="infoProcesso" layout="hbox" margin="0 0 0 5" flex={1}>
      <Label style={styleTitle} html="Sorgente: " />
      <Label style={styleText} html={rto?.desOri} />
      <Label style={styleText} head="20" width={30} className={icoSistema} />
      <Label style={styleTitle} html="Autore: " />
      <Label style={styleText}html={rto?.autoreVersione} />
      <Label style={styleTitle} html="Versione RTO: " />
      <Label style={styleText}html={"v" + rto?.prgVesRTO + " - " + rto.nomVesRTO} />
      <Label style={styleTitle} html="Data Versione: " />
      <Label style={styleText}html={formatDate(rto?.datVesRTO)} />
      <Label style={styleTitle} html="Data Scadenza: " />
      <Label style={styleText}html={formatDate(rto?.datScd)} />
      <Label style={styleTitle} html="Ultimo cambio stato: " />
      <Label style={styleText}html={rto?.autoreUltimaVersione} />
    </Panel>

    // </Panel>
  );
}

export default RtoProcesso;
