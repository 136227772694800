const Ext = window['Ext'];

export default Ext.define('LocalitaModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideLoc', type: 'int' },
            { name: 'sigLoc', type: 'string' },
            { name: 'nomLoc', type: 'string' },
            { name: 'flgCfn', type: 'int' },
            { name: 'flgRfi', type: 'boolean' },
            { name: 'flgCfnNaz', type: 'int' }
        ]
});
