const Ext = window['Ext'];

export default Ext.define('ClientiDirittiAccessoModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideOpaRfi', type: 'auto' },
            { name: 'desOpaRfi', type: 'auto' },
            { name: 'datIni', type: 'date', format: 'd/m/Y' },
            { name: 'datFin', type: 'date', format: 'd/m/Y' }
        ]
});
