const Ext = window['Ext'];

export default Ext.define('LocalitaPercorsoRTOModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'idePrcRTO', type: 'auto' },
            { name: 'prgPrc', type: 'auto' },
            { name: 'ideLoc', type: 'auto' },
            { name: 'nomLoc', type: 'auto' },
            { name: 'oraPar', type: 'auto' },
            { name: 'oraArr', type: 'auto' },
            { name: 'minPas', type: 'auto' },
            { name: 'idePas', type: 'auto' },
            { name: 'sigPas', type: 'auto' },
            { name: 'ideTipFer', type: 'auto' },
            { name: 'flgFac', type: 'auto' },
            { name: 'notLoc', type: 'auto' },
            { name: 'opeTec', type: 'auto' },
            { name: 'crp', type: 'auto' },
            { name: 'desLoc', type: 'auto' },
            { name: 'minFleArr', type: 'auto' },
            { name: 'minFlePar', type: 'auto' },
            { name: 'rifKat', type: 'auto' },
            { name: 'gIO_PER', type: 'auto' },
            { name: 'iDE_RCR', type: 'auto' },
            { name: 'nOM_RCR', type: 'auto' },
            { name: 'fLG_PAS', type: 'auto' },
            { name: 'transitaPerAltraRete', type: 'auto' },
            { name: 'flgLocalitaRFI', type: 'auto' },
            { name: 'ofsArr', type: 'auto' },
            { name: 'ofsPar', type: 'auto' },
            { name: 'ide_Agz', type: 'auto' },
            { name: 'nom_Agz', type: 'auto' },
            { name: 'ide_Loc_Pcs', type: 'auto' },
            { name: 'nom_Loc_Pcs', type: 'auto' }
        ]
});
