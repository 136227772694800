import React from 'react';
import {
    squareStyle,
    squareColors,
    circolaText,
    nonCircolaText,
    nonEffettuabileText,
    siEffettuaText,
    nonSiEffettuaText,
    attivazioneProvvedimentoText
} from './Costanti';

const Legenda = ({ IsNonEffettuabile, isAttivazioneProvvedimento }) => {
    return (
        <table className="legendTable">
            <thead>
                <tr>
                    <th>
                        <div style={{
                            ...squareStyle,
                            backgroundColor: squareColors.circola,
                            borderColor: squareColors.circola
                        }}
                        />
                    </th>
                    <th className='legendCalendarText'>{IsNonEffettuabile ? siEffettuaText : circolaText}</th>
                    <th>
                        <div style={{
                            ...squareStyle,
                            backgroundColor: squareColors.nonCircola,
                            borderColor: squareColors.nonCircola
                        }}
                        />
                    </th>
                    <th className='legendCalendarText'>{IsNonEffettuabile ? nonSiEffettuaText : nonCircolaText}</th>
                    {IsNonEffettuabile && (
                        <th>
                            <div style={{
                                ...squareStyle,
                                backgroundColor: squareColors.nonEffettuabile,
                                borderColor: squareColors.nonEffettuabile
                            }}
                            />
                        </th>
                    )}
                    {IsNonEffettuabile && (<th className='legendCalendarText'>{nonEffettuabileText}</th>)}
                    {isAttivazioneProvvedimento && (
                        <th>
                            <div style={{
                                ...squareStyle,
                                backgroundColor: squareColors.attivazioneProvvedimento,
                                borderColor: squareColors.nonEffettuabile
                            }}
                            />
                        </th>
                    )}
                    {isAttivazioneProvvedimento && (<th className='legendCalendarText'>{attivazioneProvvedimentoText}</th>)}
                </tr>
            </thead>
            {/* <tbody>
                <tr>
                    <td className='legendText'>{IsNonEffettuabile ? siEffettuaText : circolaText}</td>
                    <td className='legendText'>{IsNonEffettuabile ? nonSiEffettuaText : nonCircolaText}</td>
                    {IsNonEffettuabile && (<td className='legendText'>{nonEffettuabileText}</td>)}
                    {isAttivazioneProvvedimento && (<td className='legendText'>{attivazioneProvvedimentoText}</td>)}
                </tr>
            </tbody> */}
        </table>
    );
};

export default Legenda;
