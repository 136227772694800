import React from 'react';
import { Panel, Container, Grid, Column, Checkcolumn, Loadmask } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';
import clientiCdSModel from '../models/ClientiCdSModel.js';
import clientiCdSFileModel from '../models/ClientiCdSFileModel.js';
import CdsFilesDialog from './CdsFilesDialog.js'
import { Dialog } from '@sencha/ext-react-modern';

const Ext = window['Ext'];
const storeClienteCdS = Ext.create('Ext.data.Store', {
    model: clientiCdSModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Clienti/LeggiListaCertificatiPerLicenza',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeTipoServizio = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});
let arrayTipoServizio = [];

const storeLimitazioneMerci = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});
let arrayLimitazioneMerci = [];

function ClienteCdS(props) {
    const { user, switchUser } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    var conLicenza = props.licenza != null ? true : false;
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento CdS in corso..." });

    var isRFICOMP = user.ruolo === 'RFI-PO-COMP';
    var isRFICC = user.ruolo === 'RFI-PO-RFICC';

    const caricaCdS = () => {
        storeClienteCdS.load({
            params: {
                ideLic: conLicenza ? props.licenza.ideLic : -1
            },
            callback: (records, operation, success) => {
                if (success) {
                } else {
                    console.error("Errore durante il caricamento dei dati");
                }
            }
        })
    }
    const caricaTipoServizio = () => {
        Ext.Ajax.request({
            url: 'Clienti/LeggiServizioAll',
            method: 'GET',
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                storeTipoServizio.loadData(returnObj);
                arrayTipoServizio = [];
                for (let i = 0; i < returnObj.length; i++) {
                    let app = { text: returnObj[i].desSer, value: returnObj[i].ideSer };
                    arrayTipoServizio.push(app);
                }
                Ext.ComponentQuery.query('#cmdTipoSer')[0].setOptions(arrayTipoServizio);
            }
        });
    }
    const caricaLimitazioneMerci = () => {
        Ext.Ajax.request({
            url: 'Clienti/LeggiMerciAll',
            method: 'GET',
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                storeLimitazioneMerci.loadData(returnObj);
                arrayLimitazioneMerci = [];
                arrayLimitazioneMerci.push({ text: "", value: -1 });
                for (let i = 0; i < returnObj.length; i++) {
                    let app = { text: returnObj[i].sigLmzMerPcl, value: returnObj[i].sigLmzMerPcl };
                    arrayLimitazioneMerci.push(app);
                }
                Ext.ComponentQuery.query('#cmdLmzMerPcl')[0].setOptions(arrayLimitazioneMerci);
            }
        });
    }

    const onEliminaCdSClick = (grid, info) => {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del CdS?", function (button) {
            if (button === 'yes') {
                setStateLoadMask({ hidden: true, message: "Attendere cancellazione in corso..." });
                Ext.Ajax.request({
                    url: 'Clienti/EliminaCertificato',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideCef: info.record.data.ideCef
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            if (success) {
                                var returnObj = Ext.decode(operation.responseText);
                                //modifica gestione errore
                                if (returnObj.resp === "KO") {
                                    Ext.Msg.alert('Cancellazione certificato fallita', returnObj.msgErr)
                                }
                                else {
                                    Ext.toast({ message: 'Certificato eliminato', timeout: 4000 });
                                }
                            }
                            else {
                                Ext.Msg.alert('Cancellazione fallita', 'Errore nella cancellazione del CdS');
                            }
                        }
                        //ricarica l'elenco dei certificati
                        caricaCdS();
                    }
                })
            }
        })
    }

    const onModificaCdSClick = (grid, info) => {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 0);
        }
    }

    const onNuovoCdSClick = () => {
        for (var i = 0; i < Ext.ComponentQuery.query('#cmdTipoSer').length; i++) {
            Ext.ComponentQuery.query('#cmdTipoSer')[i].enable();
        }
        var grid = Ext.ComponentQuery.query('#gridClienteCdS')[0];
        var store = grid.getStore();
        var record = Ext.create(clientiCdSModel, {});
        store.insert(0, record);
        grid.getPlugin('rowedit').startEdit(record, 0);
    }

    const [dialogState, setDialogState] = useState({ dialogVisible: false, ideCef: null, numCef: null });

    //const [dialogVisible, setDialogVisible] = useState(false);
    //const [ideCef, setIdeCef] = useState(null);
    //const [numCef, setNumCef] = useState(null);

    const onCloseDialog = () => {
        //setDialogVisible(false);
        dialogState.dialogVisible = false;
        dialogState.ideCef = null;
        dialogState.numCef = null;
        setDialogState({ ...dialogState });
        caricaCdS();
    };

    function handleCollapse(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoCdS')[0].setHidden(true);
    };

    function handleExpand(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoCdS')[0].setHidden(!conLicenza || isIF);
    };

    useEffect(() => {
        caricaCdS();
        caricaTipoServizio();
        caricaLimitazioneMerci();
    }, []);

    function renderColorRow(value, record, field, metaData) {
        if (record != null) {
            let row = metaData.row;
            if (record.data.flgVal != '1' && record.data.numCef !== undefined) {
                row.setStyle("background:#f8f8f8;");
            }
            else {
                row.setStyle("background:#FFFFFF;");
            }
        }
        return value;
    }
    return (
        <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
            <Panel
                title="Certificato di Sicurezza"
                scrollable
                itemId="panelCdS"
                titleCollapse={true}
                iconCls="x-fa fa-award"
                collapsible={{ collapsed: true, direction: 'top' }}
                onCollapse={handleCollapse}
                onExpand={handleExpand}
                cls='PnlSectionCML2 iconPanelsRTO'
                tools={[
                    {
                        iconCls: 'x-fa x-fa fa-plus', tooltip: 'Nuovo', itemId: "nuovoCdS", handler: onNuovoCdSClick.bind(this), hidden: "true"
                    }
                ]}
            >

                <Grid
                    itemId="gridClienteCdS"
                    flex={1}
                    cls="column-in-panel"
                    store={storeClienteCdS}
                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                    plugins={{ gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" } }}
                    itemConfig={{ viewModel: true }}
                    margin={'3 20 1 20'}
                    minheight={140}
                    listeners={{
                        beforeedit: function (editor, context, eOpts) {
                            if (context.record.data.ideSer !== undefined) {
                                if (context.record.data.ideSer === 1) {
                                    Ext.ComponentQuery.query('#cmdLmzMerPcl')[0].disable();
                                    Ext.ComponentQuery.query('#txtNoteMP')[0].disable();
                                }
                                else {
                                    Ext.ComponentQuery.query('#cmdLmzMerPcl')[0].enable();
                                    if (context.record.data.sigLmzMerPcl !== undefined) {
                                        if (context.record.data.sigLmzMerPcl != "No" && context.record.data.sigLmzMerPcl != "")
                                            Ext.ComponentQuery.query('#txtNoteMP')[0].enable();
                                        else {
                                            Ext.ComponentQuery.query('#txtNoteMP')[0].disable();
                                        }
                                    }
                                }
                            }
                        },
                        childsingletap: function (editor, context, eOpts) {
                            if (context.column._text === "PDF") {
                                //let ideCef = context.record.get('ideCef');
                                //setIdeCef(ideCef);
                                //setNumCef(context.record.get('numCef'));
                                //setDialogVisible(true);
                                dialogState.dialogVisible = true;
                                dialogState.ideCef = context.record.get('ideCef');
                                dialogState.numCef = context.record.get('numCef');
                                setDialogState({ ...dialogState });
                            }
                        },
                        canceledit: function (editor, context) {
                            if (context.record.phantom) {
                                context.editor.grid.store.remove(context.record);
                            }
                        },
                        edit: function (grid, cdsNew) {
                            //controllo validità
                            if ((cdsNew.record.phantom)) {

                                if (cdsNew.record.data.datFinVal != null) {
                                    let dataInizio = new Date(cdsNew.record.data.datIniVal);
                                    let dataFine = new Date(cdsNew.record.data.datFinVal);

                                    if (dataInizio > dataFine) {
                                        Ext.Msg.alert('Errore', 'Attenzione: La data iniziale non può essere successiva alla data finale.', function () {
                                            // Ripristina i dati originali del record modificato nel negozio della griglia
                                            cdsNew.record.reject();
                                        });
                                        return;
                                    }
                                }
                                // nuovo
                                let matchingRecords = storeClienteCdS.queryRecords('numCef', cdsNew.record.data.numCef);
                                if (matchingRecords.length === -1 || (matchingRecords.length === 1 && matchingRecords[0].phantom)) {
                                    let matchingDate = storeClienteCdS.queryRecords('datIniVal', cdsNew.record.data.datIniVal);
                                    if (matchingDate.length === -1 || (matchingDate.length === 1 && matchingDate[0].phantom)) {
                                        let ideLmzMerPcl = null;
                                        if (cdsNew.record.data.sigLmzMerPcl != null) {
                                            ideLmzMerPcl = storeLimitazioneMerci.findRecord("sigLmzMerPcl", cdsNew.record.data.sigLmzMerPcl, 0, false, false, true) != null ? storeLimitazioneMerci.findRecord("sigLmzMerPcl", cdsNew.record.data.sigLmzMerPcl, 0, false, false, true).data.ideLmzMerPcl : null;
                                        }
                                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                        Ext.Ajax.request({
                                            url: 'Clienti/InserisciCertificato',
                                            method: 'POST',
                                            params: {
                                                ideUte: user.ideUte,
                                                ipAddress: "",
                                                ideLic: props.licenza.ideLic,
                                                numCef: cdsNew.record.data.numCef,
                                                desCef: cdsNew.record.data.desCef,
                                                dataInizio: cdsNew.record.data.datIniVal,
                                                dataFine: cdsNew.record.data.datFinVal,
                                                //MP flag: passa 1 se checked, 0 se unchecked
                                                flgSerCmm: cdsNew.record.data.flgSerCmm ? 1 : 0,
                                                ideSer: cdsNew.record.data.ideSer,
                                                desSer: cdsNew.record.data.desSer,
                                                sigLmzMerPcl: cdsNew.record.data.sigLmzMerPcl,
                                                ideLmzMerPcl: ideLmzMerPcl,
                                                desLmzMerPcl: cdsNew.record.data.desLmzMerPcl
                                            },
                                            callback: function (records, success, operation) {
                                                if (success) {
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    //modifica gestione errore
                                                    if (returnObj.resp === "KO") {
                                                        Ext.Msg.alert('Inserimento certificato fallito', returnObj.msgErr)
                                                    }
                                                    else {
                                                        Ext.toast({ message: 'Certificato inserito', timeout: 4000 });
                                                    }
                                                }
                                                else {
                                                    Ext.Msg.alert('Inserimento fallito', "Errore nell'inserimento del CdS");
                                                }

                                                //ricarica l'elenco dei certificati
                                                caricaCdS();
                                            }
                                        })
                                    }
                                    else {
                                        Ext.Msg.alert('Operazione non consentita', 'Non &egrave possibile inserire un certificato con una data inizio validit&aacute gi&aacute esistente.');
                                    }
                                }
                                else {
                                    Ext.Msg.alert('Operazione non consentita', 'Non &egrave possibile inserire un certificato con un numero gi&aacute esistente.');
                                }
                            }
                            else {


                                if (cdsNew.record.data.datFinVal != null) {
                                    let dataInizio = new Date(cdsNew.record.data.datIniVal);
                                    let dataFine = new Date(cdsNew.record.data.datFinVal);

                                    if (dataInizio > dataFine) {
                                        Ext.Msg.alert('Errore', 'Attenzione: La data iniziale non può essere successiva alla data finale.', function () {
                                            // Ripristina i dati originali del record modificato nel negozio della griglia
                                            cdsNew.record.reject();
                                        });
                                        return;
                                    }


                                }

                                // modifica
                                let matchingRecords = storeClienteCdS.queryRecords('numCef', cdsNew.record.data.numCef);
                                if (matchingRecords.length === -1 || (matchingRecords.length === 1 && matchingRecords[0].data.ideCef === cdsNew.record.data.ideCef)) {
                                    let matchingDate = storeClienteCdS.queryRecords('datIniVal', cdsNew.record.data.datIniVal);
                                    if (matchingDate.length === -1 || (matchingDate.length === 1 && matchingDate[0].data.ideCef === cdsNew.record.data.ideCef)) {
                                        let ideLmzMerPcl = null;
                                        if (cdsNew.record.data.sigLmzMerPcl != null) {
                                            ideLmzMerPcl = storeLimitazioneMerci.findRecord("sigLmzMerPcl", cdsNew.record.data.sigLmzMerPcl, 0, false, false, true) != null ? storeLimitazioneMerci.findRecord("sigLmzMerPcl", cdsNew.record.data.sigLmzMerPcl, 0, false, false, true).data.ideLmzMerPcl : null;
                                        }
                                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                        Ext.Ajax.request({
                                            url: 'Clienti/ModificaCertificato',
                                            method: 'POST',
                                            params: {
                                                ideUte: user.ideUte,
                                                ipAddress: "",
                                                ideLic: props.licenza.ideLic,
                                                ideCef: cdsNew.record.data.ideCef,
                                                numCef: cdsNew.record.data.numCef,
                                                desCef: cdsNew.record.data.desCef,
                                                dataInizio: cdsNew.record.data.datIniVal,
                                                dataFine: cdsNew.record.data.datFinVal,
                                                //MP flag: passa 1 se checked, 0 se unchecked
                                                flgSerCmm: cdsNew.record.data.flgSerCmm ? 1 : 0,
                                                ideSer: cdsNew.record.data.ideSer,
                                                desSer: cdsNew.record.data.desSer,
                                                sigLmzMerPcl: cdsNew.record.data.sigLmzMerPcl,
                                                ideLmzMerPcl: ideLmzMerPcl,
                                                desLmzMerPcl: cdsNew.record.data.desLmzMerPcl
                                            },
                                            callback: function (records, success, operation) {
                                                if (success) {
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    //modifica gestione errore
                                                    if (returnObj.resp === "KO") {
                                                        Ext.Msg.alert('Modifica certificato fallito', returnObj.msgErr)
                                                    }
                                                    else {
                                                        Ext.toast({ message: 'Certificato modificato', timeout: 4000 });
                                                    }
                                                }
                                                else {
                                                    Ext.Msg.alert('Modifica fallita', 'Errore nella modifica del CdS');
                                                }

                                                //ricarica l'elenco dei certificati
                                                caricaCdS();
                                            }
                                        })
                                    }
                                    else {
                                        Ext.Msg.alert('Operazione non consentita', 'Non &egrave possibile inserire un certificato con una data inizio validit&aacute gi&aacute esistente.');
                                    }
                                }
                                else {
                                    Ext.Msg.alert('Operazione non consentita', 'Non &egrave possibile inserire un certificato con un numero gi&aacute esistente.');
                                }
                            }

                        }
                    }}
                >
                    <Column text="Numero" dataIndex="numCef" renderer={renderColorRow} editable={true}
                        editor={{ xtype: 'textfield', itemId: 'txtNumCef', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true }}
                    />
                    <Column text="Descrizione" dataIndex="desCef" flex={0.4} filter='string' editor={{ xtype: 'textfield', itemId: 'txtDescrizione' }} />
                    <Column text="Data Inizio Validit&aacute" dataIndex="datIniVal" type="datecolumn" flex={0.35} formatter='date("d/m/Y")' filter='date' editable={true}
                        editor={{ xtype: 'datefield', formatter: 'date("d/m/Y")', triggerAction: 'all', allowBlank: false, itemId: "dataInizio", requiredMessage: "Questo campo non puo' essere vuoto", required: true }}
                    />
                    <Column text="Data Fine Validit&aacute" dataIndex="datFinVal" type="datecolumn" flex={0.35} formatter='date("d/m/Y")' filter='date' editable={true}
                        editor={{ xtype: 'datefield', formatter: 'date("d/m/Y")', triggerAction: 'all', itemId: "dataFine" }}
                    />
                    <Checkcolumn
                        text='Ser.Comm.'
                        width={108}
                        dataIndex='flgSerCmm'
                        listeners={{
                            beforecheckchange: (checkColumn, rowIndex, checked, record, eOpts) => {
                                var rowediting = Ext.ComponentQuery.query('#gridClienteCdS')[0].getPlugin('rowedit');
                                if (rowediting.editing === false) {
                                    return false;
                                }
                            },
                        }}
                    />
                    <Column text="Tipo Servizio" dataIndex="ideSer" width={130} filter='string'
                        renderer={function (value, record, field, metaData) {
                            if (value !== undefined && value !== "") {
                                if (value === 1)
                                    Ext.ComponentQuery.query('#cmdLmzMerPcl')[0].disable();

                                return storeTipoServizio.findRecord("ideSer", value, 0, false, false, true).data.desSer;
                            }
                        }}
                        editable={true}
                        editor={{
                            xtype: 'combobox', options: arrayTipoServizio, itemId: "cmdTipoSer", triggerAction: 'all', mode: 'local', editable: false,
                            listeners: {
                                change: function (combo, value) {
                                    if (value === 1) {
                                        Ext.ComponentQuery.query('#cmdLmzMerPcl')[0].setValue("No");
                                        Ext.ComponentQuery.query('#cmdLmzMerPcl')[0].disable();
                                        Ext.ComponentQuery.query('#txtNoteMP')[0].setValue("");
                                        Ext.ComponentQuery.query('#txtNoteMP')[0].disable();
                                    }
                                    else {
                                        let mp = Ext.ComponentQuery.query('#cmdLmzMerPcl')[0].getValue();

                                        Ext.ComponentQuery.query('#cmdLmzMerPcl')[0].enable();

                                        // imposta il valore di default se manca
                                        if (mp == "")
                                            Ext.ComponentQuery.query('#cmdLmzMerPcl')[0].setValue("No");

                                        if (mp != "No" && mp != "")
                                            Ext.ComponentQuery.query('#txtNoteMP')[0].enable();
                                        else {
                                            Ext.ComponentQuery.query('#txtNoteMP')[0].disable();
                                            Ext.ComponentQuery.query('#txtNoteMP')[0].setValue("");
                                        }
                                    }
                                }
                            }
                        }}
                    />
                    <Column text='MP' width={130} dataIndex='sigLmzMerPcl' filter='string' editable={true}
                        editor={{
                            xtype: 'combobox', options: arrayLimitazioneMerci, itemId: "cmdLmzMerPcl", triggerAction: 'all', mode: 'local', editable: false,
                            listeners: {
                                change: function (combo, value) {
                                    if (value != "No" && value != "") {
                                        Ext.ComponentQuery.query('#txtNoteMP')[0].enable();
                                    }
                                    else {
                                        Ext.ComponentQuery.query('#txtNoteMP')[0].setValue("");
                                        Ext.ComponentQuery.query('#txtNoteMP')[0].disable();
                                    }
                                }
                            }
                        }}
                    />
                    <Column text="Note MP" dataIndex="desLmzMerPcl" flex={1} filter='string' cellWrap={true} editable={true}
                        editor={{ xtype: 'textfield', itemId: 'txtNoteMP', cellWrap: true }} filter='string'
                    />
                    <Column text="PDF" dataIndex="nomiPdf" flex={1} filter='string' editable={false} />
                    <Column itemId='actionDiritti' editable={false} text="Azioni" hidden={isIF}
                        cell={{

                            tools: {

                                modificaCdS: {

                                    handler: onModificaCdSClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-edit",
                                        tooltip: 'Modifica',
                                        disabled: isRFICOMP || isRFICC
                                    }

                                },
                                eliminaCdS: {

                                    handler: onEliminaCdSClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-trash",
                                        tooltip: 'Elimina',
                                        disabled: isRFICOMP || isRFICC
                                    }

                                }
                            }

                        }}

                    />
                </Grid>


                <Dialog
                    displayed={dialogState.dialogVisible}
                    closable
                    title="CdS Files allegati"
                    modal
                    closeAction="hide"
                    maskTapHandler={onCloseDialog}
                    onHide={onCloseDialog}
                    width="600"
                >

                    <CdsFilesDialog ideCef={dialogState.ideCef} numCef={dialogState.numCef} onClose={onCloseDialog} />
                </Dialog>


            </Panel>
        </Container>
    );
}

export default ClienteCdS;