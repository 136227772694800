import React from 'react';
import { Panel, FormPanel, Container, Button, Loadmask, } from '@sencha/ext-react-modern';
import { useState, useContext, useEffect } from "react";
import PoRiepilogo from './PoRiepilogo.js';
import PnlPercorso from './PoPercorso.js';
import PnlPeriodicita from './PoPeriodicita.js';
import PnlImpostazione from './PoImpostazione.js';
import PnlImpegnoImpianto from './PoII';
import PnlLimitazioni from './PoLimitazioni.js';
import { useUser } from '../components/UserContext';
import { VersioneRtoContext } from '../components/VersioneRtoContext';

const Ext = window['Ext'];
let dataAggiornamento = "";

function PoDetail(props) {
    const [state, setState] = useState({ schedePo: [] });
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "" });
    const { user, switchUser } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    const hiddenAggiornaPO = !(props.flgUpdTrc === 1 && (!isIF) && state.schedePo.length > 0 && !props.inEdit && !props.readOnly);
    //const [collapse, setCollapse] = useState(props.panelCollapse.poCollapsed);
  
    const onPanelCollapse = () => {
        props.aggiornaPanelCollapse("PO", true);                 
    }
    const onPanelExpand = () => {
        props.aggiornaPanelCollapse("PO", false);
    }

    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede; 

    const viewDetailPO = () => {
        // condizioni per la visualizzazione del PO agli utenti
        let canViewPO = isIF ? false : true;
        if (isIF) {
            let rto = rtoSchede.data.items[0].data.rto;
            if (rto.ideStoRTO != 8) {
                canViewPO = (rto.ideStoRTO >= 5);  // Progetto orario visibile solo per (IDE_STO_RTO >= 5) per IF
            }
            else if (rto.ideStoRTO == 8)     //visibile alle IF solo se pubblicato
            {
                canViewPO = (rto.flgPUBTrc > 0);
            }
        }
        return canViewPO;
    }
    var viewPO = viewDetailPO();

    const calcolaData = (dataInput) => {

        let dateObj = new Date(dataInput);

        let day = dateObj.getDate();
        let month = dateObj.getMonth() + 1;
        let year = dateObj.getFullYear();
        let hours = dateObj.getHours();
        let minutes = dateObj.getMinutes();
        let seconds = dateObj.getSeconds();

        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        let formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes + ':' + seconds;
        return formattedDate;
    }

    const sortSchedePO = (schedePO) => {
        let sortedSchedePO = [];

        if (schedePO.length > 1) {
            sortedSchedePO = schedePO.sort((a, b) => a.testataPO.nomTreRFI - b.testataPO.nomTreRFI);
        }
        else {
            sortedSchedePO = schedePO;
        }
        return sortedSchedePO;
    }

    const aggiornaState = () => {   
        dataAggiornamento = state.schedePo.length > 0 ? calcolaData(state.schedePo[0].testataPO.datUpd).toString() : "";

        //// ordina le schede per numero treno
        //if (state.schedePo.length > 0) {
        //    let schedeOrdNomTre = sortSchedePO(state.schedePo);
        //    state.schedePo = schedeOrdNomTre;
        //}    
        
        setState({ ...state });
        setStateLoadMask({ hidden: true, message: "" });
 
    }

    const toolHandler = (panel, tool) => {
        if (rtoSchede.data.items[0].data.rto.ideStoRTO === 2 || rtoSchede.data.items[0].data.rto.ideStoRTO === 4) {
                Ext.Ajax.request({
                    url: 'Po/AggiornaDatiTracce',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                    },
                    callback: function (resp, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            // gestione errore
                            if (returnObj.resp === "KO") {
                                Ext.Msg.alert('Aggiornamento fallito', returnObj.msgErr)
                            }
                            else {
                                /* props.aggiornaStateControllo();*/
                                props.RefreshListaPO();
                            }
                        }
                    }

                });
            }
            else {
                Ext.Ajax.request({
                    url: 'Po/AggiornaPO',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                        statoRTO: rtoSchede.data.items[0].data.rto.ideStoRTO,
                        ideTipGrp: user.idTipoGruppo
                    },
                    callback: function (resp, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            // gestione errore
                            if (returnObj.resp === "KO") {
                                Ext.Msg.alert('Aggiornamento fallito', returnObj.msgErr)
                            }
                            else {
                                if (returnObj.ideRto != null)
                                    props.RefreshRtoDaAggiornaPO(returnObj.ideRto, returnObj.ideCli);
                                Ext.Msg.alert('Esito Aggiornamento', returnObj.mess)
                            }
                        }
                    }

                });
            }
        
    }

    useEffect(() => {
        state.schedePo = [];
        
        if (contextVersioneRto.listaPO.length > 0) {
            setStateLoadMask({ hidden: false, message: "Attendere caricamento PO..." });
            for (var i = 0; i < contextVersioneRto.listaPO.length; i++) {
                caricaPO(i)
            }
        }
        else {
            aggiornaState();
        }
    }, [contextVersioneRto.listaPO])

    
   

    const caricaPO = (ind) => {
        let schedaPO = { testataPO: contextVersioneRto.listaPO[ind], schede: null, IIPO: null };

        if (viewPO) {
            Ext.Ajax.request({
                url: 'Po/LeggiProgettoOrario',
                method: 'GET',
                params: { ideUte: user.ideUte, ideTrcOra: contextVersioneRto.listaPO[ind].ideTrcOra },
                callback: function (resp, success, operation) {
                    schedaPO.schede = Ext.decode(operation.responseText); //resp[0].data;
                    Ext.Ajax.request({
                        url: 'Po/GetImpegnoImpiantoTrc',
                        method: 'GET',
                        params: { ideUte: user.ideUte, ideRTOXPO: rtoSchede.data.items[0].data.rto.ideRTO, ideTrc: contextVersioneRto.listaPO[ind].ideTrcOra },
                        callback: function (resp, success, operation) {
                            if (success) {
                                schedaPO.IIPO = Ext.decode(operation.responseText);
                            }
                            //------------------------------
                            state.schedePo.push(schedaPO)

                            if (state.schedePo.length === contextVersioneRto.listaPO.length)
                                aggiornaState();
                            //------------------------------
                        }
                    });

                }

            });
        }
        else {
            //------------------------------
            state.schedePo.push(schedaPO)

            if (state.schedePo.length === contextVersioneRto.listaPO.length)
                aggiornaState();
            //------------------------------
        }
    }
    const title = props.collapsed === true && !isIF && state.schedePo.length ? (`<span style="display:flex; width:100%; justify-content: space-between"> <span >Dettaglio Offerta RFI</span><span style="font-weight:normal; font-style:italic; font-size:12px">PO acquisito il ${dataAggiornamento}</span></span>`): (`<span>Dettaglio Offerta RFI</span>`)
    
    return (

        
    <Panel
            title={title}
            layout="vbox"
            collapsible={{ direction: 'right' }}
            collapsed={props.collapsed}
            scrollable
            flex={1}
            titleCollapse={true}
            onCollapse={onPanelCollapse}
            onExpand={onPanelExpand}
            cls="PnlHeader-PO iconPanelsRTO corsivoNoGrassetto"               
            tools={[
                {
                    iconCls: 'x-fa fa-edit buttonPanelTool', tooltip: 'Modifica PO', type: 'editPo', handler: function (a,b) {
                        props.toolHandler(a,b)
                        props.aggiornaPanelCollapse("PO", false)
                        props.aggiornaPanelCollapse("RTO",true)
                    }, hidden: props.hiddenEditPO
                },
                { iconCls: 'x-fa fa-refresh buttonPanelTool', tooltip: 'AggiornaPO', type: 'agg', handler: toolHandler, hidden: hiddenAggiornaPO }
            ]}
        >
        <Panel cls="container-spinner" scrollable flex={1} layout="vbox">
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
                />
                {state.schedePo.length == contextVersioneRto.listaPO.length &&
                    <PoRiepilogo RefreshRtoDaAggiornaPO={props.RefreshRtoDaAggiornaPO} editPO={props.inEditPO} aggiornaStateControllo={props.aggiornaStateControllo} schedePo={state.schedePo} inEdit={props.inEdit} inEditPO={props.inEditPO} statoRto={rtoSchede.data.items[0].data.rto.ideStoRTO} RefreshListaPO={props.RefreshListaPO} readOnly={props.readOnly} flgUpdTrc={props.flgUpdTrc} />
                }
                {viewPO && contextVersioneRto.listaPO.length > 0 && state.schedePo.length == contextVersioneRto.listaPO.length &&
                    <Container>          
                        <Container>
                            <Panel
                                title="Percorso"
                                scrollable
                                bodyPadding={5}
                                titleCollapse={true}
                                iconCls="x-fa fa-road"
                                collapsible={{ collapsed: false, direction: 'top' }}
                                cls='PnlSectionPoL1 iconPanelsRTO'
                            >
                                {state.schedePo.length > 0 && state.schedePo.map((scheda, index) => (<PnlPercorso key={`popercorso${index}`} ind={index} schedaPo={scheda} numPO={state.schedePo.length} datUpd={scheda.testataPO.datUpd} />))}

                            </Panel>
                        </Container>
                        <Container>
                            <Panel
                                itemId="pnlMainPeriodicitaPO"
                                title="Periodicit&aacute"
                                scrollable
                                bodyPadding={5}
                                titleCollapse={true}
                                iconCls="x-fa fa-calendar custom-icon"
                                collapsible={{ collapsed: false, direction: 'top' }}
                                cls='PnlSectionPoL1 iconPanelsRTO'
                            >
                                {state.schedePo.length > 0 && state.schedePo.map((scheda, index) => (<PnlPeriodicita key={`poperiodicita${index}`} ind={index} schedaPo={scheda} numPO={state.schedePo.length} datUpd={scheda.testataPO.datUpd} />))}

                            </Panel>
                        </Container>
                        <Container>
                            <Panel
                                title="Impostazione"
                                scrollable
                                bodyPadding={5}
                                titleCollapse={true}
                                iconCls="x-fa fa-tachometer-alt"
                                collapsible={{ collapsed: false, direction: 'top' }}
                                cls='PnlSectionPoL1 iconPanelsRTO'
                            >
                                {state.schedePo.length > 0 && state.schedePo.map((scheda, index) => (<PnlImpostazione key={`poimpostazione${index}`} ind={index} schedaPo={scheda} numPO={state.schedePo.length} datUpd={scheda.testataPO.datUpd} />))}

                            </Panel>
                        </Container>
                        <Container>
                            <Panel
                                title="Impegno Impianto"
                                titleCollapse={true}
                                layout="vbox"
                                iconCls="x-fa fa-train-tram "
                                bodyPadding={3}
                                collapsible={{ collapsed: false, direction: 'top' }}
                                cls='PnlSectionPoL1 iconPanelsRTO'
                            >

                                {state.schedePo.length > 0 && state.schedePo.map((scheda, index) => (<PnlImpegnoImpianto key={`poimpegnoimpianto${index}`} ind={index} schedaPo={scheda} datUpd={scheda.testataPO.datUpd} />))}

                            </Panel>
                        </Container>
                        <Container>
                            <Panel
                                title="Limitazioni"
                                scrollable
                                bodyPadding={5}
                                titleCollapse={true}
                                iconCls="x-fa fa-hand-paper"
                                collapsible={{ collapsed: false, direction: 'top' }}
                                cls='PnlSectionPoL1 iconPanelsRTO'
                            >

                                {state.schedePo.length > 0 && state.schedePo.map((scheda, index) => (<PnlLimitazioni key={`polimitazioni${index}`} ind={index} schedaPo={scheda} datUpd={scheda.testataPO.datUpd} />))}

                            </Panel>
                        </Container>
                    </Container>
                }

        </Panel >      
           
    </Panel>
  );
}

export default PoDetail;
