import abiCli_Verde from "./../../images/abiCli_Verde.svg";
import abiCli_Giallo from "./../../images/abiCli_Giallo.svg";
import abiCli_Rosso from "./../../images/abiCli_Rosso.svg";
import abiCli_Blu from "./../../images/abiCli_Blu.svg";
import ii_ko from "./../../images/ii_ko.svg";
import ii_ko_vb from "./../../images/ii_ko_vb.svg";
import ii_ok from "./../../images/ii_ok.svg";
import ii_ok_vb from "./../../images/ii_ok_vb.svg";
import ii_pres from "./../../images/ii_pres.svg";
import ii_pres_vb from "./../../images/ii_pres_vb.svg";
import ii_pub from "./../../images/ii_pub.svg";
import ii_pub_vb from "./../../images/ii_pub_vb.svg";
import circle from "./../../images/circle.svg";
import Warning from "./../../images/Warning.svg";
import WarningRed from "./../../images/WarningRed.svg";
import dayjs from "dayjs";
const svgMap = {
  abiCli_Verde,
  abiCli_Giallo,
  abiCli_Rosso,
  abiCli_Blu,
  ii_ko,
  ii_ko_vb,
  ii_ok,
  ii_ok_vb,
  ii_pres,
  ii_pres_vb,
  ii_pub,
  ii_pub_vb,
  circle,
  Warning,
  WarningRed,
};
function verificaDataValida(data) {
  return dayjs(data).isValid();
}
function formattaDataSeValida(data) {
  if (verificaDataValida(data)) {
    return dayjs(data).format("DD/MM/YYYY");
  } else {
    return "-";
  }
}
export function formattaData(data) {
  const dataFormattata = formattaDataSeValida(data);
  return dataFormattata;
}
export function trasformaSvg(string) {
  if (string === null || string === undefined) {
    return "-";
  }
  const statoTrasformato = string.replace(/\.svg$/, "").replace("-", "_").trim();
  const svgSrc = svgMap[statoTrasformato];
  if (svgSrc) {
    return <img className="icona-svg" src={svgSrc} alt={statoTrasformato} />;
  } else {
    return "-";
  }
}
export function trasformaSvgStato(string, stati) {
  if (string === null || string === undefined) {
      return "-";
  }
  const statoTrasformato = string.replace(/\.svg$/, "").replace("-", "_");
  const statoCorrispondente = stati.find((item) => item.nomeStato === statoTrasformato);
  return statoCorrispondente ? statoCorrispondente.svg : "-";
}

