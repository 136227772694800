import React from 'react';
import { Panel, Container, FormPanel, TextField, Field, DatePickerField, Loadmask } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';

const Ext = window['Ext'];


function ClienteAnagrafica(props) {
    const { user, switchUser } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    var readonly = user.ruolo == 'RFI' ? false : true;

    const [state, setState] = useState({ anag: ('anagrafica' in props) ? props.anagrafica : null });
    const initValues = () => {
        if (props.anagrafica != null) {
            state.anag.datIscCciaa = (props.anagrafica.datIscCciaa !== null && props.anagrafica.datIscCciaa.trim() !== '') ? new Date(props.anagrafica.datIscCciaa).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' }) : ""
            state.anag.datIscRgtIpr = (props.anagrafica.datIscRgtIpr !== null && props.anagrafica.datIscRgtIpr.trim() !== '') ? new Date(props.anagrafica.datIscRgtIpr).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' }) : ""

            setState({ ...state });
        }
    }

    const onModifica = () => {
        Ext.ComponentQuery.query('#buttonModificaAnagrafica')[0].setDisabled(true);
        Ext.ComponentQuery.query('#confermaAnagrafica')[0].setDisabled(false);
        Ext.ComponentQuery.query('#annullaAnagrafica')[0].setDisabled(false);

        //Abilita box
        Ext.ComponentQuery.query('#indirizzoSL')[0].setDisabled(false);
        Ext.ComponentQuery.query('#capSL')[0].setDisabled(false);
        Ext.ComponentQuery.query('#cittaSL')[0].setDisabled(false);
        Ext.ComponentQuery.query('#indirizzoSA')[0].setDisabled(false);
        Ext.ComponentQuery.query('#capSA')[0].setDisabled(false);
        Ext.ComponentQuery.query('#cittaSA')[0].setDisabled(false);
        Ext.ComponentQuery.query('#indirizzoSO')[0].setDisabled(false);
        Ext.ComponentQuery.query('#capSO')[0].setDisabled(false);
        Ext.ComponentQuery.query('#cittaSO')[0].setDisabled(false);
        Ext.ComponentQuery.query('#ragioneSociale')[0].setDisabled(false);
        Ext.ComponentQuery.query('#partitaIva')[0].setDisabled(false);
        Ext.ComponentQuery.query('#cf')[0].setDisabled(false);
        Ext.ComponentQuery.query('#iban')[0].setDisabled(false);
        Ext.ComponentQuery.query('#dataIscrCCIAA')[0].setDisabled(false); 
        Ext.ComponentQuery.query('#numIscrCCIAA')[0].setDisabled(false);
        Ext.ComponentQuery.query('#dataIscrRehImp')[0].setDisabled(false);
        Ext.ComponentQuery.query('#numIscrRegImp')[0].setDisabled(false);
        Ext.ComponentQuery.query('#rappLegale')[0].setDisabled(false);
        Ext.ComponentQuery.query('#pec')[0].setDisabled(false);
    }

    const onConfermaModifica = () => {
        // salvataggio
        let par = {
            ideUte: user.ideUte,
            ipAddress: "",
            ideCliMse: props.cliente.ideCliMse,
            idrLgl: Ext.ComponentQuery.query('#indirizzoSL')[0].getValue(),
            cavLgl: Ext.ComponentQuery.query('#capSL')[0].getValue(),
            ciaLgl: Ext.ComponentQuery.query('#cittaSL')[0].getValue(),
            idrAmv: Ext.ComponentQuery.query('#indirizzoSA')[0].getValue(),
            cavAmv: Ext.ComponentQuery.query('#capSA')[0].getValue(),
            ciaAmv: Ext.ComponentQuery.query('#cittaSA')[0].getValue(),
            idrOpr: Ext.ComponentQuery.query('#indirizzoSO')[0].getValue(),
            cavOpr: Ext.ComponentQuery.query('#capSO')[0].getValue(),
            ciaOpr: Ext.ComponentQuery.query('#cittaSO')[0].getValue(),
            rge: Ext.ComponentQuery.query('#ragioneSociale')[0].getValue(),
            piv: Ext.ComponentQuery.query('#partitaIva')[0].getValue(),
            codFis: Ext.ComponentQuery.query('#cf')[0].getValue(),
            iban: Ext.ComponentQuery.query('#iban')[0].getValue(),
            datIscCciaa: Ext.ComponentQuery.query('#dataIscrCCIAA')[0].getValue() != null ? Ext.ComponentQuery.query('#dataIscrCCIAA')[0].getValue() : null,
            numIscCciaa: Ext.ComponentQuery.query('#numIscrCCIAA')[0].getValue(),
            datIscRgtIpr: Ext.ComponentQuery.query('#dataIscrRehImp')[0].getValue() != null ? Ext.ComponentQuery.query('#dataIscrRehImp')[0].getValue() : null,
            numIscRgtIpr: Ext.ComponentQuery.query('#numIscrRegImp')[0].getValue(),
            rppLgl: Ext.ComponentQuery.query('#rappLegale')[0].getValue(),
            pec: Ext.ComponentQuery.query('#pec')[0].getValue()
        };

        Ext.Ajax.request({
            url: 'Clienti/ModificaAnagraficaCliente',
            method: 'POST',
            params: par,
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);

                    if (returnObj.resp == "OK") {
                        Ext.toast({ message: 'Aggiornamento anagrafica effettuato con successo.', timeout: 4000 });
                        // aggiornamento state con nuovi dati
                        state.anag.idrLgl = par.idrLgl;
                        state.anag.cavLgl = par.cavLgl;
                        state.anag.ciaLgl = par.ciaLgl;
                        state.anag.idrAmv = par.idrAmv;
                        state.anag.cavAmv = par.cavAmv;
                        state.anag.ciaAmv = par.ciaAmv; 
                        state.anag.idrOpr = par.idrOpr;
                        state.anag.cavOpr = par.cavOpr; 
                        state.anag.ciaOpr = par.ciaOpr; 
                        state.anag.rge = par.rge;
                        state.anag.piv = par.piv;
                        state.anag.codFis = par.codFis;
                        state.anag.iban = par.iban; 
                        state.anag.datIscCciaa = par.datIscCciaa;
                        state.anag.numIscCciaa= par.numIscCciaa;
                        state.anag.datIscRgtIpr = par.datIscRgtIpr;
                        state.anag.numIscRgtIpr = par.numIscRgtIpr;
                        state.anag.rppLgl = par.rppLgl;
                        state.anag.pec = par.pec;
                        setState({ ...state });
                        Ext.ComponentQuery.query('#buttonModificaAnagrafica')[0].setDisabled(false);
                        Ext.ComponentQuery.query('#confermaAnagrafica')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#annullaAnagrafica')[0].setDisabled(true);
                        // disabilita box
                        Ext.ComponentQuery.query('#indirizzoSL')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#capSL')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#cittaSL')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#indirizzoSA')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#capSA')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#cittaSA')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#indirizzoSO')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#capSO')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#cittaSO')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#ragioneSociale')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#partitaIva')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#cf')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#iban')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#dataIscrCCIAA')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#numIscrCCIAA')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#dataIscrRehImp')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#numIscrRegImp')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#rappLegale')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#pec')[0].setDisabled(true);
                    }
                    else {
                        Ext.Msg.alert('Modifica fallita', returnObj.msgErr);
                    }
                }
                else {
                    Ext.Msg.alert('Modifica fallita', returnObj.msgErr);
                }
            }
        });

    }

    const onAnnullaModifica = () => {
        Ext.ComponentQuery.query('#buttonModificaAnagrafica')[0].setDisabled(false);
        Ext.ComponentQuery.query('#confermaAnagrafica')[0].setDisabled(true);
        Ext.ComponentQuery.query('#annullaAnagrafica')[0].setDisabled(true);

        Ext.ComponentQuery.query('#indirizzoSL')[0].setValue(state.anag.idrLgl).setDisabled(true);
        Ext.ComponentQuery.query('#capSL')[0].setValue(state.anag.cavLgl).setDisabled(true);
        Ext.ComponentQuery.query('#cittaSL')[0].setValue(state.anag.ciaLgl).setDisabled(true);
        Ext.ComponentQuery.query('#indirizzoSA')[0].setValue(state.anag.idrAmv).setDisabled(true);
        Ext.ComponentQuery.query('#capSA')[0].setValue(state.anag.cavAmv).setDisabled(true);
        Ext.ComponentQuery.query('#cittaSA')[0].setValue(state.anag.ciaAmv).setDisabled(true);
        Ext.ComponentQuery.query('#indirizzoSO')[0].setValue(state.anag.idrOpr).setDisabled(true);
        Ext.ComponentQuery.query('#capSO')[0].setValue(state.anag.cavOpr).setDisabled(true); 
        Ext.ComponentQuery.query('#cittaSO')[0].setValue(state.anag.ciaOpr).setDisabled(true);
        Ext.ComponentQuery.query('#ragioneSociale')[0].setValue(state.anag.rge).setDisabled(true);
        Ext.ComponentQuery.query('#partitaIva')[0].setValue(state.anag.piv).setDisabled(true);
        Ext.ComponentQuery.query('#cf')[0].setValue(state.anag.codFis).setDisabled(true);
        Ext.ComponentQuery.query('#iban')[0].setValue(state.anag.iban).setDisabled(true);
        Ext.ComponentQuery.query('#dataIscrCCIAA')[0].setValue(state.anag.datIscCciaa).setDisabled(true);
        Ext.ComponentQuery.query('#numIscrCCIAA')[0].setValue(state.anag.numIscCciaa).setDisabled(true);
        Ext.ComponentQuery.query('#dataIscrRehImp')[0].setValue(state.anag.datIscRgtIpr).setDisabled(true);
        Ext.ComponentQuery.query('#numIscrRegImp')[0].setValue(state.anag.numIscRgtIpr).setDisabled(true);
        Ext.ComponentQuery.query('#rappLegale')[0].setValue(state.anag.rppLgl).setDisabled(true);
        Ext.ComponentQuery.query('#pec')[0].setValue(state.anag.pec).setDisabled(true);

    }

    return (

        <Panel
            title="Scheda Anagrafica"
            scrollable
            itemId="panelAnagrafica"
            bodyPadding={5}
            titleCollapse={true}
            iconCls="x-fa fa-address-card"
            collapsible={{ collapsed: true, direction: 'top' }}
            cls={(props.cliente.ideTipIpr == 2 ? 'PnlSectionCML1 ' : 'PnlSectionL1 ') + ' iconPanelsRTO'}  
            tools={[
                { iconCls: 'x-fa fa-wrench', tooltip: 'Modifica', hidden: readonly, itemId: "buttonModificaAnagrafica", handler: onModifica.bind(this) },
                { iconCls: 'x-fa fa-check', itemId: "confermaAnagrafica", hidden: readonly, disabled: 'true', handler: onConfermaModifica.bind(this), tooltip: 'Conferma' },
                { iconCls: 'x-fa fa-undo', itemId: "annullaAnagrafica", hidden: readonly, handler: onAnnullaModifica.bind(this), disabled: 'true', tooltip: 'Annulla' }                
            ]}
        >
            <FormPanel >
                <Container flex={1} layout="hbox" >  
                    <Container layout="vbox" margin="4px">                   
                        <Field label="Sede Legale" disabled={true} head="20" labelWidth="145" ></Field>
                        <Field label="Sede Amministrativa" disabled={true} head="20" labelWidth="145" ></Field>
                        <Field label="Sede Operativa" disabled={true} head="20" labelWidth="145" ></Field>                       
                    </Container>
                    <Container flex={0.9} layout="vbox"  >
                        <Container layout="hbox" flex={1} margin="4px">    
                            <TextField label="Indirizzo" itemId="indirizzoSL" labelAlign="left" disabled={true} head="20" labelWidth="70" margin="0 0 0 10" flex={0.36} value={state.anag.idrLgl} />                            
                            <Container layout="hbox" flex={0.5}>
                                <TextField label="CAP" labelAlign="left" itemId="capSL" disabled={true} head="20" labelWidth="40" margin="0 0 0 18" flex={0.25} value={state.anag.cavLgl} />
                                <TextField label="Citt&aacute" labelAlign="left" itemId="cittaSL" disabled={true} head="20" labelWidth="50" margin="0 0 0 15" flex={0.5} value={state.anag.ciaLgl} />
                            </Container>
                        </Container>
                        <Container layout="hbox" margin="4px">                       
                            <TextField label="Indirizzo" itemId="indirizzoSA" labelAlign="left" disabled={true} head="20" labelWidth="70" margin="0 0 0 10" flex={0.36} value={state.anag.idrAmv} />
                            <Container layout="hbox" flex={0.5}>
                                <TextField label="CAP" labelAlign="left" itemId="capSA" disabled={true} head="20" labelWidth="40" margin="0 0 0 18" flex={0.25} value={state.anag.cavAmv} />
                                <TextField label="Citt&aacute" labelAlign="left" itemId="cittaSA" disabled={true} head="20" labelWidth="50" margin="0 0 0 15" flex={0.5} value={state.anag.ciaAmv} />
                            </Container>
                        </Container>
                        <Container layout="hbox" margin="4px">                        
                            <TextField label="Indirizzo" itemId="indirizzoSO" labelAlign="left" disabled={true} head="20" labelWidth="70" margin="0 0 0 10" flex={0.36} value={state.anag.idrOpr} />
                            <Container layout="hbox" flex={0.5}>
                                <TextField label="CAP" labelAlign="left" itemId="capSO" disabled={true} head="20" labelWidth="40" margin="0 0 0 18" flex={0.25} value={state.anag.cavOpr} />
                                <TextField label="Citt&aacute" labelAlign="left" itemId="cittaSO" disabled={true} head="20" labelWidth="50" margin="0 0 0 15" flex={0.5} value={state.anag.ciaOpr} />
                            </Container>
                        </Container>
                    </Container>
                </Container>

                <Container flex={1} layout="vbox">  
                    <TextField label="Ragione Sociale" clearable={false} itemId="ragioneSociale" labelAlign="left" disabled={true} head="20" flex={1} labelWidth="145" margin="4px" value={state.anag.rge} />
                    <Container flex={1} layout="hbox" margin="4px">
                        <Container layout="hbox" flex={0.50}>
                            <TextField label="Partita IVA" labelAlign="left" itemId="partitaIva" disabled={true} head="20" labelWidth="145" flex={0.5} value={state.anag.piv} />
                            <TextField label="CF" labelAlign="left" itemId="cf" disabled={true} head="20" labelWidth="120" margin="0 0 0 10" flex={0.48} value={state.anag.codFis} />
                        </Container>
                        <Container layout="hbox" flex={0.50} >
                            <TextField label="IBAN" labelAlign="left" itemId="iban" disabled={true} head="20" labelWidth="130" flex={1} value={state.anag.iban} />                            
                        </Container>
                    </Container>                   
                    <Container flex={1} layout="hbox" margin="4px">
                        <Container layout="hbox" flex={0.50}>                            
                            <DatePickerField                                
                                label="Data Iscr.CCIAA"
                                labelWidth="145"
                                flex={0.5}
                                itemId="dataIscrCCIAA"
                                labelAlign="left"
                                disabled={true}                                                            
                                destroyPickerOnHide
                                picker={{
                                    yearFrom: 1990
                                }}
                                value={state.anag.datIscCciaa}
                            />
                            <TextField label="Num.Iscr.CCIAA" labelAlign="left" itemId="numIscrCCIAA" disabled={true} head="20" labelWidth="120" margin="0 0 0 10" flex={0.48} value={state.anag.numIscCciaa} />
                        </Container>
                        <Container layout="hbox" flex={0.50}>
                            <DatePickerField
                                label="Data Iscr.Reg.Imp"
                                labelWidth="130"
                                flex={0.5}
                                itemId="dataIscrRehImp"
                                labelAlign="left"
                                disabled={true}
                                destroyPickerOnHide
                                picker={{
                                    yearFrom: 1990
                                }}
                                value={state.anag.datIscRgtIpr}
                            />
                            <TextField label="Num.Iscr.Reg.Imp." labelAlign="left" itemId="numIscrRegImp" disabled={true} head="20" labelWidth="130" margin="0 0 0 15" flex={0.5} value={state.anag.numIscRgtIpr} />
                        </Container>
                    </Container>   
                    <Container flex={1} layout="hbox" margin="4px">
                        <Container flex={0.49} layout="hbox" > 
                            <TextField label="Rappresentante Legale" labelAlign="left" itemId="rappLegale" disabled={true} head="20" labelWidth="145" flex={1} value={state.anag.rppLgl} />        
                        </Container>   
                        <Container flex={0.51} layout="hbox" >                        
                            <TextField label="PEC" labelAlign="left" itemId="pec" disabled={true} head="20" labelWidth="130" margin="0 0 0 15" flex={1} value={state.anag.pec} />
                        </Container>   
                    </Container>   
                </Container>

               
                
                
            </FormPanel>

        </Panel>
    );
}

export default ClienteAnagrafica;