import React, { useEffect, useState } from 'react';
import { useUser } from '../components/UserContext';
import Dashboard from './Dashboard';
import { Panel, Container, Combobox, Button, Titlebar, Loadmask } from '@sencha/ext-react-modern';
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';
import { useNavigate } from 'react-router-dom';

const Ext = window['Ext'];

const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storePeriodoRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiePeriodicita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

function DashboardDataFetcher({ onChartDataObjectChange }) {

    const { user } = useUser();
    const { ambiente, updateAmbiente } = useAmbiente();
    const { periodi, loadPeriodi } = usePeriodi();

    const ideUte = user.ideUte;
    const ideGrp = user.idGruppo;
    const [state, setState] = useState({ firstOnSelectComboPF: true });

    const [chartOneData, setChartOneData] = useState(null);
    const [chartTwoData, setChartTwoData] = useState(null);
    const [chartThreeData, setChartThreeData] = useState(null);
    const [chartFourData, setChartFourData] = useState(null);
    const [chartFiveData, setChartFiveData] = useState(null);
    const [chartSixData, setChartSixData] = useState(null);
    const [chartSevenData, setChartSevenData] = useState(null);

    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "" });


    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    

    //----------------- ricarica i periodi se il contest � vuoto -------------------------------------------
    const caricaPeriodi = () => {               
        loadPeriodi().then(
        function (result) {
                storePeriodiFerroviari.setData(periodi.periodiFerroviari);
                storePeriodoRTO.setData(periodi.periodoRTO);
            }
        );
    } 
    //---------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (periodi.periodiFerroviari.length <= 0 && periodi.periodoRTO.length <= 0) {
            caricaPeriodi();
        }        
    }, []);
    storePeriodiFerroviari.setData(periodi.periodiFerroviari);
    storePeriodoRTO.setData(periodi.periodoRTO);

    //const caricaPeriodiFerroviari = () => {
    //    storePeriodiFerroviari.load({
    //    })
    //}
    //const caricaPeriodoRTO = () => {
    //    storePeriodoRTO.load({
    //    })
    //}

    let title = "Dashboard";

    //useEffect(() => {
    //    caricaPeriodiFerroviari();
    //    caricaPeriodoRTO();
    //}, []);

    useEffect(() => {
        if (ambiente?.ideUte && ambiente?.ideAnnFro > 0 && state.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente(ambiente.ideAnnFro, ambiente.ideTipPer);
        }
    }, [ambiente.ideAnnFro, ambiente.ideTipPer]);

    const initComboAmbiente = (ideAnnFro, ideTipPer) => {
        if (ideAnnFro > 0 && state.firstOnSelectComboPF) {
            /*Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ambiente.ideAnnFro);*/
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value = ideAnnFro;

            if (ideTipPer >= 0) {
                Ext.ComponentQuery.query('#comboPeriodoRto')[0]._value = ideTipPer;
            }

            fetchDataGraph(ambiente.ideAnnFro, ambiente.ideTipPer);

            state.firstOnSelectComboPF = false;
            setState({ ...state });
        }
    };

    // -------------- caricamento dati ----------------------------
    const fetchDataGraph = async (ideAnnFro, ideTipPer) => {
        if (!ideAnnFro) return;

        setStateLoadMask({ hidden: false, message: "" });

        try {
            const requests = [
                fetchChartData('HomePage/TotaleRTOPerTipo', { ideUte, ideGrp, ideAnnFro, ideTipPer }),
                fetchChartData('HomePage/AndamentoRTOPerTipo', { ideUte, ideGrp }),
                fetchChartData('HomePage/TotaleRTOPerStato', { ideUte, ideGrp, ideAnnFro, ideTipPer }),
                fetchChartData('HomePage/RtoPerStatoImpegnoImpianto', { ideUte, ideGrp, ideAnnFro, ideTipPer }),
                fetchChartData('HomePage/StatoLavorazioneImpegnoImpiantoSintesi', { ideUte, ideGrp, ideAnnFro, ideTipPer }),
                fetchChartData('HomePage/RichiesteImpegnoImpiantoSintesi', { ideUte, ideGrp, ideAnnFro, ideTipPer }),
                fetchChartData('HomePage/SintesiServiziRichiesti', { ideUte, ideGrp, ideAnnFro, ideTipPer }),
            ];

            const responses = await Promise.all(requests);

            setChartOneData(responses[0]);
            setChartTwoData(responses[1]);
            setChartThreeData(responses[2]);
            setChartSevenData(responses[3]);
            setChartFourData(responses[4]);
            setChartFiveData(responses[5]);
            setChartSixData(responses[6]);
        } catch (error) {
            console.error('Errore durante la richiesta dei dati', error);
        } finally {
            setStateLoadMask({ hidden: true, message: "Attendere caricamento in corso..." });
        }
    };

    const fetchChartData = (url, params) => {
        return new Promise((resolve, reject) => {
            Ext.Ajax.request({
                url,
                method: 'get',
                params,
                callback: function (records, success, operation) {
                    if (success) {
                        resolve(Ext.decode(operation.responseText));
                    } else {
                        reject(new Error('Errore durante la richiesta dei dati'));
                    }
                }
            });
        });
    };


    // -------------- --------------------------------------------
    const aggiornaStateAmbiente = () => {
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        stateAmbiente.ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : -1;
        setStateAmbiente({ ...stateAmbiente });

        fetchDataGraph(stateAmbiente.ideAnnFro, stateAmbiente.ideTipPer);
    }


    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!state.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null ) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente Dashboard SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente Dashboard SPF ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
        //----------------------------------------------------------------
    }
    function onSelectPeriodoRto(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo rto
        if (!state.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoRto')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoRto')[0] != null) {
                let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                if (valTipPer != stateAmbiente.ideTipPer) {
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente Dashboard SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente Dashboard SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
        //----------------------------------------------------------------
    }
    function onClearPeriodoRto() {
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: null }).then(
            function (result) {
                aggiornaStateAmbiente();
                //console.log('sono in updateAmbiente Dashboard CPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                //console.log('sono in updateAmbiente Dashboard CPR ideTipPer = ', -1);
            }
        );
    }
    const DOCUMENT_URL = "/Documento";
    const getDocumento = (idPag) => {
        window.open(DOCUMENT_URL + "?idPag=" + idPag, '_blank');
    };

    return (
        <Container height="100%" width="100%" layout="fit">
            <Loadmask cls="custom-grid" itemId="loadMaskDashboard" hidden={stateLoadMask.hidden}
                indicator={false}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />

            <Panel layout="vbox" scrollable="true">


                <Titlebar title={title} margin='3 0 0 0'
                    docked="top" layout="hbox"
                    cls={`title-text ${user.ruolo === 'IFDISAB' ? 'hidden' : ''}`} flex={1}>
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-home" />
                    <Button align="right" cls="title-icon" iconCls="x-fa fa-circle-question" handler={() => getDocumento(1)} />
                    <Combobox
                        itemId="comboPeriodoFerroviario"
                        width={170}
                        margin='0 10 0 0'
                        align="right"
                        editable="false"
                        store={storePeriodiFerroviari}
                        displayField="desAnnFro"
                        queryMode="local"
                        valueField="ideAnnFro"
                        onSelect={onSelectPeriodoFerroviario}
                        onChange={onSelectPeriodoFerroviario}
                    />

                    <Combobox
                        itemId="comboPeriodoRto"
                        width={150}
                        docked="right"
                        align="right"
                        editable="false"
                        clearable
                        store={storePeriodoRTO}
                        displayField="desTipPer"
                        valueField="ideTipPer"
                        queryMode="local"
                        onSelect={onSelectPeriodoRto}
                        onChange={onSelectPeriodoRto}
                        onClearicontap={onClearPeriodoRto}
                    />
                </Titlebar>


                {user.ruolo !== "IFDISAB" && (
                    <Dashboard
                        periodoFerroviario={stateAmbiente.ideAnnFro}
                        chartOneData={chartOneData}
                        chartTwoData={chartTwoData}
                        chartThreeData={chartThreeData}
                        chartFourData={chartFourData}
                        chartFiveData={chartFiveData}
                        chartSixData={chartSixData}
                        chartSevenData={chartSevenData}
                    />

                )}
            </Panel>

        </Container>

    );



}

export default DashboardDataFetcher;