import React from 'react';
import { Panel, Container, Button, Toolbar, Loadmask, Spacer} from '@sencha/ext-react-modern';

const Ext = window['Ext'];

function RtoPopupModifiche(props) {

    const onChiudiDettaglio = () => {        
        props.chiudiPopup();
    }
    const onAccettaModifica = () => {
        props.accettaModificaRto(props.data);
    }
    const onRifiutaModifica = () => {
        props.rifiutaModificaRto(props.data);
    }
    
    return (
       
        <Container cls="container-spinner" layout="fit" >
            <Panel
                title="Dettaglio Modifica"             
                layout="vbox"    
                scrollable={true}
            >          
                <Panel
                    scrollable={true}
                    style={{ whiteSpace: 'pre-wrap', padding: '10px', border: '1px solid #ccc' }}
                    html={props.htmlTesto}
                />             

            </Panel>
            <Toolbar docked="bottom" border={true} height={40}>
                <Panel docked="right">
                    <Button ui="action" align="right" hidden={!props.data.flgAczVes} itemId="btnAccettaMod" iconCls="x-fa fa-square-check" text="Accetta" handler={onAccettaModifica} />
                    <Button ui="action" align="right" hidden={!props.data.flgAczVes} margin="5 10 5 5" itemId="btnRifiutaMod" iconCls="x-fa fa-square-check" text="Rifiuta" handler={onRifiutaModifica} />    
                    <Button cls="appButton" ui="action" margin="5 10 5 10" align="right" itemId="btnChiudiRTO" iconCls="x-fa fa-square-check" text="Chiudi" handler={onChiudiDettaglio} />
                </Panel>
            </Toolbar>
        </Container>

    )
}
export default RtoPopupModifiche;
