import React from "react";
import {
  Panel,
  Container,
  Loadmask,
  Label,
  //   TextAreaField,
  //   FormPanel,
  Button,
} from "@sencha/ext-react-modern";
import { useState, useEffect } from "react";
// import newsIFModel from "../models/ComunicazioniIFModel.js";
import { useUser } from "../components/UserContext";
// import ComunicazioniIFModel from "../models/ComunicazioniIFModel.js";
import { useAmbiente } from "../components/AmbienteContext";
import { useNavigate } from "react-router-dom";
const Ext = window["Ext"];

function HomeNews() {
    const [stateLoadMask, setStateLoadMask] = useState({
        hidden: false,
        message: "Attendere caricamento comunicazioni in corso...",
    });
    const [notifiche, setNotifiche] = useState([]);
    const [titoloNewsFirst, setTitoloNewsFirst] = useState("");
    const [titoloNewsSecond, setTitoloNewsSecond] = useState("");
    const { ambiente } = useAmbiente();
    const { user, switchUser } = useUser();
    const redCss =
        "custom-home-button-notification  custom-home-red-button-notification";
    const blueCss =
        "custom-home-button-notification  custom-home-blue-button-notification";
    const greenCss =
        "custom-home-button-notification  custom-home-green-button-notification";
    const yellowCss =
        "custom-home-button-notification  custom-home-yellow-button-notification ";

    let navigate = useNavigate();
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    //   const caricaNews = () => {
    //     Ext.Ajax.request({
    //       url: "HomePage/NotificheHome",
    //       method: "GET",
    //       params: {
    //         ideUte: ambiente?.ideUte,
    //         ideAnnFro: ambiente?.ideAnnFro,
    //         ideTipPer: ambiente?.ideTipPer,
    //         utenteIF: isIF,
    //       },
    //       callback: function (records, success, operation) {
    //         var returnObj = Ext.decode(operation.responseText);
    //         setTitoloNewsFirst(returnObj.rangeFirst);
    //         setTitoloNewsSecond(returnObj.rangeSecond);
    //         setStateLoadMask({
    //           hidden: true,
    //           message: "Caricamento completato...",
    //         });
    //       },
    //     });
    //   };

    useEffect(() => {
        async function fetchData() {
            try {
                setStateLoadMask({
                    hidden: false,
                    message: "Attendere caricamento comunicazioni in corso...",
                });
                if (ambiente?.ideUte && ambiente?.ideAnnFro) {
                    await Ext.Ajax.request({
                        url: "HomePage/Notifiche",
                        method: "GET",
                        params: {
                            ideUte: ambiente?.ideUte,
                            ideAnnFro: ambiente?.ideAnnFro,
                            ideTipPer: ambiente?.ideTipPer,
                        },
                        timeout: 300000,
                        callback: function (records, success, operation) {
                            if (success && records != null) {
                                setNotifiche(Ext.decode(operation.responseText));
                                //console.log("RISULTATI", Ext.decode(operation.responseText));
                            } else {
                                console.error("Nessun dato presente:", success, records);
                            }
                        },
                    });
                    await Ext.Ajax.request({
                        url: "HomePage/NotificheHome",
                        method: "GET",
                        params: {
                            ideUte: ambiente?.ideUte,
                            ideAnnFro: ambiente?.ideAnnFro,
                            ideTipPer: ambiente?.ideTipPer,
                            utenteIF: isIF,
                        },                        
                        timeout: 300000,
                        callback: function (_, __, operation) {
                            var returnObj = Ext.decode(operation.responseText);
                            setTitoloNewsFirst(returnObj.rangeFirst);
                            setTitoloNewsSecond(returnObj.rangeSecond);
                        },
                    });
                    setStateLoadMask({
                        hidden: true,
                        message: "Caricamento completato...",
                    });
                } else {
                    setStateLoadMask({
                        hidden: true,
                        message: "Caricamento completato...",
                    });
                    //console.log("Parametri di ambiente null", ambiente);
                }
            } catch (error) {
                setStateLoadMask({
                    hidden: true,
                    message: "Caricamento completato...",
                });
                console.error("Errore durante il recupero delle notifiche:", error);
            }
        }
        fetchData();
    }, [ambiente]);
 
    const IsAmbiente = ambiente?.ideUte && ambiente?.ideAnnFro;
    const { idTipoDominio } = user;
    const gotoPage = (text) => {
        const params = {
            ideTipPer: ambiente?.ideTipPer,
            ideAnnoFer: ambiente?.ideAnnFro,
            pagina: text,
        };
        navigate(`/MainRto`, { state: params });
    };
    const gotoCliente = () => {
        navigate(`/Cliente`);
    };
    const NotificationButton = ({ count, cls, iconCls, page, name }) => {
        //function formatCount(count) { //se in futuro si vorranno le migliaia col k
        //    if (count >= 1000) {
        //        // Dividi per 1000, arrotonda all'intero e aggiungi "K"
        //        return Math.round(count / 1000) + "K";
        //    } else {
        //        // Restituisci il numero originale se  inferiore a 1000
        //        return count?.toString();
        //    }
        //}      text={formatCount(count)}
        return (
            <Button
                text={count?.toString()}
                cls={cls}
                iconCls={iconCls}
                handler={() => gotoPage(page)}
                margin="0"
                align="right"
            //tooltip={name}
            />
        );
    };
    const getbuttonScadenzaCdSClass = (numberString) => {
        if (
            numberString == null ||
            numberString === undefined ||
            numberString === ""
        ) {
            return null;
        }
        let parsed;
        let value;
        if (isNaN(numberString)) {
            parsed = parseFloat(numberString.replace(/k/i, "") || "0");
            const isK = /k$/i.test(numberString.trim());
            value = isK ? parsed * 1000 : parsed;
        } else {
            parsed = parseFloat(numberString);
            value = parsed;
        }

        if (value >= 0) {
            return redCss;
        } else if (value >= -60 && parsed < 0) {
            return blueCss;
        } else {
            return null;
        }
    };

    // -----------------------------------------------------------------------
    const CNT_CDS = "Scadenza CdS";
    const CNT_TSM_NTF = "RTO trasmesse da IF";
    const CNT_MOD_NTF = "RTO modificate (in attesa di accettazione)";
    const CNT_SCD_NTF = "RTO in scadenza";
    const CNT_UPD_NTF = "RTO aggiornate";
    const CNT_RDI_ACZ = "RTO accettate da RDI";
    const CNT_RDI_RIF = "RTO rifiutate da RDI";
    const CNT_RDI_INU = "RTO incongruenti con PO";
    const CNT_TSM_ACZ = "RTO trasmesse (con valutazione I.I. OK)";
    const CNT_TSM_ATT = "RTO trasmesse (in attesa di valutazione I.I.)";
    const CNT_TSM_RIF = "RTO trasmesse (con valutazione I.I. KO)";
    const CNT_TSM_INU = "RTO trasmesse (con PO incongruente)";
    const CNT_TSM_BLU = "RTO trasmesse (valutazione I.I. non necessaria)";
    // -----------------------------------------------------------------------

    const inScadenzaDiv = IsAmbiente ? (
        idTipoDominio === 1 ? (
            <Container layout="hbox" cls="gridNotifiche">
                <NotificationButton
                  count={notifiche?.cnT_SCD_NTF}
                  cls={redCss}
                  iconCls="x-far fa-calendar-check"
                  page={CNT_SCD_NTF}
                  name={CNT_SCD_NTF}
                  funzione={gotoPage}
                  isHome={true}
                />
                <Label
                  cls="labelNotifiche"
                  disabled={true}
                  clearable={false}
                  html={CNT_SCD_NTF}
                />
            </Container>
    ) : idTipoDominio === 2 ? (
                <Container layout="hbox" cls="gridNotifiche">  
                <NotificationButton
                    count={notifiche?.cnT_SCD_NTF}
                    cls={redCss}
                    iconCls="x-far fa-calendar-check"
                    page={CNT_SCD_NTF}
                    name={CNT_SCD_NTF}
                    funzione={gotoPage}
                />
                <Label
                    cls="labelNotifiche"
                    disabled={true}
                    clearable={false}
                    html={CNT_SCD_NTF}
                />
            </Container>
    ) : (
      <></>
    )
  ) : (
    <></>
    );

  const notificheDiv = IsAmbiente ? (
    idTipoDominio === 1 ? (             //Rfi
      <>
        <Container layout="hbox" cls="gridNotifiche">
          <NotificationButton
            count={notifiche?.cnT_TSM_NTF}
            cls={blueCss}
            iconCls="x-fa fa-arrow-right-to-bracket"
            page={CNT_TSM_NTF}
            name={CNT_TSM_NTF}
            funzione={gotoPage}
          />
          <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_TSM_NTF}
          />
        </Container>
        <Container layout="hbox" cls="gridNotifiche">
          <NotificationButton
            count={notifiche?.cnT_MOD_NTF}
            cls={blueCss}
            iconCls="x-fa fa-pen-to-square"
            page={CNT_MOD_NTF}
            name={CNT_MOD_NTF}
            funzione={gotoPage}
          />
        <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_MOD_NTF}
         />
        </Container>
        <Container layout="hbox" cls="gridNotifiche">
          <NotificationButton
            count={notifiche?.cnT_RDI_ACZ}
            cls={greenCss}
            iconCls="x-fa fa-train-tram"
            page={CNT_RDI_ACZ}
            name={CNT_RDI_ACZ}
            funzione={gotoPage}
          />
          <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_RDI_ACZ}
          />
        </Container>
        <Container layout="hbox" cls="gridNotifiche">
        <NotificationButton
            count={notifiche?.cnT_RDI_RIF}
            cls={redCss}
            iconCls="x-fa fa-train-tram"
            page={CNT_RDI_RIF}
            name={CNT_RDI_RIF}
            funzione={gotoPage}
          />
        <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_RDI_RIF}
          />
        </Container>
        <Container layout="hbox" cls="gridNotifiche">
          <NotificationButton
            count={notifiche?.cnT_RDI_INU}
            cls={redCss}
            iconCls="x-fas fa-triangle-exclamation"
            page={CNT_RDI_INU}
            name={CNT_RDI_INU}
            funzione={gotoPage}
            />
          <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_RDI_INU}
          />
        </Container>
      </>
    ) : idTipoDominio === 2 ? (
      <>
        <Container layout="hbox" cls="gridNotifiche">
          <NotificationButton
            count={notifiche?.cnT_TSM_ACZ}
            cls={greenCss}
            iconCls="x-fa fa-envelope"
            page={CNT_TSM_ACZ}
            name={CNT_TSM_ACZ}
            funzione={gotoPage}
          />
          <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_TSM_ACZ}
          />
        </Container>
        <Container layout="hbox" cls="gridNotifiche">
          <NotificationButton
            count={notifiche?.cnT_TSM_ATT}
            cls={yellowCss}
            iconCls="x-fa fa-envelope"
            page={CNT_TSM_ATT}
            name={CNT_TSM_ATT}
            funzione={gotoPage}
          />
          <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_TSM_ATT}
          />
        </Container>
        <Container layout="hbox" cls="gridNotifiche">
          <NotificationButton
            count={notifiche?.cnT_TSM_RIF}
            cls={redCss}
            iconCls="x-fa fa-envelope"
            page={CNT_TSM_RIF}
            name={CNT_TSM_RIF}
            funzione={gotoPage}
          />
          <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_TSM_RIF}
          />
        </Container>
        <Container layout="hbox" cls="gridNotifiche">
            <NotificationButton
                count={notifiche?.cnT_TSM_BLU}
                cls={blueCss}
                iconCls="x-fas fa-envelope"
                page={CNT_TSM_BLU}
                name={CNT_TSM_BLU}
                funzione={gotoPage}
            />
            <Label
                cls="labelNotifiche"
                disabled={true}
                clearable={false}
                html={CNT_TSM_BLU}
            />
        </Container>
        <Container layout="hbox" cls="gridNotifiche">
          <NotificationButton
            count={notifiche?.cnT_TSM_INU}
            cls={redCss}
            iconCls="x-fas fa-triangle-exclamation"
            page={CNT_TSM_INU}
            name={CNT_TSM_INU}
            funzione={gotoPage}
          />
          <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_TSM_INU}
          />
        </Container>
        <Container layout="hbox" cls="gridNotifiche">
          <NotificationButton
            count={notifiche?.cnT_MOD_NTF}
            cls={blueCss}
            iconCls="x-fa fa-pen-to-square"
            page={CNT_MOD_NTF}
            name={CNT_MOD_NTF}
            funzione={gotoPage}
          />
          <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_MOD_NTF}
          />
        </Container>
        <Container layout="hbox" cls="gridNotifiche">
          <NotificationButton
            count={notifiche?.cnT_UPD_NTF}
            cls={blueCss}
            iconCls="x-fas fa-arrows-rotate"
            page={CNT_UPD_NTF}
            name={CNT_UPD_NTF}
            funzione={gotoPage}
          />
          <Label
            cls="labelNotifiche"
            disabled={true}
            clearable={false}
            html={CNT_UPD_NTF}
          />
        </Container>
        {getbuttonScadenzaCdSClass(notifiche?.cnT_CDS) != null && (
        <Container layout="hbox" cls="gridNotifiche">
            <Button
              text={notifiche?.cnT_CDS}
              cls={getbuttonScadenzaCdSClass(notifiche?.cnT_CDS)}
              iconCls="x-fa fa-certificate"
              handler={() => gotoCliente()}
              margin="0"
              align="right"
              tooltip={CNT_CDS}
            ></Button>
            <Label
                cls="labelNotifiche"
                disabled={true}
                clearable={false}
                html={CNT_CDS}
            />
          </Container>
        )}
      </>
    ) : (
      <></>
    )
  ) : (
    <></>
  );
  return (
    <Container
      cls="container-spinner"
      layout="vbox"
      width={"100%"}
      padding="10"
    >
      <Loadmask
        cls="custom-grid"
        itemId="loadMaskComunicazioni"
        hidden={stateLoadMask.hidden}
        indicator={true}
        message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
        modal={true}
      />
      <Panel
        cls="containerNotificheHome"
        minHeight={120}
        title={titoloNewsFirst}
      >
        <Container style={{ display: "flex", width: "100%" }}>
          {inScadenzaDiv}
          {/* <Label
            cls="altezzaNews"
            flex={1}
            disabled={true}
            clearable={false}
            html="pippo"
          /> */}
        </Container>
      </Panel>
      <Panel cls="containerNotificheHome" flex="1" title={titoloNewsSecond}>
          <Container style={{ display: "flex", width: "100%" }}>
          {notificheDiv}
          {/* <Label
            cls="altezzaNews"
            flex={1}
            disabled={true}
            clearable={false}
            html={notificheSecondString}
          /> */}
        </Container>
      </Panel>
    </Container>
  );
}

export default HomeNews;
