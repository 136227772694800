import { colorMapping } from "./Costanti";
const colorizeCell = (dayIndex, periodicita) => {
    const periodicitaValue = periodicita.charAt(dayIndex);
    return colorMapping[periodicitaValue];
};
export function fixPeriodicita(sourceString, positionToMove, positionToPlaceAfter) {
    if (positionToMove < 0 || positionToMove >= sourceString.length ||
        positionToPlaceAfter < 0 || positionToPlaceAfter >= sourceString.length) {
        return sourceString;
    }
    const sourceArray = sourceString.split('');
    const characterToMove = sourceArray[positionToMove];
    sourceArray.splice(positionToMove, 1);
    sourceArray.splice(positionToPlaceAfter, 0, characterToMove);
    sourceArray.splice(7, 0, '_');
    return sourceArray.join('');
}
export const getClassAndStyle = (day, index, periodicita) => {
    let className = '';
    let style = {};
    if (day === '' || day === 'Svg' || day === 'Giorni') {
        className = 'empty-day';
    } else {
        className = 'weekday-cell';
        style.backgroundColor = day === 'Svg' ? 'transparent' : day === 'Action' ? 'transparent' : colorizeCell(index, periodicita);
        style.borderColor = day === 'Svg' ? 'transparent' : day === 'Action' ? 'transparent' : colorizeCell(index, periodicita);
    }

    return { className, style };
};