const Ext = window['Ext'];

export default Ext.define('LottoModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'desLtx', type: 'string' },
            { name: 'ideAnnFro', type: 'int' },
            { name: 'desAnnFro', type: 'string' },
            { name: 'numRto', type: 'int' },
            { name: 'nomCli', type: 'string' },
            { name: 'ideLtx', type: 'auto' },
            { name: 'nomLtx', type: 'string' }             
        ]
});
