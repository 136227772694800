import React from 'react';
import { Container, Button, ToolTip, Menu, MenuItem } from '@sencha/ext-react-modern';

import { useNavigate } from "react-router-dom";
import { useUser } from '../components/UserContext';


const Ext = window["Ext"];

function LoggedInUser() {

    const { user, switchUser } = useUser();
    let navigate = useNavigate();

    function leggiCookieLoggedInH(cookieName) {
        const cookieString = RegExp(cookieName + "=[^;]+").exec(document.cookie);
        //const cookies = cookieString.split(';');
        return decodeURIComponent(
            !!cookieString ? cookieString.toString().replace(/^[^=]+./, "") : ""
        );
    }

    const handleLogOut = () => {
        Ext.Msg.confirm(
            "Conferma disconnessione",
            "L'utente verrà disconnesso. Si vuole procedere?",
            function (button) {
                if (button === "yes") {
                    const contenutoCookie = leggiCookieLoggedInH("AstroIFCookie");
                    if (contenutoCookie) {
                        Ext.Ajax.request({
                            url: "InfoUser/DelCookie",
                            method: "POST",
                            params: {
                                nomeCookie: "AstroIFCookie",
                            },
                            callback: function (records, success, operation) {
                                //console.log("success DelCookie:", success);
                                //console.log("records DelCookie:", records);
                                if (success) {
                                    console.log("Cookie cancellato:");
                                    switchUser(null);
                                    navigate("/");
                                }
                            },
                        });
                    } else {
                        switchUser(null);
                        navigate("/");
                    }
                }
            }
        );
    };

    const userContent = `<div class="text-container"><div class="nomeUtente">${user.nome.toUpperCase()} ${user.cognome.toUpperCase()}</div><div class="ruoloUtente">${user.descrizioneGruppo}</div></div>`

    if (!user) {
        return (
            <ToolTip showOnTap align="tl-tr" anchorToTarget anchor>
                <ul style={{ marginBottom: '15px' }}>
                    <li>Nessun utente loggato:</li>
                </ul>
            </ToolTip>);
    }
    else {
        return (
            <Container cls="userDiv">
                <Button cls="userIcon" align="right" iconCls="x-fa fa-user" text={userContent} disabled={true} />
                <Button 
                    align="right"
                    cls="logout-button"
                    margin="0 5 0 0"
                    iconCls="x-fa fa-door-open"
                    handler={handleLogOut}
                    tooltip="Esci"  />                   
             
                {/*<Button  align="right" iconCls="x-fa fa-user"  >*/}
                {/*    <Menu>*/}
                {/*        <MenuItem text="Logout" iconCls=" x-fa fa-door-open" handler={handleLogOut} />*/}
                {/*        <MenuItem text="Settings" iconCls="x-fa fa-cog" />*/}
                {/*        <MenuItem text="Help" iconCls="x-fa fa-question-circle" />*/}
                {/*    </Menu>*/}
                {/*</Button>*/}
            </Container >
        );
    }
   
}

export default LoggedInUser;
