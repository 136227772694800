const Ext = window['Ext'];

export default Ext.define('PeriodoFerroviarioModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideAnnFro', type: 'int' },
            { name: 'desAnnFro', type: 'string' }
        ]
});
