import React from 'react';
import "./Style.css";
const Stazioni = ({ stazPartenza, stazArrivo, colore }) => {
  return (
    <div className="svg-container">
      <svg xmlns="http://www.w3.org/2000/svg" className="svg-image" viewBox="0 0 27 82">
        <line id="Line_724" data-name="Line 724" y2="58.424" transform="translate(13.5 13.5)" fill="none" stroke={colore} strokeWidth="4" />
        <g id="Ellipse_188" data-name="Ellipse 188" fill={colore} stroke={colore} strokeWidth="1">
          <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
          <circle cx="13.5" cy="13.5" r="13" fill="none" />
        </g>
        <g id="Ellipse_189" data-name="Ellipse 189" transform="translate(0 55)" fill={colore} stroke={colore} strokeWidth="1">
          <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
          <circle cx="13.5" cy="13.5" r="13" fill="none" />
        </g>
      </svg>

      <div className="text-container">
        <div className="stazPartenza">{stazPartenza}</div>
        <div className="stazArrivo">{stazArrivo}</div>
      </div>
    </div>
  );
};

export default Stazioni;