const Ext = window['Ext'];

export default Ext.define('RtoPeriodicitaModel', {
    extend: 'Ext.data.Model',
    requires: [
        'Ext.data.field.String',
        'Ext.data.field.Date',
        'Ext.data.field.Integer',
        'Ext.data.field.Boolean'
    ],
    fields:
        [
            { name: 'ideLocPrv', type: 'auto' },
            { name: 'desLocPrv', type: 'auto' },
            { name: 'ideLocDsn', type: 'auto' },
            { name: 'desLocDsn', type: 'auto' },
            { name: 'ideOpv', type: 'auto' },
            { name: 'idePerRto', type: 'auto' },
            { name: 'desOpv', type: 'auto' },
            { name: 'datIniPer', type: 'date', format: 'd/m/Y' },
            { name: 'datFinPer', type: 'date', format: 'd/m/Y' },
            { name: 'desPerRto', type: 'auto' },
            { name: 'flgSmr', type: 'auto' },
            { name: 'flgLnd', type: 'auto' },
            { name: 'flgMrt', type: 'auto' },
            { name: 'flgMrc', type: 'auto' },
            { name: 'flgGvd', type: 'auto' },
            { name: 'flgVnr', type: 'auto' },
            { name: 'flgSbt', type: 'auto' },
            { name: 'flgDmn', type: 'auto' },
            { name: 'flgLav', type: 'auto' },
            { name: 'flgFes', type: 'auto' },
            { name: 'flgPtf', type: 'auto' },
            { name: 'flgPof', type: 'auto' },
            { name: 'flgSegPof', type: 'auto' },
            { name: 'prgPrcPrv', type: 'auto' },
            { name: 'prgPrcDsn', type: 'auto' }
        ]
});


