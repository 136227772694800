import React from 'react';
import { Panel, Grid, Column, Container } from '@sencha/ext-react-modern';

const Ext = window['Ext'];

const LimitazioniPOCerModel = Ext.define('LimitazioniPOCerModel', {
    extend: 'Ext.data.Model',
    fields: [
        { name: 'dscLocPrvCer', type: 'string' },
        { name: 'datIniLmzCer', type: 'date' },
        { name: 'datFinLmzCer', type: 'date' }
    ]
});
const LimitazioniPOCirModel = Ext.define('LimitazioniPOCirModel', {
    extend: 'Ext.data.Model',
    fields: [
        { name: 'desLmzCir', type: 'string' },
        { name: 'desLcm', type: 'string' },
        { name: 'dscLocPrv', type: 'string' },
        { name: 'dscLocDsn', type: 'string' },
        { name: 'datIniLmz', type: 'auto'  },
        { name: 'datFinLmz', type: 'auto' },
        { name: 'velMax', type: 'int' }
    ]
});

function PoLimitazioni(props) {

    const storeLimitazioniCer = Ext.create('Ext.data.Store', {
        model: LimitazioniPOCerModel,
        data: props.schedaPo.schede.limitazioniCer
    });
    const storeLimitazioniCir = Ext.create('Ext.data.Store', {  
        model: LimitazioniPOCirModel,
        data: props.schedaPo.schede.limitazioniCir
    });
    
    return (
        <Container>
            <Panel
                itemId={"poLimitazioni" + props.ind}
                title={props.schedaPo.testataPO.desTrcOra}
                titleCollapse={true}
                cls='PnlSectionPOTR'
                iconCls="x-fa fa-train"
                collapsible={{ collapsed: false, direction: 'top' }}
            >
                <Container layout="hbox">
                    <Panel title="Tratte con Limitazioni alla Circolabilit&aacute" cls="PnlSectionPoL1" flex={1}>
                        <Container  cls="column-in-panel" flex={1}>
                            <Grid
                                minHeight='150'
                                height='150'
                                cls="custom-grid"
                                //title="Tratte con Limitazioni alla Circolabilit&aacute"
                                flex={2}
                                scrollable
                                //selectable={{ checkbox: false, mode: 'single' }}
                                store={storeLimitazioniCir}
                                plugins={['gridfilters']}
                                itemConfig={{
                                    viewModel: true
                                }}
                            >
                                <Column text="Tipo limitazione" dataIndex="desLmzCir" filter='string' width={150} />
                                <Column text="Locomotore" dataIndex="desLcm" filter='string' flex={1} width={110} />
                                <Column text="Da" dataIndex="dscLocPrv" filter='string' flex={1} width={200} />
                                <Column text="A" dataIndex="dscLocDsn" filter='string' flex={1} width={200} />
                                <Column text="Dal" dataIndex="datIniLmz" type="datecolumn" width={90} formatter='date("d/m/Y")' />
                                <Column text="Al" dataIndex="datFinLmz" type="datecolumn" width={90} formatter='date("d/m/Y")' />
                                <Column text="V.Max" dataIndex="velMax" width={80} />
                            </Grid>
                        </Container>
                    </Panel>
                    <Panel title="Tratte non coperte da CdS" cls="PnlSectionPoL1" flex={0.7} margin="0 0 0 10" >
                        <Container cls="column-in-panel" flex={1}>
                            <Grid
                                minHeight='150'
                                height='150'
                                cls="custom-grid"
                                //title="Tratte non coperte da CdS"
                                flex={1}
                                scrollable
                                //selectable={{ checkbox: false, mode: 'single' }}
                                store={storeLimitazioniCer}
                                plugins={{
                                    gridfilters: {}
                                }}
                                itemConfig={{
                                    viewModel: true
                                }}

                            >
                                <Column text="Da" dataIndex="dscLocPrvCer" filter='string' flex={1} minWidth={200} />
                                <Column text="A" dataIndex="dscLocDsnCer" filter='string' flex={1} minWidth={200} />
                                <Column text="Dal" dataIndex="datIniLmzCer" type="datecolumn" width={90} formatter='date("d/m/Y")' />
                                <Column text="Al" dataIndex="datFinLmzCer" type="datecolumn" width={90} formatter='date("d/m/Y")' />
                            </Grid>

                        </Container>
                    </Panel>

                </Container>
            </Panel>
        </Container>
    );
}

export default PoLimitazioni;