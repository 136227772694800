import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Panel, Titlebar, Grid, Column, Button, Toolbar, SegmentedButton } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";


const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
]);
const storeReportGenerale = Ext.create('Ext.data.Store', {
    proxy: {
        url: 'AnalisiRto/ReportGenerale',
        method: 'GET',
        type: 'ajax',
        reader: {
            type: 'json'
        },
    },
    autoload: false,

});

function ReportGenerale() {

    let title = "Report Generale";
    let ui = 'action';
    let navigate = useNavigate();
    const { user } = useUser();
    const [state, setState] = useState({ stateReportGenerale: storeReportGenerale })

    const caricaReportGenerale = () => {

        storeReportGenerale.load({
            params: {
                ideUte: user.ideUte,
                ideGrp: user.idGruppo      
            },
            callback: function () {
                aggiornaState();
            }
        });
    };
    const aggiornaState = () => {
        state.stateReportGenerale = storeReportGenerale;
        setState({ ...state });
    }

    //-------Aggiornamento------------------
    useEffect(() => {
        caricaReportGenerale();
       
    }, []);



    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridReportGen')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }

    function navigateToDetails(annoFer, tipRTO, periodoRTO, statoRTO) {
        const params = {
            annoFer: annoFer,
            tipRTO: tipRTO,
            periodoRTO: periodoRTO,
            statoRTO: statoRTO,
            pagina: "linkFromReportGenerale"
        };
        navigate(`/MainRto`, { state: params });
    };

    function renderUnderline(value, record, field, meta) {
        if (value !== null) {
            meta.setCls("line");
            return value;
        }
    }

    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-table" />      
                </Titlebar>

                    <Toolbar docked="top" margin="10 0 10 10">
                        <SegmentedButton docked="right">
                            <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                        </SegmentedButton>
                    </Toolbar>

                <Grid flex={1} scrollable cls="custom-grid" itemId="gridReportGen" itemConfig={{ viewModel: true }}
                    selectable={{ checkbox: false, mode: 'single' }}
                    listeners={{
                        childsingletap: function (editor, context, eOpts) {
                            let annoFer = context.record.data.issuedate; //?TO CHECK oppure deS_ANNFRO     
                            let tipRTO = context.record.data.idE_TIP_RTO;
                            let periodoRTO = context.record.data.idE_TIP_PER;
                            let statoRTO = context.record.data.idE_STO_RTO;

                            if (context.column._text === "Totale") {
                                navigateToDetails(annoFer, tipRTO, periodoRTO, statoRTO);
                            }
                        }
                    }}
                    plugins={['gridfilters', 'gridexporter']}
                    store={state.stateReportGenerale}
                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                >

                    <Column height="40" width={250} text="Periodo Ferroviario" dataIndex="deS_ANN_FRO" filter='string' exportStyle={{ width: '330' }} />
                    <Column height="40" width={250} text="Tipo RTO" dataIndex="reqsubtype" filter='string' flex={1} exportStyle={{ width: '330' }} />
                    <Column height="40" width={250} text="Periodo RTO" dataIndex="reqtype" filter='string' flex={1} exportStyle={{ width: '330' }} />
                    <Column height="40" width={350} text="Stato RTO" dataIndex="reqstatus" filter='string' flex={1} exportStyle={{ width: '430' }} />
                    <Column height="40" width={150} text="Totale" dataIndex="cnt" filter='number' renderer={renderUnderline} exportStyle={{ width: '230' }} cell={{ tools: { Totale: {  bind: { cls: 'lineText' } } } }} />
                    

                </Grid>
            </Panel>
        </Container>

    );
}

export default ReportGenerale;