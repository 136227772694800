import React from 'react';
import { Panel, Container, Grid, Column, Loadmask } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';

const Ext = window['Ext'];
const modelClienteTitoli = Ext.define('ClientiTitoliModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideTloAtn', type: 'auto' },
            { name: 'numTloAtn', type: 'auto' },
            { name: 'desTloAtn', type: 'auto' },
            { name: 'datIniVal', type: 'date', format: 'd/m/Y' },
            { name: 'datFinVal', type: 'date', format: 'd/m/Y' },
            { name: 'flgVal', type: 'auto' },
            { name: 'ideLic', type: 'auto' }
        ]
});

const storeClienteTitoli = Ext.create('Ext.data.Store', {
    model: modelClienteTitoli,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Clienti/LeggiListaTitoliPerLicenza',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

function ClienteTitolo(props) {
    const { user, switchUser } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    var conLicenza = props.licenza != null ? true : false;
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: false, message: "Attendere caricamento Titoli in corso...", ricarica: true });

    var isRFICOMP = user.ruolo === 'RFI-PO-COMP';
    var isRFICC = user.ruolo === 'RFI-PO-RFICC';
    //var imDebugging = true;

    const caricaTitoli = () => {
        setStateLoadMask({ hidden: false, message: "Caricamento Titoli in corso..." });
        storeClienteTitoli.load({
            params: {
                ideLic: conLicenza ? props.licenza.ideLic : -1
            },
            callback: (records, operation, success) => {
                if (success) {
                } else {
                    console.error("Errore durante il caricamento dei dati");
                }
                setStateLoadMask({ hidden: true, message: "Caricamento CdS completato..." });
            }
        })
    }

    const onNuovoTitoloClick = () => {
        var grid = Ext.ComponentQuery.query('#gridClienteTitoli')[0];
        var store = grid.getStore();
        var record = Ext.create(modelClienteTitoli, {});
        store.insert(0, record);
        grid.getPlugin('rowedit').startEdit(record, 1);
    }

    const onEliminaTitoloClick = (grid, info) => {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del Titolo?", function (button) {
            if (button === 'yes') {
                setStateLoadMask({ hidden: true, message: "Attendere cancellazione in corso..." });
                Ext.Ajax.request({
                    url: 'Clienti/EliminaTitoliAutorizzatori',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        pIdeTloAtn: info.record.data.ideTloAtn
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            if (success) {
                                var returnObj = Ext.decode(operation.responseText);
                                //modifica gestione errore
                                if (returnObj.resp === "KO") {
                                    Ext.Msg.alert('Cancellazione Titolo fallita', returnObj.msgErr)
                                }
                                else {
                                    Ext.toast({ message: 'Titolo eliminato', timeout: 4000 });
                                }
                            }
                            else {
                                Ext.Msg.alert('Cancellazione fallita', 'Errore nella cancellazione del Titolo');
                            }
                        }
                        //ricarica l'elenco dei titoli
                        caricaTitoli();
                    }
                })
            }
        })
    }

    const onModificaTitoloClick = (grid, info) => {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 1);
        }
    }

    function handleCollapse(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoTitolo')[0].setHidden(true);
    };

    function handleExpand(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoTitolo')[0].setHidden(!conLicenza);
    };

    useEffect(() => {
        caricaTitoli();
    }, []);

    return (
        <Panel
            title="Titolo Autorizzatorio"
            scrollable
            itemId="panelRequisiti"
            bodyPadding={5}
            titleCollapse={true}
            iconCls="x-fa fa-file-contract"
            collapsible={{ collapsed: true, direction: 'top' }}
            onCollapse={handleCollapse}
            onExpand={handleExpand}
            cls='PnlSectionCML2 iconPanelsRTO'
            tools={[
                {
                    iconCls: 'x-fa x-fa fa-plus', tooltip: 'Nuovo', itemId: "nuovoTitolo", handler: onNuovoTitoloClick.bind(this), hidden: "true"
                }
            ]}
        >
            <Grid
                itemId="gridClienteTitoli"
                flex={1}
                cls="column-in-panel"
                store={storeClienteTitoli}
                loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                plugins={{ gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" } }}
                itemConfig={{ viewModel: true }}
                margin={'3 3 5 20'}
                minheight={120}
                listeners={{
                    canceledit: function (editor, context) {
                        if (context.record.phantom) {
                            context.editor.grid.store.remove(context.record);
                        }
                    },
                    edit: function (grid, titoloNew) {
                        //controllo validità
                        if ((titoloNew.record.phantom)) {
                            // nuovo
                            let matchingRecords = storeClienteTitoli.queryRecords('numTloAtn', titoloNew.record.data.numTloAtn);
                            if (matchingRecords.length === -1 || (matchingRecords.length === 1 && matchingRecords[0].phantom)) {
                                let matchingDate = storeClienteTitoli.queryRecords('datIniVal', titoloNew.record.data.datIniVal);
                                if (matchingDate.length === -1 || (matchingDate.length === 1 && matchingDate[0].phantom)) {
                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                    Ext.Ajax.request({
                                        url: 'Clienti/InserisciTitoloAutorizzatoro',
                                        method: 'POST',
                                        params: {
                                            ideUte: user.ideUte,
                                            ipAddress: "",
                                            ideLic: props.licenza.ideLic,
                                            numTloAtn: titoloNew.record.data.numTloAtn,
                                            desTloAtn: titoloNew.record.data.desTloAtn,
                                            dataInizio: titoloNew.record.data.datIniVal,
                                            dataFine: titoloNew.record.data.datFinVal
                                        },
                                        callback: function (records, success, operation) {
                                            if (success) {
                                                var returnObj = Ext.decode(operation.responseText);
                                                //modifica gestione errore
                                                if (returnObj.resp === "KO") {
                                                    Ext.Msg.alert('Inserimento certificato fallito', returnObj.msgErr)
                                                }
                                                else {
                                                    Ext.toast({ message: 'Certificato inserito', timeout: 4000 });
                                                }
                                            }
                                            else {
                                                Ext.Msg.alert('Inserimento fallito', "Errore nell'inserimento del CdS");
                                            }

                                            //ricarica l'elenco dei titoli
                                            caricaTitoli();
                                        }
                                    })
                                }
                                else {
                                    Ext.Msg.alert('Operazione non consentita', 'Non &egrave possibile inserire un titolo autorizzatorio con una data inizio validit&aacute gi&aacute esistente.');
                                }
                            }
                            else {
                                Ext.Msg.alert('Operazione non consentita', 'Non &egrave possibile inserire un titolo autorizzatorio con un numero gi&aacute esistente.');
                            }
                        }
                        else {
                            // modifica
                            let matchingRecords = storeClienteTitoli.queryRecords('numTloAtn', titoloNew.record.data.numTloAtn);
                            if (matchingRecords.length === -1 || (matchingRecords.length === 1 && matchingRecords[0].data.ideTloAtn === titoloNew.record.data.ideTloAtn)) {
                                let matchingDate = storeClienteTitoli.queryRecords('datIniVal', titoloNew.record.data.datIniVal);
                                if (matchingDate.length === -1 || (matchingDate.length === 1 && matchingDate[0].data.ideTloAtn === titoloNew.record.data.ideTloAtn)) {
                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                    Ext.Ajax.request({
                                        url: 'Clienti/ModificaTitoliAutorizzatori',
                                        method: 'POST',
                                        params: {
                                            ideUte: user.ideUte,
                                            ipAddress: "",
                                            ideLic: props.licenza.ideLic,
                                            ideTloAtn: titoloNew.record.data.ideTloAtn,
                                            numTloAtn: titoloNew.record.data.numTloAtn,
                                            dataInizio: titoloNew.record.data.datIniVal,
                                            dataFine: titoloNew.record.data.datFinVal,
                                            desTloAtn: titoloNew.record.data.desTloAtn
                                        },
                                        callback: function (records, success, operation) {
                                            if (success) {
                                                var returnObj = Ext.decode(operation.responseText);
                                                //modifica gestione errore
                                                if (returnObj.resp === "KO") {
                                                    Ext.Msg.alert('Modifica certificato fallita', returnObj.msgErr)
                                                }
                                                else {
                                                    Ext.toast({ message: 'Certificato modificato', timeout: 4000 });
                                                }
                                            }
                                            else {
                                                Ext.Msg.alert('Modifica fallita', 'Errore nella modifica del CdS');
                                            }

                                            //ricarica l'elenco dei titoli
                                            caricaTitoli();
                                        }
                                    })
                                }
                                else {
                                    Ext.Msg.alert('Operazione non consentita', 'Non &egrave possibile inserire un titolo autorizzatorio con una data inizio validit&aacute gi&aacute esistente.');
                                }
                            }
                            else {
                                Ext.Msg.alert('Operazione non consentita', 'Non &egrave possibile inserire un titolo autorizzatorio con un numero gi&aacute esistente.');
                            }
                        }
                    }
                }}
            >
                <Column text="Numero" dataIndex="numTloAtn" flex={0.8}
                    editor={{ xtype: 'textfield', itemId: 'txtNumTitolo', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true }}
                />
                <Column text="Descrizione" dataIndex="desTloAtn" flex={1.5} editor={{ xtype: 'textfield', itemId: 'txtDescrizioneTitolo' }} />
                <Column text="Data Inizio Validit&aacute" dataIndex="datIniVal" type="datecolumn" flex={0.4} formatter='date("d/m/Y")' filter='date'
                    editor={{ xtype: 'datefield', formatter: 'date("d/m/Y")', triggerAction: 'all', allowBlank: false, itemId: "dataInizioTitolo", requiredMessage: "Questo campo non puo' essere vuoto", required: true }}
                />
                <Column text="Data Fine Validit&aacute" dataIndex="datFinVal" type="datecolumn" flex={0.4} formatter='date("d/m/Y")' filter='date'
                    editor={{ xtype: 'datefield', formatter: 'date("d/m/Y")', triggerAction: 'all', itemId: "dataFineTitolo" }}
                />
                <Column itemId='actionTitolo' editable={false} text="Azioni"
                    cell={{
                        tools: {
                            modificaTitolo: {
                                handler: onModificaTitoloClick.bind(this),
                                bind: {
                                    iconCls: "x-fa fa-edit",
                                    tooltip: 'Modifica',
                                    disabled: isRFICOMP || isRFICC
                                }
                            },
                            eliminaTitolo: {
                                handler: onEliminaTitoloClick.bind(this),
                                bind: {
                                    iconCls: "x-fa fa-trash",
                                    tooltip: 'Elimina',
                                    disabled: isRFICOMP || isRFICC
                                }
                            }
                        }
                    }}
                />
            </Grid>
        </Panel>
    );
}

export default ClienteTitolo;
