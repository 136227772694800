import React from 'react';
import { Panel, Container, Loadmask, Checkcolumn, Grid, Column } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';
import clientiDirittiAccessoModel from '../models/ClientiDirittiAccessoModel.js';

const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

const storeClientiDirittiAccesso = Ext.create('Ext.data.Store', {
    model: clientiDirittiAccessoModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const storeAnaClientiDirittiAccesso = Ext.create('Ext.data.Store', {
    model: clientiDirittiAccessoModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

let arrayAnaDiritti = [];


function ClienteDirittiAccesso(props) {

    const { user, switchUser } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    var readonly = user.ruolo == 'RFI' ? false : true;

    const [stateLoadMask, setStateLoadMask] = useState({ hidden: false, message: "Attendere caricamento diritti di accesso in corso...", ricarica: true });
    const cliente = props;

    const caricaClientiDirittiAccesso = (ideCli) => {
        Ext.Ajax.request({
            url: 'Clienti/LeggiListaDirittiDiAccesso',
            method: 'GET',
            params: {
                idCliente: ideCli
            },
            callback: function (records, success, operation) {
                setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                var returnObj = Ext.decode(operation.responseText);
                storeClientiDirittiAccesso.loadData(returnObj);
                Ext.ComponentQuery.query('#gridDirittiAccesso')[0].setStore(storeClientiDirittiAccesso);
            }
        });
    }

    const caricaAnaClientiDirittiAccesso = (ideCli) => {
        Ext.Ajax.request({
            url: 'Clienti/LeggiListaDirittiDiAccessoAna',
            method: 'GET',
            params: {
                idCliente: ideCli
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                storeAnaClientiDirittiAccesso.loadData(returnObj);
            }
        });
    }

    if (stateLoadMask.ricarica === true) {
        caricaClientiDirittiAccesso(props.cliente.ideCli);
        caricaAnaClientiDirittiAccesso(props.cliente.ideCli)
    }

    function onNuovoDirittoAccessoClick() {
        for (var i = 0; i < Ext.ComponentQuery.query('#cmdStdCo').length; i++) {
            Ext.ComponentQuery.query('#cmdStdCo')[i].enable();
        }
        var grid = Ext.ComponentQuery.query('#gridDirittiAccesso')[0];
        var store = grid.getStore();

        var maxDatIni = new Date(0);

        store.each(function (record) {
            var datIni = record.get('datIni');
            if (datIni > maxDatIni) {
                maxDatIni = datIni;
            }
        });

        var record = Ext.create(clientiDirittiAccessoModel, {
            datIni: maxDatIni !== new Date(0) ? maxDatIni : new Date(),
            datFin: new Date('2999-12-31')
        });
        store.insert(0, record);
        grid.getPlugin('rowedit').startEdit(record);

    }

    function caricamentoDirittiAccessoDisponibili() {
        return new Promise(function (resolve, reject) {
            setStateLoadMask({ hidden: false, message: "Caricamento diritti di accesso disponibili in corso...", ricarica: false });
            arrayAnaDiritti = [];
            for (let i = 0; i < storeAnaClientiDirittiAccesso.data.items.length; i++) {
                if (storeClientiDirittiAccesso.findRecord("ideOpaRfi", storeAnaClientiDirittiAccesso.data.items[i].data.ideOpaRfi, 0, false, false, true) == null) {
                    let app = { text: storeAnaClientiDirittiAccesso.data.items[i].data.desOpaRfi, value: storeAnaClientiDirittiAccesso.data.items[i].data.ideOpaRfi };
                    arrayAnaDiritti.push(app);
                }
            }
            for (var i = 0; i < Ext.ComponentQuery.query('#cmdStdCo').length; i++) {
                Ext.ComponentQuery.query('#cmdStdCo')[i].setOptions(arrayAnaDiritti);
            }

            setStateLoadMask({ hidden: true, message: "Caricamento diritti di accesso disponibili in corso...", ricarica: false });
            resolve();
        })

    }

    function onAggiungiDirittoAccessoClick(grid, info) {
        for (var i = 0; i < Ext.ComponentQuery.query('#cmdStdCo').length; i++) {
            Ext.ComponentQuery.query('#cmdStdCo')[i].enable();
        }
        var store = grid.getStore();

        var maxDatIni = new Date(0);

        store.each(function (record) {
            var datIni = record.get('datIni');
            if (datIni > maxDatIni) {
                maxDatIni = datIni;
            }
        });

        var record = Ext.create(clientiDirittiAccessoModel, {
            datIni: maxDatIni !== new Date(0) ? maxDatIni : new Date(),
            datFin: new Date('2999-12-31')
        });
        store.insert(info.cell.row._recordIndex + 1, record);
        grid.getPlugin('rowedit').startEdit(record);
    }

    function onEliminaDirittoAccessoClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del diritto di accesso?", function (button) {

            if (button === 'yes') {
                setStateLoadMask({ hidden: false, message: "Attendere eliminazione in corso...", ricarica: false });
                var dirittiAccessoByDB = null;
                Ext.Ajax.request({
                    url: 'Clienti/LeggiListaDirittiDiAccesso',
                    method: 'GET',
                    params: {
                        idCliente: props.cliente.ideCli
                    },
                    callback: function (resp, success, operation) {
                        dirittiAccessoByDB = Ext.decode(operation.responseText);

                        if (dirittiAccessoByDB.find(dirAcc => dirAcc.ideOpaRfi === info.record.data.ideOpaRfi) != null) {
                            Ext.Ajax.request({
                                url: 'Clienti/EliminaDirittiDiAccesso',
                                method: 'POST',
                                params: {
                                    ideCli: props.cliente.ideCli,
                                    ideOpa: info.record.data.ideOpaRfi
                                },
                                callback: function (records, success, operation) {
                                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                                    if (success) {
                                        var returnObj = Ext.decode(operation.responseText);
                                        //modifica gestione errore
                                        if (returnObj.resp == "KO") {
                                            Ext.Msg.alert('Elimina diritto di accesso fallito', returnObj.msgErr)
                                        }
                                        else {
                                            Ext.toast({ message: 'Diritto di accesso eliminato', timeout: 4000 });
                                        }
                                    }
                                    else {
                                        Ext.Msg.alert('Elimina diritto di accesso fallito', 'Elimina diritto di accesso fallito');
                                    }
                                }
                            });
                        }
                        else {
                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: false });
                            Ext.ComponentQuery.query('#gridDirittiAccesso')[0].getStore().remove(info.record);
                            Ext.toast({ message: 'Diritto di accesso eliminato', timeout: 4000 });
                        }
                    }
                })

            }
        })

    }

    function onModificaDirittoAccessoClick(grid, info) {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 2);
            for (var i = 0; i < Ext.ComponentQuery.query('#cmdStdCo').length; i++) {
                Ext.ComponentQuery.query('#cmdStdCo')[i].disable();
            }
        }
    }

    function handleCollapse(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoDiritto')[0].setHidden(true);
    };

    function handleExpand(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoDiritto')[0].setHidden(readonly);
    };


    return (

        <Panel
            title="Diritti Accesso"
            scrollable
            itemId="panelDirittiAccesso"
            bodyPadding={5}
            titleCollapse={true}
            iconCls="x-fa fa-key"
            collapsible={{ collapsed: true, direction: 'top' }}
            cls='PnlSectionL1 iconPanelsRTOSpecial '
            onCollapse={handleCollapse}
            onExpand={handleExpand}
            tools={[
                {
                    iconCls: 'x-fa x-fa fa-plus', itemId: "nuovoDiritto", tooltip: 'Nuovo', hidden: "true", handler: onNuovoDirittoAccessoClick.bind(this)
                }
                /* { type: 'menu', handler: toolHandler }*/
            ]}
        >
            <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
                <Loadmask
                    cls="custom-grid"
                    itemId="loadMaskDirittiAccesso"
                    hidden={stateLoadMask.hidden}
                    indicator={true}
                    message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                    modal={true}
                />
                <Grid
                    itemId="gridDirittiAccesso"
                    flex={1}
                    markDirty={true}
                    store={storeClientiDirittiAccesso}
                    minHeight='200'
                    height='200'
                    scrollable
                    cls="custom-grid"
                    selectable={{ checkbox: false, mode: 'single' }}
                    plugins={{ gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" } }}
                    itemConfig={{
                        viewModel: true
                    }}
                    listeners={{
                        beforeedit: function (editor, context, eOpts) {
                            caricamentoDirittiAccessoDisponibili().then(function () {
                                if (context.source.type === 'doubletap') {
                                    for (var i = 0; i < Ext.ComponentQuery.query('#cmdStdCo').length; i++) {
                                        Ext.ComponentQuery.query('#cmdStdCo')[i].disable();
                                    }
                                }
                                return true;
                            })
                        },
                        canceledit: function (editor, context) {
                            caricaClientiDirittiAccesso(props.cliente.ideCli);
                        },
                        edit: function (grid, location) {
                            setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso...", ricarica: false });
                            var ideCliA = props.cliente.ideCli;
                            var ideOpaRfiA = location.record.data.ideOpaRfi;
                            var dataInizioA = location.record.data.datIni;
                            var dataFineA = location.record.data.datFin;
                            var desOpaRfiA = location.record.data.desOpaRfi;

                            if (new Date(dataInizioA) > new Date(dataFineA)) {
                                setStateLoadMask({ hidden: true, message: "", ricarica: false });
                                Ext.Msg.alert('Errore', 'Attenzione: La data iniziale non può essere successiva alla data finale.');
                                return;
                            }

                            var dirittiAccessoByDB = null;
                            Ext.Ajax.request({
                                url: 'Clienti/LeggiListaDirittiDiAccesso',
                                method: 'GET',
                                params: {
                                    idCliente: props.cliente.ideCli
                                },
                                callback: function (resp, success, operation) {
                                    const idDiritto = location.record.data.ideOpaRfi;
                                    dirittiAccessoByDB = Ext.decode(operation.responseText);

                                    if (dirittiAccessoByDB.find(dirAcc => dirAcc.ideOpaRfi === idDiritto) == null) {
                                        Ext.Ajax.request({
                                            url: 'Clienti/InserisciDirittoDiAccesso',
                                            method: 'POST',
                                            params: {
                                                ideCli: ideCliA,
                                                ideOpaRfi: ideOpaRfiA,
                                                dataInizio: dataInizioA,
                                                dataFine: dataFineA,
                                                desOpaRfi: desOpaRfiA
                                            },
                                            callback: function (records, success, operation) {
                                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                                                if (success) {
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    //modifica gestione errore
                                                    if (returnObj.resp === "KO") {
                                                        Ext.Msg.alert('Inserimento diritto di accesso fallito', returnObj.msgErr)
                                                    }
                                                    else {
                                                        Ext.toast({ message: 'Diritto di accesso inserito', timeout: 4000 });
                                                    }
                                                }
                                                else {
                                                    Ext.Msg.alert('Inserimento diritto di accesso fallito', 'Inserimento diritto di accesso fallito');
                                                }
                                            }
                                        })
                                    }
                                    else {

                                        Ext.Ajax.request({
                                            url: 'Clienti/ModificaDirittiDiAccesso',
                                            method: 'POST',
                                            params: {
                                                ideCli: ideCliA,
                                                ideOpaRfi: ideOpaRfiA,
                                                dataInizio: dataInizioA,
                                                dataFine: dataFineA,
                                                desOpaRfi: desOpaRfiA
                                            },
                                            callback: function (records, success, operation) {
                                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                                                if (success) {
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    //modifica gestione errore
                                                    if (returnObj.resp === "KO") {
                                                        Ext.Msg.alert('Modifica diritto di accesso fallito', returnObj.msgErr)
                                                    }
                                                    else {
                                                        Ext.toast({ message: 'Diritto di accesso modificato', timeout: 4000 });
                                                    }
                                                }
                                                else {
                                                    Ext.Msg.alert('Modifica diritto di accesso fallito', 'Modifica diritto di accesso fallito');
                                                }
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }}
                >
                    <Column
                        text='STDCO'
                        flex="1"
                        dataIndex='ideOpaRfi'
                        renderer={function (value, record, field, metaData) {
                            if (value !== undefined && value !== "")
                                return storeAnaClientiDirittiAccesso.findRecord("ideOpaRfi", value, 0, false, false, true).data.desOpaRfi;
                        }}
                        editor={{
                            xtype: 'combobox', options: arrayAnaDiritti, itemId: "cmdStdCo", triggerAction: 'all', mode: 'local', allowBlank: false,
                            requiredMessage: "Questo campo non puo' essere vuoto", required: true
                        }}
                    />
                    <Column type="datecolumn" formatter='date("d/m/Y")' flex={1} text="Inizio Validita" filter='date' dataIndex="datIni" editor={{
                        xtype: 'datefield', formatter: 'date("d/m/Y")', triggerAction: 'all', allowBlank: false, itemId: "dataIniDiritti", requiredMessage: "Questo campo non puo' essere vuoto", required: true
                    }} />
                    <Column type="datecolumn" formatter='date("d/m/Y")' flex={1} text="Fine Validita" filter='date' dataIndex="datFin" editor={{
                        xtype: 'datefield', formatter: 'date("d/m/Y")', triggerAction: 'all', itemId: "dataFinDiritti",
                    }} />
                    <Column flex={1} itemId='actionDiritti' editable={false} text="Azioni" hidden={readonly}
                        cell={{

                            tools: {

                                aggiungiDirittoAccesso: {

                                    handler: onAggiungiDirittoAccessoClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-plus",

                                        tooltip: 'Aggiungi'
                                    }

                                },

                                modificaDirittoAccesso: {

                                    handler: onModificaDirittoAccessoClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-edit",

                                        tooltip: 'Modifica'
                                    }

                                },

                                eliminaDirittoAccesso: {

                                    handler: onEliminaDirittoAccessoClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-trash",

                                        tooltip: 'Elimina',

                                        hidden: "{(record.desOpaRfi==='RFICO')}"
                                    }

                                }
                            }

                        }}

                    />


                </Grid>
            </Container>
        </Panel>

    );
}

export default ClienteDirittiAccesso;