import React, { useEffect, useState } from "react";
import { Container, Panel, Titlebar, Grid, Column, Button, Toolbar, SegmentedButton, Combobox, DatePickerField } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
]);

//  -----------STATISTICHE OPERAZIONI ----------------------------------------------
const storeStatisticheOperazioni = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'AnalisiRto/StatisticheOperazioni',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//-----------GRUPPO ----------------------------------
const storeGruppo = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiGruppi', 
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//-----------UTENTE ----------------------------------
const storeUtente = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiUtentiByGrp', 
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//-----------TIPO OPERAZIONI----------------------------------
const storeTipoOperazione = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipiOperazione',  
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//-----------OPERAZIONI----------------------------------
const storeOperazione = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiOperazioni', 
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//------------------------------------

function StatisticheOperazioni() {

    let title = "Statistiche Operazioni";
    let ui = 'action';
    const { user } = useUser();
  
    const caricaStatisticheOperazioni = (ideUte, ideGrp, dataInizio, dataFine,codTipOpe, nomOpe, ideGrpSne) => {
        dataInizio = dataInizio == "" ? null : dataInizio;
        dataFine = dataFine == "" ? null : dataFine;
        storeStatisticheOperazioni.load({
            params: {
                ideUte: ideUte,
                ideGrp: ideGrp,
                dataInizio: dataInizio,
                dataFine: dataFine,
                codTipOpe: codTipOpe,
                nomOpe: nomOpe,
                ideGrpSne: ideGrpSne
            },
            callback: function () {
                
            }
        });
    };


    const caricaGruppo = () => {
        storeGruppo.load({
            params: {
                ideGrp: user.idGruppo
            }
        })
    }
    const caricaUtente = () => {
        storeUtente.load({
            params: {
                ideGrp: user.idGruppo
            }
        })
    }
    const caricaTipoOperazione = () => {
        storeTipoOperazione.load({
            params: {
                ideGrp: user.idGruppo
            }
        })
    }
    const caricaOperazione = () => {
        storeOperazione.load({
            params: {
                ideGrp: user.idGruppo
            }
        })
    }
      

    useEffect(() => {
        caricaGruppo();
        caricaUtente();
        caricaTipoOperazione();
        caricaOperazione();
    }, []);

    //--------------CONFERMA/AVVIA RICERCA --------------------------
    const onConfermaClick = (x) => {
        let ideUte = parseInt(Ext.ComponentQuery.query('#comboUtente')[0].getValue()) > 0 ? Ext.ComponentQuery.query('#comboUtente')[0].getValue() : null;
        let ideGrp = parseInt(Ext.ComponentQuery.query('#comboGruppo')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboGruppo')[0].getValue() : null;
        let dataInizio = Ext.ComponentQuery.query('#dPickDataDa')[0].getValue();
        let dataFine = Ext.ComponentQuery.query('#dPickDataA')[0].getValue();
        let codTipOpe = Ext.ComponentQuery.query('#comboTipoOperazione')[0].getValue();
        let nomOpe = Ext.ComponentQuery.query('#comboOperazione')[0].getValue();
        let ideGrpSne = parseInt(Ext.ComponentQuery.query('#comboGruppo')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboGruppo')[0].getValue() : null;

        caricaStatisticheOperazioni(ideUte, ideGrp, dataInizio, dataFine, codTipOpe, nomOpe, ideGrpSne);
    }
    //--------------PULISCI FILTRI------------------------------------
    const handlePulisciFiltri = (btn) => {
        Ext.ComponentQuery.query('#comboGruppo')[0].setValue(null);
        Ext.ComponentQuery.query('#comboTipoOperazione')[0].setValue(null);
        Ext.ComponentQuery.query('#comboOperazione')[0].setValue(null);
        Ext.ComponentQuery.query('#dPickDataDa')[0].setValue(null);        
        Ext.ComponentQuery.query('#dPickDataA')[0].setValue(null);

        //svuota lo store 
        storeStatisticheOperazioni.clearData();
        storeStatisticheOperazioni.removeAll();
        Ext.ComponentQuery.query('#gridStatOper')[0].refresh();
    };

    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridStatOper')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }
   
    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="fa-lg fa-edit" />

                </Titlebar>

                <Panel docked="top" layout="hbox" margin="10 0 0 10">
                    <Combobox itemId="comboGruppo" align="left" width={270} height={25} margin='0 4 0 0' editable="false" valueField="ideGrp" displayField="desGrp"
                        label="Gruppo"
                        labelAlign="left"
                        labelWidth={60}
                        queryMode="local"
                        store={storeGruppo}
                    />
                    <Combobox itemId="comboUtente" align="left" width={250} height={25} margin='0 4 0 10' editable="false" valueField="ideUte" displayField="nom"
                        label="Utente"
                        labelAlign="left"
                        labelWidth={60}
                        clearable
                        queryMode="local"
                        store={storeUtente}                                  
                    />
                    <Combobox itemId="comboTipoOperazione" align="left" width={250} height={25} margin='0 4 0 10' editable="false" valueField="codTipOpe" displayField="tipOpe"
                        label="Tipo Operazione"
                        labelAlign="left"
                        labelWidth={120}
                        queryMode="local"
                        clearable
                        forceSelection="true"
                        store={storeTipoOperazione}
                    />
                    <Combobox itemId="comboOperazione" align="left" width={300} height={25} margin='0 4 0 15' editable="false" valueField="nomOpe" displayField="nomOpe"
                        label="Operazione"
                        labelAlign="left"
                        labelWidth={80}
                        queryMode="local"
                        clearable
                        forceSelection="true"
                        store={storeOperazione}
                    />

                    <DatePickerField label="Data Inizio" width={250} height={25} margin="0 10 0 20" picker={{ yearFrom: 1990 }}
                        labelAlign="left"
                        labelWidth={75}
                        labelCls='LabelRiepilogo'
                        itemId="dPickDataDa"
                        queryMode="local"
                    />
                    <DatePickerField label="Data Fine" width={215} height={25} margin="0 10 0 0" picker={{ yearFrom: 1990 }}
                        labelAlign="left"
                        labelWidth={75}
                        labelCls='LabelRiepilogo'
                        itemId="dPickDataA"
                        queryMode="local"
                    />
                    <Panel docked="bottom" width="100%" margin="10 0 2 0">
                        <Toolbar docked="right" width="100%">
                            <SegmentedButton layout="vbox" docked="right">
                                <Button cls="marginButton ricercaAvanzataButton" align="right" text="Pulisci filtri" iconCls={'fa-solid fa-filter-circle-xmark'} ui="action" handler={handlePulisciFiltri} />
                            <Button cls="marginButton appButton" align="right" text="Avvia ricerca" iconCls={'x-fa fa-search'} ui="action" handler={onConfermaClick} />
                                <Button cls="marginButton exportButton" align="right" docked="right" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                            </SegmentedButton>
                        </Toolbar>
                    </Panel>

                    
                </Panel>

                <Grid flex={1} scrollable cls="custom-grid" itemId="gridStatOper" itemConfig={{ viewModel: true }} margin="10 0 0 0"
                    selectable={{ checkbox: false, mode: 'single' }}
                    plugins={['gridfilters', 'gridexporter']}
                    store={storeStatisticheOperazioni}
                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                    >
                    <Column height="40" width={200} text="Data" dataIndex="daT_OPE" type="datecolumn" formatter='date("d/m/Y")' filter='date' exportStyle={{ width: '250' }} />
                    <Column height="40" width={350} text="Azione" dataIndex="noM_OPE" filter='string' flex={1} exportStyle={{ width: '300' }} />
                    <Column height="40" width={250} text="Attore" dataIndex="nom" filter='string'  exportStyle={{ width: '300' }} />
                    <Column height="40" width={400} text="Gruppo" dataIndex="deS_GRP" filter='number' exportStyle={{ width: '430' }} />
                    <Column height="40" width={140} text="Dettaglio" dataIndex="deS_OPE" filter='number' flex={1} exportStyle={{ width: '430' }} />                
                </Grid>
            </Panel>
        </Container>

    );
}

export default StatisticheOperazioni;