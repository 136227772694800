import React, { useEffect, useState } from "react";
import { Container, Panel, Titlebar, Button, Combobox, Toolbar, Loadmask } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";
import Column from "../home/charts/column";
import Stacked from "../home/charts/stacked";
import ColumnGrouped from '../home/charts/column_grouped';
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
]);

//-----------IMPRESE FERROVIARIE----------------------------------
const storeImpresa = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiListImpresaFerroviaria',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//-------------PERIODI RTO----------------------------
const storePeriodoRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiePeriodicita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//-------------------------


function ReportStatoLavorazione() {

    let title = "Dettaglio Impegno Impianto";
    let ui = 'action';
    const { user } = useUser();
    const [params, setParams] = useState({ ideUte: null, ideAnnFro: null, ideTipPer: null });
    const [chartData, setChartData] = useState({ chartOneData: null, chartTwoData: null, chartThreeData: null, chartFourData: null });
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: false, message: "Attendere caricamento in corso...", });
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    const { ambiente, updateAmbiente } = useAmbiente();
    const { periodi } = usePeriodi();

    storePeriodiFerroviari.setData(periodi.periodiFerroviari);
    storePeriodoRTO.setData(periodi.periodoRTO);

    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });
    const [impresaSelezionata, setImpresaSelezionata] = useState(false);

    //const caricaPeriodiFerroviari = () => {
    //    storePeriodiFerroviari.load({
    //    })
    //}

    //const caricaPeriodoRTO = () => {
    //    storePeriodoRTO.load({
    //    })
    //}

    const loadGraph = (pIdeAnnFro, pIdeTipPer) => {
        let updatedChartThree = null;
        let updatedChartFour = null;
        let updatedChartTwo = null;
        let updatedChartOne = null;
        let impresa = isIF ? user.ideCli : parseInt(Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0]._value);
        Ext.Ajax.request({
            url: 'AnalisiRto/ServiziRichiestiDettaglio',
            method: 'GET',
            params: {
                ideUte: user.ideUte,
                ideGrp: user.idGruppo,
                ideAnnFro: pIdeAnnFro,
                ideTipPer: pIdeTipPer,
                ideCli: impresa
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                if (impresa == null && !isIF) {
                    updatedChartFour = {
                        labels: ["Selezionare un'impresa ferroviaria per visualizzare il report"],
                        datasets: [
                            {
                                label: "Selezionare un'impresa ferroviaria per visualizzare il report",
                                data: [0],
                                borderWidth: 1,
                            },
                        ],
                        options: {
                            scales: {
                                y: {
                                    stacked: false,
                                    beginAtZero: true,
                                },
                                'y-axis-1': {
                                    type: 'linear',
                                    position: 'left',
                                    beginAtZero: true,
                                },
                                'y-axis-2': {
                                    type: 'linear',
                                    position: 'right',
                                    beginAtZero: true,
                                    gridLines: {
                                        drawOnChartArea: false,
                                    }
                                }
                            },
                        }
                    };
                }
                else {
                    if (returnObj.length > 0) {
                        const datiUnici = [...new Set(returnObj.map(item => item.tipoDato))];


                        const datasets = datiUnici.map(tipo => {
                            const yAxisID = tipo === 'Richieste' ? 'y-axis-1' : 'y-axis-2';
                            return {
                                label: tipo,
                                data: returnObj.filter(item => item.tipoDato === tipo).map(item => item.counterTotale),
                                borderWidth: 1,
                                yAxisID: yAxisID,
                            };
                        });


                        updatedChartFour = {
                            labels: [...new Set(returnObj.map(item => item.desrizioneServizio))], // Assicurati che le etichette siano uniche
                            datasets: datasets,
                            options: {
                                scales: {
                                    y: {
                                        stacked: false,
                                        beginAtZero: true,
                                    },
                                    'y-axis-1': {
                                        type: 'linear',
                                        position: 'left',
                                        beginAtZero: true,
                                    },
                                    'y-axis-2': {
                                        type: 'linear',
                                        position: 'right',
                                        beginAtZero: true,
                                        gridLines: {
                                            drawOnChartArea: false,
                                        }
                                    }
                                },
                            }
                        };

                    }
                    else {
                        updatedChartFour = {
                            labels: ['No dati'],
                            datasets: [
                                {
                                    label: 'No dati',
                                    data: [0],
                                    borderWidth: 1,
                                },
                            ],
                            options: {
                                scales: {
                                    y: {
                                        stacked: false,
                                        beginAtZero: true,
                                    },
                                    'y-axis-1': {
                                        type: 'linear',
                                        position: 'left',
                                        beginAtZero: true,
                                    },
                                    'y-axis-2': {
                                        type: 'linear',
                                        position: 'right',
                                        beginAtZero: true,
                                        gridLines: {
                                            drawOnChartArea: false,
                                        }
                                    }
                                },
                            }
                        };
                    }
                }
                Ext.Ajax.request({
                    url: 'AnalisiRto/RichiesteImpegnoImpiantoDettaglio',
                    method: 'GET',
                    params: {
                        ideUte: user.ideUte,
                        ideGrp: user.idGruppo,
                        ideAnnFro: pIdeAnnFro,
                        ideTipPer: pIdeTipPer,
                        ideCli: impresa
                    },
                    callback: function (records, success, operation) {
                        setStateLoadMask({ hidden: true, message: "Caricamento completato..." });
                        var returnObj = Ext.decode(operation.responseText);
                        if (impresa == null && !isIF) {
                            updatedChartThree = {
                                labels: ["Selezionare un'impresa ferroviaria per visualizzare il report"],
                                datasets: [
                                    {
                                        label: "Selezionare un'impresa ferroviaria per visualizzare il report",
                                        data: [0],
                                        borderWidth: 1,
                                    },
                                ]
                            };
                        }
                        else {
                            if (returnObj.length > 0) {
                                const datiUnici = [...new Set(returnObj.map(item => item.descPassaggio))];


                                const datasets = datiUnici.map(passaggio => {
                                    return {
                                        label: passaggio,
                                        data: returnObj.filter(item => item.descPassaggio === passaggio).map(item => item.counter),
                                        borderWidth: 1,
                                    };
                                });

                                updatedChartThree = {
                                    labels: [...new Set(returnObj.map(item => item.impegnoImpianto))], // Assicurati che le etichette siano uniche
                                    datasets: datasets
                                };

                            }
                            else {
                                updatedChartThree = {
                                    labels: ['No dati'],
                                    datasets: [
                                        {
                                            label: 'No dati',
                                            data: [0],
                                            borderWidth: 1,
                                        },
                                    ]
                                };
                            }
                        }
                        Ext.Ajax.request({
                            url: 'AnalisiRto/StatoLavorazioneImpegnoImpiantoDettaglio',
                            method: 'GET',
                            params: {
                                ideUte: user.ideUte,
                                ideGrp: user.idGruppo,
                                ideAnnFro: pIdeAnnFro,
                                ideTipPer: pIdeTipPer,
                                ideCli: impresa
                            },
                            callback: function (records, success, operation) {
                                setStateLoadMask({ hidden: true, message: "Caricamento completato..." });
                                var returnObj = Ext.decode(operation.responseText);
                                if (returnObj.length > 0) {
                                    const statiUnici = [...new Set(returnObj.map(item => item.statoImpegnoImpianto))];
                                    const sigleUniche = [...new Set(returnObj.map(item => item.siglaCliente))];

                                    const colors = {
                                        'Ok': 'rgba(52,204,52, 1)',
                                        'Ok (forzate)': 'rgba(0, 128, 0, 1)',
                                        'KO': 'rgba(255,8,8, 1)',
                                        'Non inviate': 'rgba(100,149,237, 1)',
                                        'In attesa': 'rgba(246,161,8, 1)',
                                        'In errore': 'rgba(128,128,128,1)'
                                    };

                                    const datasets = statiUnici.map(stato => {

                                        const backgroundColor = colors[stato];

                                        return {
                                            label: stato,
                                            data: sigleUniche.map(sigla => {
                                                const dataItem = returnObj.find(item => item.siglaCliente === sigla && item.statoImpegnoImpianto === stato);
                                                return dataItem ? dataItem.countImpegnoImpianto : '';
                                            }),
                                            backgroundColor: backgroundColor
                                        };
                                    });


                                    updatedChartTwo = {
                                        labels: sigleUniche,
                                        datasets: datasets,
                                    };

                                }

                                else {
                                    updatedChartTwo = {
                                        labels: ['No dati'],
                                        datasets: [
                                            {
                                                label: 'No dati',
                                                data: [0],
                                                borderWidth: 1,
                                            },
                                        ]
                                    };
                                }
                                Ext.Ajax.request({
                                    url: 'AnalisiRto/StatoInserimentoIIDettaglio',
                                    method: 'GET',
                                    params: {
                                        ideUte: user.ideUte,
                                        ideGrp: user.idGruppo,
                                        ideCli: impresa,
                                        ideAnnFro: pIdeAnnFro,
                                        ideTipPer: pIdeTipPer

                                    },
                                    callback: function (records, success, operation) {
                                        setStateLoadMask({ hidden: true, message: "Caricamento completato..." });
                                        var returnObj = Ext.decode(operation.responseText);

                                        if (returnObj.length > 0) {
                                            let responseData = returnObj;

                                            let completoData = [];
                                            let nonCompletoData = [];
                                            let labels = [];

                                            responseData.forEach(item => {
                                                let index = labels.indexOf(item.siG_CLI);
                                                if (index === -1) {
                                                    labels.push(item.siG_CLI);
                                                    completoData.push(0); // Inizializza a zero
                                                    nonCompletoData.push(0); // Inizializza a zero
                                                    index = labels.length - 1;
                                                }

                                                if (item.statO_INS_II === "Completo") {
                                                    completoData[index] += item.cnT_TOT;
                                                } else if (item.statO_INS_II === "Non completo") {
                                                    nonCompletoData[index] += item.cnT_TOT;
                                                }
                                            });



                                            updatedChartOne = {
                                                labels: labels,
                                                datasets: [
                                                    {
                                                        label: 'Completo',
                                                        data: completoData,
                                                    },
                                                    {
                                                        label: 'Non Completo',
                                                        data: nonCompletoData,
                                                    }
                                                ]
                                            };
                                        }


                                        else {
                                            updatedChartOne = {
                                                labels: ['No dati'],
                                                datasets: [
                                                    {
                                                        label: 'No dati',
                                                        data: [0],
                                                        borderWidth: 1,
                                                    },
                                                ]
                                            };
                                        }
                                        chartData.chartOneData = updatedChartOne;
                                        chartData.chartTwoData = updatedChartTwo;
                                        chartData.chartThreeData = updatedChartThree;
                                        chartData.chartFourData = updatedChartFour;
                                        setChartData({ ...chartData });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    const caricaImpresaFerroviaria = () => {
        storeImpresa.load({
            params: {
                IdeOpaRfi: user.idOperatoreRFI
            }
        })
    }
    const avviaRicerca = () => {
        let ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        let ideCli = parseInt(Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].getValue()) > 0 ? Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].getValue() : null;
        let ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;

        loadGraph(ideAnnFro, ideTipPer);
    }

    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF && ambiente.ideAnnFro !="") {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value = ambiente.ideAnnFro;

            if (ambiente.ideTipPer >= 0) {
                Ext.ComponentQuery.query('#comboPeriodoRto')[0]._value = ambiente.ideTipPer;
            }
            avviaRicerca();

            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });

        }
    };
    const aggiornaStateAmbiente = () => {
        //setStateLoadMask({ hidden: false, message: "Attendere caricamento..." });
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        stateAmbiente.ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : -1;
        setStateAmbiente({ ...stateAmbiente });
    }
    //-------Aggiornamento ------------------
    useEffect(() => {
        //caricaPeriodiFerroviari();
        //caricaPeriodoRTO();
        caricaImpresaFerroviaria();
    }, []);

    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);


    //---------------FILTRO PERIODO RTO------------------------
    function onSelectPeriodoRto(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo rto
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoRto')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoRto')[0] != null) {
                let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                if (valTipPer != ambiente.ideTipPer) {
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente DettaglioImpegnoImpianto SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente DettaglioImpegnoImpianto SPF ideTipPer = ', valTipPer);
                        }
                    );
                    avviaRicerca();
                }
            }
        }
        //----------------------------------------------------------------
    }


    function onSelectImpresa(e) {
        avviaRicerca();
        setImpresaSelezionata(true);
    }

    function onClearImpresa() {
        setImpresaSelezionata(false);
    }

    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente DettaglioImpegnoImpianto SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente DettaglioImpegnoImpianto SPR ideTipPer = ', valTipPer);
                        }
                    );

                    avviaRicerca();
                }
            }
        }
        //----------------------------------------------------------------
    }

    function onClearPeriodoRto() {
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: null }).then(
            function (result) {
                aggiornaStateAmbiente();
                //console.log('sono in updateAmbiente DettaglioImpegnoImpianto SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                //console.log('sono in updateAmbiente DettaglioImpegnoImpianto SPF ideTipPer = ', -1);
            }
        );
        avviaRicerca();
    }









    return (
        <Container cls="container-spinner" height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskComunicazioni"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-drafting-compass" />
                    <Combobox
                        itemId="comboPeriodoFerroviario"
                        width={170}
                        margin='0 10 0 0'
                        align="right"
                        editable="false"
                        store={storePeriodiFerroviari}
                        displayField="desAnnFro"
                        queryMode="local"
                        valueField="ideAnnFro"
                        onSelect={onSelectPeriodoFerroviario}
                        onChange={onSelectPeriodoFerroviario}
                    />
                    <Combobox
                        itemId="comboPeriodoRto"
                        width={150}
                        docked="right"
                        align="right"
                        editable="false"
                        clearable
                        store={storePeriodoRTO}
                        displayField="desTipPer"
                        valueField="ideTipPer"
                        queryMode="local"
                        onSelect={onSelectPeriodoRto}
                        onChange={onSelectPeriodoRto}
                        onClearicontap={onClearPeriodoRto}
                    />
                </Titlebar>
                <Toolbar docked="top" margin="10 0 10 10">
                    <Combobox itemId="comboImpresaFerroviaria" docked="left" align="left" height={25} margin='0 4 0 0' editable="false" valueField="ideCli" displayField="nomCli"
                        label="Impresa Ferroviaria"
                        labelAlign="left"
                        labelWidth={120}
                        width={350}
                        queryMode="local"
                        hidden={isIF}
                        clearable
                        forceSelection="true"
                        store={storeImpresa}
                        onSelect={onSelectImpresa}
                        onChange={onSelectImpresa}
                        onClearicontap={onClearImpresa}
                    />


                </Toolbar>
                {chartData.chartFourData != null &&
                    <Container layout="vbox" scrollable>


                        <Container layout={{ type: 'hbox', align: 'stretch' }}
                            defaults={{ flex: 1 }}
                            responsiveConfig={{
                                'width < 800': { layout: { type: 'vbox', align: 'stretch' } }
                            }}
                            scrollable="vertical"
                            minHeight={320}
                            padding="10">

                            <Panel flex="1" title="RTO Per Stato Impegno Impianto" padding="10" margin="10" >
                                <Container>
                                    <Stacked gridLabels={['Impresa']} isSwitchable={true} title="RTO Per Stato Impegno Impianto" datalabelsVisibility={false} chartData={chartData.chartOneData} aspectRatio={3}></Stacked>
                                </Container>
                            </Panel>

                        </Container>
                        <Container hidden={isIF} layout={{ type: 'hbox', align: 'stretch' }}
                            defaults={{ flex: 1 }}
                            responsiveConfig={{
                                'width < 800': { layout: { type: 'vbox', align: 'stretch' } }
                            }}
                            scrollable="vertical"
                            minHeight={320}
                            padding="10">

                            <Panel flex="1" title="Stato Lavorazione Impegno Impianto" padding="10" margin="10" >
                                <Container>
                                    <Stacked gridLabels={['Impresa']} isSwitchable={true} title="Stato Lavorazione Impegno Impianto" datalabelsVisibility={false} chartData={chartData.chartTwoData} aspectRatio={3}></Stacked>
                                </Container>
                            </Panel>

                        </Container>
                        {(isIF || impresaSelezionata) && (
                            <>
                                <Container layout={{ type: 'hbox', align: 'stretch' }}
                                    defaults={{ flex: 1 }}
                                    responsiveConfig={{
                                        'width < 800': { layout: { type: 'vbox', align: 'stretch' } }
                                    }}
                                    scrollable="vertical"
                                    minHeight={320}
                                    padding="10">

                                    <Panel flex="1" title="Sintesi Impegni Impianto Richiesti" padding="10" margin="10" >
                                        <Container>
                                            <ColumnGrouped gridLabels={['TIPO I. I.']} isSwitchable={true} title="Sintesi Impegni Impianto Richiesti" chartData={chartData.chartThreeData} aspectRatio={3}></ColumnGrouped>
                                        </Container>
                                    </Panel>

                                </Container>
                                <Container layout={{ type: 'hbox', align: 'stretch' }}
                                    defaults={{ flex: 1 }}
                                    responsiveConfig={{
                                        'width < 800': { layout: { type: 'vbox', align: 'stretch' } }
                                    }}
                                    scrollable="vertical"
                                    minHeight={320}
                                    padding="10">

                                    <Panel flex="1" title="Sintesi Servizi Richiesti" padding="10" margin="10" >
                                        <Container>
                                            <ColumnGrouped gridLabels={['Tipo Servizio']} isSwitchable={true} title="Sintesi Impegni Impianto Richiesti" chartData={chartData.chartFourData} aspectRatio={1}></ColumnGrouped>
                                        </Container>
                                    </Panel>

                                </Container>
                            </>
                        )}



                    </Container>
                }


            </Panel>
        </Container>

    );
}

export default ReportStatoLavorazione;