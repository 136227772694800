const Ext = window['Ext'];

export default Ext.define('RtoListModel', {
    extend: 'Ext.data.Model',
    requires: [
        'Ext.data.field.String',
        'Ext.data.field.Date',
        'Ext.data.field.Integer',
        'Ext.data.field.Boolean'
    ],
    fields:
        [
            { name: 'ideRto', type: 'int' },
            { name: 'ideGrpRTO', type: 'int' },
            { name: 'ideTipRTO', type: 'int' },
            { name: 'desTipVco', type: 'int' },
            { name: 'aggServiziComm', type: 'boolean' },
            { name: 'ideLtx', type: 'int' },
            { name: 'nomLtx', type: 'string' },         
            { name: 'ideCli', type: 'int' },
            { name: 'nomCli', type: 'string' },
            { name: 'ptlIF', type: 'string' },
            { name: 'datPtlIF', type: 'date' },
            { name: 'ptlRFI', type: 'string' },
            { name: 'datPtlRFI', type: 'date' },
            { name: 'desAnnFro', type: 'string' },
            { name: 'nomTreIpr', type: 'string' },
            { name: 'nomLocPrv', type: 'string' },
            { name: 'oraPar', type: 'string' },
            { name: 'nomLocDsn', type: 'string' },
            { name: 'oraArr', type: 'string' },
            { name: 'desTipPer', type: 'string' },
            { name: 'desTipRTO', type: 'string' },
            { name: 'datAtvPvv', type: 'date' },
            { name: 'datVesRto', type: 'date' },
            { name: 'desVes', type: 'string' },
            { name: 'datScd', type: 'date' },
            { name: 'ideStoRTO', type: 'int' },
            { name: 'desStoRTO', type: 'string' },
            { name: 'datPublicazionePO', type: 'date' },
            { name: 'numTrenoRFI', type: 'string' },
            { name: 'icoCer', type: 'string' },
            { name: 'tooltipCer', type: 'string' },
            { name: 'icoCir', type: 'string' },
            { name: 'tooltipCir', type: 'string' },
            { name: 'icoII', type: 'string' },
            { name: 'tooltipII', type: 'string' },
            { name: 'ideTipoInc', type: 'int' },
            { name: 'nomTipoInc', type: 'string' },
            { name: 'ideSevInc', type: 'int' },
            { name: 'icoInc', type: 'string' },
            { name: 'ideStatoInvio', type: 'int' },
            { name: 'desStatoInvio', type: 'string' },
            { name: 'flagForzaturaPUB', type: 'boolean' },
            { name: 'desTipMod', type: 'string' },
            { name: 'ideNazOri', type: 'int' },
            { name: 'desNazOri', type: 'string' },
            { name: 'sngUpdVes', type: 'string' },
            { name: 'flagInviaPUB', type: 'boolean' },
            { name: 'flgUpdVes', type: 'boolean' },
            { name: 'flgStuRto', type: 'boolean' },
            { name: 'flgCmbSto', type: 'int' },     // Flag per la fattibilità del cambio di stato dell'RTO (1 --> OK)
            { name: 'flgCmbStoFrz', type: 'int' },
            { name: 'flgFrzRto', type: 'int' },
            { name: 'flgAbiCmbSto', type: 'int' },
            { name: 'flgAbiPubTrc', type: 'int' },
            { name: 'modificaTraccia', type: 'boolean' },
            { name: 'aggiornaTraccia', type: 'boolean' },
            { name: 'flgAczVes', type: 'int' },
            { name: 'flgAscTrc', type: 'boolean' },
            { name: 'flgRtoDaPO', type: 'boolean' },
            { name: 'flgUpdTrc', type: 'int' },
            { name: 'flgPubFrzRto', type: 'int' },
            { name: 'flgTrnAsc', type: 'boolean' },
            { name: 'poPubblicato', type: 'string' },
            { name: 'RichiestaContract', type: 'string' }
        ]
});




