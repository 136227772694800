const Ext = window['Ext'];

export default Ext.define('LegamiLocalitaModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'iDE_LGM_LOC', type: 'auto' },
            { name: 'iDE_LOC', type: 'auto' },
            { name: 'nOM_LOC', type: 'auto' },
            { name: 'iDE_PRC_RTO', type: 'auto' },
            { name: 'mIN_SOSTA', type: 'auto' },
            { name: 'nUM_TRENO', type: 'auto' },
            { name: 'mAX_SOSTA', type: 'auto' },
            { name: 'pERIODICITA', type: 'auto' },
            { name: 'altraIF', type: 'auto' },
            { name: 'nOTA', type: 'auto' },
            { name: 'pTL_IPR', type: 'auto' },
            { name: 'nOM_TRE', type: 'auto' },
            { name: 'fLG_MOD', type: 'auto' },
            { name: 'lEGAME_IMPOSTO', type: 'auto' },
            { name: 'dETTAGLIO_PERIODICITA', type: 'auto' }
        ]
});
       
 
      