import { React, useEffect, useState } from 'react';
import { Container, Panel } from '@sencha/ext-react-modern';
import Pie from './charts/pie';
import Donut from './charts/donut';
import Column from './charts/column';
import ColumnGrouped from './charts/column_grouped';
import HomeComunicazioniIF from './HomeComunicazioniIF';
import News from './HomeNews';
import { useUser } from "../components/UserContext";

const Ext = window['Ext'];

function Dashboard(props) {
    const { user, switchUser } = useUser();

    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    const [showMenu, setShowMenu] = useState(false);
    const [showComunicazioni, setShowComunicazioni] = useState(true);

    const [chartOne, setChartOne] = useState(null);
    const [chartTwo, setChartTwo] = useState(null);
    const [chartThree, setChartThree] = useState(null);
    const [chartSeven, setChartSeven] = useState(null);
    const [chartFour, setChartFour] = useState(null);
    const [chartFive, setChartFive] = useState(null);
    const [chartSix, setChartSix] = useState(null);

    const aggiornaComunicazioni = (app) => {
        setShowComunicazioni(app);
    }

    let title = "Dashboard";

    useEffect(() => {
        if (Array.isArray(props.chartOneData)  && props.chartOneData.resp !== "KO") {
            if (props.chartOneData.length > 0) {
                const updatedChartOne = {
                    labels: props.chartOneData.map(item => item.tipo),
                    datasets: [{
                        data: props.chartOneData.map(item => item.totali),
                    }],
                };
                setChartOne(updatedChartOne);
            } else if (props.chartOneData.length === 0) {
                const updatedChartOne = {
                    labels: ['No dati'],
                    datasets: [{
                        data: [],
                    }],
                };
                setChartOne(updatedChartOne);
            }
        }
    }, [
        props.chartOneData
    ]);

    useEffect(() => {
        if (Array.isArray(props.chartTwoData) && props.chartTwoData.resp !== "KO") {
            if (props.chartTwoData.length > 0) {
                function transformDataForGroupedColumnChart(chartData) {
                    const uniqueReqTypes = [...new Set(chartData.map(item => item.reqtype))];
                    const uniqueYears = [...new Set(chartData.map(item => item.annO_RIF))].sort();

                    const datasets = uniqueReqTypes.map(reqtype => {
                        const data = uniqueYears.map(year => {
                            const item = chartData.find(item => item.reqtype === reqtype && item.annO_RIF === year);
                            return item ? item.cnt : 0;
                        });
                        return {
                            label: reqtype,
                            data,
                        };
                    });

                    return {
                        labels: uniqueYears,
                        datasets,
                    };
                }
                const updatedChartTwo = transformDataForGroupedColumnChart(props.chartTwoData);
                setChartTwo(updatedChartTwo);
            } else if (props.chartTwoData.length === 0) {
                const updatedChartTwo = {
                    labels: ['No dati'],
                    datasets: [{
                        data: [0],
                    }],
                };
                setChartTwo(updatedChartTwo);
            }
        }
    }, [
        props.chartTwoData
    ]);

    useEffect(() => {
        if (Array.isArray(props.chartThreeData) && props.chartThreeData.resp !== "KO") {
            if (props.chartThreeData.length > 0) {
                const statiLabels = ['Stato 0', 'Stato 1', 'Stato 2', 'Stato 3', 'Stato 4', 'Stato 5', 'Stato 6', 'Stato 7', 'Stato 8', 'Stato 9'];
                const datasets = props.chartThreeData.map(item => {
                    return {
                        label: item.deS_STATO,
                        data: [
                            item.statO_0,
                            item.statO_1,
                            item.statO_2,
                            item.statO_3,
                            item.statO_4,
                            item.statO_5,
                            item.statO_6,
                            item.statO_7,
                            item.statO_8,
                            item.statO_9
                        ],
                    };
                });

                const updatedChartThree = {
                    labels: statiLabels,
                    datasets: datasets
                };

                setChartThree(updatedChartThree);
            } else if (props.chartThreeData.length === 0) {
                const updatedChartThree = {
                    labels: ['No dati'],
                    datasets: [{
                        label: 'No dati',
                        data: [0]
                    }],
                };
                setChartThree(updatedChartThree)
            }
        }
    }, [
        props.chartThreeData
    ]);

    useEffect(() => {
        if (Array.isArray(props.chartSevenData) && props.chartSevenData.resp !== "KO") {

            if (props.chartSevenData.length > 0) {
                const updatedChartSeven = {
                    labels: props.chartSevenData.map(item => item.statO_INS_II),
                    datasets: [{
                        data: props.chartSevenData.map(item => item.cnT_TOT),
                    }]
                };
                setChartSeven(updatedChartSeven);
            } else if (props.chartSevenData.length === 0) {
                const updatedChartSeven = {
                    labels: ['No dati'],
                    datasets: [{
                        data: [0],
                    }],
                };
                setChartSeven(updatedChartSeven);

            }
        }
    }, [
        props.chartSevenData
    ]);

    useEffect(() => {
        if (Array.isArray(props.chartFourData) && props.chartFourData.resp !== "KO") {
            if (props.chartFourData.length > 0) {
                const colors = {
                    'Ok': 'rgba(52,204,52, 1)',
                    'Ok (forzate)': 'rgba(0, 128, 0, 1)',
                    'KO': 'rgba(255,8,8, 1)',
                    'Non inviate': 'rgba(100,149,237, 1)',
                    'In attesa': 'rgba(246,161,8, 1)',
                    'In errore': 'rgba(128,128,128,1)'
                };

                const updatedChartFour = {
                    labels: props.chartFourData.map(item => item.statoImpegnoImpianto),
                    datasets: [{
                        data: props.chartFourData.map(item => item.countImpegnoImpianto),
                        backgroundColor: props.chartFourData.map(item => colors[item.statoImpegnoImpianto])
                    }],
                };
                setChartFour(updatedChartFour);
            } else if (props.chartFourData.length === 0) {
                const updatedChartFour = {
                    labels: ['No dati'],
                    datasets: [{
                        data: [0],
                    }],
                };
                setChartFour(updatedChartFour);
            }
        }
    }, [
        props.chartFourData
    ]);


    useEffect(() => {
        if (props.chartFiveData) {
            if (props.chartFiveData.length > 0) {

                const impegnoImpiantoUnici = [...new Set(props.chartFiveData.map(item => item.impegnoImpianto))];


                const datasets = impegnoImpiantoUnici.map(impegno => {
                    return {
                        label: impegno,
                        data: props.chartFiveData.filter(item => item.impegnoImpianto === impegno).map(item => item.counter),
                        borderWidth: 1,
                    };
                });

                const updatedChartFive = {
                    labels: [...new Set(props.chartFiveData.map(item => item.descPassaggio))], // Assicurati che le etichette siano uniche
                    datasets: datasets
                };
                setChartFive(updatedChartFive);
            } else if (props.chartFiveData.length === 0) {
                const updatedChartFive = {
                    labels: ['No dati'],
                    datasets: [
                        {
                            label: 'No dati',
                            data: [0],
                            borderWidth: 1,
                        },
                    ]
                };
                setChartFive(updatedChartFive);
            }
        }
    }, [
        props.chartFiveData
    ]);

    useEffect(() => {
        if (props.chartSixData) {
            if (props.chartSixData.length > 0) {
                const serviziUnici = [...new Set(props.chartSixData.map(item => item.desrizioneServizio))];

                const datasetRichieste = {
                    label: 'Richieste',
                    data: [],
                    yAxisID: 'y-axis-1'
                };

                const datasetGiorni = {
                    label: 'Giorni',
                    data: [],
                    yAxisID: 'y-axis-2'
                };

                serviziUnici.forEach(servizio => {
                    const totaleRichieste = props.chartSixData.find(item => item.desrizioneServizio === servizio && item.tipoDato === "Richieste")?.counterTotale || 0;
                    datasetRichieste.data.push(totaleRichieste);

                    const totaleGiorni = props.chartSixData.find(item => item.desrizioneServizio === servizio && item.tipoDato === "Giorni")?.counterTotale || 0;
                    datasetGiorni.data.push(totaleGiorni);
                });

                const updatedChartSix = {
                    labels: serviziUnici,
                    datasets: [datasetRichieste, datasetGiorni],
                    options: {
                        scales: {
                            x: {
                                stacked: false
                            },
                            y: {
                                stacked: false
                            },
                            'y-axis-1': {
                                type: 'linear',
                                position: 'left',
                                beginAtZero: true,
                            },
                            'y-axis-2': {
                                type: 'linear',
                                position: 'right',
                                beginAtZero: true,
                                gridLines: {
                                    drawOnChartArea: false,
                                }
                            }
                        },
                    }
                }

                setChartSix(updatedChartSix);
            } else if (props.chartSixData.length === 0) {

                const updatedChartSix = {
                    labels: ['No dati'],
                    datasets: [{
                        label: 'No dati',
                        data: [0]
                    }],
                };
                setChartSix(updatedChartSix);
            }
        }
    }, [
        props.chartSixData
    ]);


    const respConfig = {
        'width < 800': { layout: { type: 'vbox', align: 'stretch' } },
        'width >= 800': { layout: { type: 'hbox', align: 'stretch' } }
    };

    return (
        <Container >
            <Container layout={{ type: 'hbox', align: 'stretch' }}
                defaults={{ flex: 1 }}
                scrollable="vertical"
                minHeight={320}
                padding="10">

                <Container layout="vbox" flex={2}>
                    {(showComunicazioni) &&
                        < Panel title="Comunicazioni alle IF" flex="2" padding="10" margin="20">
                            <Container style={{ display: 'flex', width: '100%' }} >
                                <HomeComunicazioniIF aggiornaComunicazioni={aggiornaComunicazioni}></HomeComunicazioniIF>
                            </Container>
                        </Panel>}


                    {chartOne && chartTwo && chartThree && chartSeven && chartFour && chartFive && chartSix && <Container layout="vbox" scrollable>

                        <Container
                            defaults={{ flex: 1 }}
                            responsiveConfig={respConfig}
                            scrollable="vertical"
                            minHeight={320}
                            padding="10">

                            <Panel flex="1" title="Totale RTO per Tipo Periodo RTO" padding="10" margin="10">
                                <Container>
                                    <Donut title="Totale RTO per Tipo Periodo RTO" gridLabels={['PERIODO RTO', 'TOTALE']} isSwitchable={true} height={320} chartData={chartOne} aspectRatio={2} ></Donut>
                                </Container>
                            </Panel>

                            <Panel flex="1" title="Andamento RTO per Tipo Periodo RTO" padding="10" margin="10" >
                                <Container>
                                    <ColumnGrouped title="Andamento RTO per Tipo Periodo RTO" tooltipValues={true} gridLabels={['ANNO']} isSwitchable={true} height={320} chartData={chartTwo} aspectRatio={2}></ColumnGrouped>
                                </Container>
                            </Panel>

                        </Container>

                        <Container
                            defaults={{ flex: 1 }}
                            responsiveConfig={respConfig}
                            scrollable="vertical"
                            minHeight={320}
                            padding="10">

                            <Panel flex="1" title="Totale RTO per Stato" padding="10" margin="10" >
                                <Container>
                                    <ColumnGrouped title="Totale RTO per Stato" gridLabels={['STATO']} isSwitchable={true} height={320} chartData={chartThree} aspectRatio={3}></ColumnGrouped>
                                </Container>
                            </Panel>

                        </Container>

                        <Container
                            defaults={{ flex: 1 }}
                            responsiveConfig={respConfig}
                            scrollable="vertical"
                            minHeight={320}
                            padding="10">

                            {!isIF &&
                                <Panel flex="1" title="RTO per Stato Impegno Impianto" padding="10" margin="10">
                                    <Container>
                                        <Donut title="RTO per Stato Impegno Impianto" gridLabels={['STATO I.I.', 'TOTALE']} linkedTo={'/DettaglioImpegnoImpianto'} isSwitchable={true} height={320} chartData={chartSeven} aspectRatio={1}></Donut>
                                    </Container>
                                </Panel>
                            }

                            <Panel flex="1" title="Stato Lavorazione Impegno Impianto" padding="10" margin="10">
                                <Container>
                                    <Donut title="Stato Lavorazione Impegno Impianto" gridLabels={['STATO LAVORAZIONE', 'TOTALE']} linkedTo={'/DettaglioImpegnoImpianto'} isSwitchable={true} height={320} chartData={chartFour} aspectRatio={1}></Donut>
                                </Container>
                            </Panel>


                            <Panel flex="1" title="Sintesi Impegni Impianto richiesti" padding="10" margin="10" >
                                <Container>
                                    <ColumnGrouped title="Sintesi Impegni Impianto richiesti" gridLabels={['TIPO PASSAGGIO']} linkedTo={'/DettaglioImpegnoImpianto'} isSwitchable={true} height={320} chartData={chartFive} aspectRatio={1}></ColumnGrouped>
                                </Container>
                            </Panel>

                        </Container>
                        <Container
                            defaults={{ flex: 1 }}
                            responsiveConfig={respConfig}
                            scrollable="vertical"
                            minHeight={320}
                            padding="10">

                            <Panel flex="1" title={"Sintesi Servizi Richiesti"} padding="10" margin="10"
                            >
                                <Container>
                                    {chartSix.labels[0] != 'No dati' &&
                                        <ColumnGrouped title="Sintesi Servizi Richiesti" gridLabels={['SERVIZIO']} isSwitchable={true} height={320} chartData={chartSix} aspectRatio={3} scales={chartSix.options.scales}></ColumnGrouped>
                                    }
                                </Container>
                            </Panel>

                        </Container>
                    </Container>}

                </Container>


                <Container layout="vbox" flex={1} padding="10px">
                    <News />
                </Container>


            </Container>

        </Container >

    );




}

export default Dashboard;