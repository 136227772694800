import React from 'react';
import clientiCdSFileModel from '../models/ClientiCdSFileModel';
import { useEffect, useState } from 'react';
import { Container, Grid, Column, Button, Panel, FormPanel, SelectField, FileField, ExtSegmentedbutton, ExtButton, Loadmask } from '@sencha/ext-react-modern';
import { useUser } from '../components/UserContext';

const Ext = window['Ext'];
const storeCdSFile = Ext.create('Ext.data.Store', {
    proxy: {
        url: 'Clienti/LeggiPDFdelCdS',
        method: 'GET',
        type: 'ajax',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
let ideCdS = null;

function CdsFilesDialog(props) {

    const { user, switchUser } = useUser();

    const [showAddButton, setShowAddButton] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento in corso..." });


    //READ FILE CONVERT TO ARRAY -----------
    let selectedFile = "";
    let byteArray = null;

    const aggiornaState = () => {
        setShowAddButton(storeCdSFile.data.length < 3 ? true : false);
        setShowAddForm(false);
    }

    const onAggiungiFileClick = () => {
        setShowAddForm(true);
    }


    const onSubmitClick = (btn) => {
        var nomFileCert = Ext.ComponentQuery.query('#ffUploadCdsPdf')[0]._value;
        var values = btn.up('formpanel').getValues();
        var codTipPdf = values.cmbTipoFile;


        const totalFileCount = storeCdSFile.getCount();

        if (totalFileCount >= 3) {

            Ext.Msg.alert('Errore', 'È possibile aggiungere al massimo 3 file in totale.');
        } else {

            const sameCodTipPdfCount = storeCdSFile.query('codTipPdf', codTipPdf).getCount();

            if (sameCodTipPdfCount >= 1) {

                Ext.Msg.alert('Errore', 'È possibile aggiungere al massimo un file per ogni tipo di CdS.');
            } else {

                setStateLoadMask({ hidden: false, message: "Attendere inserimento in corso..." });
                Ext.Ajax.request({
                    url: 'Clienti/InserisciPdf',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        IP: "test",
                        IdeCef: props.ideCef,
                        nomFileCert: props.numCef + "-" + codTipPdf + ".pdf",
                        codTipPdf: codTipPdf,
                        fileCds: byteArray
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            if (returnObj.resp === "OK") {
                                Ext.toast({ message: 'File:' + nomFileCert + ' inserito con successo', timeout: 4000 });
                                caricaCdSFile(props.ideCef);
                                console.log('File:' + nomFileCert + ' inserito con successo');
                            } else {
                                Ext.Msg.alert('Inserimento fallito', returnObj.msgErr);
                            }
                        } else {
                            Ext.Msg.alert('Inserimento fallito', returnObj.msgErr);
                        }
                        setStateLoadMask({ hidden: true });
                    }
                });
            }
        }
    };




    const caricaCdSFile = (ideCef) => {
        storeCdSFile.load({
            params: {
                ideCef: ideCef
            },
            callback: function () {
                aggiornaState();
            }
        })
    }

    useEffect(() => {
        caricaCdSFile(props.ideCef);
        ideCdS = props.ideCef;
    }, [props.ideCef]);

    const onEliminaFileClick = (grid, info) => {
        setStateLoadMask({ hidden: false, message: "Attendere cancellazione in corso..." });
        Ext.Ajax.request({
            url: 'Clienti/EliminaPdf',
            method: 'get',
            params: {
                ideUte: user.ideUte,
                IP: "test",
                idFile: info.record.data.idPdfCert
            },
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);
                    if (returnObj.resp == "OK") {
                        Ext.toast({ message: 'File:' + info.record.data.nomFileCert + ' eliminato con successo', timeout: 4000 });
                        caricaCdSFile(ideCdS);
                        //console.log('File id:' + info.record.data.idPdfCert + ' eliminato con successo');
                    }
                    else
                        Ext.Msg.alert('Cancellazione fallita', returnObj.msgErr);
                } else {
                    Ext.Msg.alert('Cancellazione fallita', returnObj.msgErr);
                    //console.log('Errore: Non è stato possibile eliminare il file');
                }
                setStateLoadMask({ hidden: true });
            }
        });
    }

    const onViewFileClick = (grid, info) => {
        setStateLoadMask({ hidden: false, message: "Attendere apertura in corso..." });
        Ext.Ajax.request({
            url: 'Clienti/GetFilePdfCdS',
            method: 'get',
            params: { idFile: info.record.data.idPdfCert },
            callback: function (records, success, operation) {
                if (success) {
                    console.log('File id:' + info.record.data.idPdfCert + ' scaricato con successo');
                    var returnObj = Ext.decode(operation.responseText);
                    if (returnObj.resp == "OK") {
                        // Converti la stringa Base64 in un array di byte
                        let ba = new Uint8Array(atob(returnObj.file).split('').map(char => char.charCodeAt(0)));
                        openInNewTab(ba)
                    }
                    else
                        Ext.Msg.alert('Lettura pdf fallita', returnObj.msgErr);
                }
                else {
                    Ext.Msg.alert('Lettura pdf fallita', returnObj.msgErr);
                }
                setStateLoadMask({ hidden: true });
            }
        });
    }

    const renderCdSType = (value) => {
        switch (value) {
            case 'U':
                return 'UNICO';
            case 'A':
                return 'PARTE A';
            case 'B':
                return 'PARTE B';
            default:
                return value;
        }
    };

    const options = [
        { text: 'UNICO', value: 'U' },
        { text: 'PARTE A', value: 'A' },
        { text: 'PARTE B', value: 'B' }
    ].filter(option => {
        const codTipPdfPresenti = storeCdSFile.collect('codTipPdf');
        return !codTipPdfPresenti.includes(option.value);
    });

    const fileToByteArray = (args) => {
        selectedFile = args.newValue;
        if (selectedFile !== "") {
            var fileInput = args.sender.el.down('input[type=file]').dom;
            if (fileInput.files.length > 0) {
                var file = fileInput.files[0];

                reader(file, (err, res) => {
                    if (err != null) {
                        Ext.toast({ message: 'Errore: ' + err, timeout: 4000 });
                    }
                    else {
                        let base64result = res.split(',')[1];
                        byteArray = base64result;
                        Ext.toast({ message: 'File Letto!', timeout: 4000 });
                    }
                });        
            }
        }
    }
    function reader(file, callback) {
        const fr = new FileReader();
        fr.onload = () => callback(null, fr.result);
        fr.onerror = (err) => callback(err);
        fr.readAsDataURL(file);
    }

    const openInNewTab = (byteArray) => {
        // Creare un Blob dal tuo array di byte
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Aprire una nuova scheda del browser con il PDF
        var url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');

        // Rilasciare l'URL dell'object quando non è più necessario
        URL.revokeObjectURL(url);
    };

    return (

        <Container layout="vbox">
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
            <Grid
                itemId="gridCdSFile"
                flex={1}
                markDirty={true}
                store={storeCdSFile}
                minHeight='200'
                height='200'
                scrollable
                loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                cls="column-in-panel"
                selectable={{ checkbox: false, mode: 'single' }}
                plugins={{ gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" } }}
                itemConfig={{
                    viewModel: true
                }}
            >
                <Column text="CdS" dataIndex="codTipPdf" flex={1} renderer={renderCdSType} />
                <Column text="Nome File" dataIndex="nomFileCert" flex={1} />
                <Column text="Data Upload" dataIndex="dataUpload" flex={1} />
                <Column text="Azioni"
                    cell={{
                        tools: {
                            
                            viewFile: {
                                handler: onViewFileClick.bind(this),
                                bind: {
                                    iconCls: "x-fa fa-folder-open",
                                    tooltip: 'Visualizza'
                                }
                            },
                            eliminaFile: {
                                handler: onEliminaFileClick.bind(this),
                                bind: {
                                    iconCls: "x-fa fa-trash",
                                    tooltip: 'Elimina'
                                }
                            }
                        }
                    }}
                />

            </Grid>

            {showAddButton &&
                <Panel
                    margin="10">

                    <Button disabled={showAddForm} text="Aggiungi File" ui="action" cls="marginButton appButton" iconCls="x-fa fa-plus" handler={onAggiungiFileClick} />

                </Panel>
            }

            {showAddForm &&
                <FormPanel
                    title="Aggiungi File"
                    bodyPadding={10}
                    margin="10"
                    shadow
                    defaults={{ labelAlign: 'top', required: true }}
                >
                    <SelectField
                        itemId="tipoFileCdsPdf"
                        label='Tipo File'
                        name="cmbTipoFile"
                        options={options}
                        displayField="text"
                        valueField="value"
                    />
                    <FileField
                        itemId="ffUploadCdsPdf"
                        name="ffFileName"
                        cls="fileFieldButton marginButton "
                        buttontext="Carica File"
                        title='title'
                        label="File..."
                        accept='application/pdf'
                        margin="0 10 0 0"
                        onChange={fileToByteArray}
                        onAction={fileToByteArray}
                    />
                    <ExtSegmentedbutton>
                        <ExtButton cls="marginButton appButton" align="right" text="Carica" ui="action" handler={onSubmitClick} />
                    </ExtSegmentedbutton>
                </FormPanel>
            }
        </Container>

    );
}

export default CdsFilesDialog;