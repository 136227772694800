import { useState, React, useEffect, useReducer } from 'react';
import { Container, Panel, Titlebar, Grid, Button, Loadmask, Progress, Dialog, SegmentedButton, Column, Toolbar, ExtButton, ComboBoxField, Combobox } from '@sencha/ext-react-modern';
import { useUser } from '../components/UserContext';
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';
import { useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import SchedaRto from '../rto/Rto';
import AuthPCS from '../pages/HomePage'
//import * as XLSX from 'xlsx'; //serve per leggere e trasformare file Excel in array

const Ext = window['Ext'];
let selezioneMultipla = false;

Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.plugin.Exporter'
]);

const storeDossier = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'RtoInternazionali/CercaDossiers',
        reader: {
            type: 'json'
        },
        timeout: 600000
    },
    autoload: false
});

const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const setBtnSelRecord = (numRecord) => {
    Ext.ComponentQuery.query('#btnSelRecordDossier')[0].setText("Righe selezionate: " + numRecord);
}

const setBtnTotRecord = (numRecord) => {
    Ext.ComponentQuery.query('#btnTotRecordDossier')[0].setText("Totale righe: " + numRecord);
}

const setBtnErrRecord = (numRecord) => {
    Ext.ComponentQuery.query('#btnTotErrRecordDossier')[0].setText("Totale righe in errore: " + numRecord);
}

const storePCSTipoProcesso = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'RtoInternazionali/LeggiPCSTipoProcesso',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storePCSTipoTreno = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'RtoInternazionali/LeggiPCSTipoTreno',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storePCSFasiDossier = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'RtoInternazionali/LeggiFasiDossier',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storePCSStatiImportazione = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'RtoInternazionali/LeggiStatoImportazione',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});


function CaricaStoreFiltri(ideUte) {
    const caricaPCSTipoProcesso = (paramsIdeUde) => {
        storePCSTipoProcesso.load({
            params: {
                ideUte: paramsIdeUde
            }
        })
    }

    const caricaPCSTipoTreno = (paramsIdeUde) => {
        storePCSTipoTreno.load({
            params: {
                ideUte: paramsIdeUde
            }
        })
    }

    const caricaPCSFasiDossier = (paramsIdeUde) => {
        storePCSFasiDossier.load({
        })
    }

    const caricaPCSStatiImportazione = (paramsIdeUde) => {
        storePCSStatiImportazione.load({
            params: {
                ideUte: paramsIdeUde
            }
        })
    }


    caricaPCSTipoProcesso(ideUte);
    caricaPCSTipoTreno(ideUte);
    caricaPCSFasiDossier(ideUte);
    caricaPCSStatiImportazione(ideUte);
}

const handlePulisciFiltri = (btn) => {
    Ext.ComponentQuery.query('#cmbProcess')[0].setValue(null);
    Ext.ComponentQuery.query('#cmbTrain')[0].setValue(null);
    Ext.ComponentQuery.query('#cmbPhase')[0].setValue(null);
    Ext.ComponentQuery.query('#cmbStatoImp')[0].setValue(null);
};


function AcqDossierPCS() {
    const { user, switchUser } = useUser();
    const { ambiente, updateAmbiente } = useAmbiente();
    const { periodi } = usePeriodi();

    storePeriodiFerroviari.setData(periodi.periodiFerroviari);

    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento dossier in corso..." });
    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });

    let tokenApp = null;
    const navigate = useNavigate();
    let numRecordText = "Totale : "
    let numRecordSelText = "Selezionate : "
    let numRecordErrText = "In errore : "
    //----------------------------
    let title = "Acquisizione Dossier PCS";
    let ui = 'action';
    const [paramsDetail, setParamsDetail] = useState({ ideRTO: 0, ideGrpRTO: 0, ideCli: 0, flgUpdVes: null, flgModTrc: null, flgUpdTrc: null, tipoAzione: "" })
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    const [state, setState] = useState({ percentuale: null, totale: null, completate: null, testo: null })
    const [showDialogDetailRTO, setShowDialogDetailRTO] = useState(false);

   
    //const caricaPeriodiFerroviari = () => {
    //    storePeriodiFerroviari.load({            
    //    })
    //}

    const aggiornaState = (result) => {
        state.totale = result[0].totale;
        state.percentuale = result[0].percentuale;
        state.completate = result[0].completate;
        state.testo = "Importazione in corso " + result[0].percentuale + "% (" + result[0].completate + " di " + result[0].totale + ")...";

        //  x = state.uploadXlsInfo.data.pctCmp
        setState({ ...state });
    }

    const aggiornaStateFine = () => {
        state.totale = null;
        state.percentuale = null;
        state.completate = null;
        state.testo = null;
        //  x = state.uploadXlsInfo.data.pctCmp
        setState({ ...state });
        if (storeDossier.data.items.length > 0) {
            autenticazionePCS().then(function () {
                caricaDossier();
            });
        }
    }

    //const caricaProgress = () => {
    //    const interval = setInterval(() => {
    //        Ext.Ajax.request({
    //            url: 'RtoInternazionali/GetBarraPercentuale',
    //            method: 'GET',
    //            params: {
    //                ideUte: user.ideUte
    //            },
    //            callback: function (records, success, operation) {
    //                if (success) {
    //                    var returnObj = Ext.decode(operation.responseText);
    //                    if (returnObj[0].totale === returnObj[0].completate) {
    //                        return () => clearInterval(interval);
    //                    }
    //                    else {
    //                        aggiornaState(returnObj);
    //                    }
    //                }
    //            }
    //        })
    //    }, 6000);

    //    return () => clearInterval(interval);
    //};

    const caricaProgress = () => {
        return new Promise(function (resolve, reject) {
        const interval = setInterval(() => {
            Ext.Ajax.request({
                url: 'RtoInternazionali/GetBarraPercentuale',
                method: 'GET',
                params: {
                    ideUte: user.ideUte
                },
                callback: function (records, success, operation) {
                    if (success) {
                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                        var returnObj = Ext.decode(operation.responseText);
                        if (returnObj[0].totale === returnObj[0].completate) {
                            aggiornaStateFine();
                            clearInterval(interval);
                        } else {
                            aggiornaState(returnObj);
                        }
                    }
                }
            });
        }, 6000);

        // Restituisci la funzione di clearInterval (se necessario)
            return () => clearInterval(interval);
        })
    };

    
    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ambiente.ideAnnFro);

            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });

        }
    };
    const aggiornaStateAmbiente = () => {
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        setStateAmbiente({ ...stateAmbiente });
    }

    useEffect(() => {
        //caricaPeriodiFerroviari();
        CaricaStoreFiltri(user.ideUte);
        caricaProgress();
        storeDossier.clearData();
        storeDossier.removeAll();       
    }, []);

    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);
   

    const caricaDossier = () => {
        setStateLoadMask({ hidden: false, message: "Caricamento..." });
        var annoA = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value;
        var faseA = Ext.ComponentQuery.query('#cmbPhase')[0]._value;
        var statoImportazioneA = Ext.ComponentQuery.query('#cmbStatoImp')[0]._value != null ? Ext.ComponentQuery.query('#cmbStatoImp')[0]._value : -1;
        var idTipTreA = Ext.ComponentQuery.query('#cmbTrain')[0]._value != null ? Ext.ComponentQuery.query('#cmbTrain')[0]._value : -1;
        var tipProcA = Ext.ComponentQuery.query('#cmbProcess')[0]._value;
        storeDossier.load({
            params: {
                anno: annoA,
                fase: faseA,
                statoImportazione: statoImportazioneA,
                idTipTre: idTipTreA,
                tipProc: tipProcA,
                ideUte: user.ideUte,
                token: tokenApp
            },
            callback: function (records, options, success) {
                setStateLoadMask({ hidden: true, message: "Attendere avvio acquisizione in corso..." });
                if (success) {
                    setBtnSelRecord(0);
                    if (records.length < 1) {
                        setBtnTotRecord(0);
                        setBtnErrRecord(0);
                    }
                    else {
                        setBtnTotRecord(records.length);
                        let countInErrore = 0;
                        for (let i = 0; i < records.length; i++) {
                            if (records[i].data.idStatoImportazione === 3) {
                                countInErrore++;
                            }
                        }
                        setBtnErrRecord(countInErrore);
                    }
                }
                else {
                    Ext.Msg.alert('Elenco Dossier', 'Errore caricamento Dossier');
                }
            }

        })
    }

    const handleSubmitCerca = (btn) => {
        
        tokenApp = null;
        autenticazionePCS().then(function () {

            caricaDossier();
        });
    };

    const autenticazionePCS = () => {
        return new Promise(function (resolve, reject) {
            Ext.Ajax.request({
                url: 'RtoInternazionali/AutenticazionePCS',
                method: 'GET',
                params: {
                    ideUte: user.ideUte
                },
                callback: function (records, success, operation) {
                    var returnObj = Ext.decode(operation.responseText);
                    if (returnObj === "" || returnObj === null) {
                        setStateLoadMask({ hidden: true, message: "Caricamento..." });
                        navigate('/AuthPCS');
                        return;
                    }
                    tokenApp = returnObj;
                    resolve();
                }
            });
        })
    };

    const getIconClass = (ideSto) => {
        switch (ideSto) {
            case 'A':
                return 'x-fa fa-circle green';
            case 'R':
                return 'x-fa fa-circle red';
            case 'P':
                return 'x-fa fa-circle yellow';
            case 'U':
                return 'x-fa fa-circle blue';
            default:
                return '';
        }
    };

    const getIconClassStar = (flag) => {
        switch (flag) {
            case 0:
                return '';
            case 1:
                return 'x-fa fa-star grey';
            default:
                return '';
        }
    };

    const renderCellWithIconsIF = (record) => {
        let arraySemoforiIF = record.data.iF_progressStatus.split(';');
        let arrayAgzIF = record.data.nomiAgzIpr.split(';');
        let arrayStar = record.data.idAgzIpr.split(';');
        let star = record.data.idApplicantLeader;
        let arraStarApp = [];
        for (let i = 0; i < arrayStar.length; i++) {
            if (arrayStar[i] === star)
                arraStarApp.push(1)
            else
                arraStarApp.push(0)
        }
        return (
            <div>
                {arraySemoforiIF.map((semoforo, index) => (
                    <div key={index}>
                        <span className={getIconClass(semoforo)} />{' '}
                        <span className={getIconClassStar(arraStarApp[index])} />{' '}
                        <span>{arrayAgzIF[index]}</span>
                    </div>
                ))}
            </div>
        );
    };

    const renderOrigine = (record) => {
        let arrayOrigine = record.data.nomeLoc.split(';');
        return (
            <div>
                {arrayOrigine.map((localita, index) => (
                    <div key={index}>
                        <span>{localita}</span>
                    </div>
                ))}
            </div>
        );
    };
    
    const renderPhase = (record) => {
        let arrayPhase = record.data.desFase.split(';');
        return (
            <div>
                {arrayPhase.map((phase, index) => (
                    <div key={index}>
                        <span>{phase}</span>
                    </div>
                ))}
            </div>
        );
    };

    const handleClick = (ideRTO, ideCli) => {
        paramsDetail.ideRTO = ideRTO;
        paramsDetail.ideGrpRTO = user.idTipoGruppo;
        paramsDetail.ideCli = ideCli;
        paramsDetail.flgUpdVes = false;
        paramsDetail.flgModTrc = false;
        paramsDetail.flgUpdTrc = false;
        paramsDetail.tipoAzione = "A";
        paramsDetail.modificaServiziCommerciali = false;
        setParamsDetail({ ...paramsDetail });
        setShowDialogDetailRTO({ showDialogDetailRTO: true });
    };
    
    const renderProtocolloIF = (record) => {
        let arrayProtocolliIF = record.data.protocolliIF.split(';');
        let arrayIdeRTO = record.data.idRTO.split(';');
        let arrayIdeCli =  record.data.idCli.split(';')
        return (
            <div>
                {arrayProtocolliIF.map((protocollo, index) => (
                    <div key={index} style={{ marginBottom: '10px'}}>
                        {protocollo !== "" && <Button cls="marginButton appButton" ui="action" text={protocollo} onTap={() => handleClick(arrayIdeRTO[index], arrayIdeCli[index])}></Button>}
                    </div>
                ))}
            </div>
        );
    };
    
    const renderStato = (record) => {
        let arrayStato = record.data.idStatoRTO.split(';');
        return (
            <div>
                {arrayStato.map((stato, index) => (
                    <div key={index} style={{ marginBottom: '10px', height: '30px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} >{stato}</div>
                    </div>
                ))}
            </div>
        );
    };

    const renderCellWithIconsGI = (record) => {
        let arraySemoforiGI = record.data.gI_progressstatus.split(';');
        let arrayAgzGI = record.data.nomiAgzGsr.split(';');
        let arrayStar = record.data.idAgzGsr.split(';');
        let star = record.data.giLeader;
        let arraStarApp = [];
        for (let i = 0; i < arrayStar.length; i++) {
            if (arrayStar[i] === star)
                arraStarApp.push(1)
            else
                arraStarApp.push(0)
        }
        return (
            <div>
                {arraySemoforiGI.map((semoforo, index) => (
                    <div key={index}>
                        <span className={getIconClass(semoforo)} />{' '}
                        <span className={getIconClassStar(arraStarApp[index])} />{' '}
                        <span>{arrayAgzGI[index]}</span>
                    </div>
                ))}
            </div>
        );
    };

    function onDownloadClick() {
        var grid = Ext.ComponentQuery.query('#gridAcqDossierPCS')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: 'Acquisizione Dossier',
            fileName: 'AcquisizioneDossier.xlsx'
        });
    }

    function acquisisci(elencoAggiorna, elencoInserisci) {
        autenticazionePCS().then(function () {
            Ext.Ajax.request({
                url: 'RtoInternazionali/AcquisisciDossiers',
                method: 'POST',
                params: {
                    listaDossierInserisci: elencoInserisci,
                    listaDossierAggiorna: elencoAggiorna,
                    ideUte: user.ideUte,
                    anno: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value,
                    token: tokenApp
                },
                callback: function (records, success, operation) {
                    if (success) {
                        caricaProgress().then(function () {
                            var returnObj = Ext.decode(operation.responseText);
                            //modifica gestione errore
                            if (returnObj.resp === "KO") {
                                Ext.Msg.alert('Avvio acquisizione fallito', returnObj.msgErr)
                            }
                            else {
                                caricaDossier();
                            }
                        })
                    }
                    else {
                        Ext.Msg.alert('Avvio acquisizione fallito', 'Avvio acquisizione fallito');
                    }
                }
            })

        });
    }

    function onMenuAcquisisciClick(item) {
        setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
        var grid = Ext.ComponentQuery.query('#gridAcqDossierPCS')[0];
        var stringaAlert = "";
        var gridSelection = grid.getSelections();
        if (gridSelection.length > 0) {
            var elencoAggiorna = "";
            var elencoInserisci = "";
            for (let i = 0; i < gridSelection.length; i++) {
                if (gridSelection[i].data.idStatoImportazione === 3 || gridSelection[i].data.idStatoImportazione === 0)
                    elencoInserisci = elencoInserisci + gridSelection[i].data.idDossier + ";";
                else if (gridSelection[i].data.idStatoImportazione === 2 || gridSelection[i].data.idStatoImportazione === 1)
                    elencoAggiorna = elencoAggiorna + gridSelection[i].data.idDossier + ";";
            }
            for (let j = 0; j < gridSelection.length; j++) {
                if (gridSelection[j].data.idTipDossier === 'F' && (gridSelection[j].data.idStatoImportazione === 2 || gridSelection[j].data.idStatoImportazione === 1)) {
                    stringaAlert = stringaAlert + "Il dossier " + gridSelection[j].data.idDossier + " &egrave; stato annullato su PCS. L'acquisizione determiner&agrave; l'annullamento delle relative RTO su AstroIF.<br>";
                }
                if ((gridSelection[j].data.iF_progressStatus.split(';').includes('R') || gridSelection[j].data.gI_progressstatus.split(';').includes('R')) && (gridSelection[j].data.idStatoImportazione === 2 || gridSelection[j].data.idStatoImportazione === 1)) {
                    stringaAlert = stringaAlert + "Il dossier " + gridSelection[j].data.idDossier + " &egrave; stato rifiutato su PCS. L'acquisizione determiner&agrave; il rifiuto delle relative RTO su AstroIF.<br>";
                }
            }
            if (stringaAlert !== "") {
                stringaAlert = stringaAlert + "<br>Procedere comunque con l'aggiornamento?<br>";
                Ext.Msg.confirm("Attenzione", stringaAlert, function (btn) {
                    if (btn === 'yes') {
                        acquisisci(elencoAggiorna, elencoInserisci);
                    }
                    else {
                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                    }
                });
            } else {
                acquisisci(elencoAggiorna, elencoInserisci);
            }
        }
        else {
            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
            Ext.Msg.alert('Nessuna selezione', 'Selezionare un dossier dalla lista');
        }
    }

    function hideDialogDetailRTO() {
        setShowDialogDetailRTO({ showDialogDetailRTO: false });
        //caricaRtoList(params.filtro);
    }

    function findMaxDateItem(items) {
        if (items.length === 0) {
            return null; // Handle the case where the array is empty
        }

        // Initialize the max date item
        let maxDateItem = items[0];

        // Iterate over the items to find the one with the highest date
        for (let i = 1; i < items.length; i++) {
            if (new Date(items[i].datIniVal) > new Date(maxDateItem.datIniVal)) {
                maxDateItem = items[i];
            }
        }

        return maxDateItem;
    }

    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
                let maxDateItem = findMaxDateItem(Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getStore().data.items);
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != maxDateItem.data.ideAnnFro) {
                    Ext.ComponentQuery.query('#buttonAcquisisci')[0].disable();
                }
                else {
                    Ext.ComponentQuery.query('#buttonAcquisisci')[0].enable();
                }
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = stateAmbiente.ideTipPer;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente AcqDossierPCS SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente AcqDossierPCS SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }        
    }

    //----------------------------------------------------------------
    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1}>
                    <Button align="left" cls="title-icon" iconCls="fa-lg fa fa-cloud-download-alt" />
                    <Combobox
                        align="right"
                        width={170}
                        itemId="comboPeriodoFerroviario"
                        margin='0 4 0 0'
                        editable="false"
                        valueField="ideAnnFro"
                        displayField="desAnnFro"                        
                        queryMode="local"
                        store={storePeriodiFerroviari}
                        onSelect={onSelectPeriodoFerroviario}  
                    />                  
                </Titlebar>
                <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
                    <Loadmask
                        cls="custom-grid"
                        itemId="loadMaskClientiReferenti"
                        hidden={stateLoadMask.hidden}
                        indicator={true}
                        message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                        modal={true}
                    />
                    <Grid
                        flex={1}
                        scrollable
                        variableHeights={true}
                        itemId="gridAcqDossierPCS"
                        store={storeDossier}
                        selectable={{ checkbox: true, mode: 'multi' }}
                        plugins={{ gridfilters: {}, gridexporter: {} }}
                        // onSelect= {onItemclick}
                        itemConfig={{
                            viewModel: true
                        }}
                        listeners={{
                            beforeselect: (checkColumn, rowIndex, checked, record, eOpts) => {
                                if (selezioneMultipla === false) {
                                    selezioneMultipla = true;
                                    if (rowIndex.length === 1) {
                                        if (rowIndex[0].data.flgImr === 0) {
                                            checkColumn.getSelected().remove(rowIndex);
                                            selezioneMultipla = false;
                                            Ext.toast({ message: 'Riga non selezionabile', timeout: 3000 });
                                            setBtnSelRecord(checkColumn.getSelected().items.length);
                                            return false;
                                        }
                                        else {
                                            setBtnSelRecord(checkColumn.getSelected().items.length);
                                        }
                                    }
                                    else {
                                        checkColumn.getSelected().removeAll();
                                        let arraySelezione = [];
                                        for (let j = 0; j < rowIndex.length; j++) {
                                            if (rowIndex[j].data.flgImr !== 0) {
                                                arraySelezione.push(rowIndex[j]);
                                            }
                                        }
                                        if (arraySelezione.length === 0) {
                                            Ext.toast({ message: 'Nessuna riga selezionabile', timeout: 3000 });
                                        }
                                        checkColumn.select(arraySelezione);
                                        selezioneMultipla = false;
                                        setBtnSelRecord(checkColumn.getSelected().items.length);
                                        return false;
                                    }
                                    setBtnSelRecord(checkColumn.getSelected().items.length);
                                    selezioneMultipla = false;
                                }
                            },
                            beforedeselect: (checkColumn, rowIndex, checked, record, eOpts) => {
                                if (selezioneMultipla === false) {
                                    selezioneMultipla = true;
                                    setBtnSelRecord(checkColumn.getSelected().items.length);
                                    selezioneMultipla = false;
                                }
                            },
                        }}
                        cls="custom-grid custom-gridRTO"
                        loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                    >
                        <Toolbar docked="top" margin="2 0 2 0" cls="lottoToolbar">


                            <SegmentedButton docked="right" margin='0 0 10 0'>
                                <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                                <ExtButton cls="marginButton appButton nuvola" align="right" width={160} text="Acquisisci Dossier" itemId = "buttonAcquisisci" iconCls={'fas fa-cloud-download-alt'} ui="action" handler={onMenuAcquisisciClick} />
                            </SegmentedButton>
                            <SegmentedButton docked="left">
                                <ExtButton cls="ricercaAvanzataButton" itemId="btnTotRecordDossier" align="left" margin="2 2 2 2" text={numRecordText}></ExtButton>
                                <ExtButton cls="ricercaAvanzataButton" itemId="btnSelRecordDossier" align="left" margin="2 2 2 2" text={numRecordSelText}></ExtButton>
                                <ExtButton cls="ricercaAvanzataButton" itemId="btnTotErrRecordDossier" align="left" margin="2 2 2 2" text={numRecordErrText}></ExtButton>
                            </SegmentedButton>
                        </Toolbar>
                        <Toolbar docked="top" margin="2 0 0 0" cls="lottoToolbar">


                            <SegmentedButton docked="right">
                                <ExtButton cls="marginButton ricercaAvanzataButton" align="right" text="Pulisci filtri" iconCls={'fa-solid fa-filter-circle-xmark'} ui="action" handler={handlePulisciFiltri} />
                                <ExtButton cls="marginButton appButton" align="right" width={160} text="Avvia ricerca" iconCls={'x-fa fa-search'} ui="action" handler={handleSubmitCerca} />
                            </SegmentedButton>
                            <Panel layout="hbox">
                                <ComboBoxField
                                    label="Process Type"
                                    labelCls='LabelRiepilogo'
                                    labelAlign="left"
                                    labelWidth={90}
                                    margin="0 10 0 0"
                                    store={storePCSTipoProcesso}
                                    displayField="desTipPoeEng"
                                    valueField="ideTipPoe"
                                    queryMode="local"
                                    clearable
                                    forceSelection="true"
                                    itemId="cmbProcess"
                                />
                                <ComboBoxField
                                    label="Train Type"
                                    labelCls='LabelRiepilogo'
                                    labelAlign="left"
                                    margin="0 10 0 0"
                                    labelWidth={70}
                                    store={storePCSTipoTreno}
                                    displayField="desTipTreEng"
                                    valueField="ideTipTrePCS"
                                    queryMode="local"
                                    clearable
                                    forceSelection="true"
                                    itemId="cmbTrain"
                                />
                                <ComboBoxField
                                    label="Phase"
                                    labelCls='LabelRiepilogo'
                                    labelAlign="left"
                                    store={storePCSFasiDossier}
                                    displayField="desFaeEng"
                                    labelWidth={45}
                                    valueField="sigFae"
                                    queryMode="local"
                                    margin="0 10 0 0"
                                    clearable
                                    forceSelection="true"
                                    itemId="cmbPhase"
                                />
                                <ComboBoxField
                                    label="Stato Importazione"
                                    labelCls='LabelRiepilogo'
                                    labelAlign="left"
                                    store={storePCSStatiImportazione}
                                    displayField="desStoImr"
                                    labelWidth={120}
                                    valueField="ideStoImr"
                                    queryMode="local"
                                    clearable
                                    forceSelection="true"
                                    itemId="cmbStatoImp"
                                />
                            </Panel >
                        </Toolbar>


                        <Column width={100} height="40" text="N.Dossier" dataIndex="idDossier" filter='string' />
                        <Column width={100} height="40" text="N.Treno" dataIndex="numTreRFI" filter='string' />
                        <Column width={115} height="40" text="Dossier Name" dataIndex="nomeDossier" filter='string' renderer={(value, metaData, record) => (
                            <div dangerouslySetInnerHTML={{ __html: value }} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} />
                        )} />
                        <Column width={230} height="40" text="Route" dataIndex="percorso" filter='string' renderer={(value, metaData, record) => (
                            <div dangerouslySetInnerHTML={{ __html: value }} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} />
                        )} />
                        <Column width={115} height="40" text="Process Type" dataIndex="desProcessType" filter='string' renderer={(value, metaData, record) => (
                            <div dangerouslySetInnerHTML={{ __html: value }} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} />
                        )} />
                        <Column width={120} height="40" text="Dossier Type" dataIndex="desTipDossier" filter='string' />
                        <Column width={100} height="40" text="Train Type" dataIndex="desTipTre" filter='string' />
                        <Column width={230} height="40" text="IF" dataIndex="numTrcAsc" filter='string'
                            cell={{
                                xtype: 'reactcell',
                                renderer: (value, record) => renderCellWithIconsIF(record),
                            }}
                        />
                        <Column width={230} height="40" text="GI" dataIndex="numTrcInv" filter='string'
                            cell={{
                                xtype: 'reactcell',
                                renderer: (value, record) => renderCellWithIconsGI(record),
                            }} />
                        <Column width={200} height="40" text="Origine" dataIndex="nomeLoc" filter='string' renderer={ (value, record) => renderOrigine(record)} />
                        <Column width={110} height="40" text="Versione PCS" dataIndex='progrVersioneDossier' filter='string' />
                        <Column width={200} height="40" text="Phase" dataIndex='desFase' filter='string' renderer={(value, record) => renderPhase(record)} />
                        <Column width={170} height="40" text="Stato Importazione" dataIndex='desStatoImportazione' filter='string' renderer={(value, record) => {
                            return (
                                <div>
                                        <div style={{ marginBottom: '10px', height: '30px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} >{value + (record.data.messaggioErrore !== "" ? " " + record.data.messaggioErrore : "")}</div>
                                        </div>
                                </div>
                            );
                        }
} />
                        <Column width={190} height="40" text="Versione Importata" dataIndex='prgVersioneDossierImportato' filter='string' renderer={(value, record) => {
                            return (
                                <div>
                                    <div style={{ marginBottom: '10px', height: '30px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} >{value === 0 ? "" : value}</div>
                                    </div>
                                </div>
                            );
                        }
                        } />
                        <Column width={100} height="40" text="Stato RTO" dataIndex='idStatoRTO' filter='string' renderer={(value, record) => renderStato(record)} />
                        <Column width={200} height="40" text="Protocollo IF" dataIndex='protocolliIF' filter='string' renderer={(value, record) => renderProtocolloIF(record)} />
                    </Grid>
                    <Container margin='0 0 4 0' height={30} docked="bottom" layout="hbox" cls="title-text" flex={1}>
                        <Progress flex={1} value={state.percentuale / 100.0} text={state.testo} />
                    </Container>
                </Container>
            </Panel>
            {showDialogDetailRTO.showDialogDetailRTO && <Dialog
                displayed={true}
                maximized={true}
            >
                <SchedaRto parRto={paramsDetail} hideDialogDetailRTO={hideDialogDetailRTO} />

            </Dialog>
            }
        </Container>
    );


}

export default AcqDossierPCS;

