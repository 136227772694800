import React from 'react';
import { createContext, useState } from 'react';

const CopiaRtoContext = createContext(undefined);

//const VersioneRtoProvider = ({ children }) => {
//    const [state, setState] = useState(null);

//    return (
//        <VersioneRtoProvider.Provider
//            value = {[state, setState]}>
//            {children}
//        </VersioneRtoProvider.Provider >
//    )
//}

export { CopiaRtoContext };
