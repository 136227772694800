import React from 'react'

const GuidaUpload = () => {
  return (
      <div>
          <h1 style={{ textAlign:"center" }}>Guida Upload</h1>
          <p style={{ textAlign: "center" }}>
              inserire testo
          </p>
      </div>
  )
}

export default GuidaUpload