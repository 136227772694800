import React, { useEffect, useState } from "react";
import { Container, Loadmask, Panel } from "@sencha/ext-react-modern";
import Bottoni from "./Bottoni";
import { useUser } from "../components/UserContext";
const Ext = window["Ext"];
function Guide({ getDocumento }) {
  const [pagine, setPagine] = useState([]);
  const [hiddenLoadMask, setHiddenLoadMask] = useState(false);
  const { user } = useUser();
  const ideUte = user?.ideUte;
  useEffect(() => {
    async function fetchData() {
      try {
        await Ext.Ajax.request({
          url: "HelpOnLine/GetAllHelpOnLine",
          method: "GET",
          params: {
            ideUte: ideUte,
          },
          callback: function (records, success, operation) {
            if (success && records != null) {
              //console.log(Ext.decode(operation.responseText)?.sort((a, b) => a.idTipoGuida - b.idTipoGuida))
              setPagine(Ext.decode(operation.responseText)?.sort((a, b) => a.idTipoGuida - b.idTipoGuida));
            }
            else{
              console.error("Nessun dato presente:", success, records);
            }
          },
        });
        setHiddenLoadMask(true);
      } catch (error) {
        console.error("Errore durante il recupero delle guide:", error);
        setHiddenLoadMask(true);
      }
    }
    fetchData();
    
  }, [ideUte]);

  const tipoGuidaSet = new Set(pagine?.map((pagina) => pagina.descTipoGuida));
  const tipiGuida = Array.from(tipoGuidaSet);
  const loadMaskMessage = "Attendi...";
  return (
    <Container
      height="100%"
      width="100%"
      layout={{ type: "vbox", pack: "top" }}
      flex={1}
      style={{
        backgroundImage: "url(/sfondo2.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      
        {hiddenLoadMask ? (
          <Container defaults={{ flex: 1, bodyPadding: 10, shadow: true }}>
          <Panel
            title={"GUIDE"}
            layout={{ type: "hbox" }}
            cls="guideContainer"
            scrollable
          >
            {tipiGuida.map((descTipoGuida, i) => (
            <Bottoni
              key={`${i.toString()}_${descTipoGuida}`}
              descTipoGuida={descTipoGuida}
              pagine={pagine}
              getDocumento={getDocumento}
            />
            ))}
          </Panel>
          </Container>
        ) : (
          <Loadmask
            cls="custom-grid doc-grid"
            itemId="loadMaskDOC"
            hidden={false}
            indicator={true}
            message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class="spinMessage">${loadMaskMessage}</span></div>`}
            modal={true}
          />
        )}
      
    </Container>
  );
}

export default Guide;
