import React from 'react';
import { Panel, Grid, Column, Container, Combobox, FormPanel } from '@sencha/ext-react-modern';
import impostazioneModel from '../models/ImpostazioneModel.js';

const Ext = window['Ext'];

function PoImpostazione(props) {

    const storeImpostazione = Ext.create('Ext.data.Store', {
        model: impostazioneModel,
        data: props.schedaPo.schede.impostazioni        
    });

    return (
        <Container itemId={"pnlImpostazione" + props.ind}>
        <Panel
            itemId={"poImpostazione" + props.ind}
            title={props.schedaPo.testataPO.desTrcOra}
            titleCollapse={true}
            cls='PnlSectionPOTR'
            iconCls="x-fa fa-train"  
            collapsible={{ collapsed: false, direction: 'top' }}
        >

            <Panel layout="vbox" flex={1} cls="column-in-panel">
                <Grid
                    itemId={"poImpostazioneGrid" + props.ind}                    
                    minHeight='150'
                    height='150'
                    flex={1}
                    scrollable
                    selectable={{ checkbox: false, mode: 'single' }}
                    store={storeImpostazione}
                    plugins={['gridfilters']}
                    itemConfig={{
                        viewModel: true
                    }}
                    cls="custom-grid"
                >
                        <Column text="Da Loc." dataIndex="nomLocPrv" filter='string' width={250} />
                        <Column text="A Loc." dataIndex="nomLocDsn" filter='string' width={250} />
                        <Column text="Motrice" dataIndex="desLcm" width={150} />
                        <Column text="Cod. Imp." dataIndex="imp" width={90} />                   
                        <Column text="Massa bloc." dataIndex="pesRmc" renderer={function (value, metaData, records, view) {
                            return value === -1 ? "" : value;
                        }} width={120} />
                        <Column text="% Massa frenata" dataIndex="pctPesFre"  width={100} />
                        <Column text="Vel." dataIndex="velMaxTre" width={80} />
                        <Column text="Rango" dataIndex="ran" filter='string' width={90} />
                        <Column text="Lunghezza Treno" dataIndex="lunghTreno" filter='string' width={120} />
                        <Column text="Note" dataIndex="note" filter='string' flex={1} />
                </Grid>
            </Panel>

        </Panel>
    </Container>
    );
}

export default PoImpostazione;
