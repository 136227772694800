import react, { useState, useContext } from "react";
import { Panel, FormPanel, Container, Button, ComboBoxField, CheckBoxField, DatePickerField, FileField, Spacer, Grid, Column, FieldSet, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import Calendario from "../components/Calendario/Calendario/Calendario.js";
import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
import Stazioni from "../components/Calendario/Stazioni/Stazioni.js";
import serviziInfrLocModel from '../models/ServiziInfrLocModel.js';
import { useUser } from '../components/UserContext';
import periodicitaModel from '../models/PeriodicitaIIServiziModel.js';

const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

const storePeriodicitaIIServizi = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});


function PoIILegami(props) {
 
    const [datiCalendario, setDatiCalendario] = useState({ isCalendarioVisible: false, titolo: "Periodicita legame ..", dataInizio: null, dataFine: null, periodicita: "", periodicitaSintetico: "", giorniTreno: 0 });

    const contextVersioneRto = useContext(VersioneRtoContext);
    const poSchede = props.storePoSchede;
    const listaPO = contextVersioneRto.listaPO;
    //const rtoSchede = contextVersioneRto.storeRtoSchede;
    const { user, switchUser } = useUser();
    let arrayLocalitaDisponibili = [];
    let arrayServiziDisponibili = [];
    let arrayAreeManovraLoc = [];

    function onPeriodicitaClick(grid, info) {
        //caricaPeriodicitaServizoImpianto(info.record.data.idE_SER_IFS_LOC);
        datiCalendario.titolo = "Periodicit&aacute legame " + info.record.data.nuM_TRENO + " - " + info.record.data.noM_LOC;

        datiCalendario.periodicitaSintetico = info.record.data.snG_GIO_SET;
        datiCalendario.giorniTreno = info.record.data.toT_GIO;
        datiCalendario.periodicita = info.record.data.snG_PER_ERR;
        datiCalendario.dataInizio = info.record.data.daT_INI_VAL;
        datiCalendario.dataFine = info.record.data.daT_FIN_VAL;
        datiCalendario.isCalendarioVisible = true;

        setDatiCalendario({ ...datiCalendario });
    }
    
    const storePeriodicitaIILegamiPO = Ext.create('Ext.data.Store', {
        proxy: {
            type: 'localstorage',
        },
        autoload: false
    });

    const storeLegamiImpiantoPO = Ext.create('Ext.data.Store', {
        proxy: {
            type: 'localstorage',
        },
        autoload: false
    });

    const caricaLegamiImpianto = () => {
        storeLegamiImpiantoPO.loadData(props.impegnoImpiantoPO.legamiLocalita);
    }
    caricaLegamiImpianto();

    function onApriRTOClick(grid, info) {
        window.open("/SchedaRtoLettura?ideRto=" + info.record.data.idE_RTO_LEG + "&ideCli=" + user.ideCli, '_blank');
    }    

    return (
    <Container>
        <Panel
            title="Legami Impianto"
            titleCollapse={true}
            iconCls="x-fa fa-link"
            collapsible={{ collapsed: false, direction: 'top' }}
            cls='PnlSectionPOL2 iconPanelsRTO'
        >
            <Container
                layout="vbox" itemId="containerLegamiPo" fitToParent={true} cls="column-in-panel"
            >
                <Grid
                    itemId="gridLegamiImpiantoPo"
                    minHeight={100}
                    maxHeight={230}
                        disabled={datiCalendario.isCalendarioVisible}
                    markDirty={true}
                    flex={1}
                    scrollable
                    selectable={{ checkbox: false, mode: 'single' }}
                    store={storeLegamiImpiantoPO}
                    itemConfig={{
                        viewModel: true
                        }}
                    cls="custom-grid"
                >

                    <Column text="Localita" dataIndex="noM_LOC" filter='string' />
                    <Column text="Tempo min Sosta" dataIndex="miN_SOSTA" filter='string' flex={1} />
                    <Column text="N.Treno / RTO" dataIndex="nuM_TRENO" filter='string' flex={1} />
                    <Column itemId='apriPOLegame' flex={1} align="center"
                        cell={{

                            tools: {

                                apriRTO: {
                                    handler: onApriRTOClick.bind(this),
                                    bind: {
                                        iconCls: "x-fa fa-folder-open buttonGridTool",
                                        tooltip: 'Apri RTO Legata',
                                        hidden: '{record.idE_RTO_LEG === "" || record.idE_RTO_LEG === null || record.idE_RTO_LEG === 0}',
                                    }

                                }
                            }

                        }}

                    />
                    <Column text="Legame Indotto" dataIndex="legamE_IMPOSTO" filter='string' />
                    <Column text="Tempo max proseg." dataIndex="maX_SOSTA" filter='string' flex={1} />
                    <Column text="Altra IF" dataIndex="altraIF" filter='string' flex={1} />
                    <Column text="Note Altra IF" dataIndex="nota" filter='string' flex={1} />
                    <Column text="Periodicit&aacute" dataIndex="periodicita" filter='string' flex={1} editable={false} cell={{
                        tools: {

                            visualizzaPeriodicita: {

                                handler: onPeriodicitaClick.bind(this),

                                bind: {

                                    iconCls: "x-fa fa-calendar buttonMerciTool",

                                    tooltip: 'Periodicita'
                                }
                            }

                        }

                    }}
                />      
                </Grid>
                {datiCalendario.isCalendarioVisible && (
                    <Panel title={datiCalendario.titolo} itemId="panelCalendarioPerServIIPo" closable={true} listeners=
                        {
                            {
                                close: function () {
                                    setDatiCalendario({ isCalendarioVisible: false, titolo: "Periodicita legame ..", dataInizio: null, dataFine: null, periodicita: "", periodicitaSintetico: "", giorniTreno: 0 });
                                }
                            }}
                    >
                        <Panel collapsible={false} cls="calendar-container">
                            <br />
                            <div className="periodicitaHeader">
                                <CalendarioSintetico
                                    giorniTreno={datiCalendario.giorniTreno}
                                    periodicitaSintetico={datiCalendario.periodicitaSintetico}
                                />
                            </div>
                        </Panel>
                        <Panel collapsible={false}>
                            <Calendario
                                dataInizio={datiCalendario.dataInizio}
                                dataFine={datiCalendario.dataFine}
                                periodicita={datiCalendario.periodicita}
                                dataAttProvvedimento={null}
                                periodicitaSintetico={datiCalendario.periodicitaSintetico}
                                IsNonEffettuabile={true}
                                isAttivazioneProvvedimento={false}
                            />
                        </Panel>
                    </Panel>)
                }
            </Container>
        </Panel>
    </Container>
    );
}

export default PoIILegami;