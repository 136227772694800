import React, { useState, useMemo, useEffect } from 'react';
import '../App.css';
import { Fieldset, ComboBoxField, TimeField, Button, Spacer, Container } from '@sencha/ext-react-modern';
import { useUser } from '../components/UserContext';
//import { faTrain } from '@fortawesome/fontawesome-free';

const Ext = window['Ext'];

const storeLocalita = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiLocalita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const caricaLocalita = (paramsLoc) => {
    storeLocalita.load({
        params: paramsLoc
    });
}


function ODComponent(props) {// { visibility }) {
    const { user, switchUser } = useUser();
    const [localitaO, setLocalitaO] = useState(-1);
    const [localitaD, setLocalitaD] = useState(-1);
    const [oraPart, setOraPart] = useState("");
    const [oraArri, setOraArri] = useState("");
    const [isHiddenButtonReverse, setIsHiddenButtonReverse] = useState(true);
   

    const handlelocalitaOChange = (e) => {
        if (e) {  
            setLocalitaO(parseInt(e.newValue)); 
            //props.onODSelected(parseInt(e.newValue), localitaD, oraPart, oraArri);
        }
    }; 
    const handlelocalitaDChange = (e) => {
        if (e) {
            setLocalitaD(parseInt(e.newValue)); 
            //props.onODSelected(localitaO, parseInt(localitaO, e.newValue), oraPart, oraArri);
        }
    };
    const handleOraParChange = (e) => {
        if (e) {
            setOraPart(e.newValue);
            //props.onODSelected(localitaO, localitaD, oraPart, oraArri);
        }
    };
    const handleOraArrChange = (e) => {
        if (e) {
            setOraArri(e.newValue);
            //props.onODSelected(localitaO, localitaD, oraPart, oraArri);
        }
    };


    if (storeLocalita.data.length <= 0) {
        caricaLocalita(user.ideUte);
    }

    function invertiLocalita(e) {
        if (e.parent._items.items[1] !== "" && e.parent._items.items[3] !== "") {
            setLocalitaO(parseInt(e.parent._items.items[2]._value));
            setLocalitaD(parseInt(e.parent._items.items[0]._value));
        }
    }

    function SetFiltriODPrescelti(origine, destino, oraP, oraA) {
        if (Ext.ComponentQuery.query('#itemIdOrigine')[0] != undefined && origine) {
            Ext.ComponentQuery.query('#itemIdOrigine')[0].setValue(origine);            
        }
        if (Ext.ComponentQuery.query('#itemIdDestino')[0] != undefined && destino) {
            Ext.ComponentQuery.query('#itemIdDestino')[0].setValue(destino);           
        }
        if (Ext.ComponentQuery.query('#itemIdOraPar')[0] != undefined && oraP) {
            const data = new Date(oraP);
            const ore = String(data.getHours()).padStart(2, '0');
            const minuti = String(data.getMinutes()).padStart(2, '0');
            const oraFormattata = `${ore}:${minuti}`;
            Ext.ComponentQuery.query('#itemIdOraPar')[0].setValue(oraFormattata);            
        }
        if (Ext.ComponentQuery.query('#itemIdOraArr')[0] != undefined && oraA) {
            const data = new Date(oraA);
            const ore = String(data.getHours()).padStart(2, '0');
            const minuti = String(data.getMinutes()).padStart(2, '0');
            const oraFormattata = `${ore}:${minuti}`;
            Ext.ComponentQuery.query('#itemIdOraArr')[0].setValue(oraFormattata);            
        }
        setIsHiddenButtonReverse(!props.isVisibleOrigineDaFiltro || !props.isVisibleDestinoDaFiltro);
    }

     //handler={() => invertiLocalita()}  oppure  handler={invertiLocalita}

    //let isHiddenButton = (props.isHiddenDestino || props.isHiddenOrigine);

    //if (props.flagRefreshODComponent)
    //    isHiddenButton = !props.isVisibleOrigineDaFiltro || !props.isVisibleDestinoDaFiltro;



    //const isHiddenButton = (props.isHiddenDestino || props.isHiddenOrigine) || (!props.isVisibleOrigineDaFiltro && !props.isVisibleDestinoDaFiltro);

    useEffect(() => {
        setIsHiddenButtonReverse(props.isHiddenDestino || props.isHiddenOrigine);
    }, [props.isHiddenOrigine, props.isHiddenDestino]); 


    useEffect(() => {
        SetFiltriODPrescelti(props.flagRefreshODComponent.valOrigine, props.flagRefreshODComponent.valDestino, props.flagRefreshODComponent.orapartenza, props.flagRefreshODComponent.oraarrivo);
    }, [props.flagRefreshODComponent]); 

    return (
            <Container layout="hbox" align="stretch">
                <ComboBoxField
                    label="Origine"
                    store={storeLocalita}
                    displayField="nomLoc"
                    labelCls="titleItemForm"
                    valueField="ideLoc"
                    queryMode="local"
                    labelAlign="top"
                    clearable
                    forceSelection="true"
                    name="cmbOrigine"
                    itemId="itemIdOrigine"
                    onChange={handlelocalitaOChange}
                    value={localitaO}
                    hidden={props.isHiddenOrigine && !props.isVisibleOrigineDaFiltro}
                    height={props?.fieldHeight}
                />
                <Button cls="buttonInvertiLocalita" hidden={isHiddenButtonReverse} iconCls={'fa-right-left'} handler={invertiLocalita} />
                <ComboBoxField
                    label="Destino"
                    store={storeLocalita}
                    displayField="nomLoc"
                    valueField="ideLoc"
                    margin="0 10 0 0"
                    queryMode="local"
                    labelCls="titleItemForm"
                    labelAlign="top"
                    clearable
                    forceSelection="true"
                    name="cmbDestino"
                    itemId="itemIdDestino"
                    onChange={handlelocalitaDChange}
                    value={localitaD}
                    height={props?.fieldHeight}
                    hidden={props.isHiddenDestino && !props.isVisibleDestinoDaFiltro}
                />
                <Spacer maxHeight={9} maxWidth={9} ></Spacer>
                <TimeField labelCls="titleItemForm"
                    margin="0 10 0 10"
                    label="Ora partenza"
                    format='H:i'
                    picker={{
                        meridiem: false,
                        alignPMInside: true
                    }}
                    name="oraPar"
                    itemId="itemIdOraPar"
                    hidden={props.isHiddenOraPartenza && !props.isVisibleOraPartenzaDaFiltro}
                    onChange={handleOraParChange}
                    height={props?.fieldHeight}
                />
                <TimeField labelCls="titleItemForm"
                    margin="0 0 0 10"
                    label="Ora arrivo"
                    format='H:i'
                    picker={{
                        meridiem: false,
                        alignPMInside: true
                    }}
                    name="oraArr"
                    itemId="itemIdOraArr"
                    hidden={props.isHiddenOraArrivo && !props.isVisibleOraArrivoDaFiltro}
                    onChange={handleOraArrChange}
                    height={props?.fieldHeight}
                />
            </Container>
    )    
}
export default ODComponent;


//isVisibleOrigineDaFiltro = { getODComponentVisible(27) }
//isVisibleDestinoDaFiltro = { getODComponentVisible(28) }
//isVisibleOraPartenzaDaFiltro = { getODComponentVisible(29) }
//isVisibleOraArrivoDaFiltro = { getODComponentVisible(30) }