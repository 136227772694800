import { Button } from "@sencha/ext-react-modern";
export const NotificationButton = ({
  count,
  cls,
  iconCls,
  page,
  name,
  funzione,
  isHome = false,
}) => {
    function formatCount(count) {
        if (count >= 1000) {
            // Dividi per 1000, arrotonda all'intero e aggiungi "K"
            return Math.round(count / 1000) + "K";
        } else {
            // Restituisci il numero originale se � inferiore a 1000
            return count;
        }
    }
    const text = count !== "0" || isHome ? formatCount(count) : ""
    //console.log("text", text)
  return (
    <Button
      text={text}
      cls={cls}
      iconCls={iconCls}
      handler={() => funzione(page)}
      margin="0"
      align="right"
      tooltip={name}
    />
  );
};
