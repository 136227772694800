const Ext = window['Ext'];

export default Ext.define('PeriodoFerroviarioAttivoModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideAnnFro', type: 'int' },
            { name: 'desAnnFro', type: 'string' },
            { name: 'datIniVal', type: 'date', format: 'd/m/Y' },
            { name: 'datFinVal', type: 'date', format: 'd/m/Y' },
            { name: 'datAde', type: 'date', format: 'd/m/Y' }
        ]
});
