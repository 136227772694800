const Ext = window['Ext'];

export default Ext.define('ServiziInfrLocModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'idE_SER_IFS_LOC', type: 'auto' },
            { name: 'idE_PRC_RTO', type: 'auto' },
            { name: 'idE_LOC', type: 'auto' },
            { name: 'noM_LOC', type: 'auto' },
            { name: 'idE_SER_IFS', type: 'auto' },
            { name: 'noM_SER_IFS', type: 'auto' },
            { name: 'urL_SER_IFS', type: 'auto' },
            { name: 'id_FASCIO', type: 'auto' },
            { name: 'noM_FASCIO', type: 'auto' },
            { name: 'id_AREA', type: 'auto' },
            { name: 'noM_AREA', type: 'auto' },
            { name: 'id_BINARIO', type: 'auto' },
            { name: 'noM_BINARIO', type: 'auto' },
            { name: 'periodicita', type: 'auto' },
            { name: 'deTTAGLIO_PERIODICITA', type: 'auto' },
            { name: 'durata', type: 'auto' },
            { name: 'snG_GIO_SET', type: 'auto' },
            { name: 'snG_PER_ERR', type: 'auto' },
            { name: 'daT_INI_VAL', type: 'date', format: 'd/m/Y' },
            { name: 'DAT_FIN_VAL', type: 'date', format: 'd/m/Y' },
        ]
});
