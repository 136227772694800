import { 
    svg_0,
    svg_1,
    svg_2,
    svg_3_0123,
    svg_3_01243,
    svg_3_012453,
    svg_3_013,
    svg_4,
    svg_5,
    svg_6_012456,
    svg_6_01245686,
    svg_7_0124567,
    svg_7_012456867,
    svg_7_01245687,
    svg_7_012457,
    svg_8,
    svg_9_01245689,
    svg_9_0124569,
    semaforo_gen_green,
    semaforo_gen_red,
    semaforo_gen_white,
    semaforo_gen_yellow
} from "./Svg";
export const stati = [
    {
        nomeStato: '0',
        svg: svg_0,
    },
    {
        nomeStato: '1',
        svg: svg_1,
    },
    {
        nomeStato: '2',
        svg: svg_2,
    },
    {
        nomeStato: '3_0123',
        svg: svg_3_0123,
    },
    {
        nomeStato: '3_01243',
        svg: svg_3_01243,
    },
    {
        nomeStato: '3_012453',
        svg: svg_3_012453,
    },
    {
        nomeStato: '3_013',
        svg: svg_3_013,
    },
    {
        nomeStato: '4',
        svg: svg_4,
    },
    {
        nomeStato: '5',
        svg: svg_5,
    },
    {
        nomeStato: '6_012456',
        svg: svg_6_012456,
    },
    {
        nomeStato: '6_01245686',
        svg: svg_6_01245686,
    },
    {
        nomeStato: '7_0124567',
        svg: svg_7_0124567,
    },
    {
        nomeStato: '7_012456867',
        svg: svg_7_012456867,
    },
    {
        nomeStato: '7_01245687',
        svg: svg_7_01245687,
    },
    {
        nomeStato: '7_012457',
        svg: svg_7_012457,
    },
    {
        nomeStato: '8',
        svg: svg_8,
    },
    {
        nomeStato: '9_01245689',
        svg: svg_9_01245689,
    },
    {
        nomeStato: '9_0124569',
        svg: svg_9_0124569,
    }
];

export const semafori = [
    {
        nomeStato: 'gen_green',
        svg: semaforo_gen_green,
    },
    {
        nomeStato: 'gen_red',
        svg: semaforo_gen_red,
    },
    {
        nomeStato: 'gen_white',
        svg: semaforo_gen_white,
    },
    {
        nomeStato: 'gen_yellow',
        svg: semaforo_gen_yellow,
    }   
];
