const Ext = window['Ext'];

export default Ext.define('CorrispondenzeModel', {
    extend: 'Ext.data.Model',
    fields:
        [
            { name: 'ideCrpPrc', type: 'auto' },
            { name: 'ideTipCrp', type: 'auto' },
            { name: 'desTipCrp', type: 'auto' },
            { name: 'temCrp', type: 'auto' },
            { name: 'nomTreCrp', type: 'auto' },
        ]
});
