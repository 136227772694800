import React from 'react';
import { Container, SearchField, Panel, Button, Titlebar, Combobox, Loadmask } from '@sencha/ext-react-modern';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useUser } from '../components/UserContext';
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';
import RtoList from './RtoList';
import periodoFerroviarioModel from '../models/PeriodoFerroviarioModel.js';
import RtoFiltriVeloci from './RtoFiltriVeloci';
import RtoFiltriAvanzati from './RtoFiltriAvanzati';


const Ext = window['Ext'];

//--------------------------------------------------------------------- Store
const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    model: periodoFerroviarioModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//const caricaPeriodiFerroviari = () => {
//    storePeriodiFerroviari.load({
//    })
//}
const storePeriodoRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiePeriodicita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//const caricaPeriodoRTO = () => {
//    storePeriodoRTO.load({
//    })
//}

//----------------------------------------------------------------------
function MainRto(props) {

    let title = "Gestione RTO";

    const { state } = useLocation(); //From Lotto, Report dettaglio (e report Generale?)

    const { user, switchUser } = useUser();
    const { ambiente, updateAmbiente } = useAmbiente();
    const { periodi } = usePeriodi();

    storePeriodiFerroviari.setData(periodi.periodiFerroviari);
    storePeriodoRTO.setData(periodi.periodoRTO);

    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: false, message: "Attendere prego ..." });

    const [filtro, setFiltro] = useState({
        "ideUte": parseInt(user.ideUte),
        "ideCli": -1,
        "ideLtx": -1,
        "ideTipPer": stateAmbiente.ideTipPer,
        "ideStoRto": -1,
        "ideTipRTO": -1,
        "ideTipTraccia": -1,
        "numeroTrenoIF": "",
        "protocolloIF": "",
        "protocolloRFI": "",
        "idePri": -1,
        "ideAnnFro": stateAmbiente.ideAnnFro,
        "ideLocOrg": -1,
        "orarioPrt": "",
        "ideLocDes": -1,
        "orarioArr": "",
        "ideVesRto": -1,
        "ideVesRtoAlt": -1,
        "datPtlIpr": null,
        "datPtlIprFin": null,
        "datVes": null,
        "datVesFin": null,
        "datScd": null,
        "datScdFin": null,
        "datPtlRFI": null,
        "datPtlRFIFin": null,
        "ideStoDes": -1,
        "ideNazOri": -1,
        "pAscTrc": null,
        "pIdeUplTrc": null,
        "idTipoTraffico": -1,
        "pTrn": null,
        "pFlgPubTrc": null,
        "pFlgCnt": null,
        "pFlgIpgImt": null,
        "statoInvioII": null,
        "pIdeStoIvoPub": null,
        "pNomKatRto": null,
        "pNomTreRfi": null,
        "listaProtRFI": null,
        "tipoVCO": 0,
        "tipoModifica": 0,
        "pFlgFrzPub": null,
        "pFlgIpgImtCnt": null,
        "pFlgIpgImtSer": null,
        "pFlgIpgImtLeg": null,
        "pFlgIpgImtMnv": null,
        "ideTrafPrev": -1,
        "pFlgIncongruenzaPO": null,
        "ideCliList": "",
        "strRicercaUniversale": ""
    })

    const [lista, setLista] = useState({ showLista: false, reload: false, filtro: filtro, filterChanged: 0 })
    const [paramsFiltroScelto, setParamsFiltroScelto] = useState({ idFiltroRapido: -1, nomeFiltroRapido: "", contenutoFiltro: null, collapseRicerca: true, isFiltroPredefinito: false })
    const [refreshTree, setRefreshTree] = useState(false);
    const [callHandlePulisciFiltri, setCallHandlePulisciFiltri] = useState(false);
    const [doRicerca, setDoRicerca] = useState(0);
   
    const [paramsFiltroDaLinkLotto, setParamsFiltroDaLinkLotto] = useState({ ideAnnFro: filtro.ideAnnFro, ideLotto: -1, idIF: -1, pagina: "" });
    const [paramsFiltroDaLinkReportDettaglio, setParamsFiltroDaLinkReportDettaglio] = useState({ annoFer: filtro.ideAnnFro, idIF: -1, tipRTO: -1, periodoRTO: -1, statoRTO: -1, pagina: "" });
    const [paramsFiltroDaLinkReportGenerale, setParamsFiltroDaLinkReportGenerale] = useState({ annoFer: filtro.ideAnnFro, tipRTO: -1, periodoRTO: -1, statoRTO: -1, pagina: "" });
    const [paramsFiltroDaLinkReportStatoLavorazione, setParamsFiltroDaLinkReportStatoLavorazione] = useState({ annoFer: filtro.ideAnnFro, periodoRTO: -1, idTipoTrafficoPrev: -1, idIF: -1, statoRTO: -1, flagModificata: false, flagPubblicata: false, pagina: "" });
    
    const [childrenBkp, setChildrenBkp] = useState(null); 

    //useEffect(() => {
    //    caricaPeriodiFerroviari();
    //    caricaPeriodoRTO();
    //}, []);


    const PulisciFiltro = (ideAnnFro, ideTipPer) => {
        setFiltro(
            {
                "ideUte": parseInt(user.ideUte),
                "ideCli": -1,
                "ideLtx": -1,
                "ideTipPer": ideTipPer,
                "ideStoRto": -1,
                "ideTipRTO": -1,
                "ideTipTraccia": -1,
                "numeroTrenoIF": "",
                "protocolloIF": "",
                "protocolloRFI": "",
                "idePri": -1,
                "ideAnnFro": ideAnnFro,
                "ideLocOrg": -1,
                "orarioPrt": "",
                "ideLocDes": -1,
                "orarioArr": "",
                "ideVesRto": -1,
                "ideVesRtoAlt": -1,
                "datPtlIpr": null,
                "datPtlIprFin": null,
                "datVes": null,
                "datVesFin": null,
                "datScd": null,
                "datScdFin": null,
                "datPtlRFI": null,
                "datPtlRFIFin": null,
                "ideStoDes": -1,
                "ideNazOri": -1,
                "pAscTrc": null,
                "pIdeUplTrc": null,
                "idTipoTraffico": -1,
                "pTrn": null,
                "pFlgPubTrc": null,
                "pFlgCnt": null,
                "pFlgIpgImt": null,
                "statoInvioII": null,
                "pIdeStoIvoPub": null,
                "pNomKatRto": null,
                "pNomTreRfi": null,
                "listaProtRFI": null,
                "tipoVCO": 0,
                "tipoModifica": 0,
                "pFlgFrzPub": null,
                "pFlgIpgImtCnt": null,
                "pFlgIpgImtSer": null,
                "pFlgIpgImtLeg": null,
                "pFlgIpgImtMnv": null,
                "ideTrafPrev": -1,
                "pFlgIncongruenzaPO": null,
                "ideCliList": "",
                "strRicercaUniversale": ""
            }
        );
    }

    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente MainRTO SPF ideAnnFro = ', e.newValue.data.ideAnnFro);
                            //console.log('sono in updateAmbiente MainRTO SPF ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
        else {

        }
        //----------------------------------------------------------------
    }
    function onSelectPeriodoRto(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo rto
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoRto')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoRto')[0] != null) {
                let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                if (valTipPer != stateAmbiente.ideTipPer) {
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente MainRTO SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente MainRTO SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
        //----------------------------------------------------------------
    }
    function onClearPeriodoRto() {
        filtro.ideTipPer = -1;
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: null }).then(
            function (result) {
                aggiornaStateAmbiente();
                //console.log('sono in updateAmbiente MainRTO SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                //console.log('sono in updateAmbiente MainRTO SPF ideTipPer = ', -1);               
            }
        );
    }

    function forzaAmbiente(ideAnnFroValue, ideTipPerParValue) {
        if (ambiente?.ideUte) {
            handleLoadMask(false);
            stateAmbiente.ideAnnFro = ideAnnFroValue;
            stateAmbiente.ideTipPer = ideTipPerParValue;
            filtro.ideAnnFro = ideAnnFroValue;
            filtro.ideTipPer = ideTipPerParValue;
            setFiltro({ ...filtro });
            setStateAmbiente({ ...stateAmbiente });
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ideAnnFroValue);
            if (ideTipPerParValue >= 0) {
                Ext.ComponentQuery.query('#comboPeriodoRto')[0].setValue(ideTipPerParValue);
            }
            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });
        }
    }

    
    const handleFiltroChanged = (value) => {
        //lista.filterChanged = (JSON.stringify(lista.filtro) !== JSON.stringify(value)) ? true : false;  // imposta flag sul cambiamnto del filtro
        lista.filterChanged = (lista.filterChanged <= 10000) ? lista.filterChanged + 1 : 0;
        lista.filtro = value;
        lista.showLista = true;
        lista.reload = true;
        setFiltro(value);
        setLista({ ...lista });
        Ext.ComponentQuery.query('#findRto')[0].setValue("");
    };

    const handleRefreshTree = (boolValue) => {
        if (boolValue) {
            setRefreshTree(true);
        }
        else {
            setRefreshTree(false);
            //setCallHandlePulisciFiltri(true);
        }
    };

    const updateFlagPulisciFiltri = (value) => {
        setCallHandlePulisciFiltri(value);
    };
    
    const handleFiltroSelected = (value1, value2, value3, value4) => {
        paramsFiltroScelto.idFiltroRapido = value1;
        paramsFiltroScelto.nomeFiltroRapido = value3;
        paramsFiltroScelto.contenutoFiltro = value2;
        paramsFiltroScelto.collapseRicerca = false;
        paramsFiltroScelto.isFiltroPredefinito = value4;
        setParamsFiltroScelto({ ...paramsFiltroScelto });
    };

    const onSearchRto = () => {
        var ric = Ext.ComponentQuery.query('#findRto')[0].getValue();
        // Se non si vuole tenere conto dei filtri avanzati eseguire la pulizia dei parametri da escludere
        PulisciFiltro(stateAmbiente.ideAnnFro, stateAmbiente.ideTipPer);
        filtro.ideUte = parseInt(user.ideUte);
        filtro.ideAnnFro = stateAmbiente.ideAnnFro;
        filtro.ideTipPer = stateAmbiente.ideTipPer;
        filtro.strRicercaUniversale = ric;
        setFiltro({ ...filtro });
        // --
        //lista.filterChanged = (JSON.stringify(lista.filtro) !== JSON.stringify(filtro)) ? true : false;  // imposta flag sul cambiamnto del filtro
        lista.filterChanged = lista.filterChanged + 1;
        lista.filtro = filtro;
        lista.showLista = true;
        lista.reload = true;
        setLista({ ...lista });

        setCallHandlePulisciFiltri(true);
    };

    const handleFiltroDaLinkLotto = (ideAnnFro, ideLotto, idIF, pagina) => {
        paramsFiltroDaLinkLotto.ideAnnFro = ideAnnFro;
        paramsFiltroDaLinkLotto.ideLotto = ideLotto;
        paramsFiltroDaLinkLotto.idIF = idIF;
        paramsFiltroDaLinkLotto.pagina = pagina;
        filtro.ideAnnFro = parseInt(ideAnnFro);
        filtro.ideLtx = parseInt(ideLotto);
        filtro.ideCli = parseInt(idIF);
        filtro.ideTipPer = parseInt(-1);

        //lista.filterChanged = (JSON.stringify(lista.filtro) !== JSON.stringify(filtro)) ? true : false;  // imposta flag sul cambiamnto del filtro
        lista.filterChanged = lista.filterChanged + 1;
        lista.filtro = filtro;
        lista.showLista = false;
        //setFiltro({ ...filtro });  //non serve, col refresh si alterano i valori del filtro
        setLista({ ...lista });

        setParamsFiltroDaLinkLotto({ ...paramsFiltroDaLinkLotto });  
    };

    const handleFiltroDaLinkReportDettaglio = (annoFer, idIF, tipRTO, periodoRTO, statoRTO, pagina) => {
        paramsFiltroDaLinkReportDettaglio.annoFer = annoFer;
        paramsFiltroDaLinkReportDettaglio.idIF = idIF;
        paramsFiltroDaLinkReportDettaglio.tipRTO = tipRTO;
        paramsFiltroDaLinkReportDettaglio.periodoRTO = periodoRTO;
        paramsFiltroDaLinkReportDettaglio.statoRTO = statoRTO;
        paramsFiltroDaLinkReportDettaglio.pagina = pagina;
        filtro.ideAnnFro = parseInt(annoFer);
        filtro.ideCli = parseInt(idIF);
        filtro.ideTipRTO = parseInt(tipRTO);
        filtro.ideTipPer = parseInt(periodoRTO);
        filtro.ideStoRto = parseInt(statoRTO);

        //lista.filterChanged = (JSON.stringify(lista.filtro) !== JSON.stringify(filtro)) ? true : false;  // imposta flag sul cambiamnto del filtro
        lista.filterChanged = lista.filterChanged + 1;
        lista.filtro = filtro;
        lista.showLista = false;
        //setFiltro({ ...filtro }); //non serve, col refresh si alterano i valori del filtro
        setLista({ ...lista });

        setParamsFiltroDaLinkReportDettaglio({ ...paramsFiltroDaLinkReportDettaglio });
    };

    const handleFiltroDaLinkReportGenerale = (annoFer, tipRTO, periodoRTO, statoRTO, pagina) => {
        paramsFiltroDaLinkReportGenerale.annoFer = annoFer;
        paramsFiltroDaLinkReportGenerale.tipRTO = tipRTO;
        paramsFiltroDaLinkReportGenerale.periodoRTO = periodoRTO;
        paramsFiltroDaLinkReportGenerale.statoRTO = statoRTO;
        paramsFiltroDaLinkReportGenerale.pagina = pagina;
        filtro.ideAnnFro = parseInt(annoFer);
        filtro.ideTipRTO = parseInt(tipRTO);
        filtro.ideTipPer = parseInt(periodoRTO);
        filtro.ideStoRto = parseInt(statoRTO);

        lista.filterChanged = lista.filterChanged + 1;
        lista.filtro = filtro;
        lista.showLista = false;
        //setFiltro({ ...filtro }); //non serve, col refresh si alterano i valori del filtro
        setLista({ ...lista });

        setParamsFiltroDaLinkReportGenerale({ ...paramsFiltroDaLinkReportGenerale });
    };

    const handleFiltroDaLinkReportStatoLavorazione = (ideAnnFro, ideTipPer, idTipoTraf, idIF, statoRTO, flagMod, flagPub, pagina) => {
        //const regex = /[0-9MP]/g;
        const regex = /[0-9]/g;
        const findStato = statoRTO.match(regex);
        if (findStato) {
            findStato.forEach((match) => {
                statoRTO = parseInt(match);
            });
        } else {
            console.log("link da report stato lavorazione: statoRTO non pervenuto.");
        }
        const storeTipTrafPrev = Ext.ComponentQuery.query('#itemIdTipoTrafficoPrevalente')[0].getStore();
        let idTipoTrafficoPrevNew = "";
        switch (idTipoTraf) {   //storeTipTrafPrev.data.items[i].data.desTipTraCli
            case "VL":
                idTipoTrafficoPrevNew = storeTipTrafPrev.data.items[0].data.ideTipTraCli;   ///= 1;
                break;
            case "VB":
                idTipoTrafficoPrevNew = storeTipTrafPrev.data.items[1].data.ideTipTraCli;   ///= 2;
                break;
            case "M":                                                       
                idTipoTrafficoPrevNew = storeTipTrafPrev.data.items[2].data.ideTipTraCli;   ///= 3;
                break;
            default:                                                        
                idTipoTrafficoPrevNew = -1;
        }

        idIF = (idIF !== undefined && idIF !== null && idIF !== -1) ? idIF : -1;
       
        paramsFiltroDaLinkReportStatoLavorazione.annoFer = ideAnnFro;
        paramsFiltroDaLinkReportStatoLavorazione.periodoRTO = ideTipPer;
        paramsFiltroDaLinkReportStatoLavorazione.idTipoTrafficoPrev = idTipoTrafficoPrevNew; //#itemIdTipoTrafficoPrevalente //pu� non esserci (riga del totale generale)
        paramsFiltroDaLinkReportStatoLavorazione.idIF = idIF;  //l'if pu� non esserci (righe dei totali)
        paramsFiltroDaLinkReportStatoLavorazione.statoRTO = statoRTO;  //lo stato c'� sempre 
        paramsFiltroDaLinkReportStatoLavorazione.flagModificata = flagMod ? 1 : 0; //se il flag � false si intende versione attiva //-1 = versione modificata + attiva
        switch (flagPub) { // se il flag � false si intende 8 non pubblicato(se per� lo stato � 8) //-1 = 8P + 8NP
            case true:
                paramsFiltroDaLinkReportStatoLavorazione.flagPubblicata = 1;
                break;
            case false:
                paramsFiltroDaLinkReportStatoLavorazione.flagPubblicata = (!flagPub && parseInt(statoRTO) !== 8) ? -1 : 0;   //P=1, NP=0, P+NP=-1
                break;
            default:
                paramsFiltroDaLinkReportStatoLavorazione.flagPubblicata = -1;
        }
        paramsFiltroDaLinkReportStatoLavorazione.pagina = pagina;

        filtro.ideAnnFro = parseInt(ideAnnFro);
        filtro.ideTipPer = parseInt(ideTipPer);
        filtro.ideTrafPrev = parseInt(idTipoTrafficoPrevNew);
        filtro.ideCli = parseInt(idIF);
        filtro.ideStoRto = parseInt(statoRTO);
        filtro.ideVesRto = (flagMod) ? 1 : 0;        
        switch (flagPub) {
            case true:
                filtro.pFlgPubTrc = 1;
                break;
            case false:
                filtro.pFlgPubTrc = (!flagPub && parseInt(statoRTO) !== 8) ? -1 : 0;   //P=1, NP=0, P+NP=-1
                break;
            default:
                filtro.pFlgPubTrc = -1;
        }

        lista.filterChanged = lista.filterChanged + 1;
        lista.filtro = filtro;
        lista.showLista = false;
        //setFiltro({ ...filtro }); //non serve, col refresh si alterano i valori del filtro
        setLista({ ...lista });

        setParamsFiltroDaLinkReportStatoLavorazione({ ...paramsFiltroDaLinkReportStatoLavorazione });
    };



    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {           
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ambiente.ideAnnFro); 
            //Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].value=ambiente.ideAnnFro; 

            if (ambiente.ideTipPer >= 0) {
                Ext.ComponentQuery.query('#comboPeriodoRto')[0].setValue(ambiente.ideTipPer);
                //Ext.ComponentQuery.query('#comboPeriodoRto')[0].value=ambiente.ideTipPer;
            }
            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });            
        }    
        handleLoadMask(true);
    };
    
    //--- end definitions

    const handleLoadMask = (pHidden) => { 
        setStateLoadMask({ hidden: pHidden, message: "Attendere prego ..." });
    }

    const handleArrayOfChildren = (children) => {       
        if (state != null && state !== undefined && state.pagina != null && state?.pagina !== undefined) {
            const oggettoCercato = children?.find(item => item.text === state.pagina);
            //console.log("Filtro da applicare", oggettoCercato);            
            handleFiltroSelected(oggettoCercato?.textProperty, oggettoCercato?.title, oggettoCercato?.text); 
            setDoRicerca(doRicerca + 1);
        }
        setChildrenBkp(children);
    };

    const aggiornaStateAmbiente = () => {
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        stateAmbiente.ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : -1;
        setStateAmbiente({ ...stateAmbiente });

        // al cambio dell'ambiente annullare lo store dell'elenco RTO senza effettuare nuova ricerca che deve essere avviata solo da bottone'
        handlePulisciGridListaRTO();
    }

    const handlePulisciGridListaRTO = () => {
        lista.reload = false;
        lista.showLista = false;
        setLista({ ...lista });
    };

    
    useEffect(() => {
          if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            handleLoadMask(false);
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            filtro.ideAnnFro = ambiente.ideAnnFro;
            filtro.ideTipPer = ambiente.ideTipPer;
            setFiltro({ ...filtro });
            setStateAmbiente({ ...stateAmbiente });
            initComboAmbiente();
        }
    }, [ambiente]);

    useEffect(() => {
        if (state?.pagina === "linkFromLotto") {
            setCallHandlePulisciFiltri(true);
            Ext.ComponentQuery.query('#comboPeriodoRto')[0].setValue(null);
            onClearPeriodoRto();
            handleFiltroDaLinkLotto(state.ideAnnoFer, state.ideLotto, state.idIF, state.pagina);
        }
        else if (state?.pagina === "linkFromReportDettaglio") {
            setCallHandlePulisciFiltri(true);            
            forzaAmbiente(state.annoFer, state.periodoRTO);
            handleFiltroDaLinkReportDettaglio(state.annoFer, state.idIF, state.tipRTO, state.periodoRTO, state.statoRTO, state.pagina);
        }
        else if (state?.pagina === "linkFromReportGenerale") {
            setCallHandlePulisciFiltri(true);
            forzaAmbiente(state.annoFer, state.periodoRTO);
            handleFiltroDaLinkReportGenerale(state.annoFer, state.tipRTO, state.periodoRTO, state.statoRTO, state.pagina);
        }
        else if (state?.pagina === "linkFromReportStatoLavorazione") {
            setCallHandlePulisciFiltri(true);
            forzaAmbiente(state.ideAnnFro, state.ideTipPer);
            handleFiltroDaLinkReportStatoLavorazione(state.ideAnnFro, state.ideTipPer, state.idTipoTraf, state.idIF, state.statoRTO, state.flagMod, state.flagPub, state.pagina);
        }
        else if (childrenBkp != null && state != null && state !== undefined && state.pagina != null && state?.pagina !== undefined) {
                //setCallHandlePulisciFiltri(true);
                handleArrayOfChildren(childrenBkp);
        } 
        else
        {
            //console.log("Da pagina non valida: ", state?.pagina);  
        }
    }, [state?.pagina]);
  
    const DOCUMENT_URL = "/Documento";
    const getDocumento = (idPag) => {
        window.open(DOCUMENT_URL + "?idPag=" + idPag, '_blank');
      };

    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Loadmask cls="custom-grid" itemId="loadMaskMainRto" hidden={stateLoadMask.hidden}
                indicator={false}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
            <Panel layout="vbox" scrollable="true">

                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1}>
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-train" />
                    <Button align="right" cls="title-icon" iconCls="x-fa fa-circle-question" handler={() =>getDocumento(2)}/>
                    <Button align="right" docked="right" cls="title-icon" iconCls="x-fa fa-search" handler={onSearchRto} />
                    <SearchField width="200"
                        docked="right" align="right"
                        placeholder="N.Treno o Protocollo IF/RFI ..."
                        margin="0 5 0 10"
                        itemId="findRto"
                        onAction={onSearchRto}
                        //onClearIconTap={onSearchRto}
                    />
                    <Combobox
                        itemId="comboPeriodoFerroviario"
                        width={170}
                        margin='0 10 0 0'
                        align="right"
                        editable ="false"
                        //label="Periodo Ferroviario"
                        store={storePeriodiFerroviari}
                        displayField="desAnnFro"
                        queryMode="local"
                        valueField="ideAnnFro"
                        onSelect={onSelectPeriodoFerroviario}
                    />

                    <Combobox
                        itemId="comboPeriodoRto"
                        width={150}
                        docked="right"
                        align="right"
                        editable="false"
                        clearable
                        //label="Periodo Rto"
                        store={storePeriodoRTO}
                        displayField="desTipPer"
                        valueField="ideTipPer"
                        queryMode="local"
                        onSelect={onSelectPeriodoRto}
                        onChange={onSelectPeriodoRto}
                        onClearicontap={onClearPeriodoRto}
                    />
                </Titlebar>

                <RtoFiltriVeloci onArrayOfChildren={handleArrayOfChildren} onFilterSelected={handleFiltroSelected} flagRefreshTree={refreshTree} onUpdateFilterTree2={handleRefreshTree} handleLoadMaskRTO={handleLoadMask} />

                <RtoFiltriAvanzati scrollable="true" doRicerca={doRicerca} pagina={state?.pagina} filtroTestata={stateAmbiente} onFiltroChanged={handleFiltroChanged} onPulisciGridListaRTO={handlePulisciGridListaRTO} paramsFiltroScelto={paramsFiltroScelto} onUpdateFilterTree={handleRefreshTree} attivaHandlePulisciFiltri={callHandlePulisciFiltri} updateFlagPulisciFiltri={updateFlagPulisciFiltri} paramsFiltroSceltoDaLinkLotto={paramsFiltroDaLinkLotto} paramsFiltroSceltoDaLinkReportDettaglio={paramsFiltroDaLinkReportDettaglio} paramsFiltroSceltoDaLinkReportGenerale={paramsFiltroDaLinkReportGenerale} paramsFiltroSceltoDaLinkReportStatoLavorazione={paramsFiltroDaLinkReportStatoLavorazione} handleLoadMaskRTO={handleLoadMask} />

                {(lista.showLista || props.nuova === true) && <RtoList reload={props.nuova === true ? true : lista.reload} filtro={lista.filtro} filterChanged={lista.filterChanged} strRicercaUniversale={lista.filtro.strRicercaUniversale} handleLoadMask={handleLoadMask} nuova={props.nuova} />}


            </Panel>

        </Container>
    );
}

export default MainRto;
