import React from 'react';
import { Panel, Container, Loadmask, ExtTogglefield, Grid, Column, Button, SegmentedButton, Toolbar ,Titlebar } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import newsIFModel from '../models/ComunicazioniIFModel.js';
import { useUser } from '../components/UserContext';
import ComunicazioniIFModel from '../models/ComunicazioniIFModel.js';

const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

const storeComunicazioniIF = Ext.create('Ext.data.Store', {
    model: newsIFModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

function ComunicazioniIF() {
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: false, message: "Attendere caricamento comunicazioni in corso...", ricarica: true });
    const { user, switchUser } = useUser();
    const title = "Gestione Comunicazione IF";
    let ui = 'action';
    const caricaComunicazioniIF = () => {
        Ext.Ajax.request({
            url: 'Clienti/LeggiComunicazioniIF',
            method: 'GET',
            callback: function (records, success, operation) {
                setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                var returnObj = Ext.decode(operation.responseText);
                storeComunicazioniIF.loadData(returnObj);
                Ext.ComponentQuery.query('#gridComunicazioniIF')[0].setStore(storeComunicazioniIF);
            }
        });
    }

    if (stateLoadMask.ricarica === true)
        caricaComunicazioniIF();

    function onModificaComunClick(grid, info) {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 2);
        }
    }

    function onNuovoClick() {
        var grid = Ext.ComponentQuery.query('#gridComunicazioniIF')[0];
        var store = grid.getStore();
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing == false) {
            var record = Ext.create(ComunicazioniIFModel, {});
            record.data.flagPubbl = "0";
            store.insert(0, record);
            rowediting.startEdit(0, 0);
        }
    }

    function onEliminaComunicazioneClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione della comunicazione?", function (button) {

            if (button === 'yes') {
                setStateLoadMask({ hidden: false, message: "Eliminazione in corso...", ricarica: false });
                Ext.Ajax.request({
                    url: 'Clienti/EliminaComunicazione',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ideCom: info.record.data.idCom
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            //modifica gestione errore
                            if (returnObj.resp == "KO") {
                                setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                                Ext.Msg.alert('Elimina comunicazione fallito', returnObj.msgErr)
                            }
                            else {
                                setStateLoadMask({ hidden: false, message: "Attendere caricamento comunicazioni in corso...", ricarica: true });
                                Ext.toast({ message: 'Comunicazione eliminata', timeout: 4000 });
                            }
                        }
                        else {
                            setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                            Ext.Msg.alert('Elimina comunicazione fallito', 'Elimina comunicazione fallito');
                        }
                    }
                });

            }
        })

    }

    function onPubblicaRevocaComunicazioneClick(grid, info) {
        let flgApp = 0;
        if (info.record.data.flagPubbl === "1")
            flgApp = 0;
        else
            flgApp = 1;
        setStateLoadMask({ hidden: false, message: "Aggiornamento in corso...", ricarica: false });
        Ext.Ajax.request({
            url: 'Clienti/PubblicaComunicazione',
            method: 'POST',
            params: {
                ideUte: user.ideUte,
                ideCom: info.record.data.idCom,
                flgPubbl: flgApp
            },
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);
                    //modifica gestione errore
                    if (returnObj.resp == "KO") {
                        setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                        Ext.Msg.alert('Aggiornamento comunicazione fallito', returnObj.msgErr)
                    }
                    else {
                        setStateLoadMask({ hidden: false, message: "Attendere caricamento comunicazioni in corso...", ricarica: true });
                        Ext.toast({ message: 'Comunicazione aggiornata', timeout: 4000 });
                    }
                }
                else {
                    setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                    Ext.Msg.alert('Aggiorna comunicazione fallito', 'Aggiorna comunicazione fallito');
                }
            }
        });
    }

    function renderColorRow(value, record, field, metaData) {
        let row = metaData.row;
        if(row !== undefined)
        row.setStyle("background:#" + record.data.coloreRiga + ";"); 
        return value;
    }


    const renderCellWithToggle = (record) => {
        if (record.data.flagPubbl === '1') {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10px', paddingTop: "3px" }}>
                </div>
            );
        }
        else if (record.data.flagPubbl === '0') {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10px', paddingTop: "3px" }}  >
                </div>
            );
        }
    };
    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridComunicazioniIF')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }
    return (

        <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
            <Panel layout="vbox" flex={1} scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-rss" />
                </Titlebar>
                <Grid
                    itemId="gridComunicazioniIF"
                    flex={1}

                    markDirty={true}
                    scrollable
                    cls="custom-grid"
                    variableHeights={true}
                    selectable={{ checkbox: false, mode: 'single' }}
                    store={storeComunicazioniIF}
                    plugins={{ gridfilters: {}, gridexporter: true ,rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" } }}
                    itemConfig={{
                        viewModel: true
                    }}
                    listeners={{
                        edit: function (grid, location) {
                            setStateLoadMask({ hidden: false, message: "Salvataggio in corso...", ricarica: false });
                            var comunicazioniByDB = null;
                            Ext.Ajax.request({
                                url: 'Clienti/LeggiComunicazioniIF',
                                method: 'GET',
                                callback: function (resp, success, operation) {
                                    const ideComunicazione = location.record.data.idCom;
                                    comunicazioniByDB = Ext.decode(operation.responseText);

                                    if (comunicazioniByDB.find(comunicazioni => comunicazioni.idCom === ideComunicazione) == null) {
                                        Ext.Ajax.request({
                                            url: 'Clienti/InserisciComunicazione',
                                            method: 'POST',
                                            params: {
                                                ideUte: user.ideUte,
                                                testo: location.record.data.testo,
                                                dataInizio: location.record.data.dataIniVal,
                                                dataFine: location.record.data.dataFinVal
                                            },
                                            callback: function (records, success, operation) {
                                                if (success) {

                                                    var returnObj = Ext.decode(operation.responseText);
                                                    //modifica gestione errore
                                                    if (returnObj.resp == "KO") {
                                                        setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                                                        Ext.Msg.alert('Inserimento comunicazione fallito', returnObj.msgErr)
                                                    }
                                                    else {
                                                        setStateLoadMask({ hidden: false, message: "Attendere caricamento comunicazioni in corso...", ricarica: true });
                                                        Ext.toast({ message: 'Comunicazione creata', timeout: 4000 });
                                                    }
                                                }
                                                else {
                                                    setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                                                    Ext.Msg.alert('Inserimento comunivcazione fallito', 'Inserimento comunicazione fallito');
                                                }
                                            }
                                        })
                                    }
                                    else {
                                        Ext.Ajax.request({
                                            url: 'Clienti/ModificaComunicazione',
                                            method: 'POST',
                                            params: {
                                                ideUte: user.ideUte,
                                                idCom: location.record.data.idCom,
                                                testo: location.record.data.testo,
                                                dataInizio: location.record.data.dataIniVal,
                                                dataFine: location.record.data.dataFinVal
                                            },
                                            callback: function (records, success, operation) {
                                                if (success) {
                                                    
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    //modifica gestione errore
                                                    if (returnObj.resp === "KO") {
                                                        setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                                                        Ext.Msg.alert('Modifica comunicazione fallita', returnObj.msgErr);
                                                        location.record.reject();
                                                    }
                                                    else {
                                                        setStateLoadMask({ hidden: false, message: "Attendere caricamento comunicazioni in corso...", ricarica: true });
                                                        Ext.toast({ message: 'Comunicazione modificata', timeout: 4000 });
                                                    }
                                                }
                                                else {
                                                    setStateLoadMask({ hidden: true, message: "Caricamento completato...", ricarica: false });
                                                    Ext.Msg.alert('Modifica comunicazione fallita', 'Modifica comunicazione fallita');
                                                    location.record.reject();
                                                }

                                            }
                                        });
                                    }
                                }
                                })
                        }
                    }}

                >
                    <Toolbar docked="top" margin="0 0 10 0" cls="lottoToolbar" layout={{ type: 'hbox', pack: 'end' }}>
                        <Panel
                            layout="hbox"
                            margin="0 0 0 5"
                            width="100%"
                            html='<table align="center" style="width: 50%"><thead><tr><th class="backgroundVisibile" style="width: 33%;">Pubblicata<br></th><th class="backgroundNonVisibile" style="width: 33%;">Bozza</th><th class="backgroundScaduta" style="width: 33%;">Scaduta</th></tr></thead></table>'
                        />
                        <SegmentedButton>
                            <Button cls="marginButton appButton" ui={ui} text="Nuovo" handler={onNuovoClick} iconCls="x-fa fa-plus" />
                            <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />

                        </SegmentedButton>
                        {/* {isIF && <div style={{ color: '#666', fontSize: '13px' }}>Doppio click su un lotto per modificarlo</div>} */}
                    </Toolbar>
                    <Column height="40" text="ID" dataIndex="idCom" width={130} filter='string' renderer={renderColorRow} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} />
                    <Column  type="datecolumn" text="Data Inserimento" width={160} formatter='date("d/m/Y")' dataIndex="datIns" filter='date' />
                    <Column width={450} text="Testo" dataIndex="testo" filter='string' flex={1} exportStyle={{ width: '950', alignment: { horizontal: 'Left' } }} editor={{
                        xtype: 'textareafield', triggerAction: 'all', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true
                    }}  renderer={(value, metaData, record) => (
                        <div dangerouslySetInnerHTML={{ __html: value }} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} />
                    )}
 />
                    <Column type="datecolumn" formatter='date("d/m/Y")' width={160} text="Inizio Validita" filter='date' dataIndex="dataIniVal" editor={{
                        xtype: 'datefield', formatter: 'date("d/m/Y")', triggerAction: 'all', allowBlank: false, itemId: "dataIniNews", requiredMessage: "Questo campo non puo' essere vuoto", required: true
                    }} />
                    <Column type="datecolumn" text="Fine Validita" width={160} formatter='date("d/m/Y")' filter='date' dataIndex="dataFinVal" editor={{
                        xtype: 'datefield', formatter: 'date("d/m/Y")', triggerAction: 'all', allowBlank: false, itemId: "dataFinNews", requiredMessage: "Questo campo non puo' essere vuoto", required: true
                    }} />
                    <Column text="Stato" dataIndex="stato" editable={false} width={150} 
                    />
                    <Column align="center" ignoreExport={true} editable={false} width={125} text="Pubblica/Revoca"
                        cell={{
                            xtype: 'reactcell',
                            renderer: (value, record) => renderCellWithToggle(record),
                            tools: {
                                pubblicaRevoca: {
                                    handler: onPubblicaRevocaComunicazioneClick.bind(this),
                                    bind: {
                                        //    tpl: '{record.flgMail === 1 ? "Si" : ((record.flgMail === 0) ? "No" : "")}',
                                        cls: '{record.flagPubbl === "1" ? "toggleOn" : ((record.flagPubbl === "0") ? "toggleOff": "" )}'
                                    }
                                }
                            }
                        }}
                    />
                    <Column width={120} itemId='actionServComuni' ignoreExport={true} editable={false} text="Azioni"
                        cell={{

                            tools: {

                                modificaComun: {

                                    handler: onModificaComunClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-edit buttonGridTool",

                                        tooltip: 'Modifica'
                                    }

                                },

                                eliminaComun: {

                                    handler: onEliminaComunicazioneClick.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-trash buttonGridTool",

                                        tooltip: 'Elimina'
                                    }

                                },

                            }

                        }}

                    />
                </Grid>
            </Panel>
            </Container>

    );
}

export default ComunicazioniIF;