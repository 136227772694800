import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Panel, Titlebar, Grid, Column, Button, Toolbar, SegmentedButton,Combobox } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";
import { useAmbiente } from '../components/AmbienteContext';
const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.plugin.SummaryRow',
    'Ext.data.summary.Sum',
]);
//  -----------PERIODI FERROVIARI ----------------------------------------------
const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//----------REPORT STATO LAVORAZIONE
const storeReportStatoLavorazione = Ext.create('Ext.data.Store', {
    proxy: {
        url: 'AnalisiRto/ReportStatoLavorazione',
        method: 'GET',
        type: 'ajax',
        reader: {
            type: 'json'
        },
    },
    autoload: false,

});

//-------------PERIODI RTO----------------------------
const storePeriodoRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiePeriodicita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//-------------------------
let firstOnSelectComboPF = true;

function ReportStatoLavorazione() {

    let title = "Report Stato Lavorazione RTO";
    let ui = 'action';
    const { user } = useUser();
    let navigate = useNavigate();

    const { ambiente, updateAmbiente, inizializzaAmbiente } = useAmbiente();
    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });

    const caricaPeriodiFerroviari = () => {
        storePeriodiFerroviari.load({
        })
    }
    const caricaPeriodoRTO = () => {
        storePeriodoRTO.load({
        })
    }

    const caricaReportStatoLavorazione = (ideUte, ideAnnFro, ideTipPer) => {
        if (ideTipPer == null)
            ideTipPer = -1;
        storeReportStatoLavorazione.load({
            params: {
                ideUte: ideUte,
                ideAnnFro: ideAnnFro,
                ideTipPer: ideTipPer
            },
            callback: function () {

            }
        });
    };

    //---------COLUMN DRILL-DOWN---------------------
    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ambiente.ideAnnFro);

            if (ambiente.ideTipPer >= 0) {
                Ext.ComponentQuery.query('#comboPeriodoRto')[0].setValue(ambiente.ideTipPer);
            }
            avviaRicerca();
            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });
        }
    };

    const aggiornaStateAmbiente = () => {
        avviaRicerca();
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        stateAmbiente.ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : -1;
        setStateAmbiente({ ...stateAmbiente });
    }

    useEffect(() => {
        caricaPeriodiFerroviari();
        caricaPeriodoRTO();
    }, []);

  
    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);

    //--------------CONFERMA/AVVIA RICERCA --------------------------
    const avviaRicerca = () => {
        let ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        let ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
        caricaReportStatoLavorazione(user.ideUte, ideAnnFro, ideTipPer);
    }

    //------PERIODO FERROVIARIO----
    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente StatisticheFinalizzazione SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente StatisticheFinalizzazione SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
    }
    //---------------FILTRO PERIODO RTO------------------------
    function onSelectPeriodoRto(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo rto
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoRto')[0] !== undefined && Ext.ComponentQuery.query('#comboPeriodoRto')[0] != null) {
                let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                if (valTipPer !== stateAmbiente.ideTipPer) {
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente StatisticheFinalizzazione SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente StatisticheFinalizzazione SPF ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
    }
        function onClearPeriodoRto() {
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: null }).then(
            function (result) {
                aggiornaStateAmbiente();
                //console.log('sono in updateAmbiente StatisticheFinalizzazione SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                //console.log('sono in updateAmbiente StatisticheFinalizzazione SPF ideTipPer = ', -1);
            }
        );
    }

    //-------COLUMN COLOR YELLOW,BLUE & TRANSPARENT ----------------------------------
    function renderColorRowYellow(value, record, field, meta) {
        meta.setStyle("background:#FFFF01;");

        if (value === 0) {
            meta.setCls("noLine");
            return null
        } else {
            meta.setCls("line");
            return value;
        }
    }
    function renderColorRowBlue(value, record, field, meta) {
        meta.setStyle("background:#BBD7F2");

        if (value === 0) {
            meta.setCls("noLine");
            return null
        } else {
            meta.setCls("line");
            return value;
        }
    }

    useEffect(() => {
        caricaPeriodiFerroviari();
        caricaPeriodoRTO();
    }, []);

    useEffect(() => {
        if (ambiente?.ideUte) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            if (firstOnSelectComboPF)
                initComboAmbiente();
        }
    }, [ambiente]);



    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridRepStatLav')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx',
            includeSummary: 'true',
            includeGroups: 'true',
        });
    }

    //-----FUNZIONI DI EXPORT RENDER COLONNE

    const summarizeExportDataTipoTraffico = (value, record, field, metaData) => {
        record = record.data.sigTipTraCli;
        if (record == null) {
            return 'Tutti'
        } else if (record !== null && record.nomCli === null) {
            return record
        } 
        else {
            return record
        }
    }

    const summarizeExportDataImpresa = (value, record, field, metaData) => {
        let sigTipTraCli = record.data.sigTipTraCli;

        if (record.data.nomCli == null) {
            if (sigTipTraCli === null && record.data.stato1 !== null) {
                return 'Totale';
            } 
            else {              
                return sigTipTraCli + ' Tutte'
            }
           
        } 
        else {
            return record.data.nomCli
        }
    }

    const renderExportZeroStato1 = (record) => {
        let value = record.data.stato1;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato2M = (record) => {
        let value = record.data.stato2M;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato2 = (record) => {
        let value = record.data.stato2A;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato4M = (record) => {
        let value = record.data.stato4M;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato4 = (record) => {
        let value = record.data.stato4A;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato5M = (record) => {
        let value = record.data.stato5M;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato5 = (record) => {
        let value = record.data.stato5A;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato6M = (record) => {
        let value = record.data.stato6M;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato6 = (record) => {
        let value = record.data.stato6A;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato8M = (record) => {
        let value = record.data.stato8M;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato8 = (record) => {
        let value = record.data.stato8An;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato8P = (record) => {
        let value = record.data.stato8Ap;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato9 = (record) => {
        let value = record.data.stato9;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroTotRto = (record) => {
        let value = record.data.totale37;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato3 = (record) => {
        let value = record.data.totale3;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };
    const renderExportZeroStato7 = (record) => {
        let value = record.data.totale7;
        if (value === 0) {
            return null
        } else {
            return value
        }
    };




     
    //------------TOTAL ROW DRILL-DOWN----------------
    const sumTotaleToGestioneRto = (grid, info, e) => {
        if (info.record.group) //riga totale
        {
            let ideAnnFro = stateAmbiente.ideAnnFro;
            let ideTipPer = stateAmbiente.ideTipPer;
            let idTipoTraf = info.record.group._groupValue;
            let idIF = undefined;  //deve essere undefined 
            let statoRTO = info.column._text;
            let flagMod = false; //flag modificate di TOTALE GENERALE
            let flagPub = false;
            if (statoRTO === "Stato 2M" || statoRTO === "Stato 4M" || statoRTO === "Stato 5M" || statoRTO === "Stato 6M" || statoRTO === "Stato 8M") {
                flagMod = true;
            } else if (statoRTO === "Stato 8P") {
                flagPub = true;
            }
            else {
                flagMod = false;
                flagPub = false;
            }
            navigateToDetails(ideAnnFro, ideTipPer, idTipoTraf, idIF, statoRTO, flagMod, flagPub);
        }
        else {  //riga intermedia
            let rec = info.record.data;
            let ideAnnFro = stateAmbiente.ideAnnFro;
            let ideTipPer = stateAmbiente.ideTipPer;
            let idTipoTraf = rec.sigTipTraCli;
            let idIF = rec.ideCli;
            let statoRTO = info.column._text;
            let flagMod = false; //flag modificate di TOTALE GENERALE
            let flagPub = false;

            if (statoRTO === "Stato 2M" || statoRTO === "Stato 4M" || statoRTO === "Stato 5M" || statoRTO === "Stato 6M" || statoRTO === "Stato 8M") {
                flagMod = true;
            } else if (statoRTO === "Stato 8P") {
                flagPub = true;
            }
            else {
                flagMod = false;
                flagPub = false;
            }
            navigateToDetails(ideAnnFro, ideTipPer, idTipoTraf, idIF, statoRTO, flagMod, flagPub);
        }

      
    }

    function navigateToDetails(ideAnnFro, ideTipPer, idTipoTraf, idIF, statoRTO, flagMod, flagPub)
    {
        const params = {
            ideAnnFro: ideAnnFro,
            ideTipPer: ideTipPer,
            idTipoTraf: idTipoTraf,
            idIF: idIF,
            statoRTO: statoRTO,
            flagMod: flagMod,
            flagPub: flagPub,
            pagina: "linkFromReportStatoLavorazione"
        };
        navigate(`/MainRto`, { state: params });
    };
    

    return (
        <Container height="100%" width="100%" layout="fit" scrollable={false} margin='3 0 0 0' >
            <Panel layout="vbox" scrollable={false}>
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-th-list" />
                    <Combobox itemId="comboPeriodoFerroviario" align="right" width={170} margin='0 10 0 0' editable="false" valueField="ideAnnFro" displayField="desAnnFro"
                        queryMode="local"
                        store={storePeriodiFerroviari}
                        onSelect={onSelectPeriodoFerroviario}
                    />
                    <Combobox itemId="comboPeriodoRto" width={150} margin='0 4 0 0' docked="right" align="right" editable="false" valueField="ideTipPer" displayField="desTipPer"
                        store={storePeriodoRTO}
                        clearable
                        queryMode="local"
                        onSelect={onSelectPeriodoRto}
                        onChange={onSelectPeriodoRto}
                        onClearicontap={onClearPeriodoRto}

                    />
                </Titlebar>

                    <Toolbar docked="top" margin="10 0 10 10">
                        <SegmentedButton docked="right">
                            <Button cls="marginButton exportButton" docked="right" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                        </SegmentedButton>
                    </Toolbar>                                                                                                   

                <Grid flex={1} scrollable={ false} cls="custom-grid" itemId="gridRepStatLav" id='topolino' itemConfig={{ viewModel: true }} columnLines={true}
                    selectable={{ checkbox: false, mode: 'single' }}
                    plugins={['gridfilters', 'gridexporter']}
                            
                    listeners={{
                        childsingletap: function (editor, context, eOpts) {
                            let ideAnnFro = stateAmbiente.ideAnnFro;
                            let ideTipPer = stateAmbiente.ideTipPer;
                            let idTipoTraf = context.record.data?.sigTipTraCli;
                            let idIF = context.record.data.ideCli;
                            let statoRTO = context.column._text;
                            let flagMod = false;
                            let flagPub = false;

                            if (context.column._text !== "Tipo Traffico" && context.column._text !== "Impresa" && context.column._text !== "Da Lavorare" && context.column._text !== "Totale RTO Attive") {

                                if (context.column._text === "Stato 2M" || context.column._text === "Stato 4M" || context.column._text === "Stato 5M" || context.column._text === "Stato 6M" || context.column._text === "Stato 8M") {
                                    flagMod = true;
                                } else if (context.column._text === "Stato 8P") {
                                    flagPub = true;
                                }
                                else {
                                    flagMod = false;
                                    flagPub = false;
                                }
                                navigateToDetails(ideAnnFro, ideTipPer, idTipoTraf, idIF, statoRTO, flagMod, flagPub);
                            }
                        }
                    }}
                    store={storeReportStatoLavorazione}
                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                >


                    <Column height="40" width={120} text="Tipo Traffico" dataIndex="sigTipTraCli" filter='string' align='center' exportRenderer={summarizeExportDataTipoTraffico} exportStyle={{ width: '150', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.sigTipTraCli == null ? "Tutti" : ((record.sigTipTraCli !== null && record.nomCli === null) ? record.sigTipTraCli : record.sigTipTraCli )}',
                            cls: '{record.sigTipTraCli == null ? "rowSummary" : ((record.sigTipTraCli !== null && record.nomCli === null) ? "rowSummary" : "rowNormal" )}',
                        }
                    }} />

                    <Column height="40" width={220} text="Impresa" dataIndex="nomCli" filter='string' align='left'  exportRenderer={summarizeExportDataImpresa} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.sigTipTraCli == null ? "Totale" : ((record.sigTipTraCli !== null && record.nomCli === null) ? record.sigTipTraCli + " Tutte" : record.nomCli )}',
                            cls: '{record.nomCli === null ? "rowSummary " : ((record.nomCli === 0) ? "noLine rowNormal" : "rowNormal" )}'
                        }
                    }} />

                    <Column width={95} text="Da Lavorare" dataIndex="daLavorare" filter='number'  align='center' flex={1} exportStyle={{ width: '150', alignment: { horizontal: 'Left' } }} cell={{
                        bind: { 
                            tpl: '{record.daLavorare === null || record.daLavorare === 0 ? "" : record.daLavorare}',
                            cls: '{record.nomCli === null ? "rowSummary noLine" : ((record.daLavorare === 0) ? "noLine rowNormal" : "rowNormal noLine" )}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={140} text="Totale RTO Attive" dataIndex="totale" filter='number' align='center' flex={1} exportStyle={{ width: '200', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.totale === null || record.totale === 0 ? "" : record.totale}',
                            cls: '{record.nomCli === null ? "rowSummary noLine" : ((record.stato1 === 0) ? "noLine rowNormal" : "rowNormal noLine" )}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />

                    <Column width={65} text="Stato 1" dataIndex="stato1" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato1(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.stato1 === null || record.stato1 === 0 ? "" : record.stato1}',
                            cls: '{record.nomCli === null &&  record.stato1 !== 0 ? "rowSummary line" : ((record.nomCli === null && record.stato1 === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.stato1 === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.stato1 === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={75} text="Stato 2M" dataIndex="stato2M" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato2M(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.stato2M === null || record.stato2M === 0 ? "" : record.stato2M}',
                            cls: '{record.nomCli === null &&  record.stato2M !== 0 ? "rowSummary line" : ((record.nomCli === null && record.stato2M === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.stato2M === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.stato2M === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={65} text="Stato 2" dataIndex="stato2A" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato2(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.stato2A === null || record.stato2A === 0 ? "" : record.stato2A}',
                            cls: '{record.nomCli === null &&  record.stato2A !== 0 ? "rowSummary line" : ((record.nomCli === null && record.stato2A === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.stato2A === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.stato2A === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={75} text="Stato 4M" dataIndex="stato4M" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato4M(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.stato4M === null || record.stato4M === 0 ? "" : record.stato4M}',
                            cls: '{record.nomCli === null &&  record.stato4M !== 0 ? "rowSummary line" : ((record.nomCli === null && record.stato4M === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.stato4M === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.stato4M === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler:
                                    sumTotaleToGestioneRto.bind(this),
                                    bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={65} text="Stato 4" dataIndex="stato4A" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato4(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.stato4A === null || record.stato4A === 0 ? "" : record.stato4A}',
                            cls: '{record.nomCli === null &&  record.stato4A !== 0 ? "rowSummary line" : ((record.nomCli === null && record.stato4A === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.stato4A === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.stato4A === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={75} text="Stato 5M" dataIndex="stato5M" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato5M(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.stato5M === null || record.stato5M === 0 ? "" : record.stato5M}',
                            cls: '{record.nomCli === null &&  record.stato5M !== 0 ? "rowSummary line" : ((record.nomCli === null && record.stato5M === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.stato5M === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.stato5M === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={65} text="Stato 5" dataIndex="stato5A" renderer={renderColorRowBlue} filter='number' align='center'   exportRenderer={(value, record) => renderExportZeroStato5(record)}   exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{ tools: { Totale: { handler: sumTotaleToGestioneRto.bind(this), bind: { cls: 'lineText' } } } }} />
                    <Column width={75} text="Stato 6M" dataIndex="stato6M" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato6M(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.stato6M === null || record.stato6M === 0 ? "" : record.stato6M}',
                            cls: '{record.nomCli === null &&  record.stato6M !== 0 ? "rowSummary line" : ((record.nomCli === null && record.stato6M === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.stato6M === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.stato6M === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={65} text="Stato 6" dataIndex="stato6A" renderer={renderColorRowBlue} filter='number' align='center'   exportRenderer={(value, record) => renderExportZeroStato6(record)}   exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{ tools: { Totale: { handler: sumTotaleToGestioneRto.bind(this), bind: { cls: 'lineText' } } } }} />
                    <Column width={75} text="Stato 8M" dataIndex="stato8M" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato8M(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.stato8M === null || record.stato8M === 0 ? "" : record.stato8M}',
                            cls: '{record.nomCli === null &&  record.stato8M !== 0 ? "rowSummary line" : ((record.nomCli === null && record.stato8M === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.stato8M === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.stato8M === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={65} text="Stato 8" dataIndex="stato8An" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato8(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.stato8An === null || record.stato8An === 0 ? "" : record.stato8An}',
                            cls: '{record.nomCli === null &&  record.stato8An !== 0 ? "rowSummary line" : ((record.nomCli === null && record.stato8An === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.stato8An === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.stato8An === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={75} text="Stato 8P" dataIndex="stato8Ap" renderer={renderColorRowBlue} filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato8P(record)}  exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{ tools: { Totale: { handler: sumTotaleToGestioneRto.bind(this), bind: { cls: 'lineText' } } } }} />
                    <Column width={65} text="Stato 9" dataIndex="stato9" renderer={renderColorRowYellow} filter='number' align='center'  exportRenderer={(value, record) => renderExportZeroStato9(record)}   exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{ tools: { Totale: { handler: sumTotaleToGestioneRto.bind(this), bind: { cls: 'lineText' } } } }} />
                    <Column width={130} text="Totale RTO Annullate" dataIndex="totale37" filter='number' align='center' flex={1} exportRenderer={(value, record) => renderExportZeroTotRto(record)} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.totale37 === null || record.totale37 === 0 ? "" : record.totale37}',
                            cls: '{record.nomCli === null ? "rowSummary noLine" : ((record.totale37 === 0) ? "noLine rowNormal" : "rowNormal noLine" )}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={65} text="Stato 3" dataIndex="totale3" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato3(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.totale3 === null || record.totale3 === 0 ? "" : record.totale3}',
                            cls: '{record.nomCli === null &&  record.totale3 !== 0 ? "rowSummary line" : ((record.nomCli === null && record.totale3 === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.totale3 === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.totale3 === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                    <Column width={65} text="Stato 7" dataIndex="totale7" filter='number' align='center' exportRenderer={(value, record) => renderExportZeroStato7(record)} exportStyle={{ width: '130', alignment: { horizontal: 'Left' } }} cell={{
                        bind: {
                            tpl: '{record.totale7 === null || record.totale7 === 0 ? "" : record.totale7}',
                            cls: '{record.nomCli === null &&  record.totale7 !== 0 ? "rowSummary line" : ((record.nomCli === null && record.totale7 === 0 ) ? "rowSummary noLine" : ((record.nomCli === null && record.totale7 === 0 ) ? "noLine rowSummary" : ((record.nomCli != null && record.totale7 === 0 ) ? "rowNormal noLine" : "rowNormal line" )))}'
                        },
                        tools: {
                            Totale: {
                                handler: sumTotaleToGestioneRto.bind(this),
                                bind: { cls: 'lineText' }
                            }
                        }
                    }} />
                </Grid>

            </Panel>
        </Container>

    );
}

export default ReportStatoLavorazione;