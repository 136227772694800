import React, { useState, useEffect } from 'react';
import { Container, Panel, TreeList, Loadmask } from '@sencha/ext-react-modern';
import { useUser } from '../components/UserContext';

const Ext = window['Ext'];

function RtoFiltriVeloci(props) {

    const storeTree = Ext.create('Ext.data.TreeStore', {
        rootVisible: true,
        root:
        {
            rootVisible: true,
            expanded: true,
            text: 'Allfiltriiii',
            children: [{
                text: 'Filtri predefiniti',
                iconCls: 'x-fa fa-tag'//,
                //children: props.childrenTreePredefiniti //arrayOfChildren   //aggiungo dopo il caricamento di questo store gli elementichildren
            }, {
                text: 'Filtri personalizzati',
                iconCls: 'x-fa fa-user'//,
                //children: [{
                //    text: 'Invii materiale 2023',
                //    iconCls: 'x-fa fa-trash',
                //    textProperty: "167",
                //    title: "contenutoFiltro",
                //    leaf: true
                //}, {
                //    text: 'Tardive Regio 2022',
                //    iconCls: 'x-fa fa-trash',
                //    textProperty: "167",
                //    title: "contenutoFiltro",
                //    leaf: true
                //}, {
                //    text: 'Rifiutati Lombardia',
                //    iconCls: 'x-fa fa-trash',
                //    qtip: 'Personalizzato Rifiutati Lombardia',
                //    textProperty: "167",
                //    title: "contenutoFiltro",
                //    leaf: true
                //}]
            }, {
                text: 'Ricerca Avanzata',
                iconCls: 'x-fa fa-search',
                textProperty: "-2",
                leaf: true
            }]
        }
    });

    const { user, switchUser } = useUser();
    const [stateStoreTree, setStateStoreTree] = useState(storeTree);
    const [doRefreshTree, setDoRefreshTree] = useState(0);
    const [doRefreshTree_AfterDel, setDoRefreshTree_AfterDel] = useState(0);
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "" });

    //------------------------- CARICAMENTO  DEF. FILTRI RAPIDI PREDEFINITI ---------------------------------------------

    /*let arrayOfChildren = "[{";*/ 
    //let arrayOfChildren = "{";

    //function creaSingleChildren(item, index, arr) {
    //    arrayOfChildren += "text: \"" + arr[index].data.nomeFiltro + "\", " + "textProperty: \"" + arr[index].data.idFiltro + "\", "
    //        //+ "title: \'" + arr[index].data.contenutoFiltro + "\', "
    //        + "title:  \"ciao" + "\", "
    //        + "leaf: true";
    //    if (index === (arr.length - 1))
    //        arrayOfChildren += "}]";
    //        //arrayOfChildren += "}";
    //    else
    //        arrayOfChildren += " }, { ";
    //}
    let arrayOfChildren = [];
  

    function creaSingleChildren(item, index, arr) {
        //arrayOfChildren += "text: \"" + arr[index].data.nomeFiltro + "\", " + "textProperty: \"" + arr[index].data.idFiltro + "\", "
        //    //+ "title: \'" + arr[index].data.contenutoFiltro + "\', "
        //    + "title:  \"ciao" + "\", "
        //    + "leaf: true";
        //if (index === (arr.length - 1))
        //    arrayOfChildren += "}]";
        ////arrayOfChildren += "}";
        //else
        //    arrayOfChildren += " }, { ";
        /////////////////////////////
        let elemChildren;

        const dynamicKey1 = 'text';
        const dynamicValue1 = arr[index].data.nomeFiltro;

        const dynamicKey2 = 'textProperty';
        const dynamicValue2 = arr[index].data.idFiltro;

        const dynamicKey3 = 'title';
        const dynamicValue3 = arr[index].data.contenutoFiltro;

        const dynamicKey4 = 'leaf';
        const dynamicValue4 = true;

        elemChildren = {
            [dynamicKey1]: dynamicValue1,
            [dynamicKey2]: dynamicValue2,
            [dynamicKey3]: dynamicValue3,
            [dynamicKey4]: dynamicValue4
        };

        arrayOfChildren.push(elemChildren);
    }


    //function fixJsonString(str) {
    //    const fixedStr = str.replace(/'/g, '"').replace(/(\w+):/g, '"$1":');
    //    return JSON.parse(fixedStr);
    //}


    function creaChildrenPredefiniti(records) {
        arrayOfChildren = [];
        records.forEach(creaSingleChildren);
     
        props.onArrayOfChildren(arrayOfChildren);
        //console.log("arrayOfChildren", arrayOfChildren);
        //let storeNew = Ext.ComponentQuery.query('#itemIdTreeFiltri')[0]._store;
        //let storeNew = storeTree;
        let rootNodo = storeTree.getRoot();
        //let nodoFiglioPredefiniti = rootNodo.findChild("text", 'Filtri predefiniti', true);

        //let newChildren = arrayOfChildren;
        //var myObj = { [a]: b };

        let nodoFiglioPredefiniti = rootNodo.findChild("text", 'Filtri predefiniti', true);

        let nodeToAppend = rootNodo.createNode(arrayOfChildren);
        //rootNodo.childNodes[0].appendChild(nodeToAppend);
        //rootNodo.data.children[0].insertChild(nodeToAppend);
        nodoFiglioPredefiniti.appendChild(arrayOfChildren);

        setStateStoreTree(storeTree);


        //let nodeToAppend = nodoFiglioPredefiniti.createNode(arrayOfChildren); //{ text: "nodo1", type: "test", leaf: false }); //TO DO: FOR su pi� nodi
        //nodoFiglioPredefiniti.appendChild(nodeToAppend1);
        //////////ok
        //let nodeToAppend = nodoFiglioPredefiniti.create(JSON.stringify(arrayOfChildren)); //{ text: "nodo1", type: "test", leaf: false }); //TO DO: FOR su pi� nodi
        //rootNodo.childNodes[0].appendChild(nodeToAppend);

        //let nodeToAppend = nodoFiglioPredefiniti.create(arrayOfChildren);
        //nodoFiglioPredefiniti.insertChild(nodeToAppend);
        //nodoFiglioPredefiniti.appendChild(nodeToAppend);
        //node.set('text', 'Changed Text');
        //set({
        //    name: 'value',
        //    age: 42
        //});
        //var nodeToAppend1 = pThis.createNode({ text: "nodo1", type: "test", leaf: false });
        //pThis.appendChild(nodeToAppend1);


//        let nodoDaAggiungere =

        //            Ext.define('MioNodo', {
    //    extend: 'Ext.data.Model',
    //        fields: [{ text: 'Trasmesse da IF', textProperty: '1', title: 'ciao', leaf: true }]
    //});

    //Ext.data.NodeInterface.decorate('MioNodo'); // String will be resolved to Class object internally

    //var obj = Ext.ModelManager.create(parent, 'MioNodo');
    //obj.appendChild(/*...*/);


        //setChildrenTreePredefiniti(JSON.stringify(arrayOfChildren));
        //arrayOfChildren= fixJsonString(arrayOfChildren);
        //let testFigliPredefiniti = Ext.ComponentQuery.query('#itemIdTreeFiltri')[0].store.getRoot().findChild("text", 'Filtri predefiniti', true);

        //let idNodo = Ext.ComponentQuery.query('#itemIdTreeFiltri')[0].rootItem.getId();
        //let nodo = Ext.ComponentQuery.query('#itemIdTreeFiltri')[0].rootItem.getNodeById(idNodo);

        //RETURNS: Ext.data.NodeInterface ovvero The found child or null if none was found

        //let figliPredefiniti Ext.ComponentQuery.query('#itemIdTreeFiltri')[0].rootItem.
        //nodo.appendChild(arrayOfChildren);
        //RETURNS :Ext.data.NodeInterface The appended node if single append, or null if an array was passed

        //let rootNodo = Ext.ComponentQuery.query('#itemIdTreeFiltri')[0]._store.getRoot();
        //let nodoFiglioPredefiniti = rootNodo.findChild("text", 'Filtri predefiniti', true);
        //let nodeToAppend1 = nodoFiglioPredefiniti.createNode(arrayOfChildren); //{ text: "Teachers", type: "ChildStaff", leaf: false });
        //nodoFiglioPredefiniti.appendChild(nodeToAppend1);

        ///nodoFiglioPredefiniti.appendChild(arrayOfChildren); ok

        //Ext.ComponentQuery.query('#itemIdTreeFiltri')[0]._store.reload();
        //Ext.ComponentQuery.query('#itemIdTreeFiltri')[0].setDisplayed(true);
        //Ext.ComponentQuery.query('#itemIdTreeFiltri')[0].show();
        // Ext.ComponentQuery.query('#itemIdTreeFiltri')[0].onRefresh(Ext.ComponentQuery.query('#itemIdTreeFiltri')[0]._store);
        //setNodoFiglioPredefiniti2(Ext.ComponentQuery.query('#itemIdTreeFiltri')[0]._store);
    }

    const storeFiltriRapidiPredefinitiList = Ext.create('Ext.data.Store', {
        proxy: {
            method: 'POST',
            type: 'ajax',
            url: 'Filtri/LeggiFiltriRapidiPredefiniti',
            reader: {
                type: 'json'
            }
        },
        autoload: false
    });

    const caricaFiltriRapidiPredefinitiList = (idGrp) => {
        storeFiltriRapidiPredefinitiList.load({
            params: {
                idGrp: parseInt(idGrp)
            },
            callback: function (records, options, success) {
                if (success) {
                    if (records.length < 0) {
                        Ext.Msg.alert('Elenco filtri rapidi predefiniti', 'Nessun filtro presente');
                    }
                    else {
                       creaChildrenPredefiniti(records);
                        //setNodoFiglioPredefiniti2(Ext.ComponentQuery.query('#itemIdTreeFiltri')[0]._store);
                    }
                }
                else {
                    Ext.Msg.alert('Elenco filtri rapidi predefiniti', 'Errore caricamento filtri rapidi predefiniti');
                }
            }
        });
    }

    //------------------------- END DEF. CARICAMENTO FILTRI RAPIDI PREDEFINTI ---------------------------------------------

    //-------------------------  DEF. CARICAMENTO FILTRI RAPIDI PERSONALIZZATI ---------------------------------------------

    let arrayOfChildrenUtente = [];


    function creaSingleChildrenUtente(item, index, arr) {
        let elemChildrenUtente;

        const dynamicKey1 = 'text';
        const dynamicValue1 = arr[index].nomeFiltro;

        const dynamicKey2 = 'textProperty';
        const dynamicValue2 = arr[index].idFiltro;

        const dynamicKey3 = 'title';
        const dynamicValue3 = arr[index].contenutoFiltro;

        const dynamicKey4 = 'leaf';
        const dynamicValue4 = true;

        const dynamicKey5 = 'iconCls';
        const dynamicValue5 = 'x-fa fa-trash';

        elemChildrenUtente = {
            [dynamicKey1]: dynamicValue1,
            [dynamicKey2]: dynamicValue2,
            [dynamicKey3]: dynamicValue3,
            [dynamicKey4]: dynamicValue4,
            [dynamicKey5]: dynamicValue5
        };

        arrayOfChildrenUtente.push(elemChildrenUtente);
    }

    function creaChildrenPersonalizzati(records) {
        if (!records || records.length <= 0) {
            //Ext.Msg.alert('Elenco filtri rapidi personalizzati', 'Nessun filtro rapidi personalizzato presente');
            return;
        }
        arrayOfChildrenUtente = [];
        records.forEach(creaSingleChildrenUtente);

        //console.log(arrayOfChildrenUtente);
        
        let rootNodoUtente = storeTree.getRoot();

        let nodoFiglioPersonalizzati = rootNodoUtente.findChild("text", 'Filtri personalizzati', true);

        let nodeUtenteToAppend = rootNodoUtente.createNode(arrayOfChildrenUtente);
        nodoFiglioPersonalizzati.appendChild(arrayOfChildrenUtente);

        setStateStoreTree(storeTree);

    }

    //const storeFiltriRapidiPersonalizzatiList = Ext.create('Ext.data.Store', {
    //    proxy: {
    //        method: 'GET',
    //        type: 'ajax',
    //        url: 'Filtri/LeggiFiltriRapidiPersonalizzati',
    //        reader: {
    //            type: 'json'
    //        }
    //    },
    //    autoload: false
    //});

    const caricaFiltriRapidiPersonalizzatiList = (ideUte) => {
        //storeFiltriRapidiPersonalizzatiList.load({
        //    params: parseInt(ideUte),
        //    callback: function (records, options, success) {
        //        if (success) {
        //            if (records.length < 0) {
        //                Ext.Msg.alert('Elenco filtri rapidi personalizzati', 'Nessun filtro presente');
        //            }
        //            else {
        //                creaChildrenPersonalizzati(records);
        //            }
        //        }
        //        else {
        //            Ext.Msg.alert('Elenco filtri rapidi personalizzati', 'Errore caricamento filtri rapidi personalizzati');
        //        }
        //    }
        //});
        //ideUte = parseInt(ideUte);
        Ext.Ajax.request({
            url: 'Filtri/LeggiFiltriRapidiPersonalizzati',
            method: 'POST',
            params: {
                ideUte: parseInt(user.ideUte)
            },
            callback: function (records, success, operation) {
                if (success) { //Ext.decode(operation.responseText)[0]
                    if (Ext.decode(operation.responseText).length < 0) {
                        Ext.Msg.alert('Elenco filtri rapidi personalizzati', 'Nessun filtro presente');
                    }
                    else {
                        creaChildrenPersonalizzati(Ext.decode(operation.responseText));
                    }
                }
                else {
                    Ext.Msg.alert('Elenco filtri rapidi personalizzati', 'Errore caricamento filtri rapidi personalizzati');
                }
            }
        });
    }

    const handleLoadMask = (pHidden) => {
        setStateLoadMask({ hidden: pHidden, message: "" });
    }
   
     //------------------------- END DEF. CARICAMENTO FILTRI RAPIDI PERSONALIZZATI ---------------------------------------------


    useEffect(() => {
        // code to run after render
        caricaFiltriRapidiPredefinitiList(user.idTipoGruppo);
        caricaFiltriRapidiPersonalizzatiList(user.ideUte);
    }, [doRefreshTree, doRefreshTree_AfterDel]); // <-- empty array means 'run once'

    useEffect(() => {
        expandTreeAll();
    }, [stateStoreTree]);

    function expandTreeAll() {
        const root = stateStoreTree.getRoot();  //Ext.data.NodeInterface
        root.expand(true); //senza il true ricorsivo si ferma a nodo singolo
        //root.expandChildren(true); anche OK
        //root.cascade(node => {
        //    if (!node.isExpanded()) { // Espande solo i nodi non espansi
        //        node.expandChildren(true);
        //    }
        //});

    }

 
    if (props.flagRefreshTree == true) {        
        //setDoRefreshTree(doRefreshTree + 1); //Not ok: crea loop
        caricaFiltriRapidiPredefinitiList(user.idTipoGruppo);
        caricaFiltriRapidiPersonalizzatiList(user.ideUte);
        //qui expandTreeAll() parte in automatico
        props.onUpdateFilterTree2(false);
    }

    function EliminaFiltro(id, nome) {
            Ext.Msg.confirm('Confirm', "Procedere con la cancellazione del filtro?", function (button) {
                let msgKo = 'Cancellazione fallita';
                if (button == 'yes') {
                    handleLoadMask(false);
                    Ext.Ajax.request({
                        url: 'Filtri/EliminaFiltroRapidoPersonalizzato',
                        method: 'POST',
                        params: {
                            idUte: user.ideUte,
                            nomeFiltro: nome,
                            idFiltro: id
                        },
                        callback: function (records, success, operation) {
                            handleLoadMask(true);
                            if (success) {
                                if (Ext.decode(operation.responseText)) {                                   
                                    Ext.toast({ message: 'Filtro eliminato!', timeout: 4000 });
                                    const randomValue = Date.now();
                                    setDoRefreshTree_AfterDel(randomValue);
                                }
                                else {
                                    Ext.Msg.alert('Cancellazione filtro fallita')
                                }
                            }
                            else {
                                Ext.Msg.alert('Cancellazione filtro fallita', 'Cancellazione filtro fallita');
                            }
                        }
                    });

                }
            })
    }

    const apriRicercaInFiltriAvanzati = (paramsIdFiltro, paramsContenutoFiltro, paramsNomeFiltro, paramsIsFiltroPredefinito) => {
        props.onFilterSelected(paramsIdFiltro, paramsContenutoFiltro, paramsNomeFiltro, paramsIsFiltroPredefinito);
    }

    //const [panelAvanzatoCollapsed, setPanelAvanzatoCollapsed] = useState({ collapse: panelCollapse })
    //function updateCollapsePanel(value) {
    //    panelAvanzatoCollapsed.collapse = value;
    //    setPanelAvanzatoCollapsed({ ...panelAvanzatoCollapsed });
    //}
    //info.node.data. textproperty
    function onItemclick({ sender, info }) {
        let iconClicked = (info.event.getTarget().className === 'x-treelist-item-icon x-fa fa-trash');
        if (info.node.data.textProperty === undefined)
            return;
        if (iconClicked) {           
            EliminaFiltro(info.node.data.textProperty, info.node.data.text); //memorizzo l'idFiltro che arriva da db nel textProperty // The property from the {@link #node} to map for the {@link #text} config.
        }
        else if (info.item.getLeaf() && info.item.getText() === "Ricerca Avanzata") {
            apriRicercaInFiltriAvanzati(info.node.data.textProperty, null, null); //dove info.node.raw.textProperty== -2 //passo parametri con id del filtro = -2  //Nessun filtro specificato
        }
        else 
        {  
            let isFiltroPredefinitoScelto = (info.node.parentNode.data.text == "Filtri predefiniti") ? true : false;
            apriRicercaInFiltriAvanzati(info.node.data.textProperty, info.node.data.title, info.node.data.text, isFiltroPredefinitoScelto); //passo parametri con id del filtro ed il suo contenuto   
        }
    }

    return (

        <Panel shadow scrollable
            titleCollapse={true}
            docked="left"
            title="Filtri"
            iconCls="x-fa fa-tag"
            collapsible={{
                collapsed: true, direction: 'left'
            }}
            collapsed={"false"}
        >

            <Container scrollable flex={1} layout="fit" >
                <Loadmask cls="custom-grid" itemId="loadMaskMainRto" hidden={stateLoadMask.hidden}
                    indicator={false}
                    message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                    modal={true}
                />
                <Panel
                    scrollable
                >
                    <TreeList docked="top"
                        expanderOnly={false}
                        store={stateStoreTree}
                        expanderFirst={false}
                        onItemclick={onItemclick}
                        itemId="itemIdTreeFiltri"
                    />
                </Panel>
           
            </Container>

        </Panel>
    );
}

export default RtoFiltriVeloci;