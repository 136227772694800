import React from 'react';
import { Panel, TextField, TextAreaField, Container, Button, Toolbar, Dialog, Loadmask, Spacer, Label} from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';

const Ext = window['Ext'];
var par = "";
function RtoPopupOperazioni(props) {

    const [showWorking, setShowWorking] = useState(false);
    const [state, setState] = useState({ intestazione: "", titolo: "", urlAzione: "", esito: "", ideStoRtoDsn: -1, flgForza: 0, numRto: 0 })
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento in corso..." });
    const { user, switchUser } = useUser();

    useEffect(() => {
        if (props != null) {
            inizializza();
        }
    }, []); // <-- empty array means 'run once'


    const inizializza = () => {
        state.numRto = props.data.length;
        switch (props.azione) {
            case "msTrasmetti":
                state.intestazione = "Cambio Stato";
                state.titolo = "Trasmetti a RFI " + state.numRto + " RTO selezionata/e in stato 0  (0 => 1)";
                state.urlAzione = "Rto/CambiaStatoRTO";
                state.ideStoRtoDsn = 1;
                break;
            case "msPrendiCarico":
                state.intestazione = "Cambio Stato";
                state.titolo = "Prendi in carico " + state.numRto + " RTO selezionata/e in stato 1  (1 => 2)"
                state.urlAzione = "Rto/CambiaStatoRTO";
                state.ideStoRtoDsn = 2;
                break;
            case "msCompletaPO":
                state.intestazione = "Cambio Stato";
                state.titolo = "Completa il PO per " + state.numRto + " RTO selezionata/e in stato 2  (2 => 4)"
                state.urlAzione = "Rto/CambiaStatoRTO";
                state.ideStoRtoDsn = 4;
                break;
            case "msPubblicaPO":
                state.intestazione = "Cambio Stato";
                state.titolo = "Pubblica il PO di " + state.numRto + " RTO selezionata/e"
                state.urlAzione = "Rto/CambiaStatoRTO";
                state.ideStoRtoDsn = 5;
                state.flgForza = 0;
                break;
            case "msPubblicaForza":
                state.intestazione = "Cambio Stato";
                state.titolo = "Pubblica e Forza il PO di " + state.numRto + " RTO selezionata/e"
                state.urlAzione = "Rto/CambiaStatoRTO";
                state.ideStoRtoDsn = 5;
                state.flgForza = 1;
                break;
            case "msAccettaPO":
                state.intestazione = "Cambio Stato";
                state.titolo = "Accetta PO per " + state.numRto + " RTO selezionata/e"
                state.urlAzione = "Rto/CambiaStatoRTO";
                state.ideStoRtoDsn = 6;
                break;
            case "msRifiutaPO":
                state.intestazione = "Cambio Stato";
                state.titolo = "Rifiuta PO per " + state.numRto + " RTO selezionata/e"
                state.urlAzione = "Rto/CambiaStatoRTO";
                state.ideStoRtoDsn = 7;
                break;
            case "msAnnullaRTO":
                state.intestazione = "Cambio Stato";
                state.titolo = "Annulla " + state.numRto + " RTO selezionata/e"
                state.urlAzione = "Rto/CambiaStatoRTO";
                state.ideStoRtoDsn = 3;
                break;
            case "msRichiediContr":
                state.intestazione = "Cambio Stato";
                state.titolo = "Richiedi la contrattualizzazione per " + state.numRto + " RTO selezionata/e"
                state.urlAzione = "Rto/CambiaStatoRTO";
                state.ideStoRtoDsn = 9;
                break;
            case "msContrattualizza":
                state.intestazione = "Cambio Stato";
                state.titolo = "Contrattualizza " + state.numRto + " RTO selezionata/e"
                state.urlAzione = "Rto/CambiaStatoRTO";
                state.ideStoRtoDsn = 9;
                break;
            case "mpAggiornaPO":
                state.intestazione = "Aggiorna PO";
                state.titolo = "Aggiorna PO " + state.numRto + " RTO selezionata/e"
                state.urlAzione = "Po/AggiornaPOMassivo";
                state.ideStoRtoDsn = -1;
                break;
            case "mpInviaRDI":
                state.intestazione = "PO";
                state.titolo = "Invio a RDI di " + state.numRto + " RTO selezionata/e "
                state.urlAzione = "Po/InviaAPUB";
                state.ideStoRtoDsn = -1;
                break;
            case "mpForzaRDI":
                state.intestazione = "PO";
                state.titolo = "Forzatura parere RDI per " + state.numRto + " RTO selezionata/e "
                state.urlAzione = "Rto/ForzaRTO";
                state.ideStoRtoDsn = -1;
                break;           
            case "mpVerificaCdSCirc":
                state.intestazione = "PO";
                state.titolo = "Verifica CdS e Circolabilità per " + state.numRto + " RTO selezionata/e "
                state.urlAzione = "Rto/CheckCCS";
                state.ideStoRtoDsn = -1;
                break;
            case "mpAssociazioneOneClick":
                state.intestazione = "PO";
                state.titolo = "Invio richiesta associazione OneClic per " + state.numRto + " RTO selezionata/e." 
                state.urlAzione = "Po/UploadSelectOneClic";
                state.ideStoRtoDsn = -1;
                break;
            case "btnAccetta":
                state.intestazione = "Accetta RTO Modificate";
                state.titolo = "Accetta le modifiche delle " + state.numRto + " RTO selezionata/e "
                state.urlAzione = "Rto/ConfermaAccettazioneList";
                state.ideStoRtoDsn = -1;
                break;
            case "btnRifiuta":
                state.intestazione = "Rifiuta RTO Modificate";
                state.titolo = "Rifiuta le modifiche delle " + state.numRto + " RTO selezionata/e "
                state.urlAzione = "Rto/ConfermaAccettazioneList";
                state.ideStoRtoDsn = -1;
                break;
            default:
                state.intestazione = "";
                state.titolo = "";
                state.ideStoRtoDsn = -1;
        }

        setState({ ...state });
    };

    const onClipboard = () => {       
        navigator.clipboard.writeText(state.esito);
    }
    const onConfermaAzione = () => {
        if (props.data.length > 0) {
            Ext.ComponentQuery.query('#btnConferma')[0].setDisabled(true); 
            var listaRto = Ext.encode(props.data);
            var azione = props.azione;
            par = "";
            if (azione === "btnAccetta" || azione === "btnRifiuta") {
                if (azione === "btnAccetta") {
                    par = {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        listaRto: listaRto,
                        flgAcz: 1,
                        noteAcz: ""
                    };
                }
                else {
                    par = {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        listaRto: listaRto,
                        flgAcz: 0,
                        noteAcz: Ext.ComponentQuery.query('#noteRifiuta')[0].getValue()  //noteRifiuto
                    };
                }
            }
            else {
                if (azione === "mpAssociazioneOneClick" || azione === "mpInviaRDI") {
                    par = {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        listaRto: listaRto
                    };
                }
                else if (azione === "mpAggiornaPO") {
                    par = {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideTipGrp: user.ideTipGrp,
                        listaRto: listaRto
                    }
                }
                else {
                    par = {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideStoRtoDsn: state.ideStoRtoDsn,
                        listaRto: listaRto,
                        gestore: !props.isIF,
                        flgForza: state.flgForza,
                        noteAnnullamento: Ext.ComponentQuery.query('#noteAnnulla')[0].getValue()    
                    };
                }
            }
            setStateLoadMask({ hidden: false, message: "Operazione in corso..." });
            Ext.Ajax.request({
                url: state.urlAzione,
                method: 'POST',
                params: par,
                timeout: 600000,
                callback: function (records, success, operation) {
                    setStateLoadMask({ hidden: true, message: "Operazione in corso..." });
                    if (success) {
                        var returnObj = Ext.decode(operation.responseText);
                        Ext.ComponentQuery.query('#btnClipboard')[0].setDisabled(false); 
                        if (returnObj.resp === "OK") {
                            let msgEsito = state.esito = (typeof returnObj.msg === 'string') ? returnObj.msg : returnObj.msg.join(";");
                            setState({ esito: msgEsito })
                        }
                        else {
                            let msgEsito = state.esito = returnObj.msgErr;
                            setState({ esito: msgEsito })
                        }
                    }
                    else {
                        Ext.Msg.alert('Conferma Azione', "Errore in " + state.urlAzione);
                    }
                }
            });
        }
    }

    const onChiudiAzione = () => {
        Ext.ComponentQuery.query('#noteAnnulla')[0].setValue("");
        Ext.ComponentQuery.query('#noteRifiuta')[0].setValue("");
        props.chiudiPopup();
    }

    return (
       
        <Container cls="container-spinner" layout="fit" >
            <Panel
                title={state.intestazione}               
                layout="vbox"    
                scrollable={true}                                
            >
                <Loadmask
                    cls="custom-grid"
                    itemId="loadMaskRtoDet"
                    hidden={stateLoadMask.hidden}
                    indicator={true}
                    message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                    modal={true}
                />
                    <Spacer />
                    <Label style={{ fontWeight: "bold" }} margin="5 0 0 10" html={state.titolo}/>
                    <TextAreaField itemId="noteAnnulla" height={150} margin="5 5 5 10" label="Nota" labelAlign="top" hidden={props.azione != "msAnnullaRTO" && props.azione != "msRifiutaPO"} />
                    <TextAreaField itemId="noteRifiuta" height={150} margin="5 5 5 10" label="Nota" labelAlign="top" hidden={props.azione != "btnRifiuta"} />
                    <Label  margin="5 0 10 10" flex={1} html={state.esito} />
                    <Spacer />
                    {/*  <Button iconCls={'fa-solid fa-hourglass-half fa-spin'} hidden={!showWorking}></Button> */}
                    <Spacer />
                </Panel>
                <Toolbar docked="bottom" border={true} height={40}>
                <Panel docked="right">
                    <Button cls="appButton" ui="action" margin="5 10 5 10" align="right" itemId="btnClipboard" iconCls="x-fa fa-clipboard" tooltip="Copia esito operazione" handler={onClipboard} disabled />
                        <Button cls="appButton" ui="action" margin="5 10 5 10" align="right" itemId="btnConferma" iconCls="x-fa fa-square-check" text="Conferma" handler={onConfermaAzione} />
                        <Button cls="appButton" ui="action" margin="5 10 5 10" align="right" itemId="btnChiudiRTO" iconCls="x-fa fa-square-check" text="Chiudi" handler={onChiudiAzione} />
                    </Panel>
                </Toolbar>
            </Container>

    )
}
export default RtoPopupOperazioni;
