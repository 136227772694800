import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Container, Button, Grid, Column, SegmentedButton } from '@sencha/ext-react-modern';
import { useNavigate } from "react-router-dom";

Chart.register(ChartDataLabels);

const Ext = window['Ext'];

function ColumnGroupedChart({ chartData, tooltipValues, title, scales, indexAxis, height = 420, isSwitchable, gridLabels, linkedTo }) {
    const [showChart, setShowChart] = useState(true);
    const chartRef = useRef(null);

    let navigate = useNavigate();

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        const chart = new Chart(ctx, {
            type: 'bar',
            data: chartData,
            options: {
                indexAxis: indexAxis,
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        display: tooltipValues ? false : 'auto',
                        formatter: (value, context) => {
                            return value;
                        },
                        color: 'rgba(0, 55, 128, 1)',
                        labels: {
                            title: {
                                font: {
                                    weight: 'bold',
                                }
                            }
                        },
                        anchor: 'center',
                        align: 'center',
                        offset: 0,
                    },
                    legend: {
                        position: 'bottom',
                    }
                },
                ...scales
            }
        });

        return () => chart.destroy();
    }, [chartData, scales, indexAxis]);

    const handleToggleView = () => {
        if (isSwitchable) {
            setShowChart(!showChart);
        }
    };

    const handleLink = () => {
        navigate(linkedTo);

    };

    function onDownloadClick() {
        var grid = Ext.ComponentQuery.query('#gridGrouped')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }

    return (
        <Container cls="chartParent" layout="fit" height={height}>
            <div style={{ display: showChart ? 'block' : 'none', width: '100%', height: '100%' }}>
                <canvas style={{ height: '100%', width: '100%', margin: '0' }} ref={chartRef} />
            </div>
            {showChart ? (
                <SegmentedButton docked="bottom" margin="10 0 0 0" style={{ display: isSwitchable ? 'flex' : 'none', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <Button tooltip="Vedi in tabella" cls="marginButton chartsButton" padding="0 2" align="right" text="" iconCls="x-fa fa-table" handler={handleToggleView} style={{ flex: '0 1 auto' }} />
                    <Button tooltip="Vedi in dettaglio" cls="marginButton chartsButton" padding="0 2" align="right" text="" iconCls="x-fa fa-magnifying-glass" style={{ flex: '0 1 auto', display: linkedTo ? 'block' : 'none' }} />
                </SegmentedButton>

            ) : (
                <>
                    <SegmentedButton docked="bottom" margin="10 0 0 0" style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
                        <Button tooltip="Vedi in grafico" cls="marginButton chartsButton" padding="0 2" align="right" text="" iconCls="x-fa fa-chart-bar" handler={handleToggleView} style={{ flex: '0 1 auto' }} />
                        <Button tooltip="Vedi in dettaglio" cls="marginButton chartsButton" padding="0 2" align="right" text="" iconCls="x-fa fa-magnifying-glass" style={{ flex: '0 1 auto', display: linkedTo ? 'block' : 'none' }} />
                        <Button tooltip="Scarica in Excel" cls="marginButton exportButton" docked="right" text="" handler={onDownloadClick} iconCls="x-fa fa-file-excel" />
                    </SegmentedButton>
                    <Grid
                        shadow
                        height="100%"
                        itemId="gridGrouped"
                        plugins={['gridexporter']}
                        store={chartData.datasets.map((dataset, datasetIndex) => (
                            dataset.data.map((value, index) => ({
                                label: chartData.labels[index],
                                [`value${datasetIndex}`]: value
                            }))
                        )).reduce((acc, val) => {
                            if (!acc.length) {
                                return val;
                            } else {
                                return acc.map((item, index) => ({ ...item, ...val[index] }));
                            }
                        }, [])}
                    >
                        <Column text={gridLabels[0]} dataIndex="label" flex={1} />
                        {chartData.datasets.map((dataset, datasetIndex) => (
                            <Column key={datasetIndex} text={`${chartData.datasets[datasetIndex].label}`} dataIndex={`value${datasetIndex}`} flex={1} />
                        ))}
                    </Grid>


                </>
            )}
        </Container>
    );
}

export default ColumnGroupedChart;
