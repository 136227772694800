import dayjs from 'dayjs';
import { colorMapping } from "./Costanti";
const colorizeCell = (dayIndex, periodicitaSettimana) => {
    const periodicitaValue = periodicitaSettimana.charAt(dayIndex);
    return colorMapping[periodicitaValue];
};
export function parseDate(dateString) {
    const [year, month, day] = dateString.split('-');
    const parsedDate = dayjs(`${year}-${month}-${day}`, { format: 'YYYY-MM-DD' });
    return parsedDate;
}

export function validateDates(dataInizio, dataFine) {
    const startDay = parseDate(dataInizio);
    const endDay = parseDate(dataFine);
    if (!startDay.isValid() || !endDay.isValid()) {
        return false;
    }
    return true;
}
export const calculateDateInfo = (currentDate, dataInizio) => {
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month();
    const daysInMonth = currentDate.daysInMonth();
    const firstDayOfWeek = currentDate.day() === 0 ? 6 : currentDate.day() - 1;
    const isFirstMonth = parseDate(dataInizio).isSame(currentDate, 'month');
    let daysDifference = 0;
    if (isFirstMonth) {
        // Calcola i giorni di differenza tra dataInizio e currentDate
        daysDifference = parseDate(dataInizio).diff(currentDate, 'day');
    }

    return { currentYear, currentMonth, daysInMonth, firstDayOfWeek, isFirstMonth, daysDifference };
};
export const calculateIsHighlightedDay = (day, currentMonth, currentYear, dataAttProvvedimento) => {
    const stringDataAttProvv = dayjs(dataAttProvvedimento)?.format('DD-MM-YYYY');
    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = (currentMonth + 1).toString().padStart(2, '0');
    const formattedDate = `${formattedDay}-${formattedMonth}-${currentYear}`;
    return formattedDate === stringDataAttProvv;
};
export function getBackgroundColor(periodicitaIndex, periodicita) {
    const periodicitaValue = periodicitaIndex < periodicita.length ? parseInt(periodicita[periodicitaIndex], 10) : 0;

    switch (periodicitaValue) {
        case 0:
            return '#C2C2C2';
        case 1:
            return '#5FA2DD';
        case 2:
            return '#DD5F5F';
        default:
            return '#C2C2C2';
    }
}

export function generateEmptyDays(firstDayOfWeek, isFirstMonth, daysDifference) {
    const emptyDays = [];
    const totalEmptyDays = firstDayOfWeek + (isFirstMonth && daysDifference > 0 ? daysDifference : 0);
    for (let i = 0; i < totalEmptyDays; i++) {
        emptyDays.push(<div className="day-empty" key={`empty-${i}`} />);
    }
    return emptyDays;
}

export function createDayElement(currentYear, currentMonth, day, dayClassName, color) {
    return (
        <div
            className={dayClassName}
            key={`${currentYear}-${currentMonth + 1}-${day}`}
            style={{ backgroundColor: color, borderColor: color }}
        >
            {day}
        </div>
    );
}
export function createMonthElement(currentYear, currentMonth, days, monthNames) {
    return (
        <div className="month" key={`${currentYear}-${currentMonth + 1}`}>
            <h2 className='monthTitle'>{monthNames[currentMonth]}</h2>
            <div className="days">{days}</div>
        </div>
    );
}
export const getClassAndStyle = (index, periodicitaSettimana) => {
    let style = {};
    const className = 'weekdayCell';
    style.backgroundColor = colorizeCell(index, periodicitaSettimana);
    style.borderColor = colorizeCell(index, periodicitaSettimana);
    return { className, style };
};
export function fixPeriodicita(sourceString, positionToMove, positionToPlaceAfter) {
    if (positionToMove < 0 || positionToMove >= sourceString.length ||
        positionToPlaceAfter < 0 || positionToPlaceAfter >= sourceString.length) {
        return sourceString;
    }
    const sourceArray = sourceString.split('');
    const characterToMove = sourceArray[positionToMove];
    sourceArray.splice(positionToMove, 1);
    sourceArray.splice(positionToPlaceAfter, 0, characterToMove);
    return sourceArray.join('');
}