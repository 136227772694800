import React from 'react';
import { Panel, FormPanel, Container, Button, TextField, Label, Grid, Column } from '@sencha/ext-react-modern';
import { useState, useContext, useEffect } from "react";
import { VersioneRtoContext } from '../components/VersioneRtoContext';
const Ext = window['Ext'];


function RtoDossierPcs(props) {

    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede;
    const dossierPCS = rtoSchede.data.items[0].data.dossierInfo
    let testata = [];
    testata.push(dossierPCS.testata);
    //console.log(dossierPCS)

    const [statePanelCollapse, setStatePanelCollapse] = useState(props.collapse);

    useEffect(() => {
        setStatePanelCollapse(props.collapse);
    }, [props.collapse]);

    const getIconClass = (ideSto) => {
        switch (ideSto) {
            case 'A':
                return 'x-fa fa-circle green';
            case 'R':
                return 'x-fa fa-circle red';
            case 'P':
                return 'x-fa fa-circle yellow';
            case 'U':
                return 'x-fa fa-circle blue';
            default:
                return '';
        }
    };
    const renderCellWithIcons = (value, ideSto, flag) => {
        const iconClass = getIconClass(ideSto);
        return (
            <div>
                <span className={iconClass} /> {flag ==="1" && (<span className='x-fa fa-star grey' />)} {value}
            </div>
        );
    };
    const formatDataAcquisizione = (dataAcquisizione) => {
        if (!dataAcquisizione) return "";

        const data = new Date(dataAcquisizione);
        if (isNaN(data.getTime())) return "";

        const options = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        };

        const dataFormattata = data.toLocaleDateString("it-IT", options);
        return dataFormattata;
    };
    const estrapolaDatiNota = (nota) => {
        const regex = /Da: (.+), (.+), (\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}:\d{2})/;
        const match = nota.match(regex);

        if (match) {
            const nome = match[1];
            const azienda = match[2];

            const dataNota = match[3].split(' ');
            const data = dataNota[0].split('.').join('/');
            const ora = dataNota[1];

            return { nome, azienda, dataNota: { data, ora } };
        } else {
            return { nome: null, azienda: null, dataNota: { data: null, ora: null } }; // Restituisci null se nessuna corrispondenza viene trovata
        }
    };
    const calcolaAltezzaTabella = (lunghezzaArray, altezzaRiga, maxAltezzaTabella) => {
        return lunghezzaArray && lunghezzaArray > 0
            ? Math.min(((lunghezzaArray * altezzaRiga) + (altezzaRiga * 2)), maxAltezzaTabella)
            : 0;
    };
    return (
        <Panel
            title="Dossier PCS"
            layout="vbox"
            iconCls="x-fa fa-earth-europe"
            collapsible={{ collapsed: statePanelCollapse, direction: 'top' }}
            cls='PnlSectionL1 iconPanelsRTO'
        >
            <Container layout="fit">
                <Container layout="hbox" defaults={{ margin: "0px 15px 0px 15px" }}>
                    <Container layout="vbox">
                        <Label style={{ fontWeight: "bold" }} html={"Dossier ID"} />
                        <Label html={dossierPCS.testata.ideDsi} />
                    </Container>
                    <Container layout="vbox">
                        <Label style={{ fontWeight: "bold" }} html={"Dossier Name"} />
                        <Label html={dossierPCS.testata.nomeDossier} />
                    </Container>
                    <Container layout="vbox">
                        <Label style={{ fontWeight: "bold" }} html={"Process Type"} />
                        <Label html={dossierPCS.testata.desTipPoe} />
                    </Container>
                    <Container layout="vbox">
                        <Label style={{ fontWeight: "bold" }} html={"Dossier Type"} />
                        <Label html={dossierPCS.testata.desTipDsi} />
                    </Container>
                    <Container layout="vbox">
                        <Label style={{ fontWeight: "bold" }} html={"Versione"} />
                        <Label html={dossierPCS.testata.prgVesDsi} />
                    </Container>
                    <Container layout="vbox">
                        <Label style={{ fontWeight: "bold" }} html={"Data Acquisizione"} />
                        <Label html={formatDataAcquisizione(dossierPCS.testata.dataAcquisizione)} />
                    </Container>
                </Container>
            </Container>

            <Panel
                title="Territories"
                layout="fit"
                iconCls="x-fa fa-map-location-dot"
                collapsible={{ collapsed: false, direction: 'top' }}
                cls='PnlSectionL1 iconPanelsRTO'
                margin="0 0 0 10"
            >
                <Grid
                    itemId="gridTerritori"
                    minHeight={calcolaAltezzaTabella(dossierPCS?.percorso?.length, 25, 160)}
                    cls="column-in-panel"
                    markDirty={true}
                    flex={1}
                    store={dossierPCS.percorso}
                    itemConfig={{ viewModel: true }}
                >
                    <Column
                        text="Impresa"
                        width={240}
                        dataIndex="nomIpr"
                        filter='string'
                        cell={{
                            xtype: 'reactcell',
                            renderer: (value, record) => renderCellWithIcons(value, record.get('ideStoAtaIpr'), record.get('flG_IPR_LEA')),
                        }}
                    />

                    <Column
                        text="Gestore"
                        width={160}
                        dataIndex="nomGsr"
                        filter='string'
                        cell={{
                            xtype: 'reactcell',
                            renderer: (value, record) => renderCellWithIcons(value, record.get('ideStoAtaGsr'), record.get('flG_GSR_LEA')),
                        }}
                    />
                    <Column text="Origine" dataIndex="nomLocPrv" filter='string' flex={1} />
                    <Column text="Destinazione" width={200} dataIndex="nomLocDsn" filter='string' />
                    <Column text="Fase" dataIndex="desFae" filter='string' flex={1} />
                    <Column text="N. Treno" dataIndex="numTreno" filter='string' flex={1} />
                </Grid>
            </Panel>

            <Panel
                title="Dossier Info"
                layout="fit"
                iconCls="x-fa fa-circle-info"
                collapsible={{ collapsed: false, direction: 'top' }}
                cls='PnlSectionL1 iconPanelsRTO'
                margin="0 0 0 10"
            >
                <Container layout="vbox" margin="0 0 0 5">

                    <Label style={{ fontWeight: "bold" }} html={"Route"} />
                    <Label html={dossierPCS.testata.prc} />
                    <br />
                    {dossierPCS.testata.arp !== null && dossierPCS.testata.arp !== "" && (
                        <>
                            <Label style={{ fontWeight: "bold" }} html={"Special Agreements"} />
                            <Label html={dossierPCS.testata.arp} />
                            <br />
                        </>
                    )}
                    {dossierPCS.testata.detGsr !== null && dossierPCS.testata.detGsr !== "" && (
                        <>
                            <Label style={{ fontWeight: "bold" }} html={"IM Details"} />
                            <Label html={dossierPCS.testata.detGsr} />
                            <br />
                        </>
                    )}

                </Container>

            </Panel>

            <Panel
                title="Comments"
                layout="fit"
                iconCls="x-fa fa-comments"
                collapsible={{ collapsed: false, direction: 'top' }}
                cls='PnlSectionL1 iconPanelsRTO'
                hidden={!dossierPCS?.note || dossierPCS.note.length === 0}
                margin="0 0 0 10"
            >
                <Grid
                    itemId="gridPCSNote"
                    minHeight={calcolaAltezzaTabella(dossierPCS?.note?.length, 40, 160)}
                    cls="column-in-panel commentsRow"
                    margin="0 0 0 5"
                    markDirty={true}
                    flex={1}
                    scrollable
                    store={dossierPCS.note}
                    itemConfig={{ viewModel: true }}
                >
                    <Column
                        text="Data"
                        width={160}
                        dataIndex="nomNot"
                        cell={{
                            xtype: 'reactcell',
                            renderer: (value) => {
                                const dataNota = estrapolaDatiNota(value)?.dataNota;
                                return (
                                    <div>
                                        <div>{dataNota.data}</div>
                                        <div>{dataNota.ora}</div>
                                    </div>
                                );

                            },
                        }}
                    />


                    <Column
                        text="Gestore"
                        width={160}
                        dataIndex="nomNot"
                        cell={{
                            xtype: 'reactcell',
                            renderer: (value) => {
                                const infoUtente = estrapolaDatiNota(value);
                                return (
                                    <div>
                                        <div>{infoUtente.nome}</div>
                                        <div>{infoUtente.azienda && `(${infoUtente.azienda})`}</div>
                                    </div>
                                );

                            },
                        }}
                    />
                    <Column text="Commento" dataIndex="notDsi" flex={1} />
                </Grid>
            </Panel>
            <Panel
                title="Link"
                layout="fit"
                iconCls="x-fa fa-bezier-curve"
                collapsible={{ collapsed: false, direction: 'top' }}
                cls='PnlSectionL1 iconPanelsRTO'
                margin="0 0 0 5"
                hidden={!dossierPCS?.link || dossierPCS.link.length === 0}
            >
                <Grid
                    itemId="gridPCSLink"
                    minHeight={calcolaAltezzaTabella(dossierPCS?.link?.length, 25, 100)}
                    cls="column-in-panel"
                    markDirty={true}
                    store={dossierPCS?.link}
                    itemConfig={{ viewModel: true }}
                >
                    <Column text="Dossier ID" width={160} dataIndex="ideDsiDsn" filter='string' />
                    <Column text="Dossier Name" width={160} dataIndex="nomDsiDsn" filter='string' flex={1} />
                    <Column text="International Train Nr" width={200} dataIndex="nomTreDsn" filter='string' />
                </Grid>

            </Panel>
            <Panel
                title="Connections"
                layout="fit"
                iconCls="x-fa fa-diagram-project"
                collapsible={{ collapsed: false, direction: 'top' }}
                cls='PnlSectionL1 iconPanelsRTO'
                margin="0 0 0 5"
                hidden={!dossierPCS?.coincidenze || dossierPCS.coincidenze.length === 0}
            >
                <Grid
                    itemId="gridPCSConnections"
                    minHeight={calcolaAltezzaTabella(dossierPCS?.coincidenze?.length, 25, 140)}
                    cls="column-in-panel"
                    markDirty={true}
                    store={dossierPCS?.coincidenze}
                    itemConfig={{ viewModel: true }}
                >
                    <Column text="N." width={90} dataIndex="prG_COI" />
                    <Column text="Coincidenza" width={200} dataIndex="deS_COI_IPR" filter='string' flex={1} />
                    <Column text="Commento del GI" width={200} dataIndex="deS_COI_GSR" filter='string' />
                    <Column
                        text="Con Garanzia"
                        width={160}
                        dataIndex="flG_GSR"
                        cell={{
                            xtype: 'reactcell',
                            renderer: (value) => {
                                const icon = value === 1 ? <span className="x-fa fa-circle-check blue" /> : null;
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {icon}
                                    </div>
                                );
                            },
                        }}
                    />
                </Grid>
            </Panel>
        </Panel>
    );
}

export default RtoDossierPcs;