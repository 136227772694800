import React from 'react';
import { Panel, Container, FormPanel, Loadmask, Field, ComboBoxField, CheckBoxField, DatePickerField, TimeField, FileField, Spacer, FieldSet, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';

const Ext = window['Ext'];
const storeTipoCliente = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiaClienteAll',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const caricaTipoCliente = () => {
    storeTipoCliente.load({
    })
}

const storeTipoImpresa = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipoImpresaAll',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const caricaTipoImpresa = () => {
    storeTipoImpresa.load();
}

const storeTipoTrafficoPrevalente = Ext.create('Ext.data.Store', {  
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiAnagraficaTipoTrafficoPrevalenteAll',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const caricaTipoTrafficoPrevalente = () => {
    storeTipoTrafficoPrevalente.load();
}

const storeClientiMaster = Ext.create('Ext.data.Store', {   
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Clienti/LeggiClientiMaster',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const caricaClientiMaster = () => {
    storeClientiMaster.load();
}

//------------------------------------------------------
const caricaStore = async () => {
    await Promise.all([
        caricaTipoCliente(),
        caricaTipoImpresa(),
        caricaTipoTrafficoPrevalente(),
        caricaClientiMaster()
    ])
}

caricaStore();

function ClienteTestata(props) {
    const { user, switchUser } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    var readonly = user.ruolo == 'RFI' ? false : true;

    // appoggio dati cliente ---------
    const [state, setState] = useState({ ideCli: null, nomCli: "", ideTipCli: null, ideTipTraCli: null, ideTipIpr: null, codCliRfi: "", siglaCli: "", ideCliMse: null, isMaster: false, isGruppo: null, isNewMaster: false });
   
    const initValues = () => {
        if (props.cliente != null) { 
            state.ideCli = props.cliente.ideCli;
            state.nomCli = props.cliente.nomCli;
            state.ideTipCli = props.cliente.ideTipCli;
            state.ideTipTraCli = props.cliente.ideTipTraCli;
            state.ideTipIpr = props.cliente.ideTipIpr;
            state.codCliRfi = props.cliente.codCliRfi;
            state.siglaCli = props.cliente.siglaCli.replace(state.codCliRfi+'-', '');  // props.cliente.siglaCli su db viene memorizzato come state.codCliRfi +"-"+ state.siglaCli
            state.ideCliMse = props.cliente.ideCliMse;
            state.isMaster = storeClientiMaster.find("idCliMse", props.cliente.ideCliMse) > 0 ? true : false;     

            state.isNewMaster = false;
            state.isGruppo = state.ideTipIpr == 2;
            setState({ ...state });
        }
    }

    const onModifica = () => {
        Ext.ComponentQuery.query('#buttonModificaTestata')[0].setDisabled(true);
        Ext.ComponentQuery.query('#confermaTestata')[0].setDisabled(false);
        Ext.ComponentQuery.query('#annullaTestata')[0].setDisabled(false);

        Ext.ComponentQuery.query('#nomCliTestata')[0].setDisabled(false);
        Ext.ComponentQuery.query('#codCliTestata')[0].setDisabled(false);
        Ext.ComponentQuery.query('#siglaCliTestata')[0].setDisabled(false);
        Ext.ComponentQuery.query('#tipoContratto')[0].setDisabled(false);
        Ext.ComponentQuery.query('#tipoTrafficoPrevalente')[0].setDisabled(false);

        Ext.ComponentQuery.query('#clienteMaster')[0].setDisabled(false);
        
    }
    const onConfermaModifica = () => {        
        // salvataggio
        let par = {
            ideUte: user.ideUte,
            ipAddress: "",
            ideCli: state.ideCli,
            nomCliNew: Ext.ComponentQuery.query('#nomCliTestata')[0].getValue(),
            codCliRfiNew: Ext.ComponentQuery.query('#codCliTestata')[0].getValue(),
            siglaClienteNew: Ext.ComponentQuery.query('#siglaCodCli')[0].getValue() + Ext.ComponentQuery.query('#siglaCliTestata')[0].getValue(),
            ragioneSociale: Ext.ComponentQuery.query('#ragSoc')[0].getValue(),
            ideCliMseNew: Ext.ComponentQuery.query('#clienteMaster')[0].getValue(),
            ideTipIprNew: Ext.ComponentQuery.query('#tipoContratto')[0].getValue(),
            IdeTipCliNew: Ext.ComponentQuery.query('#tipoCliente')[0].getValue(),
            IdeTipTraCliNew: Ext.ComponentQuery.query('#tipoTrafficoPrevalente')[0].getValue()
        };
        Ext.Ajax.request({
            url: 'Clienti/ModificaCliente',
            method: 'POST',
            params: par,
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);

                    if (returnObj.resp == "OK") {
                        Ext.toast({ message: 'Aggiornamento cliente effettuato con successo.', timeout: 4000 });
                        // aggiornamento state con nuovi dati
                        storeClientiMaster.load();
                        state.nomCli = par.nomCliNew;
                        state.ideTipCli = par.IdeTipCliNew;
                        state.ideTipTraCli = par.IdeTipTraCliNew;
                        state.ideTipIpr = par.ideTipIprNew;
                        state.codCliRfi = par.codCliRfiNew;
                        state.siglaCli = Ext.ComponentQuery.query('#siglaCliTestata')[0].getValue();
                        state.ideCliMse = par.ideCliMseNew;
                        state.isMaster = storeClientiMaster.find("idCliMse", par.ideCliMseNew) > 0 ? true : false;   
                        state.isNewMaster = false;
                        state.isGruppo = state.ideTipIpr == 2;
                        setState({ ...state });
                        Ext.ComponentQuery.query('#buttonModificaTestata')[0].setDisabled(false);
                        Ext.ComponentQuery.query('#confermaTestata')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#annullaTestata')[0].setDisabled(true);
                        disabilitaBox();
                    }
                    else {
                        Ext.Msg.alert('Modifica riepilogo fallito', returnObj.msgErr);
                    }
                }
                else {                    
                    Ext.Msg.alert('Modifica riepilogo fallito', returnObj.msgErr);
                }
            }
        });
      
    }

    const onAnnullaModifica = () => {
        Ext.ComponentQuery.query('#buttonModificaTestata')[0].setDisabled(false);
        Ext.ComponentQuery.query('#confermaTestata')[0].setDisabled(true);
        Ext.ComponentQuery.query('#annullaTestata')[0].setDisabled(true);

        Ext.ComponentQuery.query('#nomCliTestata')[0].setValue(state.nomCli).setDisabled(true);
        Ext.ComponentQuery.query('#codCliTestata')[0].setValue(state.codCliRfi).setDisabled(true);
        Ext.ComponentQuery.query('#siglaCliTestata')[0].setValue(state.siglaCli).setDisabled(true);
        Ext.ComponentQuery.query('#tipoContratto')[0].setValue(state.ideTipIpr).setDisabled(true);
        Ext.ComponentQuery.query('#tipoTrafficoPrevalente')[0].setValue(state.ideTipTraCli).setDisabled(true);
        Ext.ComponentQuery.query('#clienteMaster')[0].setValue(state.ideCliMse).setDisabled(true);

        state.isNewMaster = false;
        state.isGruppo = state.ideTipIpr == 2;
        setState({ ...state });
    }

    const disabilitaBox = () => {
        Ext.ComponentQuery.query('#nomCliTestata')[0].setDisabled(true);
        Ext.ComponentQuery.query('#codCliTestata')[0].setDisabled(true);
        Ext.ComponentQuery.query('#siglaCliTestata')[0].setDisabled(true);
        Ext.ComponentQuery.query('#tipoContratto')[0].setDisabled(true);
        Ext.ComponentQuery.query('#tipoTrafficoPrevalente')[0].setDisabled(true);
        Ext.ComponentQuery.query('#clienteMaster')[0].setDisabled(true);
    }

    const CodiceClienteOnChange = (args) => {
        Ext.ComponentQuery.query('#siglaCodCli')[0].setValue(args.newValue+"-");
    }
    const onSelectTipoContratto = (arg) => {
        state.isGruppo = arg.newValue.data.ideTipIpr === 2? true : false;
        Ext.ComponentQuery.query('#clienteMaster')[0].setDisabled(false); 
        setState({ ...state });
    }

    const onSelectClienteMaster = (arg) => {
        state.isNewMaster = arg.newValue.data.idCliMse === -1 ? true : false;        
        setState({ ...state });
    }

    useEffect(() => {
        if ('cliente' in props) {
            initValues();
        }       
    }, [props.cliente]);

    return (

        <Panel
            title="Cliente"
            scrollable
            itemId="panelCliente"
            bodyPadding={5}
            titleCollapse={true}
            iconCls="x-fa fa-user-tie"
            collapsible={{ collapsed: false, direction: 'top' }}
            cls='PnlSectionL1 iconPanelsRTO'
            tools={[
                { iconCls: 'x-fa fa-wrench', itemId: "buttonModificaTestata", hidden: readonly, handler: onModifica.bind(this), tooltip: 'Modifica' },
                { iconCls: 'x-fa fa-check', itemId: "confermaTestata", hidden: readonly, disabled: 'true', handler: onConfermaModifica.bind(this), tooltip: 'Conferma' },
                { iconCls: 'x-fa fa-undo', itemId: "annullaTestata", hidden: readonly, handler: onAnnullaModifica.bind(this), disabled: 'true', tooltip: 'Annulla' }
            ]}
        >
            <FormPanel layout="hbox" >
                <Container layout="vbox" margin="5px" flex={1.5}>
                    <TextField clearable={false} label="Denominazione" itemId="nomCliTestata" labelAlign="left" disabled={true} head="20" flex={1} labelWidth="130" required={true} requiredMessage="Questo campo &egrave; obbligatorio" value={state.nomCli} />
                    <Container layout="hbox">                        
                        <TextField clearable={false} label="Codice Cliente" itemId="codCliTestata" labelAlign="left" disabled={true} head="20" labelWidth="130" flex={0.23} 
                            value={state.codCliRfi} onChange={CodiceClienteOnChange}
                            required={true} requiredMessage="Questo campo &egrave; obbligatorio"/>
                        <Container layout="hbox" flex={0.47}>
                            <Field label="Sigla Cliente" disabled={true} margin="0 0 0 10" head="20" labelWidth="90" ></Field>
                            <TextField clearable={false} itemId="siglaCodCli" disabled={true} head="20" width="40"  />
                            <TextField clearable={false} itemId="siglaCliTestata" disabled={true} head="20" flex={1} required={true} requiredMessage="Questo campo &egrave; obbligatorio" value={state.siglaCli} />
                        </Container>
                        <ComboBoxField
                            label="Tipo Contratto"
                            labelAlign="left"
                            store={storeTipoImpresa}
                            displayField="desTipIpr"
                            valueField="ideTipIpr"
                            queryMode="local"
                            itemId="tipoContratto"
                            typeAhead
                            required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            forceSelection={true}
                            disabled={true}
                            value={state.ideTipIpr}
                            flex={0.3} 
                            margin="0 0 0 10"
                            onSelect={onSelectTipoContratto}
                        />
                    </Container>
                </Container>
                <Container layout="vbox" margin="5px" flex={0.7}>
                    <ComboBoxField
                        label="Tipo Cliente"
                        labelAlign="left"
                        store={storeTipoCliente}
                        displayField="desTipCli"
                        valueField="ideTipCli"
                        queryMode="local"
                        itemId="tipoCliente"
                        typeAhead
                        required={true}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                        disabled={true}
                        value={state.ideTipCli}
                        flex={1}
                    />
                    <Container minHeight="20" itemId="contCliMaster" cls={state.isGruppo ? 'PnlCML' : ''}>
                        <ComboBoxField
                            label="Cliente Master"
                            labelAlign="left"
                            store={storeClientiMaster}
                            displayField="rge"
                            valueField="idCliMse"
                            queryMode="local"
                            itemId="clienteMaster"
                            typeAhead
                            required={false}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            forceSelection={true}
                            disabled={true}
                            value={state.ideCliMse}
                            flex={1}
                            hidden={!state.isGruppo}
                            onSelect={onSelectClienteMaster}
                        />
                    </Container>
                </Container>
                <Container layout="vbox" margin="5px" flex={0.8} >
                    <ComboBoxField
                        label="Tipo Traffico Prevalente"
                        labelWidth="150"
                        labelAlign="left"
                        store={storeTipoTrafficoPrevalente}
                        displayField="desTipTraCli"
                        valueField="ideTipTraCli"
                        queryMode="local"
                        itemId="tipoTrafficoPrevalente"
                        typeAhead
                        required={false}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                        disabled={true}
                        value={state.ideTipTraCli}
                    />
                    <Container minHeight="20" itemId="contRagSoc" cls={state.isNewMaster ? 'PnlCML' : ''}>
                        <TextField clearable={false} label="Ragione Sociale" itemId="ragSoc" labelAlign="left" head="20" flex={1} labelWidth="150" hidden={!state.isNewMaster} />
                    </Container>
                </Container>
            </FormPanel>

        </Panel>

    );
}

export default ClienteTestata;