import React from 'react';
import { Panel, Container, Loadmask } from '@sencha/ext-react-modern';
import { useState, useEffect, useContext } from "react";
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import RtoServizi from './RtoIIServizi';
import RtoLegami from './RtoIILegami';
import RtoManovre from './RtoIIManovre';

const Ext = window['Ext'];

function RtoII(props) {
    
    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede;
    let ideRto = rtoSchede.data.items[0].data.rto.ideRTO;

    const [state, setState] = useState({ impianti: [], tipoServizioInfrastruttura: [], areeManovraLoc: [], fascioAreaManovraImpegnoImpianto: [], binarioFascioManovraImpegnoImpianto: [], gestoreManovraImpegnoImpianto: [], impDispConServizi: [], operazioni: [] })
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento in corso..." });
    const [statePanelCollapse, setStatePanelCollapse] = useState(props.collapse);

    useEffect(() => {
        setStatePanelCollapse(props.collapse);
    }, [props.collapse]);

    const leggiAnagrafiche = (ideRTO) => {
        setStateLoadMask({ hidden: false, message: "Attendere caricamento in corso..." });
        Ext.Ajax.request({
            url: 'Rto/GetAnagraficheII',
            method: 'POST',
            params: { ideRto: ideRTO },
            timeout: 600000, 
            callback: function (resp, success, operation) {
                if (success) {
                    var resptext = Ext.decode(operation.responseText);
                    state.impianti = resptext.impianti;
                    state.tipoServizioInfrastruttura = resptext.tipoServizioInfrastruttura;
                    state.areeManovraLoc = resptext.areeManovraLoc;
                    state.fascioAreaManovraImpegnoImpianto = resptext.fascioAreaManovraImpegnoImpianto;
                    state.binarioFascioManovraImpegnoImpianto = resptext.binarioFascioManovraImpegnoImpianto;
                    state.gestoreManovraImpegnoImpianto = resptext.gestoreManovraImpegnoImpianto;
                    state.operazioni = resptext.tipoOperazioneManovra;
                    Ext.Ajax.request({
                        url: 'Rto/GetLocalitaDisponibiliII',
                        method: 'POST',
                        params: { ideRto: ideRTO, impegnoImpianto: Ext.encode(state.impianti) },
                        callback: function (resp, success, operation) {
                            if (success) {
                                var resptext = Ext.decode(operation.responseText);
                                state.impDispConServizi = resptext;
                                setState({ ...state });
                            }
                            setStateLoadMask({ hidden: true, message: "Attendere caricamento in corso..." });
                        }
                    })
                }
            }
        });
    }
    
    useEffect(() => { 
        if(props.inEdit === true)
            leggiAnagrafiche(ideRto);
    }, [ideRto]); // <-- empty array means 'run once'

    return (
        <Container>            
            <Panel
                title="Impegno Impianto"
                titleCollapse={true}
                layout="vbox"
                iconCls="x-fa fa-train-tram"
                bodyPadding={3}
                collapsible={{ collapsed: statePanelCollapse, direction: 'top' }}
                cls='PnlSectionL1 iconPanelsRTO'
            //listeners={.....}
                >
                <Loadmask
                    cls="custom-grid"
                    itemId="loadMaskRtoDet"
                    hidden={stateLoadMask.hidden}
                    indicator={true}
                    message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                    modal={true}
                />
                {((state.impianti.length > 0 &&
                    state.tipoServizioInfrastruttura.length > 0 &&
                    state.areeManovraLoc.length > 0) || props.inEdit === false) &&

                    <Container margin="0 0 0 10">
                        <RtoServizi inEdit={props.inEdit} aggiornaStatePeriodII={props.aggiornaStatePeriodII} aggiornaStateServII={props.aggiornaStateServII} localitaDisponibili={state.impDispConServizi} aggiornaStateControllo={props.aggiornaStateControllo} tipiServizioInfrastruttura={Ext.encode(state.tipoServizioInfrastruttura)} aree={Ext.encode(state.areeManovraLoc)} aggiornaStateModifica={props.aggiornaStateModifica} />
                        <RtoLegami inEdit={props.inEdit} aggiornaStatePeriodII={props.aggiornaStatePeriodII} localitaDisponibili={state.impDispConServizi} aggiornaStateModifica={props.aggiornaStateModifica} aggiornaStateControllo={props.aggiornaStateControllo} />
                        <RtoManovre inEdit={props.inEdit} aggiornaStatePeriodII={props.aggiornaStatePeriodII} localitaDisponibili={state.impDispConServizi} tipiOperazioni={state.operazioni} gestori={state.gestoreManovraImpegnoImpianto} aggiornaStateModifica={props.aggiornaStateModifica} aggiornaStateControllo={props.aggiornaStateControllo} aggiornaStateModificaManovra={props.aggiornaStateModificaManovra} />
                    </Container>
                }
            </Panel>

        </Container>
    );
}

export default RtoII;