import { useState, React, useEffect } from 'react';
import { Container, Panel, Titlebar, Grid, Progress, Button, Dialog, Loadmask, SegmentedButton, Column, Toolbar, ProgressBarWidget, ExtButton, ComboBoxField, Combobox, TextField } from '@sencha/ext-react-modern';
import { useUser } from '../components/UserContext';
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';
import { useNavigate } from "react-router-dom";
import SchedaRto from '../rto/Rto';
//import * as XLSX from 'xlsx'; //serve per leggere e trasformare file Excel in array

let selezioneMultipla = false;

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.plugin.Exporter'
]);

const storePO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'RtoInternazionali/LeggiPCSElencoDossierExport',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeTipoTraffico = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipoTraffico',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeStatiRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'RtoInternazionali/LeggiPCSTipologiaStatiRTO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeTipiInvio = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'RtoInternazionali/LeggiPCSAnagraficheTipoTracce',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const storeImprese = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiClientiByUtente',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//  let x = '';
function PubblicazionePOPCS() {
    const { user, switchUser } = useUser();
    const { ambiente, updateAmbiente } = useAmbiente();
    const { periodi } = usePeriodi();

    storePeriodiFerroviari.setData(periodi.periodiFerroviari);

    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento in corso..." });

    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });

    let tokenApp = null;
    const navigate = useNavigate();
    let numRecordText = "Totale : "
    let numRecordSelText = "Selezionate : "
    let numRecordErrText = "In errore : "
    //----------------------------
    let title = "Pubblicazione PO in PCS";
    let ui = 'action';
    const [showDialogDetailRTO, setShowDialogDetailRTO] = useState(false);
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    const [state, setState] = useState({ percentuale: null, totale: null, completate: null, testo: null })
    const [paramsDetail, setParamsDetail] = useState({ ideRTO: 0, ideGrpRTO: 0, ideCli: 0, flgUpdVes: null, flgModTrc: null, flgUpdTrc: null, tipoAzione: "" })

    //const caricaPeriodiFerroviari = () => {
    //    storePeriodiFerroviari.load({
    //    })
    //}
    
    const caricaImprese = () => {
        storeImprese.load({
            params: {
                ideUte: user.ideUte
            }
        })
    }

    const caricaTipoTraffico = () => {
        storeTipoTraffico.load({
            params: {
                ideUte: user.ideUte
            }
        })
    }

    const caricaStatiRTO = () => {
        storeStatiRTO.load({
            params: {
                ideUte: user.ideUte
            }
        })
    }

    const caricaTipiInvio = () => {
        storeTipiInvio.load({
            params: {
                ideUte: user.ideUte
            }
        })
    }

 
    const handlePulisciFiltri = (btn) => {
        Ext.ComponentQuery.query('#cmbImpresa')[0].setValue(null);
        Ext.ComponentQuery.query('#cmbTipoTraffico')[0].setValue(null);
        Ext.ComponentQuery.query('#cmbStatoRto')[0].setValue(null);
        Ext.ComponentQuery.query('#cmbTipoInvio')[0].setValue(null);
        Ext.ComponentQuery.query('#txtDossier')[0].setValue("");
    };

    const setBtnTotRecord = (numRecord) => {
        Ext.ComponentQuery.query('#btnTotRecordPO')[0].setText("Totale righe: " + numRecord);
    }

    const setBtnSelRecord = (numRecord) => {
        Ext.ComponentQuery.query('#btnSelRecordPO')[0].setText("Righe selezionate: " + numRecord);
    }
    const setBtnErrRecord = (numRecord) => {
        Ext.ComponentQuery.query('#btnTotErrRecordPO')[0].setText("Totale righe in errore: " + numRecord);
    }



    const aggiornaState = (result) => {
        state.totale = result[0].totale;
        state.percentuale = result[0].percentuale;
        state.completate = result[0].completate;
        state.testo = "Pubblicazione in corso " + result[0].percentuale + "% (" + result[0].completate + " di " + result[0].totale + ")...";

        //  x = state.uploadXlsInfo.data.pctCmp
        setState({ ...state });
    }

    const aggiornaStateFine = () => {
        state.totale = null;
        state.percentuale = null;
        state.completate = null;
        state.testo = null;
        //  x = state.uploadXlsInfo.data.pctCmp
        setState({ ...state });
        if (storePO.data.items.length > 0) {
                caricaPO();
        }
    }

    const caricaProgress = () => {
        return new Promise(function (resolve, reject) {
            const interval = setInterval(() => {
                Ext.Ajax.request({
                    url: 'RtoInternazionali/GetBarraPercentualeExportInPCS',
                    method: 'GET',
                    params: {
                        ideUte: user.ideUte
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                            var returnObj = Ext.decode(operation.responseText);
                            if (returnObj[0].totale === returnObj[0].completate) {
                                aggiornaStateFine();
                                clearInterval(interval);
                            } else {
                                aggiornaState(returnObj);
                            }
                        }
                    }
                });
            }, 6000);

            // Restituisci la funzione di clearInterval (se necessario)
            return () => clearInterval(interval);
        })
    };

    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ambiente.ideAnnFro);

            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });

        }
    };
    const aggiornaStateAmbiente = () => {
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        setStateAmbiente({ ...stateAmbiente });
    }

    useEffect(() => {
        //caricaPeriodiFerroviari();
        caricaImprese();
        caricaTipoTraffico();
        caricaStatiRTO();
        caricaTipiInvio();

        storePO.clearData();
        storePO.removeAll();
        caricaProgress();
    }, []);

    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);

    const autenticazionePCS = () => {
        return new Promise(function (resolve, reject) {
            Ext.Ajax.request({
                url: 'RtoInternazionali/AutenticazionePCS',
                params: {
                    ideUte: user.ideUte
                },
                method: 'GET',
                callback: function (records, success, operation) {
                    var returnObj = Ext.decode(operation.responseText);
                    if (returnObj === "" || returnObj === null) {
                        navigate('/AuthPCS');
                        return;
                    }
                    tokenApp = returnObj;
                    resolve();
                }
            });
        })
    };


    const caricaPO = () => {
        setStateLoadMask({ hidden: false, message: "Caricamento..." });
        var annoA = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value;
        var ideCliA = Ext.ComponentQuery.query('#cmbImpresa')[0]._value;
        var ideDSIA = Ext.ComponentQuery.query('#txtDossier')[0]._value; 
        var ideTipTraA = Ext.ComponentQuery.query('#cmbTipoTraffico')[0]._value;
        var ide_sto_rtoA = Ext.ComponentQuery.query('#cmbStatoRto')[0]._value;
        var tipoInvioA = Ext.ComponentQuery.query('#cmbTipoInvio')[0]._value;
        storePO.load({
            params: {
                ideUte: user.ideUte,
                anno: annoA,
                ideCli: ideCliA,
                ideTipTra: ideTipTraA,
                ideDSI: ideDSIA,
                ide_sto_rto: ide_sto_rtoA,
                tipoInvio: tipoInvioA
            },
            callback: function (records, options, success) {
                setStateLoadMask({ hidden: true, message: "Attendere avvio acquisizione in corso..." });
                if (success) {
                    setBtnSelRecord(0);
                    if (records.length < 1) {
                        setBtnTotRecord(0);
                        setBtnErrRecord(0);
                    }
                    else {
                        setBtnTotRecord(records.length);
                        let countInErrore = 0;
                        for (let i = 0; i < records.length; i++) {
                            if (records[i].data.msG_TRC_PCS !== "Ok" && records[i].data.msG_TRC_PCS !== "" && records[i].data.msG_TRC_PCS !== undefined && records[i].data.msG_TRC_PCS !== null) {
                                countInErrore++;
                            }
                        }
                        setBtnErrRecord(countInErrore);
                    }
                }
                else {
                    Ext.Msg.alert('Elenco PO', 'Errore caricamento PO');
                }
            }

        })
    }

    const handleSubmitCerca = (btn) => {
        autenticazionePCS().then(function () {
            caricaPO(); 
        });        
    };

    function onDownloadClick() {
        var grid = Ext.ComponentQuery.query('#gridPubbPO')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: 'Pubblicazione Dossier',
            fileName: 'PubblicazioneDossier.xlsx'
        });
    }

    function distinctByProperty(array, property) {
        let distinctValues = [];
        let uniqueValues = [];

        array.forEach(obj => {
            if (!distinctValues.includes(obj[property])) {
                distinctValues.push(obj[property]);
                uniqueValues.push(obj);
            }
        });

        return uniqueValues;
    }
    function hideDialogDetailRTO() {
        setShowDialogDetailRTO({ showDialogDetailRTO: false });
        //caricaRtoList(params.filtro);
    }

    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
                let maxDateItem = findMaxDateItem(Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getStore().data.items);
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != maxDateItem.data.ideAnnFro) {
                    Ext.ComponentQuery.query('#buttonPubblica')[0].disable();
                }
                else {
                    Ext.ComponentQuery.query('#buttonPubblica')[0].enable();
                }
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = stateAmbiente.ideTipPer;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente PubblicazionePOPCS SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente PubblicazionePOPCS SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
    }

    function findMaxDateItem(items) {
        if (items.length === 0) {
            return null; // Handle the case where the array is empty
        }

        // Initialize the max date item
        let maxDateItem = items[0];

        // Iterate over the items to find the one with the highest date
        for (let i = 1; i < items.length; i++) {
            if (new Date(items[i].datIniVal) > new Date(maxDateItem.datIniVal)) {
                maxDateItem = items[i];
            }
        }

        return maxDateItem;
    }

    function onMenuPubblicaClick(item) {
        var grid = Ext.ComponentQuery.query('#gridPubbPO')[0];
        let selezione = [];
        for (let j = 0; j < grid.getSelected().items.length; j++) {
            if (!selezione.includes(grid.getSelected().items[j].data.idE_DSI))
            selezione.push(grid.getSelected().items[j].data.idE_DSI);
        }

        if (selezione.length > 0) {
            var elencoDossier = "";
            for (let i = 0; i < selezione.length; i++) {
                elencoDossier = elencoDossier + selezione[i] + ",";
            }

            var popupWindow = Ext.create('Ext.window.Window', {
                title: 'Pubblicazione PO in PCS',
                modal: true,
                width: 400,
                layout: 'fit',
                items: [
                    {
                        xtype: 'formpanel',
                        bodyPadding: 10,
                        items: [
                            {
                                xtype: 'label',
                                style: { fontWeight: "bold" },
                                html: "Pubblica PO in PCS per le " + selezione.length + " RTO selezionate" 
                            },
                            {
                                xtype: 'textareafield',
                                name: 'parametroAggiuntivo',
                                itemId: 'notaPubblica',
                                label: 'Dossier comment',
                                labelAlign: 'top', 
                                allowBlank: false,
                                maxLength: 1000, // Imposta il limite di caratteri a 1000
                                enforceMaxLength: true // Questo assicura che il limite di caratteri sia effettivamente imposto
                            }
                        ],
                        buttons: [
                            {
                                text: 'Pubblica',
                                formBind: true,
                                cls: "appButton",
                                iconCls: "x-fa fa-square-check",
                                handler: function (button) {
                                        var nota = Ext.ComponentQuery.query('#notaPubblica')[0]._value;
                                        nota = nota && nota.trim() !== '' ? nota : null;
                                        popupWindow.close();
                                        // Mostra la load mask
                                        setStateLoadMask({ hidden: false, message: "Attendere pubblicazione in corso..." });

                                        // Autenticazione e chiamata AJAX
                                        autenticazionePCS().then(function () {
                                            Ext.Ajax.request({
                                                url: 'RtoInternazionali/PubblicaDossiers',
                                                method: 'POST',
                                                params: {
                                                    listaDossier: elencoDossier,
                                                    ideUte: user.ideUte,
                                                    anno: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value,
                                                    token: tokenApp,
                                                    notaDossierChecked: nota
                                                },
                                                callback: function (records, success, operation) {
                                                    if (success) {
                                                        caricaProgress().then(function () {
                                                            var returnObj = Ext.decode(operation.responseText);
                                                            //modifica gestione errore
                                                            if (returnObj.resp == "KO") {
                                                                Ext.Msg.alert('Avvio pubblicazione fallito', returnObj.msgErr)
                                                            }
                                                            else {
                                                                caricaPO();
                                                            }
                                                        })
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Avvio pubblicazione fallito', 'Avvio pubblicazione fallito');
                                                    }
                                                }
                                            })
                                        });
                                }
                            },
                            {
                                text: 'Annulla',
                                cls: "appButton",
                                iconCls: "x-fa fa-square-check",
                                handler: function () {
                                    popupWindow.close();
                                    setStateLoadMask({ hidden: true, message: "Attendere pubblicazione in corso..." });
                                }
                            }
                        ]
                    }
                ]
            });

            popupWindow.show();

        }
        else {
            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
            Ext.Msg.alert('Nessuna selezione', 'Selezionare un PO dalla lista');
        }
    }
    function onApriRTOClick(grid, info) {
        paramsDetail.ideRTO = info.record.data.idE_RTO;
        paramsDetail.ideGrpRTO = user.idTipoGruppo;
        paramsDetail.ideCli = info.record.data.idE_CLI;
        paramsDetail.flgUpdVes = false;
        paramsDetail.flgModTrc = false;
        paramsDetail.flgUpdTrc = false;
        paramsDetail.tipoAzione = "A";
        paramsDetail.modificaServiziCommerciali = false;
        setParamsDetail({ ...paramsDetail });
        setShowDialogDetailRTO({ showDialogDetailRTO: true });
    }
    //----------------------------------------------------------------
    <i class=""></i>
    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1}>
                    <Button align="left" cls="title-icon" iconCls="fa-lg fa-solid fa-cloud-upload-alt" />
                    <Combobox
                        align="right"
                        width={170}
                        itemId="comboPeriodoFerroviario"
                        margin='0 4 0 0'
                        editable="false"
                        valueField="ideAnnFro"
                        displayField="desAnnFro"
                        autoSelect="true"
                        queryMode="local"
                        store={storePeriodiFerroviari}
                        onSelect={onSelectPeriodoFerroviario} 
                    />

                </Titlebar>
                <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
                    <Loadmask
                        cls="custom-grid"
                        itemId="loadMaskRtoDet"
                        hidden={stateLoadMask.hidden}
                        indicator={true}
                        message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                        modal={true}
                    />
                    <Grid
                        flex={1}
                        scrollable
                        itemId="gridPubbPO"
                        variableHeights={true}
                        store={storePO}
                        selectable={{ checkbox: true, mode: 'multi' }}
                        itemConfig={{
                            viewModel: true
                        }}
                        plugins={{ gridfilters: {}, gridexporter: {} }}
                        listeners={{
                            beforeselect: (checkColumn, rowIndex, checked, record, eOpts) => {
                                if (selezioneMultipla === false) {
                                    selezioneMultipla = true;
                                    if (rowIndex.length === 1) {

                                        const targetId = rowIndex[0].data.idE_DSI;
                                        const data = checkColumn.getStore().getData().items;

                                        const rowsToSelect = data.filter((row) => row.data.idE_DSI === targetId);

                                        // Verifica la condizione per ogni riga in rowsToSelect
                                        for (let i = 0; i < rowsToSelect.length; i++) {
                                            if (rowsToSelect[i].data.flG_IVO_PCS === 0) {
                                                // Se la condizione non � soddisfatta per almeno una riga, annulla la selezione
                                                selezioneMultipla = false;
                                                checkColumn.getSelected().remove(rowIndex);
                                                Ext.toast({ message: 'Riga non selezionabile', timeout: 3000 });
                                                setBtnSelRecord(checkColumn.getSelected().items.length);
                                                return false;
                                            }
                                        }

                                        const selectedIds = checkColumn.getSelected().items;
                                        rowsToSelect.push(...selectedIds);

                                        // Se la condizione � soddisfatta per tutte le righe, seleziona normalmente
                                        checkColumn.select(rowsToSelect);

                                    }
                                    else {
                                        checkColumn.getSelected().removeAll();
                                        let arraySelezione = [];
                                        for (let j = 0; j < rowIndex.length; j++) {
                                            const targetId = rowIndex[j].data.idE_DSI;
                                            const data = checkColumn.getStore().getData().items;

                                            const rowsToSelect = data.filter((row) => row.data.idE_DSI === targetId);
                                            let almenoUNoFalse = false;
                                            // Verifica la condizione per ogni riga in rowsToSelect
                                            for (let i = 0; i < rowsToSelect.length; i++) {                                           
                                                if (rowsToSelect[i].data.flG_IVO_PCS === 0) {
                                                    almenoUNoFalse = true;
                                                }
                                            }
                                            if (almenoUNoFalse === false) {
                                                arraySelezione.push(...rowsToSelect);
                                            }
                                        }
                                        if (arraySelezione.length === 0) {
                                            Ext.toast({ message: 'Nessuna riga selezionabile', timeout: 3000 });
                                        }
                                        checkColumn.select(arraySelezione);
                                        selezioneMultipla = false;
                                        setBtnSelRecord(checkColumn.getSelected().items.length);
                                        return false;
                                    }
                                    setBtnSelRecord(checkColumn.getSelected().items.length);
                                    selezioneMultipla = false;
                                }
                            },
                            beforedeselect: (checkColumn, rowIndex, checked, record, eOpts) => {
                                if (rowIndex.length === 1) {
                                    if (selezioneMultipla === false) {
                                        selezioneMultipla = true;
                                        const targetId = rowIndex[0].data.idE_DSI;
                                        const data = checkColumn.getStore().getData().items;

                                        const rowsToSelect = data.filter((row) => row.data.idE_DSI === targetId);

                                        const selectedIds = checkColumn.getSelected().items;

                                        const filteredSelectedIds = selectedIds.filter((selectedItem) => {
                                            return !rowsToSelect.some((row) => row.data.idE_DSI === selectedItem.data.idE_DSI);
                                        });

                                        checkColumn.select(filteredSelectedIds);

                                        selezioneMultipla = false;
                                        setBtnSelRecord(checkColumn.getSelected().items.length);
                                    }
                                }
                            }
                        }}
                        cls="custom-grid"
                        loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                    >

                        <Toolbar docked="top" margin="2 0 2 0" cls="lottoToolbar">


                            <SegmentedButton docked="right" margin='0 0 10 0'
>
                                <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                                <ExtButton cls="marginButton appButton nuvola" align="right" itemId="buttonPubblica" width={160} text="Pubblica in PCS" iconCls={'fas fa-cloud-upload-alt'} ui="action" handler={onMenuPubblicaClick} />
                            </SegmentedButton>
                            <SegmentedButton docked="left">
                                <ExtButton cls="ricercaAvanzataButton" itemId="btnTotRecordPO" align="left" margin="2 2 2 2" text={numRecordText}></ExtButton>
                                <ExtButton cls="ricercaAvanzataButton" itemId="btnSelRecordPO" align="left" margin="2 2 2 2" text={numRecordSelText}></ExtButton>
                                <ExtButton cls="ricercaAvanzataButton" itemId="btnTotErrRecordPO" align="left" margin="2 2 2 2" text={numRecordErrText}></ExtButton>
                            </SegmentedButton>
                        </Toolbar>
                        <Toolbar docked="top" margin="2 0 0 0" cls="lottoToolbar">


                            <SegmentedButton docked="right">
                                <ExtButton cls="marginButton ricercaAvanzataButton" align="right" text="Pulisci filtri" iconCls={'fa-solid fa-filter-circle-xmark'} ui="action" handler={handlePulisciFiltri} />
                                <ExtButton cls="marginButton appButton" align="right" width={160} text="Avvia ricerca" iconCls={'x-fa fa-search'} ui="action" handler={handleSubmitCerca} />
                            </SegmentedButton>
                            <Panel layout="hbox">
                                <ComboBoxField
                                    label="IF"
                                    labelWidth={20}
                                    labelCls='LabelRiepilogo'
                                    labelAlign="left"
                                    store={storeImprese}
                                    margin="0 10 0 0"
                                    displayField="nomCli"
                                    valueField="ideCli"
                                    queryMode="local"
                                    clearable
                                    forceSelection="true"
                                    itemId="cmbImpresa"
                                />
                                <ComboBoxField
                                    label="Tipo Traffico"
                                    labelWidth={80}
                                    width={200}
                                    labelCls='LabelRiepilogo'
                                    labelAlign="left"
                                    store={storeTipoTraffico}
                                    margin="0 10 0 0"
                                    displayField="desTipTra"
                                    valueField="ideTipTra"
                                    queryMode="local"
                                    clearable
                                    forceSelection="true"
                                    itemId="cmbTipoTraffico"
                                />
                                <ComboBoxField
                                    label="Stato RTO"
                                    labelCls='LabelRiepilogo'
                                    labelWidth={70}
                                    width={200}
                                    labelAlign="left"
                                    store={storeStatiRTO}
                                    margin="0 10 0 0"
                                    displayField="desStoRto"
                                    valueField="ideStoRto"
                                    queryMode="local"
                                    clearable
                                    forceSelection="true"
                                    itemId="cmbStatoRto"
                                />
                                <ComboBoxField
                                    label="Tipo Invio"
                                    labelCls='LabelRiepilogo'
                                    labelWidth={65}
                                    width={200}
                                    labelAlign="left"
                                    store={storeTipiInvio}
                                    displayField="etichetta"
                                    margin="0 10 0 0"
                                    valueField="valore"
                                    queryMode="local"
                                    clearable
                                    forceSelection="true"
                                    itemId="cmbTipoInvio"
                                />
                                <TextField
                                    label="Dossier"
                                    width={200}
                                    labelWidth={60}
                                    labelCls='LabelRiepilogo'
                                    margin="0 10 0 0"
                                    labelAlign="left"
                                    clearable
                                    itemId="txtDossier"
                                />
                            </Panel >
                        </Toolbar>
                        <Column itemId='azioniPubblica' text="Azioni" align="center" ignoreExport={true}
                            cell={{

                                tools: {

                                    apriRTO: {
                                        handler: onApriRTOClick.bind(this),
                                        bind: {
                                            iconCls: "x-fa fa-folder-open buttonGridTool",
                                            tooltip: 'Apri RTO'
                                        }

                                    }

                                }

                            }}

                        />
                        <Column align="center" height="40" text="N.Dossier" dataIndex="idE_DSI" filter='string' width={150} cell={{ cls: 'selectable' }} exportStyle={{ width: '200', alignment: { horizontal: 'Left' } }} />
                        <Column align="center" height="40" text="N.Treno" dataIndex="noM_TRE_IPR" filter='string' width={150} cell={{ cls: 'selectable' }} />
                        <Column align="center" height="40" text="IF" dataIndex="noM_CLI" filter='string' width={170} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                        <Column align="center" height="40" text="Protocollo IF" dataIndex="ptL_IPR" filter='string' width={150} cell={{ cls: 'selectable' }} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }}/>
                        <Column type="datecolumn" formatter='date("d/m/Y")' text="Data Protocollo IF" filter='date' dataIndex="daT_PTL_IPR" width={150} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                        <Column align="center" height="40" text="Protocollo RFI" dataIndex="ptL_RFI" filter='string' width={150} cell={{ cls: 'selectable' }} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                        <Column type="datecolumn" formatter='date("d/m/Y")' text="Data Protocollo RFI" filter='date' dataIndex="daT_PTL_RFI" width={150} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                        <Column type="datecolumn" formatter='date("d/m/Y")' text="Data versione" filter='date' dataIndex="daT_VES_RTO" width={150} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                        <Column align="center" height="40" text="Origine" dataIndex="noM_LOC_PRV" filter='string' width={170} exportStyle={{ width: '270', alignment: { horizontal: 'Left' } }} />
                        <Column align="center" height="40" text="Destinazione" dataIndex='noM_LOC_DSN' filter='string' width={170} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                        <Column align="center" height="40" text="Stato RTO" dataIndex='idE_STO_RTO' filter='string' width={150} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                        <Column type="datecolumn" formatter='date("d/m/Y")' text="Data Pubblicazione" filter='date' dataIndex="daT_TRC_PCS" width={150} exportStyle={{ width: '250', alignment: { horizontal: 'Left' } }} />
                        <Column align="center" height="40" text="PO modificato dopo invio" dataIndex='flG_UPD_TRC_PCS' filter='string' width={200} exportStyle={{ width: '300', alignment: { horizontal: 'Left' } }} />
                        <Column align="center" height="40" text="Stato Pubblicazione" dataIndex='msG_TRC_PCS' filter='string' width={200} exportStyle={{ width: '300', alignment: { horizontal: 'Left' } }} renderer={(value, metaData, record) => (
                            <div dangerouslySetInnerHTML={{ __html: value }} style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', userSelect: 'text' }} />
                        )} />
                    </Grid>
                    <Container margin='0 0 4 0' height={30} docked="bottom" layout="hbox" cls="title-text" >
                        <Progress flex={1} value={state.percentuale / 100.0} text={state.testo} />
                    </Container>
                </Container>
            </Panel>
            {showDialogDetailRTO.showDialogDetailRTO && <Dialog
                displayed={true}
                maximized={true}
            >
                <SchedaRto parRto={paramsDetail} hideDialogDetailRTO={hideDialogDetailRTO} />

            </Dialog>
            }
        </Container>
    );


}

export default PubblicazionePOPCS;

