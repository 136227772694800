import React from 'react';
import { Panel, Container, Button, Toolbar, Spacer, Label } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { Loadmask } from "@sencha/ext-react-modern";
import { CopiaRtoContext } from '../components/CopiaRtoContext';
import RtoRiepilogo from './RtoRiepilogoCopiaRto';
import { useUser } from '../components/UserContext';

const Ext = window['Ext'];
const storeOperazioniTecniche = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiaOperazioniTecniche',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeLocalita = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiLocalita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storePeriodoRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiePeriodicita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeTipoVCO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/GetElencoVersioniVCO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeCatTreniIF = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiCategorieTreno',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeCommittenti = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiAnagraficaCommittente',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeTipoTraccia = Ext.create('Ext.data.Store', {
    //model: TipologieTracciaModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologieTraccia',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeTipologieRTO = Ext.create('Ext.data.Store', {
    //model: tipologieRTOModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologieRTO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeTipoTraffico = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologieTraffico',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeAccordiQuadro = Ext.create('Ext.data.Store', {
    //model: tipologieRTOModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiGestioneAQ',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeLotti = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Lotti/LeggiLotti',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeInfoCatalogo = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiCatalogoTRC',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const storeSchedaRto = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Rto/LeggiRTO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

let storeLoaded = 0;
let storeToLoad = 0;

function RtoCopia(props) {

    const [stateLoadMask, setStateLoadMask] = useState({ hidden: false, message: "Attendere caricamento Anagrafiche..." })
    const { user, switchUser } = useUser();
    const [anagrafiche, setAnagrafiche] = useState({ loaded: false, localita: storeLocalita, operazioniTecniche: storeOperazioniTecniche, periodoRTO: storePeriodoRTO, periodoFerroviario: storePeriodiFerroviari, tipoVco: storeTipoVCO, categorieTreniIF: storeCatTreniIF, committenti: storeCommittenti, tipoTraccia: storeTipoTraccia, tipologieRTO: storeTipologieRTO, tipoTraffico: storeTipoTraffico, accordiQuadro: storeAccordiQuadro, lotti: storeLotti, schedaRto : storeSchedaRto });
    const updateAnagrafiche = () => {
        anagrafiche.periodoFerroviario = storePeriodiFerroviari;
        anagrafiche.periodoRTO = storePeriodoRTO;
        anagrafiche.localita = storeLocalita;
        anagrafiche.operazioniTecniche = storeOperazioniTecniche;
        anagrafiche.tipoVco = storeTipoVCO;
        anagrafiche.categorieTreniIF = storeCatTreniIF;
        anagrafiche.committenti = storeCommittenti;
        anagrafiche.tipoTraccia = storeTipoTraccia;
        anagrafiche.tipologieRTO = storeTipologieRTO;
        anagrafiche.tipoTraffico = storeTipoTraffico;
        anagrafiche.accordiQuadro = storeAccordiQuadro;
        anagrafiche.lotti = storeLotti;
        anagrafiche.schedaRto = storeSchedaRto;
        anagrafiche.loaded = true;
        setAnagrafiche({ ...anagrafiche });
        setStateLoadMask({ hidden: true, message: "Attendere caricamento Anagrafiche..." });
    }

    const loadStorePeriodiFerroviari = () => {
        storePeriodiFerroviari.clearData();
        storePeriodiFerroviari.removeAll();
        storePeriodiFerroviari.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStorePeriodoRTO = () => {
        storePeriodoRTO.clearData();
        storePeriodoRTO.removeAll();
        storePeriodoRTO.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreTipoVCO = () => {
        storeTipoVCO.clearData();
        storeTipoVCO.removeAll();
        storeTipoVCO.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreCommittenti = () => {
        storeCommittenti.clearData();
        storeCommittenti.removeAll();
        storeCommittenti.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreTipoTraccia = () => {
        storeTipoTraccia.clearData();
        storeTipoTraccia.removeAll();
        storeTipoTraccia.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreAccordiQuadro = () => {
        storeAccordiQuadro.clearData();
        storeAccordiQuadro.removeAll();
        storeAccordiQuadro.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreTipologieRTO = () => {
        storeTipologieRTO.clearData();
        storeTipologieRTO.removeAll();
        storeTipologieRTO.load({
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreLocalita = (ideUte) => {
        storeLocalita.clearData();
        storeLocalita.removeAll();
        storeLocalita.load({
            params: { ideUte: ideUte },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreInfoCatalogo = (ideAnnFro) => {
        storeInfoCatalogo.clearData();
        storeInfoCatalogo.removeAll();
        storeInfoCatalogo.load({
            params: { ideAnno: ideAnnFro },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreCatTreniIF = (ideCli) => {
        storeCatTreniIF.clearData();
        storeCatTreniIF.removeAll();
        storeCatTreniIF.load({
            params: { ideCli: ideCli },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreTipoTraffico = (ideCli) => {
        storeTipoTraffico.clearData();
        storeTipoTraffico.removeAll();
        storeTipoTraffico.load({
            params: { ideCli: ideCli },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreLotti = (ideUte, ideCli, ideAnnFro) => {
        storeLotti.clearData();
        storeLotti.removeAll();
        storeLotti.load({
            params: {
                ideUte: ideUte,
                ideCli: ideCli,
                ideAnnFro: ideAnnFro
            },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    const loadStoreSchedaRto = (ideRto, ideTipGp) => {
        storeSchedaRto.clearData();
        storeSchedaRto.removeAll();
        storeSchedaRto.load({
            params: {
                ideRTO: ideRto,
                ideTipGpr: ideTipGp
            },
            callback: function () {
                storeLoaded++;
                if (storeLoaded >= storeToLoad)
                    updateAnagrafiche();
            }
        });
    };
    function caricaAnagrafiche(ideUte, ideAnnFro, ideCli) {

        storeToLoad = 13;
        storeLoaded = 0;

        loadStorePeriodiFerroviari();
        loadStorePeriodoRTO();
        loadStoreTipoVCO();
        loadStoreCommittenti();
        loadStoreTipoTraccia();
        loadStoreTipologieRTO();
        loadStoreAccordiQuadro();
        loadStoreLocalita(ideUte);
        loadStoreInfoCatalogo(ideAnnFro);
        loadStoreCatTreniIF(ideCli);
        loadStoreTipoTraffico(ideCli);
        loadStoreLotti(ideUte, ideCli, ideAnnFro);
        loadStoreSchedaRto(props.ideRto, props.ideTipGrp);
    };

    useEffect(() => {
        setStateLoadMask({ hidden: false, message: "Attendere caricamento Anagrafiche..." })
        anagrafiche.loaded = false;

        caricaAnagrafiche(user.ideUte, Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value, user.ideCli, props.ideRTO, props.ideTipGpr);

    }, [])

    return (

        <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
            <CopiaRtoContext.Provider value={
                {
                    storeLocalita: anagrafiche.localita,
                    storeOperazioniTecniche: anagrafiche.operazioniTecniche,
                    storePeriodoRTO: anagrafiche.periodoRTO,
                    storePeriodiFerroviari: anagrafiche.periodoFerroviario,
                    storeTipoVCO: anagrafiche.tipoVco,
                    storeCatTreniIF: anagrafiche.categorieTreniIF,
                    storeCommittenti: anagrafiche.committenti,
                    storeTipoTraccia: anagrafiche.tipoTraccia,
                    storeTipologieRTO: anagrafiche.tipologieRTO,
                    storeTipoTraffico: anagrafiche.tipoTraffico,
                    storeAccordiQuadro: anagrafiche.accordiQuadro,
                    storeLotti: anagrafiche.lotti,
                    storeSchedaRto: anagrafiche.schedaRto
                }
            }>

                <Panel scrollable flex={1} layout="vbox">

                    {anagrafiche.loaded &&
                        <Panel layout="vbox" flex={1} bodyPadding={2}>

                            <RtoRiepilogo RefreshRtoDaNuova={props.RefreshRtoDaNuova} />

                        </Panel>
                    }
                </Panel>

            </CopiaRtoContext.Provider>

        </Container>
    );
}

export default RtoCopia;