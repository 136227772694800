import { useState, React, useEffect } from 'react';
import { useUser } from '../components/UserContext';
import { Container, Panel, Titlebar, Label, Grid, Button, TextField,SegmentedButton, Column, Combobox, DatePickerField, Toolbar } from '@sencha/ext-react-modern';
import periodoFerroviarioAttivoModel from '../models/PeriodoFerroviarioAttivoModel.js';

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.Exporter'

]);
//---------PERIODO -----------------------------------
const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    model: periodoFerroviarioAttivoModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviariAttivi',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const caricaPeriodi = () => {
    storePeriodiFerroviari.load({

    })
}
caricaPeriodi();

const setBtnTotRecord = (numRecord) => {
    Ext.ComponentQuery.query('#btnTotRecordMerci')[0].setText("Totale righe: " + numRecord);
}

//--------------------ORIGINE--------------------------------------
const storeOrigine = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Merci/GetCatalogoLocalitaOrigine',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//--------------------DESTINO--------------------------------------
const storeDestino = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Merci/GetCatalogoLocalitaDestino',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//----------ELENCO TABBELLA MERCI------------------------------------------------
const storeRiepilogoMerci = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Merci/GetCatalogoRiepilogo',
        reader: {
            type: 'json'
        }
    },
        autoload: false
});
//--------TABELLA DETTAGLI-------------------------------

const storeDettaglioMerci = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Merci/GetCatalogoDettaglio',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});


var periodoFerroviario = null;


function CatalogoMerci() {

    let title = " Catalogo Merci";
    let ui = 'action';
    let numRecordText = "Totale : "

    const { user, switchUser } = useUser();
    //const [paramsFiltro, setParamsFiltro] = useState({ ideAnnFro: filtro.ideAnnFro, ideDataProv: filtro.ideDataProv, ideOrigine: filtro.ideOrigine, ideDestino: filtro.ideDestino }); //per gestione con i filtri in alto
    const [showDettaglio, setShowDettaglio] = useState(false);

    const [state, setState] = useState({ testoAggiornamento: "", testoDettaglio:"" });
  
    const caricaOrigine = () => {
        storeOrigine.load({
            params: { data: Ext.ComponentQuery.query('#dPickDataProv')[0].getValue() },
            callback: (records, operation, success) => {
                if (success) {
                   // console.log("success carica origine " + records);
                } else {
                    console.error("Errore durante il caricamento dei dati");
                }
            }
        })
    }
    const caricaDestino = () => {      
        storeDestino.load({
            params: { data: Ext.ComponentQuery.query('#dPickDataProv')[0].getValue() },
            callback: (records, operation, success) => {
                if (success) {
                    //console.log("success carica destino " + records);
                } else {
                    console.error("Errore durante il caricamento dei dati");
                }
            }
        })
    }

    useEffect(() => {
        let td = new Date();
        if (periodoFerroviario.datIniVal.getTime() < td.getTime()) {
            Ext.ComponentQuery.query('#dPickDataProv')[0].setValue(td);
        }
        else {
            Ext.ComponentQuery.query('#dPickDataProv')[0].setValue(periodoFerroviario.datIniVal);
        }
    }, []); // <-- empty array means 'run once'

    const onChangeDataPeriodo = (e) => {       
            caricaOrigine();
            caricaDestino();        
    }

    const caricaTestoAggiornamento = () => {
        Ext.Ajax.request({
            url: 'Merci/GetCatalogoInfo',
            method: 'GET',
            params: {
                data: Ext.ComponentQuery.query('#dPickDataProv')[0].getValue(),
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                state.testoAggiornamento = '<p id="title-panel" >' + returnObj + '</p>';
                setState({ ...state });
            }
        });
    }

    const caricaTestoDettaglio = () => {
        var p = Ext.ComponentQuery.query('#cmbPrdFerr')[0].getValue(null);
        Ext.Ajax.request({
            url: 'Merci/GetMessaggioCatalogoMerci',
            method: 'GET',
            params: {
                ideAnnFro: p               
            },
            callback: function (records, success, operation) {

                var returnObj = Ext.decode(operation.responseText);

                state.testoDettaglio = returnObj;
                setState({ ...state });
            }
        });
    }

 //--------------CONFERMA/AVVIA RICERCA --------------------------
    const onConfermaClick = () => {
        setBtnTotRecord(0);
        storeRiepilogoMerci.load({
            params: {
                data: Ext.ComponentQuery.query('#dPickDataProv')[0].getValue(),
                codLocalitaOrigine: Ext.ComponentQuery.query('#cmbOrigin')[0].getSelection() != null ? parseInt(Ext.ComponentQuery.query('#cmbOrigin')[0].getSelection().data.valore) : -1,
                codLocalitaDestino: Ext.ComponentQuery.query('#cmbDestino')[0].getSelection() != null ? parseInt(Ext.ComponentQuery.query('#cmbDestino')[0].getSelection().data.valore) : -1
            },
            callback: function (records, operation, success) {
                setBtnTotRecord(records.length);
                caricaTestoAggiornamento();
                caricaTestoDettaglio();
                setShowDettaglio(false);
            }
        });      
        
    }
//--------------PULISCI FILTRI---------------------------------
    const handlePulisciFiltri = (btn) => {
        pulisciFiltri();
    };
    const pulisciFiltri = () => {
        setBtnTotRecord(0);
        let td = new Date();
        let dp = Ext.ComponentQuery.query('#dPickDataProv')[0];
        if (dp != undefined) { 
            if (periodoFerroviario.datIniVal.getTime() < td.getTime()) {
                dp.setValue(td);
            }
            else {
                dp.setValue(periodoFerroviario.datIniVal);
            }
        }

        if (Ext.ComponentQuery.query('#cmbOrigin')[0] != undefined) {
            Ext.ComponentQuery.query('#cmbOrigin')[0].setValue("");
        }
        if (Ext.ComponentQuery.query('#cmbDestino')[0] != undefined) {
            Ext.ComponentQuery.query('#cmbDestino')[0].setValue("");
        }

        storeRiepilogoMerci.clearData();
        storeRiepilogoMerci.removeAll();
        storeDettaglioMerci.clearData();
        storeDettaglioMerci.removeAll();

        state.testoAggiornamento = "";
        state.testoDettaglio = "";
        setState({ ...state });
        if (Ext.ComponentQuery.query('#gridRiepilogo')[0] !== undefined) {
            var grid = Ext.ComponentQuery.query('#gridRiepilogo')[0];
            grid.refresh();
        }
        setShowDettaglio(false);
    };
//-------------SELEZIONE FILTRI---------------------------------------------------
   function onSelectPeriodo(a) {                                                                   

       periodoFerroviario = a.newValue.data;

       // Se cambia il periodo ferroviario bisogna ripulire i filtri, gli store e aspettare una nuova selezione confermata dal bottone di avvio ricerca
       pulisciFiltri();
    }

//------------------DETTAGLIO---------------------------------------------
    function onGridSelect(e) {
        var grid = Ext.ComponentQuery.query('#gridRiepilogo')[0];
        var gridSelection = grid.getSelections();
        let lng = gridSelection.length;
        if (lng === 1) {
            // Carica store dettaglio
            storeDettaglioMerci.load({
                params: {
                    data: Ext.ComponentQuery.query('#dPickDataProv')[0].getValue(),
                    codLocalitaOrigine: e.selected.data.codlocorigine,
                    codLocalitaDestino: e.selected.data.codlocdestino
                },
                callback: function () {
                    //console.log("Dettaglio Merci data:" + Ext.ComponentQuery.query('#dPickDataProv')[0].getValue());
                    //console.log("Dettaglio Merci origine:" + e.selected.data.codlocorigine);
                    //console.log("Dettaglio Merci destino:" + e.selected.data.codlocdestino);
                }
            });
            // Attiva visualizzazione
            setShowDettaglio(true);
        }
        else {
            setShowDettaglio(false);
        }

    }
    //function onInfoDettagliClick(grid, info) {
    //    Ext.toast({ message: 'Visualizza Dettagli', timeout: 4000 });

    //        storeDettaglioMerci.load({
    //            params: { ideUte: user.ideUte },
    //            callback: function () {
    //                aggiornaState();
    //            }
    //        });

    //}


    //--------------DOWNLOAD GRID----------------------
    function onDownloadRiepilogoClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridRiepilogo')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title + ' Riepilogo',
            fileName: title + ' Riepilogo.xlsx'
        });
    }
    function onDownloadDettaglioClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridDettaglio')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title + ' Dettaglio',
            fileName: title + ' Dettaglio.xlsx'
        });
    }

  return (
      <Container height="100%" width="100%"  margin='3 0 0 0' layout="vbox" scrollable="true">

          <Titlebar title={title} margin='0 0 5 0' docked="top" layout="hbox" cls="title-text" flex={1}>
              <Button align="left" cls="title-icon" iconCls="x-fa fa-boxes"  />            
              
              <Combobox align="right" width={170} margin='0 4 0 0' editable="false" valueField="ideAnnFro" autoSelect="true"
                  itemId="cmbPrdFerr"
                  displayField="desAnnFro"
                  queryMode="local"
                  store={storePeriodiFerroviari}
                  onSelect={onSelectPeriodo}
              />                                        
         
          </Titlebar>         

          <Toolbar docked="top" layout="hbox" height={45} width="100%" margin="10 0 0 10" flex={1}>   
              
                  <DatePickerField label="Data Provvedimento" width={260} height={27} labelWidth={140} margin="0 15 0 0" docked="left"  picker={{ yearFrom: 1990 }}
                      labelAlign="left"
                      labelCls='LabelRiepilogo'
                      itemId="dPickDataProv"
                      queryMode="local"
                      required
                      onChange={onChangeDataPeriodo}                     
                  />

              <Combobox label="Origine" width={290} height={27} docked="left" margin="0 15 0 0" editable="false" valueField="valore"  autoSelect="true"
                      itemId="cmbOrigin"
                      labelAlign="left"
                      labelCls='LabelRiepilogo'
                      displayField="etichetta"
                      valueField="ideOrigine"
                      queryMode="local"
                      store={storeOrigine}                  
                      
                  />


              <Combobox label="Destino" width={290} height={27} docked="left" margin="0 15 0 0" editable="false" autoSelect="true" valueField="valore"  
                      labelAlign="left"
                      labelCls='LabelRiepilogo'
                      itemId="cmbDestino"
                      displayField="etichetta"
                      valueField="ideDestino"
                      queryMode="local"
                      store={storeDestino}
                     
                  />

              <SegmentedButton docked="right" layout="hbox" width={380} margin="0 10 0 0" flex={0.3}>
                   <Button cls="marginButton appButton" margin="0 10 0 0" height={55} width={130} text="Avvia Ricerca" iconCls={'x-fa fa-search'} ui="action" handler={onConfermaClick} />
                  <Button cls="marginButton ricercaAvanzataButton" margin="0 10 0 0" height={55} width={130} text="Pulisci Filtri" iconCls={'fa-solid fa-filter-circle-xmark'} ui="action" handler={handlePulisciFiltri} />
                  <Button cls="marginButton exportButton" text="Esporta" width={100} ui={ui} handler={onDownloadRiepilogoClick} iconCls="x-fa fa-table" />
              </SegmentedButton>
          </Toolbar>
          <Panel width="100%" height="25px" layout="hbox">
              <SegmentedButton margin="0 0 0 10" docked="left">
                  <Button itemId="btnTotRecordMerci" align="left" margin="2 2 2 2" text={numRecordText}></Button>
              </SegmentedButton>
              <Label margin="-11 0 0 10" html={state.testoAggiornamento} />
          </Panel>


          <Grid flex={1} minHeight="276" scrollable cls="custom-grid" itemId="gridRiepilogo" itemConfig={{ viewModel: true }}         
              plugins={['gridfilters', 'gridexporter']}
              selectable={{ checkbox: false, mode: 'single' }}
              title="Riepilogo"
              store={storeRiepilogoMerci}    
              onSelect={onGridSelect}
              onDeselect={onGridSelect}
              loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'                
          >
              {/*<Toolbar docked="top" cls="actionButtonPanel">*/}

              {/*</Toolbar>*/}

              {/*<Column height="40" width={250} text="Destino Relazione" dataIndex="desLtx" filter='string' autoSizeColumn="true" cell={{*/}
              {/*    tools: {*/}
              {/*        details: {*/}
              {/*            handler: onInfoDettagliClick.bind(this),*/}
              {/*            bind: {*/}
              {/*                iconCls: "fa-info-circle buttonGridTool",*/}
              {/*                tooltip: 'dettagli'*/}
              {/*            }*/}
              {/*        }*/}
              {/*    }*/}

              {/*}} />*/}

             

              <Column height="40" width={200} text="Origine Elaborazione" dataIndex="dsclocorigine" filter='string' flex={1} />
              <Column height="40" width={250} text="Destino Relazione" dataIndex="dsclocdestino" filter='string' autoSizeColumn="true" flex={1} />
              <Column height="40" width={170} text="Km Medi Relazione" dataIndex="kmmedi" filter='string' />
              <Column height="40" width={110} text="Tempo Medio" dataIndex="tempomedio" type="datecolumn" filter='string' flex={1} />
              <Column height="40" width={130} text="Vel.Com." dataIndex="vcom" filter='string' />
              <Column height="40" width={250} text="Ped.Medio Relazione" dataIndex='pedaggiomedio' filter='string' flex={1} />

              <Column height="40" width={80} text="GG/Set" dataIndex='giornisettimana' filter='string' />
              <Column height="40" width={60} text="Lun" dataIndex='lun' filter='string'/>
              <Column height="40" width={60} text="Mar" dataIndex='mar' filter='string'/>
              <Column height="40" width={60} text="Mer" dataIndex='mer' filter='string'/>
              <Column height="40" width={60} text="Gio" dataIndex='gio' filter='string'/>
              <Column height="40" width={60} text="Ven" dataIndex='ven' filter='string'/>
              <Column height="40" width={60} text="Sab" dataIndex='sab' filter='string'/>
              <Column height="40" width={60} text="Dom" dataIndex='dom' filter='string'/>
                    
          </Grid>

          {showDettaglio && <Container>
              <Panel title="Dettaglio" closable={true} onDestroy={() => {
                  setShowDettaglio(false)
              }} margin='0 0 10 0' docked="top" layout="hbox" cls="title-text" flex={1}></Panel>

              <Toolbar docked="top" layout="hbox" width="100%" height={30} margin="0 10 10 0" flex={0.3}>
                  <Button docked="right" cls="marginButton exportButton" text="Esporta" width={ 100} ui={ui} handler={onDownloadDettaglioClick} iconCls="x-fa fa-table" />
              </Toolbar>

              <Container>
                  <Label html={state.testoDettaglio}  margin="0 0 10 10"  autosize cls="title-panel" />

              </Container>

              <Grid height="200" scrollable cls="custom-grid" itemId="gridDettaglio" itemConfig={{ viewModel: true }}
                  selectable={{ checkbox: false, mode: 'single' }}
                  store={storeDettaglioMerci}
                  plugins={['gridfilters', 'gridexporter']}
                  loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
              >
                  <Column height="40" width={160} text="Master" dataIndex="master" filter='string' flex={1} />
                  <Column height="40" width={250} text="Origine Relazione" dataIndex="dsclocorigine" filter='string' autoSizeColumn="true" flex={1} />
                  <Column height="40" width={100} text="Ora Partenza" dataIndex="orapartenza" filter='string' />
                  <Column height="40" width={250} text="Destino Relazione" dataIndex="dsclocdestino" filter='string' flex={1} />
                  <Column height="40" width={100} text="Ora Arrivo" dataIndex="oraarrivo" filter='string' />
                  <Column height="40" width={100} text="Km" dataIndex='km' filter='string' />
                  <Column height="40" width={100} text="Tempo" dataIndex="tempo"   filter='string' />
                  <Column height="40" width={120} text="Vel.Comm" dataIndex="vcom" filter='string' />
                  <Column height="40" width={100} text="Pedaggio" dataIndex='pedaggio' filter='string' />

                  <Column height="40" width={80} text="GG/Anno" dataIndex='giornitotali' filter='string' />
                  <Column height="40" width={60} text="Lun" dataIndex='lun' filter='string'/>
                  <Column height="40" width={60} text="Mar" dataIndex='mar' filter='string'/>
                  <Column height="40" width={60} text="Mer" dataIndex='mer' filter='string' />
                  <Column height="40" width={60} text="Gio" dataIndex='gio' filter='string'/>
                  <Column height="40" width={60} text="Ven" dataIndex='ven' filter='string'/>
                  <Column height="40" width={60} text="Sab" dataIndex='sab' filter='string'/>
                  <Column height="40" width={60} text="Dom" dataIndex='dom' filter='string'/>

              </Grid>
          </Container>}

      </Container>
   );

  
}

export default CatalogoMerci