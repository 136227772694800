import React, { useEffect, useState } from "react";
import { Container, Panel, Titlebar, Grid, Column, Button, Toolbar, SegmentedButton, Combobox, DatePickerField, Label } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
]);
//  -----------PERIODI FERROVIARI ----------------------------------------------
const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//-------------PERIODI RTO----------------------------
const storePeriodoRTO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiePeriodicita',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//-----------TIPO REPORT----------------------------------
const storeTipoReport = Ext.create('Ext.data.Store', {
    sorters: [{
        property: 'ideTipRpo',
        direction: 'ASC'
    }],
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'AnalisiRto/LeggiTipiReport',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//--------------ELENCO REPORT-------------------------------
const storeDownloadReport = Ext.create('Ext.data.Store', {
    proxy: {
        url: 'AnalisiRto/LeggiElencoReport',
        method: 'GET',
        type: 'ajax',
        reader: {
            type: 'json'
        },
    },
    autoload: false,

});
//-------------------------

// -------------- Tipo Traffico Prevalente -----------------
const storeTipoTrafficoPrevalente = Ext.create('Ext.data.Store', {   //to do: inserire logica filtro
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiAnagraficaTipoTrafficoPrevalenteAll',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//-------------------------

// -------------- Committente --------------------------
const storeCommittenti = Ext.create('Ext.data.Store', {
    sorters: [{
        property: 'desCommittente',
        direction: 'ASC'
    }],
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiAnagraficaCommittente',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//-----------------------------------------------------

// -------------- Impresa Ferroviaria  -----------------
const storeIF = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiListImpresaFerroviaria',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//------------------------------------------------------

function DownloadReport() {

    let title = "Download Report";
    const { user } = useUser();
    const { ambiente, updateAmbiente, inizializzaAmbiente } = useAmbiente();
    const { periodi } = usePeriodi();

    storePeriodiFerroviari.setData(periodi.periodiFerroviari);
    storePeriodoRTO.setData(periodi.periodoRTO);

    const [showSelezionaDataReport, setShowSelezionaDataReport] = useState({ filtroTrafficoPrev: false, filtroCommittente: false, filtroImpresa: false, filtroDate: false });

    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });
   
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;   

    //const caricaPeriodiFerroviari = () => {
    //    storePeriodiFerroviari.load({
    //    })
    //}
       
    //const caricaPeriodoRTO = () => {
    //    storePeriodoRTO.load({
    //    })
    //}
    const caricaTipoReport = () => {
        storeTipoReport.load({
            params: {
                ideUte: user.ideUte
            }
        });
    }

    const caricaTipoTrafficoPrevalente = () => {
        storeTipoTrafficoPrevalente.load({
        })
    }
    const caricaAnagraficaCommittenti = () => {       
        storeCommittenti.load({    
            params: {
                codCliRfi: null
            }            
        });
    };
    const caricaIF = () => {
        storeIF.load({
            params: {
                IdeOpaRfi: user.idOperatoreRFI
            }
        });
    }

    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF && ambiente.ideAnnFro != "") {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value = ambiente.ideAnnFro;
            if (parseInt(ambiente.ideTipPer) >= 0) {
                Ext.ComponentQuery.query('#comboPeriodoRto')[0]._value = ambiente.ideTipPer;
            }
            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });
        }
    }

    const aggiornaStateAmbiente = () => {
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        stateAmbiente.ideTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : -1;
        setStateAmbiente({ ...stateAmbiente });        
    }

    useEffect(() => {
        //caricaPeriodiFerroviari();
        //caricaPeriodoRTO();
        caricaTipoReport();
        caricaTipoTrafficoPrevalente();
        caricaAnagraficaCommittenti();
        caricaIF();

    }, []);

    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);

    

    //--------------PULISCI FILTRI------------------------------------
    const handlePulisciFiltri = (btn) => {        
        PulisciFiltro();
    };

    const PulisciFiltro = () => {
        if (Ext.ComponentQuery.query('#dPickDataDa')[0] != undefined)
            Ext.ComponentQuery.query('#dPickDataDa')[0].setValue(null);
        if (Ext.ComponentQuery.query('#dPickDataA')[0] != undefined)
            Ext.ComponentQuery.query('#dPickDataA')[0].setValue(null);

        if (Ext.ComponentQuery.query('#comboTrafficoPrev')[0] != undefined)
            Ext.ComponentQuery.query('#comboTrafficoPrev')[0].setValue(null);

        if (Ext.ComponentQuery.query('#comboCommittente')[0] != undefined)
            Ext.ComponentQuery.query('#comboCommittente')[0].setValue(null);

        if (Ext.ComponentQuery.query('#comboIF')[0] != undefined)
            Ext.ComponentQuery.query('#comboIF')[0].setValue(null);

        Ext.ComponentQuery.query('#comboTipoReport')[0].setValue(null);
        showSelezionaDataReport.filtroDate = false;
        showSelezionaDataReport.filtroTrafficoPrev = false;
        showSelezionaDataReport.filtroImpresa = false;
        showSelezionaDataReport.filtroCommittente = false;
        setShowSelezionaDataReport({ ...showSelezionaDataReport })

        // svuota lo store dell'elenco report eventualmente caricari con la precedente selezione'
        storeDownloadReport.clearData();
        storeDownloadReport.removeAll();

    }

    //------PERIODO FERROVIARIO----
    function onSelectPeriodoFerroviario(e) {      

        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(                  
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente DownloadReport SPF ideAnnFro = ', e.newValue.data.ideAnnFro);
                            //console.log('sono in updateAmbiente DownloadReport SPF ideTipPer = ', valTipPer);
                        }
                    );
                    InitPeriodoRicerca(e.newValue.data.ideAnnFro);
                }
            }
        }
        //----------------------------------------------------------------
        
    }

    //------TIPO REPORT------------------------------------
    function onSelectTipoReport(e) {

        // svuota lo store dell'elenco report eventualmente caricari con la precedente selezione'
        storeDownloadReport.clearData();
        storeDownloadReport.removeAll();

        let ideTipoReport = e.newValue.data.ideTipRpo;        
            
        switch (ideTipoReport) {
            case 7:
                showSelezionaDataReport.filtroDate = true;
                showSelezionaDataReport.filtroTrafficoPrev = true;
                showSelezionaDataReport.filtroImpresa = false;
                showSelezionaDataReport.filtroCommittente = false;
                break;
            case 10:
                showSelezionaDataReport.filtroDate = false;
                showSelezionaDataReport.filtroTrafficoPrev = true;
                showSelezionaDataReport.filtroImpresa = true;
                showSelezionaDataReport.filtroCommittente = false;
                break;
            case 11:
                showSelezionaDataReport.filtroDate = true;
                showSelezionaDataReport.filtroTrafficoPrev = false;
                showSelezionaDataReport.filtroImpresa = isIF ? false : true;
                showSelezionaDataReport.filtroCommittente = true;
                break;
            default:
                showSelezionaDataReport.filtroDate = false;
                showSelezionaDataReport.filtroTrafficoPrev = false;
                showSelezionaDataReport.filtroImpresa = false;
                showSelezionaDataReport.filtroCommittente = false;
        }
        setShowSelezionaDataReport({ ...showSelezionaDataReport })

        // inizializza periodo di ricerca
        let newIdeAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value;
        InitPeriodoRicerca(newIdeAnnFro);
    }

    function onClearTipoReport() {
        PulisciFiltro();        
    }

    function InitPeriodoRicerca(newIdeAnnFro) {
        if (showSelezionaDataReport.filtroDate) {
            let rangeDateStart = new Date(storePeriodiFerroviari.findRecord("ideAnnFro", newIdeAnnFro, 0, false, false, true).data.datIniVal);
            let rangeDateEnd = new Date(storePeriodiFerroviari.findRecord("ideAnnFro", newIdeAnnFro, 0, false, false, true).data.datFinVal);

            if (Ext.ComponentQuery.query('#dPickDataDa')[0] != undefined)
                Ext.ComponentQuery.query('#dPickDataDa')[0].setValue(rangeDateStart);
            if (Ext.ComponentQuery.query('#dPickDataA')[0] != undefined)
                Ext.ComponentQuery.query('#dPickDataA')[0].setValue(rangeDateEnd);
        }
        else {
            if (Ext.ComponentQuery.query('#dPickDataDa')[0] != undefined)
                Ext.ComponentQuery.query('#dPickDataDa')[0].setValue(null);
            if (Ext.ComponentQuery.query('#dPickDataA')[0] != undefined)
                Ext.ComponentQuery.query('#dPickDataA')[0].setValue(null);
        }
    }

    //---------------FILTRO PERIODO RTO------------------------
    function onSelectPeriodoRto(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo rto
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoRto')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoRto')[0] != null) {
                let valTipPer = parseInt(Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboPeriodoRto')[0].getValue() : null;
                if (valTipPer != stateAmbiente.ideTipPer) {
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente DownloadReport SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente DownloadReport SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
        //----------------------------------------------------------------
    }

    function onClearPeriodoRto(e) {       
        updateAmbiente({ ideUte: user.ideUte, ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), ideTipPer: -1 }).then(
            function (result) {
                aggiornaStateAmbiente();
                //console.log('sono in updateAmbiente DownloadReport SPF ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                //console.log('sono in updateAmbiente DownloadReport SPF ideTipPer = ', -1);               
            }
        );
    }

    //--------------CONFERMA/AVVIA RICERCA --------------------------
    const onConfermaClick = (x) => {
        let ideTipRpo = Ext.ComponentQuery.query('#comboTipoReport')[0]._value;
        if (ideTipRpo == null || ideTipRpo === -1) {
            Ext.Msg.alert('Attenzione','Selezionare Tipo Report');            
        }
        else
        {
            caricaDownloadReport();
        }
    }

    const caricaDownloadReport = () => {       
        let par = {
            ideUte: user.ideUte,
            ideTipRpo: Ext.ComponentQuery.query('#comboTipoReport')[0]._value,
            ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value,
            ideTipPer: Ext.ComponentQuery.query('#comboPeriodoRto')[0]._value,            
            TipTrfPrv: Ext.ComponentQuery.query('#comboTrafficoPrev')[0] == undefined ? null : Ext.ComponentQuery.query('#comboTrafficoPrev')[0]._value,
            Committente: Ext.ComponentQuery.query('#comboCommittente')[0] == undefined ? null : Ext.ComponentQuery.query('#comboCommittente')[0]._value, 
            TipIf: Ext.ComponentQuery.query('#comboIF')[0] == undefined ? null : Ext.ComponentQuery.query('#comboIF')[0]._value,                      
            DataIni: showSelezionaDataReport.filtroDate ? Ext.ComponentQuery.query('#dPickDataDa')[0]._value : null,
            DataFin: showSelezionaDataReport.filtroDate ? Ext.ComponentQuery.query('#dPickDataA')[0]._value : null
        }
        storeDownloadReport.load({
            params: par            
        });
    };

    //-----------ELABORA REPORT----------------
    const onElaboraClick = (p) => {
        let ideTipPer = Ext.ComponentQuery.query('#comboPeriodoRto')[0]._value;
        if (ideTipPer != null && ideTipPer >= 0) {
            onNewReportClick();
        } else {
            Ext.Msg.alert('Attenzione', 'Selezionare il Periodo RTO');
        }
    }

    function onNewReportClick() {
        let par = {
            ideUte: user.ideUte,
            ideTipRpo: Ext.ComponentQuery.query('#comboTipoReport')[0]._value,
            ideAnnFro: Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value,
            ideTipPer: Ext.ComponentQuery.query('#comboPeriodoRto')[0]._value,
            TipTrfPrv: Ext.ComponentQuery.query('#comboTrafficoPrev')[0] == undefined ? null : Ext.ComponentQuery.query('#comboTrafficoPrev')[0]._value,
            Committente: Ext.ComponentQuery.query('#comboCommittente')[0] == undefined ? null : Ext.ComponentQuery.query('#comboCommittente')[0]._value,
            TipIf: Ext.ComponentQuery.query('#comboIF')[0] == undefined ? null : Ext.ComponentQuery.query('#comboIF')[0]._value,
            DataIni: showSelezionaDataReport.filtroDate ? Ext.ComponentQuery.query('#dPickDataDa')[0]._value : null,
            DataFin: showSelezionaDataReport.filtroDate ? Ext.ComponentQuery.query('#dPickDataA')[0]._value : null
        }
        Ext.Ajax.request({
            url: 'AnalisiRto/InserisciReportInCoda',
            method: 'POST',
            params: par,
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);
                    //modifica gestione errore
                    if (returnObj.resp === "KO") {
                        Ext.Msg.alert('Richiesta Elaborazione Report Fallita', returnObj.msgErr)
                    } else {
                        Ext.toast({ message: 'Richiesta Report Inserita', timeout: 4000 });
                        caricaDownloadReport();
                    }
                } else {
                    Ext.Msg.alert('Operazione Fallita', 'Richiesta Elaborazione Report Fallita');
                }
            }
        });
    }

    //------------------------ELIMINA REPORT-----------------------------------------------------

    function onDeleteClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del File?", function (button) {

            if (button === 'yes') {
                Ext.Ajax.request({
                    url: 'AnalisiRto/EliminaReport',
                    method: 'POST',
                    params: {
                        ideFieRpo: info.record.data.ideFieRpo
                        },
                    callback: function (records, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            //modifica gestione errore
                            if (returnObj.resp === "KO") {
                                Ext.Msg.alert('Eliminazione Report Fallita', returnObj.msgErr)
                            }
                            else {
                                Ext.toast({ message: 'Report Eliminato!', timeout: 4000 });
                                caricaDownloadReport();
                            }
                        }
                        else {
                            Ext.Msg.alert('Operazione Fallita', 'Elimina Report Fallito');
                        }
                    }
                });

            }
        })

    }

    //-----------------------SCARICA FILE REPORT---------------------------------------------

    const onDownloadFileReport = (grid, info) => {
        let flagDwnld = info.record.data.flagDwnld;
        let ideFieRpo = info.record.data.ideFieRpo;
        let nomeFile = info.record.data.nomFie;  
      
        if (flagDwnld) { 
            Ext.Ajax.request({
                url: "AnalisiRto/LeggiFileReport",
                method: 'GET',
                params: { idFile: ideFieRpo },            
                timeout: 600000,
                callback: function (records, success, operation) {
                    if (success) {
                        //console.log('File id:' + info.record.data.ideAlg + ' scaricato con successo');
                        var returnObj = Ext.decode(operation.responseText);
                        if (returnObj.resp == "OK") {
                            // Converti la stringa Base64 in un array di byte
                            let byteArray = new Uint8Array(atob(returnObj.file).split('').map(char => char.charCodeAt(0)));
                            downoadFile(nomeFile, byteArray)
                        }
                        else
                            Ext.Msg.alert('Lettura fallita', returnObj.msgErr);
                    }
                    else {
                        Ext.Msg.alert('Lettura fallita', "Errore in lettura del file");
                    }                    
                }
            });
        }
    }
    // Download file
    const downoadFile = (nomeFile, byteArray) => {

        // Creare un Blob dal tuo array di byte
        var blobFile = new Blob([byteArray], { type: 'application/octet-stream' });
      
        const url = window.URL.createObjectURL(blobFile);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = nomeFile
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    // --------------------------------------------------------------------------------------

    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="fa-lg fas fa-arrow-alt-circle-down" />

                    <Combobox itemId="comboPeriodoFerroviario" align="right" width={170} margin='0 10 0 0' editable="false" valueField="ideAnnFro" displayField="desAnnFro"
                        queryMode="local"
                        store={storePeriodiFerroviari}
                        onSelect={onSelectPeriodoFerroviario}
                    />


                    <Combobox itemId="comboPeriodoRto" width={150} margin='0 4 0 0' docked="right" align="right" editable="false" clearable valueField="ideTipPer" displayField="desTipPer"
                        store={storePeriodoRTO}
                        placeHolder="Periodo RTO"
                        class="placeholder"   
                        clearable
                        queryMode="local"
                        onSelect={onSelectPeriodoRto}
                        onClearicontap={onClearPeriodoRto}
                    />
                </Titlebar>

                <Panel docked="top" layout="hbox" margin="10 0 0 10">                  
                    <Container layout="hbox" > 
                        <Container margin='0 4 0 0'> 
                            <Combobox itemId="comboTipoReport" docked="left" align="left" width={328} editable="false" valueField="ideTipRpo" displayField="desTipRpo"
                                label="Tipo Report"
                                labelAlign="left"
                                labelWidth={80}
                                queryMode="local"
                                clearable
                                forceSelection="true"
                                store={storeTipoReport}
                                onClearicontap={onClearTipoReport}
                                onSelect={onSelectTipoReport}

                                />
                        </Container>
                        <Container margin='0 5 0 5'>                      

                            <DatePickerField label="Dal" width={170} height={25} labelWidth={40} docked="left" picker={{ yearFrom: 1990 }}
                                labelAlign="left"
                                labelWidth={35}
                                labelCls='LabelRiepilogo'
                                itemId="dPickDataDa"
                                queryMode="local"
                                hidden={!showSelezionaDataReport.filtroDate}
                            />
                            <DatePickerField label="Al" width={170} height={25} labelWidth={30} margin="0 0 0 10" docked="left" picker={{ yearFrom: 1990 }}
                                labelAlign="left"
                                labelWidth={30}
                                labelCls='LabelRiepilogo'
                                itemId="dPickDataA"
                                queryMode="local"
                                hidden={!showSelezionaDataReport.filtroDate}
                            />
                     
                        </Container>
                        
                        {showSelezionaDataReport.filtroImpresa && <Container margin='0 5 0 5'>
                        <Combobox
                            label="Impresa Ferroviaria"
                            labelWidth={120}
                            width={400}
                            labelAlign="left"
                            store={storeIF}
                            displayField="nomCli"
                            valueField="ideCli"
                            queryMode="local"
                            clearable
                            forceSelection="true"
                            itemId="comboIF"
                        />
                        </Container>}

                        {showSelezionaDataReport.filtroCommittente && <Container margin='0 5 0 5'>
                            <Combobox
                                label="Committente"
                                labelWidth={85}
                                labelAlign="left"
                                store={storeCommittenti}
                                displayField="desCommittente"
                                valueField="ideCommittente"
                                queryMode="local"
                                clearable
                                forceSelection="true"
                                itemId="comboCommittente"
                            />
                        </Container>}

                        {showSelezionaDataReport.filtroTrafficoPrev && <Container margin='0 5 0 5'>
                            <Combobox
                                label="Traffico Prevalente"
                                labelWidth={115}
                                labelAlign="left"
                                store={storeTipoTrafficoPrevalente}
                                displayField="desTipTraCli"
                                valueField="ideTipTraCli"
                                queryMode="local"
                                clearable
                                forceSelection="true"
                                itemId="comboTrafficoPrev"
                            />
                        </Container>}
                    </Container>

                    <Panel docked="bottom" width="100%" margin="10 0 2 0">
                        <Toolbar docked="right" width="100%">
                            <SegmentedButton docked="right">
                                <Button cls="marginButton ricercaAvanzataButton" align="right" text="Pulisci filtri" iconCls={'fa-solid fa-filter-circle-xmark'} ui="action" handler={handlePulisciFiltri} />
                                <Button cls="marginButton appButton" align="right" text="Avvia ricerca" iconCls={'x-fa fa-search'} ui="action" handler={onConfermaClick} />
                                <Button cls="marginButton appButton" align="right" text="Elabora Report" iconCls={'x-fa fa-plus-square'} ui="action" handler={onElaboraClick} />
                            </SegmentedButton>
                        </Toolbar>
                    </Panel>
                </Panel>

                <Grid flex={1} scrollable cls="custom-grid" itemId="gridDownRepo" itemConfig={{ viewModel: true }}
                    selectable={{ checkbox: false, mode: 'single' }}
                    plugins={['gridfilters', 'gridexporter']}
                    store={storeDownloadReport}
                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                >

                    <Column height="40" width={65} text="Azioni" filter='string' cell={{
                        tools: {
                            download: {
                                handler: onDownloadFileReport.bind(this),
                                bind: {
                                    hidden: '{!record.flagDwnld}',
                                    iconCls: "x-fa fa-download buttonGridTool",
                                    tooltip: 'Scarica'
                                }
                            },
                            delete: {
                                handler: onDeleteClick.bind(this),
                                bind: {
                                    iconCls: "x-fa fa-trash buttonGridTool",
                                    tooltip: 'Elimina'
                                }
                            }
                        }

                    }}
                    />
                    <Column height="40" width={250} text="Nome File" dataIndex="nomFie" filter='string' cell={{ cls: 'selectable' }} />
                    <Column height="40" width={120} text="Tipo Report" dataIndex="desTipRpo" filter='string'/>
                    <Column height="40" width={140} text="Periodo Ferroviario" dataIndex="desAnnFro" filter='string'/>
                    <Column height="40" width={100} text="Periodo RTO" dataIndex="desTipPer" filter='string' exportStyle={{ width: '150' }} />
                    <Column height="40" width={135} text="Data Inizio Ricerca" dataIndex="datIniRic" type="datecolumn" formatter='date("d/m/Y")' filter='date'/>
                    <Column height="40" width={130} text="Data Fine Ricerca" dataIndex="datFinRic" type="datecolumn" formatter='date("d/m/Y")' filter='date' />
                    <Column height="40" width={100} text="Gruppo RFI" hidden={isIF} dataIndex="dscGrp" filter='string' />
                    <Column height="40" width={190} text="Utente" dataIndex="desUte" filter='string'  />
                    <Column height="40" width={120} text="Data Richiesta" dataIndex="datRichiesta" type="datecolumn" formatter='date("d/m/Y H:i:s")' filter='date' />
                    <Column height="40" width={170} text="Data Inizio Elaborazione" dataIndex="datElaInizio" type="datecolumn" formatter='date("d/m/Y H:i:s")' filter='date'/>
                    <Column height="40" width={165} text="Data Fine Elaborazione" dataIndex="datElaFine" type="datecolumn" formatter='date("d/m/Y H:i:s")' filter='date'/>
                    <Column height="40" width={130} text="Stato" dataIndex="desStoEla" filter='string'/>
                    
                </Grid>
            </Panel>
        </Container>

    );
}

export default DownloadReport;