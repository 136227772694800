import React from 'react';
import { useEffect, useState } from 'react';
import { useUser } from '../components/UserContext';
import { useAmbiente } from '../components/AmbienteContext';
import { usePeriodi } from '../components/PeriodiContext';
import { Container, Titlebar, Grid, Button, SegmentedButton, Column, Combobox, ExtButton, Panel, ComboBoxField, Toolbar } from '@sencha/ext-react-modern';
import impresaFerroviariaModel from '../models/ImpresaFerroviariaModel.js';

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.Exporter'

]);
//---------PERIODO FERROVIARIO-----------------------------------
const storePeriodi = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviari',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//--------------------IMPRESA FERROVIARIA--------------------

const storeImpresa = Ext.create('Ext.data.Store', {
    model: impresaFerroviariaModel,
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiListImpresaFerroviaria',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//--------------------STATO RTO--------------------------------------
const storeStatoRto = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiaStatiRTO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});


//----------ELENCO TABBELLA RTO INTERNAZIONALI------------------------------------------------
const storeAnalisiRtoInternazionali = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'RtoInternazionali/LeggiAnalisiRTOIntern', 
        reader: {
            type: 'json'
        }
    },
        autoload: false
});

//--------------------------------------------

function AnalisiRTOInternazionali() {

    let title = " Analisi RTO Internazionali";
    let ui = 'action';
    //var text = "";

    const { user } = useUser();
    const { ambiente, updateAmbiente } = useAmbiente();
    const { periodi } = usePeriodi();

    storePeriodi.setData(periodi.periodiFerroviari);

    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });

    //const caricaPeriodiFerroviari = () => {
    //    storePeriodi.load({
    //    })
    //}
    const caricaStatoRto = () => {
        storeStatoRto.load({
        })
    }
    const caricaImpreseFerroviarie = () => {
        storeImpresa.load({
            params: {
                IdeOpaRfi: user.idOperatoreRFI
            }
        })
    }
   
    const caricaRtoInternazionali = (ideUte, ideAnnFro, ideCli, ideStoRto) => {

        storeAnalisiRtoInternazionali.load({
            params: {
                ideUte: ideUte,
                ideAnnFro: ideAnnFro,
                ideCli: ideCli,
                ideStoRTO: ideStoRto
            },
            callback: (records, operation, success) => {
                if (success) {
                    //console.log(records);
                } else {
                    console.error("Errore durante il caricamento dei dati");
                }
            }
        });

    }

 //--------------CONFERMA/AVVIA RICERCA --------------------------
    const onConfermaClick = (x) => {
        let ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value;
        let ideCli = Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0]._value;
        let ideStoRTO = Ext.ComponentQuery.query('#comboStatoRto')[0]._value;
        caricaRtoInternazionali(user.ideUte, ideAnnFro, ideCli, ideStoRTO);
        Ext.toast({ message: 'Ricerca Effettuata', timeout: 4000 });
    }    
//---------------------------------------------------------------

//--------------PULISCI FILTRI------------------------------------
    const handlePulisciFiltri = (btn) => {
        Ext.ComponentQuery.query('#comboImpresaFerroviaria')[0].setValue(null);
        Ext.ComponentQuery.query('#comboStatoRto')[0].setValue(null);
    };
    //-----------------------------------------------------------------

    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridAnalisiRtoInternazionali')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }

    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {
                    let valTipPer = stateAmbiente.ideTipPer;
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente AnalisiRTOInternazionali SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente AnalisiRTOInternazionali SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
    }

    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ambiente.ideAnnFro);

            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });

        }
    };
    const aggiornaStateAmbiente = () => {
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        setStateAmbiente({ ...stateAmbiente });
    }
 
    useEffect(() => {
        //caricaPeriodiFerroviari();
        caricaImpreseFerroviarie();
        caricaStatoRto();
    }, []);

    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);

    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1}>
                    <Button align="left" cls="title-icon" iconCls="fa-lg fa-solid fa-globe" />
                    <Combobox
                        align="right"
                        width={170}
                        margin='0 4 0 0'
                        editable="false"
                        valueField="ideAnnFro"
                        displayField="desAnnFro"
                        queryMode="local"
                        store={storePeriodi}
                        itemId="comboPeriodoFerroviario"
                        onSelect={onSelectPeriodoFerroviario}   
                    />
                </Titlebar>

                <Grid
                    flex={1} scrollable itemId="gridAnalisiRtoInternazionali" selectable={{ checkbox: false, mode: 'single' }} store={storeAnalisiRtoInternazionali}
                    itemConfig={{
                        viewModel: true
                    }}
                    plugins={['gridfilters', 'gridexporter']}
                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                    cls="custom-grid"
                >

                    <Toolbar docked="top" margin="10 0 15 10" cls="lottoToolbar">
                        <SegmentedButton docked="right">
                            <ExtButton cls="marginButton ricercaAvanzataButton" align="right" text="Pulisci filtri" iconCls={'fa-solid fa-filter-circle-xmark'} ui="action" handler={handlePulisciFiltri} />
                            <ExtButton cls="marginButton appButton" align="right" text="Avvia ricerca" iconCls={'x-fa fa-search'} ui="action" handler={onConfermaClick} />
                            <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />

                        </SegmentedButton>
                        <Panel layout="hbox">
                            <ComboBoxField
                                label="Impresa Ferroviaria"
                                labelWidth={124}
                                width={420}
                                labelAlign="left"
                                store={storeImpresa}
                                displayField="nomCli"
                                valueField="ideCli"
                                queryMode="local"
                                clearable
                                forceSelection="true"
                                itemId="comboImpresaFerroviaria"
                                />
                           
                            <ComboBoxField
                                label="Stato RTO"
                                labelAlign="left"
                                labelWidth={73}
                                width={250}
                                margin="0 0 0 20"
                                store={storeStatoRto}
                                displayField="desStoRto"
                                valueField="ideStoRto"
                                queryMode="local"
                                clearable
                                forceSelection="true"
                                itemId="comboStatoRto"
                            />
                        </Panel >
                    </Toolbar>

                    <Column height="40" width={330} text="Impresa" dataIndex="noM_CLI" filter='string' flex={1} exportStyle={{ width: '530' }} />
                    <Column height="40" width={330} text="Stato" dataIndex="stO_RTO" filter='string' flex={1} exportStyle={{ width: '530' }} />
                    <Column height="40" width={270} text="Tipo Treno" dataIndex="deS_TIP_TRA" filter='string' exportStyle={{ width: '360' }} />
                    <Column height="40" width={170} text="PO Inviati a PCS" dataIndex="nuM_TRC_PCS" filter='string' exportStyle={{width: '260',alignment:{horizontal: 'Left'} }} />
                    <Column height="40" width={200} text="PO Modificati Dopo Invio" dataIndex='upD_TRC_PCS' filter='string' exportStyle={{ width: '260', alignment: {horizontal: 'Left'} }} />
                    <Column height="40" width={100} text="RTO" dataIndex='nuM_RTO' filter='string' exportStyle={{ width: '120', alignment: {horizontal: 'Left'} }} />
                    <Column height="40" width={100} text="Dossier" dataIndex='nuM_DSI' filter='string' exportStyle={{ width: '120', alignment: {horizontal: 'Left'} }} />
                </Grid>
            </Panel>
        </Container>
    );
}

export default AnalisiRTOInternazionali