import React, { useState, useEffect } from "react";
import "./Rto.css";
import { stati } from "./Constants";
import { formattaData, trasformaSvg, trasformaSvgStato } from "./Funzioni";
import { Container } from "@sencha/ext-react-modern";
const Rto = ({
    stato,
    poPubblicato,
    statoII,
    iICompleto,
    congruenzaRTOPO,
    statoInvioII,
    dataInvioRDI,
    dataRispostaRDI,
    circolabilità,
    cdS,
    modificheControllo
}) => {
    const _dataInvioRDI = dataInvioRDI;
    const _dataRispostaRDI = dataRispostaRDI;
    const [svgStato, setSvgStato] = useState(trasformaSvgStato(stato, stati));
    const [svgPoPubblicato, setSvgPoPubblicato] = useState(trasformaSvg(poPubblicato));
    const [svgStatoII, setSvgStatoII] = useState(trasformaSvg(statoII));
    const [svgIICompleto, setSvgIICompleto] = useState(trasformaSvg(iICompleto));
    const [svgCongruenzaRTOPO, setSvgCongruenzaRTOPO] = useState(trasformaSvg(congruenzaRTOPO));
    const [svgCircolabilità, setSvgCircolabilità] = useState(trasformaSvg(circolabilità));
    const [svgCdS, setSvgCdS] = useState(null);

    useEffect(() => {
        setSvgCdS(trasformaSvg(cdS));
        
    }, [modificheControllo]);

  return (
    <Container cls="content">
      <Container cls="flex-container-nowrap">
        <Container cls="svgImg">
            {svgStato}
        </Container>
        <Container cls="tableRTO">
        <table className="table">
        <thead>
        <tr>
                <th>PO pubblicato</th>
                <th>Stato I.I.</th>
                <th>I.I. Completo</th>
                <th>Congruenza RTO/PO</th>
                <th>Stato Invio I.I.</th>
                <th>Data invio a RDI</th>
                <th>Data risposta RDI</th>
                <th>Circolabilità</th>
                <th>CdS</th>
              </tr>
        </thead>
            <tbody>
            <tr>
                <td>{svgPoPubblicato}</td>
                <td>{svgStatoII}</td>
                <td>{svgIICompleto}</td>
                <td>{svgCongruenzaRTOPO}</td>
                <td>
                  <u>{statoInvioII}</u>
                </td>
                <td>{_dataInvioRDI}</td>
                <td>{_dataRispostaRDI}</td>
                <td>{svgCircolabilità}</td>
                <td>{svgCdS}</td>
              </tr>
            </tbody>
        </table>
        </Container>
      </Container>
    </Container>
  );
};

export default Rto;
