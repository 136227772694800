import React, { useState, useEffect } from 'react';
import { Panel, Container, FormPanel, TextField, DatePickerField, ComboBoxField, CheckBoxField } from '@sencha/ext-react-modern';
import { useUser } from '../components/UserContext';

const Ext = window['Ext'];

const storeTipoServizio = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Clienti/LeggiServizioAll',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const caricaTipoServizio = () => {
    storeTipoServizio.load();
}

//------------------------------------------------------
const caricaStore = async () => {
    await Promise.all([
        caricaTipoServizio()
    ])
}

caricaStore();


function ClienteLicenza(props) {
    const { user } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    var readonly = user.ruolo == 'RFI' ? false : true;

    const [isStoreLoaded, setIsStoreLoaded] = useState(false);

    useEffect(() => {
        caricaStore().then(() => setIsStoreLoaded(true));
    }, []);

    const onModifica = () => {
        Ext.ComponentQuery.query('#buttonModificaLicenza')[0].setDisabled(true);
        Ext.ComponentQuery.query('#confermaLicenza')[0].setDisabled(false);
        Ext.ComponentQuery.query('#annullaLicenza')[0].setDisabled(false);

        Ext.ComponentQuery.query('#numeroField')[0].setDisabled(false);
        Ext.ComponentQuery.query('#dataRilascioField')[0].setDisabled(false);
        Ext.ComponentQuery.query('#tipoServizioField')[0].setDisabled(false);
        Ext.ComponentQuery.query('#revocaCheckbox')[0].setDisabled(false);
    }

    const onConfermaModifica = () => {

        let licenzaAggiornata = { ...props.licenza };

        // Aggiorna la copia con i nuovi valori dai campi del form
        licenzaAggiornata.numLic = Ext.ComponentQuery.query('#numeroField')[0].getValue();
        licenzaAggiornata.datRla = Ext.ComponentQuery.query('#dataRilascioField')[0].getValue();
        licenzaAggiornata.ideCliMse = props.cliente.ideCliMse;

        licenzaAggiornata.ideSer = Ext.ComponentQuery.query('#tipoServizioField')[0].getValue();
        licenzaAggiornata.ideStoLic = Ext.ComponentQuery.query('#revocaCheckbox')[0].getChecked() ? 1 : 0;


        // Determina l'URL e i parametri in base a se numLic esiste o meno
        let url = "", par = "";
        if (props.licenza) {
            url = 'Clienti/ModificaLicenza';
            par = {
                ideUte: user.ideUte,
                ipAddress: '',
                ideCliMse: licenzaAggiornata.ideCliMse,
                ideLic: props.licenza.ideLic,
                numLic: licenzaAggiornata.numLic,
                datRla: licenzaAggiornata.datRla,
                ideSer: licenzaAggiornata.ideSer,
                IdeStoLic: licenzaAggiornata.ideStoLic
            };
        }
        else {
            url = 'Clienti/InserisciLicenza';
            par = {
                ideUte: user.ideUte,
                ipAddress: '',
                ideCliMse: licenzaAggiornata.ideCliMse,
                numLic: licenzaAggiornata.numLic,
                datRla: licenzaAggiornata.datRla,
                ideSer: licenzaAggiornata.ideSer,
                IdeStoLic: licenzaAggiornata.ideStoLic
            };
        }


        // Effettua la chiamata Ajax
        Ext.Ajax.request({
            url: url,
            method: 'POST',
            params: par,
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);
                    if (returnObj.resp == "OK") {
                        Ext.toast({ message: 'Aggiornamento effettuato con successo.', timeout: 4000 });
                        Ext.ComponentQuery.query('#buttonModificaLicenza')[0].setDisabled(false);
                        Ext.ComponentQuery.query('#confermaLicenza')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#annullaLicenza')[0].setDisabled(true);
                        // disabilita box
                        Ext.ComponentQuery.query('#numeroField')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#dataRilascioField')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#tipoServizioField')[0].setDisabled(true);
                        Ext.ComponentQuery.query('#revocaCheckbox')[0].setDisabled(true);
                        props.aggiornaLicenza();
                        console.log('Oggetto Licenza modificato con successo');
                    } else {
                        Ext.Msg.alert('Modifica fallita', returnObj.msgErr);
                    }
                } else {
                    Ext.Msg.alert('Modifica fallita', returnObj.msgErr);
                    console.error('Errore nella modifica dell\'oggetto Licenza');
                }
            }
        });
    }

    const onAnnullaModifica = () => {
        Ext.ComponentQuery.query('#numeroField')[0].setValue(props.licenza ? props.licenza.numLic : null).setDisabled(true);
        Ext.ComponentQuery.query('#dataRilascioField')[0].setValue(props.licenza ? new Date(props.licenza.datRla) : null).setDisabled(false);
        Ext.ComponentQuery.query('#tipoServizioField')[0].setValue(props.licenza ? props.licenza.ideSer : null).setDisabled(false);
        Ext.ComponentQuery.query('#revocaCheckbox')[0].setChecked(props.licenza ? (props.licenza.ideStoLic === 1 ? true : false) : false).setDisabled(false);

        Ext.ComponentQuery.query('#buttonModificaLicenza')[0].setDisabled(false);
        Ext.ComponentQuery.query('#confermaLicenza')[0].setDisabled(true);
        Ext.ComponentQuery.query('#annullaLicenza')[0].setDisabled(true);

        // disabilita box
        Ext.ComponentQuery.query('#numeroField')[0].setDisabled(true);
        Ext.ComponentQuery.query('#dataRilascioField')[0].setDisabled(true);
        Ext.ComponentQuery.query('#tipoServizioField')[0].setDisabled(true);
        Ext.ComponentQuery.query('#revocaCheckbox')[0].setDisabled(true);
    }

    function handleCollapse(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#buttonModificaLicenza')[0].setHidden(true);
        Ext.ComponentQuery.query('#confermaLicenza')[0].setHidden(true);
        Ext.ComponentQuery.query('#annullaLicenza')[0].setHidden(true);
    };

    function handleExpand(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#buttonModificaLicenza')[0].setHidden(readonly);
        Ext.ComponentQuery.query('#confermaLicenza')[0].setHidden(readonly);
        Ext.ComponentQuery.query('#annullaLicenza')[0].setHidden(readonly);
    };

    //console.log(props);

    return (

        <Panel
            title="Licenza"
            scrollable
            itemId="panelLicenza"
            bodyPadding={5}
            titleCollapse={true}
            iconCls="x-fa fa-file-signature"
            collapsible={{ collapsed: true, direction: 'top' }}
            onCollapse={handleCollapse}
            onExpand={handleExpand}
            cls='PnlSectionCML2 iconPanelsRTO'
            tools={[
                { iconCls: 'x-fa fa-wrench', tooltip: 'Modifica', hidden: true, itemId: "buttonModificaLicenza", handler: onModifica.bind(this) },
                { iconCls: 'x-fa fa-check', itemId: "confermaLicenza", hidden: true, disabled: 'true', handler: onConfermaModifica.bind(this), tooltip: 'Conferma' },
                { iconCls: 'x-fa fa-undo', itemId: "annullaLicenza", hidden: true, handler: onAnnullaModifica.bind(this), disabled: 'true', tooltip: 'Annulla' }
            ]}
        >
            {isStoreLoaded ? (

                < FormPanel >
                    <Container layout="hbox" margin="4px">
                        {/* Campo Numero */}
                        <TextField
                            label="Numero"
                            type="number"
                            itemId="numeroField"
                            labelAlign="left"
                            flex={1}
                            margin="0 10px 0 0"
                            disabled={true}
                            value={props.licenza ? props.licenza.numLic : null}
                            required={true}
                        />

                        {/* Campo Data Rilascio */}
                        <DatePickerField
                            label="Data Rilascio"
                            itemId="dataRilascioField"
                            labelAlign="left"
                            flex={1}
                            margin="0 10px 0 0"
                            picker="auto"
                            disabled={true}
                            value={props.licenza ? new Date(props.licenza.datRla) : null}
                            required={true}
                        />

                        {/* Campo Tipo Servizio */}
                        <ComboBoxField
                            name="ideSer"
                            itemId="tipoServizioField"
                            label="Tipo Servizio"
                            labelAlign="left"
                            flex={2}
                            margin="0 10px 0 0"
                            store={storeTipoServizio}
                            displayField="desSer"
                            valueField="ideSer"
                            queryMode="local"
                            disabled={true}
                            value={props.licenza ? props.licenza.ideSer : null}
                        />

                        {/* Spaziatore per spingere il campo Revoca a destra */}
                        <Container flex={1} />

                        {/* Campo Revoca */}
                        <CheckBoxField
                            label="Revoca"
                            itemId="revocaCheckbox"
                            disabled={true}
                            checked={props.licenza ? (props.licenza.ideStoLic === 1 ? true : false) : false}
                            required={false}
                        />
                    </Container>
                </FormPanel>
            ) : <FormPanel></FormPanel>
            }



        </Panel >
    );
}

export default ClienteLicenza;