import React from 'react';
import { Panel, Container, Loadmask, Grid, Button, Column } from '@sencha/ext-react-modern';
import { useState, useContext } from "react";
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import legamiLocModel from '../models/LegamiLocalitaModel.js';
import localitaPercorsoModel from '../models/LocalitaPercorsoRTOModel.js';
import { useUser } from '../components/UserContext';
import Calendario from "../components/Calendario/Calendario/Calendario.js";
import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
import periodicitaModel from '../models/PeriodicitaIIServiziModel.js';
import { parseDate } from "../components/Calendario/Calendario/Funzioni.js";
const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);

var rangeDateStart = null;
var rangeDateEnd = null;

const storeLegamiImpianto = Ext.create('Ext.data.Store', {
    model: legamiLocModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const storePercorso = Ext.create('Ext.data.Store', {
    model: localitaPercorsoModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const storePeriodicitaIILegami = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const numeriTrenoStore = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

let titolo = "";
let modificabile = true;
let legameSelezionato = null;
let localitaSelezionata = null;

function onNuovaPeriodicitaIILegClick() {
    var grid = Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0];
    var store = grid.getStore();
    var record = Ext.create(periodicitaModel, {});
    record.data.datIniPer = rangeDateStart;
    record.data.datFinPer = rangeDateEnd;
    record.data.flgSmr = true;
    record.data.ideOpv = 0;
    store.insert(grid.getStore().data.length, record);
    grid.getPlugin('rowedit').startEdit(record, 1);
}


function RtoIILegami(props) {
    let nomTreApp = "";
    let protocolloIFApp = "";
    function aggiornaControllo(stato) {
        props.aggiornaStateControllo(stato);
    }
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
    const [calendars, setCalendars] = useState(null);
    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);
    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede;
    rangeDateStart = new Date(contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", rtoSchede.data.items[0].data.rto.ideAnnFro, 0, false, false, true).data.datIniVal);
    rangeDateEnd = new Date(contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", rtoSchede.data.items[0].data.rto.ideAnnFro, 0, false, false, true).data.datFinVal);
    const { user, switchUser } = useUser();
    let arrayLocalitaDisponibili = [];
    let locApp = props.localitaDisponibili;
    for (let i = 0; i < locApp.length; i++) {
        let app = { text: locApp[i].nomLoc, value: locApp[i].prgPrc, idePrc: locApp[i].idePrcRTO, ideLoc: locApp[i].ideLoc };
        arrayLocalitaDisponibili.push(app);
    }

    function aggiornaStateCheck(ckPeriodi) {
        props.aggiornaStateModifica(true);
        props.aggiornaStatePeriodII(ckPeriodi);
    }

    const caricaPercorso = () => {
        Ext.Ajax.request({
            url: 'Rto/LeggiPercorsoRTO',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                for (let i = 0; i < returnObj.length; i++) {
                    returnObj[i].flgCfn = returnObj[i].flgCfn;
                    returnObj[i].flgServizio = returnObj[i].flgFlg.indexOf("S") !== -1;
                    returnObj[i].flgManovra = returnObj[i].flgFlg.indexOf("M") !== -1;
                    returnObj[i].flgLegame = returnObj[i].flgFlg.indexOf("L") !== -1;
                }
                storePercorso.loadData(returnObj);
                Ext.ComponentQuery.query('#gridPercorso')[0].setStore(storePercorso);
            }
        });
    }

    function onAggiungiPeriodicitaLegameIIClick(grid, info) {

        var store = grid.getStore();
        var record = Ext.create(periodicitaModel, {});
        record.data.datIniPer = rangeDateStart;
        record.data.datFinPer = rangeDateEnd;
        record.data.flgSmr = true;
        record.data.ideOpv = 0;
        store.insert(info.cell.row._recordIndex + 1, record);
        grid.getPlugin('rowedit').startEdit(record, 1);
    }

    function onModificaPeriodicitaLegameIIClick(grid, info) {

        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 2);
        }
    }

    function onEliminaPeriodicitaLegameIIClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione della periodicita legame?", function (button) {

            if (button === 'yes') {
                setStateLoadMask({ hidden: false, message: "Attendere eliminazione in corso...", ricarica: false });
                var periodicitaByDB = null;
                Ext.Ajax.request({
                    url: 'Rto/GetImpegnoImpianto',
                    method: 'GET',
                    params: {
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                    },
                    callback: function (resp, success, operation) {
                        const idPeriodicita = info.record.data.idePerImpegnoImpianto;
                        periodicitaByDB = Ext.decode(operation.responseText);

                        if (periodicitaByDB.periodicitaImpegnoImpiantoLegami.find(per => per.idePerImpegnoImpianto === idPeriodicita) != null) {
                            var periodicitaJson = Ext.encode(periodicitaByDB.periodicitaImpegnoImpiantoLegami.find(per => per.idePerImpegnoImpianto === idPeriodicita));
                            Ext.Ajax.request({
                                url: 'Rto/CancellaPeriodicitaLegamiImpegnoImpianto',
                                method: 'POST',
                                params: {
                                    ideUte: user.ideUte,
                                    periodicitaDes: periodicitaJson
                                },
                                callback: function (records, success, operation) {
                                    if (success) {
                                        var returnObj = Ext.decode(operation.responseText);
                                        //modifica gestione errore
                                        if (returnObj.resp === "KO") {
                                            Ext.Msg.alert('Elimina periodicita legame fallito', returnObj.msgErr)
                                            setStateLoadMask({ hidden: true, message: "Attendere eliminazione in corso...", ricarica: false });
                                        }
                                        else {
                                            refreshGridPeriodicitaLegII();
                                            Ext.toast({ message: 'Periodicita legame eliminata', timeout: 4000 });
                                            aggiornaControllo(true);
                                            Ext.Ajax.request({
                                                url: 'Rto/CheckPeriodicitaImpegnoImpianto',
                                                method: 'POST',
                                                params: {
                                                    ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                                                },
                                                callback: function (records, success, operation) {
                                                    var returnCheck = Ext.decode(operation.responseText);
                                                    if (returnCheck.length > 0) {
                                                        aggiornaStateCheck(false);
                                                    }
                                                    else {
                                                        aggiornaStateCheck(true);
                                                    }
                                                }
                                            });
                                        }
                                    }
                                    else {
                                        Ext.Msg.alert('Elimina periodicita legame fallito', 'Elimina periodicita legame fallito');
                                        setStateLoadMask({ hidden: true, message: "Attendere eliminazione in corso...", ricarica: false });
                                    }
                                }
                            });
                        }
                        else {
                            Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0].getStore().remove(info.record);
                            Ext.toast({ message: 'Periodicita legame eliminata', timeout: 4000 });
                        }
                    }
                })

            }
        })

    }

    function caricamentoLocalitaDisponibili() {
        return new Promise(function (resolve, reject) {
            setStateLoadMask({ hidden: false, message: "Caricamento localita disponibili in corso...", ricarica: false });
            Ext.Ajax.request({
                url: 'Rto/GetAnagraficheII',
                method: 'POST',
                timeout: 600000,
                params: { ideRto: rtoSchede.data.items[0].data.rto.ideRTO },
                callback: function (resp, success, operation) {
                    if (success) {
                        var resptext = Ext.decode(operation.responseText);
                        Ext.Ajax.request({
                            url: 'Rto/GetLocalitaDisponibiliII',
                            method: 'POST',
                            params: { ideRto: rtoSchede.data.items[0].data.rto.ideRTO, impegnoImpianto: Ext.encode(resptext.impianti) },
                            callback: function (resp, success, operation) {
                                if (success) {
                                    var resptextBefore = Ext.decode(operation.responseText);
                                    locApp = resptextBefore;
                                    arrayLocalitaDisponibili = [];
                                    for (let i = 0; i < resptextBefore.length; i++) {
                                        let app = { text: resptextBefore[i].nomLoc, value: resptextBefore[i].prgPrc, idePrc: resptextBefore[i].idePrcRTO, ideLoc: resptextBefore[i].ideLoc };
                                        arrayLocalitaDisponibili.push(app);
                                    }
                                    Ext.ComponentQuery.query('#cmbLocalitaLegamiII')[0].setOptions(arrayLocalitaDisponibili);
                                    setStateLoadMask({ hidden: true, message: "Caricamento localita disponibili in corso...", ricarica: false });
                                    resolve();
                                }
                            }
                        })
                    }
                }
            });
        })

    }

    const caricaPeriodicitaLegamiImpianto = (idE_LGM_LOC) => {
        var periodicitaByDB = null;
        Ext.Ajax.request({
            url: 'Rto/GetImpegnoImpianto',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (resp, success, operation) {
                periodicitaByDB = Ext.decode(operation.responseText);
                storePeriodicitaIILegami.loadData(periodicitaByDB.periodicitaImpegnoImpiantoLegami.filter(function (value) {
                    return value.ideParent === idE_LGM_LOC;
                }))
            }
        })
    }

    function refreshGridPeriodicitaLegII() {
        setStateLoadMask({ hidden: false, message: "Refresh griglia periodicita legami...", ricarica: false });
        var periodicitaByDB = null;
        Ext.Ajax.request({
            url: 'Rto/GetImpegnoImpianto',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (resp, success, operation) {
                periodicitaByDB = Ext.decode(operation.responseText);
                storePeriodicitaIILegami.loadData(periodicitaByDB.periodicitaImpegnoImpiantoLegami.filter(function (value) {
                    return value.ideParent === legameSelezionato;
                }));
                Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0].setStore(storePeriodicitaIILegami);
                Ext.Ajax.request({
                    url: 'Rto/LeggiLegamiLocalita',
                    method: 'GET',
                    params: {
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                        inEdit: props.inEdit
                    },
                    callback: function (records, success, operation) {
                        var returnObj = Ext.decode(operation.responseText);
                        let arrayCalendario = [];
                        if (returnObj.find(x => x.idE_LGM_LOC === legameSelezionato).snG_PER_ERR !== "") {
                            arrayCalendario.push(returnObj.find(x => x.idE_LGM_LOC === legameSelezionato));
                            setCalendars(arrayCalendario);
                        }
                        else {
                            setCalendars(null);
                        }
                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                    }
                })
            }
        });
    }

    function onNuovoLegameImpiantoClick() {
        caricamentoLocalitaDisponibili().then(function () {
            var grid = Ext.ComponentQuery.query('#gridLegamiImpianto')[0];
            var store = grid.getStore();
            var record = Ext.create(legamiLocModel, {});
            record.data.altraIF = 0;
            record.data.pTL_IPR = "";
            record.data.nOM_TRE = "";
            store.insert(grid.getStore().data.length, record);
            grid.getPlugin('rowedit').startEdit(record, 1);
        })

    }

    const renderListOfCalendar = (calendars) => {
        const cal = calendars.map((name, i) => <><Panel collapsible={false} key={`sintesiLeg${i}`} cls="calendar-container">
            <br />
            <div className="periodicitaHeader">
                <CalendarioSintetico
                    giorniTreno={calendars[i].snG_PER_ERR ? calendars[i].snG_PER_ERR.split('1').length - 1 : '0'}
                    periodicitaSintetico={calendars[i].snG_GIO_SET}
                />
            </div>
            <Button cls="plus-minus-button" itemId={`calendarioExpButminIILeg${i}`} iconCls="x-fa fa-minus" handler={function () {
                Ext.ComponentQuery.query(`#calendarioExpButPlusIILeg${i}`)[0].show();
                this.hide();
                Ext.ComponentQuery.query(`#calendarioExpIILeg${i}`)[0].hide();
            }}></Button>
            <Button cls="plus-minus-button" itemId={`calendarioExpButPlusIILeg${i}`} hidden={true} iconCls="x-fa fa-plus" handler={function () {
                Ext.ComponentQuery.query(`#calendarioExpButminIILeg${i}`)[0].show();
                this.hide();
                Ext.ComponentQuery.query(`#calendarioExpIILeg${i}`)[0].show();
            }}></Button>
        </Panel>
            <Panel itemId={`calendarioExpIILeg${i}`} hidden={false} collapsible={false}>
                <Calendario
                    dataInizio={calendars[i].daT_INI_VAL}
                    dataFine={calendars[i].daT_FIN_VAL}
                    periodicita={calendars[i].snG_PER_ERR}
                    dataAttProvvedimento={null}
                    periodicitaSintetico={calendars[i].snG_GIO_SET}
                    IsNonEffettuabile={true}
                    isAttivazioneProvvedimento={false}
                />
            </Panel>
        </>)
        return cal;
    }

    function onModificaLegameIIClick(grid, info) {
        if (info.record.data.legamE_IMPOSTO === "No") {
            caricamentoLocalitaDisponibili().then(function () {
                var rowediting = grid.getPlugin('rowedit');
                if (rowediting.editing === false) {
                    var record = info.record;
                    rowediting.startEdit(record, 1);
                    Ext.ComponentQuery.query('#cmbLocalitaLegamiII')[0].setValue(arrayLocalitaDisponibili.find(x => x.idePrc === info.record.data.idE_PRC_RTO).value);
                }
            });
        }
        else {
            Ext.toast({ message: 'Impossibile modificare legame imposto', timeout: 4000 });
        }
    }

    function modificaPeriodicita(grid, info) {
        modificabile = info.record.data.legamE_IMPOSTO === "Si" ? false : true;
        setStateLoadMask({ hidden: false, message: "Attendere caricamento periodicita in corso...", ricarica: false });
        Ext.Ajax.request({
            url: 'Rto/LeggiLegamiLocalita',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                inEdit: props.inEdit
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                let arrayCalendario = [];
                if (returnObj.find(x => x.idE_LGM_LOC === info.record.data.idE_LGM_LOC).snG_PER_ERR !== "") {
                    arrayCalendario.push(returnObj.find(x => x.idE_LGM_LOC === info.record.data.idE_LGM_LOC));
                    setCalendars(arrayCalendario);
                }
                else {
                    setCalendars(null);
                }
                caricaPeriodicitaLegamiImpianto(info.record.data.idE_LGM_LOC);
                setIsCalendarioVisible(true);
                titolo = "Periodicita legame " + info.record.data.noM_LOC;
                legameSelezionato = info.record.data.idE_LGM_LOC;
                localitaSelezionata = info.record.data.idE_LOC;
                setStateLoadMask({ hidden: true, message: "Attendere caricamento periodicita in corso...", ricarica: false });
            }
        });

    }

    function modificaGiorniCircolazione(grid, info) {
        var dialog = Ext.create({
            xtype: 'dialog',
            title: 'Dettaglio Giorni',
            layout: 'fit',
            width: '500px',
            closable: "true",
            listeners: {
                close: function (dialog) {
                    dialog.destroy();
                    refreshGridPeriodicitaLegII();
                    aggiornaControllo(true);
                    Ext.Ajax.request({
                        url: 'Rto/CheckPeriodicitaSML',
                        method: 'POST',
                        params: {
                            idePer: legameSelezionato,
                            ideTipo: 2
                        },
                        callback: function (records, success, operation) {
                            var returnCheck = Ext.decode(operation.responseText);
                            if (returnCheck.length > 0) {
                                Ext.Msg.alert('Attenzione', returnCheck);
                                aggiornaStateCheck(false);
                            }
                            else {
                                aggiornaStateCheck(true);
                            }
                        }
                    });
                }
            },
            items: [
                {
                    xtype: 'formpanel',
                    cls: 'PnlSectionL1',
                    ref: form => form = (form),
                    itemId: 'formDettaglioGiorniIILegami',
                    items: [
                        {
                            xtype: 'fieldset',
                            defaults: { labelAlign: "left" },
                            items: [
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Sempre",
                                            flex: 1,
                                            checked: info.record.data.flgSmr,
                                            margin: "5 5 5 5",
                                            name: "checkSmrIILegami",
                                            itemId: "checkSmrIILegami",
                                            listeners: {
                                                change: function (checkbox, newValue, oldValue, eOpts) {
                                                    if (newValue) {
                                                        Ext.ComponentQuery.query('#checkLndIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkMrtIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkMrcIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkGvdIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkVnrIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkSbtIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkDmnIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkLavIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkFesIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkPtfIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkPofIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkSegPofIILegami')[0].disable();
                                                        Ext.ComponentQuery.query('#checkLndIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkMrtIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkMrcIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkGvdIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkVnrIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkSbtIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkDmnIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkLavIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkFesIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkPtfIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkPofIILegami')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkSegPofIILegami')[0].setChecked(false);
                                                    }
                                                    else {
                                                        Ext.ComponentQuery.query('#checkLndIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkMrtIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkMrcIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkGvdIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkVnrIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkSbtIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkDmnIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkLavIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkFesIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkPtfIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkPofIILegami')[0].enable();
                                                        Ext.ComponentQuery.query('#checkSegPofIILegami')[0].enable();
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Lavorativi",
                                            flex: 1,
                                            checked: info.record.data.flgLav,
                                            margin: "5 5 5 5",
                                            name: "checkLavIILegami",
                                            itemId: "checkLavIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Lunedi",
                                            flex: 1,
                                            checked: info.record.data.flgLnd,
                                            margin: "5 5 5 5",
                                            name: "checkLndIILegami",
                                            itemId: "checkLndIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgFes,
                                            margin: "5 5 5 5",
                                            name: "checkFesIILegami",
                                            itemId: "checkFesIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Martedi",
                                            flex: 1,
                                            checked: info.record.data.flgMrt,
                                            margin: "5 5 5 5",
                                            name: "checkMrtIILegami",
                                            itemId: "checkMrtIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Prefestivi",
                                            flex: 1,
                                            checked: info.record.data.flgPtf,
                                            margin: "5 5 5 5",
                                            name: "checkPtfIILegami",
                                            itemId: "checkPtfIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Mercoledi",
                                            flex: 1,
                                            checked: info.record.data.flgMrc,
                                            margin: "5 5 5 5",
                                            name: "checkMrcIILegami",
                                            itemId: "checkMrcIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Post Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgPof,
                                            margin: "5 5 5 5",
                                            name: "checkPofIILegami",
                                            itemId: "checkPofIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Giovedi",
                                            flex: 1,
                                            checked: info.record.data.flgGvd,
                                            margin: "5 5 5 5",
                                            name: "checkGvdIILegami",
                                            itemId: "checkGvdIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Seguenti Post Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgSegPof,
                                            margin: "5 5 5 5",
                                            name: "checkSegPofIILegami",
                                            itemId: "checkSegPofIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Venerdi",
                                            flex: 1,
                                            checked: info.record.data.flgVnr,
                                            margin: "5 5 5 5",
                                            name: "checkVnrIILegami",
                                            itemId: "checkVnrIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Sabato",
                                            flex: 1,
                                            checked: info.record.data.flgSbt,
                                            margin: "5 5 5 5",
                                            name: "checkSbtIILegami",
                                            itemId: "checkSbtIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Domenica",
                                            flex: 1,
                                            checked: info.record.data.flgDmn,
                                            margin: "5 5 5 5",
                                            name: "checkDmnIILegami",
                                            itemId: "checkDmnIILegami",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            xtype: 'toolbar',
                            shadow: false,
                            cls: 'PnlSectionL1',
                            docked: 'bottom',
                            layout: { type: 'hbox', pack: 'right' },
                            items: [
                                {
                                    xtype: 'button',
                                    cls: 'appButton',
                                    text: 'RESET',
                                    handler: () =>
                                        Ext.ComponentQuery.query('#formDettaglioGiorniIILegami')[0].reset()
                                },
                                {
                                    xtype: 'button',
                                    cls: 'appButton',
                                    text: 'OK',
                                    handler: (btn) => {
                                        const values = btn.up('formpanel').getValues();
                                        if (!((values.checkSmrIILegami === false || values.checkSmrIILegami === null) && (values.checkLndIILegami === false || values.checkLndIILegami === null) && (values.checkMrtIILegami === false || values.checkMrtIILegami === null) && (values.checkMrcIILegami === false || values.checkMrcIILegami === null) && (values.checkGvdIILegami === false || values.checkGvdIILegami === null) && (values.checkVnrIILegami === false || values.checkVnrIILegami === null) && (values.checkSbtIILegami === false || values.checkSbtIILegami === null) && (values.checkDmnIILegami === false || values.checkDmnIILegami === null) && (values.checkLavIILegami === false || values.checkLavIILegami === null) && (values.checkFesIILegami === false || values.checkFesIILegami === null) && (values.checkPtfIILegami === false || values.checkPtfIILegami === null) && (values.checkPofIILegami === false || values.checkPofIILegami === null) && (values.checkSegPofIILegami === false || values.checkSegPofIILegami === null))) {
                                            info.record.data.flgSmr = values.checkSmrIILegami === true ? true : false;
                                            info.record.data.flgLnd = values.checkLndIILegami === true ? true : false;
                                            info.record.data.flgMrt = values.checkMrtIILegami === true ? true : false;
                                            info.record.data.flgMrc = values.checkMrcIILegami === true ? true : false;
                                            info.record.data.flgGvd = values.checkGvdIILegami === true ? true : false;
                                            info.record.data.flgVnr = values.checkVnrIILegami === true ? true : false;
                                            info.record.data.flgSbt = values.checkSbtIILegami === true ? true : false;
                                            info.record.data.flgDmn = values.checkDmnIILegami === true ? true : false;
                                            info.record.data.flgLav = values.checkLavIILegami === true ? true : false;
                                            info.record.data.flgFes = values.checkFesIILegami === true ? true : false;
                                            info.record.data.flgPtf = values.checkPtfIILegami === true ? true : false;
                                            info.record.data.flgPof = values.checkPofIILegami === true ? true : false;
                                            info.record.data.flgSegPof = values.checkSegPofIILegami === true ? true : false;
                                            var periodicitaLegamiIIObject = info.record.data;
                                            periodicitaLegamiIIObject.ideLoc = localitaSelezionata;
                                            periodicitaLegamiIIObject.ideParent = legameSelezionato;
                                            var periodicitaJson = Ext.encode(periodicitaLegamiIIObject);
                                            setStateLoadMask({ hidden: false, message: "Salvataggio in corso...", ricarica: true });
                                            Ext.Ajax.request({
                                                url: 'Rto/ModificaPeriodicitaLegamiImpegnoImpianto',
                                                method: 'POST',
                                                params: {
                                                    ideUte: user.ideUte,
                                                    periodicitaDes: periodicitaJson
                                                },
                                                callback: function (records, success, operation) {
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp === "KO") {
                                                            setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                            Ext.Msg.alert('Modifica periodicita legame fallita', returnObj.msgErr);
                                                        }
                                                        else {
                                                            dialog.destroy();
                                                            refreshGridPeriodicitaLegII();
                                                            Ext.toast({ message: 'Periodicita legame modificata', timeout: 4000 });
                                                            aggiornaControllo(true);
                                                            Ext.Ajax.request({
                                                                url: 'Rto/CheckPeriodicitaSML',
                                                                method: 'POST',
                                                                params: {
                                                                    idePer: legameSelezionato,
                                                                    ideTipo: 2
                                                                },
                                                                callback: function (records, success, operation) {
                                                                    var returnCheck = Ext.decode(operation.responseText);
                                                                    if (returnCheck.length > 0) {
                                                                        Ext.Msg.alert('Attenzione', returnCheck);
                                                                        aggiornaStateCheck(false);
                                                                    }
                                                                    else {
                                                                        aggiornaStateCheck(true);
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Modifica periodicita legame fallita', 'Modifica periodicita legame fallita');
                                                        setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                    }

                                                }
                                            });
                                        }
                                        else {
                                            Ext.Msg.alert('Dettaglio Giorni', 'Scegliere almeno un giorno')
                                        }


                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        });

        dialog.show();
    }

    function onAggiungiLegameIIClick(grid, info) {
        caricamentoLocalitaDisponibili().then(function () {
            var store = grid.getStore();
            var record = Ext.create(legamiLocModel, {});
            record.data.altraIF = 0;
            record.data.pTL_IPR = "";
            record.data.nOM_TRE = "";
            store.insert(info.cell.row._recordIndex + 1, record);
            grid.getPlugin('rowedit').startEdit(record, 1);
        });
    }



    const caricaLegamiImpianto = () => {
        Ext.Ajax.request({
            url: 'Rto/LeggiLegamiLocalita',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                inEdit: props.inEdit
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                storeLegamiImpianto.loadData(returnObj);
                Ext.ComponentQuery.query('#gridLegamiImpianto')[0].setStore(storeLegamiImpianto);
            }
        });
    }

    if (stateLoadMask.ricarica === true)
        caricaLegamiImpianto();

    function onEliminaLegamiIIClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del legame?", function (button) {

            if (button === 'yes') {
                if (info.record.data.legamE_IMPOSTO === "Si") {
                    Ext.toast({ message: 'Impossibile eliminare legame imposto', timeout: 4000 });
                }
                else {
                    setStateLoadMask({ hidden: false, message: "Eliminazione legame in corso...", ricarica: false });
                    var legamiIIByDB = null;
                    Ext.Ajax.request({
                        url: 'Rto/LeggiLegamiLocalita',
                        method: 'GET',
                        params: {
                            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                            inEdit: props.inEdit
                        },
                        callback: function (resp, success, operation) {
                            legamiIIByDB = Ext.decode(operation.responseText);
                            if (legamiIIByDB.find(per => per.idE_LGM_LOC === info.record.data.idE_LGM_LOC) !== undefined) {
                                Ext.Ajax.request({
                                    url: 'Rto/CancellaImpegnoImpiantoLegami',
                                    method: 'POST',
                                    params: {
                                        ideUte: user.ideUte,
                                        ideLegame: info.record.data.idE_LGM_LOC,
                                        idePerc: arrayLocalitaDisponibili.find(x => x.ideLoc === info.record.data.idE_LOC).idePrc,
                                        minPass: info.record.data.miN_SOSTA,
                                        nomTre: nomTreApp,
                                        protocolloIF: protocolloIFApp,
                                        tempMax: info.record.data.maX_SOSTA === undefined ? "" : info.record.data.maX_SOSTA,
                                        flagAltraIF: info.record.data.altraIF,
                                        nota: info.record.data.nota === undefined ? "" : info.record.data.nota
                                    },
                                    callback: function (records, success, operation) {
                                        if (success) {
                                            var returnObj = Ext.decode(operation.responseText);
                                            //modifica gestione errore
                                            if (returnObj.resp == "KO") {
                                                setStateLoadMask({ hidden: true, message: "Eliminazione legame in corso...", ricarica: false });
                                                Ext.Msg.alert('Elimina legame fallito', returnObj.msgErr)
                                            }
                                            else {
                                                setStateLoadMask({ hidden: true, message: "Eliminazione legame in corso...", ricarica: true });
                                                Ext.toast({ message: 'Legame eliminato', timeout: 4000 });
                                                caricaPercorso();
                                                aggiornaControllo(true);
                                                Ext.Ajax.request({
                                                    url: 'Rto/CheckPeriodicitaImpegnoImpianto',
                                                    method: 'POST',
                                                    params: {
                                                        ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                                                    },
                                                    callback: function (records, success, operation) {
                                                        var returnCheck = Ext.decode(operation.responseText);
                                                        if (returnCheck.length > 0) {
                                                            aggiornaStateCheck(false);
                                                        }
                                                        else {
                                                            aggiornaStateCheck(true);
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                        else {
                                            setStateLoadMask({ hidden: true, message: "Eliminazione legame in corso...", ricarica: false });
                                            Ext.Msg.alert('Elimina legame fallito', 'Elimina legame fallito');
                                        }
                                    }
                                });
                            }
                            else {
                                Ext.ComponentQuery.query('#gridLegamiImpianto')[0].getStore().remove(info.record);
                                setStateLoadMask({ hidden: true, message: "Eliminazione legame in corso...", ricarica: true });
                                Ext.toast({ message: 'Legame eliminato', timeout: 4000 });
                            }
                        }
                    })
                }

            }
        })

    }

    function onApriRTOClick(grid, info) {
        window.open("/SchedaRtoLettura?ideRto=" + info.record.data.idE_RTO_LEG + "&ideCli=" + user.ideCli, '_blank');
    }

    function onAssociaRto(grid, info) {

        let ideRTOApp = rtoSchede.data.items[0].data.rto.ideRTO;
        let idLocApp = info.record.data.idE_LOC;
        var dialog = Ext.create({
            xtype: 'dialog',
            title: 'Cerca',
            itemId: 'dialogCercaNumeroTreno',
            layout: 'fit',
            closable: "true",
            modal: true,
            width: 800,
            items: [
                {
                    xtype: 'panel',
                    layout: "vbox",
                    margin: "0 0 0 5",
                    items: [

                        {
                            xtype: 'panel',
                            layout: "hbox",
                            margin: "0 0 0 5",
                            items: [{
                                xtype: 'textfield',
                                flex: 1,
                                itemId: "txtNumTrenoRFILegami",
                                margin: "5 5 5 5",
                                label: "Numero Treno RFI",
                                listeners: {
                                    change: function (textfield, newValue) {
                                        if (newValue.length >= 2) {
                                            Ext.Ajax.request({
                                                url: 'Rto/GetElencoTreniProtocolliLegami',
                                                method: 'GET',
                                                params: {
                                                    ideRTO: ideRTOApp,
                                                    numeroTreno: newValue,
                                                    protocollo: Ext.ComponentQuery.query('#txtProtocIF')[0]._value,
                                                    idLoc: idLocApp
                                                },
                                                callback: function (records, success, operation) {
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    numeriTrenoStore.clearData();
                                                    numeriTrenoStore.removeAll();
                                                    numeriTrenoStore.loadData(returnObj);
                                                    Ext.ComponentQuery.query('#gridNumeriTreno')[0].setStore(numeriTrenoStore);

                                                }
                                            });
                                        }
                                    }
                                }
                            },
                            {
                                xtype: 'textfield',
                                flex: 1,
                                itemId: "txtProtocIF",
                                margin: "5 5 5 5",
                                label: "Protocollo IF",
                                listeners: {
                                    change: function (textfield, newValue) {
                                        if (newValue.length >= 2) {
                                            Ext.Ajax.request({
                                                url: 'Rto/GetElencoTreniProtocolliLegami',
                                                method: 'GET',
                                                params: {
                                                    ideRTO: ideRTOApp,
                                                    numeroTreno: Ext.ComponentQuery.query('#txtNumTrenoRFILegami')[0]._value,
                                                    protocollo: newValue,
                                                    idLoc: idLocApp
                                                },
                                                callback: function (records, success, operation) {
                                                    var returnObj = Ext.decode(operation.responseText);
                                                    numeriTrenoStore.clearData();
                                                    numeriTrenoStore.removeAll();
                                                    numeriTrenoStore.loadData(returnObj);
                                                    Ext.ComponentQuery.query('#gridNumeriTreno')[0].setStore(numeriTrenoStore);
                                                }
                                            });
                                        }
                                    }

                                }
                            }
                            ]
                        },
                        {

                            xtype: 'grid',
                            itemId: "gridNumeriTreno",
                            markDirty: true,
                            selectable: { checkbox: true, mode: 'single', rows: true },
                            store: { numeriTrenoStore },
                            layout: "fit",
                            minHeight: '200',
                            listeners: {
                                selectionchange: function (grid, record, item, index, e, eOpts) {
                                    Ext.ComponentQuery.query('#btnAssociaNum')[0].enable();
                                }
                            },
                            height: '200',
                            margin: "0 0 0 5",
                            scrollable: true,
                            selType: 'rowmodel',
                            selModel: {
                                mode: 'SINGLE' // Imposta la modalità di selezione su 'SINGLE' per una sola riga selezionabile alla volta
                            },

                            columns: [
                                {
                                    text: "N. Treno RFI",
                                    flex: 1,
                                    dataIndex: "noM_TRE_RFI",
                                    filter: 'string',
                                },
                                {
                                    text: "Protocollo IF",
                                    flex: 1,
                                    dataIndex: "ptL_IPR",
                                    filter: 'string',
                                }
                            ]
                        },
                        {
                            xtype: 'panel',
                            layout: "hbox",
                            margin: "0 0 0 5",
                            items: [{
                                xtype: 'button',
                                flex: 1,
                                itemId: "btnAssociaNum",
                                margin: "5 5 5 5",
                                cls : "button-height appButton",
                                disabled: true,
                                text: "Conferma", handler: function () {
                                    let nomtrerfi = Ext.ComponentQuery.query('#gridNumeriTreno')[0].getSelection().data.noM_TRE_RFI;
                                    let protIF = Ext.ComponentQuery.query('#gridNumeriTreno')[0].getSelection().data.ptL_IPR;
                                    nomTreApp = nomtrerfi;
                                    protocolloIFApp = protIF;
                                    Ext.ComponentQuery.query('#numTreRTO')[0].setValue(nomtrerfi + " / " + protIF);
                                    Ext.ComponentQuery.query('#dialogCercaNumeroTreno')[0].destroy();
                                    setStateLoadMask({ hidden: false, message: "Salvataggio in corso...", ricarica: false });
                                    Ext.Ajax.request({
                                        url: 'Rto/ModificaImpegnoImpiantoLegami',
                                        method: 'POST',
                                        params: {
                                            ideUte: user.ideUte,
                                            ideLegame: info.record.data.idE_LGM_LOC,
                                            idePerc: arrayLocalitaDisponibili.find(x => x.ideLoc === info.record.data.idE_LOC).idePrc,
                                            minPass: info.record.data.miN_SOSTA,
                                            nomTre: nomTreApp,
                                            protocolloIF: protocolloIFApp,
                                            tempMax: info.record.data.maX_SOSTA === undefined ? "" : info.record.data.maX_SOSTA,
                                            flagAltraIF: info.record.data.altraIF,
                                            nota: info.record.data.nota === undefined ? "" : info.record.data.nota,
                                            ideRto: rtoSchede.data.items[0].data.rto.ideRTO,
                                            ideLoc: arrayLocalitaDisponibili.find(x => x.ideLoc === info.record.data.idE_LOC).ideLoc
                                        },
                                        callback: function (records, success, operation) {
                                            if (success) {
                                                var returnObj = Ext.decode(operation.responseText);
                                                //modifica gestione errore
                                                if (returnObj.resp === "KO") {
                                                    setStateLoadMask({ hidden: true, message: "Salvataggio in corso...", ricarica: false });
                                                    Ext.Msg.alert('Modifica legame fallita', returnObj.msgErr);
                                                }
                                                else {
                                                    setStateLoadMask({ hidden: true, message: "Salvataggio in corso...", ricarica: true });
                                                    Ext.toast({ message: 'Legame modificato', timeout: 4000 });
                                                    aggiornaControllo(true);
                                                    props.aggiornaStateModifica(true);
                                                }
                                            }
                                            else {
                                                Ext.Msg.alert('Modifica Legame fallita', 'Modifica legame fallita');
                                                setStateLoadMask({ hidden: true, message: "Eliminazione legame in corso...", ricarica: false });
                                            }

                                        }
                                    });
                                }
                            },
                                {
                                    xtype: 'button',
                                    flex: 1,
                                    cls : "button-height appButton",
                                    itemId: "btnAnnullaMod",
                                    margin: "5 5 5 5",
                                    text: "Annulla",
                                    handler: function () {
                                        Ext.ComponentQuery.query('#checkAltraIF')[0].setChecked(true);
                                        Ext.ComponentQuery.query('#dialogCercaNumeroTreno')[0].destroy();
                                    }
                                }

                            ]
                        },
                    ]
                }
            ]
        });

        dialog.show();
    }

    function handleCollapse(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovoLegame')[0].setHidden(true);
        Ext.ComponentQuery.query('#containerLegamiII')[0].setCls("container-spinner");
    };

    function handleExpand(panel, direction, eOpts) {
        if (props.inEdit === true)
            Ext.ComponentQuery.query('#nuovoLegame')[0].setHidden(false);
        Ext.ComponentQuery.query('#containerLegamiII')[0].setCls("container-spinner PnlSectionL2B");
    };

    return (
        <Container itemId="containerLegamiII" cls="container-spinner" margin='2px 0px 2px 0px' border={true} cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
        <Panel
            title="Legami Impianto"
            titleCollapse={true}
            iconCls="x-fa fa-link"
            collapsible={{ collapsed: true, direction: 'top' }}
            onCollapse={handleCollapse}
            onExpand={handleExpand}
            cls='PnlSectionL2 iconPanelsRTO'
            tools={[
                {
                    iconCls: 'x-fa x-fa fa-plus', tooltip: 'Nuovo', hidden: "true", itemId:"nuovoLegame", handler: onNuovoLegameImpiantoClick.bind(this)
                }
                /* { type: 'menu', handler: toolHandler }*/
            ]}
        >
            <Container
                layout="vbox" fitToParent={true} cls="column-in-panel"
            >
                <Grid
                        itemId="gridLegamiImpianto"
                        minHeight={100}
                        maxHeight={200}
                        markDirty={true}
                        disabled={isCalendarioVisible}
                        flex={1}
                        scrollable
                        selectable={{ checkbox: false, mode: 'single' }}
                        store={storeLegamiImpianto}
                        plugins={{
                            gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" },
                        }}
                        itemConfig={{
                            viewModel: true
                        }}
                        listeners={{
                            beforeedit: function (editor, context, eOpts) {
                                if (!props.inEdit || context.record.data.legamE_IMPOSTO === "Si")
                                    return false;
                                else {
                                    Ext.ComponentQuery.query('#gridLegamiImpianto')[0].setMinHeight(Ext.ComponentQuery.query('#gridLegamiImpianto')[0]._minHeight + 50);
                                    if (context.source.type === 'doubletap')
                                        caricamentoLocalitaDisponibili().then(function () {
                                            Ext.ComponentQuery.query('#cmbLocalitaLegamiII')[0].setValue(arrayLocalitaDisponibili.find(x => x.idePrc === context.record.data.idE_PRC_RTO).value);
                                            return true;
                                        })
                                    else
                                        return true;
                                }
                            },
                            edit: function (grid, location) {
                                Ext.ComponentQuery.query('#gridLegamiImpianto')[0].setMinHeight(Ext.ComponentQuery.query('#gridLegamiImpianto')[0]._minHeight - 50);
                                if (location.record.data.maX_SOSTA !== undefined && location.record.data.maX_SOSTA !== "" && location.record.data.maX_SOSTA !== null) {
                                    if (location.record.data.maX_SOSTA < location.record.data.miN_SOSTA) {
                                        Ext.toast({ message: 'Il tempo massimo sosta deve essere maggiore o uguale del tempo minimo', timeout: 4000 });
                                        return false;
                                    }
                                }
                                setStateLoadMask({ hidden: false, message: "Salvataggio in corso...", ricarica: false });
                                var legamiIIByDB = null;
                                Ext.Ajax.request({
                                    url: 'Rto/LeggiLegamiLocalita',
                                    method: 'GET',
                                    params: {
                                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                                        inEdit: props.inEdit
                                    },
                                    callback: function (resp, success, operation) {
                                        const idLegameII = location.record.data.idE_LGM_LOC;
                                        legamiIIByDB = Ext.decode(operation.responseText);

                                        if (legamiIIByDB.find(per => per.idE_LGM_LOC === idLegameII) == null) {
                                            Ext.Ajax.request({
                                                url: 'Rto/InserisciImpegnoImpiantoLegami',
                                                method: 'POST',
                                                params: {
                                                    ideUte: user.ideUte,
                                                    idePerc: arrayLocalitaDisponibili.find(x => x.value === location.record.data.idE_LOC).idePrc,
                                                    minPass: location.record.data.miN_SOSTA,
                                                    nomTre: nomTreApp,
                                                    protocolloIF: protocolloIFApp,
                                                    tempMax: location.record.data.maX_SOSTA === undefined ? "" : location.record.data.maX_SOSTA,
                                                    flagAltraIF: location.record.data.altraIF,
                                                    nota: location.record.data.nota === undefined ? "" : location.record.data.nota,
                                                    ideRto: rtoSchede.data.items[0].data.rto.ideRTO,
                                                    ideLoc: arrayLocalitaDisponibili.find(x => x.value === location.record.data.idE_LOC).ideLoc
                                                },
                                                callback: function (records, success, operation) {
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp == "KO") {
                                                            setStateLoadMask({ hidden: true, message: "Salvataggio in corso...", ricarica: true });
                                                            Ext.Msg.alert('Inserimento legame fallito', returnObj.msgErr)
                                                        }
                                                        else {
                                                            setStateLoadMask({ hidden: true, message: "Salvataggio in corso...", ricarica: true });
                                                            Ext.toast({ message: 'Legame creato', timeout: 4000 });
                                                            caricaPercorso();
                                                            aggiornaControllo(true);
                                                            props.aggiornaStateModifica(true);
                                                        }
                                                    }
                                                    else {
                                                        setStateLoadMask({ hidden: true, message: "Eliminazione legame in corso...", ricarica: false });
                                                        Ext.Msg.alert('Inserimento legame fallito', 'Inserimento legame fallito');
                                                    }
                                                }
                                            })
                                        }
                                        else {
                                            Ext.Ajax.request({
                                                url: 'Rto/ModificaImpegnoImpiantoLegami',
                                                method: 'POST',
                                                params: {
                                                    ideUte: user.ideUte,
                                                    ideLegame: location.record.data.idE_LGM_LOC,
                                                    idePerc: arrayLocalitaDisponibili.find(x => x.value === location.record.data.idE_LOC).idePrc,
                                                    minPass: location.record.data.miN_SOSTA,
                                                    nomTre: (location.record.data.altraIF === 0 && nomTreApp === "") ? location.record.data.noM_TRE : nomTreApp,
                                                    protocolloIF: (location.record.data.altraIF === 0 && protocolloIFApp === "") ? location.record.data.ptL_IPR : protocolloIFApp, 
                                                    tempMax: location.record.data.maX_SOSTA === undefined ? "" : location.record.data.maX_SOSTA,
                                                    flagAltraIF: location.record.data.altraIF,
                                                    nota: location.record.data.nota === undefined ? "" : location.record.data.nota,
                                                    ideRto: rtoSchede.data.items[0].data.rto.ideRTO,
                                                    ideLoc: arrayLocalitaDisponibili.find(x => x.value === location.record.data.idE_LOC).ideLoc
                                                },
                                                callback: function (records, success, operation) {
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp === "KO") {
                                                            setStateLoadMask({ hidden: true, message: "Salvataggio in corso...", ricarica: true });
                                                            Ext.Msg.alert('Modifica legame fallita', returnObj.msgErr);
                                                            location.record.reject();
                                                        }
                                                        else {
                                                            setStateLoadMask({ hidden: true, message: "Salvataggio in corso...", ricarica: true });
                                                            Ext.toast({ message: 'Legame modificato', timeout: 4000 });
                                                            caricaPercorso();
                                                            aggiornaControllo(true);
                                                            props.aggiornaStateModifica(true);
                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Modifica Legame fallita', 'Modifica legame fallita');
                                                        location.record.reject();
                                                        setStateLoadMask({ hidden: true, message: "Eliminazione legame in corso...", ricarica: false });
                                                    }

                                                }
                                            });
                                        }
                                    }
                                })

                            },
                            canceledit: function (editor, context) {
                                setStateLoadMask({ hidden: true, message: "Eliminazione legame in corso...", ricarica: true });

                            }
                        }}
                    >
                        <Column itemId='actionRTOIILegami' editable={false} text="Azioni" flex={1}
                            cell={{

                                tools: {
                                    aggiungiRTOIILEGAMI: {

                                        handler: onAggiungiLegameIIClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-plus",

                                            tooltip: 'Aggiungi',

                                            hidden: '{record.edit === false || record.legamE_IMPOSTO === "Si"}'
                                        }

                                    },

                                    modificaRTOIILEGAMI: {

                                        handler: onModificaLegameIIClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-edit",

                                            tooltip: 'Modifica',

                                            hidden: '{record.edit === false || record.legamE_IMPOSTO === "Si"}'
                                        }

                                    },

                                    eliminaRTOIILEGAMI: {

                                        handler: onEliminaLegamiIIClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-trash",

                                            tooltip: 'Elimina',

                                            hidden: '{record.edit === false || record.legamE_IMPOSTO === "Si"}'
                                        }

                                    }



                                }

                            }}
                        />
                        <Column flex={1} text="Localita" dataIndex="idE_LOC" renderer={function (value, metaData, records, view) {
                            let row = view.row;
                            row.setStyle("background:#ffffff;");  
                            if (metaData.data.legamE_IMPOSTO === 'Si') {
                                row.setStyle("background:#f8f8f8;");
                            }
                            return metaData.data.noM_LOC;
                        }} editor={{
                            xtype: 'combobox', itemId: "cmbLocalitaLegamiII", triggerAction: 'all', mode: 'local', options: arrayLocalitaDisponibili, allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true,
                            listeners:
                            {
                                change: function (gridView, value, columnIndex) {
                                    Ext.defer(function () {
                                    if (value !== null && value !== undefined && value !== "") {
                                        Ext.ComponentQuery.query('#tempMinSosta')[0].enable();
                                        Ext.ComponentQuery.query('#numTreRTO')[0].disable();
                                        Ext.ComponentQuery.query('#tempMaxProseg')[0].enable();
                                        if (Ext.ComponentQuery.query('#checkAltraIF')[0]._checked === true)
                                            Ext.ComponentQuery.query('#notaAltraIF')[0].enable();
                                        else {
                                            Ext.ComponentQuery.query('#notaAltraIF')[0].disable();
                                            Ext.ComponentQuery.query('#notaAltraIF')[0].setValue("");
                                            if (Ext.ComponentQuery.query('#numTreRTO')[0]._value === "") {
                                                let ideRTOApp = rtoSchede.data.items[0].data.rto.ideRTO;
                                                let idLocApp = arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbLocalitaLegamiII')[0]._value).ideLoc;
                                                var dialog = Ext.create({
                                                    xtype: 'dialog',
                                                    title: 'Cerca',
                                                    itemId: 'dialogCercaNumeroTreno',
                                                    layout: 'fit',
                                                    closable: "true",
                                                    modal: true,
                                                    width: 800,
                                                    items: [
                                                        {
                                                            xtype: 'panel',
                                                            layout: "vbox",
                                                            margin: "0 0 0 5",
                                                            items: [

                                                                {
                                                                    xtype: 'panel',
                                                                    layout: "hbox",
                                                                    margin: "0 0 0 5",
                                                                    items: [{
                                                                        xtype: 'textfield',
                                                                        flex: 1,
                                                                        itemId: "txtNumTrenoRFILegami",
                                                                        margin: "5 5 5 5",
                                                                        label: "Numero Treno RFI",
                                                                        listeners: {
                                                                            change: function (textfield, newValue) {
                                                                                if (newValue.length >= 2) {
                                                                                    Ext.Ajax.request({
                                                                                        url: 'Rto/GetElencoTreniProtocolliLegami',
                                                                                        method: 'GET',
                                                                                        params: {
                                                                                            ideRTO: ideRTOApp,
                                                                                            numeroTreno: newValue,
                                                                                            protocollo: Ext.ComponentQuery.query('#txtProtocIF')[0]._value,
                                                                                            idLoc: idLocApp
                                                                                        },
                                                                                        callback: function (records, success, operation) {
                                                                                            var returnObj = Ext.decode(operation.responseText);
                                                                                            numeriTrenoStore.clearData();
                                                                                            numeriTrenoStore.removeAll();
                                                                                            numeriTrenoStore.loadData(returnObj);
                                                                                            Ext.ComponentQuery.query('#gridNumeriTreno')[0].setStore(numeriTrenoStore);

                                                                                        }
                                                                                    });
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        xtype: 'textfield',
                                                                        flex: 1,
                                                                        itemId: "txtProtocIF",
                                                                        margin: "5 5 5 5",
                                                                        label: "Protocollo IF",
                                                                        listeners: {
                                                                            change: function (textfield, newValue) {
                                                                                if (newValue.length >= 2) {
                                                                                    Ext.Ajax.request({
                                                                                        url: 'Rto/GetElencoTreniProtocolliLegami',
                                                                                        method: 'GET',
                                                                                        params: {
                                                                                            ideRTO: ideRTOApp,
                                                                                            numeroTreno: Ext.ComponentQuery.query('#txtNumTrenoRFILegami')[0]._value,
                                                                                            protocollo: newValue,
                                                                                            idLoc: idLocApp
                                                                                        },
                                                                                        callback: function (records, success, operation) {
                                                                                            var returnObj = Ext.decode(operation.responseText);
                                                                                            numeriTrenoStore.clearData();
                                                                                            numeriTrenoStore.removeAll();
                                                                                            numeriTrenoStore.loadData(returnObj);
                                                                                            Ext.ComponentQuery.query('#gridNumeriTreno')[0].setStore(numeriTrenoStore);
                                                                                        }
                                                                                    });
                                                                                }
                                                                            }

                                                                        }
                                                                    }
                                                                    ]
                                                                },
                                                                {

                                                                    xtype: 'grid',
                                                                    itemId: "gridNumeriTreno",
                                                                    markDirty: true,
                                                                    selectable: { checkbox: true, mode: 'single', rows: true },
                                                                    store: { numeriTrenoStore },
                                                                    layout: "fit",
                                                                    minHeight: '200',
                                                                    listeners: {
                                                                        selectionchange: function (grid, record, item, index, e, eOpts) {
                                                                            Ext.ComponentQuery.query('#btnAssociaNum')[0].enable();
                                                                        }
                                                                    },
                                                                    height: '200',
                                                                    margin: "0 0 0 5",
                                                                    scrollable: true,
                                                                    selType: 'rowmodel',
                                                                    selModel: {
                                                                        mode: 'SINGLE' // Imposta la modalità di selezione su 'SINGLE' per una sola riga selezionabile alla volta
                                                                    },

                                                                    columns: [
                                                                        {
                                                                            text: "N. Treno RFI",
                                                                            flex: 1,
                                                                            dataIndex: "noM_TRE_RFI",
                                                                            filter: 'string',
                                                                        },
                                                                        {
                                                                            text: "Protocollo IF",
                                                                            flex: 1,
                                                                            dataIndex: "ptL_IPR",
                                                                            filter: 'string',
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    xtype: 'panel',
                                                                    layout: "hbox",
                                                                    margin: "0 0 0 5",
                                                                    items: [{
                                                                        xtype: 'button',
                                                                        flex: 1,
                                                                        itemId: "btnAssociaNum",
                                                                        cls : "button-height appButton",
                                                                        margin: "5 5 5 5",
                                                                        disabled: true,
                                                                        text: "Conferma", handler: function () {
                                                                            let nomtrerfi = Ext.ComponentQuery.query('#gridNumeriTreno')[0].getSelection().data.noM_TRE_RFI;
                                                                            let protIF = Ext.ComponentQuery.query('#gridNumeriTreno')[0].getSelection().data.ptL_IPR;
                                                                            nomTreApp = nomtrerfi;
                                                                            protocolloIFApp = protIF;
                                                                            Ext.ComponentQuery.query('#numTreRTO')[0].setValue(nomtrerfi + " / " + protIF);
                                                                            Ext.ComponentQuery.query('#dialogCercaNumeroTreno')[0].destroy();
                                                                        }
                                                                    },
                                                                    {
                                                                        xtype: 'button',
                                                                        flex: 1,
                                                                        itemId: "btnAnnullaNum",
                                                                        cls : "button-height appButton",
                                                                        margin: "5 5 5 5",
                                                                        text: "Legame con altra IF",
                                                                        handler: function () {
                                                                            Ext.ComponentQuery.query('#checkAltraIF')[0].setChecked(true);
                                                                            Ext.ComponentQuery.query('#dialogCercaNumeroTreno')[0].destroy();
                                                                        }
                                                                        },
                                                                        {
                                                                            xtype: 'button',
                                                                            cls : "button-height appButton",
                                                                            flex: 1,
                                                                            itemId: "btnAnnullaMod",
                                                                            margin: "5 5 5 5",
                                                                            text: "Annulla",
                                                                            handler: function () {
                                                                                Ext.ComponentQuery.query('#checkAltraIF')[0].setChecked(true);
                                                                                Ext.ComponentQuery.query('#dialogCercaNumeroTreno')[0].destroy();
                                                                            }
                                                                        }

                                                                    ]
                                                                },
                                                            ]
                                                        }
                                                    ]
                                                });

                                                dialog.show();
                                            }
                                        }
                                    }
                                    else {
                                        Ext.ComponentQuery.query('#tempMinSosta')[0].disable();
                                        Ext.ComponentQuery.query('#numTreRTO')[0].disable();
                                        Ext.ComponentQuery.query('#tempMaxProseg')[0].disable();
                                        Ext.ComponentQuery.query('#notaAltraIF')[0].disable();
                                        }
                                    }, 100);
                                }
                            }

                        }} filter='string' />
                    <Column text="Tempo min Sosta" dataIndex="miN_SOSTA" filter='string' flex={1} editor={{
                            xtype: 'numberfield', triggerAction: 'all', itemId: 'tempMinSosta', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true

                        }} />

                        <Column text="N.Treno / RTO" dataIndex="nuM_TRENO" filter='string' flex={1} editor={{
                            xtype: 'textfield', itemId: 'numTreRTO'

                        }} />
                        <Column itemId='apriRtoLegame' flex={1} align="center"
                            cell={{

                                tools: {

                                    apriRTO: {
                                        handler: onApriRTOClick.bind(this),
                                        bind: {
                                            iconCls: "x-fa fa-folder-open buttonGridTool",
                                            tooltip: 'Apri RTO Legata',
                                            hidden: '{record.idE_RTO_LEG === "" || record.idE_RTO_LEG === null || record.idE_RTO_LEG === 0}',
                                        }

                                    },
                                    ricercaRto: {
                                        handler: onAssociaRto.bind(this),
                                        bind: {
                                            iconCls: "x-fa fa-edit buttonMerciTool",
                                            tooltip: 'Cerca RTO',
                                            hidden: '{record.altraIF === 1 || record.edit === false || record.legamE_IMPOSTO === "Si"}'
                                        }
                                    }


                                }

                            }}

                        />
                    <Column text="Legame Indotto" dataIndex="legamE_IMPOSTO" filter='string' flex={1} />
                    <Column text="Tempo max proseg." dataIndex="maX_SOSTA" filter='string' flex={1} editor={{
                            xtype: 'textfield', itemId: 'tempMaxProseg', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true

                    }} />
                        <Column flex={1} text='Altra IF' dataIndex='altraIF' renderer={function (value, metaData, records, view) {
                            return "";
                        }} editor={{
                            xtype: 'checkbox',
                            itemId: 'checkAltraIF',
                            listeners: {
                                change: function (checkbox, checked) {
                                    if (!checked && Ext.ComponentQuery.query('#cmbLocalitaLegamiII')[0]._value !== "" && Ext.ComponentQuery.query('#cmbLocalitaLegamiII')[0]._value !== null && Ext.ComponentQuery.query('#cmbLocalitaLegamiII')[0]._value !== undefined && Ext.ComponentQuery.query('#numTreRTO')[0]._value === "") {
                                        Ext.ComponentQuery.query('#notaAltraIF')[0].disable();
                                        Ext.ComponentQuery.query('#notaAltraIF')[0].setValue("");
                                        let ideRTOApp = rtoSchede.data.items[0].data.rto.ideRTO;
                                        let idLocApp = arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbLocalitaLegamiII')[0]._value).ideLoc;
                                        var dialog = Ext.create({
                                            xtype: 'dialog',
                                            title: 'Cerca',
                                            itemId: 'dialogCercaNumeroTreno',
                                            layout: 'fit',
                                            closable: "true",
                                            modal: true,
                                            width: 800,
                                            items: [
                                                {
                                                    xtype: 'panel',
                                                    layout: "vbox",
                                                    margin: "0 0 0 5",
                                                    items: [

                                                        {
                                                            xtype: 'panel',
                                                            layout: "hbox",
                                                            margin: "0 0 0 5",
                                                            items: [{
                                                                xtype: 'textfield',
                                                                flex: 1,
                                                                itemId: "txtNumTrenoRFILegami",
                                                                margin: "5 5 5 5",
                                                                label: "Numero Treno RFI",
                                                                listeners: {
                                                                    change: function (textfield, newValue) {
                                                                        if (newValue.length >= 2) {
                                                                            Ext.Ajax.request({
                                                                                url: 'Rto/GetElencoTreniProtocolliLegami',
                                                                                method: 'GET',
                                                                                params: {
                                                                                    ideRTO: ideRTOApp,
                                                                                    numeroTreno: newValue,
                                                                                    protocollo: Ext.ComponentQuery.query('#txtProtocIF')[0]._value,
                                                                                    idLoc: idLocApp
                                                                                },
                                                                                callback: function (records, success, operation) {
                                                                                    var returnObj = Ext.decode(operation.responseText);
                                                                                    numeriTrenoStore.clearData();
                                                                                    numeriTrenoStore.removeAll();
                                                                                    numeriTrenoStore.loadData(returnObj);
                                                                                    Ext.ComponentQuery.query('#gridNumeriTreno')[0].setStore(numeriTrenoStore);

                                                                                }
                                                                            });
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                xtype: 'textfield',
                                                                flex: 1,
                                                                itemId: "txtProtocIF",
                                                                margin: "5 5 5 5",
                                                                label: "Protocollo IF",
                                                                listeners: {
                                                                    change: function (textfield, newValue) {
                                                                        if (newValue.length >= 2) {
                                                                            Ext.Ajax.request({
                                                                                url: 'Rto/GetElencoTreniProtocolliLegami',
                                                                                method: 'GET',
                                                                                params: {
                                                                                    ideRTO: ideRTOApp,
                                                                                    numeroTreno: Ext.ComponentQuery.query('#txtNumTrenoRFILegami')[0]._value,
                                                                                    protocollo: newValue,
                                                                                    idLoc: idLocApp
                                                                                },
                                                                                callback: function (records, success, operation) {
                                                                                    var returnObj = Ext.decode(operation.responseText);
                                                                                    numeriTrenoStore.clearData();
                                                                                    numeriTrenoStore.removeAll();
                                                                                    numeriTrenoStore.loadData(returnObj);
                                                                                    Ext.ComponentQuery.query('#gridNumeriTreno')[0].setStore(numeriTrenoStore);
                                                                                }
                                                                            });
                                                                        }
                                                                    }

                                                                }
                                                            }
                                                            ]
                                                        },
                                                        {

                                                            xtype: 'grid',
                                                            itemId: "gridNumeriTreno",
                                                            markDirty: true,
                                                            selectable: { checkbox: true, mode: 'single', rows: true },
                                                            store: { numeriTrenoStore },
                                                            layout: "fit",
                                                            minHeight: '200',
                                                            listeners: {
                                                                selectionchange: function (grid, record, item, index, e, eOpts) {
                                                                    Ext.ComponentQuery.query('#btnAssociaNum')[0].enable();
                                                                }
                                                            },
                                                            height: '200',
                                                            margin: "0 0 0 5",
                                                            scrollable: true,
                                                            selType: 'rowmodel',
                                                            selModel: {
                                                                mode: 'SINGLE' // Imposta la modalità di selezione su 'SINGLE' per una sola riga selezionabile alla volta
                                                            },

                                                            columns: [
                                                                {
                                                                    text: "N. Treno RFI",
                                                                    flex: 1,
                                                                    dataIndex: "noM_TRE_RFI",
                                                                    filter: 'string',
                                                                },
                                                                {
                                                                    text: "Protocollo IF",
                                                                    flex: 1,
                                                                    dataIndex: "ptL_IPR",
                                                                    filter: 'string',
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            xtype: 'panel',
                                                            layout: "hbox",
                                                            margin: "0 0 0 5",
                                                            items: [{
                                                                xtype: 'button',
                                                                flex: 1,
                                                                itemId: "btnAssociaNum",
                                                                cls : "button-height appButton",
                                                                margin: "5 5 5 5",
                                                                disabled: true,
                                                                text: "Conferma", handler: function () {
                                                                    let nomtrerfi = Ext.ComponentQuery.query('#gridNumeriTreno')[0].getSelection().data.noM_TRE_RFI;
                                                                    let protIF = Ext.ComponentQuery.query('#gridNumeriTreno')[0].getSelection().data.ptL_IPR;
                                                                    nomTreApp = nomtrerfi;
                                                                    protocolloIFApp = protIF;
                                                                    Ext.ComponentQuery.query('#numTreRTO')[0].setValue(nomtrerfi + " / " + protIF);
                                                                    Ext.ComponentQuery.query('#dialogCercaNumeroTreno')[0].destroy();
                                                                }
                                                            },
                                                                {
                                                                    xtype: 'button',
                                                                    flex: 1,
                                                                    itemId: "btnAnnullaNum",
                                                                    cls : "button-height appButton",
                                                                    margin: "5 5 5 5",
                                                                    text: "Legame con altra IF",
                                                                    handler: function () {
                                                                        Ext.ComponentQuery.query('#checkAltraIF')[0].setChecked(true);
                                                                        Ext.ComponentQuery.query('#dialogCercaNumeroTreno')[0].destroy();
                                                                    }
                                                                },
                                                                {
                                                                    xtype: 'button',
                                                                    flex: 1,
                                                                    itemId: "btnAnnullaMod",
                                                                    cls : "button-height appButton",
                                                                    margin: "5 5 5 5",
                                                                    text: "Annulla",
                                                                    handler: function () {
                                                                        Ext.ComponentQuery.query('#checkAltraIF')[0].setChecked(true);
                                                                        Ext.ComponentQuery.query('#dialogCercaNumeroTreno')[0].destroy();
                                                                    }
                                                                }

                                                            ]
                                                        },
                                                    ]
                                                }
                                            ]
                                        });

                                        dialog.show();
                                    }
                                    else {
                                        nomTreApp = "";
                                        protocolloIFApp = "";
                                        Ext.ComponentQuery.query('#numTreRTO')[0].setValue("");
                                        Ext.ComponentQuery.query('#notaAltraIF')[0].enable();
                                    }
                                }
                            }
                        }}
                            cell={{

                                tools: {
                                    prova: {

                                        bind: {

                                            iconCls: "x-fa fa-check",

                                            hidden: '{!(record.altraIF)}',

                                            tooltip: 'Altra IF'
                                        }

                                    }

                                }

                            }}
                        />
                    <Column text="Note Altra IF" dataIndex="nota" filter='string' flex={1} editor={{
                            xtype: 'textfield', itemId: 'notaAltraIF', allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true

                    }} />
                        <Column text="Periodicit&aacute" renderer={function (value, metaData, record, rowIndex, colIndex, store, view) {
                            return metaData.data.periodicita;
                        }} editable={false} filter='string' flex={1} cell={{

                            tools: {

                                modificaPeriodicita: {

                                    handler: modificaPeriodicita.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-calendar buttonMerciTool",

                                        tooltip: 'Periodicita',

                                        hidden: '{(record.legamE_IMPOSTO === "Si")}'
                                    }
                                }

                            }

                        }} />

                    </Grid>
                    {isCalendarioVisible && (
                        <Panel title={titolo} itemId="panelCalendarioPerLegII" closable={true} listeners=
                            {
                                {
                                    close: function () {
                                        setIsCalendarioVisible(false);
                                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: false });
                                    }
                                }} tools={[
                                    {
                                        iconCls: 'x-fa x-fa fa-plus', tooltip: 'Nuovo', hidden: !props.inEdit || !modificabile, handler: onNuovaPeriodicitaIILegClick.bind(this)
                                    }
                                    /* { type: 'menu', handler: toolHandler }*/
                                ]}>
                            <Grid
                                itemId="gridPeriodicitaIILegami"
                                minHeight={100}
                                maxHeight={100}
                                markDirty={true}
                                flex={1}
                                scrollable
                                selectable={{ checkbox: false, mode: 'single' }}
                                store={storePeriodicitaIILegami}
                                plugins={{
                                    gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" },
                                }}
                                itemConfig={{
                                    viewModel: true
                                }}
                                listeners={{
                                    beforeedit: function (editor, context, eOpts) {
                                        if (!props.inEdit || !modificabile)
                                            return false;
                                    },
                                    edit: function (grid, location) {
                                        setStateLoadMask({ hidden: false, message: "Salvataggio in corso...", ricarica: false });
                                        var periodicitaByDB = null;
                                        Ext.Ajax.request({
                                            url: 'Rto/GetImpegnoImpianto',
                                            method: 'GET',
                                            params: {
                                                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                            },
                                            callback: function (resp, success, operation) {
                                                const idPeriodicita = location.record.data.idePerImpegnoImpianto;
                                                periodicitaByDB = Ext.decode(operation.responseText);
                                                var idPeriodicitaNew = null;

                                                if (periodicitaByDB.periodicitaImpegnoImpiantoLegami.find(per => per.idePerImpegnoImpianto === idPeriodicita) === undefined) {
                                                    var periodicitaNewObject = location.record.data;
                                                    periodicitaNewObject.ideLoc = localitaSelezionata;
                                                    periodicitaNewObject.ideParent = legameSelezionato;
                                                    periodicitaNewObject.datIniPer.setHours(periodicitaNewObject.datIniPer.getHours() + 4);
                                                    periodicitaNewObject.datFinPer.setHours(periodicitaNewObject.datFinPer.getHours() + 4);
                                                    periodicitaNewObject.flgSmr = true;
                                                    periodicitaNewObject.flgLnd = false;
                                                    periodicitaNewObject.flgMrt = false;
                                                    periodicitaNewObject.flgMrc = false;
                                                    periodicitaNewObject.flgGvd = false;
                                                    periodicitaNewObject.flgVnr = false;
                                                    periodicitaNewObject.flgSbt = false;
                                                    periodicitaNewObject.flgDmn = false;
                                                    periodicitaNewObject.flgLav = false;
                                                    periodicitaNewObject.flgFes = false;
                                                    periodicitaNewObject.flgPtf = false;
                                                    periodicitaNewObject.flgPof = false;
                                                    periodicitaNewObject.flgSegPof = false;
                                                    var periodicitaNewJson = Ext.encode(periodicitaNewObject);
                                                    Ext.Ajax.request({
                                                        url: 'Rto/InserisciPeriodicitaLegamiImpegnoImpianto',
                                                        method: 'POST',
                                                        params: {
                                                            ideUte: user.ideUte,
                                                            dati: periodicitaNewJson
                                                        },
                                                        callback: function (records, success, operation) {
                                                            if (success) {
                                                                var returnObj = Ext.decode(operation.responseText);
                                                                //modifica gestione errore
                                                                if (returnObj.resp == "KO") {
                                                                    setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                                    Ext.Msg.alert('Inserimento periodicita legame fallito', returnObj.msgErr)
                                                                }
                                                                else {
                                                                    idPeriodicitaNew = returnObj.idPerLeg;
                                                                    setStateLoadMask({ hidden: false, message: "Attendere...", ricarica: false });
                                                                    Ext.Ajax.request({
                                                                        url: 'Rto/GetImpegnoImpianto',
                                                                        method: 'GET',
                                                                        params: {
                                                                            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                                        },
                                                                        callback: function (resp, success, operation) {
                                                                            const info = new Object();
                                                                            info.record = new Object();
                                                                            info.record.data = new Object();
                                                                            const idPeriodicitaNewApp = idPeriodicitaNew;
                                                                            periodicitaByDB = Ext.decode(operation.responseText);
                                                                            info.record.data = periodicitaByDB.periodicitaImpegnoImpiantoLegami.find(per => per.idePerImpegnoImpianto === idPeriodicitaNewApp);
                                                                            setStateLoadMask({ hidden: true, message: "Attendere...", ricarica: false });
                                                                            modificaGiorniCircolazione(null, info);
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                            else {
                                                                Ext.Msg.alert('Inserimento periodicita legame fallito', 'Inserimento periodicita legame fallito');
                                                                setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                            }
                                                        }
                                                    })
                                                }
                                                else {

                                                    var periodicitaObject = location.record.data;
                                                    periodicitaObject.datIniPer = new Date(periodicitaObject.datIniPer);
                                                    periodicitaObject.datFinPer = new Date(periodicitaObject.datFinPer);
                                                    periodicitaObject.datIniPer.setHours(periodicitaObject.datIniPer.getHours() + 4);
                                                    periodicitaObject.datFinPer.setHours(periodicitaObject.datFinPer.getHours() + 4);
                                                    periodicitaObject.ideLoc = localitaSelezionata;
                                                    periodicitaObject.ideParent = legameSelezionato;
                                                    periodicitaObject.flgSmr = location.record.data.flgSmr;
                                                    periodicitaObject.flgLnd = location.record.data.flgLnd;
                                                    periodicitaObject.flgMrt = location.record.data.flgMrt;
                                                    periodicitaObject.flgMrc = location.record.data.flgMrc;
                                                    periodicitaObject.flgGvd = location.record.data.flgGvd;
                                                    periodicitaObject.flgVnr = location.record.data.flgVnr;
                                                    periodicitaObject.flgSbt = location.record.data.flgSbt;
                                                    periodicitaObject.flgDmn = location.record.data.flgDmn;
                                                    periodicitaObject.flgLav = location.record.data.flgLav;
                                                    periodicitaObject.flgFes = location.record.data.flgFes;
                                                    periodicitaObject.flgPtf = location.record.data.flgPtf;
                                                    periodicitaObject.flgPof = location.record.data.flgPof;
                                                    periodicitaObject.flgSegPof = location.record.data.flgSegPof;
                                                    var periodicitaJson = Ext.encode(periodicitaObject);
                                                    Ext.Ajax.request({
                                                        url: 'Rto/ModificaPeriodicitaLegamiImpegnoImpianto',
                                                        method: 'POST',
                                                        params: {
                                                            ideUte: user.ideUte,
                                                            periodicitaDes: periodicitaJson
                                                        },
                                                        callback: function (records, success, operation) {
                                                            if (success) {
                                                                var returnObj = Ext.decode(operation.responseText);
                                                                //modifica gestione errore
                                                                if (returnObj.resp === "KO") {
                                                                    setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                                    Ext.Msg.alert('Modifica periodicita legame fallita', returnObj.msgErr);
                                                                    location.record.reject();
                                                                }
                                                                else {
                                                                    refreshGridPeriodicitaLegII();
                                                                    Ext.toast({ message: 'Periodicita legame modificata', timeout: 4000 });
                                                                    aggiornaControllo(true);
                                                                    Ext.Ajax.request({
                                                                        url: 'Rto/CheckPeriodicitaSML',
                                                                        method: 'POST',
                                                                        params: {
                                                                            idePer: legameSelezionato,
                                                                            ideTipo: 2
                                                                        },
                                                                        callback: function (records, success, operation) {
                                                                            var returnCheck = Ext.decode(operation.responseText);
                                                                            if (returnCheck.length > 0) {
                                                                                Ext.Msg.alert('Attenzione', returnCheck);
                                                                                aggiornaStateCheck(false);
                                                                            }
                                                                            else {
                                                                                aggiornaStateCheck(true);
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                            else {
                                                                Ext.Msg.alert('Modifica periodicita legame fallita', 'Modifica periodicita legame fallita');
                                                                location.record.reject();
                                                                setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                            }

                                                        }
                                                    });
                                                }
                                            }
                                        })

                                    },
                                    canceledit: function (editor, context) {
                                        refreshGridPeriodicitaLegII();
                                    }
                                }}
                            >
                                <Column itemId='actionRTOPeriodicita' editable={false} text="Azioni" width={150} hidden={!props.inEdit || !modificabile}
                                    cell={{

                                        tools: {

                                            aggiungiPeriodicita: {

                                                handler: onAggiungiPeriodicitaLegameIIClick.bind(this),

                                                bind: {

                                                    iconCls: "x-fa fa-plus",

                                                    tooltip: 'Aggiungi'
                                                }

                                            },

                                            modificaPeriodicita: {

                                                handler: onModificaPeriodicitaLegameIIClick.bind(this),

                                                bind: {

                                                    iconCls: "x-fa fa-edit",

                                                    tooltip: 'Modifica'
                                                }

                                            },

                                            eliminaPeriodicita: {

                                                handler: onEliminaPeriodicitaLegameIIClick.bind(this),

                                                bind: {

                                                    iconCls: "x-fa fa-trash",

                                                    tooltip: 'Elimina'
                                                }

                                            }



                                        }

                                    }}
                                />
                                <Column text="Operativita" dataIndex="ideOpv" width={150} filter='string' renderer={function (value, metaData, records, view) {
                                    if (value === 0)
                                        return "Si Effettua"
                                    else
                                        return "Non si Effettua"
                                }} editor={{
                                    xtype: 'combobox', editable: true, itemId: 'cmbOpe', triggerAction: 'all', mode: 'local', options:
                                        [
                                            { text: 'Si Effettua', value: 0 },
                                            { text: 'Non si Effettua', value: 1 }
                                        ]
                                    ,
                                    allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true

                                }} />
                                <Column text="Dal" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datIniPer" editor={{
                                    xtype: 'datefield', triggerAction: 'all', allowBlank: false, itemId: "dataIniServiII", requiredMessage: "Questo campo non puo' essere vuoto", required: true, listeners: {
                                        change: function (filed, newValue) {
                                            if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                                Ext.ComponentQuery.query('#dataIniServiII')[0].setValue(null);
                                            }
                                        }
                                    }
                                }} />
                                <Column text="Al" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datFinPer" editor={{
                                    xtype: 'datefield', triggerAction: 'all', allowBlank: false, itemId: "dataFinServiII", requiredMessage: "Questo campo non puo' essere vuoto", required: true, listeners: {
                                        change: function (filed, newValue) {
                                            if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                                Ext.ComponentQuery.query('#dataFinServiII')[0].setValue(null);
                                            }
                                        }
                                    }
                                }} />
                                <Column text="Giorni" editable={false} dataIndex="desPer" filter='string' flex={1} cell={{

                                    tools: {

                                        modificaMerci: {

                                            handler: modificaGiorniCircolazione.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-edit buttonMerciTool",

                                                tooltip: 'Modifica Giorni Circolazione',

                                                hidden: !props.inEdit
                                            }
                                        }

                                    }

                                }} />

                            </Grid>

                            {calendars != null && renderListOfCalendar(calendars)}
                        </Panel>)}
            </Container>
            </Panel>
        </Container>
    );
}

export default RtoIILegami;