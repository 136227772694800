import React, { useEffect, useState } from "react";
import { Container, Panel, Titlebar, Grid, Column, Button, Toolbar, SegmentedButton, Combobox, DatePickerField } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
]);


//-------------STATISTICHE CAMBI STATO--------------------------------
const storeStatisticheCambiStato = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'AnalisiRto/StatisticheCambiStato',
        reader: {
            type: 'json'
        },
        timeout: 300000
    },
    autoload: false
});
//-----------PASSAGGIO STATO ----------------------------------
const storePassaggioStato = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPassaggiStatoRTO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//-----------GRUPPO ----------------------------------
const storeGruppo = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiGruppi',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
//-----------UTENTE ----------------------------------
const storeUtente = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiUtentiByGrp',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

function StatisticheCambiStato() {

    let title = "Statistiche Cambi Stato";
    let ui = 'action';
    const { user } = useUser();
    

    const caricaGruppo = () => {
        storeGruppo.load({
            params: {
                ideGrp: user.idGruppo
            }
        })
    }
    const caricaUtente = () => {
        storeUtente.load({
            params: {
                ideGrp: user.idGruppo
            }
        })
    }
    const caricaPassaggioStato = () => {
        storePassaggioStato.load({
           
        })
    }
    


    useEffect(() => {
        caricaUtente();
        caricaGruppo();
        caricaPassaggioStato();
    }, []);

    //--------------CONFERMA/AVVIA RICERCA --------------------------
    const onConfermaClick = (x) => {
        let ideUte = parseInt(Ext.ComponentQuery.query('#comboUtente')[0].getValue()) > 0 ? Ext.ComponentQuery.query('#comboUtente')[0].getValue() : null;
        let ideGrp = parseInt(Ext.ComponentQuery.query('#comboGruppo')[0].getValue()) >= 0 ? Ext.ComponentQuery.query('#comboGruppo')[0].getValue() : null;
        let dataInizio = Ext.ComponentQuery.query('#dPickDataDa')[0].getValue();
        let dataFine = Ext.ComponentQuery.query('#dPickDataA')[0].getValue();        
        let ideStoPrv = null;
        let ideStoDsn = null;

        let codTipOpe = Ext.ComponentQuery.query('#comboPassaggioStato')[0].getValue();
        if (codTipOpe != null) {
            let ope = storePassaggioStato.findRecord("desCmbSto", codTipOpe, 0, false, false, true).data;
            ideStoPrv = ope.ideStoPrv;
            ideStoDsn = ope.ideStoDsn;
        }        
        
        storeStatisticheCambiStato.load({
            params: {
                ideUte: ideUte,
                ideGrp: ideGrp,
                dataInizio: dataInizio,
                dataFine: dataFine,
                ideStoPrv: ideStoPrv,
                ideStoDsn: ideStoDsn,
                ideGrpSne: user.idGruppo
            },
            callback: function (records, options, success) {
                if (success) {
                    if (records.length < 1) {
                        Ext.toast({ message: 'Nessuna dato soddisfa il criterio di ricerca', timeout: 4000 });
                    }
                }
            }        
        });
    }
   
    //--------------PULISCI FILTRI------------------------------------
    const handlePulisciFiltri = (btn) => {
        Ext.ComponentQuery.query('#comboGruppo')[0].setValue(null);
        Ext.ComponentQuery.query('#comboPassaggioStato')[0].setValue(null);
        Ext.ComponentQuery.query('#comboUtente')[0].setValue(null);
        Ext.ComponentQuery.query('#dPickDataDa')[0].setValue(null);
        Ext.ComponentQuery.query('#dPickDataA')[0].setValue(null);

        //svuota lo store 
        storeStatisticheCambiStato.clearData();
        storeStatisticheCambiStato.removeAll();
        Ext.ComponentQuery.query('#gridCamStato')[0].refresh();
    };

   
    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridCamStato')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }

   

    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>
            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1} >
                    <Button align="left" cls="title-icon" iconCls="fa-lg fas fa-chart-bar" />

                  
                </Titlebar>
                <Panel docked="top" layout="hbox" margin="10 0 0 10">
                    <Combobox itemId="comboPassaggioStato" align="left" width={450} height={25} margin='0 4 0 10' editable="false" valueField="desCmbSto" displayField="desCmbSto"
                        label="Passaggio Stato"
                        labelAlign="left"
                        labelWidth={110}
                        queryMode="local"
                        clearable
                        forceSelection="true"
                        store={storePassaggioStato}     
                    />
                    <Combobox itemId="comboGruppo" align="left" width={270} height={25} margin='0 4 0 10' editable="false" valueField="ideGrp" displayField="desGrp"
                        label="Gruppo"
                        labelAlign="left"
                        labelWidth={60}
                        queryMode="local"
                        store={storeGruppo}
                    />
                    <Combobox itemId="comboUtente" align="left" width={250} height={25} margin='0 4 0 10' editable="false" valueField="ideUte" displayField="nom"
                        label="Utente"
                        labelAlign="left"
                        labelWidth={60}
                        clearable
                        queryMode="local"
                        store={storeUtente}
                    />
                    

                    <DatePickerField label="Dal" width={150} height={25} margin="0 0 0 10" picker={{ yearFrom: 1990 }}
                        labelAlign="left"
                        labelWidth={35}
                        labelCls='LabelRiepilogo'
                        itemId="dPickDataDa"
                        queryMode="local"
                    />
                    <DatePickerField label="Al" width={150} height={25} margin="0 0 0 10" picker={{ yearFrom: 1990 }}
                        labelAlign="left"
                        labelWidth={30}
                        labelCls='LabelRiepilogo'
                        itemId="dPickDataA"
                        queryMode="local"
                    />
                    <Panel docked="bottom" width="100%" margin="10 0 2 0">
                        <Toolbar docked="right" width="100%">
                            <SegmentedButton layout="vbox" docked="right">
                                <Button cls="marginButton ricercaAvanzataButton" align="right" text="Pulisci filtri" iconCls={'fa-solid fa-filter-circle-xmark'} ui="action" handler={handlePulisciFiltri} />
                                <Button cls="marginButton appButton" align="right" text="Avvia ricerca" iconCls={'x-fa fa-search'} ui="action" handler={onConfermaClick} />
                                <Button cls="marginButton exportButton" align="right" docked="right" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />
                            </SegmentedButton>
                        </Toolbar>
                    </Panel>


                </Panel>

                <Grid flex={1} scrollable cls="custom-grid" itemId="gridCamStato" itemConfig={{ viewModel: true }} margin="10 0 0 0"
                     selectable={{ checkbox: false, mode: 'single' }}
                     plugins={['gridfilters', 'gridexporter']}
                    store={storeStatisticheCambiStato}
                     loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                >
                    <Column height="40" width={300} text="IF" dataIndex="noM_CLI" filter='string' flex={1} exportStyle={{ width: '300' }} />
                    <Column height="40" width={170} text="Protocollo IF" dataIndex="ptL_RFI" filter='string' exportStyle={{ width: '250' }} />
                    <Column height="40" width={200} text="Numero Treno RFI" dataIndex="noM_TRE_RFI" filter='string' exportStyle={{ width: '210' }} />
                    <Column height="40" width={200} text="Origine" dataIndex="noM_LOC_PRV" filter='string' exportStyle={{ width: '430' }} />
                    <Column height="40" width={200} text="Destino" dataIndex="noM_LOC_DSN" filter='string' exportStyle={{ width: '430' }} />
                    <Column height="40" width={170} text="Utente" dataIndex="nom" filter='string' exportStyle={{ width: '170' }} />
                    <Column height="40" width={100} text="Gruppo" dataIndex="deS_GRP" filter='string' exportStyle={{ width: '170' }} />
                    <Column height="40" width={140} text="Data Cambi Stato" dataIndex="daT_OPE" type="datecolumn" formatter='date("d/m/Y")' filter='date' exportStyle={{ width: '170' }} />
                    <Column height="40" width={140} text="Stato Finale" dataIndex="deS_STO_RTO" flex={1} filter='string' />
                    {/*<Column height="40" width={70} text="Apri" dataIndex="idE_RTO" filter='string' />*/}
                </Grid>

             
            </Panel>
        </Container>

    );
}

export default StatisticheCambiStato;