export const weekdays = ['L', 'M', 'M', 'G', 'V', 'S', 'D', '', 'La', 'Fe', 'Pre', 'Ptf', 'Spf', 'Svg', 'Giorni'];
export const colorMapping = {
    '0': '#C2C2C2',
    '1': '#90BEE6',
    '2': '#5FA2DD'
};
export const PlusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="plus-minus" fill="#5FA2DD" viewBox="0 0 448 512"> <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
  </svg>
);
export const MinusIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" className="plus-minus" fill="#5FA2DD"viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>
);
export const CalendarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="svg"  fill="currentColor" viewBox="0 0 448 512">
      <path d="M128 0c13.3 0 24 10.7 24 24V64H296V24c0-13.3 10.7-24 24-24s24 10.7 24 24V64h40c35.3 0 64 28.7 64 64v16 48V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192 144 128C0 92.7 28.7 64 64 64h40V24c0-13.3 10.7-24 24-24zM400 192H48V448c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V192zM329 297L217 409c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 95-95c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
    </svg>
);


