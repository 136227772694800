import React from 'react';
import { Panel, FormPanel, Container, Button, ComboBoxField, Loadmask, DatePickerField, TimeField, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { useState, useContext } from "react";
import periodoFerroviarioModel from '../models/PeriodoFerroviarioModel.js';
import { useUser } from '../components/UserContext';
import { NuovaRtoContext } from '../components/NuovaRtoContext';

const Ext = window['Ext'];
var isAnnoOrAdeVCONUovaTraccia = false;
var isVcoAdeVariazione = false;
var ideRTOOriginaria = -1;
var flagRTO = 0;
var dataOriginaria = "";
var numTrenoRFIOrig = "";
var rangeDateStartProvvediamento = null;
var rangeDateEndProvvediamento = null;

const storeLotti = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Lotti/LeggiLotti',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

var calcolaCaso = function (idePeriodo, ideTipoVco) {
    if (isNaN(ideTipoVco) && idePeriodo === 0) {
        isAnnoOrAdeVCONUovaTraccia = true;
        isVcoAdeVariazione = false;
    }
    else if (idePeriodo === 0 || ((idePeriodo === 1 || idePeriodo === 2) && ideTipoVco === 2)) {
        isAnnoOrAdeVCONUovaTraccia = true;
        isVcoAdeVariazione = false;
    }
    else if (((idePeriodo === 1 || idePeriodo === 2) && (!isNaN(ideTipoVco) && ideTipoVco !== 2))) {
        isAnnoOrAdeVCONUovaTraccia = false;
        isVcoAdeVariazione = true;
    }
    else {
        isAnnoOrAdeVCONUovaTraccia = false;
        isVcoAdeVariazione = false;
    }

}

const rtoOriginariaStore = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});
var filterConditionPerFerrVCOAde = function (record) {
    var dataIni = record.get('datIniVal');
    var dataFin = record.get('datFinVal');
    var compreso = new Date(dataIni) <= new Date() && new Date(dataFin) >= new Date();
    // Replace this with your actual filtering logic
    return new Date(dataIni) >= new Date() || compreso;
};
var filterConditionPerFerrAnn = function (record) {
    // Replace this with your actual filtering logic
    return new Date(record.get('datIniVal')) >= new Date();
};

function renderColorRow(value, record, field, metaData) {
    if (record.data.bcK_CLR === 'White') {
        let row = metaData.row;
        row.setStyle("background:#FFFFFF;");  // #adfd8c
    }
    else if (record.data.bcK_CLR === 'Red') {
        let row = metaData.row;
        row.setStyle("background:#FFDAB9;");  // pesca
    }
    else if (record.data.bcK_CLR === 'Blue') {
        let row = metaData.row;
        row.setStyle("background:#ADD8E6;");  // ligthblue
    }
    return value;
}
function renderData(value, record, field, metaData) {
        return new Date(value).toLocaleDateString('it-IT');
}

function RtoRiepilogoNuovaRto(props) {
    const contextVersioneRto = useContext(NuovaRtoContext);
    const { user, switchUser } = useUser();
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere salvataggio in corso..." })
    const ideTipoRTO = contextVersioneRto.storeTipologieRTO.data.items[0].data.ideTipRTO;
    var rangeDateStart = new Date (contextVersioneRto.storePeriodiFerroviari.data.items.find(pf => new Date(pf.data.datIniVal) <= (new Date()) && new Date(pf.data.datFinVal) >= (new Date()))?.data.datIniVal);
    var rangeDateEnd = new Date(contextVersioneRto.storePeriodiFerroviari.data.items.find(pf => new Date(pf.data.datIniVal) <= (new Date()) && new Date(pf.data.datFinVal) >= (new Date()))?.data.datFinVal);
    function validatoreCampiRiepilogo(campo) {
        if (campo._required === true) {
            if (((campo._itemId === "dataProvvedimentoIdNuova") && Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]._value === 2) || ((campo._itemId === "categoriaIFId") && Ext.ComponentQuery.query('#tipoVCOIdNuova')[0]._value !== '3') || ((campo._itemId === "tipoTrafficoId") && Ext.ComponentQuery.query('#tipoVCOIdNuova')[0]._value !== '3') || ((campo._itemId !== "dataProvvedimentoIdNuova") && (campo._itemId !== "categoriaIFId") && (campo._itemId !== "tipoTrafficoId") )) {
                if (campo.getValue() !== null) {
                    if (typeof campo.getValue() === 'string') {
                        if (campo.getValue().trim().length > 0)
                            return true;
                        else
                            return false;
                    }
                    else
                        return true;
                }
                else
                    return false;
            }
            else
                return true;
        }
        else
            return true;

    }

    function isValidTimeFormat(time) {
        // Definisci una regex per il formato HH:mm
        var regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(time);
    }

    function onConfermaNuovaIFClick() {
        if ((isVcoAdeVariazione && ideRTOOriginaria !== -1) || isAnnoOrAdeVCONUovaTraccia) {
            if (validatoreCampiRiepilogo(Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#protocolloIFIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#dataProtocolloIFIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#categoriaIFIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#origineIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#destinazioneIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#tipoRTOIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#lottoIdNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#timeOraDestNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#timeOraPartNuova')[0]) &&
                validatoreCampiRiepilogo(Ext.ComponentQuery.query('#trenoIFIdNuova')[0]) && Ext.ComponentQuery.query('#timeOraDestNuova')[0]._error === null && Ext.ComponentQuery.query('#timeOraPartNuova')[0]._error === null) {
                var timeArrMod = "";
                var timeParMod = "";
                if (typeof Ext.ComponentQuery.query('#timeOraDestNuova')[0]._value !== 'string') {
                    timeArrMod = ('0' + Ext.ComponentQuery.query('#timeOraDestNuova')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#timeOraDestNuova')[0]._value.getMinutes()).slice(-2);
                }
                if (typeof Ext.ComponentQuery.query('#timeOraPartNuova')[0]._value !== 'string') {
                    timeParMod = ('0' + Ext.ComponentQuery.query('#timeOraPartNuova')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#timeOraPartNuova')[0]._value.getMinutes()).slice(-2);
                }
                if (isAnnoOrAdeVCONUovaTraccia) {
                    setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                    Ext.Ajax.request({
                        url: 'Rto/InserisciRTO',
                        method: 'POST',
                        params: {
                            ideUte: user.ideUte,
                            ipAddress: "",
                            ptlIF: Ext.ComponentQuery.query('#protocolloIFIdNuova')[0]._value,
                            note: Ext.ComponentQuery.query('#noteIdNuova')[0]._value,
                            nomTreIpr: Ext.ComponentQuery.query('#trenoIFIdNuova')[0]._value,
                            ideCatTreIF: Ext.ComponentQuery.query('#categoriaIFIdNuova')[0]._value,
                            oraPar: timeParMod,
                            ideOri: Ext.ComponentQuery.query('#origineIdNuova')[0]._value,
                            oraDsn: timeArrMod,
                            ideDes: Ext.ComponentQuery.query('#destinazioneIdNuova')[0]._value,
                            ideTipTra: Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0]._value,
                            datPtlIF: Ext.ComponentQuery.query('#dataProtocolloIFIdNuova')[0]._value,
                            datAtvPvv: Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0]._value,
                            ideAcoQrd: Ext.ComponentQuery.query('#accordoQuadroIdNuova')[0]._value != null ? Ext.ComponentQuery.query('#accordoQuadroIdNuova')[0]._value : -1,
                            ideTipPer: Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]._value,
                            ideTipRTO: Ext.ComponentQuery.query('#tipoRTOIdNuova')[0]._value,
                            ideLtx: Ext.ComponentQuery.query('#lottoIdNuova')[0]._value,
                            committente: Ext.ComponentQuery.query('#committenteIdNuova')[0]._value

                        },
                        timeout: 120000,
                        callback: function (records, success, operation) {
                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                            if (success) {
                                var returnObj = Ext.decode(operation.responseText);
                                //modifica gestione errore
                                if (returnObj.resp === "KO") {
                                    Ext.Msg.alert('Inserimento fallito', returnObj.msgErr);
                                }
                                else {
                                    Ext.toast({ message: 'Operazione terminata con successo!', timeout: 4000 });
                                    props.RefreshRtoDaNuova(returnObj.rtoResp.ideRTO, user.idTipoGruppo, user.ideCli);
                                }
                            }
                            else {
                                Ext.Msg.alert('Inserimento fallito', 'Inserimento fallito');
                            }

                        }
                    })
                }
                else if (isVcoAdeVariazione) {
                    if (numTrenoRFIOrig !== "") {
                        setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                        Ext.Ajax.request({
                            url: 'Rto/InserisciVCO',
                            method: 'POST',
                            params: {
                                ideUte: user.ideUte,
                                ipAddress: "",
                                flgRTO: flagRTO,
                                idRToOri: ideRTOOriginaria,
                                numTrenoRFI: numTrenoRFIOrig,
                                dataInizioValiditaorigine: dataOriginaria,
                                ptlIF: Ext.ComponentQuery.query('#protocolloIFIdNuova')[0]._value,
                                note: Ext.ComponentQuery.query('#noteIdNuova')[0]._value,
                                nomTreIpr: Ext.ComponentQuery.query('#trenoIFIdNuova')[0]._value,
                                ideCatTreIF: Ext.ComponentQuery.query('#categoriaIFIdNuova')[0]._value,
                                oraPar: timeParMod,
                                ideOri: Ext.ComponentQuery.query('#origineIdNuova')[0]._value,
                                oraDsn: timeArrMod,
                                ideDes: Ext.ComponentQuery.query('#destinazioneIdNuova')[0]._value,
                                ideTipTra: Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0]._value,
                                datPtlIF: Ext.ComponentQuery.query('#dataProtocolloIFIdNuova')[0]._value,
                                datAtvPvv: Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0]._value,
                                ideTipPer: Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]._value,
                                ideTipRTO: Ext.ComponentQuery.query('#tipoRTOIdNuova')[0]._value,
                                ideLtx: Ext.ComponentQuery.query('#lottoIdNuova')[0]._value,
                                ideTipVco: Ext.ComponentQuery.query('#tipoVCOIdNuova')[0]._value,
                                committente: Ext.ComponentQuery.query('#committenteIdNuova')[0]._value
                            },
                            timeout: 120000,
                            callback: function (records, success, operation) {
                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                if (success) {
                                    var returnObj = Ext.decode(operation.responseText);
                                    //modifica gestione errore
                                    if (returnObj.resp === "KO") {
                                        Ext.Msg.alert('Inserimento fallito', returnObj.msgErr);
                                    }
                                    else {
                                        Ext.toast({ message: 'Operazione terminata con successo!', timeout: 4000 });
                                        props.RefreshRtoDaNuova(returnObj.rtoResp.ideRTO, user.idTipoGruppo, user.ideCli);
                                    }
                                }
                                else {
                                    Ext.Msg.alert('Inserimento fallito', 'Inserimento fallito');
                                }

                            }
                        })
                    }
                    else {
                        Ext.toast({ message: 'Selezionare una RTO di origine!', timeout: 4000 })
                    }
                }
            }
            else
                Ext.toast({ message: 'Verificare i campi contrassegnati come obbligatori!', timeout: 4000 })
        }
        else
            Ext.toast({ message: 'Selezionare la RTO originaria!', timeout: 4000 })
    }

    const handleTextFieldClick = () => {
        // Add your logic to open the dialog here
        if (isVcoAdeVariazione) {
            var dialog = Ext.create({
                xtype: 'dialog',
                title: 'Rto Originaria',
                itemId: 'dialogRtoOriginaria',
                layout: 'fit',
                closable: "true",
                modal: true,
                width: 800,
                items: [
                    {
                        xtype: 'panel',
                        layout: "vbox",
                        margin: "0 0 0 5",
                        items: [

                            {
                                xtype: 'panel',
                                layout: "hbox",
                                margin: "0 0 0 5",
                                items: [{
                                    xtype: 'textfield',
                                    flex: 1,
                                    itemId: "txtNumTrenoRFI",
                                    margin: "5 5 5 5",
                                    label: "Numero Treno RFI",
                                    listeners: {
                                        change: function (textfield, newValue) {
                                            if (newValue.length >= 2) {
                                                Ext.Ajax.request({
                                                    url: 'Rto/GetElencoTreniProtocolli',
                                                    method: 'GET',
                                                    params: {
                                                        ideCli: user.ideCli,
                                                        treno: newValue,
                                                        protocollo: Ext.ComponentQuery.query('#txtProtocRFI')[0]._value,
                                                        datIniPer: contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0]._value, 0, false, false, true) != null ? contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0]._value, 0, false, false, true).data.datIniVal : null,
                                                        datFinPer: contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0]._value, 0, false, false, true) != null ? contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0]._value, 0, false, false, true).data.datFinVal : null
                                                    },
                                                    callback: function (records, success, operation) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        rtoOriginariaStore.clearData();
                                                        rtoOriginariaStore.removeAll();
                                                        rtoOriginariaStore.loadData(returnObj);
                                                        Ext.ComponentQuery.query('#gridRtoOriginaria')[0].setStore(rtoOriginariaStore);
                                                    }
                                                });
                                            }
                                        }
                                    }
                                },
                                {
                                    xtype: 'textfield',
                                    flex: 1,
                                    itemId: "txtProtocRFI",
                                    margin: "5 5 5 5",
                                    label: "Protocollo RFI",
                                    listeners: {
                                        change: function (textfield, newValue) {
                                            if (newValue.length >= 2) {
                                                Ext.Ajax.request({
                                                    url: 'Rto/GetElencoTreniProtocolli',
                                                    method: 'GET',
                                                    params: {
                                                        ideCli: user.ideCli,
                                                        treno: Ext.ComponentQuery.query('#txtNumTrenoRFI')[0]._value,
                                                        protocollo: newValue,
                                                        datIniPer: contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0]._value, 0, false, false, true) != null ? contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0]._value, 0, false, false, true).data.datIniVal : null,
                                                        datFinPer: contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0]._value, 0, false, false, true) != null ? contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0]._value, 0, false, false, true).data.datFinVal : null
                                                    },
                                                    callback: function (records, success, operation) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        rtoOriginariaStore.clearData();
                                                        rtoOriginariaStore.removeAll();
                                                        rtoOriginariaStore.loadData(returnObj);
                                                        Ext.ComponentQuery.query('#gridRtoOriginaria')[0].setStore(rtoOriginariaStore);
                                                    }
                                                });
                                            }
                                        }

                                    }
                                }
                                ]
                            },
                            {

                                xtype: 'grid',
                                itemId: "gridRtoOriginaria",
                                markDirty: true,
                                selectable: { checkbox: true, mode: 'single', rows: true },
                                store: { rtoOriginariaStore },
                                layout: "fit",
                                minHeight: '200',
                                listeners: {
                                    selectionchange: function (grid, record, item, index, e, eOpts) {
                                        Ext.ComponentQuery.query('#btnCreaDaPO')[0].enable();
                                        if (record[0].data.flG_TRC === 1 || record[0].data.flG_EXT === 1) {
                                            Ext.ComponentQuery.query('#btnCreaDaRTO')[0].enable();
                                        }
                                        else {
                                            Ext.ComponentQuery.query('#btnCreaDaRTO')[0].disable();
                                        }
                                    }
                                },
                                height: '200',
                                margin: "0 0 0 5",
                                scrollable: true,
                                selType: 'rowmodel', // Imposta il tipo di selezione su 'rowmodel'
                                selModel: {
                                    mode: 'SINGLE' // Imposta la modalit� di selezione su 'SINGLE' per una sola riga selezionabile alla volta
                                },

                                columns: [
                                    {
                                        text: "N. Treno RFI",
                                        flex: 1,
                                        dataIndex: "noM_TRE_RFI",
                                        filter: 'string',
                                        renderer: renderColorRow
                                    },
                                    {
                                        text: "Data Inzio Validita",
                                        flex: 1,
                                        dataIndex: "daT_INI_VAL",
                                        filter: 'string',
                                        renderer: renderData
                                    },
                                    {
                                        text: "Protocollo RFI",
                                        flex: 1,
                                        dataIndex: "ptL_RFI",
                                        filter: 'string',
                                    },
                                    {
                                        text: "Ex Treno",
                                        flex: 1,
                                        dataIndex: "noM_TRE_EXT",
                                        filter: 'string',
                                    },
                                    {
                                        text: "Ultima vers. treno Roman",
                                        flex: 1,
                                        dataIndex: "trN_OLD",
                                        filter: 'string',
                                    }
                                ]
                            },
                            {
                                xtype: 'panel',
                                layout: "hbox",
                                margin: "0 0 0 5",
                                width: '100%',
                                html: '<table style="width: 100%"><thead><tr><th class="backgroundWhite">Traccia associata a una RTO<br></th><th class="backgroundpeachpuff">Traccia non associata a una RTO o il cui<br>ex treno associato a RTO</th><th class="backgroundlightblue">Traccia di cui una versione<br>prec. e associata a una RTO</th></tr></thead></table>'
                            },
                            {
                                xtype: 'panel',
                                layout: "hbox",
                                margin: "0 0 0 5",
                                items: [{
                                    xtype: 'button',
                                    flex: 1,
                                    itemId: "btnCreaDaPO",
                                    margin: "5 5 5 5",
                                    disabled: true,
                                    cls: "appButtonDanger",
                                    text: "Crea da PO", handler: function () {
                                        setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                                        let nomtrerfi = Ext.ComponentQuery.query('#gridRtoOriginaria')[0].getSelection().data.noM_TRE_RFI;
                                        let dataIniValid = Ext.ComponentQuery.query('#gridRtoOriginaria')[0].getSelection().data.daT_INI_VAL;
                                        let ideRtoOrig = Ext.ComponentQuery.query('#gridRtoOriginaria')[0].getSelection().data.idE_RTO
                                        Ext.ComponentQuery.query('#dialogRtoOriginaria')[0].destroy();
                                        flagRTO = 0;
                                        Ext.Ajax.request({
                                            url: 'Rto/GetInfoCreaDaVCO',
                                            method: 'GET',
                                            params: {
                                                flagRTO: 0,
                                                trenoRFI: nomtrerfi,
                                                datIni: dataIniValid,
                                                ideRTO: ideRtoOrig
                                            },
                                            callback: function (records, success, operation) {
                                                var returnObj = Ext.decode(operation.responseText);
                                                dataOriginaria = dataIniValid;
                                                numTrenoRFIOrig = nomtrerfi;
                                                if (ideRtoOrig !== null && ideRtoOrig !== "") {
                                                    ideRTOOriginaria = ideRtoOrig;
                                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].setCls('marginButton appButtonRTO');
                                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].setText(nomtrerfi);
                                                }
                                                else {
                                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].setCls('marginButton appButtonPO');
                                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].setText(nomtrerfi);
                                                }
                                                Ext.ComponentQuery.query('#categoriaIFIdNuova')[0].setValue(returnObj[0].idE_CAT_TRE);
                                                Ext.ComponentQuery.query('#destinazioneIdNuova')[0].setValue(returnObj[0].idE_LOC_DSN);
                                                Ext.ComponentQuery.query('#origineIdNuova')[0].setValue(returnObj[0].idE_LOC_ORI);
                                                Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0].setValue(returnObj[0].idE_TIP_TRA);
                                                Ext.ComponentQuery.query('#timeOraDestNuova')[0].setValue(returnObj[0].orA_ARR);
                                                Ext.ComponentQuery.query('#timeOraPartNuova')[0].setValue(returnObj[0].orA_PAR);
                                                Ext.ComponentQuery.query('#committenteIdNuova')[0].setValue(returnObj[0].idE_CME);
                                                Ext.ComponentQuery.query('#trenoIFIdNuova')[0].setValue(returnObj[0].noM_TRE_IPR); 
                                                
                                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                            }
                                        });
                                    }
                                },
                                {
                                    xtype: 'button',
                                    flex: 1,
                                    itemId: "btnCreaDaRTO",
                                    margin: "5 5 5 5",
                                    disabled: true,
                                    text: "Crea da RTO",
                                    cls: "appButton",
                                    handler: function () {
                                        setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
                                        flagRTO = 1;
                                        let nomtrerfi = Ext.ComponentQuery.query('#gridRtoOriginaria')[0].getSelection().data.noM_TRE_RFI;
                                        let dataIniValid = Ext.ComponentQuery.query('#gridRtoOriginaria')[0].getSelection().data.daT_INI_VAL;
                                        let ideRtoOrig = Ext.ComponentQuery.query('#gridRtoOriginaria')[0].getSelection().data.idE_RTO;
                                        Ext.ComponentQuery.query('#dialogRtoOriginaria')[0].destroy();
                                        Ext.Ajax.request({
                                            url: 'Rto/GetInfoCreaDaVCO',
                                            method: 'GET',
                                            params: {
                                                flagRTO: 1,
                                                trenoRFI: nomtrerfi,
                                                datIni: dataIniValid,
                                                ideRTO: ideRtoOrig
                                            },
                                            callback: function (records, success, operation) {
                                                var returnObj = Ext.decode(operation.responseText);
                                                dataOriginaria = dataIniValid;
                                                numTrenoRFIOrig = nomtrerfi;
                                                if (ideRtoOrig != null) {
                                                    ideRTOOriginaria = ideRtoOrig;
                                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].setCls('marginButton appButtonRTO');
                                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].setText(nomtrerfi);
                                                }
                                                else {
                                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].setCls('marginButton appButtonPO');
                                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].setText(nomtrerfi);
                                                }
                                                Ext.ComponentQuery.query('#categoriaIFIdNuova')[0].setValue(returnObj[0].idE_CAT_TRE)
                                                Ext.ComponentQuery.query('#destinazioneIdNuova')[0].setValue(returnObj[0].idE_LOC_DSN);
                                                Ext.ComponentQuery.query('#origineIdNuova')[0].setValue(returnObj[0].idE_LOC_ORI);
                                                Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0].setValue(returnObj[0].idE_TIP_TRA);
                                                Ext.ComponentQuery.query('#timeOraDestNuova')[0].setValue(returnObj[0].orA_ARR);
                                                Ext.ComponentQuery.query('#timeOraPartNuova')[0].setValue(returnObj[0].orA_PAR);
                                                Ext.ComponentQuery.query('#committenteIdNuova')[0].setValue(returnObj[0].idE_CME)
                                                Ext.ComponentQuery.query('#trenoIFIdNuova')[0].setValue(returnObj[0].noM_TRE_IPR); 
                                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                            }
                                        });
                                    }
                                },
                                {
                                    xtype: 'button',
                                    flex: 1,
                                    itemId: "btnAnnulla",
                                    margin: "5 5 5 5",
                                    text: "Annulla",
                                    cls: "appButtonNeutro",
                                    handler: function () {
                                        Ext.ComponentQuery.query('#dialogRtoOriginaria')[0].destroy();
                                    }
                                }

                                ]
                            },
                        ]
                    }
                ]
            });

            dialog.show();
        }
    };

    return (
        <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
        <Panel
            title="Riepilogo"
            scrollablec
            itemId="panelRiepilogoNuova"
            bodyPadding={5}
            titleCollapse={true}
            iconCls="x-fa fa-th-list"
            cls='PnlSectionL1'
            tools={[
                { iconCls: 'x-fa fa-check', itemId: "confermaRiepilogoNuova", handler: onConfermaNuovaIFClick.bind(this), tooltip: 'Conferma' }
                /* { type: 'menu', handler: toolHandler }*/
            ]}
        >
                <FormPanel layout="hbox">

                <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                    <ComboBoxField
                        label="Periodo RTO"
                        labelAlign="top"
                        store={contextVersioneRto.storePeriodoRTO}
                        displayField="desTipPer"
                        valueField="ideTipPer"
                        queryMode="local"
                        itemId="periodoRtoIdNuova"
                        typeAhead
                        required={true}
                        requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                        listeners={{
                            change: function (combo, value, oldValue) {
                                Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0].enable();
                                //anno orario
                                if (parseInt(value) === 0) {
                                    Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].setValue(null); 
                                    Ext.ComponentQuery.query('#lottoIdNuova')[0].enable(); 
                                    Ext.ComponentQuery.query('#protocolloIFIdNuova')[0].enable(); 
                                    Ext.ComponentQuery.query('#dataProtocolloIFIdNuova')[0].enable(); 
                                    Ext.ComponentQuery.query('#trenoIFIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].disable();
                                    Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0].getStore().clearFilter();
                                    Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0].getStore().filterBy(filterConditionPerFerrAnn);
                                    Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0].setValue(Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0].getStore().getAt(0).data.ideAnnFro);
                                }
                                //adeguamento o vco
                                else {
                                    Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0].getStore().clearFilter();
                                    Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0].getStore().filterBy(filterConditionPerFerrVCOAde);
                                    Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0].setValue(Ext.ComponentQuery.query('#periodoFerroviarioIdNuova')[0].getStore().getAt(1).data.ideAnnFro);
                                    Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#lottoIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#protocolloIFIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#dataProtocolloIFIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#trenoIFIdNuova')[0].enable();
                                }
                                    calcolaCaso(parseInt(value), parseInt(Ext.ComponentQuery.query('#tipoVCOIdNuova')[0]._value));
                                if (isAnnoOrAdeVCONUovaTraccia) {
                                    Ext.ComponentQuery.query('#accordoQuadroIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#timeOraDestNuova')[0].enable();
                                    Ext.ComponentQuery.query('#destinazioneIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#timeOraPartNuova')[0].enable();
                                    Ext.ComponentQuery.query('#origineIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#categoriaIFIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].disable();
                                    //anno orario
                                    if (parseInt(value) === 0) {
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].setValue(null);
                                    }
                                    //adeguamento
                                    else if (parseInt(value) === 1) {
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].setValue(null);
                                    }
                                    //vco
                                    else if (parseInt(value) === 2) {
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].enable();
                                    }
                                }
                                else if (isVcoAdeVariazione) {
                                    Ext.ComponentQuery.query('#accordoQuadroIdNuova')[0].disable();
                                    Ext.ComponentQuery.query('#accordoQuadroIdNuova')[0].setValue(null);
                                    Ext.ComponentQuery.query('#committenteIdNuova')[0].disable();
                                    Ext.ComponentQuery.query('#committenteIdNuova')[0].setValue(null);
                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].enable();
                                    if (parseInt(Ext.ComponentQuery.query('#tipoVCOIdNuova')[0]._value) === 3)  //solo servizi
                                    {
                                        Ext.ComponentQuery.query('#categoriaIFIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#categoriaIFIdNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#timeOraDestNuova')[0].disable();
                                        Ext.ComponentQuery.query('#timeOraDestNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#destinazioneIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#destinazioneIdNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#timeOraPartNuova')[0].disable();
                                        Ext.ComponentQuery.query('#timeOraPartNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#origineIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#origineIdNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0].setValue(null);
                                    }
                                    else if (parseInt(Ext.ComponentQuery.query('#tipoVCOIdNuova')[0]._value) === 1) //traccia e servizi
                                    {
                                        Ext.ComponentQuery.query('#categoriaIFIdNuova')[0].enable();
                                        Ext.ComponentQuery.query('#timeOraDestNuova')[0].enable();
                                        Ext.ComponentQuery.query('#destinazioneIdNuova')[0].enable();
                                        Ext.ComponentQuery.query('#timeOraPartNuova')[0].enable();
                                        Ext.ComponentQuery.query('#origineIdNuova')[0].enable();
                                        Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0].enable();
                                    }
                                }
                                
                                
                            }

                        }}
                    />
                        <TextField clearable={false} label="Protocollo IF" itemId="protocolloIFIdNuova" labelAlign="top" head="20" maxLength={50} minwidth="200" required={true} requiredMessage="Questo campo &egrave; obbligatorio" />
                        <TextField clearable={false} label="N. Treno IF" itemId="trenoIFIdNuova" labelAlign="top" maxLength={100} labelCls='LabelRiepilogoBlu' head="20" required={true} requiredMessage="Questo campo &egrave; obbligatorio"  />
                    <Panel layout="hbox" margin="4 0 4 0">
                    <ComboBoxField
                            label="Origine"
                            labelAlign="top"
                            itemId="origineIdNuova"
                            typeAhead
                            store={contextVersioneRto.storeLocalita}
                            displayField="nomLoc"
                            valueField="ideLoc"
                            labelCls='LabelRiepilogoBlu'
                            required={true}
                            disabled={true}
                                requiredMessage="Questo campo &egrave; obbligatorio"
                            forceSelection={true}
                            queryMode="local"
                        />
                        <TimeField
                            label="Ora"
                            labelAlign="top"
                            width="100"
                            disabled={true}
                                itemId='timeOraPartNuova'
                                maxLength={5}
                            required={true}
                                requiredMessage="Questo campo &egrave; obbligatorio"
                            format='H:i'
                            picker={{
                                meridiem: false,
                                alignPMInside: true
                            }}
                       />
                    </Panel>
                        <ComboBoxField
                            label="Tipo RTO"
                            labelAlign="top"
                            store={contextVersioneRto.storeTipologieRTO}
                            displayField="desTipRTO"
                            itemId="tipoRTOIdNuova"
                            valueField="ideTipRTO"
                            queryMode="local"
                            required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            forceSelection={true}
                            typeAhead
                            value={ideTipoRTO}
                        // onSelect={onSelectPeriodoRTO}
                    />
                </Panel>

                <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                    <ComboBoxField
                        label="Periodo Ferroviario"
                        labelAlign="top"
                        store={contextVersioneRto.storePeriodiFerroviari}
                        displayField="desAnnFro"
                        itemId="periodoFerroviarioIdNuova"
                        valueField="ideAnnFro"
                        queryMode="local"
                        required={true}
                        disabled={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                        typeAhead
                        listeners={{
                            select: function (combo, value, oldValue) {
                                rangeDateStartProvvediamento = new Date(combo._selection.data.datIniVal);
                                rangeDateEndProvvediamento = new Date(combo._selection.data.datFinVal);
                                storeLotti.clearData();
                                storeLotti.removeAll();
                                storeLotti.load({
                                    params: {
                                        ideUte: user.ideUte,
                                        ideCli: user.ideCli,
                                        ideAnnFro: value.data.ideAnnFro
                                    }
                                });
                            }
                        }}
                        // onSelect={onSelectPeriodoRTO}
                    />
                    <DatePickerField
                        clearable={false}
                        label="Data Prot.IF"
                            itemId="dataProtocolloIFIdNuova"
                        required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                        labelAlign="top"
                        destroyPickerOnHide
                        picker={{
                            yearFrom: 1990
                            }}
                            listeners={{
                                change: function (filed, newValue) {
                                    if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                        Ext.ComponentQuery.query('#dataProtocolloIFIdNuova')[0].setValue(null);
                                    }
                                }
                            }}
                    />

                    <DatePickerField
                        clearable={false}
                        label="Data Provvedimento"
                        itemId="dataProvvedimentoIdNuova"
                        labelAlign="top"
                        labelCls='LabelRiepilogoBlu'
                        required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                        destroyPickerOnHide
                        disabled={true}
                        picker={{
                            yearFrom: 1990
                        }}
                        listeners={{
                            change: function (filed, newValue) {
                                if (newValue > rangeDateEndProvvediamento || newValue < rangeDateStartProvvediamento) {
                                    Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].setValue(null);
                                }
                            }
                        }}
                    />
                    <Panel layout="hbox" margin="4 0 4 0">
                    <ComboBoxField
                        label="Destinazione"
                        labelAlign="top"
                        itemId="destinazioneIdNuova"
                        typeAhead
                        labelCls='LabelRiepilogoBlu'
                        store={contextVersioneRto.storeLocalita}
                        displayField="nomLoc"
                        valueField="ideLoc"
                            required={true}
                            disabled={true}
                                requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                            queryMode="local"
                    />
                        <TimeField
                            label="Ora"
                            labelAlign="top"
                                itemId='timeOraDestNuova'
                                maxLength={5}
                                requiredMessage="Questo campo &egrave; obbligatorio"
                            format='H:i'
                            required={true}
                            disabled={true}
                            width="100"
                            picker={{
                                meridiem: false,
                                alignPMInside: true
                            }}
                       />
                    </Panel>
                    <ComboBoxField
                        label="Tipo Traffico"
                        labelAlign="top"
                        store={contextVersioneRto.storeTipoTraffico}
                        displayField="desTipTra"
                        valueField="ideTipTra"
                        itemId="tipoTrafficoIdNuova"
                        required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                        queryMode="local"
                        disabled={true}
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                        listeners={{
                            change: function (combo, value, oldValue) {
                                if (value === 2) {
                                    if (Ext.ComponentQuery.query('#tipoVCOIdNuova')[0] !== undefined && Ext.ComponentQuery.query('#tipoVCOIdNuova')[0]._value === "3") {
                                        Ext.ComponentQuery.query('#committenteIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#committenteIdNuova')[0].setValue(null);
                                    }
                                    else{
                                        Ext.ComponentQuery.query('#committenteIdNuova')[0].enable();
                                    }
                                }
                                else {
                                    Ext.ComponentQuery.query('#committenteIdNuova')[0].disable();
                                    Ext.ComponentQuery.query('#committenteIdNuova')[0].setValue(null);
                                }
                            }

                        }}
                    />
                 </Panel>

                <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                    <ComboBoxField
                        label="Tipo VCO"
                        labelAlign="top"
                        store={contextVersioneRto.storeTipoVCO}
                        displayField="etichetta"
                        itemId="tipoVCOIdNuova"
                        valueField="valore"
                        required={true}
                        queryMode="local"
                            requiredMessage="Questo campo &egrave; obbligatorio"
                        forceSelection={true}
                        disabled={true}
                        typeAhead
                    listeners={{
                        change: function (combo, value, oldValue) {
                            if (parseInt(Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]._value) != null) {
                                calcolaCaso(parseInt(Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]._value), parseInt(value));
                                if (isAnnoOrAdeVCONUovaTraccia) {
                                    Ext.ComponentQuery.query('#accordoQuadroIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#timeOraDestNuova')[0].enable();
                                    Ext.ComponentQuery.query('#destinazioneIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#timeOraPartNuova')[0].enable();
                                    Ext.ComponentQuery.query('#origineIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#categoriaIFIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].disable();
                                    //anno orario
                                    if (parseInt(Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]._value) === 0) {
                                        Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].setValue(null);
                                    }
                                    //adeguamento
                                    else if (parseInt(Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]._value) === 1) {
                                        Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].enable();
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].setValue(null);
                                    }
                                    //vco
                                    else if (parseInt(Ext.ComponentQuery.query('#periodoRtoIdNuova')[0]._value) === 2) {
                                        Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].enable();
                                        Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].enable();
                                    }
                                }
                                else if (isVcoAdeVariazione) {
                                    Ext.ComponentQuery.query('#accordoQuadroIdNuova')[0].disable();
                                    Ext.ComponentQuery.query('#accordoQuadroIdNuova')[0].setValue(null);
                                    Ext.ComponentQuery.query('#committenteIdNuova')[0].disable();
                                    Ext.ComponentQuery.query('#committenteIdNuova')[0].setValue(null);
                                    Ext.ComponentQuery.query('#tipoVCOIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#rtoOriginariaIdNuova')[0].enable();
                                    Ext.ComponentQuery.query('#dataProvvedimentoIdNuova')[0].enable();
                                    if (parseInt(value) === 3) //solo servizi
                                    {
                                        Ext.ComponentQuery.query('#categoriaIFIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#categoriaIFIdNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#timeOraDestNuova')[0].disable();
                                        Ext.ComponentQuery.query('#timeOraDestNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#destinazioneIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#destinazioneIdNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#timeOraPartNuova')[0].disable();
                                        Ext.ComponentQuery.query('#timeOraPartNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#origineIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#origineIdNuova')[0].setValue(null);
                                        Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0].disable();
                                        Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0].setValue(null);
                                    }
                                    else //traccia e servizi
                                    {
                                        Ext.ComponentQuery.query('#categoriaIFIdNuova')[0].enable();
                                        Ext.ComponentQuery.query('#timeOraDestNuova')[0].enable();
                                        Ext.ComponentQuery.query('#destinazioneIdNuova')[0].enable();
                                        Ext.ComponentQuery.query('#timeOraPartNuova')[0].enable();
                                        Ext.ComponentQuery.query('#origineIdNuova')[0].enable();
                                        Ext.ComponentQuery.query('#tipoTrafficoIdNuova')[0].enable();
                                    }
                                }
                            }
                        }

                    }}
                        // onSelect={onSelectPeriodoRTO}
                    />
                    <TextField clearable={false} disabled={true} label="Protocollo RFI" itemId="prtotocolloRFIIdNuova" labelAlign="top" head="20" minwidth="200" />
                    <ComboBoxField
                        label="Categoria IF"
                        labelAlign="top"
                        store={contextVersioneRto.storeCatTreniIF}
                        displayField="desCatTre"
                        valueField="ideCatTre"
                        queryMode="local"
                        required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                        labelCls='LabelRiepilogoBlu'
                        itemId="categoriaIFIdNuova"
                        disabled={true}
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                    />
                    <TextField clearable={false} label="Giorni RTO" itemId="giorniRTOIdNuova" labelCls='LabelRiepilogoBlu' labelAlign="top" disabled={true} head="20" />
                    <ComboBoxField
                        label="Accordo Quadro"
                        labelAlign="top"
                        store={contextVersioneRto.storeAccordiQuadro}
                        displayField="desAcoQrd"
                        valueField="ideAcoQrd"
                        itemId="accordoQuadroIdNuova"
                        queryMode="local"
                        disabled={true}
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                    />
                </Panel>

                <Panel layout="vbox" margin="0 0 0 5" flex={1}>
                     <Button iconCls={'x-fa fa-search'} cls="marginButton appButtonRTO" text="RTO Originaria" labelAlign="top" margin= "23 0 5 0" itemId="rtoOriginariaIdNuova" head="20" disabled={true} handler={handleTextFieldClick} />
                    <TextField clearable={false} label="Data Prot.RFI" itemId="dataProtocolloRFIIdNuova" labelAlign="top" head="20" disabled={true} />
                    <ComboBoxField
                        label="Committente"
                        labelAlign="top"
                        itemId="committenteIdNuova"
                        labelCls='LabelRiepilogoBlu'
                        store={contextVersioneRto.storeCommittenti}
                        displayField="nomeCommittente"
                        valueField="ideCommittente"
                        queryMode="local"
                        disabled={true}
                        forceSelection={true}
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                    />
                    <ComboBoxField
                        label="Tipo Traccia"
                        labelAlign="top"
                        itemId="tipoTracciaIdNuova"
                        store={contextVersioneRto.storeTipoTraccia}
                        displayField="desTipTraccia"
                        valueField="ideTipTraccia"
                        disabled={true}
                        queryMode="local"
                        typeAhead
                        // onSelect={onSelectPeriodoRTO}
                    />
                    <ComboBoxField
                            label="Lotto"
                            labelAlign="top"
                            store={storeLotti}
                            displayField="nomLtx"
                            itemId="lottoIdNuova"
                            valueField="ideLtx"
                            required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            queryMode="local"
                            forceSelection={true}
                            typeAhead
                        // onSelect={onSelectPeriodoRTO}
                    />
                </Panel>

            </FormPanel>


            <Panel
                title="Note"
                iconCls="x-fa fa-sticky-note"
                collapsible={{ collapsed: false, direction: 'top' }}
                cls='PnlSectionL1'
                padding={3}
            >
                <FormPanel >
                    <TextAreaField
                        flex={1}
                            maxRows={10}
                            maxLength={1000}
                        itemId="noteIdNuova"
                        clearable={false}
                    />
                </FormPanel>
            </Panel>

            </Panel>
        </Container>
    );
}

export default RtoRiepilogoNuovaRto;