import React from 'react';
import { Panel, Container, Grid, Toolbar, SegmentedButton, Button, Column, FileField, SelectField, DatePickerField, Loadmask } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';
import allegatoClienteModel from '../models/AllegatoClienteModel.js';

const Ext = window['Ext'];

const storeAllegatiCliente = Ext.create('Ext.data.Store', {
    model: allegatoClienteModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

let arrayTipologiaAllegati = [];

function ClienteAllegati(props) {
    const { user, switchUser } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    var readonly = user.ruolo == 'RFI' ? false : true;

    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento in corso..." });

    //READ FILE CONVERT TO ARRAY -----------
    let selectedFile = "";
    let byteArray = null;

    const caricaStoreAllegati = (ideCli) => {
        Ext.Ajax.request({
            url: 'Clienti/LeggiListaAllegati',
            method: 'GET',
            params: { IdeCli: ideCli },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                storeAllegatiCliente.loadData(returnObj.lista);
                arrayTipologiaAllegati = [];
                for (let i = 0; i < returnObj.tipologie.length; i++) {
                    if (returnObj.tipologie[i].ideTipAlg > 0) {
                        let app = { text: returnObj.tipologie[i].desTipAlg, value: returnObj.tipologie[i].ideTipAlg };
                        arrayTipologiaAllegati.push(app);
                    }
                }
                Ext.ComponentQuery.query('#tipoFileAllegati')[0].setOptions(arrayTipologiaAllegati);
            }
        });
    }

    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridAllegatiCliente')[0];
        let title = " Elenco allegati " + props.cliente.cliente;
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }

    // Lettura file
    const fileToByteArray = (args) => {
        selectedFile = args.newValue;
        if (selectedFile !== "") {
            var fileInput = args.sender.el.down('input[type=file]').dom;
            if (fileInput.files.length > 0) {
                var file = fileInput.files[0];

                reader(file, (err, res) => {
                    if (err != null) {
                        Ext.toast({ message: 'Errore: ' + err, timeout: 4000 });
                    }
                    else {
                        let base64result = res.split(',')[1];
                        byteArray = base64result;
                        Ext.toast({ message: 'File Letto!', timeout: 4000 });
                    }
                });        

            }
        }

    }
    function reader(file, callback) {
        const fr = new FileReader();
        fr.onload = () => callback(null, fr.result);
        fr.onerror = (err) => callback(err);
        fr.readAsDataURL(file);
    }

    const fileSubmit = () => {
        let tipoAllegato = Ext.ComponentQuery.query('#tipoFileAllegati')[0].getValue();
        let dataComunicazione = Ext.ComponentQuery.query('#dfDataComunicazione')[0].getValue();
        let nomFile = Ext.ComponentQuery.query('#ffAllegatiCli')[0]._value;

        if (tipoAllegato > 0 && dataComunicazione != null && nomFile != null) {
            setStateLoadMask({ hidden: false, message: "Attendere inserimento in corso..." });

            Ext.Ajax.request({
                url: 'Clienti/InserisciAllegato',
                method: 'POST',
                params: {
                    ideCli: props.cliente.ideCli,
                    ideCliMse: props.cliente.anagraficaCliente.idCliMse,
                    nomFie: nomFile,
                    ideTipAlg: tipoAllegato,
                    datCmc: dataComunicazione,
                    file: byteArray
                },
                callback: function (records, success, operation) {
                    if (success) {
                        var returnObj = Ext.decode(operation.responseText);
                        if (returnObj.resp === "OK") {
                            caricaStoreAllegati(props.cliente.ideCli);
                            Ext.toast({ message: 'File:' + nomFile + ' inserito con successo', timeout: 4000 });
                            //console.log('File:' + nomFile + ' inserito con successo');
                        } else {
                            Ext.Msg.alert('Inserimento fallito', returnObj.msgErr);
                        }
                    } else {
                        Ext.Msg.alert('Inserimento fallito', "Errore in inserimento dell'allegato");
                    }
                    setStateLoadMask({ hidden: true });
                }
            });
        }
        else
            Ext.Msg.alert('Attenzione', "Completare l'inserimento di tutti i campi per l'upload");
    }

    // Azioni ----------------------------------
    const onViewFileClick = (grid, info) => {
        setStateLoadMask({ hidden: false, message: "Attendere apertura in corso..." });
        Ext.Ajax.request({
            url: 'Clienti/LeggiAllegato',
            method: 'GET',
            params: { idFile: info.record.data.ideAlg },
            callback: function (records, success, operation) {
                if (success) {
                    //console.log('File id:' + info.record.data.ideAlg + ' scaricato con successo');
                    var returnObj = Ext.decode(operation.responseText);
                    if (returnObj.resp == "OK") {
                        // Converti la stringa Base64 in un array di byte
                        let byteArray = new Uint8Array(atob(returnObj.file).split('').map(char => char.charCodeAt(0)));
                        openInNewTab(byteArray)
                    }
                    else
                        Ext.Msg.alert('Lettura pdf fallita', returnObj.msgErr);
                }
                else {
                    Ext.Msg.alert('Lettura pdf fallita', "Errore in lettura del Pdf");
                }
                setStateLoadMask({ hidden: true });
            }
        });
    }

    const onEliminaFileClick = (grid, info) => {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione dell'allegato?", function (button) {
            if (button === 'yes') {
                setStateLoadMask({ hidden: false, message: "Attendere cancellazione in corso..." });
                Ext.Ajax.request({
                    url: 'Clienti/EliminaAllegato',
                    method: 'GET',
                    params: { idAllegato: info.record.data.ideAlg },
                    callback: function (records, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            if (returnObj.resp === "OK") {
                                //console.log('File id:' + info.record.data.ideAlg + ' eliminato con successo');
                                caricaStoreAllegati(props.cliente.ideCli);
                                Ext.toast({ message: 'Allegato eliminato', timeout: 4000 });
                            }
                            else {
                                Ext.Msg.alert('Cancellazione fallito', returnObj.msgErr);
                            }
                        }
                        else {
                            Ext.Msg.alert('Cancellazione fallita', "Errore nella cancellazione dell'allegato");
                        }
                        setStateLoadMask({ hidden: true });
                    }
                });
            }
        })
    }

    const onModificaFileClick = (grid, info) => {
        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 0);
        }
    }
    // --------------------------------------------
    const openInNewTab = (byteArray) => {
        // Creare un Blob dal tuo array di byte
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Aprire una nuova scheda del browser con il PDF
        var url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');

        // Rilasciare l'URL dell'object quando non è più necessario
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        caricaStoreAllegati(props.cliente.ideCli);
    }, []);

    function renderColorRow(value, record, field, metaData) {
        if (record != null) {
            let row = metaData.row;
            if (record.data.ideTipAlg == 2) {
                row.setStyle("background:#FFE4AC;");
            }
            else {
                row.setStyle("background:#FFFFFF;");
            }
        }
        return value;
    }

    return (
        <Panel
            title="Allegati"
            scrollable
            itemId="panelAllegatiCliente"
            // bodyPadding={5}
            titleCollapse={true}
            iconCls="x-fa fa-paperclip"
            collapsible={{ collapsed: true, direction: 'top' }}
            cls='PnlSectionL1 iconPanelsRTO'
        >
            <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
                <Loadmask
                    cls="custom-grid"
                    itemId="loadMaskRtoDet"
                    hidden={stateLoadMask.hidden}
                    indicator={true}
                    message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                    modal={true}
                />
                <Panel layout="vbox" scrollable="true">

                    <Panel layout="hbox" height={35} margin='10 10 0 10' hidden={readonly}>

                        <SelectField
                            itemId="tipoFileAllegati"
                            label='Tipo File'
                            labelWidth={65}
                            width={280}
                            height="32"
                            name="cmbTipoFile"
                            labelAlign='left'
                            options={arrayTipologiaAllegati}
                            displayField="text"
                            valueField="value"
                            required={true}
                        />
                        <DatePickerField
                            margin="0 0 0 10"
                            clearable={false}
                            label="Data Comunicazione"
                            itemId="dfDataComunicazione"
                            labelAlign="left"
                            labelWidth={135}
                            height="32"
                            required={true}
                            requiredMessage="Questo campo &egrave; obbligatorio"
                            destroyPickerOnHide
                            picker={{
                                yearFrom: 1990
                            }}
                        />
                        <Panel margin="-25 0 0 10">
                            <FileField cls="marginButton appButton" itemId="ffAllegatiCli" buttontext="Sfoglia" title='title' width={450} label="File pdf..." accept='application/pdf' margin="0 10 0 0"
                                onChange={fileToByteArray}
                                onAction={fileToByteArray}
                            />
                        </Panel >

                        <SegmentedButton docked="right" height={35}>
                            <Button cls="marginButton appButton" text="Carica" ui="action" handler={fileSubmit} />
                            <Button cls="marginButton exportButton" text="Esporta" ui="action" handler={onDownloadClick} iconCls="x-fa fa-table" />
                        </SegmentedButton>



                    </Panel >

                    <Grid
                        flex={1} scrollable itemId="gridAllegatiCliente"
                        selectable={{ checkbox: false, mode: 'single' }}
                        store={storeAllegatiCliente}
                        plugins={{ gridfilters: true, gridexporter: true, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" } }}
                        // onSelect= {onItemclick}
                        itemConfig={{ viewModel: true }}
                        cls="column-in-panel"
                        minHeight='200'
                        margin='5 0 0 0'
                        listeners={{
                            canceledit: function (editor, context) {
                                if (context.record.phantom) {
                                    context.editor.grid.store.remove(context.record);
                                }
                            },
                            edit: function (grid, cdsNew) {
                                // modifica
                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                Ext.Ajax.request({
                                    url: 'Clienti/ModificaAllegato',
                                    method: 'POST',
                                    params: {
                                        idAllegato: cdsNew.record.data.ideAlg,
                                        ideTipAlg: cdsNew.record.data.ideTipAlg,
                                        datCmc: cdsNew.record.data.datCmc
                                    },
                                    callback: function (records, success, operation) {
                                        if (success) {
                                            var returnObj = Ext.decode(operation.responseText);
                                            //modifica gestione errore
                                            if (returnObj.resp === "KO") {
                                                Ext.Msg.alert('Modifica fallita', returnObj.msgErr)
                                            }
                                            else {
                                                Ext.toast({ message: 'Modifica effettuata', timeout: 4000 });
                                                caricaStoreAllegati(props.cliente.ideCli);
                                            }
                                        }
                                        else {
                                            Ext.Msg.alert('Modifica fallita', 'Errore nella modifica della data');
                                        }

                                    }
                                })
                            }
                        }}
                    >

                        <Column height="30" width={300} text="Nome File" dataIndex="nomFie" filter='string' renderer={renderColorRow} />
                        <Column flex={1} text="Cliente" dataIndex="nomCli" filter='string' autoSizeColumn="true" />
                        <Column width={200} text="Cliente Master" dataIndex="rge" filter='string' />
                        <Column width={150} text="Data Inserimento" dataIndex="datImo" type="datecolumn" formatter='date("d/m/Y")' filter='date' />
                        <Column width={150} text="Data Comunicazione" dataIndex="datCmc" type="datecolumn" formatter='date("d/m/Y")' filter='date'
                            editor={{ xtype: 'datefield', formatter: 'date("d/m/Y")', triggerAction: 'all', itemId: "dataCom" }}
                        />
                        <Column width={200} text="Tipo Allegato" dataIndex="desTipAlg" filter='string' />
                        <Column width={200} text="Dimensioni(MB arrotondato)" dataIndex="byt" filter='string' />

                        <Column text="Azioni" ignoreExport
                            cell={{
                                tools: {
                                    viewFile: {
                                        handler: onViewFileClick.bind(this),
                                        bind: {
                                            iconCls: "x-fa fa-folder-open",
                                            tooltip: 'Visualizza'
                                        }
                                    },
                                    modificaFile: {
                                        hidden: readonly,
                                        handler: onModificaFileClick.bind(this),
                                        bind: {
                                            iconCls: "x-fa fa-edit",
                                            tooltip: 'Modifica'
                                        }
                                    },
                                    eliminaFile: {
                                        hidden: readonly,
                                        handler: onEliminaFileClick.bind(this),
                                        bind: {
                                            iconCls: "x-fa fa-trash",
                                            tooltip: 'Elimina'
                                        }
                                    }
                                }
                            }}
                        />

                    </Grid>

                </Panel>

            </Container>
        </Panel>
    );
}

export default ClienteAllegati;