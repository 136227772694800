import React from 'react';
import { Panel, Container, FormPanel, Loadmask } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUser } from '../components/UserContext';
import { useNavigate } from "react-router-dom";

import Testata from './ClienteTestata';
import Anagrafica from './ClienteAnagrafica';
import Licenza from './ClienteLicenza';
import Titolo from './ClienteTitolo';
import CdS from './ClienteCdS';
import Referenti from './ClienteReferenti';
import DirittiAccesso from './ClienteDirittiAccesso';
import Allegati from './ClienteAllegati';

const Ext = window['Ext'];
const storeClienteSchede = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Clienti/LeggiSchedaCliente',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

function Cliente() {

    const { user, switchUser } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    let navigate = useNavigate();
    const location = useLocation();
    const { ideCli: stateIdeCli, action: stateAction } = location.state || {};
    const ideCli = isIF ? user.ideCli : stateIdeCli;
    const action = isIF ? 'view' : stateAction;

    const [state, setState] = useState({ tipoAzione: action, schedaCliente: null, titolo: "", licenzaCliente: null});
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: false, message: "Attendere caricamento scheda..." });


    const caricaSchedaCliente = (ideCli) => {

        let msgErrore = 'Errore caricamento schede';
        storeClienteSchede.clearData();
        storeClienteSchede.removeAll();
        let params = { ideCli: ideCli };
        storeClienteSchede.load({
            params: params,
            callback: function (records, options, success) {
                if (success && records.length > 0) {
                    if (records[0].data.resp === "KO") {
                        Ext.Msg.alert('Caricamento Scheda Cliente', records[0].data.msgErr);
                        setStateLoadMask({ hidden: true })
                    }
                    else {
                        aggiornaState();
                    }
                }
                else {
                    Ext.Msg.alert('Caricamento Scheda Cliente', msgErrore);
                    setStateLoadMask({ hidden: true })
                }
            }
        });
    }

    const aggiornaState = () => {
        if (storeClienteSchede.getCount() > 0) {
            let schede = storeClienteSchede.data.items[0].data;

            // Impostazione Titolo -------------------------------------------------------------------------------------------------------------
            state.titolo = "Gestione Cliente - " + schede.cliente.nomCli;
            //---------------------------------------------------------------------------------------------------------------------------------
            state.schedaCliente = schede;
            state.licenzaCliente = schede.licenze[0];
        }
        setState({ ...state });
        setStateLoadMask({ hidden: true })
    }
    const aggiornaLicenza = () => {
        
        Ext.Ajax.request({
            url: 'Clienti/LeggiLicenzePerMse',
            method: 'get',
            params: { IdeCliMse: state.schedaCliente.cliente.ideCliMse },
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);
                    state.licenzaCliente = returnObj[0];
                    setState({ ...state });
                }
            }
        });
    }

    const toolHandler = (panel, tool) => {

        if (tool.config.type === 'close') {
            navigate('/Clienti');
        }
    }

    useEffect(() => {
        caricaSchedaCliente(ideCli)
    }, [ideCli]);


    return (

        <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask cls="custom-grid" itemId="loadMaskRtoDet" hidden={stateLoadMask.hidden}
                indicator={false}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true} />

            <Panel
                layout="vbox"
                cls="iconTrainRTO"
                iconCls="x-fa fa-user"
                title={state.titolo}
                docked="top"
                tools={[
                    { iconCls: 'x-fa fa-xmark buttonPanelTool', tooltip: 'Chiudi', type: 'close', handler: toolHandler, hidden: isIF}
                ]}
            />

            <Panel
                itemId="Cliente"
                scrollable
                flex={1}
            >
                <Container>
                    <Testata cliente={state.schedaCliente != null ? state.schedaCliente.cliente : null} />
                </Container>
                {state.schedaCliente != null && <Container>
                    <Container>
                        <Anagrafica cliente={state.schedaCliente.cliente} anagrafica={state.schedaCliente.anagraficaCliente} />
                    </Container>

                    <Container>
                        <Panel
                            title="Requisiti per richiedere tracce"
                            scrollable
                            itemId="panelRequisiti"
                            bodyPadding={5}
                            titleCollapse={true}
                            iconCls="x-fa fa-th-list"
                            collapsible={{ collapsed: true, direction: 'top' }}
                            cls={(state.schedaCliente.cliente.ideTipIpr == 2 ? 'PnlSectionCML1 ' : 'PnlSectionL1 ') + ' iconPanelsRTO'}
                        >
                            <Container>
                                <Licenza cliente={state.schedaCliente.cliente} licenza={state.licenzaCliente} aggiornaLicenza={aggiornaLicenza} />
                            </Container>
                            <Container>
                                <Titolo cliente={state.schedaCliente.cliente} licenza={state.licenzaCliente} />
                            </Container>
                            <Container>
                                <CdS cliente={state.schedaCliente.cliente} licenza={state.licenzaCliente} />
                            </Container>
                        </Panel>
                    </Container>

                    <Container>
                        <Referenti cliente={state.schedaCliente != null ? state.schedaCliente : null} />
                    </Container>
                    <Container>
                        <DirittiAccesso cliente={state.schedaCliente != null ? state.schedaCliente : null} />
                    </Container>
                    <Container>
                        <Allegati cliente={state.schedaCliente != null ? state.schedaCliente : null} />
                    </Container>
                </Container>}
            </Panel>
        </Container>
    );
}

export default Cliente;