import { useState, React, useEffect } from 'react';
import { Container, Panel, Titlebar, Grid, Button, FileField, SegmentedButton, Column, Toolbar, Combobox, Loadmask } from '@sencha/ext-react-modern';
import { useUser } from '../components/UserContext';
import { useAmbiente } from '../components/AmbienteContext';
import { useNavigate } from "react-router-dom";
//import * as XLSX from 'xlsx'; //serve per leggere e trasformare file Excel in array

const Ext = window['Ext'];

Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    'Ext.grid.plugin.Exporter'
]);

//  -----------PERIODI FERROVIARI MENU ----------------------------------------------
    const storePeriodiFerroviari = Ext.create('Ext.data.Store', {
        proxy: {
            method: 'GET',
            type: 'ajax',
            url: 'Anagrafiche/LeggiPeriodiFerroviari',
            reader: {
                type: 'json'
            }
        },
        autoload: false
    });


const storePeriodiFerroviariAttivi = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiPeriodiFerroviariAttivi',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

//----------Elenco File Tabella------------------------------------------------
const storeuploadXlsInfoRto = Ext.create('Ext.data.Store', {

        proxy: {
            method: 'GET',
            type: 'ajax',
            url: 'Rto/LeggiElencoFileXLS',
            reader: {
                type: 'json'
            }
        },
        autoload: false
});


//-----------GESTIONE LOTTI COMBO MENU -------------------------------------------------------------
const storeuploadXlsComboLotti = Ext.create('Ext.data.Store', {

    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Lotti/LeggiLottiWrapperUploadRto',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});


function UploadRTO() {
    let uploadTimerPause = false;
    const { user, switchUser } = useUser();
    const { ambiente, updateAmbiente } = useAmbiente();
    const [stateAmbiente, setStateAmbiente] = useState({ ideUte: ambiente.ideUte, ideAnnFro: ambiente.ideAnnFro, ideTipPer: ambiente.ideTipPer });
    const [statePF, setStatePF] = useState({ firstOnSelectComboPF: true });
    const [disableCarica, setDisableCarica] = useState(false);  // disabilita bottone di caricamento del file
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento in corso..." });
    const [stateFile, setStateFile] = useState({ selectedFile: null });
    const navigate = useNavigate();

    let title = " Upload RTO";
    let ui = 'action';

//--------------Elenco File Tabella------------------------------------------------
    const caricaElencoUpload = (ideCli, pIdeAnnFro, uploadTimerPause) => { 
        if (uploadTimerPause === false) {
            storeuploadXlsInfoRto.load({
                params: { ideCli: ideCli, ideAnnFro: pIdeAnnFro },
                callback: function () {

                }
            });
        }
    }
//---------------COMBO GESTIONE LOTTI------------------------------------
    const caricaLotti = (pIdeAnnFro) => {
        storeuploadXlsComboLotti.load({
            params: {
                ideUte: user.ideUte,
                ideCli: user.ideCli,
                ideAnnFro: pIdeAnnFro
            }
        });
    }

    function onSelectLotto(idLotto) {

        if (idLotto === -2) {
            navigate('/Lotto');
          //  Ext.toast({ message: ' Lotto !', timeout: 4000 });

        }
    }

//-------------GRID ROW EXPANDER MESSAGGIO ELABORAZIONE-----------

    const tplLog = data => (
        <div className='rowexpander'>
            <div id='singlecolumn'>
                {data.logElaborazione?.split("\n").map((i, key) => {
                    return <div key={key}>{i}</div>;
                })}
            </div>   
        </div>
    )
    
//------------------------ELIMINA FILE-----------------------------------------------------

    function onDeleteClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione del File?", function (button) {

            if (button === 'yes') {
                Ext.Ajax.request({
                    url: 'Rto/EliminaFileExcel',
                    method: 'POST',
                    params: {                        
                        ideUplXLS: info.record.data.ideUplXLS    
                    },
                    callback: function (records, success, operation) {                      
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            //modifica gestione errore
                            if (returnObj.resp === "KO") {
                                Ext.Msg.alert('Eliminazione File Fallita', returnObj.msgErr)
                            }
                            else {
                                //updateValuesParams(user.ideUte, user.ideCli, Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0]._value)
                                Ext.toast({ message: 'File Eliminato!', timeout: 4000 });
                                caricaElencoUpload(user.ideCli, stateAmbiente.ideAnnFro, uploadTimerPause);
                            }
                        }
                        else {
                            Ext.Msg.alert('Operazione Fallita', 'Elimina File Fallito');
                        }
                    }
                });

            }
        })

    }

//-----------------------SCARICA FILE---------------------------------------------

    const downloadBlob = (ideUplXLS,nomeFile) => {
        let url = `Rto/LeggiBlob?ideUplXLS=${ideUplXLS}`;  
        let msgKo = 'Esportazione fallita';

        fetch(url)
            .then(response => {
                // Estre il nome del file dall'intestazione Content-Disposition
                let archiveNameRTO = nomeFile + '.xls';
                const contentDisposition = response.headers.get('Content-Disposition');
                const regex = /filename\*=UTF-8''(.+?)(?=';|$)/;
                const match = regex.exec(contentDisposition);

                if (match) {
                    archiveNameRTO = match[1];
                }

                return { response, archiveNameRTO };
            })
            .then(({ response, archiveNameRTO }) => response.blob()
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = archiveNameRTO; // Utilizza il nome del file recuperato
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                })
            )
        .catch(error => {
            Ext.Msg.alert('Operazione fallita', msgKo);
        });
    }
    function onDownloadFileClick(grid, info) {     
        if(info !== null){
            downloadBlob(info.record.data.ideUplXLS, info.record.data.nomeFile);
        }   
    }

    //------------------Info Pagina Guida---------------

    const onInfoClick = () => {
       
        window.open('GuidaUpload')
    }
    const onRefreshClick = () => { caricaElencoUpload(user.ideCli, stateAmbiente.ideAnnFro, uploadTimerPause); }

    //------PERIODO FERROVIARIO-------------------------------------------------------------
    function onSelectPeriodoFerroviario(e) {
        // questa parte serve se per salvare la nuova configurazione del periodo ferroviario
        if (!statePF.firstOnSelectComboPF) {
            if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != undefined && Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0] != null) {
                if (Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue() != stateAmbiente.ideAnnFro) {                    
                    let valTipPer = stateAmbiente.ideTipPer;                   
                    updateAmbiente({ ideUte: user.ideUte, ideAnnFro: e.newValue.data.ideAnnFro, ideTipPer: valTipPer }).then(
                        function (result) {
                            aggiornaStateAmbiente();
                            //console.log('sono in updateAmbiente UploadRTO SPR ideAnnFro = ', Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue());
                            //console.log('sono in updateAmbiente UploadRTO SPR ideTipPer = ', valTipPer);
                        }
                    );
                }
            }
        }
    }
    //-----------------------------------------------------------------------------

    const initComboAmbiente = () => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].setValue(ambiente.ideAnnFro);

            statePF.firstOnSelectComboPF = false;
            setStatePF({ ...statePF });

            caricaLotti(ambiente.ideAnnFro);
        }
    };
    const aggiornaStateAmbiente = () => {
        stateAmbiente.ideAnnFro = Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue();
        setStateAmbiente({ ...stateAmbiente });
        setDisableCarica(storePeriodiFerroviariAttivi.findRecord("ideAnnFro", Ext.ComponentQuery.query('#comboPeriodoFerroviario')[0].getValue(), 0, false, false, true) != null ? false : true);
        caricaLotti(stateAmbiente.ideAnnFro);
    }

    useEffect(() => {
        storePeriodiFerroviari.load();
        storePeriodiFerroviariAttivi.load();
    }, []);

    useEffect(() => {
        if (ambiente?.ideUte && statePF.firstOnSelectComboPF) {
            stateAmbiente.ideAnnFro = ambiente.ideAnnFro;
            stateAmbiente.ideTipPer = ambiente.ideTipPer;
            setStateAmbiente({ ...stateAmbiente });

            initComboAmbiente();
        }
    }, [ambiente]);

//----------------//aggiornamento intervalli-------------------------------------------

    useEffect(() => {
            caricaElencoUpload(user.ideCli, stateAmbiente.ideAnnFro, uploadTimerPause);
            const interval = setInterval(() => {
                caricaElencoUpload(user.ideCli, stateAmbiente.ideAnnFro, uploadTimerPause); //aggiorna ogni 90 sec
            }, 90000);

            return () => clearInterval(interval);
        
    }, []);
//------------------------------------------------------------------------------------

//------------------READ EXCEL FILE CONVERT TO ARRAY --------------------------
    let excelFile = "";

    //let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;



    const handleFileChange = (fileField, value) => {
        const file = fileField.sender.el.down('input[type=file]').dom.files[0];
        stateFile.selectedFile = file;
        setStateFile({ ...stateFile});
    };

    const handleUploadClick = () => {
        uploadTimerPause = true;
        setStateLoadMask({ hidden: false, message: "Attendere caricamento in corso..." });
        const selectedFile  = stateFile.selectedFile;
        if (!selectedFile) {
            alert('Seleziona un file prima di procedere.');
            return;
        }
        const formData = new FormData();
        formData.append('ideUte', user.ideUte); // Esempio di valore per ideUte
        formData.append('ideLtx', Ext.ComponentQuery.query('#comboGestioneLotti')[0]._value); // Esempio di valore per ideLtx
        formData.append('nomeFile', selectedFile.name);
        formData.append('file', selectedFile);

        fetch('Rto/UploadFileExcel', {
            method: 'POST',
            body: formData,
            timeout: 300000
        })
            .then(response => response.json())
            .then(data => {
                uploadTimerPause = false;
                // Gestisci la risposta dal controller
                if (data.resp === "OK") {
                    Ext.toast({ message: 'File Caricato', timeout: 4000 });
                }
                else if (data.resp === "OKWITHERR") {
                    alert('Errori durante il caricamento del file');
                }
                else {
                    alert('Errore durante il caricamento del file: ' + data.msgErr);
                }
                setStateLoadMask({ hidden: true });
                caricaElencoUpload(user.ideCli, stateAmbiente.ideAnnFro, uploadTimerPause);
            })
            .catch(error => {
                alert('Errore durante il caricamento del file: ' + error.message);
            });
    };




    //--------------DOWNLOAD GRID----------------------
    function onDownloadClick() {
        Ext.toast({ message: 'Scarica File', timeout: 4000 });

        var grid = Ext.ComponentQuery.query('#gridUploadRto')[0];
        grid.saveDocumentAs({
            type: 'xlsx',
            title: title,
            fileName: title + '.xlsx'
        });
    }
    const DOCUMENT_URL = "/Documento";
    const getDocumento = (idPag) => {
        window.open(DOCUMENT_URL + "?idPag=" + idPag, '_blank');
      };
    return (
        <Container height="100%" width="100%" layout="fit" margin='3 0 0 0'>           

            <Panel layout="vbox" scrollable="true">
                <Titlebar title={title} margin='0 0 4 0' docked="top" layout="hbox" cls="title-text" flex={1}>
                    <Button align="left" cls="title-icon" iconCls="x-fa fa-upload" />
                    <Button align="right" cls="title-icon" iconCls="x-fa fa-circle-question" handler={() =>getDocumento(16)}/>
                    <Combobox
                        itemId="comboPeriodoFerroviario"
                        align="right"
                        width={170}
                        margin='0 4 0 0'
                        editable="false"
                        valueField="ideAnnFro"
                        displayField="desAnnFro"                     
                        queryMode="local"
                        store={storePeriodiFerroviari}
                        onSelect={onSelectPeriodoFerroviario}
                        />
                </Titlebar>

                <Loadmask cls="custom-grid" itemId="loadMaskRtoDet" hidden={stateLoadMask.hidden}
                    indicator={false}
                    message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                    modal={true} />

                <Grid
                    flex={1}
                    scrollable
                    itemId="gridUploadRto"
                    selectable={{ checkbox: false, mode: 'single' }}
                    store={storeuploadXlsInfoRto}
                    cls="custom-grid"
                    plugins={{ rowexpander: true, gridfilters: true, gridexporter: true }}
                    // onSelect= {onItemclick}
                    itemConfig={{
                        body: {
                            tpl: tplLog
                        },
                        viewModel: true
                    }}
                    loadingText='<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><span class=spinMessage>Caricamento...</span></div>'
                   
                >


                    <Toolbar docked="top" margin="10 0 15 10" >
                        <Button align="bottom" docked="left" height={30} margin="0 0 0 0" text="Refresh" iconCls="x-fa fa-arrows-rotate" handler={onRefreshClick} hidden/>

                        <SegmentedButton docked="right" margin="0 0 0 -12" layout="hbox" >
                            <Button cls="marginButton appButton" text="Carica" ui={ui} handler={handleUploadClick} disabled={disableCarica}  />
                            <Button cls="marginButton exportButton" text="Esporta" ui={ui} handler={onDownloadClick} iconCls="x-fa fa-table" />

                        </SegmentedButton>

                        <Combobox
                            itemId="comboGestioneLotti"
                            width={260}
                            label="Lotto"
                            labelAlign="left"
                            labelWidth={50}
                            autoSelect="true"
                            align="right"
                            docked="right"
                            height="30"
                            margin='0 25 0 0'
                            editable="false"
                            valueField="ideLtx"
                            displayField="desLtx"
                            //autoSelect="true"
                            queryMode="local" 
                            store={storeuploadXlsComboLotti}
                            onSelect={
                                (list, record) =>
                                    (onSelectLotto(list.newValue.data.ideLtx))
                            }/>


                        <Panel docked="right" margin="-25 0 0 10">
                            <FileField
                                itemId="ffUploadRto"
                                cls="fileFieldButton marginButton"
                                buttontext="Sfoglia"
                                width="400"
                                title='title'
                                label="File..."
                                margin="0 30 0 0"
                                onChange={handleFileChange}
                            />
                        </Panel >
                    </Toolbar>

                    <Column height="40" width={70} text="Scarica"   cell={{                     
                        tools: {
                            download: {
                                handler: onDownloadFileClick.bind(this),
                                bind: {                                   
                                    iconCls: "x-fa fa-download buttonGridTool",
                                    tooltip: 'Scarica'                                                                     
                                }
                            }
                        }
                     
                    }}
                    />
                    <Column height="40" width={450} text="Nome File" dataIndex="nomeFile" filter='string' flex={1} />
                    <Column height="40" width={200} text="Tipo File" dataIndex="desTipoXLS" filter='string' autoSizeColumn="true" />
                    <Column height="40" width={200} text="Utente" dataIndex="desUte" filter='string' />
                    <Column height="40" width={150} text="Data Upload" dataIndex="dtUpload" type="datecolumn" formatter='date("d/m/Y")' filter='date' />
                    <Column height="40" width={200} text="Stato" dataIndex="desStatoElab" filter='string'/>
                    <Column height="40" width={150} text="Lotto" dataIndex='nomLtx' filter='string' flex={1} />
                    <Column height="40" width={65} text="Elimina"   cell={{                
                        tools: {
                            delete: {
                                handler: onDeleteClick.bind(this),
                                bind: {
                                  //  iconCls: 'ideUplXLS !== null ? "x-fa fa-trash buttonGridTool" :  "x-fa fa-trash" ',
                                    hidden: '{record.desStatoElab === "ELABORAZIONE IN CORSO"}',
                                  //iconCls: "x-fa fa-trash buttonGridTool",
                                    cls: '{record.desStatoElab === "OK" ? "buttonToolDisabled" : "x-fa fa-trash buttonTool"}',
                                    tooltip: 'Elimina'
                                }
                            }

                        }
                    }}
                    />
                </Grid>

            </Panel>
        </Container>
    );


}

export default UploadRTO;


