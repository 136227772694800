import React from 'react';
import { Panel, Container, Button, Toolbar, Loadmask, Combobox, Spacer, Label } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { useUser } from '../components/UserContext';

const Ext = window['Ext'];

function RtoPopupCreaRtoDaPo(props) {

    const { user, switchUser } = useUser();
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere caricamento in corso..." });

    const onConfermaAzione = () => {
        setStateLoadMask({ hidden: false, message: "Attendere prego..." });
            Ext.ComponentQuery.query('#btnConferma')[0].setDisabled(true);


            Ext.Ajax.request({
                url: 'Rto/CopiaRTODaPO',
                method: 'POST',
                params: {
                    ideRTO: (props.data.record !== undefined ? props.data.record.data.ideRTO : props.data.ideRTO),
                    ideUte: user.ideUte,
                    ideTipGrp: user.idTipoGruppo,
                    ideTrcOra: Ext.ComponentQuery.query('#comboPo')[0].getSelection().data.ideTrcOra
                },
                timeout: 600000,
                callback: function (records, success, operation) {
                    setStateLoadMask({ hidden: true, message: "Attendere copia in corso..." });
                    if (success) {
                        var returnObj = Ext.decode(operation.responseText);

                        if (returnObj.resp === "OK") {
                            props.onModificaRtoDaPoRefresh(returnObj.rtoApp.ideRTO, returnObj.rtoApp.ideGrpRTO, returnObj.rtoApp.ideCli, returnObj.tipologia, returnObj.rtoApp.ideStoRTO);
                        }
                        else {
                            Ext.Msg.alert('Errore', returnObj.msgErr);
                        }
                    }
                    else {
                        Ext.Msg.alert('Conferma Azione', "Errore in copia RTO da PO");
                    }
                }
            });
    }

    const onChiudiAzione = () => {
        props.chiudiPopup();
    }

    return (
        <>
            <Container cls="container-spinner" height="100%" width="100%" layout="fit" >
                <Loadmask
                    cls="custom-grid"
                    itemId="loadMaskRtoDet"
                    hidden={stateLoadMask.hidden}
                    indicator={true}
                    message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                    modal={true}
                />
                <Container cls="containerCreaRtoDaPO" flex={1} layout={{ type: "hbox", align: "middle" }}>
                <Spacer flex={1} /> {/* Adjust flex configuration */}
                <Label
                    style={{
                        fontSize: "26px",
                        fontWeight: "bold",
                        width: "fit-content"
                    }}
                    html={"<span style='color: #157FCC;'><br><br>MODIFICA RTO DA PO<br>"}
                />
                <Label
                    style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        width: "fit-content"
                    }}
                        html={"Prot. RFI: " + (props.data.record !== undefined ? props.data.record.data.ptlRFI : props.data.ptlRFI) + "<br>"}
                />
                <Label
                    style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        width: "fit-content"
                    }}
                        html={"Treno: " + (props.data.record !== undefined ? props.data.record.data.numTrenoRFI : props.data.nomTreRFI) + "<br>"}
                />
                <Label
                    style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        width: "fit-content"
                    }}
                        html={"Origine: " + (props.data.record != undefined ? props.data.record.data.nomLocPrv : props.data.nomLocPrv) + " (" + (props.data.record != undefined ? props.data.record.data.oraPar : props.data.oraPar) + ")<br>"}
                />
                <Label
                    style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        width: "fit-content"
                    }}
                        html={"Origine: " + (props.data.record != undefined ? props.data.record.data.nomLocDsn : props.data.nomLocDsn) + " (" + (props.data.record != undefined ? props.data.record.data.oraArr : props.data.oraArr) + ")<br><br>"}
                />
                <Spacer flex={1} /> {/* Adjust flex configuration */}
                <Spacer flex={1} /> {/* Adjust flex configuration */}
                <Spacer flex={1} /> {/* Adjust flex configuration */}
                <Spacer flex={1} /> {/* Adjust flex configuration */}
                <Combobox
                        itemId="comboPo"
                        width={550}
                        margin='0 10 0 0'
                        align="center"
                        editable={false}
                        store={props.storeListaPO}
                        displayField="desTreRfi"
                        autoSelect={true}
                        queryMode="local"
                        valueField="ideTrcOra"
                        minHeigth={100}
                />
                <Spacer flex={1} /> {/* Adjust flex configuration */}
            </Container>
                    <Toolbar cls="pippo" docked="bottom" border={true} height={40}>
                <Panel docked="right">
                    <Button cls="appButton" ui="action" margin="5 10 5 10" align="right" itemId="btnConferma" iconCls="x-fa fa-square-check" text="Conferma" handler={onConfermaAzione}  />
                    <Button cls="appButton" ui="action" margin="5 10 5 10" align="right" itemId="btnChiudiRTO" iconCls="x-fa fa-square-check" text="Chiudi" handler={onChiudiAzione}  />
                </Panel>
                </Toolbar>
            </Container>
        </>
    )
}
export default RtoPopupCreaRtoDaPo;
