import React from 'react';
import { Panel,PanelTitle, Container, Button, Toolbar, Spacer, Label, TextField, CheckBoxField, Dialog } from '@sencha/ext-react-modern';
import { useState, useEffect } from "react";
import { Loadmask } from "@sencha/ext-react-modern";
import RtoNuova from './RtoNuova';
import RtoCopia from './RtoCopia';
import PopUpCreaRtoDaPo from './RtoPopupCreaRtoDaPo';
import RtoVersione from './RtoVersione';
import { useUser } from '../components/UserContext';   
import { useLocation } from 'react-router-dom';

const Ext = window['Ext'];

const storeListaPO = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Po/LeggiListTreniRTO',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});

const EditRtoButton = (props) => {
    const { user, switchUser } = useUser();
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;
    const onConfermaRto = () => {
        let chkVerAccettazione = 1;
        let ckBoxAcc = Ext.ComponentQuery.query('#chkRicAccRTO')[0];
        chkVerAccettazione = ckBoxAcc.getHidden() ? true : ckBoxAcc.getChecked();
        props.confermaRto(chkVerAccettazione);
    }

    return (
        <Panel
            docked="bottom"
            layout="hbox"
            cls="EditRtoConfirm"
        >
            <Toolbar cls="EditRtoConfirm" border={true} flex={1} height={40}>
                <Label padding="0 8 0 8" html={props.title} />
                <Spacer />
                <Spacer />               
                <CheckBoxField itemId="chkRicAccRTO" hidden={props.flgModTrc || isIF || !props.verInModifica} boxLabel="Richiedi accettazione modifica (cambio versione)" disabled={!(props.modificata) || !(props.checkPeriodII) || !(props.checkServII)} />
                <Button ui="action" align="right" itemId="btnConfermaRTO" disabled={props.flgModTrc ? false : (!(props.modificata) || !(props.checkPeriodII) || !(props.checkServII))} iconCls="x-fa fa-square-check" text="Conferma" handler={onConfermaRto} />
                <Button ui="action" align="right" itemId="btnAnnullaRTO" iconCls="x-fa fa-square-check" text="Annulla" handler={props.annullaRto} />
            </Toolbar>

        </Panel>
    );
}

const AccettaRtoButton = (props) => {
    const { user, switchUser } = useUser();
    let isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;

    let title =  "Nota rifiuto";
    const [stateButton, setStateButton] = useState({ accetta: true, rifiuta: true })

    const onRifiutaClick = () => {
        if (!isIF) {
            let notaRifiuto = Ext.ComponentQuery.query('#txtRifiuto')[0].getValue();
            if (notaRifiuto != "" && notaRifiuto != null)
                props.rifiutaModificaRto(notaRifiuto);
            else
                Ext.Msg.alert('Rifiuto modifiche Rto', "Inserire motivazione del rifiuto.");
        }
        else {
            props.rifiutaModificaRto(" ");
        }
    }
  
    const formatTesto = (testo) => {     
        let html = "<div class='dialogTxt'>";
        testo.split("\n\t").map((i, key) => {
            html = html + "<div key=" + key + ">" + i + "</div>";
        })
        html = html + "</div>";
                
        return html;
    }
    

    const onVisualizzaModifiche = () => {
        let rto = storeRtoSchede.data.items[0].data.rto;
  
        Ext.Ajax.request({
            url: 'Rto/LeggiMessaggioPosta',
            method: 'POST',
            params: {
                ideUte: user.ideUte,    
                ipAddress: "localhost",
                ideRTO: rto.ideRTO
            },
            callback: function (records, success, operation) {
                if (success) {
                    let returnObj = Ext.decode(operation.responseText);
                    
                    if (returnObj.resp == "OK") {

                        let html = formatTesto(returnObj.testo)

                        let dialogTesto = Ext.create({
                            xtype: 'dialog',
                            closable: "true",
                            title: 'Modifiche',
                            minWidth:'40%',
                            minHeight:'35%',
                            maxWidth:'70%',
                            maxHeight:'60%',
                            centered:'true',
                            scrollable:'true',
                            items: 
                                [
                                    {
                                        xtype: 'panel',
                                        scrollable: "true",
                                        style : { whiteSpace: 'pre-wrap', padding: '10px', border: '1px solid #ccc' },
                                        html: html
                                    }                                
                            ]
                        })                        
                        dialogTesto.show();
                            
                    }
                    else {
                        if (returnObj.resp == "KO")
                            Ext.Msg.alert('Dettaglio modifiche RTO', returnObj.msgErr);
                    }
                }
                else {
                    Ext.Msg.alert('Dettaglio modifiche RTO', "Errore in lettura delle modifiche.");
                }
            }
        });
    }

    return (
        <Panel
            docked="bottom"
            layout="hbox"
            cls="EditRtoConfirm"
        >
            <Toolbar cls="EditRtoConfirm" border={true} flex={1} height={40}>
                <Label padding="0 8 0 8" html={title} />
                <TextField hidden={isIF} itemId="txtRifiuto" margin="5 5 5 10" flex={1} />
                <Button ui="action" align="right" disabled={!stateButton.accetta} itemId="btnDettaglioMod" iconCls="x-fa fa-square-check" text="Dettaglio Modifiche" handler={onVisualizzaModifiche} />
                <Button ui="action" align="right" disabled={!stateButton.accetta} itemId="btnAccettaMod" iconCls="x-fa fa-square-check" text="Accetta" handler={props.accettaModificaRto} />
                <Button ui="action" align="right" disabled={!stateButton.rifiuta} itemId="btnRifiutaMod" iconCls="x-fa fa-square-check" text="Rifiuta" handler={onRifiutaClick} />                
            </Toolbar>

        </Panel>
    );
}

const storeRtoSchede = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Rto/LeggiSchedeRTO',
        timeout: 120000,
        reader: {
            type: 'json'
        }
    },
    autoload: false
});


var listaPO = null;

function Rto(props) {
    // in props.parRto i parametri openInEdit, versione e ideStoRTO devono essere impostati per il tipoAzione="A" e openInEdit = true;

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [state, setState] = useState({ tipoAzione: props.parRto.tipoAzione === "T" ? "A" : props.parRto.tipoAzione, trasformazioneStudio: props.parRto.tipoAzione === "T" ? true : null, edit: false, schedeRto: storeRtoSchede, editPo: false, listaPo: listaPO, titoloRto: "", titoloConf: "", flgAczVes: false, verInModifica: false, hiddenEdit: true, hiddenEditPO: true, hiddenEditRtoDaPO: true, hiddenClose: !(searchParams.size === 0) })
    // state.edit           determina l'abilitazione della modifica RTO (dei bottoni di modifica) nei panel delle schede RTO
    // state.editPO         determina l'abilitazione della modifica PO (dei bottoni di modifica) nei panel delle schede PO
    // state.verInModifica  determina se è stata caricata una versione non "attiva" quindi modificata da altro utente
    // state.tipoAzione     determina se si sta aprendo un nuova Rto, una nuova Rto da una copia o una versione di Rto "N-Nova/C-Copia/A-Apri"  -- ideRTO sarà=0 solo per nuovo se copi popolato con rto da copiare

    const [stateModifica, setStateModifica] = useState({ modificata: false, checkPeriodII: true, checkServII: true});
    const [statePrecModifica, setStatePrecModifica] = useState({ precModificata: false });

    const [popupRtoDaPo, setPopupRtoDaPo] = useState({ showPopup: false, data: "" });

    // stateModifica.modificata     indica se la versione della Rto è stata modificata (abilitazione bottone conferma nella barra gialla)
    // stateModifica.checkPeriodII  indica se la periodicità nell'impegno impianto è congruente a quella della Rto

    const { user, switchUser } = useUser();
    var isIF = parseInt(user.idOperatoreRFI) > 0 ? false : true;

    const [stateLoadMask, setStateLoadMask] = useState({ hidden: props.parRto.tipoAzione === 'N' || props.parRto.tipoAzione === 'C' ? true : false, message: "Attendere caricamento RTO..." })

    const caricaRto = (ideRTO, ideGrp, ideCli, verAttiva) => {
     
        let msgErrore = 'Errore caricamento schede RTO';
        storeRtoSchede.clearData();
        storeRtoSchede.removeAll();
        let params = {
            ideRto: ideRTO,
            ideTipGrp: ideGrp,
            verAttiva: verAttiva,   // verAttiva è un flag che viene usato nel caricamento dell'Rto e ne determina la gestione lato DB per creare una nuova versione
            ideUte: user.ideUte,
            ipAddress: "localhost",
            urlPage: "Rto/LeggiSchedeRTO",
            ideTrcOra: null, // appoggio per ModificaRTOdaPO
            trasformaInStudio: state.trasformazioneStudio
        };
        storeRtoSchede.load({
            params: params,
            callback: function (records, options, success) {               
                if (success && records.length > 0) {
                    if (records[0].data.resp === "KO") {
                        Ext.Msg.alert('Caricamento RTO', records[0].data.msgErr);
                        props.hideDialogDetailRTO();
                    }
                    else { 
                        Ext.Ajax.request({
                            url: 'Po/LeggiTreniPO',
                            method: 'GET',
                            params: { ideRTOXPO: records[0].data.rto.ideRTO },
                            callback: function (resp, success, operation) {
                                listaPO = Ext.decode(operation.responseText);
                                aggiornaState();
                                if (props.parRto.modificaRTODaPOConferma === true)
                                    aggiornaStateModifica(true);
                            }
                        })
                        VerificaCongruenzaPeriodicita();
                    }
                }
                else {
                    Ext.Msg.alert('Caricamento RTO', msgErrore);
                    props.hideDialogDetailRTO();
                }
            }
        });
    }

    function chiudiPopupCreaRtoDaPo() {
        popupRtoDaPo.data = "";
        popupRtoDaPo.showPopup = false;
        setPopupRtoDaPo({ ...popupRtoDaPo });
    }

    const aggiornaState = () => {
        if (storeRtoSchede.getCount() > 0)
        {
            //var panel = Ext.ComponentQuery.query('#panelRtoDet')[0];
            //if (panel) {
            //    panel.addCls('pnlTitleSelectable');
            //}

            let schede = storeRtoSchede.data.items[0].data;
            
            // Impostazione Titolo -------------------------------------------------------------------------------------------------------------
            let origine = schede.rto.nomLocPrv + "&nbsp;(" + schede.rto.oraPar + ")";
            let destino = schede.rto.nomLocDsn + "&nbsp;(" + schede.rto.oraArr + ")";
            let title1 = (schede.rto.ideStoRTO === 0) ? schede.rto.ptlIF : schede.rto.ptlRFI;
            let title2 = "&ensp;&ensp;Richiesta&ensp;IF:&nbsp;" + schede.rto.nomTreIpr + "&ensp;-&ensp;" + origine + "&nbsp;-&nbsp;" + destino;
            let title3 = "&nbsp;Versione:&nbsp;" + schede.rto.nomVesRTO;
            let divParent = '<div style="display: flex;">';
            let divTitle1 = '<div style="display: flex; justify-content: center; align-content: center; flex-direction: column;">&ensp;&ensp;' + title1 + '</div>';
            state.titoloRto = divParent + divTitle1 + '<div ><span>' + title2 + title3 + '</span>';

            if (listaPO != null && listaPO.length > 0) {
                let titlePO = "<br><span>" + "&ensp;&ensp;Offerta&ensp;&ensp;RFI:&nbsp;";
                for (var i = 0; i < listaPO.length; i++) {
                    let row = listaPO[i];
                    if (i === 0) {
                        titlePO += row.desTrcOra;
                    }
                    if (i === 1) {
                        titlePO = titlePO + ",&ensp;" + row.desTrcOra;
                    }
                    if (i > 1) {
                        titlePO = titlePO + ",&ensp;" + row.nomTreRFI;
                        if (i == listaPO.length - 1)
                            titlePO += "</span>";                        
                    }                  
                }
                state.titoloRto = state.titoloRto + titlePO;
            }
            state.titoloRto = state.titoloRto + '</div></div>';
            state.titoloConf = "RTO in modifica - Treno:&nbsp;" + title1 + "&ensp;-&ensp;" + origine + "&nbsp;-&nbsp;" + destino;
            //---------------------------------------------------------------------------------------------------------------------------------
            state.verInModifica = (schede.rto.nomVesRTO.toLowerCase() === "modificata") ? true : false;
            state.flgAczVes = schede.rto.flgAczVes == 1 ? true : false;
            state.hiddenEdit = setHiddenEdit();
            state.hiddenEditPO = setHiddenEditPO();
            state.hiddenEditRtoDaPO = setHiddenEditRtoDaPO();
            //-----
            state.schedeRto = storeRtoSchede;
            //-----          
            state.listaPo = listaPO;
        }
        setState({ ...state });
        setStateLoadMask({ hidden: true })
    }

    const aggiornaStateModifica = (modificata) => {
        stateModifica.modificata = modificata;  
        setStateModifica({ ...stateModifica });        
    }

    const aggiornaStateModificaManovra = (tipo) => {
        if (tipo == 'M') {
            let precMod = stateModifica.modificata;
            statePrecModifica.precModificata = precMod;
            setStatePrecModifica({ ...statePrecModifica });

            aggiornaStateModifica(false);
            
        }
        else if (tipo == 'A') {
            let precMod = statePrecModifica.precModificata;
            stateModifica.modificata = precMod;

            setStateModifica({ ...stateModifica });
        }        
    }

    const aggiornaStatePeriodII = (checkPeriodII) => {
        stateModifica.checkPeriodII = checkPeriodII;
        setStateModifica({ ...stateModifica });
    }
    const aggiornaStateServII = (checkServII) => {
        stateModifica.checkServII = checkServII;
        setStateModifica({ ...stateModifica });
    }
    //const aggiornaStatePeriodII = (checkPeriodII) => {     
    //    stateModifica.checkPeriodII = checkPeriodII;
    //    setStateModifica({ ...stateModifica });
    //}

    const setHiddenEdit = () => { 
        let vis = false;
        if (typeof props.parRto.readOnly !== 'undefined' && props.parRto.readOnly === false) {
            if (!state.edit && !state.editPO && !state.verInModifica) {
                let rto = storeRtoSchede.data.items[0].data.rto;

                if (rto.flgAczVes != 1) {
                    if (!isIF) {
                        vis = props.parRto.flgUpdVes;
                    }
                    else {
                        vis = rto.ideStoRTO === 0 || props.parRto.flgUpdVes;
                    }
                }

            }
        }
        return !vis;
    }

    const setHiddenEditPO = () => {
        let vis = false;
        if (typeof props.parRto.readOnly !== 'undefined' && props.parRto.readOnly === false) {
            if (!state.edit && !state.editPO && !isIF) {
                let rto = storeRtoSchede.data.items[0].data.rto;

                if (rto.flgAczVes !== 1) {
                    vis = props.parRto.flgModTrc;
                }
            }
        }
        return !vis;
    }

    const setHiddenEditRtoDaPO = () => {
        let vis = false;
        if (typeof props.parRto.readOnly !== 'undefined' && props.parRto.readOnly === false) {
            if (!state.edit && !state.editPO && isIF) {
                let rto = storeRtoSchede.data.items[0].data.rto;
                if (rto.flgAczVes !== 1 && rto.ideStoRTO) {
                    vis = props.parRto.flgModRTODaPO;
                }
            }
        }
        return !vis;
    }

    

    const DOCUMENT_URL = "/Documento";
    const getDocumento = (idPag) => {
        window.open(DOCUMENT_URL + "?idPag=" + idPag, '_blank');
      };
    const toolHandler = (panel, tool) => {
        if (tool.config.type === 'edit') {
            
            if (!state.edit) {              
                if (storeRtoSchede.data.items[0].data.rto.ideStoRTO === 0) {
                    // Modifica secca
                    state.edit = true;
                    aggiornaState();
                    RefreshRtoStatoZero();
                }
                else {         
                    props.parRto.flgModTrc = false;
                    // crea nuova versione e ricarica i dati 
                    state.edit = true;  
                    CreaNuovaVersioneRto();                                         
                }
            }
            //else {
            //    Ext.Msg.alert('Attenzione', "Modifica non consentita per la versione dell'RTO.");
            //}
           
        }

        if (tool.config.type === 'editPo') {
            if (!state.edit && !state.editPO && !isIF) {
                let rto = storeRtoSchede.data.items[0].data.rto;
                if (props.parRto.flgModTrc === true) {

                    if (rto.ideStoRTO < 5) {
                        // Modifica secca
                        state.edit = false;  
                        state.editPO = true;
                        aggiornaState();
                    }
                    else {
                        // Crea una nuova versione
                        state.edit = false; 
                        state.editPO = true;
                        CreaNuovaVersioneRto();                  
                    }
                }
            }
            
        }

        if (tool.config.type === 'editRTODaPO') {
            let rto = storeRtoSchede.data.items[0].data.rto;
            storeListaPO.clearData();
            storeListaPO.removeAll();
            storeListaPO.load({
                params: {
                    ideRto: rto.ideRTO
                },
                callback: function (records, options, success) {
                    if (success) {
                        if (records.length < 1) {
                            Ext.Msg.alert('Elenco PO', 'Nessun Treno associato alla RTO');
                        }
                        else {
                            popupRtoDaPo.data = rto;
                            popupRtoDaPo.showPopup = true;
                            setPopupRtoDaPo({ ...popupRtoDaPo });
                        }
                    }
                    else {
                        Ext.Msg.alert('Elenco PO', 'Errore caricamento lista Treni');
                    }
                }
            });

        }

        if (tool.config.type === 'close') {
            if ((state.edit || state.editPO) && state.verInModifica && state.flgAczVes === false) {
                // Se la versione è già in modifica si può solo annullare
                Ext.Msg.alert('Attenzione', "RTO in modifica - Consentito solo l'annullamento dell'operazione");
            }
            else {
                if (state.tipoAzione === 'N') {
                    Ext.Msg.confirm('Confirm', "Procedere con l'annullamento della richiesta?", function (button) {
                        if (button == 'yes') {
                            if (Ext.ComponentQuery.query('#gridPercorso')[0] != undefined) {
                                Ext.ComponentQuery.query('#gridPercorso')[0].store.clearData();
                                Ext.ComponentQuery.query('#gridPercorso')[0].store.removeAll();
                            }
                            if (Ext.ComponentQuery.query('#gridServiziImpianto')[0] != undefined) {
                                Ext.ComponentQuery.query('#gridServiziImpianto')[0].store.clearData();
                                Ext.ComponentQuery.query('#gridServiziImpianto')[0].store.removeAll();
                            }
                            if (Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0] != undefined) {
                                Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0].store.clearData();
                                Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0].store.removeAll();
                            }
                            if (Ext.ComponentQuery.query('#gridLegamiImpianto')[0] != undefined) {
                                Ext.ComponentQuery.query('#gridLegamiImpianto')[0].store.clearData();
                                Ext.ComponentQuery.query('#gridLegamiImpianto')[0].store.removeAll();
                            }
                            if (Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0] != undefined) {
                                Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0].store.clearData();
                                Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0].store.removeAll();
                            }
                            if (Ext.ComponentQuery.query('#gridManovre')[0] != undefined) {
                                Ext.ComponentQuery.query('#gridManovre')[0].store.clearData();
                                Ext.ComponentQuery.query('#gridManovre')[0].store.removeAll();
                            }
                            if (Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0] != undefined) {
                                Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0].store.clearData();
                                Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0].store.removeAll();
                            }
                            if (Ext.ComponentQuery.query('#gridServiziCommerciali')[0] != undefined) {
                                Ext.ComponentQuery.query('#gridServiziCommerciali')[0].store.clearData();
                                Ext.ComponentQuery.query('#gridServiziCommerciali')[0].store.removeAll();
                            }
                            if (Ext.ComponentQuery.query('#gridImpostazione')[0] != undefined) {
                                Ext.ComponentQuery.query('#gridImpostazione')[0].store.clearData();
                                Ext.ComponentQuery.query('#gridImpostazione')[0].store.removeAll();
                            }
                            if (Ext.ComponentQuery.query('#gridPeriodicita')[0] != undefined) {
                                Ext.ComponentQuery.query('#gridPeriodicita')[0].store.clearData();
                                Ext.ComponentQuery.query('#gridPeriodicita')[0].store.removeAll();
                            }
                            props.hideDialogDetailRTO();
                        }
                    });
                }
                else {
                    if (state.schedeRto.data.length > 0) {
                        storeRtoSchede.loadData([], false);
                        if (Ext.ComponentQuery.query('#gridPercorso')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridPercorso')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridPercorso')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridServiziImpianto')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridServiziImpianto')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridServiziImpianto')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridLegamiImpianto')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridLegamiImpianto')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridLegamiImpianto')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridManovre')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridManovre')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridManovre')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridServiziCommerciali')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridServiziCommerciali')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridServiziCommerciali')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridImpostazione')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridImpostazione')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridImpostazione')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridPeriodicita')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridPeriodicita')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridPeriodicita')[0].store.removeAll();
                        }
                        props.hideDialogDetailRTO();
                        props.RefreshRtoList();
                    }
                    else {
                        if (Ext.ComponentQuery.query('#gridPercorso')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridPercorso')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridPercorso')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridServiziImpianto')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridServiziImpianto')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridServiziImpianto')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridPeriodicitaIIServizi')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridLegamiImpianto')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridLegamiImpianto')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridLegamiImpianto')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridPeriodicitaIILegami')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridManovre')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridManovre')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridManovre')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridServiziCommerciali')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridServiziCommerciali')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridServiziCommerciali')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridImpostazione')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridImpostazione')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridImpostazione')[0].store.removeAll();
                        }
                        if (Ext.ComponentQuery.query('#gridPeriodicita')[0] != undefined) {
                            Ext.ComponentQuery.query('#gridPeriodicita')[0].store.clearData();
                            Ext.ComponentQuery.query('#gridPeriodicita')[0].store.removeAll();
                        }
                        props.hideDialogDetailRTO();
                    }
                }
            }
        }
    }

    const CreaNuovaVersioneRto = () => {
        // Crea una nuova versione
        stateLoadMask.hidden = false;
        stateLoadMask.message = "Attendere caricamento RTO in modifica...";
        setStateLoadMask({ ...stateLoadMask });
        
         caricaRto(props.parRto.ideRTO, user.idTipoGruppo, props.parRto.ideCli, true);
                    
    }

    const AnnullaVersioneRto = () => {
        aggiornaStateModifica(false);
        if ((state.edit || state.editPO) && state.verInModifica) {
            let rto = storeRtoSchede.data.items[0].data.rto;
            storeRtoSchede.clearData();
            storeRtoSchede.removeAll();
            aggiornaState();
            stateLoadMask.message = "Annullamento modifiche in corso...";
            stateLoadMask.hidden = false;
            setStateLoadMask({ ...stateLoadMask });
            if (state.editPO) {
                Ext.Ajax.request({
                    url: 'Rto/ConfermaCambioVersioneRequest',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideRtoVer: rto.ideRTO,
                        flgCnf: 0,
                        flgAcz: 0,
                        stato: rto.ideStoRTO
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            //Ricarica lo store
                            stateLoadMask.hidden = false;
                            stateLoadMask.message = "Modifiche annullate - Attendere caricamento RTO...";
                            setStateLoadMask({ ...stateLoadMask });
                            state.edit = false;
                            state.editPO = false;
                            //caricaRto(props.parRto.ideRTO, user.idTipoGruppo, props.parRto.ideCli, false);
                            aggiornaState();
                            props.hideDialogDetailRTO();
                            props.RefreshRtoList();
                        }
                        else {
                            Ext.Msg.alert('Annullamento modifiche RTO', "Errore nell'annullamento delle modifiche.");
                        }
                    }
                });
            }
            else {
                Ext.Ajax.request({
                    url: 'Rto/ConfermaCambioVersione',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideRtoVer: rto.ideRTO,
                        flgCnf: 0,
                        flgAcz: 1
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            //Ricarica lo store
                            stateLoadMask.hidden = false;
                            stateLoadMask.message = "Modifiche annullate - Attendere caricamento RTO...";
                            setStateLoadMask({ ...stateLoadMask });
                            state.edit = false;
                            state.editPO = false;
                            //caricaRto(props.parRto.ideRTO, user.idTipoGruppo, props.parRto.ideCli, false);
                            aggiornaState();
                            props.hideDialogDetailRTO();
                            props.RefreshRtoList();
                        }
                        else {
                            Ext.Msg.alert('Annullamento modifiche RTO', "Errore nell'annullamento delle modifiche.");
                        }
                    }
                });
            }
        }
        else
            props.hideDialogDetailRTO();  // non è stata generata una versione in modifica, non si dovrebbe verificare -  caso controllato in anticipo
    }

    const ConfermaVersioneRto = (chkVerAccettazione) => {
        if ((state.edit || state.editPO) && state.verInModifica) {
            let rto = storeRtoSchede.data.items[0].data.rto;
            storeRtoSchede.clearData();
            storeRtoSchede.removeAll();
            aggiornaState();
            stateLoadMask.message = "Conferma modifiche in corso...";
            stateLoadMask.hidden = false;
            setStateLoadMask({ ...stateLoadMask });

            if (state.editPO) {

                Ext.Ajax.request({
                    url: 'Rto/ConfermaCambioVersioneRequest',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideRtoVer: rto.ideRTO,
                        flgCnf: 1,
                        flgAcz: 0,
                        stato: rto.ideStoRTO
                    },
                    callback: function (records, success, operation) {
                        aggiornaStateModifica(false);
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            if (returnObj.resp == "OK") {
                                Ext.Msg.alert('Modifiche', returnObj.mess);
                                state.edit = false;
                                state.editPO = false;
                                //Ricarica lo store
                                stateLoadMask.hidden = false;
                                stateLoadMask.message = "Modifiche confermate - Attendere caricamento RTO...";
                                setStateLoadMask({ ...stateLoadMask });
                                //caricaRto(rto.ideRTO, user.idTipoGruppo, rto.ideCli, false);
                                aggiornaState();
                                props.hideDialogDetailRTO();
                                props.RefreshRtoList();
                            }
                            else {
                                Ext.Msg.alert('Conferma modifiche PO', "Errore nella conferma delle modifiche.");
                            }
                        }
                        else {
                            Ext.Msg.alert('Conferma modifiche PO', "Errore nella conferma delle modifiche.");
                        }
                    }
                });
            }
            else {
                Ext.Ajax.request({
                    url: 'Rto/ConfermaCambioVersione',
                    method: 'POST',
                    params: {
                        ideUte: user.ideUte,
                        ipAddress: "",
                        ideRtoVer: rto.ideRTO,
                        flgCnf: 1,
                        flgAcz: (chkVerAccettazione ? 1 : 0)
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            state.edit = false;
                            state.editPO = false;
                            //Ricarica lo store
                            stateLoadMask.hidden = false;
                            stateLoadMask.message = "Modifiche confermate - Attendere caricamento RTO...";
                            setStateLoadMask({ ...stateLoadMask });
                            //caricaRto(rto.ideRTO, user.idTipoGruppo, rto.ideCli, false);
                            aggiornaState();
                            props.hideDialogDetailRTO();
                            props.RefreshRtoList();
                        }
                        else {
                            Ext.Msg.alert('Conferma modifiche RTO', "Errore nella conferma delle modifiche.");
                        }
                    }
                });
            }
        }
        else
            props.hideDialogDetailRTO();  // non è stata generata una versione in modifica, non si dovrebbe verificare -  caso controllato in anticipo
    }

    const AccettaModificaRto = () => {
        let rto = storeRtoSchede.data.items[0].data.rto;

        stateLoadMask.message = "Accettazione modifiche in corso...";
        stateLoadMask.hidden = false;
        setStateLoadMask({ ...stateLoadMask });

        Ext.Ajax.request({
            url: 'Rto/ConfermaAccettazioneVersione',
            method: 'POST',
            params: {
                ideUte: user.ideUte,
                ipAddress: "",
                ideRtoMod: rto.ideRTO,
                flgAcz: 1,
                noteAcz: ""
            },
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);

                    if (returnObj.resp == "OK") {
                        state.edit = false;
                        state.editPO = false;
                        //Ricarica lo store
                        stateLoadMask.hidden = false;
                        stateLoadMask.message = "Modifiche accettate - Attendere caricamento RTO...";
                        setStateLoadMask({ ...stateLoadMask });
                        caricaRto(rto.ideRTO, user.idTipoGruppo, rto.ideCli, false);
                        props.RefreshRtoList();
                    }
                    else {

                        if (returnObj.resp == "KO")
                            Ext.Msg.alert('Rifiuto modifiche RTO', returnObj.msgErr);
                    }
                }
                else {
                    Ext.Msg.alert('Accetta modifiche RTO', "Errore nell'accettazione delle modifiche.");
                }
            }
        });
        
    }

    const RifiutaModificaRto = (noteAcz) => {
        let rto = storeRtoSchede.data.items[0].data.rto;

        stateLoadMask.message = "Rifiuto delle modifiche in corso...";
        stateLoadMask.hidden = false;
        setStateLoadMask({ ...stateLoadMask });

        Ext.Ajax.request({
            url: 'Rto/ConfermaAccettazioneVersione',
            method: 'POST',
            params: {
                ideUte: user.ideUte,
                ipAddress: "",
                ideRtoMod: rto.ideRTO,
                flgAcz: 0,
                noteAcz: noteAcz
            },
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);

                    if (returnObj.resp == "OK") {
                        state.edit = false;
                        state.editPO = false;
                        //Ricarica lo store
                        stateLoadMask.hidden = false;
                        stateLoadMask.message = "Modifiche rifiutate - Attendere caricamento RTO...";
                        setStateLoadMask({ ...stateLoadMask });
                        caricaRto(returnObj.ideRtoAtv, user.idTipoGruppo, rto.ideCli, false);
                        props.RefreshRtoList();
                    }   
                    else {
                     
                        if (returnObj.resp == "KO")                            
                            Ext.Msg.alert('Rifiuto modifiche RTO', returnObj.msgErr);
                    }
                }
                else {
                    Ext.Msg.alert('Rifiuto modifiche RTO', "Errore in fase di rifiuto delle modifiche.");
                }
            }
        });
    
        
    }

  
    const RefreshRto = () => {
        if ((state.edit || state.editPO)) {
            let rto = storeRtoSchede.data.items[0].data.rto;

            stateLoadMask.message = "Aggiornamento in corso...";
            stateLoadMask.hidden = false;
            setStateLoadMask({ ...stateLoadMask });
            caricaRto(rto.ideRTO, user.idTipoGruppo, rto.ideCli, false);
        }
    }

    const RefreshRtoDaAggiornaPO = (ideRto,ideCli) => {
            stateLoadMask.message = "Aggiornamento in corso...";
            stateLoadMask.hidden = false;
            setStateLoadMask({ ...stateLoadMask });
            caricaRto(ideRto, user.idTipoGruppo, ideCli, false);
    }

    const RefreshRtoStatoZero = () => {
        if (state.edit) {
            let rto = storeRtoSchede.data.items[0].data.rto;

            stateLoadMask.message = "Aggiornamento in corso...";
            stateLoadMask.hidden = false;
            setStateLoadMask({ ...stateLoadMask });
            caricaRto(rto.ideRTO, user.idTipoGruppo, rto.ideCli, false);
        }
    }

    const RefreshRtoDaNuova = (ideRTO, ideGrpRTO, ideCli) => {
        state.edit = true;
        state.tipoAzione = "A";
        stateLoadMask.message = "Aggiornamento in corso...";
        stateLoadMask.hidden = false;
        props.parRto.ideRTO = ideRTO;
        props.parRto.ideCli = ideCli;
        setStateLoadMask({ ...stateLoadMask });
    }

    const RefreshRtoCreaDaPO = (ideRTO, ideGrpRTO, ideCli,tipologia,stato) => {
        state.edit = true;
        state.tipoAzione = "A";
        stateLoadMask.message = "Aggiornamento in corso...";
        stateLoadMask.hidden = false;
        props.parRto.ideRTO = ideRTO;
        props.parRto.ideCli = ideCli;
        props.parRto.ideGrpRTO = ideGrpRTO;
        props.parRto.flgUpdVes = false;
        props.parRto.flgModTrc = false;
        props.parRto.flgUpdTrc = false;
        props.parRto.openInEdit = true;
        props.parRto.versione = tipologia;
        props.parRto.ideStoRTO = stato;
        props.parRto.readOnly = false;
        props.parRto.modificaRTODaPOConferma = true;
        popupRtoDaPo.data = "";
        popupRtoDaPo.showPopup = false;
        props.setParamsDetail({ ...props.parRto})
        setPopupRtoDaPo({ ...popupRtoDaPo });
        setStateLoadMask({ ...stateLoadMask });
        caricaRto(ideRTO, user.idTipoGruppo, ideCli, false);
    }

    const RefreshListaPO = () => {
        if (storeRtoSchede.getCount() > 0) {

            let rto = storeRtoSchede.data.items[0].data.rto;
            stateLoadMask.hidden = false;
            stateLoadMask.message = "Aggiornamento in corso...";
            setStateLoadMask({ ...stateLoadMask });
            Ext.Ajax.request({
                url: 'Po/LeggiTreniPO',
                method: 'GET',
                params: { ideRTOXPO: rto.ideRTO },
                callback: function (resp, success, operation) {
                    listaPO = Ext.decode(operation.responseText);
                    aggiornaState();
                }
            })

        }
    }

    const VerificaCongruenzaPeriodicita = () => {
        //verifica congruenza periodicità
        Ext.Ajax.request({
            url: 'Rto/CheckPeriodicitaImpegnoImpianto',
            method: 'POST',
            params: {
                ideRto: props.parRto.ideRTO
            },
            callback: function (records, success, operation) {
                var returnCheck = Ext.decode(operation.responseText);
                if (returnCheck.length > 0) {
                    let stringa = "";
                    for (let i = 0; i < returnCheck.length; i++) {
                        stringa = stringa + returnCheck[i] + "<br>";
                    }
                    Ext.Msg.alert('Attenzione', stringa);
                    aggiornaStatePeriodII(false);
                }
                else {
                    aggiornaStatePeriodII(true);
                    let hasDuplicates = false; 

                    for (let i = 0; i < storeRtoSchede.data.items[0].data.impegnoImpianto.serviziInfrastrutturaLocalita.length; i++) {
                        if (hasDuplicates) {
                            break; 
                        }

                        Ext.Ajax.request({
                            url: 'Rto/CheckServiziDuplicati',
                            method: 'POST',
                            params: {
                                idServ: storeRtoSchede.data.items[0].data.impegnoImpianto.serviziInfrastrutturaLocalita[i].idE_SER_IFS_LOC
                            },
                            callback: function (records, success, operation) {
                                var returnCheckServ = Ext.decode(operation.responseText);
                                if (returnCheckServ.length > 0) {
                                    Ext.Msg.alert('Attenzione servizi duplicati', returnCheckServ);
                                    aggiornaStateServII(false);
                                    hasDuplicates = true; 
                                } else {
                                    aggiornaStateServII(true);
                                }
                            }
                        });
                    }
                }
            }
        });
    }
    useEffect(() => {
        if (state.tipoAzione !== "N" && state.tipoAzione !== "C") {
            // code to run after render
            if (props.parRto.openInEdit)
            {
                state.edit = true;
                aggiornaState();
                if (props.parRto.ideStoRTO === 0) {
                    stateLoadMask.hidden = false;
                    stateLoadMask.message = "Attendere caricamento RTO in modifica...";
                    setStateLoadMask({ ...stateLoadMask });
                    caricaRto(props.parRto.ideRTO, user.idTipoGruppo, props.parRto.ideCli, false);
                }
                else {
                    if (props.parRto.versione.toLowerCase() == "attiva") {
                        CreaNuovaVersioneRto();
                    }
                    else {
                        stateLoadMask.hidden = false;
                        stateLoadMask.message = "Attendere caricamento RTO in modifica...";
                        setStateLoadMask({ ...stateLoadMask });
                        caricaRto(props.parRto.ideRTO, user.idTipoGruppo, props.parRto.ideCli, false);

                    }
                }
            }
            else if (props.parRto.openInEditPO) {
                state.edit = false;
                state.editPO = true;
                aggiornaState();
                if (props.parRto.ideStoRTO < 5) {
                    stateLoadMask.hidden = false;
                    stateLoadMask.message = "Attendere caricamento RTO in modifica...";
                    setStateLoadMask({ ...stateLoadMask });
                    caricaRto(props.parRto.ideRTO, user.idTipoGruppo, props.parRto.ideCli, false);
                }
                else {
                    // Crea una nuova versione
                    state.edit = false;
                    state.editPO = true;
                    CreaNuovaVersioneRto();
                }
            }
            else  { 
                caricaRto(props.parRto.ideRTO, user.idTipoGruppo, props.parRto.ideCli, false);               
            }
        }
    }, [state.tipoAzione]); // <-- empty array means 'run once'

    return (
       
    <Container cls="container-spinner" height="100%" width="100%" layout="fit" > 
            <Loadmask cls="custom-grid" itemId="loadMaskRtoDet" hidden={stateLoadMask.hidden} 
            indicator={false} 
            message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
            modal={true} />

    {/* ----------------- render per l'apertura rto -----------------------------------------------------------------------------------------------*/}
            {state.tipoAzione === "A" && <Panel
                layout="vbox"
                cls="iconTrainRTO"
                iconCls="x-fa fa-train"
                title= {state.titoloRto}
                itemId="panelRtoDet"
                docked="top"
                tools={[
                    { iconCls: 'x-fa fa-edit buttonPanelTool', tooltip: 'Modifica RTO', type: 'edit', handler: toolHandler, hidden: state.hiddenEdit },
                    { iconCls: 'x-fa fa-wand-magic-sparkles buttonPanelTool', tooltip: 'Modifica RTO da PO', type: 'editRTODaPO', handler: toolHandler, hidden: state.hiddenEditRtoDaPO },
                    { iconCls: 'x-fa fa-circle-question buttonPanelTool', tooltip: 'Guida scheda RTO', type: 'edit', handler: () =>getDocumento(3) },
                    { iconCls: 'x-fa fa-xmark buttonPanelTool', tooltip: 'Chiudi', type: 'close', handler: toolHandler, hidden: state.hiddenClose }
                ]}
            />}
         
            {state.tipoAzione === "A" &&
                <Panel
                    layout="vbox"
                    flex={1}
                    //scrollable  abilita lo scroll dell'intera rto
                >

                    {state.schedeRto.getCount() > 0 && <RtoVersione hiddenEditPO={state.hiddenEditPO} toolHandler={toolHandler} storeRtoSchede={state.schedeRto} listaPO={state.listaPo} inEdit={state.edit} inEditPO={state.editPO} RefreshRto={RefreshRto} RefreshRtoDaAggiornaPO={RefreshRtoDaAggiornaPO} RefreshListaPO={RefreshListaPO} aggiornaStateModifica={aggiornaStateModifica} aggiornaStatePeriodII={aggiornaStatePeriodII} aggiornaStateServII={aggiornaStateServII} readOnly={props.parRto.readOnly !== 'undefined' ? props.parRto.readOnly : true} modificaServiziCommerciali={props.parRto.modificaServiziCommerciali} aggiornaStateModificaManovra={aggiornaStateModificaManovra} />}

                    {(state.edit || state.editPO) && state.verInModifica && !state.flgAczVes && <EditRtoButton flgModTrc={props.parRto.flgModTrc} title={state.titoloConf} verInModifica={state.verInModifica} modificata={stateModifica.modificata} checkPeriodII={stateModifica.checkPeriodII} checkServII={stateModifica.checkServII} annullaRto={AnnullaVersioneRto} confermaRto={ConfermaVersioneRto} />}

                {!state.edit && state.verInModifica && state.flgAczVes && <AccettaRtoButton accettaModificaRto={AccettaModificaRto} rifiutaModificaRto={RifiutaModificaRto} />}

            </Panel>}
    {/* ----------------- render per la nuova rto -----------------------------------------------------------------------------------------------*/}
            {state.tipoAzione === "N" && <Panel
                cls="iconTrainRTO"
                layout="vbox"
                iconCls="x-fa fa-train"
                title="Nuova RTO"
                docked="top"
                tools={[                   
                    { iconCls: 'x-fa fa-xmark', tooltip: 'Chiudi', type: 'close', handler: toolHandler }
                ]}
            />}
            {state.tipoAzione === "N" && <RtoNuova layout="vbox" RefreshRtoDaNuova={RefreshRtoDaNuova} />}

            {/* ------------------------------------------------------------------------------------------------------------------------------------------*/}
            {state.tipoAzione === "C" && <Panel
                cls="iconTrainRTO"
                layout="vbox"
                iconCls="x-fa fa-train"
                title="Copia RTO"
                docked="top"
                tools={[
                    { iconCls: 'x-fa fa-xmark', tooltip: 'Chiudi', type: 'close', handler: toolHandler }
                ]}
            />}
            {state.tipoAzione === "C" && <RtoCopia layout="vbox" RefreshRtoDaNuova={RefreshRtoDaNuova} ideRto={props.parRto.ideRTO} ideTipGrp={props.parRto.ideGrpRTO} />}
            {popupRtoDaPo.showPopup && <Dialog
                displayed={true}
                zIndex={10}
                layout='fit'
                minWidth='40%'
                minHeight='35%'
                itemId='dialogCreaRtoDaPODentroRTO'
            >
                <PopUpCreaRtoDaPo data={popupRtoDaPo.data} storeListaPO={storeListaPO} chiudiPopup={chiudiPopupCreaRtoDaPo} onModificaRtoDaPoRefresh={RefreshRtoCreaDaPO} />
            </Dialog>}
    </Container>
  );
}

export default Rto;
