import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useState } from "react";
import './App.css';
import Home from './pages/HomePage';
import Clienti from './Clienti/MainClienti';
import ComunicazioniIF from './Clienti/ComunicazioniIF';
import Cliente from './Clienti/Cliente';
import AcqDossierPCS from './rtoInternazionali/AcqDossierPCS';
import PubblicazionePOPCS from './rtoInternazionali/PubblicazionePOPCS';
import GestioneUtenti from './Clienti/GestioneUtenti';
import MonitoraggioCdS from './Clienti/MonitoraggioCdS';
import MainRto from './rto/MainRto';
import SchedaRto from './rto/Rto';
import MaterialiSTI from './gestioneAnagrafiche/MaterialiSTI';
import Lotto from './lotto/Lotto';
import UploadPO from "./Uploads/UploadPO";
import UploadRTO from "./Uploads/UploadRTO";
import GuidaUpload from "./Uploads/GuidaUpload";
import AppPortal from './home/AppPortal';
import DashboardDataFetcher from "./home/DashboardDataFetcher";
import NoMatch from './pages/NoMatchPage';
import LoginPage from './pages/LoginPage';
import AuthPCS from './rtoInternazionali/AuthPCS'

import ReportDettaglio from './analisiRto/ReportDettaglio';
import ReportGenerale from './analisiRto/ReportGenerale';
import ReportStatoLavorazione from './analisiRto/ReportStatoLavorazione';
import DownloadReport from './analisiRto/DownloadReport';
import AnalisiModificheInCorso from './analisiRto/AnalisiModificheInCorso';
import DettaglioImpegnoImpianto from './analisiRto/DettaglioImpegnoImpianto';
import StatisticheFinalizzazione from './analisiRto/StatisticheFinalizzazione';
import StatisticheFattibilita from './analisiRto/StatisticheFattibilita';
import StatisticheModifiche from './analisiRto/StatisticheModifiche';
import StatisticheCambiStato from './analisiRto/StatisticheCambisStato';
import StatisticheOperazioni from './analisiRto/StatisticheOperazioni';

import RtoLettura from './pages/RtoPage'
import CatalogoMerci from './catalogoMerci/CatalogoMerci';
import Guide from './Guide/Guide';
//import RtoPage from './pages/RtoPage';

import { UserContext, UserProvider, useUser } from './components/UserContext';
import { AmbienteProvider, useAmbiente } from './components/AmbienteContext';
import { PeriodiProvider, usePeriodi } from './components/PeriodiContext';
import AnalisiRTOInternazionali from "./rtoInternazionali/AnalisiRTOInternazionali";
import Documento from "./pages/Documento";
import { useEffect } from "react";



const Ext = window['Ext'];

const it = () => {
    Ext.define('Ext.locale.it.ux.colorpick.Selector', {
        override: 'Ext.ux.colorpick.Selector',

        okButtonText: 'OK',
        cancelButtonText: 'Annulla'
    });
    // This is needed until we can refactor all of the locales into individual files
    Ext.define("Ext.locale.it.Component", {
        override: "Ext.Component"
    });
    Ext.define('Ext.locale.it.Dialog', {
        override: 'Ext.Dialog',

        config: {
            maximizeTool: {
                tooltip: "Massimizza a pieno schermo"
            },
            restoreTool: {
                tooltip: "Ripristina dimensioni originali"
            }
        }
    });
    Ext.define("Ext.locale.it.LoadMask", {
        override: "Ext.LoadMask",

        config: {
            message: 'Caricamento...'
        }
    });
    Ext.define('Ext.locale.it.Panel', {
        override: 'Ext.Panel',

        config: {
            standardButtons: {
                ok: {
                    text: 'Ok'
                },
                abort: {
                    text: 'Interrompere'
                },
                retry: {
                    text: 'Riprovare'
                },
                ignore: {
                    text: 'Ignora'
                },
                yes: {
                    text: 'Si'
                },
                no: {
                    text: 'No'
                },
                cancel: {
                    text: 'Cancella'
                },
                apply: {
                    text: 'Applica'
                },
                save: {
                    text: 'Salva'
                },
                submit: {
                    text: 'Invia'
                },
                help: {
                    text: 'Aiuto'
                },
                close: {
                    text: 'Chiudi'
                }
            },
            closeToolText: 'Chiudi pannello'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Bound', {
        override: 'Ext.data.validator.Bound',

        config: {
            emptyMessage: 'Deve essere disponibile',
            minOnlyMessage: 'Il valore deve essere maggiore di {0}',
            maxOnlyMessage: 'Il valore deve essere inferiore a {0}',
            bothMessage: 'Il valore deve essere compreso tra {0} e {1}'
        }
    });
    Ext.define('Ext.locale.it.data.validator.CIDRv4', {
        override: 'Ext.data.validator.CIDRv4',

        config: {
            message: 'Non &egrave un CIDR block valido'
        }
    });
    Ext.define('Ext.locale.it.data.validator.CIDRv6', {
        override: 'Ext.data.validator.CIDRv6',

        config: {
            message: 'Non &egrave un CIDR block valido'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Currency', {
        override: 'Ext.data.validator.Currency',

        config: {
            message: 'Importo valuta non valido'
        }

    });
    Ext.define('Ext.locale.it.data.validator.Date', {
        override: 'Ext.data.validator.Date',

        config: {
            message: "Data non valida"
        }
    });
    Ext.define('Ext.locale.it.data.validator.DateTime', {
        override: 'Ext.data.validator.DateTime',

        config: {
            message: 'Data e orario non valido'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Email', {
        override: 'Ext.data.validator.Email',

        config: {
            message: 'Non &egrave un indirizzo email valido'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Exclusion', {
        override: 'Ext.data.validator.Exclusion',

        config: {
            message: '&egrave un valore che &egrave stato escluso'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Format', {
        override: 'Ext.data.validator.Format',

        config: {
            message: '&egrave nel formato sbagliato'
        }
    });
    Ext.define('Ext.locale.it.data.validator.IPAddress', {
        override: 'Ext.data.validator.IPAddress',

        config: {
            message: 'Non &egrave un indirizzo IP valido'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Inclusion', {
        override: 'Ext.data.validator.Inclusion',

        config: {
            message: 'Non sta nella lista dei valori validi'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Length', {
        override: 'Ext.data.validator.Length',

        config: {
            minOnlyMessage: 'La lunghezza deve essere almeno {0}',
            maxOnlyMessage: 'La lunghezza deve essere inferiore a {0}',
            bothMessage: 'La lunghezza deve essere tra {0} e {1}'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Number', {
        override: 'Ext.data.validator.Number',

        config: {
            message: 'Non &egrave un numero valido'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Phone', {
        override: 'Ext.data.validator.Phone',

        config: {
            message: 'Non &egrave un numero di telefono valido'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Presence', {
        override: 'Ext.data.validator.Presence',

        config: {
            message: 'Deve essere presente'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Range', {
        override: 'Ext.data.validator.Range',

        config: {
            nanMessage: 'Deve essere un valore numerico',
            minOnlyMessage: 'Deve essere almeno {0}',
            maxOnlyMessage: 'Non deve essere più di {0}',
            bothMessage: 'Deve essere tra {0} e {1}'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Time', {
        override: 'Ext.data.validator.Time',

        config: {
            message: 'Non &egrave un tempo valido'
        }
    });
    Ext.define('Ext.locale.it.data.validator.Url', {
        override: 'Ext.data.validator.Url',

        config: {
            message: 'Non &egrave un url valido'
        }
    });
    Ext.define('Ext.locale.it.dataview.Abstract', {
        override: 'Ext.dataview.Abstract',

        config: {
            loadingText: 'Caricamento...'
        }
    });
    Ext.define("Ext.locale.it.dataview.DataView", {
        override: "Ext.dataview.DataView",

        config: {
            emptyText: "nessun dato da visualizzare"
        }
    });
    Ext.define('Ext.locale.it.dataview.EmptyText', {
        override: 'Ext.dataview.EmptyText',

        config: {
            html: 'Nessun dato da visualizzare'
        }
    });
    Ext.define('Ext.locale.it.dataview.List', {
        override: 'Ext.dataview.List',

        config: {
            loadingText: 'Caricamento...'
        }
    });
    Ext.define('Ext.locale.it.dataview.plugin.ListPaging', {
        override: 'Ext.dataview.plugin.ListPaging',

        config: {
            loadMoreText: 'Carica di più...',
            noMoreRecordsText: 'Nessun dato'
        }
    });

    if (Ext.Date) {
        Ext.Date.monthNames = [
            "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto",
            "Settembre", "Ottobre", "Novembre", "Dicembre"
        ];

        Ext.Date.defaultFormat = 'd/m/Y';
        Ext.Date.defaultTimeFormat = 'H:i';

        Ext.Date.getShortMonthName = function (month) {
            return Ext.Date.monthNames[month].substring(0, 3);
        };

        Ext.Date.monthNumbers = {
            Gen: 0,
            Feb: 1,
            Mar: 2,
            Apr: 3,
            Mag: 4,
            Giu: 5,
            Lug: 6,
            Ago: 7,
            Set: 8,
            Ott: 9,
            Nov: 10,
            Dic: 11
        };

        Ext.Date.getMonthNumber = function (name) {
            return Ext.Date.monthNumbers[name.substring(0, 1).toUpperCase() + name.substring(1, 3)
                .toLowerCase()];
        };

        Ext.Date.dayNames = [
            "Domenica", "Lunedi", "Martedi", "Mercoledi", "Giovedi", "Venerdi", "Sabato"
        ];

        Ext.Date.getShortDayName = function (day) {
            return Ext.Date.dayNames[day].substring(0, 3);
        };
    }

    if (Ext.util && Ext.util.Format) {
        Ext.apply(Ext.util.Format, {
            thousandSeparator: '.',
            decimalSeparator: ',',
            currencySign: '\u20ac', // Euro
            dateFormat: 'd/m/Y',
            defaultDateFormat: "d/m/Y",
        });
    }

    Ext.define('Ext.locale.it.field.Date', {
        override: 'Ext.field.Date',

        config: {
            altFormats:
                'd/m/Y|' +
                'j/n/Y|' +
                'j/n/y|' +
                'j/m/y|' +
                'd/n/y|' +
                'j/m/Y|' +
                'd/n/Y|' +
                'd-m-y|' +
                'd-m-Y|' +
                'j-n-Y|' +
                'j-n-y|' +
                'j-m-y|' +
                'd-n-y|' +
                'j-m-Y|' +
                'd-n-Y|' +
                'd/m|' +
                'd-m|' +
                'dm|' +
                'dmy|' +
                'dmY|' +
                'd|' +
                'Y-m-d|' +
                'Y/m/d|' +
                'j-n|' +
                'j/n',
        },
        minDateMessage: 'La data in questo campo deve essere uguale o successivo a {0}',
        maxDateMessage: 'La data in questo campo deve essere uguale o precedente a {0}'
    });
    Ext.define('Ext.locale.it.field.Time', {
        override: 'Ext.field.Time',
        config: {
            format: 'H:i',
            altFormats: 'H:i|Hi|H:i:s|H:i:s.u|H|HHii'
        }
    })
    Ext.define('Ext.locale.it.field.Field', {
        override: 'Ext.field.Field',

        config: {
            requiredMessage: 'Questo campo &egrave; obbligatorio',
            validationMessage: '&egrave nel formato sbagliato'
        }
    });
    Ext.define("Ext.locale.it.field.FileButton", {
        override: "Ext.field.FileButton",

        config: {
            text: 'Sfoglia...'
        }
    });
    Ext.define('Ext.locale.it.field.Number', {
        override: 'Ext.field.Number',

        decimalsText: 'El n�mero m�ximo de decimales es  {0}',
        minValueText: 'Il valore minimo per questo campo &egrave {0}',
        maxValueText: 'Il valore massimo per questo campo &egrave {0}',
        badFormatMessage: 'Il valore non &egrave un numero valido'
    });
    Ext.define('Ext.locale.it.field.Text', {
        override: 'Ext.field.Text',

        badFormatMessage: 'Il valore non corrisponde al formato richiesto',
        config: {
            requiredMessage: 'Questo campo &egrave; obbligatorio',
            validationMessage: '&egrave nel formato sbagliato'
        }
    });
    Ext.define("Ext.locale.it.grid.filters.menu.Base", {
        override: "Ext.grid.filters.menu.Base",

        config: {
            text: "Filtro"
        }
    });
    Ext.define("Ext.locale.it.grid.locked.Grid", {
        override: 'Ext.grid.locked.Grid',

        config: {
            columnMenu: {
                items: {
                    region: {
                        text: 'Regione'
                    }
                }
            },
            regions: {
                left: {
                    menuLabel: 'Bloccato (Sinistra)'
                },
                center: {
                    menuLabel: 'Sbloccato'
                },
                right: {
                    menuLabel: 'Bloccato (Destra)'
                }
            }
        }
    });
    Ext.define("Ext.locale.it.grid.menu.Columns", {
        override: "Ext.grid.menu.Columns",

        config: {
            text: "Colonne"
        }
    });
    Ext.define("Ext.locale.it.grid.menu.GroupByThis", {
        override: "Ext.grid.menu.GroupByThis",

        config: {
            text: "Raggruppa per questo campo"
        }
    });
    Ext.define("Ext.locale.it.grid.menu.ShowInGroups", {
        override: "Ext.grid.menu.ShowInGroups",

        config: {
            text: "Mostra in gruppi"
        }
    });
    Ext.define("Ext.locale.it.grid.menu.SortAsc", {
        override: "Ext.grid.menu.SortAsc",

        config: {
            text: "Ordine crescente"
        }
    });
    Ext.define("Ext.locale.it.grid.menu.SortDesc", {
        override: "Ext.grid.menu.SortDesc",

        config: {
            text: "Ordine decrescente"
        }
    });
    Ext.define("Ext.locale.it.grid.plugin.RowDragDrop", {
        override: "Ext.grid.plugin.RowDragDrop",
        dragText: "{0} Righe selezionate"
    });
    Ext.define('Ext.locale.it.panel.Collapser', {
        override: 'Ext.panel.Collapser',

        config: {
            collapseToolText: "Riduci pannello",
            expandToolText: "Espandi pannello"
        }
    });
    Ext.define('Ext.locale.it.panel.Date', {
        override: 'Ext.panel.Date',

        config: {
            nextText: 'Mese successivo (control+destra)',
            prevText: 'Mese precedente (control+sinistra)',
            buttons: {
                footerTodayButton: {
                    text: "Oggi"
                }
            }
        }
    });
    Ext.define('Ext.locale.it.picker.Date', {
        override: 'Ext.picker.Date',

        config: {
            doneButton: 'Pronto',
            monthText: 'Mese',
            dayText: 'Giorno',
            yearText: 'Anno'
        }
    });
    Ext.define('Ext.locale.it.picker.Picker', {
        override: 'Ext.picker.Picker',

        config: {
            doneButton: 'Fatto',
            cancelButton: 'Cancella'
        }
    });
    Ext.define('Ext.locale.it.Tool', {
        override: 'Ext.Tool',
        config: {
            printText: 'Stampa',
            exportText: 'Esporta',
            allFiltersText: 'Tutti',
            operatorsText: { 'like': 'contiene', '==': '=' },
            falseText: 'No',
            trueText: 'Si'
        }
    });
    Ext.define('Ext.locale.it.grid.filters.menu.Date', {
        override: 'Ext.grid.filters.menu.Date',
        config: {
            menu: {
                items: {
                    lt: {
                        label: 'Prima del',
                        placeholder: 'Prima del...',
                    },
                    gt: {
                        label: 'Dopo il',
                        placeholder: 'Dopo il...'
                    },
                    eq: {
                        label: 'Il',
                        placeholder: 'Il...',
                    }
                }
            }
        }
    });
    Ext.define('Ext.locale.it.grid.filters.menu.String', {
        override: 'Ext.grid.filters.menu.String',
        config: {
            menu: {
                items: {
                    like: {
                        placeholder: 'Contiene...',
                        label: 'Contiene'
                    }
                }
            }
        }
    });
    Ext.define('Ext.locale.it.grid.filters.menu.Boolean', {
        override: 'Ext.grid.filters.menu.Boolean',
        config: {
            menu: {
                items: {
                    yes: {
                        text: 'Si',
                    },
                    no: {
                        text: 'No',
                    }
                }
            }
        }
    });
    Ext.define('Ext.locale.it.grid.filters.menu.Number', {
        override: 'Ext.grid.filters.menu.Number',
        config: {
            menu: {
                items: {
                    lt: {
                        label: 'Minore di',
                        placeholder: 'Minore di...',
                    },
                    gt: {
                        label: 'Maggiore di',
                        placeholder: 'Maggiore di...'
                    },
                    eq: {
                        label: 'Uguale a',
                        placeholder: 'Uguale a...',
                    }
                }
            }
        }
    });
    Ext.define('Ext.locale.it.grid.rowedit.Plugin', {
        override: 'Ext.grid.rowedit.Plugin',
        config: {
            dirtyText: 'Devi salvare o annullare le modifiche',
            invalidToastMessage: 'Impossibile salvare dati non validi',
            confirmation: {
                reset: 'Sei sicuro di voler annullare le modifiche?',
                revert: 'Sei sicuro di voler ripristinare i valori?'
            },
            buttons: {
                ok: {
                    tooltip: 'Applica'
                },
                cancel: {
                    tooltip: 'Annulla'
                },
                reset: {
                    tooltip: 'Ripristina ai valori visualizzati inizialmente'
                },
                revert: {
                    tooltip: 'Ripristina ai valori originali'
                }
            }
        }
    });

    Ext.define('Ext.locale.it.grid.plugin.filterbar.Operator', {
        override: 'Ext.grid.plugin.filterbar.Operator',
    
        // Definizione della mappa di testo degli operatori
        operatorsTextMap: {
            '=': 'Uguale',
            '==': 'Uguale',
            '!=': 'Diverso',
            '>': 'Maggiore',
            '>=': 'Maggiore o Uguale',
            '<': 'Minore',
            '<=': 'Minore o Uguale',
            'like': 'Contiene',
            'nlike': 'Non contiene',
            'empty': 'Assente',
            'nempty': 'Presente',
            '===': 'Uguale',
            '!==': 'Diverso',
            'regex': 'Espressione regolare',
            'in': 'Compreso',
            'notin': 'Non compreso'
        },
    });
    
    // Assicurati che la sovrascrittura della localizzazione sia caricata
    Ext.require('Ext.locale.it.grid.plugin.filterbar.Operator');
    //Ext.define('Ext.locale.it.grid.plugin.filterbar.filters.List', {
    //    override: 'Ext.grid.plugin.filterbar.List',
    //    operators: ['==', '!=', 'empty', 'nempty'],
    //});
    //Ext.define('Ext.locale.it.grid.plugin.filterbar.filters.String', {
    //    override: 'Ext.grid.plugin.filterbar.String',
    //    operators: ['like', '==', '!=', 'empty', 'nempty'],
    //});
    //Ext.define('Ext.locale.it.grid.plugin.filterbar.filters.Boolean', {
    //    override: 'Ext.grid.plugin.filterbar.Boolean',
    //    operators: ['==', '!='],
    //    trueText: 'Si',
    //});

    // Sovrascrivi le traduzioni per il plugin GridViewOptions di ExtReact per la lingua italiana
    Ext.define('Ext.locale.it.grid.plugin.ViewOptions', {
        override: 'Ext.grid.plugin.ViewOptions',
        config: {
            sheet: {
                scrollable: 'true',
                $value: {
                    items: [{
                        docked: 'top',
                        xtype: 'titlebar',
                        title: 'CONFIGURA GRIGLIA',
                        items: [{
                            xtype: 'button',
                            text: null,
                            ui: 'action',
                            align: 'right',
                            role: 'donebutton',
                            cls: 'appButton',
                            iconCls: 'x-fa fa-xmark'
                        }]
                    }]
                }
            },
            columnList: {
                $value: {
                    cls: 'configuratorGrid',
                    title: null
                }
            }
        }
    });
    if (Ext.Msg) {
        Ext.Msg.setStandardButtons({
            ok: {
                text: 'Ok'
            },
            abort: {
                text: 'Interrompere'
            },
            retry: {
                text: 'Riprovare'
            },
            ignore: {
                text: 'Ignora'
            },
            yes: {
                text: 'Si'
            },
            no: {
                text: 'No'
            },
            cancel: {
                text: 'Cancella'
            },
            apply: {
                text: 'Applica'
            },
            save: {
                text: 'Salva'
            },
            submit: {
                text: 'Invia'
            },
            help: {
                text: 'Aiuto'
            },
            close: {
                text: 'Chiudi'
            }
        });
    }

};

// *** per lo switch del theme ***
//import { ThemeProvider, useTheme } from "./components/ThemeContext";
//import Switch from "./components/switchTheme";

//const Title = ({ children }) => {
//    const { theme } = useTheme();
//    return (
//        <h2
//            style={{
//                color: theme === "light" ? "black" : "white",
//            }}
//        >
//            {children}
//        </h2>
//    );
//};


function App() {
    //const [ambiente, setAmbiente] = useState({ ideAnnFro: null, ideTipPer: null })
    //function updateAmbiente(value) {
    //    ambiente.ideAnnFro = value.ideAnnFro;
    //    ambiente.ideTipPer = value.ideTipPer;
    //    setAmbiente({ ...ambiente });
    //}
    // const [documento, setDocumento] = useState({ idPagina: null, nomePagina: null })
    const { user } = useUser();

    const DOCUMENT_URL = "/Documento";
    const getDocumento = (idPag) => {
        window.open(DOCUMENT_URL + "?idPag=" + idPag, '_blank');
    };

    it();
    return (
        <BrowserRouter>
            <PeriodiProvider>
                <AmbienteProvider>
                    <Routes>
                        <Route exact path="/" element={<LoginPage />} >
                            <Route index element={user?.ruolo === 'IFDISAB' ? <Navigate to="/Cliente" /> : <DashboardDataFetcher />} />
                            {/* <Route index element={<AppPortal />} /> */}

                            <Route path="Dashboard" element={<DashboardDataFetcher />} />

                            <Route path="MainRto" element={<MainRto />} >
                                {/* <Route path="Rto/:id" element={<Rto />} /> */}
                            </Route>
                            <Route path="NuovaRto" element={<MainRto nuova={true} />} >
                            </Route>
                            <Route path="SchedaRto" element={<SchedaRto />} />
                            <Route path="SchedaRtoLettura" element={<RtoLettura />} />
                            {/* <Route path="RtoPage" element={<RtoPage />} /> */}
                            <Route path="Lotto" element={<Lotto />} />

                            <Route path="UploadPO" element={<UploadPO />} />
                            <Route path="UploadRTO" element={<UploadRTO />} />

                            <Route path="Clienti" element={<Clienti />} />
                            <Route path="AcqDossierPCS" element={<AcqDossierPCS />} />
                            <Route path="PubDossierPCS" element={<PubblicazionePOPCS />} />
                            <Route path="AnalisiRTOInternazionali" element={<AnalisiRTOInternazionali />} />
                            <Route path="MonitoraggioCdS" element={<MonitoraggioCdS />} />
                            <Route path="GestioneUtenti" element={<GestioneUtenti />} />

                            <Route path="*" element={<NoMatch />} />
                            <Route path="CatalogoMerci" element={<CatalogoMerci />} />
                            <Route path="GuidaUpload" element={<GuidaUpload />} />
                            <Route path="/Cliente/:ideCli/:action" element={<Cliente />} />
                            <Route path="Cliente" element={<Cliente />} />
                            <Route path="IFNews" element={<ComunicazioniIF />} />
                            <Route path="AuthPCS" element={<AuthPCS />} />

                            <Route path="ReportDettaglio" element={<ReportDettaglio />} />
                            <Route path="ReportGenerale" element={<ReportGenerale />} />
                            <Route path="ReportStatoLavorazione" element={<ReportStatoLavorazione />} />
                            <Route path="DownloadReport" element={<DownloadReport />} />
                            <Route path="AnalisiModificheInCorso" element={<AnalisiModificheInCorso />} />
                            <Route path="DettaglioImpegnoImpianto" element={<DettaglioImpegnoImpianto />} />
                            <Route path="StatisticheFinalizzazione" element={<StatisticheFinalizzazione />} />
                            <Route path="StatisticheStudiFattibilita" element={<StatisticheFattibilita />} />
                            <Route path="StatisticheModificheRTO" element={<StatisticheModifiche />} />
                            <Route path="StatisticheCambiStato" element={<StatisticheCambiStato />} />
                            <Route path="StatisticheOperazioni" element={<StatisticheOperazioni />} />

                            <Route path="Guide" element={<Guide getDocumento={getDocumento} />} />
                            <Route path="Documento" element={<Documento />} />

                        <Route path="MaterialiSTI" element={<MaterialiSTI />} />


                        </Route>
                    </Routes>
                </AmbienteProvider>
            </PeriodiProvider>
        </BrowserRouter>

    );
}

export default App;
