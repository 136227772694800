import React, { useState, useRef } from "react";
import { FormPanel, TextField, ComboBoxField, Container, Button } from "@sencha/ext-react-modern";
import { useUser } from "../components/UserContext";
import { useNavigate } from "react-router-dom";



const Ext = window['Ext'];

const inserimentoClienteStore = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'ajax',
        url: 'Clienti/InserimentoCliente',
        actionMethods: {
            create: 'POST', read: 'POST', update: 'POST', destroy: 'POST'
        },
        reader: {
            type: 'json'
        },
        writer: {
            type: 'json'
        }
    },
    autoload: false
});

const storeTipoCliente = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipologiaClienteAll',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const caricaTipoCliente = () => {
    storeTipoCliente.load({
    })
}

const storeTipoContratto = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiTipoImpresaAll',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const caricaTipoContratto = () => {
    storeTipoContratto.load();
}

const storeTipoTrafficoPrevalente = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Anagrafiche/LeggiAnagraficaTipoTrafficoPrevalenteAll',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const caricaTipoTrafficoPrevalente = () => {
    storeTipoTrafficoPrevalente.load();
}

const storeClientiMaster = Ext.create('Ext.data.Store', {
    proxy: {
        method: 'GET',
        type: 'ajax',
        url: 'Clienti/LeggiClientiMaster',
        reader: {
            type: 'json'
        }
    },
    autoload: false
});
const caricaClientiMaster = () => {
    storeClientiMaster.load();
}

//------------------------------------------------------
const caricaStore = async () => {
    await Promise.all([
        caricaTipoCliente(),
        caricaTipoContratto(),
        caricaTipoTrafficoPrevalente(),
        caricaClientiMaster()
    ])
}

caricaStore();

const NuovoClienteForm = ({ onClose }) => {

    const navigate = useNavigate();
    const { user } = useUser();
    const formPanelRef = useRef(null);

    const [ideTipIprSelected, setIdeTipIprSelected] = useState(null);

    const [idCliMseSelected, setIdCliMseSelected] = useState(null);


    const handleIdeTipIprChange = (event) => {
        setIdeTipIprSelected(event.newValue);
    };

    const handleIdCliMseChange = (event) => {
        setIdCliMseSelected(event.newValue);
    };

    // Aggiungi uno stato per tenere traccia del codice cliente
    const [codiceCliente, setCodiceCliente] = useState('');
    const [codiceClienteError, setCodiceClienteError] = useState('');

    // Funzione di validazione per il codice cliente
    const validateCodiceCliente = (value) => {
        return /^\d{3}$/.test(value);
    };

    // Funzione per gestire il cambiamento del codice cliente
    const handleCodiceClienteChange = (event) => {
        const newValue = event.newValue;
        if (validateCodiceCliente(newValue)) {
            setCodiceCliente(newValue);
            setCodiceClienteError('');
        } else {
            setCodiceClienteError('Il codice cliente deve essere un numero di 3 cifre');
        }
    };


    const handleSubmit = () => {
        if (formPanelRef.current && !codiceClienteError) {
            const formValues = formPanelRef.current.cmp.getValues();
            // Qui puoi gestire i dati del form come necessario

            Ext.Ajax.request({
                url: 'Clienti/InserimentoCliente',
                method: 'POST',
                params: {
                    ideUte: user.ideUte,
                    ipAddress: '',
                    CodCliRfi: formValues.codCliRfi,
                    IdeTipCli: formValues.ideTipCli,
                    IdeTipIpr: formValues.ideTipIpr,
                    IdeTipTraCli: formValues.ideTipTraCli,
                    SiglaCli: `${formValues.codCliRfi}-${formValues.siglaCli}`,
                    NomCli: formValues.nomCli,
                    IdeCliMse: formValues.idCliMse,
                    Rse: formValues.rse
                },
                callback: (records, success, operation) => {
                    if (success) {
                        var returnObj = Ext.decode(operation.responseText);
                        if (returnObj.resp == "OK") {
                            const ideCli = returnObj.cliente.ideCli;
                            const action = 'view';
                            console.log('Cliente inserito con successo');
                            onClose();

                            navigate("/Cliente", { state: { ideCli, action } });
                        }
                        else {
                            Ext.Msg.alert('Inserimento fallito', returnObj.msgErr);
                        }
                    } else {
                        Ext.Msg.alert('Inserimento fallito', returnObj.msgErr);
                        console.error('Errore nell\'inserimento del cliente');
                        //console.log('dataToSend', records);
                    }
                }
            });

        }
    };



    return (
        <FormPanel ref={formPanelRef} layout="vbox" padding="10">

            <TextField
                name="nomCli"
                label="Denominazione"
                labelAlign="left"
                placeholder="Inserisci il nome completo del cliente"
            />

            <ComboBoxField
                name="ideTipCli"
                label="Tipo Cliente"
                labelAlign="left"
                store={storeTipoCliente}
                displayField="desTipCli"
                valueField="ideTipCli"
                queryMode="local"
                placeholder="Seleziona il tipo del cliente"
            />

            <ComboBoxField
                name="ideTipTraCli"
                label="Tipo Traffico Prevalente"
                labelAlign="left"
                store={storeTipoTrafficoPrevalente}
                displayField="desTipTraCli"
                valueField="ideTipTraCli"
                queryMode="local"
                placeholder="Seleziona il tipo di traffico prevalente"
            />
            <TextField
                label="Codice Cliente"
                labelAlign="left"
                name="codCliRfi"
                placeholder="Inserisci il codice del cliente"
                onChange={handleCodiceClienteChange}
                value={codiceCliente}
                error={codiceClienteError}
            />

            <Container layout="hbox" label="Sigla Cliente">
                <TextField
                    label="Sigla Cliente"
                    labelAlign="left"
                    flex={3}
                    readOnly={true}
                    value={codiceCliente ? `${codiceCliente}-` : ''}
                />
                <TextField
                    label=""
                    labelAlign="left"
                    flex={4}
                    name="siglaCli"
                    placeholder="Inserisci la sigla del cliente"
                />
            </Container>
            <ComboBoxField
                name="ideTipIpr"
                label="Tipo Contratto"
                labelAlign="left"
                store={storeTipoContratto}
                displayField="desTipIpr"
                valueField="ideTipIpr"
                queryMode="local"
                placeholder="Seleziona il tipo di contratto"
                onChange={handleIdeTipIprChange}
            />

            {ideTipIprSelected && ideTipIprSelected !== 1 && (
                <ComboBoxField
                    name="idCliMse"
                    label="Cliente Master"
                    labelAlign="left"
                    store={storeClientiMaster}
                    displayField="rge"
                    valueField="idCliMse"
                    queryMode="local"
                    placeholder="Seleziona il cliente Master"
                    onChange={handleIdCliMseChange}
                />
            )}

            {idCliMseSelected === -1 && (
                <TextField
                    label="Ragione Sociale"
                    labelAlign="left"
                    name="rse"
                    placeholder="Inserisci la ragione sociale"
                />
            )}


            <Button
                cls="appButton fullWidth"
                align="right"
                text="Salva"
                ui="action"
                handler={handleSubmit}
                disabled={!!codiceClienteError}
            />



        </FormPanel>
    );
};

export default NuovoClienteForm;
