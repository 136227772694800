// COMPONENTE GESTIONE UTENTE
// ---------------------------
// Dati utente che arrivano dal token PIC, se non c'è il token lo UserContext sarà undefined e se si è in ambiente Development si aprirà la dialogModale per inserire le credenziali.
// Questa dialog avrà 2 button IF e RFIDC (solo in modalità #debug) per l'inserimento veloce delle credenziali di test + un form di login/pwd
// Dal form se le credenziali sono valide, in base alle regole delle pagine masterpage (token)/login(debug) vecchie, si accede al sistema.


import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import '../App.css';
import { useUser } from '../components/UserContext';
import { useAmbiente } from '../components/AmbienteContext';
import { PasswordField, Button, ExtDialog, Panel, Formpanel, Spacer, TextField, Toolbar } from '@sencha/ext-react-modern';
import { useNavigate } from "react-router-dom";
import infoUtenteModel from '../models/InfoUtenteModel.js';
import Home from './HomePage';
import NotValidUser from './NotValidUser';

const Ext = window['Ext'];

const isDevelopmentEnv = (process.env.NODE_ENV != undefined && process.env.NODE_ENV != null && process.env.NODE_ENV != "")? process.env.NODE_ENV === 'development': false; 

function LoginPage() {
    const [showModal, setShowModal] = useState(true);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [params, setParams] = useState({ login: "", pwd: "" })
    const { user, switchUser } = useUser();
    const { ambiente, updateAmbiente, inizializzaAmbiente } = useAmbiente();

    const setUtenteAuto = valore => {
        //alert('Test valore: ' + valore);
        setUserName(valore);
        setPassword(valore);
    }

    //loadAnagPeriodi: () => {
    //    try {
    //        const requests = [
    //            fetchChartData('HomePage/TotaleRTOPerTipo', { ideUte, ideGrp, ideAnnFro, ideTipPer }),
    //            fetchChartData('HomePage/AndamentoRTOPerTipo', { ideUte, ideGrp }),
    //            fetchChartData('HomePage/TotaleRTOPerStato', { ideUte, ideGrp, ideAnnFro, ideTipPer }),
                
    //        ];

    //        const responses = await Promise.all(requests);

    //        ambiente.periodoFerroviario(responses[0]);
          
    //    } catch (error) {
    //        console.error("Errore durante la lettura dell'anagrafica dei periodi", error);
    //    } 
    //}

    const caricaUtentePIC = (paramIdUte) => {
        //console.log('Sono in caricaUtentePIC Ext.Ajax.request con idUte:', paramIdUte);
        Ext.Ajax.request({
            url: 'InfoUser/LeggiInfoUser',
            method: 'POST',
            params: {
                ideUte: parseInt(paramIdUte) 
            },
            callback: function (records, success, operation) {
                if (success && records != null) {
                    let datiUtenteDB = Ext.decode(operation.responseText);
                    configuraUtente(datiUtenteDB);
                }
            }
        });
    }

    const configuraUtente = (datiUtenteDB) => {
        //console.log('sono in configuraUtente1 E datiUtenteDB.ideUte= ', datiUtenteDB.ideUte);
        if (datiUtenteDB != null) {  
            //console.log('sono in configuraUtente2 E datiUtenteDB.usr= ', datiUtenteDB.usr);
            switchUser({
                username: datiUtenteDB.usr,
                idTipoGruppo: datiUtenteDB.ideTipGrp,
                ideCli: datiUtenteDB.ideCli,
                idTipoDominio: datiUtenteDB.ideTipDmi,
                idOperatoreRFI: datiUtenteDB.ideOpaRfi,
                ideUte: datiUtenteDB.ideUte,
                nome: datiUtenteDB.nom,
                cognome: datiUtenteDB.cog,
                descrizioneGruppo: datiUtenteDB.dscGrp,
                idGruppo: datiUtenteDB.ideGrp,
                ruolo: calcolaRuoloUtente(datiUtenteDB.ideTipGrp, datiUtenteDB.ideAbiCli, datiUtenteDB.flgCatMer)

                //esempio
                //username: "astroif-centrale", //ad es.: "astroif-impresa";
                //idTipoGruppo: 2,
                //ideCli: 2,
                //idTipoDominio: 2,
                //idOperatoreRFI: 2
                //ecc.
            });
            async function fetchData() {
                try {
                  await Ext.Ajax.request({
                    url: "Ambiente/GetAmbiente",
                    method: "GET",
                    params: {
                      ideUte: datiUtenteDB.ideUte,
                    },
                    callback: function (records, success, operation) {
                      if (success && records != null) {
                        let callbackOperation = Ext.decode(operation.responseText);
                        inizializzaAmbiente({
                            ideUte: datiUtenteDB.ideUte,
                            ideAnnFro: callbackOperation.ideAnnFro,
                            ideTipPer: callbackOperation.ideTipPer,
                          })
                        
                      }
                    },
                  });
                } catch (error) {
                  console.error(
                    "Errore durante il recupero dell' ambiente:",
                    error
                  );
                }
              }
              fetchData();
            // inizializzaAmbiente(datiUtenteDB.ideUte);
        }
    }

    const calcolaRuoloUtente = (idTipoGruppo, ideAbiCli, flgCatMer) => {
        //return ruolo utente o null se non valido
        switch (idTipoGruppo) {
            case 0:
                return "RFI";
            case 1:
                return "RFI-PO-COMP";
            case 2:
                return "RFI-PO-RFICC";
            case 3:
                if (ideAbiCli != 1)
                    return "IFDISAB";
                else if (flgCatMer == 1)
                    return "IFMERCI";
                else
                    return "IF";
            case 4:
                return "IF-RICAUT";
            default:
                return null;  // redirect verso pagina errore
        }

    }

    /********* DICH. USATE SOLO DA DEVELOPMENT ENVIRONMENT ****************/
    
    function updateValuesParams(a, b) {
        params.login = a;
        params.pwd = b;
        setParams({ ...params });
    }

    const handleSubmit = (btn) => {
        const values = btn.up('formpanel').getValues();
        updateValuesParams(values.login, values.pwd);       
        caricaUtente(params);       
    };

    const handleClose = (e) => {
        setShowModal(false);
        console.log("sto chiudendo");
        //destroy();
        console.log("chiuso");
    }

    const caricaUtente = (params) => {       
        Ext.Ajax.request({
            url: 'InfoUser/LeggiInfoUserDev',
            method: 'POST',
            params: {
                login: params.login,
                pwd : params.pwd
            },
            callback: function (records, success, operation) {
                if (success && records != null) {
                    let datiUtenteLocal = Ext.decode(operation.responseText);
                    configuraUtente(datiUtenteLocal);
                }
            }
        });
    }


    /********** END DEV **************/


    function leggiCookieLoggedIn(cookieName) {
        const cookieString = RegExp(cookieName + "=[^;]+").exec(document.cookie);
        //const cookies = cookieString.split(';');
        return decodeURIComponent(!!cookieString ? cookieString.toString().replace(/^[^=]+./, "") : "");
    }



    if (user) {   //per refresh
        //console.log('user ruolo:', user.ruolo);
        return <Home />
    }
    else {
        if (isDevelopmentEnv != undefined && isDevelopmentEnv) {
            return ( //{ isDevelopmentEnv &&
                <ExtDialog
                    modal={showModal}
                    displayed={showModal}
                    title="Login"
                    closable
                    onHide={handleClose}
                    centered
                >
                    <Panel>
                        <Formpanel ref={form => form = (form)}
                            shadow
                            padding="20"
                            platformConfig={{
                                "!phone": {
                                    maxHeight: 500,
                                    width: 350
                                }
                            }}>
                            <Button iconCls={'fa-solid fa-user fa-spin'}></Button>
                            <TextField value={userName} label="UserID" name="login" required placeholder="inserire utente" />
                            <PasswordField value={password} label="Password" name="pwd" required revealable />
                            <Toolbar shadow={false} docked="bottom" layout={{ type: 'hbox', pack: 'right' }}>
                                <Button text="IF" handler={() => setUtenteAuto("IF")} cls={'ButtonAstro'} />
                                <Button text="RFIDC" handler={() => setUtenteAuto("RFIDC")} cls={'ButtonAstro'} />
                                <Spacer maxHeight={100} maxWidth={100} ></Spacer>
                                <Button type="submit" text={"Entra"} cls={'ButtonAstro'} handler={handleSubmit} />
                                {/*<Button text="Reset" handler={() => this.form.reset()} />*/}
                            </Toolbar>
                        </Formpanel>
                    </Panel>
                </ExtDialog>
            )
        }
        else {  //accesso da PICWEB
            //console.log('user non presente:', user);
            const contenutoCookie = leggiCookieLoggedIn('AstroIFCookie');
            if (contenutoCookie) {
                //console.log('Contenuto del cookie:', contenutoCookie);
                Ext.Ajax.request({
                    url: 'InfoUtente/GetCookie',
                    method: 'POST',
                    params: {
                        contenutoCookie: contenutoCookie
                    },
                    callback: function (records, success, operation) {
                        //console.log('success:', success);
                        //console.log('records:', records);
                        //console.log('operation.responseText:', operation.responseText);

                        if (success && operation.responseText!=null) {
                            let datiUtentePIC = Ext.decode(operation.responseText);
                            //console.log('Contenuto del cookie decrypted e deserializzato:', Ext.decode(operation.responseText));	
                            //console.log('datiUtentePIC.usr:', datiUtentePIC.usr);
                            //console.log('datiUtentePIC.ideUte:', datiUtentePIC.ideUte);
                            //lo Ext.decode(operation.responseText) contiene InfoUser
                            caricaUtentePIC(parseInt(datiUtentePIC.ideUte)); //refresh sul setUser e va in home                           
                        }
                        else {
                            console.log('Utente non valido.');
                            return <NotValidUser />
                        }
                    }
                });
            } else {
                console.log('Il cookie AstroIFCookie non è presente o è vuoto.');
                return <NotValidUser />
            }
        }
    }

}

export default LoginPage;




